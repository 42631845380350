import {connect} from "react-redux";
import DriverState from "./DriverState";
import {
  changeDriverVehicle,
  startDriverPresence,
  stopDriverPresence,
  isothermBagReturn,
  changeDriverCenter,
} from "../../actions/driver";
import {
  DISPATCH_MAIN_MODAL_HIDE,
  showDepositModalDriver,
  showReturnBagsModal,
} from "../../actions/modal";

const mapStateToProps = (state, ownProps) => {
  //console.log("drivers state", ownProps);
  return {
    ...ownProps,
    centers: state.dispatch.metas.centers || [],
  };
};

const mapDispatchToProps = dispatch => {
  // WIP HERE
  return {
    "startPresence": (driver, vehicle) => dispatch(startDriverPresence(driver, vehicle)),
    "stopPresence": (driver, unfinished_new_status) => dispatch(stopDriverPresence(driver, unfinished_new_status)),
    "changeVehicle": (driver, vehicle) => dispatch(changeDriverVehicle(driver, vehicle)),
    "isothermBagReturn": (driver) => dispatch(isothermBagReturn(driver)),
    "showDepositModalDriver": (driver) => dispatch(showDepositModalDriver(driver)),
    "showReturnBagsModal": (driver) => dispatch(showReturnBagsModal(driver)),
    "onHide": () => dispatch({ type: DISPATCH_MAIN_MODAL_HIDE }),
    "changeCenter": (driver, centerId) => dispatch(changeDriverCenter(driver, centerId)),
  };
};

let DriverStateLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverState);


export default DriverStateLinked;
