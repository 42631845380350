import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {DISPATCH_EXTRA_COST_DEMANDS_REVIEW} from "../actions";
import {DISPATCH_MAIN_MODAL_SHOW} from "../actions/modal";


const InternalDriverExtraCostDemand = ({demand, review, validate, driver}) => {
  return (
    <tr>
      <td>
        {
          (new Date(demand.created_at)).toLocaleDateString()
            + ' à '
            + (new Date(demand.created_at)).toLocaleTimeString()
        }
      </td>
      <td>
        {driver ? driver.name : demand.driver_object ? demand.driver_object.name : ''}
      </td>
      <td>
        {demand.dock ?
          <a href={`${process.env.REACT_APP_DELEEVADMIN}/livreurs/logs/group/${demand.dock}`}
            target="_blank" rel="noopener noreferrer" >Fiche</a>
          : ''
        }
      </td>
      <td>
        {demand.order ?
          <a href={`/support/order/${demand.order}`} target="_blank" rel="noopener noreferrer" >Fiche</a>
          : ''
        }
      </td>
      <td>
        {demand.extra_cost_demand_type_label}
      </td>
      <td>
        {demand.minutes}
      </td>
      <td>
        {demand.comment}
      </td>
      <td>
        <div className={"btn-group"}>
          <button
            className={"btn btn-xs btn-default"}
            style={{borderRadius: "3px"}}
            onClick={() => review(demand)}
          >
            Refusé
          </button>
          <button
            className={"btn btn-xs btn-success ml-3"}
            style={{borderRadius: "3px"}}
            onClick={() => validate(demand)}
          >
            Validé
          </button>
        </div>
      </td>
    </tr>
  );
};

InternalDriverExtraCostDemand.propTypes = {
  demand: PropTypes.object.isRequired,
  review: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  driver: PropTypes.object,
};

export const DriverExtraCostDemand = withRouter(connect(
  (state) => {
    return { };
  },
  (dispatch) => {
    return {
      review: (demand) => {
        dispatch(
          {
            type: DISPATCH_EXTRA_COST_DEMANDS_REVIEW,
            demandId: demand.id,
          }
        );
      },
      validate: (demand) => {
        dispatch(
          {
            type: DISPATCH_MAIN_MODAL_SHOW,
            modalData: {
              modalType: "extracost_add",
              demand: demand,
            }
          }
        );
      }
    };
  }
)(InternalDriverExtraCostDemand));
