import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { driverDepositOrderMakeReturn } from  "../../actions/deposit_driver";
import { rd } from "../../../../utils";
import "../../css/Deposit.css";

const DepositOrderDriverReturn = ({
  driver,
  deposit_driver: { orders },
  dispatch
}) => (
  orders.length > 0
    ? (
      <div className="DepositDriverReturn">
        <div>
          {orders.map(depositOrder => (
            <div key={depositOrder.order_id} className="DepositOrderRow">
              <Link to={`/support/order/${depositOrder.order_id}/`} target="_blank" rel="noopener noreferrer">
                Commande <strong>#{depositOrder.random_id}</strong>
              </Link>
              <p>{rd(depositOrder.amount)} €</p>
              <Button
                variant="primary"
                size="sm"
                onClick={
                  () => dispatch(driverDepositOrderMakeReturn(driver.id, depositOrder.order_id))
                }
              >
                Retourner
              </Button>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div>
        <br />
        <p className="alert alert-info">
          Le livreur n&apos;a pas de commande consignée en sa possession
        </p>
      </div>
    )
);

DepositOrderDriverReturn.propTypes = {
  driver: PropTypes.object.isRequired,
  deposit_driver: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    deposit_driver: state.dispatch.deposit.driver,
  })
)(DepositOrderDriverReturn);
