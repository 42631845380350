import {connect} from "react-redux";
import {
  changeEndingCenter, changeMainSetting, callStuart,
  closeDock, cancelStuart, assignDock, unlockDock,
  regularizeStuart,
} from "../actions";
import DockTitle from "../components/DockTitle";


const mapStateToProps = (state) => {
  return {
    authUser: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "onChangeMainSetting":  (dock, newValue) => dispatch(changeMainSetting(dock, newValue)),
    "onCallStuart":         (dock, transportType) => dispatch(callStuart(dock, transportType)),
    "onCancelStuart":       (dock) => dispatch(cancelStuart(dock)),
    "onRegularizeStuart":   (dock) => dispatch(regularizeStuart(dock)),
    "onCloseDock":          (dock) => dispatch(closeDock(dock)),
    "onChangeEndingCenter": (dock, newValue) => dispatch(changeEndingCenter(dock, newValue)),
    "onAssignDock":         (dock) => dispatch(assignDock(dock)),
    "onUnlockDock":         (dock) => dispatch(unlockDock(dock)),
  };
};


let DockTitleLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(DockTitle);


export default DockTitleLinked;

