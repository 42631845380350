import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {changeStatus} from "../actions";
import {MAIN_MODAL_SHOW} from "../actions/modal";
import { XD_FULL_STOCK_ORDER } from "../utils/crossDocking";
import { STATUS_ORDER_PREPARED, STATUS_ORDER_XD_TO_CHECK } from "../utils/status";

class IReadyToGoButton extends Component {

  handleClick(task) {
    const { onChangeStatus, showNbBagsModal } = this.props;

    task.linked_order.cross_docking === XD_FULL_STOCK_ORDER
      ? onChangeStatus(task.linked_order.id, STATUS_ORDER_XD_TO_CHECK)
      : onChangeStatus(task.linked_order.id, STATUS_ORDER_PREPARED);

    showNbBagsModal(task.linked_order);
  }

  render() {
    let task = this.props.task;

    if (!task.linked_order) {
      return ('');
    } else {
      return (<button className="ReadyToGoButton btn btn-xs btn-primary" onClick={() => this.handleClick(task)}>Prêt à partir</button>);
    }
  }
}

IReadyToGoButton.propTypes = {
  task: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  showNbBagsModal: PropTypes.func.isRequired
};

const ReadyToGoButton = connect(
  null,
  (dispatch) => {
    return {
      "onChangeStatus": (orderId, newStatus) => (dispatch(changeStatus(orderId, newStatus, null, null))),
      "showNbBagsModal": (order) => (
        dispatch({
          type: MAIN_MODAL_SHOW,
          modalData: {order: order, modalType: 'order_number_of_bags'}
        })
      )
    };
  }
)(IReadyToGoButton);

export default  ReadyToGoButton;
