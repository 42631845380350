import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import {MODAL_HIDE__CANTEEN_ORDERS} from "../actions/commonModals";
import DeliveriesList from "../apps/dispatch/components/Deliveries/DeliveriesList";
import {withRouter} from "react-router-dom";


const InternalCanteenOrdersModal = (
  ({modal, hide, location}) => {
    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      centered={true}
      size={"xl"}
      dialogClassName="canteen-orders-dialog"
    >
      <Modal.Body>
        <h4>Détail de la cantine</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        {
          modal && modal.canteen
          ? (
            <React.Fragment>
              <DeliveriesList
                nothingIfEmpty={false}
                tasks={modal.canteen.canteen_tasks}
                title={`Commandes de la cantine ${modal.canteen.canteen_name}`}
                className={"canteen-orders-list"}
                show={
                  {
                    displayMode: "one_line",
                    name: true,
                    driver: true,
                    sms: true,
                    status: false,
                    late: false,
                    menu: false,
                    withSelect: false,
                    addressFormatting: null,
                  }
                }
                location={location}
              />
            </React.Fragment>
          )
          : null
        }
      </Modal.Body>
    </Modal>;
  }
)

InternalCanteenOrdersModal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
};

export const CanteenOrdersModal = withRouter(
  connect(
    (state) => {
      return {
        modal: state.commonModals.canteenOrders,
      };
    },
    (dispatch) => {
      return {
        "hide": () => {
          dispatch({"type": MODAL_HIDE__CANTEEN_ORDERS});
        }
      };
    }
  )(InternalCanteenOrdersModal)
);
