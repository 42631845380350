import { createSelector } from 'reselect'
import {computeDockList, computeDockListDeliveries} from "../containers/common-props/dock";

const getDeliveries = state => state.m.deliveries
const getDocks = state => state.m.docks
const getDrivers = state => state.m.drivers

const getDockListTodo = state => state.m.dock_list_todo
const getDockListBasketTodo = state => state.m.dock_list_basket_todo

export const getComputedDockListTodo = createSelector(
  [getDockListTodo, getDeliveries, getDocks, getDrivers],
  (dock_list, deliveries, docks, drivers) => {
    return computeDockList(dock_list, deliveries, docks, drivers)
  }
)

export const getComputedDockListBasketTodo = createSelector(
  [getDockListBasketTodo, getDeliveries, getDocks, getDrivers],
  (dock_list, deliveries, docks, drivers) => {
    return computeDockList(dock_list, deliveries, docks, drivers)
  }
)

const getDockListDoing = state => state.m.dock_list_doing
const getDockListBasketDoing = state => state.m.dock_list_basket_doing

export const getComputedDockListDoing = createSelector(
  [getDockListDoing, getDeliveries],
  (dock_list, deliveries) => {
    return computeDockListDeliveries(dock_list, deliveries)
  }
)

export const getComputedDockListBasketDoing = createSelector(
  [getDockListBasketDoing, getDeliveries],
  (dock_list, deliveries) => {
    return computeDockListDeliveries(dock_list, deliveries)
  }
)
