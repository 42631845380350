import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { api } from "../../../api";
import PropTypes from 'prop-types';
import moment from 'moment';
import StoreStatusChangeLogs from './StoreStatusChangeLogs';

const formatStoreStatus = (status) => {
  if (status === 'ONLINE') {
    return "OUVERT";
  } else if (status === 'OFFLINE') {
    return "EN PAUSE";
  }
  return status;
};

const formatStoreStatusReason = (reason) => {
  if (reason === 'PAUSED_BY_RESTAURANT') {
    return "Mis en pause par le store";
  } else if (reason === 'OUT_OF_MENU_HOURS') {
    return "En dehors des heures d'ouverture";
  } else if (reason === 'PAUSED_BY_UBER') {
    return "Mis en pause par Uber";
  } else if (reason === 'INVISIBLE') {
    return "Invisible";
  }
  return reason;
};

const formatStoreStatusMetadata = (metadata) => {
  if (metadata === 'UNKNOWN') {
    return "Inconnue";
  } else if (metadata === 'PROBLEM_IN_RESTAURANT') {
    return "Problème dans le store";
  } else if (metadata === 'OUT_OF_ITEM') {
    return "En rupture de stock";
  } else if (metadata === 'CLOSING_EARLY') {
    return "Fermeture anticipée";
  } else if (metadata === 'INCIDENT_DETECTED_ON_ORDER_MANAGEMENT_APPLICATION') {
    return "Incident détecté sur l'application de gestion des commandes";
  } else if (metadata === 'UNFULFILL_RATE') {
    return "Taux d'acceptation non-respecté (automatique après un refus/annulation)";
  } else if (metadata === 'NO_COURIER_AVAILABLE') {
    return "Pas de livreur disponible";
  } else if (metadata === 'FULFILLMENT_LATENCY') {
    return "Délai de préparation";
  } else if (metadata === 'PCA_LATENCY') {
    return "Délai PCA";
  } else if (metadata === 'PCA_ERROR_RATE') {
    return "Taux d'erreur PCA";
  } else if (metadata === 'INVALID') {
    return "Invalide";
  } else if (metadata === 'OTHER') {
    return "Autre";
  }
  return metadata;
};

const StoreStatusData = ({ storeStatusData, centerId, refetch }) => {
  const queryCache = useQueryCache();
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [
    handleUpdateStoreStatus,
    { isError: mutateStoreStatusIsError, error: mutateStoreStatusError }
  ] = useMutation(({ storeId, status }) => {
    return api.updateUberEatsStoreStatus(centerId, storeId, status);
  }, {
    onSuccess: () => {
      queryCache.invalidateQueries({ queryKey: ['uberEatsStoreStatuses', centerId] });
      refetch();
    },
    onError: (error) => {
      console.error(error);
    }
  });

  return (
    <>
      <div key={storeStatusData.store_id}>
        <h1>{storeStatusData.store_name}</h1>
        <h2>
          <span className={`badge badge-${storeStatusData.status === 'OFFLINE' ? 'danger' : 'success'}`}>{formatStoreStatus(storeStatusData.status)}</span>
          <button className="btn btn-info btn-sm" style={{ marginLeft: 5 }} data-toggle="modal" data-target={`#status-logs-${storeStatusData.store_id}`} onClick={() => setIsLogsOpen(true)}>Voir les changements</button>
        </h2>
        {storeStatusData.status === 'OFFLINE' ? (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading" style={{ backgroundColor: '#f2dede' }}>Le store est en pause.</h4>
            <hr />
            Il n'est pas possible de recevoir des commandes UberEats.<br />
            {storeStatusData.is_offline_until && (
              <>
                Jusqu'à: {moment(storeStatusData.is_offline_until).locale('fr').format('LLLL')}<br />
              </>
            )}
            Raison: {formatStoreStatusReason(storeStatusData.offline_reason)}
            {storeStatusData.offline_reason_metadata && storeStatusData.offline_reason_metadata !== 'UNKNOWN' && (
              <span style={{ marginLeft: 3 }}>
                ({formatStoreStatusMetadata(storeStatusData.offline_reason_metadata)})
              </span>
            )}<br />
            {storeStatusData.offline_reason !== 'OUT_OF_MENU_HOURS' && storeStatusData.offline_reason !== 'INVISIBLE' ? (
              <div>
                Pour le rouvrir, veuillez cliquer sur le bouton ci-dessous.<br />
                <button className="btn btn-success" onClick={() => handleUpdateStoreStatus({ storeId: storeStatusData.store_id, status: "ONLINE" })}>Rouvrir le store</button>
              </div>
            ) : (
              <div>
                Les heures d'ouverture sont configurables <a href={process.env.REACT_APP_DELEEVADMIN + `/admin/slots/byweekdayrule/?center=${centerId}`}
                  target="_blank" rel="noopener noreferrer">ici</a>.
                {storeStatusData.store_type === 'RESTAURANT'
                  ? (<> (Il faudra ensuite faire une synchronisation manuelle des stores sur <a href={process.env.REACT_APP_PRODUCTS + '/sites'} target="_blank" rel="noopener noreferrer">products</a> pour appliquer les nouveaux horaires)</>)
                  : (<> (Il faudra ensuite changer les heures d'ouverture manuellement sur la dashboard <a href={`https://merchants.ubereats.com/manager/stores/${storeStatusData.store_id}`} target="_blank" rel="noopener noreferrer">Merchants UberEats</a>)</>)
                }
              </div>
            )}
          </div>
        ) : (
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading" style={{ backgroundColor: '#dff0d8' }}>Le store est ouvert.</h4>
            <div>
              <button className="btn btn-danger" onClick={() => handleUpdateStoreStatus({ storeId: storeStatusData.store_id, status: "OFFLINE" })}>Mettre le store en PAUSE</button>
            </div>
          </div>
        )}
        {mutateStoreStatusIsError && <div>{mutateStoreStatusError.message}</div>}
      </div>
      <StoreStatusChangeLogs storeId={storeStatusData.store_id} storeName={storeStatusData.store_name} isOpen={isLogsOpen} setIsOpen={setIsLogsOpen} />
      <hr />
    </>
  );
};

StoreStatusData.propTypes = {
  storeStatusData: PropTypes.object,
  centerId: PropTypes.number,
  refetch: PropTypes.func,
};

export default StoreStatusData;
