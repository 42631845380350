import {connect} from "react-redux";
import DriverStates from "../components/DriverStates";
import {getFilteredDrivers} from "../selectors/driver_states";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
  return {
    drivers: getFilteredDrivers(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => {
  // WIP HERE
  return {
  };
};

let DriverStatesLinked = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverStates));


export default DriverStatesLinked;
