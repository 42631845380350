import React from 'react';

import "../css/TaskName.css";
import {
  SHOW_ORDER_INFO,
  ORDER_PSEUDOZONE_UPDATE,
  alterStockPseudoZone,
  loadOrderSupportInfo,
} from "../actions";
import { connect } from 'react-redux';
import * as Raven from "raven-js";
import {MAIN_MODAL_SHOW} from "../actions/modal";
import {showPopinConfirm} from "../apps/support/actions";
import {extractCenterId} from "../utils/center";

import TaskNameDisplay from '../common-comps/TaskNameDisplay';

export const TaskName = connect(
  (state, ownProps) => {

    let pseudoZones = [];
    try {
      Raven.context(function() {
        const pzList = state.m.pseudoZones;
        const o = ownProps.task.linked_order;

        if (pzList.length > 0 && o && o.pseudo_zones_status) {
          // console.log('local pseudozones_match', ownProps.task.linked_order.extra.pseudozones_match );

          for (let opz of o.pseudo_zones_status) {
            let userName = '';
            if (opz.user && opz.user.id) {
              userName = opz.user.name.trim() || opz.user.email;
            }

            pseudoZones.push({
              pz: pzList.find((e) => e.id === opz.pseudozone_id),
              isDone: opz.is_done,
              isLocked: opz.is_locked,
              isMatched: opz.is_matched,
              countItemMatch: opz.count_item_match,
              user: state.user,
              userName
            });

          }
        }
      });
    } catch (e) {
      Raven.captureException(e);
      console.error(e);
    }
    return {
      pseudoZones: pseudoZones,
      centerId: extractCenterId(state.user),
      someSupportInfo: (
        ownProps.task.linked_order
        && state.noLoadState.someSupportInfoByOrderId.hasOwnProperty(ownProps.task.linked_order.id)
        ? state.noLoadState.someSupportInfoByOrderId[ownProps.task.linked_order.id]
        : {}
      ),
    };
  },
  (dispatch) => {
    return {
      displayOrder : (order) => dispatch({type:SHOW_ORDER_INFO, order: order}),
      loadOrderSupportInfo: (order, fetchedAt) => dispatch(loadOrderSupportInfo(order, fetchedAt)),
      toggleOrderPseudoZone : (orderId, pseudoZoneId, isDone) => {
        return dispatch({type: ORDER_PSEUDOZONE_UPDATE, orderId:orderId, pseudoZoneId: pseudoZoneId, isDone:isDone});
      },
      alterStock: (task, pseudoZoneId, action_type) => {
        let modalTitle = <span>Êtes vous sûr de vouloir <strong style={{color: "red"}}>DESTOCKER</strong> les produits de cette zone ?</span>;
        if (action_type === 'restock') {
          modalTitle = <span>Êtes vous sûr de vouloir <strong style={{color: "red"}}>RESTOCKER</strong> les produits de cette zone ?</span>;
        }
        return dispatch(showPopinConfirm(
          modalTitle,
          alterStockPseudoZone(task, pseudoZoneId, action_type)
        ));
      },
      showMissing : (order) => {
        dispatch({type: MAIN_MODAL_SHOW, modalData: {order: order, modalType: 'order_missing_product'}});
      }
    };
  }
)(TaskNameDisplay);

export default TaskName;
