import React from 'react';
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import moment from 'moment';

const ExternalShippingJobTooltip = ({ externalJobs }) => {
  if (!externalJobs || !externalJobs.length) {
    return null;
  }
  const popover = (
    <Popover placement="top" title="multi" style={{maxWidth:"500px", border: "1px solid black"}}>
      <table cellPadding={8}>
        <thead>
          <tr style={{ borderBottom: "1px solid black"}}>
            <th>Livreur</th>
            <th>véhicule</th>
            <th>status</th>
            <th>pickup</th>
            <th>dropoff</th>
          </tr>
        </thead>
        <tbody>
          {externalJobs.map((externalJob) => (
            <tr>
              <td>{externalJob.data.driver_name} {externalJob.data.driver_phone}</td>
              <td>{externalJob.data.transport_type}</td>
              <td>{externalJob.status}</td>
              <td>{externalJob.data.pickup_at && moment(externalJob.data.pickup_at).format('LT')}</td>
              <td>{externalJob.data.dropoff_eta && moment(externalJob.data.dropoff_eta).format('LT')}</td>
            </tr>
          ))}
        </tbody>

      </table>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" overlay={popover} placement='top'>
      <Button variant="danger" size="xs">
        multi
      </Button>
    </OverlayTrigger>
  )
};

export default ExternalShippingJobTooltip;
