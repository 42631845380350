import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import moment from 'moment';
import {withRouter} from "react-router-dom";

import "../../css/OrderList.css";


const InternalSubscriptionList = withRouter(({subscriptionList}) => {
  if (!subscriptionList) {
    return null;
  }
  moment.locale('fr');
  let createdDate = null;
  let dateLabel = null;

  return <div className='subscriptionList'>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>Id stripe</th>
          <th>Depuis le</th>
          <th>Prix</th>
          <th>Activé</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {subscriptionList.map((subscription)=> {
          createdDate = moment(subscription.created);
          dateLabel = createdDate.format('L');
          return <tr key={subscription.id} className={""}>
            <td>{subscription.stripe_subscription}</td>
            <td>{dateLabel}</td>
            <td>{subscription.price} {subscription.currency}</td>
            <td>{subscription.active ? "Oui" : "Non"}</td>
            <td>
              <span className="col-sm-4 checkbox">
                <a href={process.env.REACT_APP_DELEEVADMIN + `/subscriptions/cancel?subscription_id=${subscription.stripe_subscription}`} rel="noopener noreferrer" target="_blank">
                Résilier et rembourser
                </a>
              </span>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalSubscriptionList.propTypes = {
  subscriptionList: PropTypes.array,
  cancelSubscription: PropTypes.func,
};

export const SubscriptionList = connect(
  null,
)(InternalSubscriptionList);
