import React from 'react';
import PropTypes from "prop-types";

import { ShortSlot } from "../../../components/Common";
import { slotLabel, slotDepartureHourLabel } from "../../../utils/task";
import ArrivalsTaskName from './ArrivalsTaskName';
import { NumberOfBags } from "../../../components/NumberOfBags";
import {NumberOfCanteenOrders} from "../../../common-comps/NumberOfCanteenOrders";

class ArrivalRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newArea: '', received: false, notReceived: false };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const { target } = e;
    const { shippingTask, updateArrivals, dockId } = this.props;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({...this.state, [name]: value });
    updateArrivals(shippingTask.linked_order, {...this.state, [name]: value }, dockId);
  }

  render() {
    const { shippingTask, readOnly, dockId, showCrateModal } = this.props;

    let mainLabel;
    let mainEtdLabel;
    if (shippingTask.main_order) {
      mainLabel = slotLabel(shippingTask.main_order);
      mainEtdLabel = slotDepartureHourLabel(shippingTask.main_order);
    } else {
      mainLabel = slotLabel(shippingTask.linked_order);
      mainEtdLabel = slotDepartureHourLabel(shippingTask.linked_order);
    }

    return (
      <tr className="ArrivalRow Row">
        <td title={shippingTask.id}>
          <ArrivalsTaskName task={shippingTask} disableTabIndex />
        </td>
        <td>
          {
            shippingTask.linked_order && shippingTask.linked_order.canteen_witness
            ? <NumberOfCanteenOrders order={shippingTask.linked_order} />
            : <NumberOfBags order={shippingTask.linked_order} taskId={shippingTask.id} dockId={dockId}/>
          }
        </td>
        <td>
          <div
            onClick={() => showCrateModal(shippingTask.linked_order, shippingTask.id, dockId)}
            style={{cursor:'pointer'}}
          >
            <span className="font-weight-bold">{shippingTask.linked_order.crates.length}</span>
            <div>
              {shippingTask.linked_order.crates.map(c => c.code).join(', ')}
            </div>
          </div>
        </td>
        <td>
          <input
            type="text"
            tabIndex="0"
            value={this.state.newArea}
            onChange={this.handleInputChange}
            name="newArea"
          />
        </td>
        <td>
          <input
            type="checkbox"
            id="received"
            name="received"
            className="ml-3"
            onChange={this.handleInputChange}
            value={this.state.received}
          />
        </td>
        <td>
          <input
            type="checkbox"
            id="not-received"
            name="notReceived"
            className="ml-3"
            onChange={this.handleInputChange}
            value={this.state.notReceived}
            disabled={readOnly}
          />
        </td>
        <td>
          <ShortSlot
            slot={mainLabel}
            slotEtd={mainEtdLabel}
            uniqueId={shippingTask.main_order ? shippingTask.main_order.id : shippingTask.linked_order.id}
          />
        </td>
      </tr>
    );
  }
}

ArrivalRow.propTypes = {
  shippingTask: PropTypes.object.isRequired,
  updateArrivals: PropTypes.func.isRequired,
  dockId: PropTypes.number,
  readOnly: PropTypes.bool,
  showCrateModal: PropTypes.func,
};

export default ArrivalRow;
