import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import { connect } from 'react-redux';

import {driverDepositCancelReceived, act_showPopinConfirm} from  "../../actions/deposit_driver";

class Internal extends Component {

  render() {
    const {deposit, deposit_user, showPopupConfirm, user_id} = this.props;

    if (!deposit.is_received) {
      return null;
    }

    let cancel_status = deposit.status.cancel_status;
    let quantity_can_cancel_receive = deposit.status.quantity_can_cancel_receive;
    let deposit_user_id = null;
    if (typeof(deposit_user) !== 'undefined') {
      deposit_user_id = deposit_user.id;
      cancel_status = deposit.status.details[deposit_user_id].cancel_status;
      quantity_can_cancel_receive = deposit.status.details[deposit_user_id].quantity_can_cancel_receive;
    }
    if (cancel_status === 'total') {
      return null;
    }
    let disabled = false;
    if (!quantity_can_cancel_receive > 0) {
      disabled = true;
    }


    const popoverInfos = () => {
      return <Tooltip id="deposit-cannot-cancel">
        Cette remise ne peut être annulée car le livreur l'a déjà rendue.
      </Tooltip>;
    };

    const popupConfirmContent = (deposit, deposit_user_id) => {
      let detail_html = null;

      // Si deposit_user_id = annulation depuis la fiche client
      if (deposit_user_id !== null) {
        let details = Object.values(deposit.status.details).filter(detail => (
          detail.deposit_user_id === deposit_user_id
        ));
        detail_html = <ul>{details.map((detail, key) => (
          <li key={key}>x {detail.quantity_can_cancel_receive} remis le {deposit.formated_created_at}</li>
        ))}</ul>;

      // Annulation depuis l'historique du livreur
      } else {
        let details = deposit.details.filter(detail => (
          detail.deposit_user.status.cancel_status !== 'total'
        ));
        detail_html = <ul>{details.map((detail, key) => (
          <li key={key}>x {detail.deposit_user.status.quantity_can_cancel_receive} acheté le {detail.deposit_user.formated_created_at} et remis le {deposit.formated_created_at} (Cmd #{detail.deposit_user.order_id})</li>
        ))}</ul>;
      }
      return <div>
        <h3>Êtes vous sûr de vouloir annuler cette remise ?</h3>
        <div>{deposit.product_infos.deposit_label}</div>
        {detail_html}
      </div>;

    };

    return <div className="DepositDriverCancelReceivedButton">
      {disabled
        ? <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popoverInfos(deposit.id, deposit.quantity_can_cancel_receive, deposit.product_infos.deposit_label)} rootClose={true} transition={false}>
          <div className="cancel_received_disabled">
            <Button variant="danger" size="xs">Annuler</Button>
          </div>
        </OverlayTrigger>
        : <Button variant="danger" size="xs" onClick={() => {showPopupConfirm(deposit.driver_infos.id, deposit.id, deposit_user_id, user_id, popupConfirmContent(deposit, deposit_user_id));}}>Annuler</Button>
      }
    </div>;
  }
}

Internal.propTypes = {
  isSubmitting: PropTypes.bool,
  deposit: PropTypes.object.isRequired,
  deposit_user: PropTypes.object,
  user_id: PropTypes.number,
  showPopupConfirm: PropTypes.func.isRequired,
};

export const DepositDriverCancelReceivedButton = connect(
  () => {
    return {};
  },
  (dispatch) => {
    return {
      "showPopupConfirm": (driver_id, deposit_driver_id, deposit_user_id, user_id, content) => {
        dispatch(act_showPopinConfirm(
          content,
          driverDepositCancelReceived(driver_id, deposit_driver_id, deposit_user_id, user_id)
        ));
      }
    };
  }
)(Internal);
