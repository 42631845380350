import {connect} from "react-redux";
import PackingList from "../components/PackingList";
import {getSortedPackingToCheck} from "../selectors/packing";


const SHOW_OBJECT = {prep: true};

const mapStateToProps = state => {
  let tasks = getSortedPackingToCheck(state);

  return { tasks: tasks, title: "À vérifier", className: 'to-check-list', show: SHOW_OBJECT};
};

const mapDispatchToProps = dispatch => {
  return {
    // "onDropTask": (targetTaskId, droppedTaskId) => dispatch(dropOnPackingTask(targetTaskId, droppedTaskId)),
    // "onDropDefault": (driverId, droppedTaskId) => dispatch(dropOnPackingDefault(droppedTaskId)),
  };
};

let PackingCheckingListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingList);

export default PackingCheckingListLinked;
