import {TYPE_TROLLEY} from "../../utils/transit_areas";

let trolleys = {};

trolleys.loaded = function (action, state) {
  /*
   * action = liste de trolleys
   */
  return {
    ...state,
    metas: {
      ...state.metas,
      trolleys: action.results
    }
  };
};

trolleys.changed = function(action, state) {
  /*
   * action = trolley
   */
  let transit_areas = [];
  let ta_by_id = {};
  if (!(state.transit_areas instanceof Array)) {
    transit_areas = Object.values(state.transit_areas);
  }
  transit_areas.forEach((ta) => {
    if (
      ta.transit_area_type === TYPE_TROLLEY
      && (
        (typeof(ta.trolley.id) !== "undefined" && ta.trolley.id === action.id)
        || (ta.trolley === action.id)
      )
    ) {
      ta.trolley = action;
    }
    ta_by_id[ta.id] = ta;
  });
  
  return {
    ...state,
    transit_areas: ta_by_id
  };
};

trolleys.logsLoad = function (action, state) {
  /*
   * action = liste de logs
   */
  return {
    ...state,
    trolley_logs: [],
  };
};

trolleys.logsLoaded = function (action, state) {
  /*
   * action = liste de logs
   */
  return {
    ...state,
    trolley_logs: action.data
  };
};

export default trolleys;
