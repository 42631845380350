import {connect} from "react-redux";
import PackingList from "../components/PackingList";
import {getAvgTitles} from "../selectors/iph";
import {getCphAvg} from "../selectors/cph";
import {getTadAvg} from "../selectors/tad";
import {getTapAvg} from "../selectors/tap";
import {getSortedPackingDoing} from "../selectors/packing";


const SHOW_OBJECT = {prep: true};

const mapStateToProps = state => {
  let iph_titles = getAvgTitles(state);

  return {
    tasks: getSortedPackingDoing(state),
    title: `En préparation`,
    titleRight : iph_titles['titleRight'],
    className: "to-check-list",
    show : SHOW_OBJECT,
    packerstatiph_avg: iph_titles['packerstatiph_avg'],
    packerstatcph_avg: getCphAvg(state),
    packerstattad_avg: getTadAvg(state),
    packerstattap_avg: getTapAvg(state)
  };

};

const mapDispatchToProps = null;

let PackingDoingListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingList);

export default PackingDoingListLinked;
