/**
 *
 * Retourne le détail du nombre de sac pour une commande
 * @param {Object} order
 * @return {Object}
 *  orderBagsDetail: Nombre de sac sans opz: sec, iso, surg.
 *  bagDetail: Nombre de sac opz compris: total, sec, iso, surg
 *  extraPlaces: Pseudozones avec nombre de sac (utilisé dans le formulaire)
 *  getFormattedContent: Contenu à afficher (différent flow vs. support)
 *
 */
export const useNumberOfBags = (order) => {
  const {
    number_of_bags_isotherm,
    number_of_bags_frozen,
    number_of_bags,
    pseudo_zones_status,
  } = order;

  const orderNbOfBagsIsotherm = number_of_bags_isotherm || 0;
  const orderNbOfBagsFrozen = number_of_bags_frozen || 0;

  const orderNbOfBagsDry =
    number_of_bags - number_of_bags_frozen - number_of_bags_isotherm;

  let bagIsoCount = orderNbOfBagsIsotherm;
  let bagDryCount = orderNbOfBagsDry;
  let bagFrozenCount = orderNbOfBagsFrozen;
  let extraPlaces = [];

  if (pseudo_zones_status && pseudo_zones_status.length) {
    let [isoBagsOpz, frozenBagsOpz] = pseudo_zones_status.reduce(
      ([isoCount, frozenCount], opz) => {
        if (
          opz.pseudozone &&
          opz.pseudozone.is_active &&
          (opz.is_bag_picking_late || opz.pseudozone.bag_apart)
        ) {
          isoCount +=
            opz.nob_iso && opz.pseudozone.bag_picking_late_label === "FRAIS"
              ? opz.nob_iso
              : 0;
          frozenCount +=
            opz.nob_iso && opz.pseudozone.bag_picking_late_label === "SURG"
              ? opz.nob_iso
              : 0;
          extraPlaces = [
            ...extraPlaces,
            {
              id: opz.id,
              pseudoZone: opz.pseudozone,
              number_of_bags_iso: opz.nob_iso ? opz.nob_iso : 0,
              field_name: "pz_number_of_bag_iso_" + opz.pseudozone_id,
            },
          ];
        }
        return [isoCount, frozenCount];
      },
      [0, 0]
    );

    bagIsoCount += isoBagsOpz;
    bagFrozenCount += frozenBagsOpz;
  }

  const totalBagsCount = bagDryCount + bagIsoCount + bagFrozenCount;

  const getFormattedContent = (showFullInfo = false) => {
    let frozenContent = bagFrozenCount ? `+ ${bagFrozenCount}surg` : "";
    if (showFullInfo) {
      return `${totalBagsCount} sacs = ${bagDryCount}sec + ${bagIsoCount}frais ${frozenContent}`;
    }
    return order.tags && order.tags.includes("1p") && bagFrozenCount
      ? `${bagDryCount}+${bagIsoCount}+${bagFrozenCount}`
      : `${bagDryCount}+${bagIsoCount + bagFrozenCount}`;
  };

  return {
    orderBagsDetail: [
      orderNbOfBagsDry,
      orderNbOfBagsIsotherm,
      orderNbOfBagsFrozen,
    ],
    bagsDetail: [totalBagsCount, bagDryCount, bagIsoCount, bagFrozenCount],
    extraPlaces,
    getFormattedContent,
  };
};
