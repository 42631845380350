

export function currentToggleValue(subState, key, defaultValue=false) {
  if (typeof  subState[key] === "undefined") {
    return defaultValue;
  } else {
    return subState[key];
  }
}


export const PACKING_LATER_LIST_KEY = 'PACKING_LATER_LIST';
export const DELIVERY_TODO_LIST_KEY = 'DELIVERY_TODO_LIST';
export const DELIVERING_LIST_KEY = 'DELIVERING_LIST';
export const PACKING_NOW_LONG_LIST_KEY = 'PACKING_NOW_LONG_LIST';
export const NEXT_LIST_KEY = 'NEXT_LIST';
export const INCOMING_PLANNING_LIST_KEY = 'INCOMING_PLANNING_LIST';
export const PACKING_XD_TO_CHECK_KEY = 'PACKING_XD_TO_CHECK_LIST';
export const PACKING_XD_STAND_BY_KEY = 'PACKING_XD_STAND_BY';
export const EXTRA_COST_DEMAND_LIST_KEY = 'EXTRA_COST_DEMAND_LIST';
export const ALERT_NOTIFICATIONS_SHOW_KEY = 'ALERT_NOTIFICATIONS_SHOW';

export const TOGGLE_KEYS = [
  PACKING_LATER_LIST_KEY,
  NEXT_LIST_KEY,
  DELIVERING_LIST_KEY,
  DELIVERY_TODO_LIST_KEY,
  INCOMING_PLANNING_LIST_KEY,
  PACKING_NOW_LONG_LIST_KEY,
  PACKING_XD_TO_CHECK_KEY,
  PACKING_XD_STAND_BY_KEY,
  EXTRA_COST_DEMAND_LIST_KEY,
  ALERT_NOTIFICATIONS_SHOW_KEY,
];
