import { connect } from "react-redux";
import {currentToggleValue, ALERT_NOTIFICATIONS_SHOW_KEY} from "../../../utils/toggle";
import {SWITCH_TOGGLE} from "../../../actions/switch_toggle";
import { HIDE_ALERT_NOTIFICATIONS, REVIEW_LOG_TROLLEY_NOT_FOUND } from "../../../actions";
import AlertNotifications from "../../../common-comps/AlertNotifications";

function currentHiddenAlerts(subState) {
  if (subState === null || subState === undefined) {
    return [];
  }
  return subState;
}


export default connect(
  (state) => {
    return {
      alertNotifications: state.dispatch.alert_notifications,
      hiddenAlertNotifications: currentHiddenAlerts(state.m.hiddenAlertNotifications),
      showAlertNotifications: currentToggleValue(state.toggle, ALERT_NOTIFICATIONS_SHOW_KEY),
      allowHideAll: false,
    };
  },
  (dispatch) => {
    return {
      toggle: () => dispatch({type: SWITCH_TOGGLE, key: ALERT_NOTIFICATIONS_SHOW_KEY}),
      hideAlertNotifications: (alertNotifications) => dispatch({type: HIDE_ALERT_NOTIFICATIONS, alertNotifications }),
      reviewLogTrolleyNotFound: (payload) => dispatch({type: REVIEW_LOG_TROLLEY_NOT_FOUND, payload }),
    };
  }
)(AlertNotifications);


