import React from "react";
import {connect} from "react-redux";
import {ASSIGN_UPDATE_NEXTCONFIG} from "../actions";


const buttonMapStateToProps = (state, ownProps) => {
  return {
    "field": ownProps.field,
    "value": ownProps.nextConfig[ownProps.field],
    "label": ownProps.label,
    "nextConfig": ownProps.nextConfig
  };
};

const buttonMapDispatchToProps = dispatch => {
  return {
    "onClick":  (nc, field) => {
      let patch = {};
      patch[field] = !nc[field];
      return dispatch({
        type: ASSIGN_UPDATE_NEXTCONFIG,
        id: nc.id,
        patch: patch,
      });
    },
  };
};


export const PrioItemButton = connect(buttonMapStateToProps, buttonMapDispatchToProps)(({field, value, onClick, label, nextConfig}) => {

  let classesSpan = "fa-regular";
  classesSpan += value ? " fa-square-check" : " fa-square";
  let classesButton = "btn btn-sm";
  classesButton += value ? " btn-primary" : " btn-default";

  return <div><button className={classesButton} onClick={(e) => {onClick(nextConfig, field);}}>
    {label} <span className={classesSpan}/>
  </button></div>;
});
