import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';


const ReviewProcessedLateOrderForm = ({
  formValues,
  loading,
  handleValidate,
  handleIgnore
}) => (
  <Formik
    initialValues={formValues}
  >
    {({ values, handleSubmit, handleChange }) => (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Montant à créditer:</Form.Label>
          <Form.Control
            type="number"
            id="credits"
            name="credits"
            onChange={handleChange}
            value={values.credits} min={0} step="0.01"
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="switch"
            label="Recréditer les frais de livraison:"
            id="recredit_shipping_fee"
            name="recredit_shipping_fee"
            onChange={handleChange}
            checked={values.recredit_shipping_fee}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>SMS:</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="sms"
            onChange={handleChange}
            value={values.sms}
          />
        </Form.Group>
        <Row className="justify-content-md-center">
          <Button
            variant="primary"
            size="sm"
            disabled={loading}
            onClick={() => handleValidate(values)}
            className="mx-4"
          >
            Valider
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={loading}
            onClick={() => handleIgnore(values)}
          >
            Ignorer le retard
          </Button>
        </Row>
      </Form>
    )}
  </Formik>
);

ReviewProcessedLateOrderForm.propTypes = {
  formValues: PropTypes.object,
  loading: PropTypes.bool,
  handleValidate: PropTypes.func,
  handleIgnore: PropTypes.func,
};

export default ReviewProcessedLateOrderForm;
