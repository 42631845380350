import React from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Tab, Button, Table } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';


const CrateManagerTabs = ({ order, deleteCrate, addCrate, readOnly }) => {
  const handleAdd = (values, actions) => {
    addCrate(values);
    actions.resetForm();
  };

  return (
    <Tabs defaultActiveKey={1} id="SlotChangeModal" transition={false}>
      <Tab eventKey={1} title="liste">
        <Table size="sm" hover >
          <thead>
            <tr>
              <th>Nom</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {order && order.crates.map(crate => (
              <tr key={crate.code}>
                <td>{crate.code}</td>
                <td>
                  {!readOnly && (
                    <Button
                      variant="danger"
                      size="xs"
                      onClick={() => deleteCrate(crate.code, order.id)}
                    >
                      Supprimer
                    </Button>
                  )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tab>
      <Tab eventKey={2} title="ajouter" disabled={readOnly}>
        <Formik
          initialValues={{ crateCode: '', orderId: order.id }}
          onSubmit={handleAdd}
          validate={values => {
            let errors = {};
            if (!values.crateCode.match(/^\d{2}-\d{2}$/)) {
              errors.crateCode = "Mauvais format. Format accepté: 12-34";
            }
            return errors;
          }
          }
          render={(props) => (
            <React.Fragment>
              <Form onSubmit={props.handleSubmit} className="form-inline my-3">
                <Form.Group>
                  <Form.Label className='mx-2'>Nom:</Form.Label>
                  <Field
                    className="form-control form-control-sm mx-2"
                    type="text"
                    name="crateCode"
                    required
                    placeholder="XX-XX"
                  />
                </Form.Group>
                <Button variant="primary" size="sm" type="submit" disabled={props.isSubmitting}>
                  Ajouter
                </Button>
              </Form>
              <div style={{color: 'red'}}><ErrorMessage name="crateCode" /></div>
            </React.Fragment>
          )}
        />
      </Tab>
    </Tabs>
  );
};

CrateManagerTabs.propTypes = {
  order: PropTypes.object,
  readOnly: PropTypes.bool,
  deleteCrate: PropTypes.func,
  addCrate: PropTypes.func,
};

export default CrateManagerTabs;
