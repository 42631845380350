import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {orderProductRefund, SUPPORT_MODAL_HIDE} from "../actions";
import {rd} from "../../../utils";
import {getRefundProductSubTypeList} from "../../../selectors/refund_modal";


class InternalRefundModal extends React.Component {


  render() {
    const {sub_type_list, modal, hide, validate} = this.props;

    /* Set up defaults values min and max quantity */
    const minQuantity = -modal.refundQuantity;
    const maxQuantity = modal.defaultQuantity - modal.recreditQuantity - modal.refundQuantity;
    modal.defaultQuantity = maxQuantity;

    return <Modal
      show={modal && modal.modalType === 'refund'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="RefundModal"
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        
        <Formik
          initialValues={{
            quantity : modal.defaultQuantity,
            pricePerUnitQuantity : modal.pricePerUnitQuantity,
            orderProductId: modal.orderProductId,
            sub_type_list: sub_type_list,
            minQuantity: minQuantity,
            maxQuantity: maxQuantity
          }}
          onSubmit={(values, actions) => {
            validate(values);
          }}
          render={(props: FormikProps<Values>) => {
            let {values } = props;

            return <form onSubmit={props.handleSubmit} className="form-horizontal">
              <FormGroup as={Row} controlId="formHorizontalEmail">
                <Col as={Form.Label} sm={4}>
                  Quantité à rembourser
                </Col>
                <Col sm={8}>
                  <Field type="number" name="quantity" placeholder="Quantité à rembourser" className="form-control"
                         required autoFocus={true}
                         onChange={e => {
                           /* Manage min and max quantity */
                          let qty = e.target.value;
                          if (qty < values.minQuantity)
                            qty = values.minQuantity;
                          else if (qty > values.maxQuantity)
                            qty = values.maxQuantity;
                          props.setFieldValue("quantity", qty);
                        }} />
                </Col>
              </FormGroup>
              <FormGroup as={Row} controlId="formControlsSelect">
                <Col as={Form.Label} sm={4}>
                  Type de raison
                </Col>
                <Col sm={8}>
                  <Field
                    name="subtype"
                    className="form-control"
                    required="required"
                    component="select"
                  >
                    <option value="">choisir</option>
                    {values.sub_type_list.map(e => (<option key={e.subtype} value={e.subtype}>{e.label}</option>))}
                  </Field>
                </Col>
              </FormGroup>
              <FormGroup as={Row} controlId="formControlsSelect">
                <Col as={Form.Label} sm={4}>
                  Explications
                </Col>
                <Col sm={8}>
                  <Field
                    name="notes"
                    className="form-control"
                    component="textarea"
                  />
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col sm={12} className="offset-sm-5">
                  <button type="submit"
                          className="btn btn-primary">Rembourser {rd(values.pricePerUnitQuantity * values.quantity)} €
                  </button>
                </Col>
              </FormGroup>
            </form>;
          }}
        />
      </Modal.Body>
    </Modal>;

  }
}

export const RefundModal = connect(
  (state) => ({
    "modal" : state.support.modal,
    "sub_type_list": getRefundProductSubTypeList(state),
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (values) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch(orderProductRefund(
          values.quantity,
          values.subtype,
          values.notes || '',
          values.pricePerUnitQuantity,
          values.orderProductId
        ));
      }
    };
  }
)(InternalRefundModal);
