import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Modal, Tab, Tabs} from "react-bootstrap";

import {DISPATCH_MAIN_MODAL_HIDE, DISPATCH_MODAL_DEPOSIT_DRIVERS} from "../../actions/modal";
import {DepositDrivers} from "../Deposit/DepositDrivers";
import {DepositProductsDetail} from "../Deposit/DepositProductsDetail";
import "../../css/DepositModal.css";

class InternalModal extends React.Component {

  render() {
    const {modal, hide} = this.props;

    return <Modal
      show={modal && modal.modalType === DISPATCH_MODAL_DEPOSIT_DRIVERS}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="DepositModalDrivers"
      enforceFocus={false}
    >
      <Modal.Body>
        <h4>Gestion des Consignes</h4>
        <Tabs defaultActiveKey='deposit_driver' id="NavUser" transition={false}>
          <Tab eventKey='deposit_driver' title="Consignes livreurs">
            <DepositDrivers />
          </Tab>
          <Tab eventKey='deposit_product_detail' title="Détail par Produits">
            <DepositProductsDetail />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>;

  }
}

InternalModal.propTypes = {
  modal: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
};

export const DepositModalDrivers = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": DISPATCH_MAIN_MODAL_HIDE});
      },
    };
  }
)(InternalModal);
