import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {Sms} from "./Common";
import {changeStatus} from "../actions";
import {
  getCurrentStatus,
  STATUS_ORDER_PAID,
} from "../utils/status";
import {
  MODAL_SHOW__REPREPARATION_REASON,
  MODAL_HIDE__REPREPARATION_REASON,
} from "../actions/commonModals";


function buildOptions(task) {
  let options = [];
  if (task.isLock) {
    return options;
  }
  if (task.linked_order.status === "received" || task.linked_order.status === "undeliveredclient") {
    options.push({label: "En livraison", status:"shipping"});
  }
  if (task.linked_order.status === "shipping") {
    options.push({label: "Finalisée", status:"prepared"});
    options.push({label: "Livrée", status:"received"});
  }
  return options;
}

const InternalActionMenu = ({task, changeStatus}) => {
  const options = buildOptions(task);

  const popoverClick = (
    <Popover className="menu-actions" title="" id={"task-menu-" + task.id}>
      <Popover.Content>
        <div className="container">
          { options ? <strong>status</strong> : null}
          { options.map(e => (<div className="col-sm-12" key={e.status}><Button variant="primary" size="xs" onClick={() => changeStatus(task, e.status)} >cmd => {e.label}</Button></div>)) }
          <strong>autre</strong>
          <div className="col-sm-12"><Sms task={task}/></div>

        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      transition={false}
      overlay={popoverClick}
      rootClose
    >
      <button style={{marginLeft: "2px", marginRight: "2px" }} className="btn btn-xs btn-default"><i className="fa-solid fa-bars"></i></button>
    </OverlayTrigger>
  );
};

InternalActionMenu.propTypes = {
  task: PropTypes.any.isRequired,
  changeStatus: PropTypes.func,
};


// link
export const DeliveryActionMenu = connect(
  (state) => {
    return {
      repreparationReasons: state.m.metas.repreparation_reasons,
      orderStatuses: state.m.metas.order_statuses,
    };
  },
  (dispatch) => {
    return {
      cancelFunction: () => dispatch(
        {type: MODAL_HIDE__REPREPARATION_REASON}
      ),
      submitPreFunction: (task, newStatus) => (additionalData) => {
        dispatch(
          changeStatus(
            task.linked_order.id,
            newStatus,
            null,
            additionalData,
          )
        );
        dispatch(
          {type: MODAL_HIDE__REPREPARATION_REASON}
        );
      },
      preShowRepreparationReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__REPREPARATION_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            newOrder: false,
          }
        }
      ),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      changeStatus: (
        task,
        newStatus,
      ) => {
        const submitFunction = dispatchProps.submitPreFunction(task, newStatus);
        if(
          newStatus === STATUS_ORDER_PAID
          && getCurrentStatus(task.linked_order) in stateProps.orderStatuses.ORIGIN_STATUSES_FOR_REPREPARATION_REASON
        ){
          dispatchProps.preShowRepreparationReasonModal(dispatchProps.cancelFunction, submitFunction);
        }
        else{
          submitFunction(null);
        }
      },
    };
  },
)(InternalActionMenu);
