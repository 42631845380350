import moment from "moment";

export const NO_CLIENT_RECEPTION_FLAG_ID = 1;
export const CANCELED_NO_SHIPPING_FLAG_ID = 2;
export const WAITING_RESTOCK_FLAG_ID = 3;

export const isRedeliverStillInTime = (order) => {
  moment.locale('fr');
  // const max_redeliver_delay = 100000000;
  const max_redeliver_delay = 12; // hours
  const now = moment();
  const shipped_time_ago = moment.duration(
    now.diff(moment(order.max_shipping_time))
  ).asHours();
  return shipped_time_ago < max_redeliver_delay;
};
