import { createSelector } from 'reselect'
import {TASK_TYPE_PICKING_ZONE} from "../apps/assign/utils";
import {findByid, sortByKey} from "../utils";

const getNextConfigs = state => state.assign.nextConfigs
const getPseudoZones = state => state.m.pseudoZones

export const getSortedFilteredNextConfigs = createSelector(
  [getNextConfigs, getPseudoZones],
  (nextConfigs, pseudoZones) => {
    return nextConfigs.map(e => {
          let disabled = false;

          if (TASK_TYPE_PICKING_ZONE === e.next_task_type) {
            const pz = findByid(pseudoZones, e.external_id);
            if (pz && !pz.is_active) {
              disabled = true;
            }
          }

          return {...e, disabled: disabled};
        })
        .sort(sortByKey('rank'))
  }
)
