import { call, put, takeEvery, select } from 'redux-saga/effects';
import {daUtil} from "../../api";
import {
  LOGS_LOAD, LOGS_LOADED,
  LOGS_PARTIAL_LOAD, LOGS_PARTIAL_LOADED,
  LOGS_PRESENCE_SAVE, LOGS_PRESENCE_SAVED,
  LOGS_PRESENCE_ADD,
  LOGS_EXTRACOST_DELETE,
  LOGS_EXTRACOST_ADD,
  LOGS_DRIVER_LIST_LOAD,
  LOGS_DRIVER_LIST_LOADED,
  LOGS_EXTRA_COST_DEMANDS_LOAD,
  LOGS_EXTRA_COST_DEMANDS_LOADED,
  LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD,
  LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED,
  LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD,
  LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED,
  LOGS_EXTRA_COST_DEMANDS_REVIEW,
  LOGS_EXTRA_COST_ADD_FROM_DEMAND,
} from "./actions";
import Notifications from 'react-notification-system-redux';
import moment from "moment";
import {doExtraCostAdd, doReviewExtraCostDemand} from "../../utils/extraCost";


function* load(action) {
  const state = yield select();
  const queryString = Object.keys(state.logs.filters)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(state.logs.filters[k])}`)
    .join('&');
  const res = yield call(daUtil.get, `/driver-logs/?${queryString}`);
  yield put({type: LOGS_LOADED, data: res});
}

/* On charge les logs sans changer le state.logs.filters 
 * ex : on l'utilise pour recharger les logs d'un driver en particulier
 * après avoir ajouter un log de présence pour ne pas avoir à tout
 * recharger
 */
function* partialLoad(action) {
  const queryString = Object.keys(action.filters)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(action.filters[k])}`)
    .join('&');
  const res = yield call(daUtil.get, `/driver-logs/?${queryString}`);
  yield put({type: LOGS_PARTIAL_LOADED, data: res});
}

function* presenceSave(action) {
  const d = {
    started_at: action.started_at,
    ended_at:action.ended_at
  };
  const res = yield call(daUtil.patch, `/driver-logs/${action.id}/`, d);
  yield put({type: LOGS_PRESENCE_SAVED, presence: res});
  yield put(Notifications.success({title: "Log" , "message": "Le log de présence a été mis à jour avec succès",  autoDismiss:3, dismissible:"click"}));
}

function* presenceAdd(action) {
  const datas = {
    started_at: action.presencelog.started_at,
    ended_at: action.presencelog.ended_at,
    manual_edit_start: true,
    manual_edit_end: true,
    driver: action.presencelog.driver_id
  };
  
  const state = yield(select());
  try {
    yield call(daUtil.post, `/presence-logs/`, datas);
    yield put(Notifications.success({title: "Log" , "message": "Le log de présence a été ajouté avec succès",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Log" , "message": "Une erreur empêche la création du log de présence (" + error.message + ")",  autoDismiss:30, dismissible:"click"}));
  }
  const show_newline = !(
    state.logs.filters.date !== moment(datas.started_at).format('YYYY-MM-DD')
    || (state.logs.filters.driver_id && parseInt(state.logs.filters.driver_id, 10) !== parseInt(datas.driver, 10))
  );
  if (show_newline === true) {
    yield put({type: LOGS_PARTIAL_LOAD, filters: {"date": state.logs.filters.date, "driver_id": datas.driver}});
  }
}

/* permet de charger la liste de tous les livreurs lorsqu'on en a besoin */
function* loadDriverList() {
  const state = yield(select());
  if (state.logs.driver_list.length === 0) {
    const res = yield call(daUtil.get, `/drivers/`);
    yield put({type: LOGS_DRIVER_LIST_LOADED, driver_list: res});
  }
}

function* extracostAdd(action) {
  
  const datas = action.extracost;
  const state = yield(select());
  yield* doExtraCostAdd(datas);
  const show_newline = !(
    state.logs.filters.date !== datas.day
    || (state.logs.filters.driver_id && parseInt(state.logs.filters.driver_id, 10) !== parseInt(datas.driver, 10))
  );
  if (show_newline === true) {
    yield put({type: LOGS_PARTIAL_LOAD, filters: {"date": state.logs.filters.date, "driver_id": datas.driver}});
  }
}

function* extracostDelete(action) {
  const state = yield(select());
  try {
    yield call(daUtil.req, "delete", `/driver-extra-costs/${action.id}/`, {});
    if (state.logs.filters.date && state.logs.filters.driver_id) {
      yield put({type: LOGS_PARTIAL_LOAD, filters: {
        "date": state.logs.filters.date,
        "driver_id": state.logs.filters.driver_id,
      }});
    } else {
      yield put({type: LOGS_LOAD});
    }
    yield put(Notifications.success({title: "Log" , "message": "Le surcoût a été supprimé avec succès",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Log" , "message": "Une erreur empêche la suppression du surcoût (" + error.message + ")",  autoDismiss:30, dismissible:"click"}));
  }
}

function* loadExtraCostDemands(action) {
  const queryString = Object.keys(action.filters)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(action.filters[k])}`)
    .join('&');
  const res = yield call(daUtil.get, `/driver-extra-cost-demands/?${queryString}`);
  yield put({...res, type: LOGS_EXTRA_COST_DEMANDS_LOADED});
}

function* loadExtraCostDemandsDrivers() {
  const res = yield call(daUtil.get, '/drivers/?has_created_extra_cost_demand=true');
  yield put({results: res, type: LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED});
}

function* loadExtraCostDemandsReviewers() {
  const res = yield call(daUtil.get, '/users/?has_reviewed_extra_cost_demand=true');
  yield put({...res, type: LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED});
}

function* reviewExtraCostDemand(action) {
  const state = yield(select());
  yield* doReviewExtraCostDemand(action.demandId)
  yield put({type: LOGS_EXTRA_COST_DEMANDS_LOAD, filters: state.logs.demands.filters});
  yield put({type: LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD});
  yield put({type: LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD});
}

function* extracostAddFromDemand(action) {
  const datas = action.extracost;
  const state = yield(select());
  yield* doExtraCostAdd(datas);
  yield put({type: LOGS_EXTRA_COST_DEMANDS_LOAD, filters: state.logs.demands.filters});
  yield put({type: LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD});
  yield put({type: LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD});
}

export const logsSagas = [
  takeEvery(LOGS_LOAD, load),
  takeEvery(LOGS_PARTIAL_LOAD, partialLoad),
  takeEvery(LOGS_PRESENCE_SAVE, presenceSave),
  takeEvery(LOGS_PRESENCE_ADD, presenceAdd),
  takeEvery(LOGS_DRIVER_LIST_LOAD, loadDriverList),
  takeEvery(LOGS_EXTRACOST_DELETE, extracostDelete),
  takeEvery(LOGS_EXTRACOST_ADD, extracostAdd),
  takeEvery(LOGS_EXTRA_COST_DEMANDS_LOAD, loadExtraCostDemands),
  takeEvery(LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD, loadExtraCostDemandsDrivers),
  takeEvery(LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD, loadExtraCostDemandsReviewers),
  takeEvery(LOGS_EXTRA_COST_DEMANDS_REVIEW, reviewExtraCostDemand),
  takeEvery(LOGS_EXTRA_COST_ADD_FROM_DEMAND, extracostAddFromDemand),
];
