import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import { api } from '../../../../api';
import { DISPATCH_MAIN_MODAL_HIDE } from '../../actions/modal';
import { lateAction } from '../../actions/deliveries';
import { ignoreLateOrder, validateLateOrder } from '../../actions/deliveries';
import ReviewProcessedLateOrderForm from './ReviewProcessedLateOrderForm';
import ReviewLateOrderForm from './ReviewLateOrderForm';


const MODAL_TYPE = 'review_late_order';

const ReviewLateOrderModal = ({
  modal,
  onHide,
  lateOrders,
  ignoreLateOrder,
  validateLateOrder,
  submitLateOrder,
}) => {
  const initialState = {
    loading: true,
    hasRelatedLateOrder: false,
    formValues: {},
  };

  const [modalState, setModalState] = useState({ ...initialState });

  useEffect(() => {
    if (modal && modal.modalType === MODAL_TYPE) {
      const { linked_order: order } = task;
      const relatedLateOrder = lateOrders[order.id];

      const fetchSmsContent = async (orderId, smsTemplateId, creditsAmount) => {
        const { sms_content: smsContent } = await api.getLateSmsContent(
          orderId, smsTemplateId, creditsAmount
        );
        setModalState({
          ...modalState,
          loading: false,
          hasRelatedLateOrder: true,
          formValues: {
            credits: relatedLateOrder.credit,
            recredit_shipping_fee: relatedLateOrder.recredit_shipping_fee,
            sms: smsContent || '', 
          }
        });
      }

      relatedLateOrder
        ? relatedLateOrder.sms_template_id
          ? fetchSmsContent(order.id, relatedLateOrder.sms_template_id, relatedLateOrder.credit)
          : setModalState({
            ...modalState,
            hasRelatedLateOrder: true,
            credits: relatedLateOrder.credit,
            recredit_shipping_fee: relatedLateOrder.recredit_shipping_fee,
            sms: '', 
            loading: false
          })
        : setModalState({
          ...modalState,
          loading: false,
          hasRelatedLateOrder: false,
          formValues: {
            credits: 0,
          }
        })
    }
    return () => setModalState({ ...initialState });
  }, [modal.modalType, modal.order]);

  if (!modal || !modal.task) {
    return null;
  }

  const { modalType, task } = modal;
  const { linked_order: order } = task;

  const getEstimatedSlotStr = (eta) => {
    if (!eta) {
      return false;
    }
    const estimatedStartSlot = new Date(moment(eta).subtract(5, 'm').format());
    const estimatedEndSlot = new Date(moment(eta).add(15, 'm').format());
    return (
      `entre ${estimatedStartSlot.getHours()}:${estimatedStartSlot.getMinutes()} ` +
      `et ${estimatedEndSlot.getHours()}:${estimatedEndSlot.getMinutes()}`
    )
  };

  const handleValidate = values => {
    const cleanedValues = { ...values, sms: values.sms.trim() };
    validateLateOrder(order.id, cleanedValues)
  }

  const handleIgnore = values => {
    ignoreLateOrder(order.id)
  }

  const handleLateSmsSubmit = values => {
    let estimatedSlot;
    if (values.delay) {
      estimatedSlot = `dans environ ${values.delay} min maximum`;
    } else {
      estimatedSlot = getEstimatedSlotStr(task.eta);
    }

    const startMessage = `Bonjour, votre commande devrait arriver ${estimatedSlot ? estimatedSlot : 'avec du retard'}.`;
    const fixedCredits = values.credits.toFixed(2);

    const endMessage = values.credits > 0
      ? `Toutes nos excuses pour le retard, votre compte est crédité de ${fixedCredits} euros.`
      : "Toutes nos excuses pour le retard."

    const message = `${startMessage} ${endMessage}`;
    submitLateOrder(task, fixedCredits, message);
  }

  return (
    <Modal
      show={modalType === MODAL_TYPE}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>Commande #{order.random_id} en retard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalState.loading ? (
          <div>Chargement...</div>
        ) : modalState.hasRelatedLateOrder 
          ? (
            <ReviewProcessedLateOrderForm
              handleIgnore={handleIgnore}
              handleValidate={handleValidate}
              loading={modalState.loading}
              formValues={modalState.formValues}
            />
          ) : (
            <ReviewLateOrderForm
              handleSubmit={handleLateSmsSubmit}
              loading={modalState.loading}
              formValues={modalState.formValues}
              estimatedSlot={getEstimatedSlotStr(task.eta)}
            />
          )
      }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide} >Fermer</Button>
      </Modal.Footer>
    </Modal>
  );
};

ReviewLateOrderModal.propTypes = {
  order: PropTypes.object,
  modal: PropTypes.object,
  onHide: PropTypes.func,
  ignoreLateOrder: PropTypes.func,
  validateLateOrder: PropTypes.func,
  submitLateOrder: PropTypes.func,
};

export default connect(
  state => ({
    modal: state.dispatch.modal,
    lateOrders: state.dispatch.late_orders,
  }),
  dispatch => ({
    onHide: () => dispatch({ type: DISPATCH_MAIN_MODAL_HIDE }),
    ignoreLateOrder: orderId => dispatch(ignoreLateOrder(orderId)),
    validateLateOrder: (orderId, data) => dispatch(validateLateOrder(orderId, data)),
    submitLateOrder: (task, credits, message) => dispatch(lateAction(task, credits, message)),
  })
)(ReviewLateOrderModal);
