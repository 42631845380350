import React from 'react';
import {connect} from "react-redux";
import {filterToggle} from "../../actions/dispatchFilter";
import {Button} from "react-bootstrap";
import PropTypes from "prop-types";
// import {SUB_DISPATCH_CODE_LIGHT_LOADING_1} from "../../sub_dispatchs";

const Internal = (
  {
    filter,
    departments,
    idf_departments,
    non_idf_departments,
    subScreen,
    filterToggle,
  }
) => {

  let listFilter = [
    ...idf_departments.map(
      (department) => { return {value: department.code, type: "department"}; }
    ).sort((a,b) => a.value.localeCompare(b.value)),
    {value: "Non-IDF", type: "non-idf"},
    {value: "now", type: "now"},
  ];
  // if(subScreen === SUB_DISPATCH_CODE_LIGHT_LOADING_1){
  listFilter = [{value: "now", type: "now"}];
  // }


  for (let f of listFilter) {
    if (f.value === filter.value && f.type === filter.type) {
      f['active'] = true;
    }
  }

  return (<div className={'ToDispatchFilter'}>
    {listFilter.map((e, key) => {
      return (<Button key={key} size={"xs"} onClick={() => { filterToggle(e.type, e.value);}}>
        {e.value} {e.active ? <i className="fa-solid fa-check"></i> : null}
      </Button>);
    })}

  </div>);
};

Internal.propTypes = {
  filter: PropTypes.object.isRequired,
  filterToggle: PropTypes.func
};

export const ToDispatchFilter = connect(
  (state) => {
    return {
      filter: state.dispatch.dispatchFilter,
      departments: state.dispatch.metas.departments,
      idf_departments: state.dispatch.metas.idf_departments,
      non_idf_departments: state.dispatch.metas.non_idf_departments,
      subScreen: state.dispatch.subScreen,
    };
  },
  (dispatch) => {
    return {
      "filterToggle": (t, v) =>  dispatch(filterToggle(t, v)),
    };
  }
)(Internal);
