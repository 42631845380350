/* eslint-disable no-case-declarations,indent */
import {
  ASSIGN_LOADED_NEXTCONFIG,
  ASSIGN_DROP_NEXTCONFIG,
  ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG,
  ASSIGN_SKIP,
  ASSIGN_UPDATE_NEXTCONFIG,
  ASSIGN_UPDATED_NEXTCONFIG,
  ASSIGN_LOADED_PSEUDOZONE,
  ASSIGN_LOAD_PSEUDOZONE,
  ASSIGN_LOADED_CONFIGS,
  ASSIGN_UPDATE_CONFIGS
} from "./actions";
import {sortByKey} from "../../utils";


const initialState = {
  nextConfigs:[],
  pseudoZones:[],
  configs:[],
  refreshing: false,
};

export const assignReducer = function(state = initialState, action) {

  switch (action.type) {
    case ASSIGN_LOADED_NEXTCONFIG:
      return {...state, nextConfigs: action.response.results, refreshing: false};
    case ASSIGN_LOADED_PSEUDOZONE:
      return {...state, pseudoZones: action.response.results, refreshing: false};
    case ASSIGN_LOADED_CONFIGS:
      return {...state, configs: action.response.results, refreshing: false};

    case ASSIGN_UPDATE_NEXTCONFIG:
      return {
        ...state,
        nextConfigs: state.nextConfigs.map(
          e => e.id === action.id ? {...e, ...action.patch, updating: true} : e
        )
      };

    case ASSIGN_UPDATED_NEXTCONFIG:
      return {
        ...state,
        nextConfigs: state.nextConfigs.map(
          e => e.id === action.response.id ? action.response : e
        )
      };


    case ASSIGN_UPDATE_CONFIGS:
      return {
        ...state,
        configs: state.configs.map(e => ({...e, updating: true}))
      };

    case ASSIGN_DROP_NEXTCONFIG:
      const dropped = state.nextConfigs.find(e => e.id === action.droppedId);
      let sortedWithoutDropped = state.nextConfigs.filter(e => e.id !==  action.droppedId).sort(sortByKey('rank'));

      const targetIndex = sortedWithoutDropped.findIndex(e => e.id === action.targetId);
      console.log("sortedWithoutDropped", sortedWithoutDropped, targetIndex);

      // ajout au bon endroit et copie + maj rank
      let rank = 0;
      sortedWithoutDropped.splice((targetIndex + 1), 0, {...dropped});
      let sortedNextConfig = sortedWithoutDropped.map(e => {
        rank++;
        return {...e, rank:rank, updating: true};
      });

      console.log("sortedNextConfig", sortedNextConfig);

      return {
        ...state,
        nextConfigs: sortedNextConfig
      };
    case ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG:
    case ASSIGN_LOAD_PSEUDOZONE:
    case ASSIGN_SKIP:
    default:
      return state;

  }
};
