import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Raven from "raven-js";

import {cloneAndPluck} from "../../../utils/index";

import { MAIN_MODAL_HIDE } from '../../../actions/modal';
import { ORDER_UPDATED } from '../../../actions';
import {
  DISPATCH_LOAD,
  DISPATCH_CHANGE_STATUS,
  DISPATCH_BULK_CHANGE_STATUS,
  DISPATCH_ADD_EXTRACOST,
  DISPATCH_EXTRA_COST_DEMANDS_REVIEW,
  DISPATCH_ORDER_CALL_STUART,
  DISPATCH_ORDER_STUART_SENDING,
  DISPATCH_DOCK_CALL_STUART,
  DISPATCH_DOCK_STUART_SENDING,
  DISPATCH_ORDER_CALL_UBER,
  DISPATCH_ORDER_UBER_SENDING,
  FIELDS_DELIVERY,
  DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  DISPATCH_METAS_SAVED,
  errorThrown,
  dispatchLoaded,
  loadDispatchError,
} from "../actions";
import { deliveryUpdateSucceeded } from "../actions/deliveries";
import { dockUpdated } from '../actions/dock';

import { api, daUtil } from '../../../api';
import { depositDriverSagas } from "./deposit_driver";
import { dragNDropSagas } from "./drag_n_drop";
import { driverSagas } from "./driver";
import { dockSagas } from "./dock";
import { modalSagas } from "./modal";
import { deliveriesSagas } from './deliveries';
import { _utilUpdateTasks } from "./deliveries";
import Notifications from "react-notification-system-redux";
import {doExtraCostAdd, doReviewExtraCostDemand} from "../../../utils/extraCost";
import {stateToCenterId} from "../../../utils/center";
import {SUB_DISPATCH_CODES, SUB_DISPATCH_CONFIGURATIONS} from "../sub_dispatchs";


function* loadDispatch(action) {
  try {
    let additionalParameters = "";
    if(SUB_DISPATCH_CODES.indexOf(action.sub) !== -1){
      let configuration = SUB_DISPATCH_CONFIGURATIONS[action.sub];
      if(configuration.withExtraCostDemands === false){
        additionalParameters += "&with_extra_cost_demands=0";
      }
      if(configuration.withDockCurrentStatuses){
        additionalParameters += (
          "&with_dock_current_statuses="
          + configuration.withDockCurrentStatuses.join(",")
        );
      }
      if(configuration.withDriverQueue === false){
        additionalParameters += "&with_driver_queue=0";
      }
      if(configuration.withNormalSlotAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_normal_slot_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPExpressAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_express_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPExpressPrioAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_express_prio_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPCourierAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_courier_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPNoonTourAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_noon_tour_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDQPEveningTourAmongDeliveriesToDispatch === false){
        additionalParameters += "&with_dqp_evening_tour_among_deliveries_to_dispatch=0";
      }
      if(configuration.withDriverAmongDispatchedDeliveries === false){
        additionalParameters += "&with_driver_among_dispatched_deliveries=0";
      }
      if(configuration.withNotLoadedDockAmongDispatchedDeliveries === false){
        additionalParameters += "&with_not_loaded_dock_among_dispatched_deliveries=0";
      }
      if(configuration.withDrivers === false){
        additionalParameters += "&with_drivers=0";
      }
    }
    const dispatch = yield call(
      api.loadDispatch,
      action.version,
      action.center_id,
      additionalParameters,
    );
    yield put(dispatchLoaded(dispatch));
  } catch (e) {
    console.log("load dispatch failed")
    yield put(loadDispatchError(action.center_id))
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* changeStatus(action) {
  const orderId = action.orderId;
  const dockId = action.dockId ? action.dockId : null;
  let resTaskDeliveries = [];

  const state = yield select();
  let toUpdateDelivery = state.dispatch.deliveries
    .filter((e) => e.updating === orderId)
    .map(e => cloneAndPluck(e, FIELDS_DELIVERY));

  try {
    // network calls
    yield call(
      api.changeOrderStatus,
      orderId,
      action.newStatus,
      "dispatch",
      action.additionalData,
    );

    if (toUpdateDelivery.length > 0) {
      resTaskDeliveries = yield call(
          api.saveShippingTasks,
          toUpdateDelivery,
          "dispatch",
      );
    }
    // change state
    if (resTaskDeliveries.length > 0) {
      yield put(deliveryUpdateSucceeded(resTaskDeliveries));
    }

    // case update inside dock (special refresh case)
    if (dockId) {
      yield* _utilUpdateTasks((e) => e.dock && e.dock.id === dockId);
    }
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* extracostAdd(action) {
  const datas = action.extracost;
  yield* doExtraCostAdd(datas);
}

function* reviewExtraCostDemand(action) {
  yield* doReviewExtraCostDemand(action.demandId);
}

function* dockCallStuart({ dockId, vehicle }) {
  yield put(Notifications.info({title: "Stuart" , "message": "Appel en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const dock =  yield call(api.callStuartForDock, dockId, vehicle);
    yield put(Notifications.success({title: "Stuart" , "message": "Appel Stuart effectué",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(dock));
    yield put({ type: MAIN_MODAL_HIDE });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put({ type: DISPATCH_DOCK_STUART_SENDING, sending: false });
      yield put(Notifications.error({title: "Stuart" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* orderCallStuart({ orderPatch, vehicle }) {
  yield put(Notifications.info({title: "Stuart" , "message": "Appel en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const { order, dock } =  yield call(api.callStuartForOrder, orderPatch.id, vehicle);
    yield put(Notifications.success({title: "Stuart" , "message": "Appel Stuart effectué",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(dock));
    yield put({ type: ORDER_UPDATED, order });
    yield put({ type: MAIN_MODAL_HIDE });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put({ type: DISPATCH_ORDER_STUART_SENDING, sending: false });
      yield put(Notifications.error({title: "Stuart" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* orderCallUber({ orderPatch }) {
  yield put(Notifications.info({title: "Uber" , "message": "Appel en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const { order, dock } =  yield call(api.callUberForOrder, orderPatch.id);
    yield put(Notifications.success({title: "Uber" , "message": "Appel Uber effectué",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(dock));
    yield put({ type: ORDER_UPDATED, order });
    yield put({ type: MAIN_MODAL_HIDE });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put({ type: DISPATCH_ORDER_UBER_SENDING, sending: false });
      yield put(Notifications.error({title: "Uber" , "message": `Erreur: ${e.response.data.message}`,  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* saveFrichtiExtraDelay(action) {
  const newValue = parseInt(action.value, 10);
  if (isNaN(newValue)) {
    put(
      Notifications.error(
        {
          title: "FRICHTI DQP",
          message: "INVALIDE, Il faut juste mettre le nombre en minutes.",
          autoDismiss: 10,
          dismissible:"click",
        }
      )
    );
    return;
  }

  const frichtiSiteId = 1013;
  const state = yield select();
  yield call(daUtil.post, "/postal_codes/update_center_site_extra_delay/", {
    extra_delay: newValue,
    logistics_center_id: stateToCenterId(state),
    site_id: frichtiSiteId
  });
  yield put({type: DISPATCH_METAS_SAVED});
}

function* saveFrichtiExpressPrioExtraDelay(action) {
  const newValue = parseInt(action.value, 10);
  if (isNaN(newValue)) {
    put(
      Notifications.error(
        {
          title: "FRICHTI DQP",
          message: "INVALIDE, Il faut juste mettre le nombre en minutes.",
          autoDismiss: 10,
          dismissible:"click",
        }
      )
    );
    return;
  }

  const frichtiSiteId = 1013;
  const state = yield select();
  yield call(daUtil.post, "/postal_codes/update_center_site_express_prio_extra_delay/", {
    express_prio_extra_delay: newValue,
    logistics_center_id: stateToCenterId(state),
    site_id: frichtiSiteId,
  });
  yield put({type: DISPATCH_METAS_SAVED});
}

function* saveFrichtiDQPExpressPrioEnabled(action){
  const newValue = action.value === true ? 1 : 0;
  const state = yield select();
  const label = "Frichti DQP Express Prio";
  try {
    yield call(daUtil.post, `/postal_codes/update_center_site_dqp_express_prio_enabled/`, {
      dqp_express_prio_enabled: newValue,
      logistics_center_id: stateToCenterId(state),
      site_id: 1013,
    });
    yield put(
      Notifications.success(
        {
          title: label,
          message: action.value ? `${label} activé` : `${label} désactivé`,
          autoDismiss: 10,
          dismissible:"click",
        }
      )
    );
  } catch (e) {
    if(e.response.status === 400){
      console.log(e.response.data);
      yield put(
        Notifications.error(
          {
            title: label,
            message: e.response.data.errors[0] + " Votre flow n'est sans doute pas à jour.",
            autoDismiss: 10,
            dismissible: "click",
          }
        )
      );
    }
    else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
  yield put({type: DISPATCH_METAS_SAVED});
}

function* bulkChangeStatus(action) {
  const { ordersIds, newStatus, dockId } = action;

  try {
    // network calls
    const { orders } = yield call(api.bulkChangeOrderStatus, { orders_ids: ordersIds, dock_id: dockId, status: newStatus });

    // case update inside dock (special refresh case)
    console.log(orders, dockId)
    if (dockId) {
      yield* _utilUpdateTasks((e) => e.dock && e.dock.id === dockId);
    }
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

export const dispatchSagas = [
  takeEvery(DISPATCH_LOAD, loadDispatch),
  takeEvery(DISPATCH_CHANGE_STATUS, changeStatus),
  takeEvery(DISPATCH_BULK_CHANGE_STATUS, bulkChangeStatus),
  takeEvery(DISPATCH_ADD_EXTRACOST, extracostAdd),
  takeEvery(DISPATCH_EXTRA_COST_DEMANDS_REVIEW, reviewExtraCostDemand),
  takeEvery(DISPATCH_ORDER_CALL_STUART, orderCallStuart),
  takeEvery(DISPATCH_DOCK_CALL_STUART, dockCallStuart),
  takeEvery(DISPATCH_ORDER_CALL_UBER, orderCallUber),
  takeEvery(DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE, saveFrichtiExtraDelay),
  takeEvery(DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE, saveFrichtiExpressPrioExtraDelay),
  takeEvery(DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE, saveFrichtiDQPExpressPrioEnabled),
].concat(depositDriverSagas, dragNDropSagas, driverSagas, dockSagas, modalSagas, deliveriesSagas);
