import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";

import {createTour, dropOnDefault, dropOnTask} from "../../actions/drag_n_drop";
import {cleanRowsSelected, toggleRowSelected} from "../../../../actions/deliveries_todo";
import {getDeliveriesToDispatch} from "../../selectors/deliveries";
import DeliveryRowDnd from "./DeliveryRowDnd";

import "../../css/DeliveriesToDispatch.css";
import {ToDispatchFilter} from "./ToDispatchFilter";

class InternalDeliveriesToDispatch extends Component {

  render() {

    const onDropTask = this.props.onDropTask;
    const selectedTasksIds = this.props.selectedTasksIds;
    const toggleSelect = this.props.toggleSelect;
    const show = this.props.show;
    let tasks = this.props.tasks;
    let transit_areas = this.props.transit_areas;

    return (
      <div className="DeliveriesToDispatch">
        <h4>
          Commandes à dispatcher <span>({tasks.length})</span>
        </h4>
        <ToDispatchFilter />
        {tasks.length === 0
          ? <div className="alert alert-info" style={{ textAlign: 'center'}}>Aucune commande à dispatcher</div>
          :
          <div className="d-flex justify-content-center">
            <span className="btn btn-sm btn-success text-center" onClick={this.props.createTour}>Créer une tournée</span>
          </div>
        }
        {tasks.length === 0
          ? null
          :
          <div style={{maxHeight: "calc(100vh - 115px)", overflowY: "auto"}}>
            <Table bordered size="sm" hover striped>
            <tbody>
              {tasks.map((task, key) => {
                let selected = selectedTasksIds.includes(task.id);
                return <React.Fragment key={key}>
                  <DeliveryRowDnd
                    key={task.id}
                    task={task}
                    transit_areas={transit_areas}
                    onDropTask={onDropTask}
                    show={show}
                    withSelect={true}
                    selected={selected}
                    toggleSelect={toggleSelect}
                    inToDispatch={true}
                  />
                </React.Fragment>;
              })}
            </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}

InternalDeliveriesToDispatch.propTypes = {
  tasks: PropTypes.array.isRequired,
  transit_areas: PropTypes.object.isRequired,
  show: PropTypes.object,
  onDropDefault: PropTypes.func.isRequired,
  onDropTask: PropTypes.func.isRequired,
  onCleanSelected: PropTypes.func.isRequired,
  countDeliveries: PropTypes.any,
  selectedTasksIds: PropTypes.array.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  createTour: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  let props = {
    tasks: getDeliveriesToDispatch(state),
    countDeliveries: state.dispatch.deliveries.length,
    selectedTasksIds: state.deliveriesTodo.selectedTasksIds,
    transit_areas: state.dispatch.transit_areas,
    show: {
      displayMode: "two_lines",
      name: false,
      sms: false,
      status: false,
      late: false,
      driver: false,
      addressFormatting: "compact",
      withSelect: true,
      externalCall: true,
      directDispatch: true,
    }
  };
  return props;
};

const mapDispatchToProps = dispatch => {
  return {
    "onDropTask": (targetTask, droppedTask, droppedDock) => {
      dispatch(dropOnTask(targetTask, droppedTask, droppedDock));
    },
    "onDropDefault": (dock, droppedTask) => {
      dispatch(dropOnDefault(dock, droppedTask));
    },
    "onCleanSelected": () => {
      dispatch(cleanRowsSelected());
    },
    "toggleSelect": (taskId) => dispatch(toggleRowSelected(taskId)),
    createTour: () => dispatch(createTour()),
  };
};

const DeliveriesToDispatch = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalDeliveriesToDispatch);

export default DeliveriesToDispatch;
