import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormGroup} from "react-bootstrap";
import moment from 'moment';

import "../css/DockStuart.css";

//onClick={e => handler(e.target.checked)}

const DockStuart = ({
  dock,
  onCallStuart,
  onCancelStuart,
  onRegularizeStuart,
}) => {

  if (dock.dock_type === 'STUART' && !!dock.external_job.id) {

    const getStatus = () => {

      if (['in_progress', 'finished'].includes(dock.external_job.status) && dock.external_job.cancel_reason) {
        const statusLabel = dock.external_job.status === 'in_progress' ? 'returning' : dock.external_job.status;
        return `${dock.external_job.transport_type} ${statusLabel}:${dock.external_job.cancel_reason}`;
      }

      if (!dock.gone_at && !['expired', 'canceled', 'voided'].includes(dock.external_job.status) && dock.external_job.pickup_at) {
        const pickup_at = moment(dock.external_job.pickup_at).format('LT');
        return `${dock.external_job.transport_type}, pick_at: ${pickup_at}`;
      }

      if (dock.external_job.status === 'canceled') {
        return `${dock.external_job.transport_type} ${dock.external_job.status}:${dock.external_job.cancel_reason}`;
      }

      return `${dock.external_job.transport_type}, ${dock.external_job.status}`;
    };

    const status = getStatus();

    const handleRegularizeStuart = (e, dock) => {
      e.preventDefault();
      onRegularizeStuart(dock);
    };

    const handleCancelStuart = (e, dock) => {
      e.preventDefault();
      onCancelStuart(dock);
    };

    return (
      <div>
        <span className="stuart-running">
          {status}
          {dock.external_job.id
            ? (
              <a
                className="btn btn-xs btn-default"
                href={`${process.env.REACT_APP_STUART}/active?job=${dock.external_job.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                dashboard
              </a>
            ) : null
          }
          {dock.external_job.tracking_url
            ? (
              <a
                className="btn btn-xs btn-info"
                href={dock.external_job.tracking_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                tracking
              </a>
            ) : null
          }
          {(
            !['expired', 'canceled', 'voided'].includes(dock.external_job.status) &&
            !dock.gone_at &&
            !dock.external_job.cancel_reason
          ) ? (
              <button
                className="btn btn-xs btn-warning"
                onClick={(e) => handleCancelStuart(e, dock)}
              >
                annuler
              </button>
            ) : null
          }
          {(
            ['expired', 'canceled', 'voided'].includes(dock.external_job.status)
            || (
              dock.external_job.status === 'finished'
              && dock.external_job.cancel_reason
            )
          ) ? (
              <button
                className="btn btn-xs btn-secondary"
                onClick={(e) => handleRegularizeStuart(e, dock)}>
                Régulariser
              </button>
            ) : null
          }
        </span>

      </div>
    );
  }

  if (dock.dock_type === 'STUART' && !dock.external_job.id && dock.tasks.length > 0) {
    return (
      <FormGroup className="DockStuart">
        <FormControl
          disabled={!!dock.updating}
          as="select"
          value={dock.external_job && dock.external_job.transport_type ? dock.external_job.transport_type : ""}
          onChange={evt => onCallStuart(dock, evt.target.value)} >
          <option key="" value="">choisir</option>
          <option key="bike" value="bike">Bike</option>
          <option key="car" value="car">Car</option>
          <option key="cargobike" value="cargobike">cargobike</option>
          <option key="cargobikexl" value="cargobikexl">cargobikexl</option>
        </FormControl>
      </FormGroup>
    );
  }

  return (<span></span>);
};

DockStuart.propTypes = {
  dock: PropTypes.object.isRequired,
  onCancelStuart: PropTypes.func.isRequired,
  onCallStuart: PropTypes.func,
  onRegularizeStuart: PropTypes.func,
};

export default DockStuart;
