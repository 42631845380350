import {connect} from "react-redux";

import {connectChangeTrolleyLocationModalAction} from "../../../../utils/trolleys";
import ChangeTrolleyLocationModalContent from "../../../../components/modal/ChangeTrolleyLocationModalContent";


export const ChangeTrolleyLocationModal = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
    "trolley_logs": state.dispatch.trolley_logs,
    "trolleys": state.dispatch.metas.trolleys,
    "trolley_locations": state.dispatch.metas.trolley_locations,
  }),
  (dispatch) => connectChangeTrolleyLocationModalAction(dispatch)
)(ChangeTrolleyLocationModalContent);
