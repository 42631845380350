import { createSelector } from 'reselect'


const getHiddenOrders = state => state.m.hidden_orders

export const getHiddenOrdersInError = createSelector(
  [getHiddenOrders],
  (orders) => {
      return orders.filter(o => o.error);
  }
)
