import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import "../../../css/OrderProducts.css";
import moment from "moment/moment";
import {SUPPORT_MODAL_HIDE_ORDER_REPREPARE} from "../../../actions";

import { ReprepareOrder } from "./FormikReprepareForm";

class InternalReprepareModal extends React.Component {
  render() {
    const {modal, hide} = this.props;
    moment.locale('fr');

    // Init checkbox checked at false or old state and disable if out of stock
    if (modal.order && modal.order.products){
      Array.prototype.forEach.call(modal.order.products, product => {
        let product_detail = modal.product_details.find(p => p.product === product.product.id);

        // true if api can't find product detail for order center
        product.not_found = !product_detail;

        if (product_detail && product_detail.unlimited_stocks === true) {
          product.stock_quantity = 'illimité';
          product.out_of_stock = false;
        } else if (product_detail) {
          product.stock_quantity = product_detail.real_stock_quantity < 0 ? 0 : product_detail.real_stock_quantity;
          product.out_of_stock = product.quantity > product.stock_quantity;
        } else {
          // product_detail not found
          product.stock_quantity = 0;
          product.out_of_stock = true;
        }
        product.checked = (product.checked || false) && !product.out_of_stock;
      });
    }

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      dialogClassName="duplicate-order-dialog"
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        <ReprepareOrder order={modal.order} />
      </Modal.Body>
    </Modal>;
  }
}

InternalReprepareModal.propTypes = {
  "modal": PropTypes.object,
  "hide": PropTypes.func,
};

export const ReprepareModal = connect(
  (state) => ({
    "modal" : state.support.modalReprepare,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_ORDER_REPREPARE});
      }
    };
  }
)(InternalReprepareModal);
