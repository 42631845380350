import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {currentToggleValue, TOGGLE_KEYS} from "../utils/toggle";

let initialState = {};

for (const k of TOGGLE_KEYS) {
  const locStorValue = localStorage.getItem(k);
  initialState[k] = locStorValue !== '0';
}

export const switchToggleReducer = (state = initialState, action) => {
  switch (action.type) {
  case SWITCH_TOGGLE:
    let ns = {...state};
    ns[action.key] = !currentToggleValue(state, action.key);

    return ns;
  default:
    return state;
  }
};

