import { createSelector } from 'reselect';

import {computeDockList, dockLoadingSort, dockMovingSort} from "../components/common-comps/dock";
import {
  DOCK_TYPE_HARD_ASSIGNED,
  STATUS_DRAFT,
  STATUS_MOVING,
  STATUS_READY_TO_LOAD,
  STATUS_LOADING,
  STATUS_LOADED,
} from "../utils/dock_n_driver";
import {getTomorrowDate} from "../utils/time";
import moment from "moment";
import {filterAndSortDockTasks} from "../utils/task";


const getTransitAreas = state => state.dispatch.transit_areas;
const getDeliveriesBrut = state => state.dispatch.deliveries;
const getDeliveries = createSelector(
  [getDeliveriesBrut, getTransitAreas],
  (deliveries, transitAreas) => {
    return deliveries
      .map((t) => {
        const order = t.linked_order;
        let newTask = {...t, linked_order: {...order, transit_area_object: transitAreas[order.transit_area_current]}};

        return newTask;
      });
  }
);

const getDocks = state => state.dispatch.docks;
const getDrivers = state => state.dispatch.drivers;
const getDepartureFilter = state => state.dispatch.filters.dockDeparture;
const getPostalCodesFilter = state => state.dispatch.filters.postalCodesArray;


/**
 * Retourne un selector avec les docs filtrés
 * selon les critères choisis par l'utilisateur de l'app
 *
 * @return docks[]
 */
export const getFilteredDocks = createSelector(
  [
      getDocks,
      getDepartureFilter,
      getPostalCodesFilter,
      getDeliveries,
  ],
  (docks, departureFilter, postalCodesFilter, deliveries) => {
    if (departureFilter !== 0) {
      const departureTime = dock => (
        dock.max_expected_start_date
          ? moment(dock.max_expected_start_date)
          : moment().add(1, 'days')
      );

      const hoursBeforeDeparture = dock => (
        departureTime(dock).diff(
          moment(), 'hours', true
        )
      );

      docks = docks.filter(dock => (
        hoursBeforeDeparture(dock) <= departureFilter
      ));
    }

    // Si nécessaire, on filtre les codes postaux sélectionnés
    if (postalCodesFilter.length > 0) {
      docks = docks.filter(dock => {
        let tasks = filterAndSortDockTasks(deliveries, dock.id);
        for (let postalCode of postalCodesFilter) {
          for (let task of tasks) {
            if (task.address && task.address.postal_code && task.address.postal_code.startsWith(postalCode)) {
              return true;
            }
          }
        }
        return tasks.length === 0; // Pas de tâche, on garde le dock.
      });
    }
    return docks;
  }
);

export const getComputedDockListDraftToday = createSelector(
  /* Dock en statut "draft" et sans livreur */
  [getDeliveries, getFilteredDocks, getDrivers],
  (deliveries, docks, drivers) => {
    let dock_list = docks.filter(
      d => d.current_status === STATUS_DRAFT && d.dock_type !== DOCK_TYPE_HARD_ASSIGNED
    );
    docks = computeDockList(dock_list, deliveries, docks, drivers);

    const tomorrow = getTomorrowDate(4);
    return docks.filter(d => d.tasks.length === 0 || d.tasks.filter(t => new Date(t.linked_order.shipping_time) < tomorrow).length > 0);
  }
);

export const getComputedDockListDraftTomorrow = createSelector(
  /* Dock en statut "draft" et sans livreur */
  [getDeliveries, getFilteredDocks, getDrivers],
  (deliveries, docks, drivers) => {
    let dock_list = docks.filter(
      d => d.current_status === STATUS_DRAFT && d.dock_type !== DOCK_TYPE_HARD_ASSIGNED
    );
    docks = computeDockList(dock_list, deliveries, docks, drivers);
    const tomorrow = getTomorrowDate(4);
    return docks.filter(d => d.tasks.length > 0 && d.tasks.filter(t => new Date(t.linked_order.shipping_time) > tomorrow).length === d.tasks.length);
  }
);


export const getComputedDockListMoving = createSelector(
  /* Uniquement les Dock en status "moving" */
  [getDeliveries, getFilteredDocks, getDrivers],
  (deliveries, docks, drivers) => {
    let dock_list = docks.filter(d => d.current_status === STATUS_MOVING);
    return  computeDockList(dock_list, deliveries, docks, drivers, dockMovingSort);
  }
);

export const getComputedDockListReadyToLoad = createSelector(
  /* Dock en statut "ready_to_load" + les docks de livreurs  qui ne sont pas en livraison */
  [getDeliveries, getFilteredDocks, getDrivers],
  (deliveries, docks, drivers) => {
    let valid_status = [STATUS_READY_TO_LOAD, STATUS_LOADING];
    let driver_valid_status = [STATUS_DRAFT];
    let dock_list = docks.filter(d =>
      (valid_status.includes(d.current_status))
      || (d.dock_type === DOCK_TYPE_HARD_ASSIGNED && driver_valid_status.includes(d.current_status))
    ).filter(d => !d.target_name.startsWith('Transfert Bam'));
    return computeDockList(dock_list, deliveries, docks, drivers, dockLoadingSort);
  }
);

export const getComputedDockListShipping = createSelector(
  /* Dock en statut "loaded" qui sont en cours de livraison */
  [getDeliveries, getFilteredDocks, getDrivers],
  (deliveries, docks, drivers) => {
    let dock_list = docks.filter(d => d.current_status === STATUS_LOADED);
    return computeDockList(dock_list, deliveries, docks, drivers);
  }
);
