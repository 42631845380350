import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { showRedeliverOrRestock } from '../actions/modal';

const CanceledNoShippingActionButton = ({ task, children, dispatch }) => {
  return (
    <Button
      size="xs"
      onClick={() => dispatch(showRedeliverOrRestock(task))}
    >
      {children}
    </Button>
  );
};

CanceledNoShippingActionButton.propTypes = {
  task: PropTypes.object,
  children: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(() => ({}))(CanceledNoShippingActionButton);
