
export const DRIVER_STATEMENT_TYPE_ISOBAG = 1;

export const PAY_MODE_LABEL_BY_LOG_PAY_MODE = {
  performance: 'Perf',
  presence: 'Temps',
  by_order: 'A la commande',
  by_day: 'Forfait jour',
  perf_and_waiting: 'Perf. et attente',
};
