import { createSelector } from 'reselect';
import { maxShippingSorting } from "../utils/task";


const getTasks = state =>  state.m.tasks_stand_by;

export const getSortedTasks = createSelector(
  [getTasks],
  (tasks) => {
    return tasks.slice().sort(maxShippingSorting);
  }
);
