/* eslint-disable react/prop-types */
import React from "react";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";

import "../css/Nexts.css";
import {NextTaskForm} from "./NextTaskForm";
import {UI_SORT, UI_TOGGLE} from "../actions/ui";
import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {currentToggleValue, NEXT_LIST_KEY} from "../utils/toggle";
import {getSortedFilteredNexts} from "../selectors/nexts";



// component
class InternalAssign extends React.Component {

  render() {
    return (
      <div className="Assign row">
        <div className="col-md-12">
          <h4 >
            Préparateurs et types de tâches
            {this.props.onToggle ? <button className="btn btn btn-default btn-xs float-right" onClick={(e) => this.props.onToggle()}  >{this.props.showAll ? "Cacher" : "Afficher"}</button> : null}
          </h4>
          {this.props.showAll || !this.props.onToggle ? <div>
            <Table bordered size="sm" hover striped>
              <thead>
                <tr>
                  <th className={'sortable ' + (this.props.currentSort === 'name' ? 'sort-current' : "")} onClick={() => this.props.persistantSort('name')}>
                    Nom
                  </th>
                  <th>Droits</th>
                  <th className={'sortable ' + (this.props.currentSort === 'lastStartedTask' ? 'sort-current' : "")} colSpan={2} onClick={() => this.props.persistantSort('lastStartedTask')}>
                    Dernière tâche  / tâche en cours
                  </th>
                  <th>Tâche suivante</th>
                </tr>
              </thead>
              <tbody>
                {this.props.nexts.map(p => <NextTaskForm nextTask={p} key={p.id} iph_by_packer={this.props.iph_by_packer} cph_by_packer={this.props.cph_by_packer} tad_by_packer={this.props.tad_by_packer} tap_by_packer={this.props.tap_by_packer} />)}
                <tr>
                  <td colSpan={5} style={{textAlign: "center"}}>
                    <Button variant="default" size="xs"  onClick={() => this.props.showMoreToggle()} >Afficher / Cacher les "hors session"</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div> : null}
        </div>
      </div>
    );
  }
}

const SHOW_MORE_KEY  = 'next_show_more';

// link
export const Nexts = connect(
  (state) => {
    let props = {
      nexts: getSortedFilteredNexts(state),
      iph_by_packer: {},
      cph_by_packer: {},
      tad_by_packer: {},
      tap_by_packer: {}
    };
    if (state.m.packerstatiph && typeof(state.m.packerstatiph.iph_by_packer) !== 'undefined') {
      props.iph_by_packer = state.m.packerstatiph.iph_by_packer;
    }
    if (state.m.packerstatstockpilling && typeof(state.m.packerstatstockpilling.cph_by_packer) !== 'undefined') {
      props.cph_by_packer = state.m.packerstatstockpilling.cph_by_packer;
    }
    if (state.m.packerstatsordermoving && typeof(state.m.packerstatsordermoving.by_packer) !== 'undefined') {
      props.tad_by_packer = state.m.packerstatsordermoving.by_packer;
    }
    if (state.m.packerstatsbeforepicking && typeof(state.m.packerstatsbeforepicking.by_packer) !== 'undefined') {
      props.tap_by_packer = state.m.packerstatsbeforepicking.by_packer;
    }
    const currentSort = state.ui.sort['nexts'] || 'name';
    props.showAll = currentToggleValue(state.toggle, NEXT_LIST_KEY);
    props.currentSort = currentSort;
    return props;
  },
  (dispatch) => {
    return {
      "persistantSort": (colKey) => (dispatch({type: UI_SORT, colKey: colKey, colGroup: 'nexts'})),
      "showMoreToggle": () => (dispatch({type: UI_TOGGLE, key: SHOW_MORE_KEY})),
      "onToggle":       () => dispatch({type: SWITCH_TOGGLE, key: NEXT_LIST_KEY}),
    };
  }
)(InternalAssign);
