import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { Badge } from "react-bootstrap";
import RepreparationAlertSmall from "./Alerts/RepreparationAlertSmall";
import TrolleyNotFoundAlert from "./Alerts/TrolleyNotFoundAlert";
import HighTrolleyOccupationRateAlert from "./Alerts/HighTrolleyOccupationRateAlert";
import PackerCommentLogAlert from "./Alerts/PackerCommentLogAlert";


class AlertNotifications extends React.Component {
  render() {
    const {
      alertNotifications,
      occupationRate,
      hiddenAlertNotifications,
      showAlertNotifications,
      toggle,
      hideAlertNotifications,
      reviewLogTrolleyNotFound,
      allowHideAll,
    } = this.props;
    moment.locale("fr");

    //console.log(alerts, hiddenAlertNotifications);
    const  someAlerts = alertNotifications.filter(alert => !hiddenAlertNotifications.some(alert2 => alert2.id === alert.id));

    // let scrollbarWidth = "18px";
    let mainDivStyling = {
      position: "fixed",
      top: "10vh",
      // right: scrollbarWidth,
      right: 0,
      maxHeight: "75vh",
      maxWidth: "96vw",
      display: "grid",
      gridTemplateColumns: "1fr min-content",
    };

    return <div style={mainDivStyling}>
      <div>
        {
          (allowHideAll && showAlertNotifications)
            ? (
              <div style={{width: "100%"}}>
                <button onClick={() => hideAlertNotifications(someAlerts)} className="btn btn-sm btn-default" style={{width: "100%"}}>
                  Vider les notifications
                </button>
              </div>
            ) : null
        }
        {showAlertNotifications && someAlerts.map(a => {
          switch (a.type) {
          case "REPREPARATION":
            return <RepreparationAlertSmall key={"REPREPARATION" + a.id} alert={a} hideAlerts={hideAlertNotifications} />;
          case "TROLLEY_NOT_FOUND":
            return <TrolleyNotFoundAlert key={"TROLLEY_NOT_FOUND" + a.id} alert={a} reviewAlert={reviewLogTrolleyNotFound} />;
          case "PACKER_COMMENT_LOG":
            return <PackerCommentLogAlert key={"PACKER_COMMENT_LOG" + a.id} alert={a} hideAlerts={hideAlertNotifications} />;
          default:
            return null;
          }
        }
        )}
        {occupationRate >= 0.9
          ? <HighTrolleyOccupationRateAlert occupationRate={occupationRate} />
          : null
        }
      </div>
      <div>
        <button onClick={toggle} className="btn btn-sm btn-default">
          {showAlertNotifications
            ? (
              <React.Fragment>
                <div>&gt;</div>
                <div>&gt;</div>
                <div>&gt;</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  <Badge
                    className={someAlerts.length > 0 ? "bg-success text-white" : "bg-light text-dark"}
                  >
                    {someAlerts.length}
                  </Badge>
                </div>
                <div>&lt;</div>
                <div>&lt;</div>
              </React.Fragment>
            )
          }
        </button>
      </div>
    </div>;
  }
}

AlertNotifications.propTypes = {
  alertNotifications: PropTypes.array,
  occupationRate: PropTypes.number,
  hiddenAlertNotifications: PropTypes.array,
  showAlertNotifications: PropTypes.bool,
  allowHideAll: PropTypes.bool,
  toggle: PropTypes.func,
  hideAlertNotifications: PropTypes.func,
  reviewLogTrolleyNotFound: PropTypes.func,
};

export default AlertNotifications;
