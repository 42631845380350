import {filterAndSortDockTasks, getTasksSameTrolleyOnDock} from "../utils/task";
import {findByid} from "../../../utils";

let _exp = {};

_exp.reduceDockSimpleAction = function(action, state) {
  const diff = {updating:action.dock.id};
  return {...state, ...{docks:state.docks.map(e => e.id === action.dock.id ? {...e, ...diff} : e)}};
};

_exp.reduceDockUpdate = function(action, state) {
  //console.log("Reducer Dock reduceDockUpdate", action);

  return Object.assign(
    {},
    state,
    {
      docks: state.docks.map((e) => {
        if (e.id === action.patch.id) {
          return Object.assign({}, e, {
            updating: action.patch.id,
            ...action.patch
          });
        }
        return e;
      })
    }
  );
};

_exp.reduceDockClose = function(action, state) {
  //console.log("Reducer Dock reduceDockClose", action);

  return Object.assign(
    {},
    state,
    {
      docks: state.docks.map((e) => {
        if (e.id === action.dock.id) {
          return Object.assign({}, e, {
            updating: action.dock.id,
            hidden: true,
          });}
        return e;
      }),
      deliveries: state.deliveries.map((task) => {
        if (task.dock && task.dock.id === action.dock.id) {
          return Object.assign({}, task, {
            updating: action.dock.id,
            dock: null
          });
        }
        if (task.dock_target && task.dock_target.id === action.dock.id) {
          return Object.assign({}, task, {
            updating: action.dock.id,
            dock: null
          });
        }
        return task;
      })
    }
  );
};

_exp.reduceDockUpdated = function (action, state) {
  //console.log("Reducer Dock reduceDockUpdated", action);
  return {...state, docks: state.docks.map((d) => {
    if (d.id === action.dock.id) {
      for (var key in action.dock) {
        d[key] = action.dock[key];
      }
      d["updating"] = false;
    }
    let dock = {...d};
    return dock;
  })};
};


_exp.reduceDockDropped = function (action, state) {
  //console.log("Reducer Dock reduceDockDropped", action);

  const srcDockTasks = filterAndSortDockTasks(state.deliveries, action.droppedDockId);
  const destDockTasks = filterAndSortDockTasks(state.deliveries, action.targetDockId);
  const destDock = findByid(state.docks, action.targetDockId);

  let currentRank = 1;
  if (destDockTasks.length > 0) {
    currentRank = destDockTasks[destDockTasks.length - 1].dock_rank + 1;
  }
  let newRankMap = {};
  for (const ta of srcDockTasks) {
    newRankMap[ta.id] = currentRank;
    currentRank++;
  }

  // setter updating sur les 2 docks
  const diffDock = {
    updating: action.targetDockId
  };
  return {
    ...state,
    ...{
      docks: state.docks.map((e) => {
        if (e.id === action.targetDockId || e.id === action.droppedDockId) {
          return {...e, ...diffDock};
        } else {
          return e;
        }
      }),
      deliveries: state.deliveries.map((e) => {
        let temp = {};

        // mettre à jour le dock_id sur toutes les tâches ayant le droppedDockId
        if  (
          (e.dock && e.dock.id === action.droppedDockId)
          || (e.dock_target && e.dock_target.id === action.droppedDockId)
        ) {
          temp.updating = action.targetDockId;
          temp.dock_target = {...destDock};
          temp.dock_rank_target = newRankMap[e.id];
        }

        if (temp.updating) {
          return {...e, ...temp};
        }

        return e;
      }),
    }
  };
};

_exp.reduceDockToggleExtract = function(action, state) {
  //console.log("Reducer Dock reduceDockToggleExtract", action);
  return {...state, docks: state.docks.map((d) => {
    if (d.id === action.dock.id) {
      if (Object.keys(state.docks_extract).includes("" + d.id)) {
        delete state.docks_extract[d.id];
      } else {
        state.docks_extract[d.id] = [];
      }
    }
    let dock = {...d};
    return dock;
  })};

};

_exp.reduceDockCheckTaskToExtract = function(action, state) {
  //console.log("Reducer Dock reduceDockCheckTaskToExtract", action, state);
  return {...state, docks: state.docks.map((d) => {
    if (d.id === action.dock.id) {
      let taskSameTrolley = getTasksSameTrolleyOnDock(state, action.task);
      if (state.docks_extract[d.id].includes(action.task.id)) {
        taskSameTrolley.forEach(task => {
          let idx = state.docks_extract[d.id].indexOf(task.id);
          state.docks_extract[d.id].splice(idx, 1);
        });
      } else {
        taskSameTrolley.forEach(task => {
          state.docks_extract[d.id].push(task.id);
        });
      }
    }
    let dock = {...d};
    return dock;
  })};

};

_exp.reduceDockTaskExtract = function(action, state) {
  //console.log("Reducer Dock reduceDockTaskExtract", action, state);
  return {
    ...state,
    docks: state.docks.map(dock => {
      if (dock.id === action.dock.id) {
        dock.updating = dock.id;
      }
      return dock;
    })
  };
};

_exp.reduceDockTaskExtracted = function(action, state) {
  //console.log("Reducer Dock reduceDockTaskExtracted", action, state);
  let docks = state.docks;
  docks.push(action.new_dock);
  if (Object.keys(state.docks_extract).includes("" + action.dock.id)) {
    delete state.docks_extract[action.dock.id];
  }
  return {
    ...state,
    docks: docks,
    deliveries: state.deliveries.map(task => {
      if (action.task_ids.includes(task.id)) {
        task.dock = action.new_dock.id;
      }
      return task;
    }),
  };
};

_exp.reduceDockSwitchDrivers = function(action, state) {
  //console.log("Reducer Dock reduceDockSwitchDrivers", action, state);
  return {
    ...state,
    docks: state.docks.map(dock => {
      if (
          dock.id === action.fromDockId
          || dock.id === action.toDockId
      ) {
        dock.updating = dock.id;
      }
      return dock;
    })
  };
};

_exp.reduceDockDriversSwitched = function(action, state) {
  //console.log("Reducer Dock reduceDockDriversSwitched", action, state);
  const fromDock = findByid(state.docks, action.fromDockId);
  const toDock = findByid(state.docks, action.toDockId);

  return {
    ...state,
    ...{
      deliveries: state.deliveries.map((e) => {
        let temp = {};

        // échanger les références aux docks
        if (
            (e.dock && e.dock.id === action.fromDockId)
            || (e.dock_target && e.dock_target.id === action.fromDockId)
        ) {
          temp.updating = action.toDockId;
          temp.dock = {...toDock};
          //temp.dock_target = {...toDock};
          //temp.dock_rank_target = newRankMap[e.id]; pas de modif de rang en cas d'échange
        }
        if (
            (e.dock && e.dock.id === action.toDockId)
            || (e.dock_target && e.dock_target.id === action.toDockId)
        ) {
          temp.updating = action.fromDockId;
          temp.dock = {...fromDock};
          //temp.dock_target = {...fromDock};
          //temp.dock_rank_target = newRankMap[e.id]; pas de modif de rang en cas d'échange
        }

        if (temp.updating) {
          return {...e, ...temp};
        }

        return e;
      }),
    }
  };
}

_exp.reduceNewDockAdded = (action, state) => {
  return { ...state, docks: [...state.docks, action.dock] };
};

export default _exp;
