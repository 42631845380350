import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { DISPATCH_DIRECT_DISPATCH } from "../../actions/deliveries";

const DirectDispatchButton = ({ task, dispatch }) => {
  const handleClick = (e) => {
    e.preventDefault();
    dispatch({ type: DISPATCH_DIRECT_DISPATCH, task });
  };

  return (
    <Button variant="warning" size="xs" onClick={handleClick} disabled={task.updating}>
      Dispatch
    </Button>
  );
};

DirectDispatchButton.propTypes = {
  task: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  docks: PropTypes.array,
};

export default connect(
  (state) => ({
    docks: state.dispatch.docks,
    deliveries: state.dispatch.deliveries,
  })
)(DirectDispatchButton);
