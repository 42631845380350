import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadDispatch, loadDispatchError,ignoreDisconnect } from '../actions/index';
import { findByid } from "../../../utils";
import { extractCenterId } from "../../../utils/center";
import { ChangeNumberOfBagsModal } from "./modal/ChangeNumberOfBagsModal";
import { Docks } from "./Docks";
import { Deliveries } from "./Deliveries";
import { TransitAreaModal } from "./modal/TransitAreaModal";
import { ChangeTrolleyLocationModal } from "./modal/ChangeTrolleyLocationModal";
import { ConfirmModal } from "../../support/components/ConfirmModal";
import ReviewLateOrderModal from './modal/ReviewLateOrderModal';
import ReturnBagsDepositModal from './modal/ReturnBagsDepositModal';
import StuartCallModalLinked from './modal/StuartCallModalLinked';
import UberCallModalLinked from './modal/UberCallModalLinked';
import RedeliverOrRestockModal from './modal/RedeliverOrRestockModal';
import * as Raven from "raven-js";
import '../css/Dispatch.css';
import {RouteComparisonModal} from "./modal/RouteComparisonModal";
import AlertNotificationsLinked from './DispatchAlertNotificationsLinked';
import {withRouter} from "react-router-dom";
import {CommonModals} from "../../../common-comps/CommonModals";


let loadingInterval = null;

class InternalDispatch extends React.Component {

  loadIntervalDispatchState() {
    const { loadDispatch, centerId, ignoreDisconnect, location } = this.props;
    const query = new URLSearchParams(location.search);
    const sub = query.get("sub");
    if (loadingInterval !== null) {
      window.clearInterval(loadingInterval);
    }
    loadingInterval = window.setInterval(function() {
      if (document.visibilityState && document.visibilityState === 'hidden') {
        ignoreDisconnect();
        console.log('skipped load dispatch because not visible');
        return;
      }
      if(document.pause_dispatch_load){
        ignoreDisconnect();
        console.log('skipped load dispatch because pause_dispatch_load');
        return;
      }
      loadDispatch(centerId, sub);
    }, 20000);
  }


  componentDidMount() {
    const { loadDispatch, centerId, location } = this.props;
    const query = new URLSearchParams(location.search);
    const sub = query.get("sub");
    loadDispatch(centerId, sub);
    this.loadIntervalDispatchState();
  }

  componentDidUpdate(prevProps) {
    const { centerId } = this.props;
    if (prevProps.centerId !== centerId) {
      this.loadIntervalDispatchState();
    }
  }

  render() {
    const { loading, centers, centerId } = this.props;
    if (loading) {
      return <h1 style={{textAlign: "center", marginTop: "100px", opacity: "0.6"}}>Chargement...</h1>;
    }

    let center = findByid(centers, centerId);
    if (center.is_shadow_groupment_enabled === false) {
      return <h1 style={{textAlign: "center", marginTop: "100px", opacity: "0.6"}}>Le Shadow Groupement est désactivé pour ce centre</h1>;
    }

    /*
     * On défini les 2 colonnes : 
     * - A gauche la liste des commandes à dispatcher (Deliveries)
     * - A droite la liste des Docks (chariots) + livreurs + livraison en cours (Docks)
     */
    return (
      <div className="Dispatch">
        <div className="row">
          <Deliveries />
          <Docks />
        </div>
        <ConfirmModal />
        <ChangeNumberOfBagsModal />
        <TransitAreaModal />
        <ChangeTrolleyLocationModal />
        <ReviewLateOrderModal />
        <ReturnBagsDepositModal />
        <RouteComparisonModal />
        <UberCallModalLinked />
        <StuartCallModalLinked />
        <RedeliverOrRestockModal />
        <CommonModals />
        <AlertNotificationsLinked />
      </div>
    );
  }
}

InternalDispatch.propTypes = {
  centerId: PropTypes.number,
  centers: PropTypes.array,
  loading: PropTypes.bool,
  loadDispatch: PropTypes.func,
  ignoreDisconnect: PropTypes.func,
};


export const Dispatch = withRouter(connect(
  (state) => {
    return {
      loading: state.dispatch.firstLoad,
      centers: state.dispatch.metas.centers,
      centerId: extractCenterId(state.user),
    };
  },
  (dispatch) => {
    return {
      ignoreDisconnect: () =>{
        dispatch(ignoreDisconnect())
      },
      loadDispatch: (centerId, sub) => {
        try {
          dispatch(loadDispatch(centerId, sub))
        } catch (e) {
          Raven.captureException(e)
          console.log("Error while trying to load dispatch", e)
          dispatch(loadDispatchError(centerId))
        }
      },
    };
  }
)(InternalDispatch));
