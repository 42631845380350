import {connect} from "react-redux";
import {InternalRedeliverModal} from "../../../../common-comps/InternalRedeliverModal";
import {connectTransitAreaModalAction} from "../../../../utils/transit_areas";
import {SUPPORT_MODAL_HIDE_ORDER_REDELIVER} from "../../../support/actions";



export const RedeliverModal = connect(
  (state) => {
    return {
      "modal": state.support.modalRedeliver,
      "addresses":state.support.user_addresses,
      "pseudoZones": state.dispatch.pseudoZones,
      "trolleys": state.dispatch.metas.trolleys,
      "transit_areas": state.dispatch.transit_areas,
      "trolley_locations": state.dispatch.metas.trolley_locations,
      "transit_area_types": state.dispatch.metas.transit_area_types,
    };
  },
  (dispatch) => {
    return {
      ...connectTransitAreaModalAction(dispatch),
      "hide": () => dispatch({"type": SUPPORT_MODAL_HIDE_ORDER_REDELIVER}),
    };
  }
)(InternalRedeliverModal);
