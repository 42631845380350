import {connect} from "react-redux";
import PackingList from "../components/PackingList";
import {getSortedPackingWaiting, getTitleLinkMissing} from "../selectors/packing";

const SHOW_OBJECT = {nbBags: true, transitArea: true, nbProducts: false, departure: true};

const mapStateToProps = state => {
  return {
    tasks: getSortedPackingWaiting(state),
    title: " En attente de produits",
    title_link: getTitleLinkMissing(state),
    className: 'waiting-products-list',
    show : SHOW_OBJECT,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // "onDropTask": (targetTaskId, droppedTaskId) => dispatch(dropOnPackingTask(targetTaskId, droppedTaskId)),
    // "onDropDefault": (driverId, droppedTaskId) => dispatch(dropOnPackingDefault(droppedTaskId)),
  };
};

let PackingWaitingListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingList);

export default PackingWaitingListLinked;
