import React from "react";
import {withFormik, Field} from "formik";
import {Col, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isRepreparationReasonCommentValid} from "../utils";

const buttonsSpaceStyle = {
  display: "flex",
  justifyContent: "space-around",
};

const MyForm = props => {
  const {
    values,
    handleSubmit,
    errors,
    repreparationReasons,
    cancelFunction,
  } = props;

  let availableReasons = (
    repreparationReasons
    ? (
      values.newOrder
      ? repreparationReasons.NEW_ORDER_AVAILABLE
      : repreparationReasons.SAME_ORDER_AVAILABLE
    )
    : []
  );
  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup>
        <Field component="select" name="repreparation_reason_id" required={true} className="form-control">
          <option value="">-- Choisir la raison de la repréparation --</option>
          {
            availableReasons
            ? Object.entries(availableReasons).sort(
                // Tri par valeur
                ([,a], [,b]) => a-b
              ).map(
                ([choice_id,], idx) => <option key={idx} value={choice_id}>
                  {repreparationReasons.ID_TO_LABEL[choice_id]}
                </option>
              )
            : <option value="">Loading...</option>
          }
        </Field>
      </FormGroup>
      <FormGroup>
        <Form.Label>Commentaire de raison de repréparation</Form.Label>
        <Field
            component="textarea"
            col="100"
            row="9"
            name="repreparation_reason_comment"
            className="form-control"
        ></Field>
        {
          errors.repreparation_reason_comment
          && <div style={{color: 'red'}}>{errors.repreparation_reason_comment}</div>
        }
      </FormGroup>
      <FormGroup>
        <Col sm={12} style={buttonsSpaceStyle}>
          <button type="button" onClick={() => cancelFunction()} className="btn btn-danger">Retour</button>
          <button type="submit" className="btn btn-success">Valider</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "handleSubmit": PropTypes.any,
};

const InternalRepreparationReasonForm = withFormik({
  handleSubmit(values, { props, setSubmitting }) {
    props.submitFunction(
      {
        repreparation_reason_id: parseInt(values.repreparation_reason_id, 10),
        repreparation_reason_comment: values.repreparation_reason_comment,
      },
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
  validate: (values, props) => {
    const errors = {};
    if (
      values.repreparation_reason_id !== ""
      && !isRepreparationReasonCommentValid(values.repreparation_reason_comment)
      && props.repreparationReasons.MANDATORY_COMMENT[parseInt(values.repreparation_reason_id, 10)]
    ) {
      errors.repreparation_reason_comment = 'Un commentaire de raison de repréparation est requis.';
    }
    return errors;
  },
})(MyForm);

export const RepreparationReasonForm = connect()(InternalRepreparationReasonForm);
