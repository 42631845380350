import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {act_dockCheckTaskToExtract} from "../../../actions/dock";


// eslint-disable-next-line react/prop-types
const Internal = ({dock, task, checkTaskToExtract, docks_extract}) => {

  // Impossible d'extraire si dock livreur
  if (dock.target_driver && (dock.locked_at || dock.gone_at)) {
    return null;
  }

  let dock_extract = null;
  if (Object.keys(docks_extract).includes("" + dock.id)) {
    dock_extract = docks_extract[dock.id];
  }
  return (<span className="DockCheckTaskToExtract">
    <input
      type="checkbox"
      size="xs"
      checked={dock_extract && dock_extract.includes(task.id)}
      disabled={dock.updating}
      onChange={() => checkTaskToExtract(dock, task)} />
  </span>);
};

Internal.propTypes = {
  dock: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  docks_extract: PropTypes.object,
  checkTaskToExtract: PropTypes.func.isRequired,
};

export const CheckTaskToExtract = connect(
  (state) => {
    return {
      docks_extract: state.dispatch.docks_extract,
    };
  },
  (dispatch) => {
    return {
      "checkTaskToExtract": (dock, task) => {
        dispatch(act_dockCheckTaskToExtract(dock, task));
      },
    };
  }
)(Internal);


export default CheckTaskToExtract;
