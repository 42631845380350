import React from 'react';
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";
import PackingRow from "./PackingRow";

import "../css/PackingList.css";


export const PackingLaterList = ({days, show, showTable, toggleTable}) => {

  if (days.length === 0) {
    return (<div><h4>A préparer plus tard : AUCUNE</h4></div>);
  }

  return (
    <div className="PackingList PackingLaterList">
      <h4>
        A préparer plus tard :
        <button className="btn btn btn-default btn-xs float-right" onClick={(e) => toggleTable()}  >{showTable ? "Cacher" : "Afficher"}</button>
      </h4>
      {showTable ? <Table bordered size="sm" hover striped>
        <thead>
          <tr>
            {/*<th>#</th>*/}
            <th>Tâche</th>
            <th>Pr.</th>
            <th>Client</th>
            <th>Adresse</th>
            {/*<th>H. prep.</th>*/}
            <th></th>
            <th>État</th>
            <th></th>
          </tr>
        </thead>
        {
          days.map((d) => (
            <tbody key={d.day}>
              <tr className="Row day-header">
                <td colSpan="7">
                  <a href={d.productLink} className="product-link btn btn btn-default btn-xs float-right" target="_blank" rel="noopener noreferrer" >liste de produits</a>
                  <span className="day-label">{d.label}</span>
                  {d.countOrders ? <span> {d.countOrders} commandes, {d.countItems} items</span>: null}
                  <br/>
                </td>
              </tr>
              {d.tasks.map(t => (<PackingRow key={t.id} task={t} show={show} />))}
            </tbody>
          ))
        }
      </Table> : null}
    </div>
  );
};


PackingLaterList.propTypes = {
  days: PropTypes.array.isRequired,
  toggleTable: PropTypes.func.isRequired,
  showTable: PropTypes.bool.isRequired,
  show: PropTypes.func
};


export default PackingLaterList;
