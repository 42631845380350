export const DOCK_FILTER_DEPARTURE = "DOCK_FILTER_DEPARTURE";
export const filterDockDeparture = (hoursBeforeGoing) => {
  return {
    type: DOCK_FILTER_DEPARTURE,
    payload: hoursBeforeGoing
  };
};

export const POSTAL_CODES_FILTER = "POSTAL_CODES_FILTER";
export const filterPostalCodes = (postalCodes) => {
  return {
    type: POSTAL_CODES_FILTER,
    payload: postalCodes,
  };
};
