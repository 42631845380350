import * as Raven from "raven-js";
import Notifications from "react-notification-system-redux";
import { call, put, takeEvery, select } from 'redux-saga/effects';

import {findByid} from "../../../utils/index";
import { AUTH_ERROR }from "../../../actions/auth";
import { api, daUtil } from "../../../api";
import {
  DISPATCH_DOCK_LOAD,
  DISPATCH_DOCK_CLOSE,
  DISPATCH_DOCK_UPDATE,
  DISPATCH_DOCK_ASSIGN,
  DISPATCH_DOCK_UNLOCK,
  DISPATCH_FINISH_DOCK,
  DISPATCH_DOCK_MANUALLY_OPTIMIZED,
  DISPATCH_DOCK_MANUALLY_VALIDATED,
  DISPATCH_DOCK_DEFINE,
  DISPATCH_DOCK_UNDEFINE,
  DISPATCH_DOCK_TASK_EXTRACT,
  DISPATCH_DOCK_TASK_EXTRACTED,
  DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED,
  DISPATCH_DOCK_SWITCH_DRIVERS,
  DISPATCH_DOCK_READY_TO_LOAD,
  DISPATCH_AUTO_GENERATE,
  DISPATCH_CANCEL_STUART,
  DISPATCH_REGULARIZE_STUART,
  DISPATCH_CANCEL_UBER,
  DISPATCH_REGULARIZE_UBER,
  dockAdd,
  dockUpdated,
  act_dockDriversSwitched,
} from "../actions/dock";

import {
  DISPATCH_ERROR,
  errorThrown,
  _alertOnError,
  DISPATCH_NEEDS_REFRESH,
} from "../actions/index";

import { _utilUpdateTasks } from "./deliveries";


function* dockLoad(action) {
  try {
    let res = yield call(api.loadDock, action.dock_id);
    yield put(dockUpdated(res));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockUpdate(action) {

  if (!action.patch) {
    console.error("dockUpdate, undefined update", action);
  }

  const state = yield select();
  let dock = {...findByid(state.dispatch.docks, action.patch.id)};
  try {
    let res = yield call(api.editDock, action.patch);
    yield put(dockUpdated(res));

    // S'il y a eu une assignation automatique dûe à un changement de config
    // (ex: first scooter), alors pour que ça soit affiché tout de suite sur le flow,
    // on récupère le target_dock_id de la réponse et on met à jour ce target_dock
    // + les tâches que l'on vient de lui attribuer et qui étaient dans le dock
    // qu'on a mis à jour.
    if (typeof(res.target_dock_id) !== "undefined" && res.target_dock_id) {
      yield* _utilUpdateTasks(task => task.dock && dock.id === task.dock.id);
      yield put({type: DISPATCH_DOCK_LOAD, dock_id: res.target_dock_id});
    }
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockAssign(action) {
  try {
    const res = yield call(daUtil.post, `/docks/${action.dock.id}/assign/`, undefined, { timeout: 120000 });
    if (!res.locked_at) {
      yield put(Notifications.error({title: "Action impossible" , "message": "Impossible de lancer la livraison : " + (res.error_message && res.error_message !== "" ? res.error_message : "raison inconnue") + ". Veuillez réessayer ou mettre à jour votre flow",  autoDismiss:10, dismissible:"click"}));
    }
    yield put(dockUpdated(res));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error,  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* dockService(action) {
  try {
    const res = yield call(daUtil.post, `/docks/${action.dock.id}/${action.service}/`);
    yield put(dockUpdated(res));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error,  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }

  // TODO: GERER LES RETOUR 409
}




function* dockUnlock(action) {
  let allTasks = [];
  for(let task of action.dock.tasks){
    if(task.linked_order.canteen_tasks){
      for(let someTask of task.linked_order.canteen_tasks){
        allTasks.push(someTask);
      }
    }
    else{
      allTasks.push(task);
    }
  }
  const taskIdsShouldBe = allTasks.map(t => t.id);
  try {
    const res = yield call(
      daUtil.post, `/docks/${action.dock.id}/unlock/`,
      { task_ids_should_be: taskIdsShouldBe, accepted_by_driver_should_be: action.dock.accepted_by_driver }
    );
    yield put(dockUpdated(res));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* dockClose(action) {

  try {
    let res = yield call(api.closeDock, action.dock);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.updating === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* finishDock(action) {
  try {
    let res = yield call(api.finishDock, action.dock);
    res = yield call(_alertOnError, res);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockManuallyOptimized(action) {
  try {
    let res = yield call(api.dockManuallyOptimized, action.dock);
    res = yield call(_alertOnError, res);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);
    if (typeof(res.target_dock_id) !== "undefined" && res.target_dock_id) {
      yield put({type: DISPATCH_DOCK_LOAD, dock_id: res.target_dock_id});
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockManuallyValidated(action) {
  try {
    let res = yield call(api.dockManuallyValidated, action.dock);
    res = yield call(_alertOnError, res);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);
    if (typeof(res.target_dock_id) !== "undefined" && res.target_dock_id) {
      yield put({type: DISPATCH_DOCK_LOAD, dock_id: res.target_dock_id});
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockTaskExtract(action) {
  let res;
  try {
    res = yield call(
      daUtil.post,
      `/dispatch/docks/${action.dock.id}/extract_tasks/`,
      { task_ids: action.task_ids }
    );

    if (res.error) {
      res = yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }

  } catch (e) {
    if (e.response && (e.response.status === 409 || e.response.status === 400)) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
    return;
  }

  let dock_name = "G" + String(res.new_dock.id % 10000);
  let message = "Les tâches ont été déplacées vers le groupement " + dock_name;
  yield put(Notifications.success({title: "Extraction terminée vers " + dock_name , "message": message, "position": "tc",  autoDismiss:10, dismissible:"click"}));

  yield* _utilUpdateTasks((e) => e.updating);
  yield put(dockUpdated(res));
  yield put({type: DISPATCH_DOCK_TASK_EXTRACTED, dock: res, new_dock: res.new_dock, task_ids: action.task_ids});
}

function* dispatchDocksExtractAllFinalized(action) {
  try {
    yield call(
      daUtil.post,
      `/dispatch/docks/extract_all_finalized/`,
      { logistics_center_id: action.centerId }
    );
  } catch (e) {
  }
  yield put({type: DISPATCH_NEEDS_REFRESH});
}

function* dockSwitchDrivers(action) {
  let res;
  try {
    res = yield call(
      daUtil.post,
      `/dispatch/docks/switch_drivers_orders_docks/`,
      {
        from_dock_id: action.fromDockId,
        to_dock_id: action.toDockId,
        accepted_by_driver_should_be: action.fromDockAcceptedByDriver,
      }
    );

    if (res.error) {
      res = yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }

  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error(
          {
            title: "Action impossible",
            "message": res.error,
            "position": "tc",
            autoDismiss:10,
            dismissible:"click",
          }
      ));
      delete res.error;
      yield put(dockUpdated(res.from_dock));
      yield put(dockUpdated(res.to_dock));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
    return;
  }

  let message = "Les chauffeurs ont bien eu leur tournées échangées.";
  yield put(Notifications.success(
      {
        title: "Échange de tournées",
        "message": message,
        "position": "tc",
        autoDismiss:10,
        dismissible:"click",
      }
  ));

  yield* _utilUpdateTasks((e) => e.updating);
  yield put(dockUpdated(res.from_dock));
  yield put(dockUpdated(res.to_dock));
  yield put(act_dockDriversSwitched(action.fromDockId, action.toDockId));
}

function* processErrors(action) {
  if(action.e.response){
    let response = action.e.response
    if(response.status === 401){
      yield put({type: AUTH_ERROR});
    }
    else if(
      response.status === 400
      && response.data
      && response.data.error
    ){
      yield put(
        Notifications.error(
          {
            title: "Action impossible",
            message: response.data.error,
            autoDismiss: 10,
            dismissible: "click",
          }
        )
      );
    }
    else if(response.data.detail) {
      yield put(
        Notifications.error(
          {
            title: "Une erreur est survenue",
            message: response.data.detail,
            autoDismiss: 5,
            dismissible: "click",
          }
        )
      );
    }
  }
  yield true;
}

function* sendDispatchAutoRequest(action) {
  const {center} = action;
  try {
    yield call(
      daUtil.post,
      '/docks/generate_dispatch_auto/',
      {center}
    );
  } catch (e) {
    Raven.captureException(e);
  }

}

function* dockToReadyToLoad(action) {
  const { dock: fromDock, toVehicle } = action;
  let allTasks = [];
  for(let task of fromDock.tasks){
    if(task.linked_order.canteen_tasks){
      for(let someTask of task.linked_order.canteen_tasks){
        allTasks.push(someTask);
      }
    }
    else{
      allTasks.push(task);
    }
  }
  const taskIdsShouldBe = allTasks.map(t => t.id);
  try {
    const res = yield call(
      daUtil.post,
      `/dispatch/docks/${fromDock.id}/switch_to_ready_to_load/`,
      {
        to_vehicle: toVehicle,
        accepted_by_driver_should_be: fromDock.accepted_by_driver,
        task_ids_should_be: taskIdsShouldBe,
      }
    );
    const {
      dock,
      ready_to_load_dock: readyToLoadDock,
      autotake_dock: autotakeDock,
    } = res;
    yield put(dockUpdated(dock));
    if (autotakeDock) {
      yield put(dockUpdated(autotakeDock));
    }
    if (readyToLoadDock) {
      yield put(dockAdd(readyToLoadDock));
    }
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === dock.id);
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* cancelStuartJob(action) {
  try {
    const res = yield call(api.cancelStuartJob, action.dock);
    yield _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);
    if (res.error) {
      yield put(dockUpdated(action.dock));
      yield put(Notifications.error(
        {
          title: "Stuart" ,
          message: res.error.message,
          autoDismiss: 10,
          dismissible:"click"}
      ));
    } else {
      const pendingDock = {
        ...action.dock,
        external_job: {
          ...action.dock.external_job,
          status: "annulation en cours...",
          pickup_at: null,
        }
      };
      yield put(dockUpdated(pendingDock));
      yield put(Notifications.success(
        {
          title: "Stuart" ,
          message: "Job en cours d'annulation",
          autoDismiss: 10,
          dismissible:"click"}
      ));
    }
  } catch (e) {
    yield put(Notifications.error(
      {
        title: "Stuart" ,
        message: "Une erreur est survenue lors de l'annulation",
        autoDismiss: 10,
        dismissible:"click"}
    ));
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* regularizeStuartDock(action) {
  try {
    const [firstTask] = action.dock.tasks || [];
    let res = yield call(api.regularizeCanceledStuart, action.dock);
    res = yield call(_alertOnError, res);
    const { dock, new_dock: newDock } = res;
    yield put(dockUpdated(dock));
    if (newDock) {
      yield put(dockAdd(newDock));
    } else {
      const orderNumber = firstTask ? firstTask.linked_order.random_id : null;
      const message = orderNumber
        ? `Le groupement de la commande #${orderNumber} a été régularisé`
        : `Le groupement a été régularisé`
      yield put(Notifications.success(
        {
          title: "Groupement régularisé" ,
          "message": message,
          autoDismiss:10,
          dismissible:"click"}
      ));
    }
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* cancelUberJob(action) {
  try {
    const res = yield call(api.cancelUberJob, action.dock);
    yield _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);
    if (res.error) {
      yield put(dockUpdated(action.dock));
      yield put(Notifications.error(
        {
          title: "Uber" ,
          message: res.error.message,
          autoDismiss: 10,
          dismissible:"click"}
      ));
    } else {
      const pendingDock = {
        ...action.dock,
        external_job: {
          ...action.dock.external_job,
          status: "annulation en cours...",
          pickup_at: null,
        }
      };
      yield put(dockUpdated(pendingDock));
      yield put(Notifications.success(
        {
          title: "Uber" ,
          message: "Job en cours d'annulation",
          autoDismiss: 10,
          dismissible:"click"}
      ));
    }
  } catch (e) {
    const message = (e.response && e.response.data && e.response.data.message)
      ? e.response.data.message
      : "Une erreur est survenue lors de l'annulation";

    yield put(Notifications.error(
      {
        title: "Uber" ,
        message,
        autoDismiss: 10,
        dismissible:"click"}
    ));
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* regularizeUberDock(action) {
  try {
    const [firstTask] = action.dock.tasks || [];
    let res = yield call(api.regularizeCanceledUber, action.dock);
    res = yield call(_alertOnError, res);
    const { dock, new_dock: newDock } = res;
    yield put(dockUpdated(dock));
    if (newDock) {
      yield put(dockAdd(newDock));
    } else {
      const orderNumber = firstTask ? firstTask.linked_order.random_id : null;
      const message = orderNumber
        ? `Le groupement de la commande #${orderNumber} a été régularisé`
        : 'Le groupement a été régularisé'
      yield put(Notifications.success(
        {
          title: "Groupement régularisé" ,
          "message": message,
          autoDismiss:10,
          dismissible:"click"}
      ));
    }
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

export const dockSagas = [
  takeEvery(DISPATCH_DOCK_LOAD, dockLoad),
  takeEvery(DISPATCH_DOCK_UPDATE, dockUpdate),
  takeEvery(DISPATCH_DOCK_CLOSE, dockClose),
  takeEvery(DISPATCH_DOCK_ASSIGN, dockAssign),
  takeEvery(DISPATCH_DOCK_UNLOCK, dockUnlock),
  takeEvery([DISPATCH_DOCK_DEFINE, DISPATCH_DOCK_UNDEFINE], dockService),
  takeEvery(DISPATCH_FINISH_DOCK, finishDock),
  takeEvery(DISPATCH_DOCK_MANUALLY_OPTIMIZED, dockManuallyOptimized),
  takeEvery(DISPATCH_DOCK_MANUALLY_VALIDATED, dockManuallyValidated),
  takeEvery(DISPATCH_ERROR, processErrors),
  takeEvery(DISPATCH_DOCK_TASK_EXTRACT, dockTaskExtract),
  takeEvery(DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED, dispatchDocksExtractAllFinalized),
  takeEvery(DISPATCH_DOCK_SWITCH_DRIVERS, dockSwitchDrivers),
  takeEvery(DISPATCH_DOCK_READY_TO_LOAD, dockToReadyToLoad),
  takeEvery(DISPATCH_AUTO_GENERATE, sendDispatchAutoRequest),
  takeEvery(DISPATCH_CANCEL_STUART, cancelStuartJob),
  takeEvery(DISPATCH_REGULARIZE_STUART, regularizeStuartDock),
  takeEvery(DISPATCH_CANCEL_UBER, cancelUberJob),
  takeEvery(DISPATCH_REGULARIZE_UBER, regularizeUberDock),
];
