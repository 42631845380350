import {findByid} from "../../../utils/index";
import {filterAndSortDockTasks, getTasksSameTrolleyOnDock} from "../utils/task";


let task = {};

task.updateSucceeded = function (action, partialState) {
  let index = {};
  for (const t of action.tasks) {
    index[t.id] = t;
  }

  return partialState.map((e) => index.hasOwnProperty(e.id) ? { ...index[e.id], ...{updating: false, dock_updating:false}} : e);
};


task.dropOnDefault = function(action, state) {
  //console.log("REDUCER : Task dropOnDefault");
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));

  const payloadDropOnDriver = {
    "dock_target": null,
    "updating": task.id,
  };

  return {
    ...state,
    deliveries: state.deliveries.map((e) => e.id === task.id ? {...e, ...payloadDropOnDriver} : e),
  };
};


task.dockDropOnDefault  = function(action, state) {
  //console.log("REDUCER : Task dockDropOnDefault");
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  const dock = Object.assign({}, findByid(state.docks, action.dockTarget.id));

  let tasksInFromDock = getTasksSameTrolleyOnDock(state, task);

  let tasksById = {};
  tasksInFromDock.forEach(t => tasksById[t.id] = t);

  let rank = 0;
  return {
    ...state,
    deliveries: state.deliveries.map((t) => {
      // On met à jour la tâche dans les deliveries
      if (typeof(tasksById[t.id]) !== "undefined") {
        rank += 1;
        return {
          ...t,
          ...{
            "dock_rank_target": rank,
            "dock_target": dock,
            "updating": task.id,
            "dock_updating": dock.id,
          }
        };
      }
      return t;
    })

  };
};


task.dockDropOnDeliveryTask = function(action, state) {
  // console.log("REDUCER : Task dockDropOnDeliveryTask", action);

  /*
   * On Drop une task sur une autre task appartenant à un dock
   * On calcule le dock_rank_target = tâche sur laquelle on a droppé + 1
   * On met à jour le statut de la tâche qu'on a déposée (dock_target + dock_rank_target)
   * On met à jour aussi les tâches du dock dont le rank a été modifié
   * On indique au state que le Dock est en cours de mise à jour (updating)
   * Ensuite ça passera vers Sagas.drag_n_drop => pour la mise à jour API
   */
  if (action.droppedTaskId === action.targetTaskId) {
    //console.warn('self drop : skip');
    return state;
  }
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  const targetTask = Object.assign({}, findByid(state.deliveries, action.targetTaskId));
  let targetDock =  action.dockTarget ? action.dockTarget : targetTask.dock_target;

  // on recalcul le rank pour tout le dock de destination
  let tasksInTargetDock = filterAndSortDockTasks(state.deliveries, targetDock.id)
    .filter(t => t.id !== task.id);

  let tasksInFromDock = getTasksSameTrolleyOnDock(state, task);

  const targetIndex = tasksInTargetDock.findIndex(t => t.id === targetTask.id);
  tasksInTargetDock = [
    ...tasksInTargetDock.slice(0, targetIndex),
    ...tasksInFromDock,
    ...tasksInTargetDock.slice(targetIndex)
  ];
  let newRankMap = {};
  let currentRank = 1;
  for (const ta of tasksInTargetDock) {
    newRankMap[ta.id] = currentRank;
    currentRank++;
  }

  const payload = {
    "dock_target" : targetDock,
    "dock_rank_target": newRankMap[task.id],
    "updating": task.id,
    "dock_updating": targetDock.id
  };

  return {
    ...state,
    "deliveries": state.deliveries.map((e) => {
      if (e.id === task.id) {
        return {...e, ...payload};
      }

      // Si on drop la tâche dans un dock où une des tâches est en cours de déplacement
      // vers un autre dock => on ne doit pas modifier cette tâche
      let task_moving = e.dock && e.dock_target && e.dock.id === targetDock.id ? true : false;

      if (e.id in newRankMap && !task_moving) {
        return {
          ...e,
          "dock_target": targetDock,
          "dock_rank_target": newRankMap[e.id],
          "updating": task.id,
          "dock_updating": targetDock.id
        };
      }
      return e;
    }),
    "docks": state.docks.map((d) => {
      if (d.id === targetDock.id) {
        return {...d, "updating": task.id};
      }
      return d;
    })
  };

};


task.dropOnDeliveryTask = function(action, state) {
  //console.log("REDUCER : Task dropOnDeliveryTask");
  if (action.droppedTaskId === action.targetTaskId) {
    //console.warn('self drop : skip');
    return state;
  }

  // see also saga.js
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  // const target = Object.assign({}, findByid(state.deliveries, action.targetTaskId));
  const updating_dock =  task.dock_target;

  const payloadDropOnTask = {
    "dock_target" : null,
    "updating": task.id,
    "dock_updating": updating_dock ? updating_dock.id : false
  };

  // console.log('target', target, 'droppedTask', task, 'dmid', dmid, 'diff', payloadDropOnTask);

  return Object.assign({}, state, {
    deliveries: state.deliveries.map((e) => {
      if  (e.id === task.id) {
        return {...e, ...payloadDropOnTask};
      }
      return e;
    })
  });

};




task.deliveryUpdate = function (action, state) {
  return Object.assign({}, state, {
    deliveries: state.deliveries.map((e) => {
      return action.taskIds.includes(e.id) ? Object.assign({}, e, {updating: action.updating}) : e;
    })
  });
};


task.lateProcessing = function (action, state) {
  const tid = action.task.id;
  return {...state, ...{
    deliveries: state.deliveries.map((e) => {
      return (e.id === tid ? {...e, ...{updating: tid}} : e);
    })
  }};
};

task.cancelDeliveryDrop = function (action, state) {
  const tid = action.task.id;
  return {...state, ...{
    deliveries: state.deliveries.map((e) => {
      return (e.id === tid ? {...e, ...{updating: tid}} : e);
    })
  }};
};

task.lateOrderUpdate = (action, state) => (
  {
    ...state,
    deliveries: state.deliveries.map((e) => (
      e.linked_order.id === action.orderId ? {...e, ...{updating: true}} : e
    ))
  }
);

task.lateOrderReviewed = (action, state) => {
  if (state.late_orders instanceof Object) {
    const newlateOrder = {
      ...state.late_orders[action.orderId],
      reviewed_at: action.reviewedAt,
    };
    return {
      ...state,
      late_orders: {
        ...state.late_orders,
        [action.orderId]: newlateOrder
      }
    };
  }
  return { ...state };
};

task.resetOrderForRedelivery = (action, state) => {
  const { order } = action;
  const relatedTask = state.deliveries_not_delivered.find(t => t.linked_order.id === order.id);
  const updatedTask = {...relatedTask, linked_order: order };
  if (!relatedTask) {
    return { ...state };
  }
  const existInDeliveries = !!state.deliveries.find(t => t.linked_order.id === order.id);
  return {
    ...state,
    deliveries: existInDeliveries
      ? state.deliveries.map(t => t.id === updatedTask.id ? updatedTask : t)
      : [...state.deliveries, updatedTask],
    deliveries_not_delivered: state.deliveries_not_delivered.filter(t => t.id !== updatedTask.id),
  };
};

task.orderToRestock = (action, state) => {
  const { order, newFlag } = action;
  return {
    ...state,
    deliveries_not_delivered: state.deliveries_not_delivered.map(t =>
      t.linked_order.id === order.id
        ? {...t, linked_order: order, flagged_orders: [newFlag]}
        : t
    ),
  };
};

task.validateRestock = (action, state) => {
  const { order } = action;
  return {
    ...state,
    deliveries_not_delivered: state.deliveries_not_delivered.filter(t =>t.linked_order.id !== order.id)
  };
};

task.orderWasDelivered = (action, state) => {
  const { order } = action;
  return {
    ...state,
    deliveries_not_delivered: state.deliveries_not_delivered.filter(t =>t.linked_order.id !== order.id)
  };
};

export default task;
