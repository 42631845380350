import React, { Component } from 'react';
import DeliveryRowDnd from "./DeliveryRowDnd";
import {Button, Table} from "react-bootstrap";
import DefaultDropZoneBuilder from "./DefaultDropZone";
import PropTypes from "prop-types";

const DefaultDropZone = DefaultDropZoneBuilder.build("TASK/DELIVERY");

class DeliveriesTodo extends Component {

  render() {
    const COLSPAN = 8;

    const onDropDefault = this.props.onDropDefault;
    const onDropTask = this.props.onDropTask;
    const selectedTasksIds = this.props.selectedTasksIds;
    const toggleSelect = this.props.toggleSelect;
    const toggleDispatchWaitingTasks = this.props.toggleDispatchWaitingTasks;
    let tasks = this.props.tasks;

    if (tasks.length === 0) {
      return (<div className="DeliveriesTodo"><h4>Commandes à dispatcher : AUCUNE</h4></div>);
    }

    let canDispatchWaitingTasks = this.props.canDispatchWaitingTasks;
    let toggleWaitingTasksTitle = 'Masquer cmdes non finalisées';
    if (canDispatchWaitingTasks === false) {
      toggleWaitingTasksTitle = 'Afficher cmdes non finalisées';
    }
    if (canDispatchWaitingTasks === false) {
      tasks = tasks.filter(item => item.status !== 'waiting');
    } else {
      const packingDoingTasks = this.props.packingDoing;

      if (packingDoingTasks.length > 0) {
        let packingDoingByOrderId = {};
        for (var i=0; i < packingDoingTasks.length; ++i) {
          packingDoingByOrderId[packingDoingTasks[i].linked_order.id] = packingDoingTasks[i];
        }
        for (var j=0; j < tasks.length; ++j) {
          if (tasks[j].status === 'waiting') {
            if (typeof(packingDoingByOrderId[tasks[j].linked_order.id ]) !== 'undefined') {
              tasks[j].packing_task = packingDoingByOrderId[tasks[j].linked_order.id ];
            }
          }
        }
      }
    }

    return (
      <div className="DeliveriesTodo">
        <h4>
          Commandes à dispatcher
          <span className="title-right">
            <Button variant="default" size="xs" className="float-right" onClick={toggleDispatchWaitingTasks}>{toggleWaitingTasksTitle}</Button>
          </span>
        </h4>
        <Table bordered size="sm" hover striped className="DeliveriesTodo">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom ({tasks.length}/{this.props.countDeliveries} )</th>
              <th>Sacs</th>
              <th>Z.</th>
              <th>Client</th>
              <th className={'drag-select'} onClick={this.props.onCleanSelected}></th>
              <th>Adresse</th>
              <th></th>
              <th>État</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => {
              let selected = selectedTasksIds.includes(task.id);
              return (<DeliveryRowDnd
                key={task.id}
                task={task}
                onDropTask={onDropTask}
                showSms={true}
                showStatus={true}
                showExternalCall={true}
                withSelect={true}
                selected={selected}
                toggleSelect={toggleSelect}
                inToDispatch={true}
              />);
            })}
            { tasks.length === 0 ?
              (<DefaultDropZone id={null} onDropDefault={onDropDefault} colSpan={COLSPAN}/>)
              : null
            }
          </tbody>
        </Table>
      </div>
    );
  }
}


DeliveriesTodo.propTypes = {
  onDropDefault: PropTypes.func.isRequired,
  onDropTask: PropTypes.func.isRequired,
  onCleanSelected: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  countDeliveries: PropTypes.any,
  selectedTasksIds: PropTypes.array.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  toggleDispatchWaitingTasks: PropTypes.func.isRequired,
  canDispatchWaitingTasks: PropTypes.bool,
  packingDoing: PropTypes.any,
};


export default DeliveriesTodo;
