import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import moment from "moment/moment";

import { RepreparationReasonForm } from "./RepreparationReasonForm";
import {MODAL_HIDE__REPREPARATION_REASON} from "../actions/commonModals";

class InternalRepreparationReasonModal extends React.Component {
  render() {
    const {
      modal,
      hide,
      repreparationReasons,
    } = this.props;
    moment.locale("fr");

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      dialogClassName="repreparation-reasons-dialog"
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        <RepreparationReasonForm
            repreparationReasons={repreparationReasons}
            cancelFunction={modal.cancelFunction}
            submitFunction={modal.submitFunction}
            newOrder={modal.newOrder}
        />
      </Modal.Body>
    </Modal>;
  }
}

InternalRepreparationReasonModal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
  repreparationReasons: PropTypes.object,
};

export const RepreparationReasonModal = connect(
  (state) => {
    return {
      modal: state.commonModals.repreparationReason,
      repreparationReasons: (
        state.support && state.support.meta && state.support.meta.repreparation_reasons
        ? state.support.meta.repreparation_reasons
        : (
          state.dispatch && state.dispatch.metas && state.dispatch.metas.repreparation_reasons
          ? state.dispatch.metas.repreparation_reasons
          : (
            state.m && state.m.metas && state.m.metas.repreparation_reasons
            ? state.m.metas.repreparation_reasons
            : null
          )
        )
      ),
    };
  },
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MODAL_HIDE__REPREPARATION_REASON});
      }
    };
  }
)(InternalRepreparationReasonModal);
