import { all, call, put, takeEvery } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import * as Raven from "raven-js";

import {
  ARRIVALS_CHANGE_NUMBER_BAGS,
  ARRIVALS_LOAD,
  ARRIVALS_LOADED,
  ARRIVALS_TASK_UPDATED,
  ARRIVALS_ORDER_CRATE_ADD,
  ARRIVALS_ORDER_CRATE_DELETE,
  ARRIVALS_CRATE_MODAL_UPDATED,
  VALIDATE_ARRIVALS,
  arrivalsApiState
} from "./actions";
import { errorThrown } from '../../actions';
import { api, daUtil } from '../../api';

function* loadArrivals(action) {
  const { centerId } = action;
  yield put(arrivalsApiState(true, false));
  const arrivals = yield call(api.loadArrivals, centerId);
  yield put({ type: ARRIVALS_LOADED, arrivals });
  yield put(arrivalsApiState(false, false));
}

function* validateArrivals(action) {
  const { arrivals, centerId } = action;
  yield put(arrivalsApiState(false, true));
  yield put(Notifications.info({
    title: "Arrivages" ,
    message: "Réception en cours de traitement...",
    autoDismiss:3,
    dismissible:"click"
  }));
  try {
    yield call(api.validateArrivals, arrivals);
    yield put(Notifications.success({
      title: "Arrivages" ,
      message: "Réception validée",
      autoDismiss:3,
      dismissible:"click"
    }));
  } catch (e) {
    yield put(
      Notifications.error(
        {
          title: "Arrivages" ,
          message: `Une erreur est survenue durant l'enregistrement.`,
          autoDismiss:3,
          dismissible:"click"
        }
      )
    );
  }
  yield put(arrivalsApiState(false, false));
  yield put({ type: ARRIVALS_LOAD, centerId });
}

function* changeNumberOfBags(action) {
  //`/processing-orders/${data.id}/${service}/`
  try {
    const {
      dockId,
      taskId,
      orderId,
      number_of_bags_iso,
      number_of_bags,
      extraPlacePzBags
    } = action;

    let calls = [];
    if (extraPlacePzBags) {
      for (const opzPatch of extraPlacePzBags) {
        calls.push(
          call(daUtil.post, `/processing-orders/${orderId}/pseudozone/`, opzPatch)
        );
      }
    }
    calls.push(call(api.editOrder, { id: orderId, number_of_bags, number_of_bags_isotherm: number_of_bags_iso }));

    let responses = yield all(calls);
    let order = responses[responses.length - 1];
    yield put({ type: ARRIVALS_TASK_UPDATED, dockId, taskId, order});
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* addOrderCrate({ orderId, crateCode, taskId, dockId }) {
  yield put(Notifications.info({title: "Arrivages", "message": "Ajout en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.addOrderCrate, orderId, crateCode, 1);
    yield put(Notifications.success({title: "Arrivages", "message": "Cagette ajoutée",  autoDismiss:3, dismissible:"click"}));
    yield put({ type: ARRIVALS_TASK_UPDATED, order, taskId, dockId });
    yield put({ type: ARRIVALS_CRATE_MODAL_UPDATED, order});
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Arrivages" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Arrivages" , "message": "Impossible d'ajouter la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* deleteOrderCrate({ orderId, crateCode, taskId, dockId }) {
  yield put(Notifications.info({title: "Arrivages" , "message": "Suppression en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.deleteOrderCrate, orderId, crateCode, 1);
    yield put(Notifications.success({title: "Arrivages" , "message": "Cagette supprimée",  autoDismiss:3, dismissible:"click"}));
    yield put({ type: ARRIVALS_TASK_UPDATED, order, taskId, dockId});
    yield put({ type: ARRIVALS_CRATE_MODAL_UPDATED, order});
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Arrivages" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Arrivages" , "message": "Impossible de supprimer la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

export const arrivalsSagas = [
  takeEvery(ARRIVALS_LOAD, loadArrivals),
  takeEvery(VALIDATE_ARRIVALS, validateArrivals),
  takeEvery(ARRIVALS_CHANGE_NUMBER_BAGS, changeNumberOfBags),
  takeEvery(ARRIVALS_ORDER_CRATE_ADD, addOrderCrate),
  takeEvery(ARRIVALS_ORDER_CRATE_DELETE, deleteOrderCrate),
];
