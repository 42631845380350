import {
  LOGS_LOAD, LOGS_LOADED, LOGS_PARTIAL_LOADED,
  LOGS_PRESENCE_SAVE, LOGS_PRESENCE_SAVED,
  LOGS_PRESENCE_ADD,
  LOGS_MODAL_SHOW, LOGS_MODAL_HIDE,
  LOGS_DRIVER_LIST_LOADED,
  LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE,
  LOGS_EXTRA_COST_DEMANDS_LOADED,
  LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED,
  LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED,
} from "./actions";
import moment from "moment";


const initialState = {
  loaded: false,
  centers: [],
  filters : {
    date: "",
    is_paid_by_race: "",
    vehicle: "",
    center_id: "",
    log_type: "",
    include_invalid_logs: "1",
    has_out_of_planning_delivery: "",
  },
  extracost_types: [],
  modal: {},
  driver_list: [],
  logs: [],
  demands: {
    loaded: false,
    filters: {
      fromDate: "",
      toDate: "",
      driverId: "",
      isReviewed: "false",
      reviewedById: "",
      fromReviewedAt: "",
      toReviewedAt: "",
      isValidated: "",
      offset: 0,
      limit: 100,
    },
    demands: [],
    count: null,
    driversList: [], //this drivers list corresponds to drivers that made at least one demand
    reviewersList: [], //this users list corresponds to users that reviewed at least one demand
  },
};

export const logReducer = function(state = initialState, action) {
  switch (action.type) {
  case LOGS_LOAD: {
    if (action.filters.date === '0') {
      action.filters.date = moment().format('YYYY-MM-DD');
    }
    return {
      ...state,
      ...{filters: {...action.filters, include_invalid_logs: "1"}, loaded: false}
    };
  }
  case LOGS_LOADED: {
    return {...state, ...{
      logs: action.data.logs,
      extracost_types: action.data.extracost_types,
      loaded:true,
      centers: action.data.centers,
    }};
  }
  case LOGS_PARTIAL_LOADED: {
    /* on enlève d'abord les logs du driver_id que l'on vient de mettre à jour */
    let filtered_logs = Object.keys(state.logs).reduce((logs, log_id) => {
      if (parseInt(state.logs[log_id].driver.id, 10) !== parseInt(action.data.filters.driver_id, 10)) {
        logs[log_id] = state.logs[log_id];
      }
      return logs;
    }, {});
    return {
      ...state,
      logs: {
        ...filtered_logs,
        ...action.data.logs
      }
    };
  }
  case LOGS_PRESENCE_SAVE: {
    return {
      ...state,
      logs: {
        ...state.logs,
        [action.id] : { ...state.logs[action.id], updating:true }
      }
    };
  }
  case LOGS_PRESENCE_SAVED: {
    return {
      ...state,
      logs: { ...state.logs,
        [action.presence.id] : action.presence
      }
    };
  }

  case LOGS_PRESENCE_ADD: {
    if (
      state.filters.date !== moment(action.presencelog.started_at).format('YYYY-MM-DD')
      || (state.filters.driver_id && parseInt(state.filters.driver_id, 10) !== parseInt(action.presencelog.driver_id, 10))
    ) {
      return state;
    }
    let start = moment(action.presencelog.started_at);
    let end = moment(action.presencelog.ended_at);
    let diff = moment.duration(end.diff(start)); 
    // on affiche une ligne temporaire le temps de la requete API
    let tmp_presence_log = {
      id: parseInt(action.presencelog.uniq_id, 10),
      started_at: action.presencelog.started_at,
      ended_at: action.presencelog.ended_at,
      is_ended: true,
      manual_edit_start: action.presencelog.manual_edit_start,
      manual_edit_end: action.presencelog.manual_edit_end,
      date_formated: start.format('dddd D MMMM'),
      planning_sessions: [],
      driver: {
        id: parseInt(action.presencelog.driver_id, 10),
        name: action.presencelog.driver_name
      },
      time_total_formated: diff.hours() + 'h' + diff.minutes(),
      money_pres: 'x.xx',
      money_perf: 'x.xx',
      updating: true,
      logs: []
    };
    return {
      ...state,
      logs: { ...state.logs,
        [action.presencelog.uniq_id]: tmp_presence_log
      }
    };
  }

  case LOGS_MODAL_SHOW:
    return {...state, modal:action.modalData};
  case LOGS_MODAL_HIDE:
    return {...state, modal: {}};
  
  case LOGS_DRIVER_LIST_LOADED:
    return {...state, driver_list: action.driver_list};

  case LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE: {
    let newDemandsFilters = Object.assign({}, state.demands.filters);
    newDemandsFilters[action.filterName] = action.filterValue;
    let newDemands = Object.assign({}, state.demands);
    newDemands.filters = newDemandsFilters;
    // console.log(newDemands);
    return {
      ...state,
      demands: newDemands,
    };
  }

  case LOGS_EXTRA_COST_DEMANDS_LOADED: {
    let newDemands = Object.assign({}, state.demands);
    //console.log(action)
    newDemands.demands = action.results;
    newDemands.count = action.count;
    newDemands.loaded = true;
    //console.log(newDemands);
    return {
      ...state,
      demands: newDemands,
    };
  }

  case LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED: {
    let newDemands = Object.assign({}, state.demands);
    //console.log(action)
    newDemands.driversList = action.results;
    //console.log(newDemands);
    return {
      ...state,
      demands: newDemands,
    };
  }

  case LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED: {
    let newDemands = Object.assign({}, state.demands);
    //console.log(action)
    newDemands.reviewersList = action.results;
    //console.log(newDemands);
    return {
      ...state,
      demands: newDemands,
    };
  }

  default: {
    return state;
  }
  }
};
