import { call, put, takeEvery } from 'redux-saga/effects';
import { daUtil } from "../../../api";
import {
  DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG,
  DISPATCH_MAIN_MODAL_SHOW,
  DISPATCH_MODAL_DEPOSIT_DRIVER,
  DISPATCH_MODAL_DEPOSIT_DRIVERS,
  DISPATCH_MODAL_DOCK_ROUTE,
  DISPATCH_MODAL_OPTIMIZE_DOCK,
  // DISPATCH_MODAL_OPTIMIZE_DOCK_LOADED,
} from "../actions/modal";
import Raven from "raven-js";


function* modalIsobagPreload(action) {
  let driverList = yield call(daUtil.get, `/drivers/`);
  yield put({type: DISPATCH_MAIN_MODAL_SHOW, modalData: {modalType: "driver_iso_bag", driverList: driverList}});
}

function* modalDepositDriver(action) {
  yield put({type: DISPATCH_MAIN_MODAL_SHOW, modalData: {modalType: DISPATCH_MODAL_DEPOSIT_DRIVER, driver: action.driver}});
}

function* modalDepositDrivers(action) {
  yield put({type: DISPATCH_MAIN_MODAL_SHOW, modalData: {modalType: DISPATCH_MODAL_DEPOSIT_DRIVERS}});
}

function* modalCompareDockRoute(action) {
  try {
    const url = `route/${action.dock.id}/?parent_order=0&products=0`;
    let dock = yield call(daUtil.get, url);

    yield put({type: DISPATCH_MAIN_MODAL_SHOW, modalData: {
      modalType: DISPATCH_MODAL_DOCK_ROUTE,
      dock: dock,
      routes: dock.routes,
    }});
  } catch (exception) {
    Raven.captureException(exception);
  }

}

function* modalOptimizeDockRoute(action) {
  try {
    const optimizer = action.optimizer;
    const url = `route/${action.dock.id}/optimize/?optimizer=${optimizer}&parent_order=0&products=0`;
    const dock = yield call(daUtil.post, url);

    yield put({
      type: DISPATCH_MAIN_MODAL_SHOW, modalData: {
        modalType: DISPATCH_MODAL_DOCK_ROUTE,
        dock: dock,
        routes: dock.routes,
      }
    });
  } catch (exception) {
    Raven.captureException(exception);
  }
}

export const modalSagas = [
  takeEvery(DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG, modalIsobagPreload),
  takeEvery(DISPATCH_MODAL_DEPOSIT_DRIVER, modalDepositDriver),
  takeEvery(DISPATCH_MODAL_DEPOSIT_DRIVERS, modalDepositDrivers),
  takeEvery(DISPATCH_MODAL_DOCK_ROUTE, modalCompareDockRoute),
  takeEvery(DISPATCH_MODAL_OPTIMIZE_DOCK, modalOptimizeDockRoute)
];
