import { connect } from "react-redux";
import DeliveryList from "./DeliveryList";


export default connect(
  state => (
    {
      tasks: state.m.canceled_deliveries,
      title: "Commandes annulées avant livraison",
      className: 'to-restock-list',
      show : {
        status: true,
        toRestock: true,
      },
    }
  )
)(DeliveryList);
