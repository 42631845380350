import React from "react";
import {Button, Badge, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";

import "../css/TaskName.css";
import { TagsOrder } from "../components/TagsOrder";
import {orderStatusMap, STATUS_ORDER_WAITING_PRODUCTS} from "../utils/status";


const InternalOrderPseudoZoneName = ({
  task,
  pseudoZones,
  toggleOrderPseudoZone,
  alterStock,
}) => {
  function is_stock_altered(pzid, action_type) {
    if (
      typeof task.logstock_pz !== "undefined" &&
      Object.keys(task.logstock_pz).length > 0
    ) {
      let operation = action_type === "destock" ? "destock" : "ajout";
      if (
        pzid in task.logstock_pz &&
        task.logstock_pz[pzid].indexOf(operation) > -1
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  const popoverClick = (order, pz, opz) => (
    <Popover id="popover-trigger-click" className="opz-popover" title="">
      <Popover.Content>
        <p>
          #{order.random_id}, zone &#34;{pz.name}&#34;
        </p>
        
        {!pz.is_packing_on_expe &&
          <Button
            variant="success"
            size="xs"
            onClick={() => toggleOrderPseudoZone(order.id, pz.id, true)}
            disabled={opz.isDone}
          >
            Déverouiller, marquer &#34;fait&#34;{opz.isDone}
          </Button>
        }
        <Button
          variant="default"
          size="xs"
          onClick={() => toggleOrderPseudoZone(order.id, pz.id, false)}
        >
          Deverouiller, marquer &#34;à faire&#34;
        </Button>
        {opz.userName ? (
          <p style={{ marginTop: "5px" }}>fait par: {opz.userName}</p>
        ) : null}
        <Button
          disabled={task.updating ? true : false}
          style={{ marginTop: "15px" }}
          variant="warning"
          size="xs"
          onClick={() => alterStock(task, pz.id, "destock")}
        >
          Destocker
          {is_stock_altered(pz.id, "destock") ? (
            <span> (déjà fait)</span>
          ) : null}
        </Button>
        <Button
          disabled={task.updating ? true : false}
          variant="warning"
          size="xs"
          onClick={() => alterStock(task, pz.id, "restock")}
        >
          Restocker
          {is_stock_altered(pz.id, "restock") ? (
            <span> (déjà fait)</span>
          ) : null}
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <span className="OrderPseudoZoneName">
      {pseudoZones.map((e, idx) => {
        if (e.countItemMatch === 0 && !e.isLocked) {
          return null;
        }
        if (!e.pz) {
          console.error("PAS DE PZ ", e);
          return null;
        }

        let name = `${e.pz.name}:${e.countItemMatch}`;
        let style = e.isDone ? "success" : "default";
        let classInternal = `opz-${style}`;
        if (e.isLocked) {
          classInternal = "opz-lock";
          style = "info";
        }

        if (!e.pz.is_active && !e.isDone && !e.isLocked) {
          return null;
        }

        if (e.user && e.user.is_logistic) {
          return (
            <OverlayTrigger
              key={idx}
              trigger="click"
              placement="bottom"
              overlay={popoverClick(task.linked_order, e.pz, e)}
              rootClose
              transition={false}
            >
              <Badge variant={style} className={classInternal}>
                {name}
                {e.isLocked ? <span className="fa-solid fa-lock" /> : null}
              </Badge>
            </OverlayTrigger>
          );
        } else {
          return (
            <Badge variant={style} key={idx} className={`opz opz-${style}`}>
              {name}
            </Badge>
          );
        }
      })}
    </span>
  );
};
InternalOrderPseudoZoneName.propTypes = {
  task: PropTypes.object.isRequired,
  pseudoZones: PropTypes.array,
  toggleOrderPseudoZone: PropTypes.func,
  alterStock: PropTypes.func,
};

// TODO: extraire PseudoZoneBadge dans son propre composant
const TaskNameDisplay = ({
  task,
  pseudoZones,
  displayOrder,
  loadOrderSupportInfo,
  toggleOrderPseudoZone,
  showMissing,
  alterStock,
  centerId,
  someSupportInfo,
  disableTabIndex = false,
}) => {
  let taskName;
  if (!task.linked_order) {
    return <span className="TaskName">{task.id}</span>;
  } else {
    taskName = task.linked_order.random_id;
  }

  let prepInfoTitle = null;
  let cratesInfoTitle = null;
  let statusInfoTitle = null;
  if (
      someSupportInfo
      && someSupportInfo.packing_task
      && someSupportInfo.packing_task.packer
      && someSupportInfo.packing_task.packer.name !== undefined
      && someSupportInfo.packing_task.packer.name !== null
      && someSupportInfo.packing_task.packer.name !== ""
  ) {
    prepInfoTitle = someSupportInfo.packing_task.packer.name
  }
  if (
      someSupportInfo
      && someSupportInfo.crates
      && someSupportInfo.crates.length > 0
  ) {
    cratesInfoTitle = []
    let i = 0;
    for (let crate of someSupportInfo.crates){
      if (cratesInfoTitle.length > 0){
        cratesInfoTitle.push(<span key={"crate-" + i}>, </span>);
        ++i;
      }
      cratesInfoTitle.push(
          <span key={"crate-" + i} style={{whiteSpace: "nowrap"}}>{crate.code}</span>
      );
      ++i;
    }
  }
  if (
      someSupportInfo
      && someSupportInfo.status
  ) {
    statusInfoTitle = orderStatusMap[someSupportInfo.status];
  }
  const tooltip = (
    <Tooltip id={`tooltip-loader-task-${task.id}`}>
      <div style={{textAlign: "left"}}>
        {
          prepInfoTitle !== null
          ? <span><b>Prép. : </b>{prepInfoTitle}<br/></span>
          : null
        }
        {
          cratesInfoTitle !== null
          ? <span><b>Cagettes : </b>{cratesInfoTitle}<br/></span>
          : null
        }
        {
          statusInfoTitle !== null
          ? <span><b>Statut : </b>{statusInfoTitle}<br/></span>
          : null
        }
      </div>
    </Tooltip>
  );

  if(task.linked_order.canteen_witness){
    taskName = <span>{task.linked_order.canteen_name}</span>;
  }
  else{
    taskName = (
      <span>
        <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
          <a
            href={`/support/order-with-center/${task.linked_order.id}/center/${centerId}/`}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={disableTabIndex ? "-1" : "0"}
            onMouseEnter={() => loadOrderSupportInfo(task.linked_order, someSupportInfo.fetchedAt ? someSupportInfo.fetchedAt : null)}
          >
            <span className={"random-id"}>{taskName}</span>
            {
              task.linked_order.tags.includes("is_expensive")
              ? <span style={{color:'red'}} title={"Au moins un produit cher sur la commande"}> $$</span>
              : null
            }
          </a>
        </OverlayTrigger>
        {/*<button className="btn btn-xs btn-warning" onClick={() => displayOrder(task.linked_order)}></button>*/}
        {
          task.linked_order.status === STATUS_ORDER_WAITING_PRODUCTS
          && (
            task.missing_products_flow_active
            ? <i className="fa-solid fa-circle-pause"
                 title={"Recherche de manquants en cours par " + task.missing_products_flow_user}></i>
            : <i className="fa-regular fa-circle-pause"
                 title="Pas de préparateur sur cette recherche de manquants"></i>
          )
        }
      </span>
    );
  }

  const openAddProduct = (task) => {
    if (task.linked_order) {
      window.open(
        `${process.env.REACT_APP_DELEEVADMIN}/orders_checking/${task.linked_order.id}/missing_products/`,
        "win",
        "height=700,width=800"
      );
    }
  };

  let waiting_class = "btn-warning";
  if (task.missing_checked_state === "done") {
    waiting_class = "btn-success";
  }
  if (task.missing_checked_state === "doing") {
    waiting_class = "btn-info";
  }
  // if (task.
  // console.log(pseudoZones)
  return (
    <span className="TaskName tags-collection">
      {taskName}
      {task.status === "waiting-products" && (
        <span>
          <button
            className={"btn btn-xs " + waiting_class}
            onClick={() => openAddProduct(task)}
          >
            Ajouter produit
            {!task.linked_order.is_replace_allowed ? (
              <span>
                &nbsp;<i className="fa-solid fa-ban"></i>
              </span>
            ) : null}
            { task.missing_products_flow_user !== '' ? (
              <span style={{textDecorationLine: task.missing_products_flow_active ? 'none' : 'line-through'}}>
                &nbsp;({task.missing_products_flow_user})
              </span>
            ) : null}
          </button>
          &nbsp;
          {/*<button onClick={() => showMissing(task.linked_order)}>A</button>*/}
        </span>
      )}
      {task.linked_order && (
        <TagsOrder order={task.linked_order} address={task.address} client={task.client} />
      )}
      {task.client && task.client.is_fraud && (
        <Badge className="is-fraud">FRAUD</Badge>
      )}
      {task.client && task.client.is_staff && (
        <Badge className="is-staff">STAFF</Badge>
      )}
      {task.client && task.client.user_type === 2 && (
          <Badge className="is-exchange">
            &nbsp;<i className="fa-solid fa-right-left" title="..."></i>&nbsp;
          </Badge>
      )}
      {task.loading_status && (
        <Badge className={`loading-${task.loading_status}`}>
          <span
            title={
              task.loading_status === 'inprogress'
                ? `Chargement en cours - ${task.loading_by}`
                : `Chargement terminé - ${task.loading_by}`
            }
          >
            <i className="fa-solid fa-truck-ramp-box"></i>
          </span>
        </Badge>
      )}
      {/*task.linked_order && task.linked_order.tags.includes("1p") && (
        <Badge className="is-oneprep">1p</Badge>
      )*/}
      <InternalOrderPseudoZoneName
        task={task}
        pseudoZones={pseudoZones}
        toggleOrderPseudoZone={toggleOrderPseudoZone}
        alterStock={alterStock}
      />
      {/*<Badge className="warning">{task.status}</Badge>*/}
    </span>
  );
};

TaskNameDisplay.propTypes = {
  task: PropTypes.object.isRequired,
  pseudoZones: PropTypes.array,
  displayOrder: PropTypes.func,
  loadOrderSupportInfo: PropTypes.func,
  toggleOrderPseudoZone: PropTypes.func,
  showMissing: PropTypes.func,
  alterStock: PropTypes.func,
  centerId: PropTypes.number,
  disableTabIndex: PropTypes.bool,
  someSupportInfo: PropTypes.object,
};

export default TaskNameDisplay;

