import React from 'react';
import PropTypes from 'prop-types';
import {STATUS_LOADED} from "../../utils/dock_n_driver";
import {Button} from "react-bootstrap";
import {showPopinExtraCostAdd} from "../../actions/modal";
import {connect} from "react-redux";


const Internal = ({dock, task, openModal}) => {

  if (dock.current_status !== STATUS_LOADED) {
    // Pas de demande de surcoût si le dock n'est pas chargé
    return null;
  }

  return (
      <Button
          className="Sms"
          variant="default"
          size="xs"
          onClick={() => openModal(dock, task)}
      >Surcoût</Button>
  );
};

Internal.propTypes = {
  dock: PropTypes.object.isRequired,
  task: PropTypes.object,
  openModal: PropTypes.func.isRequired,
};

export const BtnOpenModalExtraCost = connect(
  null,
  (dispatch) => {
    return {
      "openModal": (dock, task) => {
        dispatch(showPopinExtraCostAdd(dock, task));
      },
    };
  }
)(Internal);


export default BtnOpenModalExtraCost;
