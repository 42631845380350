import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { stuartVehiclesSelect } from '../../../utils/stuart';
import { flattenAddress } from '../../../utils/address';


const OrderStuartCallContent = ({ task, onChangeVehicle, loadingEta, stuartEta }) => {
  let weight = parseFloat(task.linked_order.total_weight.replace('Kg', '').trim());
  weight = Math.round(weight);

  return (
    <div>
      <ul>
        <li>Adresse: {flattenAddress(task.address)}</li>
        <li>Poids: {task.linked_order.total_weight}</li>
        {task.linked_order.number_of_bags
          ? <li>Nombre de sacs: {task.linked_order.number_of_bags}</li>
          : null
        }
        <li>
          Véhicule:
          <select name="stuart_vehicles" onChange={onChangeVehicle}>
            {stuartVehiclesSelect.map(v =>
              <option key={v.value} value={v.value}>{v.name}</option>
            )}
          </select>
        </li>
        <li>
          Estimation arrivée Stuart au centre: {
            loadingEta
              ? 'calcul en cours...'
              : stuartEta
          }
        </li>
      </ul>
      {weight > 12
        ? (
          <Alert variant="danger">
            Le poids calculé de la commande est supérieur à 12 kg.
          </Alert>
        ): null
      }
    </div>
  );
};

OrderStuartCallContent.propTypes = {
  task: PropTypes.object,
  onChangeVehicle: PropTypes.func,
  loadingEta: PropTypes.bool,
  stuartEta: PropTypes.string,
};

export default OrderStuartCallContent;