import { ORDER_UPDATE } from "../../../actions";
export const SKIP = 'SKIP';

export const DISPATCH_START_DRIVER_PRESENCE = 'DISPATCH_START_DRIVER_PRESENCE';
export const DISPATCH_STOP_DRIVER_PRESENCE = 'DISPATCH_STOP_DRIVER_PRESENCE';
export const DISPATCH_CHANGE_DRIVER_VEHICLE = 'DISPATCH_CHANGE_DRIVER_VEHICLE';
export const DISPATCH_CHANGE_DRIVER_CENTER = 'DISPATCH_CHANGE_DRIVER_CENTER';
export const DISPATCH_CREATE_STATEMENT = 'DISPATCH_CREATE_STATEMENT';
export const DISPATCH_DRIVER_RETURN_BAGS = 'DISPATCH_DRIVER_RETURN_BAGS';

export const DISPATCH_DRIVER_UPDATE = 'DISPATCH_DRIVER_UPDATE';
export const DISPATCH_DRIVER_UPDATE_SUCCEEDED = 'DISPATCH_DRIVER_UPDATE_SUCCEEDED';
// export const DISPATCH_DRIVER_LEAVE = 'DISPATCH_DRIVER_LEAVE';

export const DISPATCH_DRIVER_ACTIVITY_START = 'DISPATCH_DRIVER_ACTIVITY_START';
export const DISPATCH_DRIVER_ACTIVITY_END = 'DISPATCH_DRIVER_ACTIVITY_END';

export const startDriverPresence = (driver, vehicle) => {
  return {
    type: DISPATCH_START_DRIVER_PRESENCE,
    vehicle: vehicle,
    driver: driver,
  };
};

export const changeDriverVehicle = (driver, vehicle) => {
  return {
    type: DISPATCH_CHANGE_DRIVER_VEHICLE,
    vehicle: vehicle,
    driver: driver,
  };
};

export const changeDriverCenter = (driver, centerId) => {
  return {
    type: DISPATCH_CHANGE_DRIVER_CENTER,
    centerId: parseInt(centerId, 10),
    driver: driver,
  };
};

export const stopDriverPresence = (driver, unfinished_new_status = "prepared") => {
  return {
    type: DISPATCH_STOP_DRIVER_PRESENCE,
    driver: driver,
    unfinished_new_status: unfinished_new_status
  };
};

export const validateChangeBags = (
  orderId, numberOfBags, numberOfBagsIso, numberOfBagsFrozen, opzPatchs
) => {
  return {
    type: ORDER_UPDATE,
    orderPatch: {
      id: orderId,
      number_of_bags: numberOfBags,
      number_of_bags_isotherm: numberOfBagsIso,
      number_of_bags_frozen: numberOfBagsFrozen,
    },
    opzPatchs,
  };
};

export const isothermBagReturn = (driver, quantityByDepositLabel) => ({
  type: DISPATCH_DRIVER_RETURN_BAGS,
  driver,
  quantityByDepositLabel
});

export const driverUpdate = (driver, patch) => {
  return {
    type: DISPATCH_DRIVER_UPDATE,
    driver: driver,
    patch: patch
  };

};


export const driverUpdateSucceeded = (driver) => {
  return {
    type: DISPATCH_DRIVER_UPDATE_SUCCEEDED,
    driver: driver,
  };
};

export const startActivity = function (driver) {
  const r = prompt("Ajouter une note");
  if (!r) {
    return { type: SKIP };
  }

  return {
    type: DISPATCH_DRIVER_ACTIVITY_START,
    driver : driver,
    note: r
  };
};

export const endActivity = function (driver) {
  return {
    type: DISPATCH_DRIVER_ACTIVITY_END,
    driver : driver,
  };
};
