import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "react-bootstrap";

import DeliveryRowDnd from "../Deliveries/DeliveryRowDnd";
import {DeliveryRow} from "../Deliveries/DeliveryRow";
import DefaultDropZoneBuilder from "../common-comps/DefaultDropZone";

import "../../css/Dock.css";
import moment from "moment";
import {OtherActivityButton} from "./Dock/OtherActivityButton";
import {FinishDockButton} from "./Dock/FinishDockButton";
import DockTitleLinked from "./Dock/DockTitleLinked";
import { EXTERNAL_DOCK_TYPES } from '../../utils/dock_n_driver';


// const ANSWER_TYPE_ID__REFUSED = 0
// const ANSWER_TYPE_ID__ACCEPTED = 1
const ANSWER_TYPE_ID__TIMEOUT = 2

const DefaultDropZone = DefaultDropZoneBuilder.build("TASK/DELIVERY");

const Dock = ({dock, transit_areas, docks, docks_extract, onDropTask, onDropDefault, handlerDockDnd, connectDragPreview, connectDropTarget, show, className}) => {

  let colspan = 8;
  const name = dock.name;
  const tasks = dock.tasks;
  show.externalCall = !EXTERNAL_DOCK_TYPES.includes(dock.dock_type) &&
    dock.tasks.length === 1;

  let listTasks = null;
  let now = moment(new Date());
  let dockAge = now.diff(moment(dock.created_at), 'days');
  let dockClassNames = "Dock col-12 col-sm-6 col-md-6 col-huge-2 " + dock.dock_type.toLowerCase() + " " + dock.current_status;
  if (className) {
    dockClassNames += className;
  }
  if (dock.locked_at) {
    dockClassNames += " locked";
  }

  if (dock.dock_type === 'STUART') {
    dockClassNames += " stuart";
  }

  if (dock.dock_type === 'UBER') {
    dockClassNames += " uber";
  }

  if (
    dock.external_job && (
      [
        'voided',
        'expired',
        'canceled',
        'returned',
      ].includes(dock.external_job.status)
      || (
        ['in_progress', 'finished'].includes(dock.external_job.status)
        && dock.external_job.cancel_reason
      )
    )
  ) {
    dockClassNames += " canceled-external-job";
  }

  if (dock.loaded_at && !dock.locked_at) {
    dockClassNames += " loaded";
  }

  if(dock.cross_docking_target_center !== null){
    dockClassNames += " cross_dock";
  }

  if (tasks.length === 0) {

    let resetCase = dock.dock_type !== 'DRIVER_HARD' && dock.external_job && ['voided', 'expired'].includes(dock.external_job.status);
    resetCase = resetCase || (dock.dock_type !== 'DRIVER_HARD' && dock.locked_at);

    if(onDropDefault){
      listTasks = (
        <DefaultDropZone dock={dock} docks={docks} name={name} onDropDefault={onDropDefault} colSpan={colspan}>
          {dock.dock_type === 'DRIVER_HARD' ? <OtherActivityButton driver={dock.driver} /> : null}
          {resetCase ?
            <FinishDockButton dock={dock} /> : null}
          {'\u00A0'}
        </DefaultDropZone>
      );
    }
    else{
      listTasks = (
        <tr>
          <td colSpan={colspan} style={{textAlign: "center"}}>
            {dock.dock_type === 'DRIVER_HARD' ? <OtherActivityButton driver={dock.driver} /> : null}
            {resetCase ? <FinishDockButton dock={dock} /> : null}
            {'\u00A0'}
          </td>
        </tr>
      );
    }
  } else {
    listTasks = tasks.map((task) => {
      if ( !onDropTask || dock.locked_at) {
        return (
          <DeliveryRow key={task.id}
            task={task}
            dock={dock}
            docks_extract={docks_extract}
            show={show}
            inCrossdock={dock.cross_docking_target_center !== null}
            addressFormatting={'compact'}
          />
        );
      } else {
        return (
          <DeliveryRowDnd key={task.id}
            transit_areas={transit_areas}
            task={task}
            docks={docks}
            docks_extract={docks_extract}
            onDropTask={dockAge < 7 ? onDropTask: () => {alert(`ce dock est trop vieux: ${dockAge} jours`);}}
            dock={dock}
            show={show}
            inCrossdock={dock.cross_docking_target_center !== null}
            addressFormatting={'compact'}
          />
        );
      }
    });
  }

  let handlerDockDndElement = null;
  let headerColspan = colspan;
  if (handlerDockDnd && !dock.locked_at) {
    if (
      dock.tasks.length > 0
      && !dock.isLock
      && (typeof(dock.load_info.is_expe_frozen) === "undefined" || dock.load_info.is_expe_frozen === false)
    ) {
      handlerDockDndElement = handlerDockDnd(<th className="draggable" title={`dock_id: ${dock.id}`}></th>);
    } else {
      handlerDockDndElement = (<th className="draggable locked" title={`dock_id: ${dock.id}`}></th>);
    }
    headerColspan--;
  }

  let driverGainReal = (
    dock.driver_gain_real === null
    ? null
    : <span> pour {dock.driver_gain_real.toFixed(2)} €</span>
  );

  const dockElement =  (
    <div className={dockClassNames}>
      <Table bordered size="sm" hover striped>
        <thead>
          <tr>
            {handlerDockDndElement}
            <th colSpan={headerColspan} >
              <DockTitleLinked dock={dock} />
            </th>
          </tr>

          {dock.return_time ?
            <tr className="subheader">
              <td colSpan={colspan} >
                {dock.driver && dock.driver.is_paid_by_race && dock.accepted_by_driver === null ?
                  <b style={{"color": "orange"}}><i className="fa-solid fa-circle-minus"></i> Tournée en cours d'acceptation {driverGainReal}</b>
                  : dock.driver && dock.driver.is_paid_by_race && dock.accepted_by_driver === false ?
                    (
                      dock.driver_answer === ANSWER_TYPE_ID__TIMEOUT
                        ? <b style={{"color": "red"}}><i className="fa-solid fa-circle-minus"></i> Le livreur n'a pas répondu avant la fin du délai {driverGainReal}.</b>
                        : (
                          <b style={{"color": "red"}}>
                            <i className="fa-solid fa-circle-minus"></i>
                            Le livreur a refusé la tournée {driverGainReal}.
                            {dock.driver && dock.driver.daily_refusals_limit_exceeded && (
                              <strong style={{marginLeft: '3px'}}>Limite de refus dépassée</strong>
                            )}
                          </b>
                        )
                    )
                    : <span>
                          En liv depuis {moment(dock.locked_at).format("HH:mm", 'fr').replace(':', 'h')},
                          retour prévu à <b>{dock.return_time.replace(':', 'h')}</b>
                          {driverGainReal}
                  </span>
                }
              </td>
            </tr> : null
          }
        </thead>
        <tbody>{listTasks}</tbody>
      </Table>
    </div>
  );

  return (handlerDockDnd && !dock.locked_at ? connectDragPreview(connectDropTarget(dockElement)) : dockElement);
};


Dock.propTypes = {
  dock: PropTypes.object.isRequired,
  transit_areas: PropTypes.object.isRequired,
  docks_extract: PropTypes.object,
  docks: PropTypes.array.isRequired,
  onDropDefault: PropTypes.func,
  onDropTask: PropTypes.func,
  handlerDockDnd: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  show: PropTypes.object,
  classes: PropTypes.string
};

export default Dock;
