import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { connect } from 'react-redux';

import {cancelDeliveryDrop} from "../../../actions/drag_n_drop";

export const CancelDeliveryDrop = connect()(({task, dispatch}) => {
  if (!task.is_moving || !task.dock) {
    return null;
  }
  const tooltip = (
    <Tooltip>Annuler le déplacement</Tooltip>
  );
  return <React.Fragment>
    <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <button className="CancelDeliveryDrop btn btn-danger btn-xs" onClick={() => dispatch(cancelDeliveryDrop(task))} title="Annuler le déplacement">
        <i className="fa-regular fa-rectangle-xmark"></i>
      </button>
    </OverlayTrigger>
  </React.Fragment>;
});

CancelDeliveryDrop.propTypes = {
  task: PropTypes.object.isRequired
};

export default CancelDeliveryDrop;
