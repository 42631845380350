/* eslint-disable react/prop-types */
import React, {useState} from "react";
import {Presence} from "./Presence";

export const PresenceList = ({logs}) => {
  const [showDetails, setShowDetails] = useState(new Set([]));

  const getSetShowDetailFor = (log_id) => {
    return (showDetailValue) => {
      if (showDetailValue && !showDetails.has(log_id)) {
        let newShowDetails = new Set(showDetails);
        newShowDetails.add(log_id);
        setShowDetails(newShowDetails);
      }
      else if (!showDetailValue && showDetails.has(log_id)) {
        let newShowDetails = new Set(showDetails);
        newShowDetails.delete(log_id);
        setShowDetails(newShowDetails);
      }
    }
  }

  let allUnfoldSet = new Set(logs.map(l => l.id));

  return (
    <div className="row">
      <div className="col-md-12">
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>
                Chauffeur
              </th>
              <th className="text-center">
                <button
                    className={"btn btn-xs btn-default"}
                    onClick={() => setShowDetails(new Set(allUnfoldSet))}
                >
                  Tout déplier
                </button>
                <button
                    className={"btn btn-xs btn-default ml-3"}
                    onClick={() => setShowDetails(new Set([]))}
                >
                  Tout replier
                </button>
              </th>
              <th>
                Type de log
              </th>
              <th>
                Critère de paiement
              </th>
              <th>
                Durée
              </th>
              <th>
                Dont attente
              </th>
              <th>
                €
              </th>
              <th>
                Planning
              </th>
              <th>
                Shift
              </th>
            </tr>
          </thead>
          <tbody>
            {
              logs.map(
                p => <Presence key={p.id}
                               presence={p}
                               showDetail={showDetails.has(p.id)}
                               setShowDetail={getSetShowDetailFor(p.id)}
                />
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
