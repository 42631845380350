import {connect} from "react-redux";
import DriverState from "../components/DriverState";
import {
  changeDriverVehicle,
  startDriverPresence,
  stopDriverPresence,
  isothermBagReturn
} from "../actions";
import {showDepositModalDriver} from "../actions/modal";
import {changeDriverCenter} from "../apps/dispatch/actions/driver";

const mapStateToProps = (state, ownProps) => {
  //console.log("drivers state", ownProps);
  return {
    ...ownProps,
    centers: state.m.metas.centers || [],
  };
};

const mapDispatchToProps = dispatch => {
  // WIP HERE
  return {
    "startPresence": (driver, vehicle) => dispatch(startDriverPresence(driver, vehicle)),
    "stopPresence": (driver, unfinished_new_status) => dispatch(stopDriverPresence(driver, unfinished_new_status)),
    "changeVehicle": (driver, vehicle) => dispatch(changeDriverVehicle(driver, vehicle)),
    "isothermBagReturn": (driver) => dispatch(isothermBagReturn(driver)),
    "showDepositModalDriver": (driver) => dispatch(showDepositModalDriver(driver)),
    "changeCenter": (driver, centerId) => dispatch(changeDriverCenter(driver, centerId)),
  };
};

let DriverStateLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverState);


export default DriverStateLinked;
