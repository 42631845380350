import {filterAndSortDockTasks} from "../../utils/task";
import {findByid} from "../../utils";

let _exp = {};

_exp.reduceDockSimpleAction = function(action, state) {
  const diff = {updating:action.dock.id};
  return {...state, ...{docks:state.docks.map(e => e.id === action.dock.id ? {...e, ...diff} : e)}};
};

_exp.reduceDockUpdate = function(action, state) {

  return Object.assign(
    {},
    state,
    {
      docks: state.docks.map((e) => {
        if (e.id === action.patch.id) {
          return Object.assign({}, e, {
            updating: action.patch.id,
            ...action.patch
          });
        }
        return e;
      })
    }
  );
};


_exp.reduceDockClose = function(action, state) {

  return Object.assign(
    {},
    state,
    {
      docks: state.docks.map((e) => {
        if (e.id === action.dock.id) {
          return Object.assign({}, e, {
            updating: action.dock.id,
            hidden: true,
          });}
        return e;
      }),
      deliveries: state.deliveries.map((e) => {
        if (e.dock && e.dock.id === action.dock.id) {
          return Object.assign({}, e, {
            updating: action.dock.id,
            dock: null
          });
        }
        return e;
      })
    }
  );
};

_exp.reduceAddDock = (action, state) => ({
  ...state, docks: [...state.docks, action.dock]
});

_exp.reduceCallStuart = function(action, state) {

  return Object.assign(
    {},
    state,
    {
      docks: state.docks.map((e) => {
        if (e.id === action.dock.id) {
          return Object.assign({}, e, {
            updating: action.dock.id,
            external_job: {
              id: null,
              transport_type: action.transportType,
              status: 'init',
            }
          });
        }
        return e;
      })
    }
  );
};


_exp.reduceDockUpdated = function (action, state) {
  console.log("reduceDockUpdated", action.dock);
  return Object.assign({}, state, {
    docks: state.docks.map((e) => {
      if (e.id === action.dock.id) {
        return Object.assign({}, action.dock, {updating: false});
      }
      return e;
    })
  });
};


_exp.reduceDockDropped = function (action, state) {
  console.log("reduceDockDropped", action);

  const srcDockTasks = filterAndSortDockTasks(state.deliveries, action.droppedDockId);
  const destDockTasks = filterAndSortDockTasks(state.deliveries, action.targetDockId);
  const destDock = findByid(state.docks, action.targetDockId);

  // const destLastTaskId = destDockTasks.length > 0 ? destDockTasks[destDockTasks.length -1].id : null;
  // const srcFirstTaskId = srcDockTasks.length > 0 ? srcDockTasks[0].id : null;

  let currentRank = 1;
  if (destDockTasks.length > 0) {
    currentRank = destDockTasks[destDockTasks.length - 1].dock_rank + 1;
  }
  let newRankMap = {};
  for (const ta of srcDockTasks) {
    newRankMap[ta.id] = currentRank;
    currentRank++;
  }


  // setter updating sur les 2 docks
  const diffDock = {
    updating: action.targetDockId
  };
  return {
    ...state,
    ...{
      docks: state.docks.map((e) => {
        if (e.id === action.targetDockId || e.id === action.droppedDockId) {
          return {...e, ...diffDock};
        } else {
          return e;
        }
      }),
      deliveries: state.deliveries.map((e) => {
        let temp = {};

        // mettre à jour le dock_id sur toutes les tâches ayant le droppedDockId
        if  (e.dock && e.dock.id === action.droppedDockId) {
          temp.updating = action.targetDockId;
          temp.dock = {...destDock};
          temp.dock_rank = newRankMap[e.id];
        }

        if (temp.updating) {
          return {...e, ...temp};
        }

        return e;
      }),
    }
  };
};



export default _exp;
