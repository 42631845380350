// load center : priority : session, staff, 1
// voir aussi stateToCenter

import store from "../createStore";
import {SHARE_WORKER_ON} from "../config";
import {refreshRequest} from "../actions";

export const postSetCenterRefresh = () => {
  if (SHARE_WORKER_ON) {
    window.location.reload();
  } else {
    store.dispatch(refreshRequest());
  }
};

export const setCenterIdAndRefresh = (centerId, user = null) => {
  const hasChanged = setCenterId(centerId, user);
  if (hasChanged) {
    postSetCenterRefresh();
  }
};



/**
 * setCenterId
 * met à jour le centre , mais ATTENTION, pas de maj du state, donc pas de refresh des composants
 */
export const setCenterId = (centerId, user = null) => {
  let previousCenterId = extractCenterId(user);
  if (previousCenterId !== centerId) {
    console.log('update current centerId', previousCenterId, centerId);
    window.sessionStorage.setItem('flow_current_center', centerId);
    sessionStorage.setItem('hash_orders', "");
    return true;
  }
  return false;
};

/**
 * attention , si appellé trop top, cad avant boot, le user n'est pas setté donc on ne recupere pas le
 * current_center du user
 * @param user
 * @returns {null}
 */
export const extractCenterId = (user) => {
  let centerId = null;
  let rawSessionCenterId = window.sessionStorage.getItem('flow_current_center');
  let sessionCenterId = parseInt(rawSessionCenterId, 10);
  if (!isNaN(sessionCenterId)) {
    centerId = sessionCenterId;
  }

  if (centerId == null) {
    if(user && user.staff_info && user.staff_info.current_center){
      centerId = user.staff_info.current_center;
      window.sessionStorage.setItem('flow_current_center', centerId);
      //console.log(`center loaded : Init session center id to ${centerId}`);
    }
    else{
      centerId = 9;  // default center_id
      //console.log(`center loaded : Init temporary default center id to ${centerId}`);
    }
  }

  //let centerId = user && user.staff_info.current_center ? user.staff_info.current_center : 1;
  //console.log(`center loaded : final cid: ${centerId}, scid:${sessionCenterId} , ucid: ${user && user.staff_info.current_center ? user.staff_info.current_center : null} `);
  return centerId;
};

export function stateToCenterId(state) {
  return extractCenterId(state.user);
}

