import {connect} from "react-redux";
import DockList from "./DockList";
import {
  dockListEventHandler,
  dockListProps,
} from "../common-comps/dock";
import {getComputedDockListDraftTomorrow} from "../../selectors/dock";
import {withRouter} from "react-router-dom";

export const DockListDraftTomorrow = withRouter(connect(
  state => dockListProps(
    state,
    getComputedDockListDraftTomorrow(state),
    "Tournées en création POUR DEMAIN"
  ),
  dispatch => dockListEventHandler(dispatch)
)(DockList));

