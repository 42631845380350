
let _exp = {};


_exp.ignoreDisconnect = function(state) {
  const versioning = {...state.versioning};
  versioning.updated_at = (new Date()).getTime();
  return {
    ...state,
    versioning
  };
}

_exp.refreshRequest = function(action, state) {
  // return state;
  return {
    ...state,
    version: action.version,
    versioning: {
      ...state.versioning,
      last_requested_at: action.timestamp
    }
  };
};

_exp.refreshRequestError = function (action, state) {
  const versioning = {
    ...state.versioning,
    syncFails: state.versioning.syncFails + 1
  }
  return {...state, versioning};
}

_exp.refreshRequestSucceded = function (action, state) {
  const version = action.dispatch.version;
  const versioning = {...state.versioning};

  if (version + "" !==  state.version + "" && !state.firstLoad) {
    versioning.skipped += 1;
    return {...state, versioning};
  }

  const newState = state;
  const timestamp = action.timestamp;
  const notUpdatedMap = {};

  // reinit versioning not updated keys
  versioning.notUpdated.forEach(e => notUpdatedMap[e.key] = e)
  versioning.notUpdated = [];

  for (var key in action.dispatch) {
    if (key === "version") {
      continue;
    }

    if (key === "stats") {
      newState[key] = action.dispatch[key];
      // continue?
    }

    if (key === "metas" && state[key].updating === true) {
      versioning.notUpdated.push(notUpdatedMap[key] || {key, timestamp})
      continue;
    }

    if (state[key] instanceof Array && state[key].filter(e => e.updating).length > 0) {
      versioning.notUpdated.push(notUpdatedMap[key] || {key, timestamp});
      continue;
    }

    newState[key] = action.dispatch[key];
  }
  //We keep the oldest timestamp of all to set the updated_at property,
  // or the current action timestamp if everything's up to date
  versioning.updated_at = versioning.notUpdated.reduce((tsOne, tsTwo) => {
    return tsOne < tsTwo ? tsOne : tsTwo;
  }, action.timestamp);

  return {...newState, version: 0, firstLoad: false, waitingForRefresh: false, versioning};
};


export default _exp;
