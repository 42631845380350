export const MAIN_MODAL_SHOW = 'MAIN_MODAL_SHOW';
export const MAIN_MODAL_HIDE = 'MAIN_MODAL_HIDE';

export const MAIN_MODAL_PRELOAD_ISO_BAG = 'MAIN_MODAL_PRELOAD_ISO_BAG';
export const MODAL_DEPOSIT_DRIVER = 'MODAL_DEPOSIT_DRIVER';
export const MODAL_DEPOSIT_DRIVERS = 'MODAL_DEPOSIT_DRIVERS';

export const MODAL_CHANGE_TROLLEY_LOCATION_SHOW = 'MODAL_CHANGE_TROLLEY_LOCATION_SHOW';
export const MODAL_CHANGE_TROLLEY_LOCATION_HIDE = 'MODAL_CHANGE_TROLLEY_LOCATION_HIDE';

export const showDepositModalDriver = (driver) => {
  return {
    type: MODAL_DEPOSIT_DRIVER,
    driver: driver,
  };
};
export const showDepositModalDrivers = () => {
  return {
    type: MODAL_DEPOSIT_DRIVERS,
  };
};

export const showTransitAreaModal = (order) => {
  return {
    type: MAIN_MODAL_SHOW,
    modalData: {
      order: order,
      modalType: "order_change_transit_area",
    }
  };
};

export const showModalChangeTrolleyLocation = (trolley_id) => {
  return {
    type: MAIN_MODAL_SHOW,
    modalData: {
      trolley_id: trolley_id,
      modalType: "change_trolley_location",
    }
  };
};

export const showStuartDockCall = dock => ({
  type: MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'stuart_call',
    dock,
  }
});

export const showStuartCall = task => ({
  type: MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'stuart_call',
    task,
  }
});

export const showUberCall = task => ({
  type: MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'uber_call',
    task,
  }
});

export const showRedeliverOrRestock = task => ({
  type: MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'redeliver_or_restock',
    task,
  }
});
