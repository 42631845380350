import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {MODAL_SHOW__CANTEEN_ORDERS} from "../actions/commonModals";


export const NumberOfCanteenOrders = connect()(
  ({order, dispatch}) => {
    if(order.canteen_witness) {
      const modalData = {canteen: order, show: true};

      return (
        <div
          onClick={() => dispatch({type: MODAL_SHOW__CANTEEN_ORDERS, data: modalData})}
          className="NumberOfCanteenOrders" style={{cursor: 'pointer'}}
        >
          <span>{order.canteen_orders_count}</span>
        </div>
      );
    }
    return null;
  }
);

NumberOfCanteenOrders.propTypes = {
  order: PropTypes.object.isRequired,
};
