export const ORDER_TO_RESTOCK = 'ORDER_TO_RESTOCK';
export const ORDER_TO_RESTOCK_SUCCEEDED = 'ORDER_TO_RESTOCK_SUCCEEDED';

export const VALIDATE_RESTOCK = 'VALIDATE_RESTOCK';
export const VALIDATE_RESTOCK_SUCCEEDED = 'VALIDATE_RESTOCK_SUCCEEDED';

export const orderToRestock = (orderId) => ({
  type: ORDER_TO_RESTOCK,
  orderId,
});

export const validateRestock = (orderId) => ({
  type: VALIDATE_RESTOCK,
  orderId,
});