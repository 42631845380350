import React, { Component } from 'react';
import PropTypes from "prop-types";
import { DragSource, DropTarget } from 'react-dnd';
import {DeliveryRow, DEFAULT_TOGGLE_SELECT} from "./DeliveryRow";

const taskSource = {
  beginDrag(props) {
    console.log('start drag', props.task);
    return {
      task: props.task
    };
  }
};

const taskTarget = {
  // hover(props, monitor, component) {
  //   //console.log(props.task.id, monitor.getItem().taskId, component);
  //   return;
  // },

  canDrop(props, monitor) {
    let item = monitor.getItem();
    // console.log("task CAN DROP",  props.task, item.task);

    if (props.task.isLock) {
      // console.log("FALSE A", props.task);
      return false;
    }

    if (item.task.id === props.task.id) {
      // console.log("FALSE B", item.task, props.task);
      return false;
    }

    // si dnd vers tâche sans dock
    if (!props.task.dock) {
      // console.log("TRUE , finalized (extract)");
      return true;
    }

    // si dnd vers tâche dans dock NOCONFIG non terminé
    if (props.task.dock && !props.task.dock.defined_at && props.task.dock.dock_type === "NOCONFIG") {
      // console.log("TRUE , not defined");
      return true;
    }

    // on permet le dnd sur un dock déverrouillé de livreur, même si déjà rempli
    if (!!props.task.dock && !!item.task.dock
        && !props.task.dock.locked_at  && !item.task.dock.locked_at
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(props.task.dock.dock_type)
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(item.task.dock.dock_type)) {
      // console.log("TRUE, on driver");
      return true;
    }

    // console.log("FALSE LAST");

    return false;
  },

  drop(props, monitor) {
    //console.log('dropped ! ', props.task.id, monitor.getItem().taskId);
    props.onDropTask(props.task, monitor.getItem().task);
  }
};


function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

class DeliveryRowDnd extends Component {
  render() {
    const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, canDrop, isOver } = this.props;
    const {
      task,
      showMenu,
      showDriver,
      showStatus,
      showSms,
      showLate,
      showExternalCall,
      addressFormatting,
      withSelect,
      selected,
      toggleSelect,
      inCrossdock,
      inToDispatch,
    } = this.props;

    let classes = 'DeliveryRow';
    classes += isDragging ? " is-dragging-row" : '';
    classes += canDrop && isOver ? " is-over-row" : '';

    return (
      <DeliveryRow className={classes}
        task={task}
        handlerDnd={connectDragSource}
        connectDragPreview={connectDragPreview}
        connectDropTarget={connectDropTarget}
        showMenu={showMenu}
        showDriver={showDriver}
        showStatus={showStatus}
        showSms={showSms}
        showLate={showLate}
        showExternalCall={showExternalCall}
        addressFormatting={addressFormatting}
        withSelect={withSelect}
        selected={selected}
        toggleSelect={toggleSelect}
        inCrossdock={inCrossdock}
        inToDispatch={inToDispatch}
      />
    );
  }
}

DeliveryRowDnd.propTypes = {
  task: PropTypes.object.isRequired,
  showMenu: PropTypes.bool,
  showDriver: PropTypes.bool,
  showStatus: PropTypes.bool,
  showSms: PropTypes.bool,
  showLate: PropTypes.bool,
  addressFormatting: PropTypes.string,
  onDropTask: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  withSelect: PropTypes.bool,
  selected: PropTypes.bool,
  toggleSelect: PropTypes.func,
  inCrossdock: PropTypes.bool,
  inToDispatch: PropTypes.bool,
};

DeliveryRowDnd.defaultProps = {
  showMenu: false,
  showDriver: false,
  showStatus: true,
  showSms: true,
  showLate: false,
  addressFormatting: null,
  withSelect: false,
  selected: false,
  toggleSelect: DEFAULT_TOGGLE_SELECT,
  inCrossdock: false,
  inToDispatch: false,
};

export default DragSource("TASK/DELIVERY", taskSource, collectSource)(
  DropTarget("TASK/DELIVERY", taskTarget, collectTarget)(DeliveryRowDnd)
);
