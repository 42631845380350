/* eslint-disable react/prop-types */
import React from 'react';
import {DropdownButton, Dropdown} from "react-bootstrap";
import {connect} from "react-redux";
import {DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG, showDepositModalDrivers} from "../../actions/modal";


const Internal = ({isoAdd, showDepositModalDrivers}) => {
  return <DropdownButton variant="default" size="xs" className="DriverBurger" title="AUTRES LIVS" id="bg-nested-dropdown">
    <Dropdown.Item eventKey="1" onClick={() => isoAdd()}>sac iso</Dropdown.Item>
    <Dropdown.Item eventKey="2" onClick={() => showDepositModalDrivers()}>Consignes</Dropdown.Item>
  </DropdownButton>;
};

const mapDispatchToProps = dispatch => {
  return {
    "isoAdd": () => (dispatch({type: DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG})),
    "showDepositModalDrivers": () => dispatch(showDepositModalDrivers()),
  };
};


export const DriverBurger = connect(null, mapDispatchToProps)(Internal);
