import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";

export const DriverEndOfShift = ({dock}) => {

  const driver = dock.driver;

  if (
    !driver
    || driver.state === 'plan'
    || !driver.end_of_shift
    || driver.end_of_shift.includes("dont_care")
  ) {
    return null;
  }

  let style_danger = {};
  // Si pas de retour, on vérifie que le dernier point de livraison
  // se trouve dans la zone préférée (end_of_shift)
  // Sinon on applique un style particulier pour le signaler au dispatcheur
  if (!dock.ending_center && dock.tasks.length > 0) {
    const last_task = dock.tasks[dock.tasks.length - 1];
    const dpt = last_task.address.postal_code.substring(0, 2);
    if (!driver.end_of_shift.includes(dpt)) {
      style_danger = {
        "backgroundColor": "red",
        "color": "white",
      };
    }
    
  }

  let zones = driver.end_of_shift.join(",");
  const tooltip = (<Tooltip id={"tooltip_driver_eos_" + driver.id}>{`Zone de livraison pour la fin de tournée`}</Tooltip>);

  return <span className="DriverEndOfShift">
    <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <span style={style_danger}>({zones})</span>
    </OverlayTrigger>
  </span>;

};

DriverEndOfShift.propTypes = {
  dock: PropTypes.object.isRequired
};

export default DriverEndOfShift;



