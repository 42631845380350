import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Form, FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {SUPPORT_MODAL_HIDE, SUPPORT_ORDER_PREPZONE_UPDATE} from "../../../actions";

import {
  TYPE_TROLLEY,
  TYPE_DRIVER,
  connectTransitAreaModalAction
} from "../../../../../utils/transit_areas";


class InternalOrderPrepZoneMarkDoneModal extends React.Component {


  render() {
    const {
      transit_area_types,
      trolleys,
      modal,
      hide,
      submit,
      validateTransitAreaType,
      validateTransitAreaName,
      validateTrolley,
      validateNumberOfBagsIso,
      validateNumberOfCrates,
    } = this.props;

    if (!modal || modal.modalType !== "OrderPrepZoneMarkDoneModal") {
      return null;
    }
    if (!modal.opz) {
      return null;
    }

    let iv = modal.opz ? {
      pseudo_zone_id: modal.opz.pseudozone_id,
      number_of_bags_isotherm: modal.opz.nob_iso ? modal.opz.nob_iso : '',
      number_of_crates: modal.opz.nb_crates ? modal.opz.nb_crates : '',
      transit_subarea: modal.opz.transit_subarea,
      transit_area_type: "",
      trolley_id: "",
      is_done: true,
    } : {};

    return <Modal
      show={modal && modal.modalType === 'OrderPrepZoneMarkDoneModal'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          initialValues={iv}
          onSubmit={(values, actions) => {
            submit(modal.order, values);
          }}
        >
          {({errors, touched, values, handleSubmit}) => {
            return <form onSubmit={handleSubmit} className="form-horizontal">

              {!modal.opz.pseudozone.bag_apart && <div className="alert alert-info">Une fois la commande closée, la modification du nombre de sac pour cette zone ne sert à rien</div>}

              {modal.opz.pseudozone.bag_apart ? 
                <FormGroup>
                  <Form.Label>Nombre de sac isotherme</Form.Label>
                  <Field
                    type="number"
                    min="0"
                    max="999"
                    step="1"
                    name="number_of_bags_isotherm"
                    className="form-control"
                    validate={validateNumberOfBagsIso}
                    value={values.number_of_bags_isotherm}
                  />
                  {errors.number_of_bags_isotherm && touched.number_of_bags_isotherm && <p style={{color: "red"}}>{errors.number_of_bags_isotherm}</p>}
                </FormGroup>
                : <FormGroup>
                  <Form.Label>Nombre de cagettes</Form.Label>
                  <Field
                    type="number"
                    min="0"
                    max="999"
                    step="1"
                    name="number_of_crates"
                    validate={validateNumberOfCrates}
                    value={values.number_of_crates}
                    className="form-control"
                  />
                  {errors.number_of_crates && touched.number_of_crates && <p style={{color: "red"}}>{errors.number_of_crates}</p>}
                </FormGroup>}

              <FormGroup key="transit_area_type">
                <label>Type de dépôt</label>
                <Field
                  component="select"
                  name="transit_area_type"
                  className="form-control"
                  required="required"
                  validate={validateTransitAreaType}
                  value={values.transit_area_type}
                >
                  <option value="">-- Sélectionner un type --</option>
                  {Object.keys(transit_area_types).map((type_id, key) => {
                    return <option key={key} value={type_id}>{transit_area_types[type_id]}</option>;
                  })}
                </Field>
                {errors.transit_area_type && touched.transit_area_type && <p style={{color: "red"}}>{errors.transit_area_type}</p>}
              </FormGroup>
              {parseInt(values.transit_area_type, 10) !== TYPE_TROLLEY && parseInt(values.transit_area_type, 10) !== TYPE_DRIVER && 
                <FormGroup key="transit_subarea">
                  <label>Zone de dépôt</label>
                  <Field
                    type="text"
                    name="transit_subarea"
                    className="form-control"
                    required="required"
                    validate={validateTransitAreaName}
                  />
                  {errors.transit_subarea && touched.transit_subarea && <p style={{color: "red"}}>{errors.transit_subarea}</p>}
                </FormGroup>
              }
              {parseInt(values.transit_area_type, 10) === TYPE_TROLLEY && 
                <FormGroup key="trolley_id">
                  <label>Chariot</label>
                  <Field
                    component="select"
                    name="trolley_id"
                    className="form-control"
                    required="required"
                    validate={validateTrolley}
                  >
                    <option value="">-- Sélectionner un chariot --</option>
                    {trolleys.map((trolley, key) => {
                      return <option key={key} value={trolley.id}>{trolley.full_name}</option>;
                    })}
                  </Field>
                  {errors.trolley_id && touched.trolley_id && <p style={{color: "red"}}>{errors.trolley_id}</p>}
                </FormGroup>
              }
              <FormGroup>
                <button className="btn btn-primary btn-small float-right" type="submit">
                  VALIDER
                </button>
              </FormGroup>
            </form>;
          }}
        </Formik>
      </Modal.Body>
    </Modal>;

  }
}

InternalOrderPrepZoneMarkDoneModal.propTypes = {
  modal: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  pseudoZones: PropTypes.array,
  transit_area_types: PropTypes.object,
  trolleys: PropTypes.array,
  cancelSubmit: PropTypes.func,
  submit: PropTypes.func,
  validateTransitAreaType: PropTypes.func,
  validateTransitAreaName: PropTypes.func,
  validateTrolley: PropTypes.func,
  validateNumberOfBagsIso: PropTypes.func,
  validateNumberOfCrates: PropTypes.func,
};

export const OrderPrepZoneMarkDoneModal = connect(
  (state) => ({
    "modal" : state.support.modal,
    "trolleys": state.support.meta.trolleys,
    "transit_area_types": state.support.meta.transit_area_types,
  }),
  (dispatch) => {
    let datas = connectTransitAreaModalAction(dispatch);
    return {
      ...datas,
      "hide": () => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
      },
      "validateNumberOfBagsIso": (value) => {
        let error;
        if (value === '') {
          error = "Veuillez renseigner le nombre de sacs isotherme";
        }
        return error;
      },
      "validateNumberOfCrates": (value) => {
        let error;
        if (value === '') {
          error = "Veuillez renseigner le nombre de cagettes";
        }
        return error;
      },
      "submit": (order, prepZone) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch({'type': SUPPORT_ORDER_PREPZONE_UPDATE, center_id:order.center, orderId: order.id, prepZone: prepZone});
      }
    };
  }
)(InternalOrderPrepZoneMarkDoneModal);
