import { withFormik } from "formik";
import { connect } from "react-redux";
import moment from "moment";

import { TYPE_DRIVER, TYPE_TROLLEY } from "../../../../../utils/transit_areas";
import { findAddress } from "../../../utils/address";
import { resetForRedelivery } from "../../../actions/deliveries";

import RedeliverForm from "../../../../../common-comps/RedeliverForm";

const RedeliverFormEnhanced = withFormik({
  mapPropsToValues: ({ slots, addresses, order, address }) => ({
    slots,
    address,
    addresses,
    order,
    number_of_bags_isotherm: '',
    number_of_bags_dry: '',
    transit_area_name: '',
  }),
  handleSubmit: (
    {
      order,
      shipping_address_id: shippingAddressIdValue,
      transit_area_type: transitAreaTypeValue,
      transit_area_name,
      trolley_id,
      slot: slotValue,
      number_of_bags_dry,
      number_of_bags_isotherm,
    },
    { props: { slots, address, addresses, dispatch }, setSubmitting }
  ) => {
    // Generate shipping_time and max_shipping_time
    let slot = slots[slotValue];
    let shipping_time = moment(slot.value, 'X').format();
    let max_shipping_time = moment(slot.max_delivery_time, 'X').format();
    const shipping_address_id = shippingAddressIdValue
      || findAddress(address, addresses).id;

    const transit_area_type = parseInt(transitAreaTypeValue, 10);

    const locationData = { transit_area_type };

    if (transit_area_type === TYPE_TROLLEY) {
      locationData['trolley_id'] = parseInt(trolley_id, 10);
    }

    if(![TYPE_DRIVER, TYPE_TROLLEY].includes(transit_area_type)) {
      locationData['transit_area_name'] = transit_area_name;
    }

    let data = {
      shipping_time,
      max_shipping_time,
      shipping_address_id,
      number_of_bags: number_of_bags_dry + number_of_bags_isotherm,
      number_of_bags_isotherm: number_of_bags_isotherm,
      ...locationData
    };
    dispatch(resetForRedelivery(order.id, data));
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(RedeliverForm);

export default connect(
  state => ({
    pseudoZones: state.dispatch.pseudoZones,
    trolleys: state.dispatch.metas.trolleys,
    transit_areas: state.dispatch.transit_areas,
    trolley_locations: state.dispatch.metas.trolley_locations,
    transit_area_types: state.dispatch.metas.transit_area_types,
  })
)(RedeliverFormEnhanced);
