import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Modal, Button } from "react-bootstrap";

import { api } from "../../../../api";
import { DISPATCH_MAIN_MODAL_HIDE } from "../../actions/modal";
import { isothermBagReturn } from "../../actions/driver";
import ReturnBagsDepositForm from "./ReturnBagsDepositForm";

const MODAL_TYPE = "return_bags_deposit_modal";

const ReturnBagsDepositModal = ({ modal, isothermBagReturn, onHide }) => {
  const initialState = {
    loading: true,
    remainingDebts: [],
    formValues: {},
    depositLabelByKey: {},
    errorMessage: null
  };
  const [modalState, setModalState] = useState({ ...initialState });

  useEffect(() => {
    if (modal && modal.modalType === MODAL_TYPE) {
      const { driver } = modal;

      const fetchDebtQuantity = async (driverId) => {
        try {
          const { debts: remainingDebts } = await api.getHiddenProductsToReturn(
            driverId
          );
          const hiddenFormValues = remainingDebts.reduce(
            (obj, [depositKey, , quantity]) => {
              obj[depositKey] = quantity;
              return obj;
            },
            {}
          );

          const depositLabelByKey = remainingDebts.reduce(
            (obj, [depositKey, depositLabel]) => {
              obj[depositKey] = depositLabel;
              return obj;
            },
            {}
          );

          setModalState({
            ...modalState,
            loading: false,
            remainingDebts,
            depositLabelByKey,
            formValues: hiddenFormValues,
          });
        } catch (e) {
          const errorMessage = e.response && e.response.data.detail
            ? e.response.data.detail
            : "Une erreur s'est produite, veuillez réessayer"
          setModalState({
            ...modalState,
            loading: false,
            errorMessage,
          })
          return
        }
      };

      driver && fetchDebtQuantity(driver.id);
    }

    return () => setModalState({ ...initialState });
  }, [modal.modalType, modal.driver]);

  if (!modal) {
    return null;
  }

  const { modalType, driver } = modal;
  if (!driver) {
    return null;
  }

  const {
    loading,
    formValues,
    remainingDebts,
    depositLabelByKey,
    errorMessage,
  } = modalState;

  const handleSubmit = (values) => {
    const filteredQuantityByDepositLabel = Object.fromEntries(
      Object.entries(values).filter(
        ([, quantity]) => quantity > 0
      ).map(([key, quantity]) => [depositLabelByKey[key], quantity])
    );
    isothermBagReturn(driver, filteredQuantityByDepositLabel);
  };

  return (
    <Modal
      show={modalType === MODAL_TYPE}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>Retour - {driver.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div className="my-3">
            {Object.keys(formValues).length ? (
              <span><strong>{driver.name}</strong> retourne:</span>
            ) : null}
          </div>
          <a
            className="px-1 btn btn-sm btn-default"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_DELEEVADMIN}/deposit/driver_history/${driver.id}`}
          >
            Voir l'historique
          </a>
        </div>
        {loading ? (
          <div>Chargement...</div>
        ) : errorMessage
          ? <Alert variant="danger">{errorMessage}</Alert>
          : Object.keys(formValues).length
            ? (
              <ReturnBagsDepositForm
                formValues={formValues}
                remainingDebts={remainingDebts}
                handleSubmit={handleSubmit}
              />
            ) : (
              <Alert variant="success">
                <strong>{driver.name}</strong> n'a rien à retourner.
              </Alert>
            )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ReturnBagsDepositModal.propTypes = {
  modal: PropTypes.object,
  isothermBagReturn: PropTypes.func,
  onHide: PropTypes.func,
};

export default connect(
  (state) => ({
    modal: state.dispatch.modal,
  }),
  (dispatch) => ({
    isothermBagReturn: (driver, quantityByDepositLabel) =>
      dispatch(isothermBagReturn(driver, quantityByDepositLabel)),
    onHide: () => dispatch({ type: DISPATCH_MAIN_MODAL_HIDE }),
  })
)(ReturnBagsDepositModal);
