import React from 'react';
import PropTypes from 'prop-types';
import { Form } from "react-bootstrap";

import "../../../css/Dock.css";
import {
  DOCK_TYPE_STUART,
  DOCK_TYPE_HARD_ASSIGNED,
  // DOCK_TYPE_SOFT_ASSIGNED,
  DOCK_TYPE_UBER,
  STATUS_READY_TO_LOAD,
  isDeletable
} from "../../../utils/dock_n_driver";
import StuartDockCallButton from '../../../../../common-comps/StuartDockCallButton';
import {BasicDockName} from "./BasicDockName";
import {DriverDockName} from "./DriverDockName";
import DockSetting from "./DockSetting";
// import DockReturnSetting from "./DockReturnSetting";
import DockStuart from '../../../../../components/DockStuart';
import DockUber from '../../../../../components/DockUber';


const DockTitle = ({
  authUser,
  dock,
  onChangeEndingCenter,
  onCloseDock,
  onChangeMainSetting,
  onCancelStuart,
  onCancelUber,
  onRegularizeStuart,
  onRegularizeUber,
  onCallStuart,
}) => {

  let formClassNames = "";
  if (dock.updating) {
    formClassNames += " updating";
  }

  let displayed_name = <BasicDockName dock={dock} user={authUser}/>;
  // case freelance driver
  if (dock.target_driver && dock.driver !== undefined) {
    displayed_name =  (<DriverDockName dock={dock} user={authUser} />);
  }

  let admin_opti_url = process.env.REACT_APP_DELEEVADMIN + "/logistics/stats/optimization/dock/?dock_id=" + dock.id;


  let nbBags = 0;
  if (dock.tasks.length > 0 && dock.loaded_at) {
    nbBags = dock.tasks.reduce((allBags, t) => {
      let zoneBags = 0;
      // sac de zone
      if (t.linked_order.pseudo_zones_status.length > 0) {
        zoneBags = t.linked_order.pseudo_zones_status.reduce((accu, pzs) => {
          return pzs.is_bag_picking_late ? pzs.nb_crates + pzs.nob_iso : 0;
        }, 0);
      }
      // sac de la commande
      return allBags + t.linked_order.number_of_bags + zoneBags;
    }, 0);
  }

  const canCallStuartOnDock = !dock.target_driver
    && !([DOCK_TYPE_STUART, DOCK_TYPE_UBER]).includes(dock.dock_type)
    && dock.current_status === STATUS_READY_TO_LOAD
    && dock.tasks.length
    && dock.tasks.every(t => !!t.linked_order.canteen_id);

  return (<div className="DockTitle" data-dock-id={dock.id}>
    <span className="dock_name">{displayed_name}</span>
    {nbBags ? <span style={{marginLeft:"5px"}} className={"nb-bags"}>{nbBags} sacs</span> : null}
    {canCallStuartOnDock && <StuartDockCallButton dock={dock}>Stuart</StuartDockCallButton>}
    {
      dock.target_driver
      && authUser
      && authUser.groups
      && (authUser.groups.is_manager || authUser.groups.is_super_admin)
        ? (
          <a href={admin_opti_url} className="link_admin" title="Détails d'optimisation">
            <i className="fa-solid fa-gear"></i>
          </a>
        )
        : null
    }
    <Form inline className={formClassNames}>
      {dock.dock_type === DOCK_TYPE_STUART ?
        <DockStuart
          dock={dock}
          onCallStuart={onCallStuart}
          onCancelStuart={onCancelStuart}
          onRegularizeStuart={onRegularizeStuart}
        /> : null}

      {dock.dock_type === DOCK_TYPE_UBER ?
        <DockUber
          dock={dock}
          onCancelUber={onCancelUber}
          onRegularizeUber={onRegularizeUber}
        /> : null}

      {![DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_STUART].includes(dock.dock_type) && !dock.locked_at ?
        <DockSetting dock={dock} onChangeMainSetting={onChangeMainSetting}/> : null}

{/* 
      {dock.dock_type === DOCK_TYPE_HARD_ASSIGNED || dock.dock_type === DOCK_TYPE_SOFT_ASSIGNED ?
        <DockReturnSetting dock={dock} onChangeEndingCenter={onChangeEndingCenter}/> : null
      } */}

      {isDeletable(dock) && !dock.isLock ?
        <button className="btn btn-xs btn-danger" onClick={() => onCloseDock(dock)}>x</button> : null}
      {isDeletable(dock) && dock.isLock ?
        <button className="btn btn-xs btn-default" onClick={() => false}>x</button> : null}
    </Form>
  </div>);
};


DockTitle.propTypes = {
  authUser: PropTypes.object.isRequired,
  dock: PropTypes.object.isRequired,
  onChangeEndingCenter: PropTypes.func.isRequired,
  onCloseDock: PropTypes.func,
  onChangeMainSetting: PropTypes.func,
  onCancelUber: PropTypes.func,
  onRegularizeUber: PropTypes.func,
  onCancelStuart: PropTypes.func,
  onCallStuart: PropTypes.func,
  onRegularizeStuart: PropTypes.func,
};

export default DockTitle;
