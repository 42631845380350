import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormGroup} from "react-bootstrap";

import "../../../css/DockSetting.css";
import {DOCK_TYPE_FIRST, DOCK_TYPE_SOFT_ASSIGNED} from "../../../utils/dock_n_driver";


const DockSetting = ({dock, onChangeMainSetting}) => {

  let currentValue = dock.dock_type;
  if (dock.dock_type === DOCK_TYPE_FIRST && dock.target_vehicle) {
    currentValue += '_' + dock.target_vehicle;
  }
  if (dock.dock_type === DOCK_TYPE_SOFT_ASSIGNED && dock.target_driver) {
    currentValue = dock.target_driver;
  }
  return (<FormGroup className="DockSetting">
    <FormControl
      style={{ width: 80 }}
      disabled={!!dock.updating}
      as="select"
      value={currentValue}
      onChange={evt => onChangeMainSetting(dock, evt.target.value)}>
      {dock.configChoices.map(e => (<option key={e.value} value={e.value}>{e.label}</option>))}
    </FormControl>
  </FormGroup>);
};

DockSetting.propTypes = {
  dock: PropTypes.object.isRequired,
  onChangeMainSetting: PropTypes.func.isRequired,
};

export default DockSetting;
