import React from "react";
import moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import DriverPlanning from "../../Driver/DriverPlanning";
import DriverVehicle from "../../Driver/DriverVehicle";
import DriverEndOfShift from "../../Driver/DriverEndOfShift";
import {GoCancel} from "./GoCancel";
import {LinkMapsRound} from "./LinkMapsRound";
import {BtnDefine} from "./BtnDefine";
import {BtnExtractTask} from "./BtnExtractTask";
import {BtnSwitchTasks} from "./BtnSwitchTasks";
import BtnOpenModalExtraCost from "../../common-comps/BtnOpenModalExtraCost";
import {BtnOptimize} from "./BtnOptimize";
import {DriverQueue} from "../../Driver/DriverQueue";
import DriverName from "../../common-comps/DriverName";
import {BtnEstimateKO} from "./BtnEstimateKO";


// eslint-disable-next-line react/prop-types
export const DriverDockName = ({dock, user}) => {
  const isAdminUser = user.groups && user.groups.is_manager;
  const tooltip = (<Tooltip id={"tooltip_dock_" + dock.id}>{dock.driver.mobile}</Tooltip>);
  return (
    <span className="DriverDockName">
      {dock.scheduled_round && <span style={{ margin: '0 2px' }} title={`[${dock.scheduled_round.id}] ${dock.scheduled_round.name}`}>-{dock.scheduled_round.code} ({moment(dock.scheduled_round.cut_off, ['h:m a', 'H:m']).format('HH:mm')})</span>}
      <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
        <Link to={"/logs/driver/" + dock.driver.id + "/date/0"}>
          <DriverName isLgep={dock.driver.lgep_orders_allowed}>
            <span>{dock.name}</span>
          </DriverName>
        </Link>
      </OverlayTrigger>
      <DriverPlanning driver={dock.driver}/>
      <DriverVehicle driver={dock.driver}/>
      <DriverQueue dock={dock} />
      {dock.driver.pay_mode === 'perf_and_waiting' && <strong className="ml-1 alert-info">P&A</strong>}
      <DriverEndOfShift dock={dock} />
      <GoCancel dock={dock}/>
      <BtnDefine dock={dock}/>
      <BtnExtractTask dock={dock} style={{marginRight:"5px"}} />
      <BtnSwitchTasks dock={dock} style={{marginRight:"5px"}} />
      <BtnOpenModalExtraCost dock={dock} />
      {isAdminUser && <BtnOptimize dock={dock} style={{marginRight: "5px"}}/>}
      {dock.defined_at && <BtnEstimateKO dock={dock} style={{marginRight: "5px"}}/>}
      <LinkMapsRound dock={dock}/>
    </span>
  );
};
