import {USER_LOAD, USER_LOADED} from "../actions/user";

const userInitialState = {is_staff:true, is_logistic:false};
export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
  case USER_LOAD:
    return state ? state : userInitialState;
  case USER_LOADED:
    return action.user;
  default:
    return state;
  }
};

