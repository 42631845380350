import React from 'react';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import {MAIN_MODAL_HIDE} from "../../../actions/modal";


class InternalModal extends React.Component {
  render() {
    const {modal, hide } = this.props;
    /*, validate*/

    if (!modal.order) {
      return null;
    }


    return <Modal
      show={modal && modal.modalType === 'order_missing_product'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>
          Missing...
        </h4>
        blabla
      </Modal.Body>
    </Modal>;
  }
}


export const OrderMissingProductModal = connect(
  (state) => ({
    "modal" : state.m.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MAIN_MODAL_HIDE});
      },
    };
  }
)(InternalModal);

