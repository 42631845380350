import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paginator } from '../../../../common-comps/Paginator';
import { DepositDriverHistoryDetail } from './DepositDriverHistoryDetail';
import { DepositDriverHistoryCancelReturnButton } from './DepositDriverHistoryCancelReturnButton';
import { DepositDriverCancelReceivedButton } from './DepositDriverCancelReceivedButton';
import {act_loadDepositDriver} from  "../../actions/deposit_driver";

import {rd} from "../../../../utils";

const Internal = ({driver, deposit_driver, handlePagination}) => {

  return <div className="DepositDriverHistory">
    {deposit_driver.datas.count > 0 &&
      <div className="deposit_history">
        <Paginator data_object={driver} paginated_api_datas={deposit_driver.datas} handlePagination={handlePagination} />
        <table className="table no-border table-condensed">
          <thead>
            <tr>
              <th></th>
              <th>Le</th>
              <th>Produit</th>
              <th>Qté</th>
              <th>Montant</th>
              <th>Par</th>
              <th>Détail</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deposit_driver.datas.results.map(deposit => (
              <tr key={deposit.id}>
                <td>
                  { deposit.is_received_canceled ?
                    <span className="badge deposit-cancel">annul. remise</span>
                    : deposit.is_returned_canceled ?
                      <span className="badge deposit-fix">annul. retour</span>
                      : deposit.is_received ?
                        <span className="badge deposit-user">remise</span>
                        : <span className="badge deposit-driver">retour</span>
                  }
                  {deposit.status.cancel_status === 'partial' ?
                    <span className="badge deposit-fix-partial">annulation partiel.</span>
                    : deposit.status.cancel_status === 'total' ?
                      <span className="badge deposit-fix-total">annulé</span>
                      : null
                  }

                </td>
                <td>{ deposit.formated_created_at }</td>
                <td>
                  <img alt={deposit.product_infos.deposit_label} src={ deposit.product_infos.picture_thumbnail_url } width="40" />
                  <a href={process.env.REACT_APP_PRODUCTS + `/products/${deposit.product}`} 
                    target="_blank" rel="noopener noreferrer"
                    title={`#${deposit.product} - ${deposit.product_infos.selling_name}`}>
                    { deposit.product_infos.deposit_label }
                  </a>
                </td>
                <td>
                  { deposit.quantity } x { rd(1 * deposit.deposit_unit_price) }€
                  {deposit.status.cancel_status === 'partial' && deposit.status.quantity_can_return > 0 && <span><br />(x{deposit.status.quantity_can_return} à rendre)</span>}
                </td>
                <td>{ rd(deposit.quantity * deposit.deposit_unit_price) }€</td>
                <td>
                  { deposit.user && <span>reçu de <a href={process.env.REACT_APP_DELEEVADMIN + `/users/${deposit.user}`} target="_blank" rel="noopener noreferrer">{ deposit.user_infos.full_name }</a></span>}
                  { deposit.done_by && <span>par { deposit.done_by_infos.full_name }</span>}
                  
                </td>
                <td>
                  <DepositDriverHistoryDetail driver={driver} deposit={deposit} />
                </td>
                <td>
                  <DepositDriverHistoryCancelReturnButton deposit={deposit} />
                  { deposit.user ? <DepositDriverCancelReceivedButton deposit={deposit} /> : null }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Paginator data_object={driver} paginated_api_datas={deposit_driver.datas} handlePagination={handlePagination} />
      </div>}
  </div>;
};
Internal.propTypes = {
  driver: PropTypes.object.isRequired,
  deposit_driver: PropTypes.object.isRequired,
  handlePagination: PropTypes.func.isRequired,
};

export const DepositDriverHistory = connect(
  (state) => {
    return {
      deposit_driver: state.dispatch.deposit.driver,
    };
  },
  (dispatch) => {
    return {
      "handlePagination": (driver, offset) => {
        dispatch(act_loadDepositDriver(driver.id, offset));
      }
    };
  }
)(Internal);
