import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Alert } from "react-bootstrap";
import { Formik } from "formik";

const ReviewLateOrderForm = ({
  formValues,
  loading,
  handleSubmit,
  estimatedSlot,
}) => (
  <React.Fragment>
    {estimatedSlot &&
      <Alert variant="info">Livraison estimée {estimatedSlot}</Alert>
    }
    <Formik initialValues={formValues}>
      {({ values, handleChange }) => (
        <Form>
          <Form.Group>
            <Form.Label>Montant à créditer:</Form.Label>
            <Form.Control
              type="number"
              id="credits"
              name="credits"
              onChange={handleChange}
              value={values.credits}
              min={0}
              step="0.01"
            />
          </Form.Group>
          {estimatedSlot === false
            ? (
              <Form.Group>
                <Form.Label>Temps avant livraison (en minutes):</Form.Label>
                <Form.Control
                  type="number"
                  id="delay"
                  name="delay"
                  onChange={handleChange}
                  value={values.delay}
                  min={0}
                />
              </Form.Group>
            ) : null
          }
          <Button
            variant="primary"
            size="sm"
            disabled={loading}
            onClick={() => handleSubmit(values)}
            className="mx-4"
          >
            Envoyer un SMS
          </Button>
        </Form>
      )}
    </Formik>
  </React.Fragment>
);

ReviewLateOrderForm.propTypes = {
  formValues: PropTypes.object,
  estimatedSlot: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default ReviewLateOrderForm;
