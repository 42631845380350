import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

import { MAIN_MODAL_HIDE } from "../../actions/modal";
import { orderToRestock, validateRestock } from "../../actions/canceled_no_shipping";
import { WAITING_RESTOCK_FLAG_ID } from "../../utils/task";

const RESTOCK_CHOICE = 'restock';
const WAITING_RESTOCK = 'waiting_restock';

const TITLE_MAPPING = {
  [RESTOCK_CHOICE]: ': Restock',
  [WAITING_RESTOCK]: ': Attente de restockage',
};

const RestockModal = ({
  modal,
  dispatch,
}) => {
  const initialState = {
    selectedChoice: null,
    loading: false
  };

  const [modalState, setModalState] = useState({...initialState});
  useEffect(() => {
    if (
      modal && modal.modalType === 'redeliver_or_restock'
      && modal.task
    ) {
      if (modal.task.flagged_orders.find(of => of.flag_id === WAITING_RESTOCK_FLAG_ID)) {
        setModalState({...modalState, selectedChoice: WAITING_RESTOCK});
      }
    }
    return () => setModalState(initialState);
  }, [modal]
  );

  if (!modal || !modal.task) {
    return null;
  }

  const onHide = () => dispatch({ type: MAIN_MODAL_HIDE });

  const { modalType, task } = modal;
  const { linked_order: order } = task;

  let titleLabel = `Commande #${order.random_id}`;

  if (TITLE_MAPPING[modalState.selectedChoice]) {
    titleLabel += TITLE_MAPPING[modalState.selectedChoice];
  }

  return (
    <Modal
      show={modalType === "redeliver_or_restock"}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton className="py-2">
        <Modal.Title>Relivrer ou restocker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className="mb-4">{titleLabel}</h4>
        </div>
        {!modalState.selectedChoice && (
          <React.Fragment>
            <div className="d-flex justify-content-center mx-4 my-2">
              <ul>
                <li>
                  <strong>{order.number_of_bags} {order.number_of_bags > 1 ? 'sacs' : 'sac'} </strong>
                  ({order.number_of_bags - order.number_of_bags_isotherm} sec, {order.number_of_bags_isotherm} frais)
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-center mx-4 my-2">
              <Button
                variant="warning"
                className="px-2 mx-3"
                onClick={() => dispatch(orderToRestock(order.id))}
              >
                Restocker
              </Button>
            </div>
          </React.Fragment>
        )}
        {modalState.selectedChoice === WAITING_RESTOCK && (
          <div>
            <div className="alert alert-info">
              <p>
                La commande doit être restockée via la fonctionnalité
                <strong>&ldquo;Restocker par N° de commande&ldquo;</strong>
                sur un PDA.
              </p>
              <p>
                <strong>Si les produits ont été restockés</strong>, vous pouvez
                valider en cliquant sur le bouton ci-dessous
                (la commande disparaitra du flow).
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                size="sm"
                className="mx-auto"
                onClick={() => dispatch(validateRestock(order.id))}
              >
                La commande a été restockée
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Button variant="secondary" size="sm" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RestockModal.propTypes = {
  modal: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(
  state => ({
    modal: state.m.modal,
  })
)(RestockModal);
