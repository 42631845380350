export const STATUS_ORDER_PAID = 'paid';
export const STATUS_ORDER_PREP = 'prep';
export const STATUS_ORDER_PREPARED = 'prepared';
export const STATUS_ORDER_SHIPPING = 'shipping';
export const STATUS_ORDER_RECEIVED = 'received';
export const STATUS_ORDER_UNDELIVERED_CLIENT = 'undeliveredclient';
export const STATUS_ORDER_NOW = 'now';
export const STATUS_ORDER_LATER = 'later';
export const STATUS_ORDER_IMPORTANT = 'important';
export const STATUS_ORDER_TO_CHECK = 'to-check';
export const STATUS_ORDER_WAITING_PRODUCTS = 'waiting-products';
export const STATUS_ORDER_CANCELED = 'canceled';
export const STATUS_ORDER_RESTOCK = 'restock';
export const STATUS_ORDER_REDESTOCK = 'redestock';
export const STATUS_ORDER_BLOCKED = 'blocked';
export const STATUS_ORDER_STAND_BY = 'stand-by';
export const STATUS_ORDER_XD_TO_CHECK = 'xd-to-check';
export const STATUS_ORDER_UNPAID = 'unpaid';


export const  orderStatus = [
  { value: STATUS_ORDER_PAID,       text: 'PAYÉE'},
  { value: STATUS_ORDER_PREP,       text: 'EN PRÉP.'},
  { value: STATUS_ORDER_PREPARED,   text: 'FINALISÉE'},
  { value: STATUS_ORDER_SHIPPING,   text: 'EN LIVRAISON'},
  { value: STATUS_ORDER_RECEIVED,   text: 'LIVRÉE',},
  { value: STATUS_ORDER_UNDELIVERED_CLIENT, text: 'PASLÀ' },
  { value: STATUS_ORDER_NOW,        text: 'PREP. MNT.'},
  { value: STATUS_ORDER_LATER,      text: 'PREP. + TARD'},
  { value: STATUS_ORDER_IMPORTANT,  text: 'IMPORTANT' },
  { value: STATUS_ORDER_TO_CHECK,   text: 'À VÉRIF.'},
  { value: STATUS_ORDER_WAITING_PRODUCTS, text: 'ATTENTE PROD.'},
  { value: STATUS_ORDER_XD_TO_CHECK, text: 'CROSSDOCKING À VÉRIF.'},
  { value: STATUS_ORDER_REDESTOCK,    text: 'DESTOCK (RE)', end: true},
  { value: STATUS_ORDER_CANCELED,   text: 'ANNULÉE', end: true},
  { value: STATUS_ORDER_RESTOCK,    text: 'RESTOCK', end: true},
  { value: STATUS_ORDER_BLOCKED,    text: 'BLOQUÉE', end: true},
  { value: STATUS_ORDER_STAND_BY,   text: 'STAND BY' },
  { value: STATUS_ORDER_UNPAID, text: 'À PAYER'},
];

let statusMap = {};
for (let s  of orderStatus) {
  statusMap[s.value] = s.text;
}
export const orderStatusMap = statusMap;

export const simplePackingStatus = [
  { value: 'todo', text: 'TODO' },
  { value: 'doing', text: 'DOING' },
  { value: 'done', text: 'DONE' }
];

export const simpleShippingStatus = [
  { value: 'todo', text: 'TODO' },
  { value: 'shipping', text: 'DOING' },
  { value: 'returned', text: 'DONE' }
];

export function humanStatus(status) {
  return orderStatusMap[status];
}


export function newTaskStatus(t, newStatus) {
  let status_mapping = {
    'paid' : 'waiting',
    'now' : 'waiting',
    'later' : 'waiting',
    'prep' : 'waiting',
    'to-check': 'waiting',
    'waiting-products': 'waiting',
    'important' : null,
    'prepared' : 'todo',
    'shipping': 'shipping',
    'received': 'received',
  };

  if (t.packer) {
    status_mapping = {
      'paid' : 'todo',
      'prep' : 'doing',
      'to-check': 'to-check',
      'waiting-products': 'waiting-products',
      'prepared' : 'done',
      'now' : 'todo',
      'later' : 'todo',
      'important' : null,
    };
  }

  if (!status_mapping.hasOwnProperty(newStatus)) {
    console.error('newTaskStatus, not found : ', t, newStatus);
  }
  return (status_mapping.hasOwnProperty(newStatus) ? status_mapping[newStatus] : '');
}

export function getStatusDiff(t , newStatus) {

  const beforeShipping = [
    STATUS_ORDER_PAID,
    STATUS_ORDER_PREP,
    STATUS_ORDER_CANCELED,
    STATUS_ORDER_RESTOCK,
    STATUS_ORDER_WAITING_PRODUCTS,
    STATUS_ORDER_TO_CHECK,
    STATUS_ORDER_LATER,
    STATUS_ORDER_NOW,
    STATUS_ORDER_PREPARED
  ];
  const shippingAndAfter = [
    STATUS_ORDER_SHIPPING,
    STATUS_ORDER_RECEIVED
  ];

  let diff = {};

  if (t.dock && beforeShipping.includes(newStatus) && shippingAndAfter.includes(t.status)) {
    diff.ordering = 1;
    diff.dock = null;
  }

  if (!t.linked_order) {
    diff.status = newStatus;
  } else {
    // const ts = newTaskStatus(t, newStatus);
    // if (ts !== null) {
    //   diff.status = ts;
    // }
    diff.linked_order = Object.assign(t.linked_order, {status: newStatus});
  }
  return diff;
}

export function getCurrentStatus(tOrO) {
  if (!tOrO.linked_order) {
    return tOrO.status;
  }
  return tOrO.linked_order.status;
}

export function getStatuses() {
  let statuslist = [];
  for (const e of orderStatus) {
    if (!e.end) {
      statuslist.push(e);
    }
  }

  for (const e of orderStatus) {
    if (e.end) {
      statuslist.push(e);
    }
  }
  return statuslist;
}


export function askRepreparationReason(repreparationReasons){
  let promptText = "Veuillez choisir la raison de la repréparation de la commande :\n";
  let idsTri = Object.entries(repreparationReasons.SAME_ORDER_AVAILABLE).sort(
    // Tri par valeur
    ([,a], [,b]) => a-b
  )
  let i = 1;
  for(const [choiceId,] of idsTri) {
    promptText += "- Si \"" + repreparationReasons.ID_TO_LABEL[choiceId] + "\", tapez " + i + ".\n";
    ++i;
  }
  let promptResult = prompt(promptText);
  if(promptResult === null){
    alert("Modification du statut abandonnée.");
    return null;
  }
  if(promptResult === ""){
    alert("Valeur vide, modification du statut abandonnée.");
    return null;
  }
  let choiceIndex = parseInt(promptResult, 10);
  if(isNaN(choiceIndex)){
    alert("Valeur incorrecte, modification du statut abandonnée.");
    return null;
  }
  i = 1;
  let selectedChoiceId = -1;
  for(const [choiceId,] of idsTri) {
    if(choiceIndex === i) {
      selectedChoiceId = choiceId;
      break;
    }
    ++i;
  }
  if(selectedChoiceId === -1) {
    alert("Valeur inconnue, modification du statut abandonnée.");
    return null;
  }

  let promptText2 = "Veuillez saisir un commentaire pour la raison de la repréparation";
  if(repreparationReasons.MANDATORY_COMMENT[selectedChoiceId]){
    promptText2 += " (obligatoire)";
  }
  else {
    promptText2 += " (facultatif)";
  }
  let promptResult2 = prompt(promptText2);
  if(promptResult2 === null){
    alert("Modification du statut abandonnée.");
    return null;
  }
  if(promptResult2 === "" && repreparationReasons.MANDATORY_COMMENT[selectedChoiceId]){
    alert("Commentaire obligatoire, modification du statut abandonnée.");
    return null;
  }
  return {
    repreparation_reason_id: selectedChoiceId,
    repreparation_reason_comment: promptResult2,
  };
}


export default { getCurrentStatus, getStatuses };
