import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import { connect } from 'react-redux';

import {driverDepositMakeReturn} from  "../../actions";
import "../../css/Deposit.css";

const Internal = ({driver, deposit_driver, validate, handleSubmit}) => {

  let products = deposit_driver.products.filter(p => p.quantity_debt > 0);
  if (products.length === 0) {
    return <div><br /><p className="alert alert-info">{`Le livreur n'a plus de produit consigné en sa possession`}</p></div>;
  }
	
  return <div className="DepositDriverReturn">
    {products.length > 0 && 
      <div>
        <Row>
          <Col as={Form.Label} sm={8}>
            Produit
          </Col>
          <Col as={Form.Label} sm={2}>
            Qté
          </Col>
          <Col as={Form.Label} sm={2}>
          </Col>
        </Row>
        {products.map(prod => (
          <Formik 
            key={prod.id}
            initialValues={{
              quantity : 0,
              product_id: prod.id,
            }}
            onSubmit={(values, actions) => {
              if (validate(values)) {
                if (values.quantity < 0) {
                  if (!window.confirm('La quantité est négative. Le client ne sera pas remboursé. Êtes vous sûr ?')) {
                    return;
                  }
                }
                handleSubmit(driver.id, values);
                actions.resetForm();
              }
              actions.setSubmitting(false);
            }}
          >
            {props => (
              <div>
                <Form onSubmit={props.handleSubmit}>
                  <FormGroup as={Row} controlId="formControlsSelect">
                    <Col sm={8} className="deposit-flex">
                      <div className="deposit-center">
                        <Field name="product_id" type="hidden" className="form-control" component="input" />
                        <img src={prod.picture_thumbnail_url} alt={prod.deposit_label} width="50" />
                        {prod.deposit_label} (à rendre x {prod.quantity_debt})
                      </div>
                    </Col>
                    <Col sm={2} className="deposit-flex deposit-qty">
                      <div className="deposit-center">
                        <Field name="quantity" type="number" required="required" 
                          className="form-control" component="input" max={prod.quantity_debt} min="0" />
                        <span>max: {prod.quantity_debt}</span>
                      </div>
                    </Col>
                    <Col sm={2} className="deposit-flex">
                      <div className="deposit-center">
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>OK</button>
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            )}
          </Formik>
        ))}
      </div>
    }
  </div>;
};

Internal.propTypes = {
  driver: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  deposit_driver: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const DepositDriverReturn = connect(
  (state) => {
    return {
      deposit_driver: state.m.deposit.driver,
      "validate": (values) => {
        if (values.product_id && values.quantity && values.quantity > 0) {
          return true;
        }
        return false;
      },
    };
  },
  (dispatch) => {
    return {
      "handleSubmit": (driver_id, values) => {
        dispatch(driverDepositMakeReturn(driver_id, values.product_id, values.quantity));
      },
    };
  }
)(Internal);
