export function getTomorrowDate(hour=0) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(hour,0,0);
  return tomorrow;
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes*60000);
}
