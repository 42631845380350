import moment from "moment/moment";
import {sortByKey} from "./index";

export function filterAndSortDockTasks(tasks, dock_id) {
  return tasks.filter((t) => t.dock && t.dock.id === dock_id)
    .map(e => ({...e}))
    .sort(sortByKey('dock_rank'));
}

export function isLock(allTasks, dock) {
  if (allTasks.length && dock.defined_at && dock.dock_type === "NOCONFIG") {
    return true;
  }

  let isLock = dock.tasks.reduce((acc, curVal) => !!curVal.updating  || acc, !!dock.updating);
  if (allTasks.length) {
    isLock = !!allTasks.filter(e => e.dock_updating === dock.id).length || isLock;
  }
  return isLock;
}


export const isNow = (checkDate) => {
  // .isBefore(moment().add(5, "hours"));
  let today = moment();
  let checkDateMoment =  moment(checkDate);
  return today.isSame(checkDateMoment, "day") || checkDateMoment < today;
};


export const extractStuartLabels = (task) => {
  let st = {
    "ok": false,
  };
  if (task.dock && task.dock.external_job  && task.dock.external_job.driver_name) {
    st.ok = true;
    let status = task.external_job_delivery_status;
    let eta = '';
    const driver = task.dock.external_job.driver_name;
    const phone = task.dock.external_job.driver_phone;

    st.label =  `stuart:${status}`;

    if (status === 'delivering') {
      if (task.external_job_delivered_at) {
        eta = moment(task.external_job_delivered_at).tz("Europe/Paris").format('HH:mm');
      }
      st.label = `liv à:${eta}`;
    }
    if (status === 'picking') {
      st.label = 'pick';
    }
    st.driver = driver;
    st.phone = phone.replace('+33', '0');
  }
  return st;
};

export const getSortableDatetimeValue = (t) => {
  // Old
  // const etd_at =  t.linked_order.estimated_travel_departure_at;

  // New
  const etd_at =  t.linked_order.priority_rank;

  if (!etd_at) {
    console.log("etd is null ? ", t);
  }
  return etd_at ? etd_at : t.linked_order.shipping_time;
};

export const getShippingSortableDatetimeValue = (t) => {
  const max_shipping_time =  t.linked_order.max_shipping_time;
  if (!max_shipping_time) {
    console.log("etd is null ? ", t);
  }
  return max_shipping_time ? max_shipping_time : t.linked_order.shipping_time;
};

export const maxShippingSortingFinalized = (a, b) => {
  return maxShippingSorting(a, b);
};

export const maxShippingSorting = (a, b) => {
  let a_st = getSortableDatetimeValue(a);
  let b_st = getSortableDatetimeValue(b);

  // On trie du plus vieux en haut au plus récent en bas
  // (premier arrivé premier servi).

  if (a_st < b_st) {
    return -1;
  }
  if(a_st > b_st){
    return 1;
  }

  if(a.address.postal_code < b.address.postal_code){
    return -1;
  }
  if(a.address.postal_code > b.address.postal_code){
    return 1;
  }

  if (a.linked_order.id < b.linked_order.id) {
    return -1;
  }
  if(a.linked_order.id > b.linked_order.id){
    return 1;
  }
  return 0;
};

export const slotLabel = (taskOrOrder) => {
  let label = 'N/A';
  if(taskOrOrder.shipping_time) {
    const start_date = moment(taskOrOrder.shipping_time).tz("Europe/Paris");
    label = start_date.format('HH:mm') + ' - ??';
  }

  if(taskOrOrder.max_shipping_time) {
    const start_date = moment(taskOrOrder.shipping_time).tz("Europe/Paris");
    const end_date = moment(taskOrOrder.max_shipping_time).tz("Europe/Paris");
    label = start_date.format('HH:mm') + ' - ' + end_date.format('HH:mm');
    const order = taskOrOrder.linked_order || taskOrOrder;
    if (order && order.tags.includes('dqp_express_prio')) {
      label = 'QUICK+ ' + end_date.format('HH:mm');
    }
    else if (order && order.tags.includes('dqp_courier')) {
      label = 'BOLT ' + end_date.format('HH:mm');
    }
    else if (order && order.tags.includes('dqp_express')) {
      label = 'QUICK ' + end_date.format('HH:mm');
    }
    else if (taskOrOrder.is_speed) {
      label = 'SPEED ' + end_date.format('HH:mm');
    }
  }
  return label;
};

export const slotDepartureHourLabel = (order) => {
  let etdLabel = '';
  if (order.estimated_travel_departure_at) {
    const departureHour = (
        moment(order.estimated_travel_departure_at)
        .tz("Europe/Paris")
        .subtract(10, 'minutes')
        .format("HH:mm", "fr")
    );
    etdLabel = `D:${departureHour}max`;
  }
  return etdLabel
}

export const CANCELED_NO_SHIPPING_FLAG_ID = 2;
export const WAITING_RESTOCK_FLAG_ID = 3;

export  default  { filterAndSortDockTasks, slotLabel, slotDepartureHourLabel };
