import { createSelector } from 'reselect'

const SORT_BY_STATE = {
  'noplan': 12,
  'wait': 9,
  'run': 7,
  'plan': 5,
  'hidden': 0,
};

const getDrivers = state => state.m.drivers
const getLaunchpad = (state, props) => props.launchpad

export const getFilteredDrivers = createSelector(
  [getDrivers, getLaunchpad],
  (drivers, launchpad) => {
    return drivers.map(d => Object.assign({}, d))
      .filter(d => d.launchpad === launchpad)
      .sort((a, b ) => {
        if (a.state === b.state) {
          if (a.eta_comparable === b.eta_comparable ) {
            return (a.name <= b.name ? -1 : 1);
          } else {
            return (a.eta_comparable <= b.eta_comparable ? -1 : 1);
          }

        }
        return SORT_BY_STATE[a.state] > SORT_BY_STATE[b.state] ? -1 : 1;
      });
  }
)
