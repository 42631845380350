import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import {loadUserAddresses} from "../../../actions";
import { AddressRow } from './AddressRow';

import "../../../css/AddressesList.css";

class InternalAddressesList extends React.Component {

  componentDidMount() {
    this.props._loadUserAddresses(this.props.user.id);
  }

  componentDidUpdate() {
    if (
      typeof(this.props.user_addresses.shouldRefresh) !== 'undefined'
      && this.props.user_addresses.shouldRefresh === true
    ) {
      this.props._loadUserAddresses(this.props.user.id);
    }
  }

  render() {
    let user_addresses = this.props.user_addresses;

    console.log("User addresses : ", user_addresses);

    if (user_addresses.loading) {
      return <div className='AddressesList text-center'><br />Chargement...</div>;
    }

    if (user_addresses.datas.count === 0) {
      return <div className='AddressesList'><br /><div className="alert alert-info">{`Le client n'a aucune adresses`}</div></div>;
    }

    console.log("User addresses : ", user_addresses);
    return <Table size="sm" striped hover className='AddressesList'>
      <thead>
        <tr>
          <th className="col-address">Adresse</th>
          <th></th>
          <th></th>
          <th className="col-action"></th>
        </tr>
      </thead>
      <tbody>
        {user_addresses.datas.map((address, key) => {
          return <AddressRow key={key} address={address} />;
        })}
      </tbody>
    </Table>;
  }
}

InternalAddressesList.propTypes = {
  "user_addresses": PropTypes.object,
  "user": PropTypes.object,
  "_loadUserAddresses": PropTypes.func,
};

export const AddressesList = connect(
  (state) => ({
    "user_addresses": state.support.user_addresses,
    "user": state.support.user
  }),
  (dispatch) => {
    return {
      _loadUserAddresses: (id) => {
        return dispatch(loadUserAddresses(id));
      },
    };
  }
)(InternalAddressesList);
