import { createSelector } from 'reselect'
import {getShippingSortableDatetimeValue, getSortableDatetimeValue, maxShippingSorting} from "../utils/task";
import moment from "moment";
import 'moment/locale/fr';
import {currentToggleValue, PACKING_NOW_LONG_LIST_KEY} from "../utils/toggle";
import {stateToCenterId} from "../utils/center";

moment.locale('fr');

const getPackingToCheck = state => state.m.packing_to_check

export const getSortedPackingToCheck = createSelector(
  [getPackingToCheck],
  (packingToCheck) => {
      return packingToCheck.slice().sort(maxShippingSorting);
  }
)

const getPackingDoing = state => state.m.packing_doing

export const getSortedPackingDoing = createSelector(
  [getPackingDoing],
  (packingDoing) => {
      return packingDoing.slice().sort((a, b) => a.shipping_time < b.shipping_time ? -1 : 1);
  }
)

const getPackingUberDirect = state => state.m.packing_uber_direct
export const getSortedPackingUberDirect = createSelector(
  [getPackingUberDirect],
  (packingUberDirect) => {
    return packingUberDirect.slice().sort(maxShippingSorting)
  }
)

const getPackingLater = state => state.m.packing_later

const laterSortingRules = (a, b) => {

  if (a.day < b.day) {
    return -1;
  } else if (a.day > b.day) {
    return 1;
  }


  let a_shipping = getShippingSortableDatetimeValue(a);
  let b_shipping = getShippingSortableDatetimeValue(b);

  if (a_shipping < b_shipping) {
    return -1;
  } else if (a_shipping >= b_shipping) {
    return 1;
  }
};

export const getSortedPackingLater = createSelector(
  [getPackingLater],
  (packingLater) => {
      return packingLater.map((t) => ({...t, ...{day: moment(getSortableDatetimeValue(t)).format('YYYY-MM-DD')}}))
          .sort(laterSortingRules);
  }
)

const getPackingNow = state => state.m.packing_now
const getPackingNowLongListToggle = state => currentToggleValue(state.toggle, PACKING_NOW_LONG_LIST_KEY)

export const getSortedFilteredPackingNow = createSelector(
  [getPackingNow, getPackingNowLongListToggle],
  (packingNow, packingNowLongListToggle) => {
      packingNow = packingNow.filter((t) => !t.emergency_rank)
          .sort(maxShippingSorting);
      if (!packingNowLongListToggle) {
        packingNow = packingNow.slice(0,100);
      }
      return packingNow;
  }
)

export const getSortedEmergencyPackingNow = createSelector(
  [getPackingNow],
  (packingNow) => {
      return packingNow.filter((t) => t.emergency_rank)
          .sort((a, b) => a.emergency_rank < b.emergency_rank ? -1 : 1);
  }
)

const getCenterId = state => stateToCenterId(state);

export const getTitleLink = createSelector(
  [getCenterId],
  (centerId) => {
      return {url : `${process.env.REACT_APP_DELEEVADMIN}/queue/day_by_day?now=1&center_id=${centerId}`, label : 'liste de produits'};
  }
)

const getPackingWaiting = state => state.m.packing_waiting;

export const getSortedPackingWaiting = createSelector(
  [getPackingWaiting],
  (packingWaiting) => {
    return packingWaiting.slice().sort(maxShippingSorting);
  }
)

export const getTitleLinkMissing = createSelector(
  [getCenterId],
  (centerId) => {
    let url = `${process.env.REACT_APP_DELEEVADMIN}/orders_checking/missing_all/`;
    if (centerId) {
      url += '?logistics_center_id=' + centerId;
    }
    return {
      "url": url,
      "label": "produits manquants (tous)"
    };
  }
)
