import React from 'react';
import * as moment from "moment";
import { playAlarm} from "../utils/index";


export const Footer = () => {
  const tomorrow  = moment().add(1, 'days').format("YYYY-MM-DD");
  return (
    <div className="Footer" style={{marginBottom:"30px"}}>
      <a href={`https://admin.deleev.com/tadp/supplier-orders/${tomorrow}/`} >voir la commande TADP</a>
      <button className="btn btn-default btn-xs btn-primary ml-3" onClick={playAlarm}>Tester la sonnerie</button>
    </div>);
};


export default Footer;
