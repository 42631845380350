import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";

import {startActivity, endActivity} from "../../../actions/driver";

export const OtherActivityButton = connect()(
  ({driver , dispatch}) => {

    let action = startActivity;
    let label = "Autre activité : START";
    let style = "default";

    if (!driver) {
      return null;
    }

    if (Object.keys(driver.activity).length > 0) {
      action = endActivity;
      label = `${driver.activity.note} : STOP`;
      style = "primary";
    }

    return (
      <Button variant={style} size="sm" onClick={() => dispatch(action(driver))}>
        {label}
      </Button>
    );
  });
OtherActivityButton.propTypes = {
  driver: PropTypes.object
};
