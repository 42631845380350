import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

function RepreparationAlertSmall ({ alert, hideAlerts }) {
  const {
    id,
    created_at,
    order_id,
    order_random_id,
    trolley_area,
    transit_area,
    crates,
    reason_label,
    reason_comment,
  } = alert;

  return (
    <div className="alert alert-danger" key={id}>
      <p>
        {moment(created_at).format('HH:mm').replace(':', 'h')},
        <span></span>
        <a
          href={"/support/order/"+order_id}
          target="_blank" rel="noopener noreferrer"
          tabIndex="0"
        >
        #{order_random_id}
        </a>
        <span> </span>
        {reason_label}
        <span> : </span>
        {reason_comment}
      </p>
      <p>
        {transit_area} / {trolley_area} {crates.join(", ")}
        <button onClick={() => hideAlerts([alert])} className="btn btn-xs btn-default float-right">
        Masquer
        </button>
      </p>
    </div>
  );
}

RepreparationAlertSmall.propTypes = {
  alert: PropTypes.object.isRequired,
  hideAlerts: PropTypes.func.isRequired,
};

export default RepreparationAlertSmall;
