import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ExternalShippingJobTooltip from '../common-comps/ExternalShippingJobsTooltip';
import { DOCK_TYPE_UBER } from '../apps/dispatch/utils/dock_n_driver';

const DockUber = ({
  dock,
  onCancelUber,
  onRegularizeUber,
}) => {

  if (dock.dock_type === DOCK_TYPE_UBER && !!dock.external_job.id) {
    const externalJobs = dock.tasks.length ? dock.tasks[0].external_shipping_jobs : null;

    const getStatus = () => {
      const transportTypeLabel = dock.external_job.transport_type ? `${dock.external_job.transport_type}, ` : '';
      if (['canceled', 'returned'].includes(dock.external_job.status)) {
        const return_dropoff_at = moment(dock.external_job.return_dropoff_at).format('LT');
        const statusLabel = `returning  (${dock.external_job.cancel_reason}, ${dock.external_job.return_status}:${return_dropoff_at})`;
        return `${transportTypeLabel}${statusLabel}`;
      }

      if (!dock.gone_at && ['pickup', 'pickup_complete'].includes(dock.external_job.status) && dock.external_job.pickup_at) {
        const pickup_at = moment(dock.external_job.pickup_at).format('LT');
        const imminent = dock.external_job.imminent ? '(imminent)' : '';
        return `${transportTypeLabel}pick_at: ${pickup_at} ${imminent}`;
      }

      if (dock.external_job.status === 'canceled') {
        return `${transportTypeLabel}${dock.external_job.status}:${dock.external_job.cancel_reason}`;
      }

      return `${transportTypeLabel}${dock.external_job.status}`;
    };

    const status = getStatus();

    const handleRegularizeUber = (e, dock) => {
      e.preventDefault();
      onRegularizeUber(dock);
    };

    return (
      <div>
        <span className="uber-running">
          {status}
          <ExternalShippingJobTooltip externalJobs={externalJobs} />
          {dock.external_job.tracking_url
            ? (
              <a
                className="btn btn-xs btn-info"
                href={dock.external_job.tracking_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                tracking
              </a>
            ) : null
          }
          {(
            !dock.gone_at &&
            !dock.external_job.cancel_reason
          ) ? (
              <button
                type="button"
                className="btn btn-xs btn-warning"
                onClick={() => onCancelUber(dock)}
              >
                annuler
              </button>
            ) : null
          }
          {['canceled', 'returned'].includes(dock.external_job.status)
            ? (
              <button
                type="button"
                className="btn btn-xs btn-secondary"
                onClick={(e) => handleRegularizeUber(e, dock)}>
                Régulariser
              </button>
            ) : null
          }
        </span>

      </div>
    );
  }

  return (<span></span>);
};

DockUber.propTypes = {
  dock: PropTypes.object.isRequired,
  onCancelUber: PropTypes.func.isRequired,
  onCallUber: PropTypes.func,
  onRegularizeUber: PropTypes.func,
};

export default DockUber;

