import React, { Component } from 'react';
import PropTypes from "prop-types";
import { DragSource, DropTarget } from 'react-dnd';
import Dock from "./Dock";
import { STATUS_MOVING } from "../../utils/dock_n_driver";

const dockSource = {
  beginDrag(props) {
    return {
      dock: props.dock,
    };
  }
};

const dockTarget = {
  // hover(props, monitor, component) {
  //   //console.log(props.task.id, monitor.getItem().taskId, component);
  //   return;
  // },

  canDrop(props, monitor) {
    let item = monitor.getItem();
    /*
     * props.dock = dock cible
     * item.dock = dock en train d'être déplacé
     */
    //console.log("DOCK CAN DROP",  props.dock, item.dock);

    // Si Dock target est locké
    if (props.dock.isLock) {
      //console.log('DOCK LOCK');
      return false;
    }

    // Si le Dock source est locké
    if (item.dock.isLock) {
      return false;
    }

    // On ne peut pas déplacer un dock sur lui même
    if (props.dock.id === item.dock.id) {
      //console.log('DOCK SELF');
      return false;
    }

    // On ne peut pas déplacer un dock non validé sur un dock avec livreur
    if (!item.dock.defined_at && ["DRIVER_HARD", "DRIVER_SOFT"].includes(props.dock.dock_type)) {
      return false;
    }

    // On ne peut pas déplacer un dock avec des tâches en mouvement sur un dock avec livreur
    if (item.dock.current_status === STATUS_MOVING && ["DRIVER_HARD", "DRIVER_SOFT"].includes(props.dock.dock_type)) {
      return false;
    }

    // Si le dock source n'a aucune tâche
    if (item.dock.tasks.length === 0) {
      return false;
    }

    return true;
  },

  drop(props, monitor) {
    //console.log('dock dropped ! ', props.dock.id, monitor.getItem());
    props.onDropDock(props.dock, monitor.getItem().dock);
  }
};


function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

class DockDnd extends Component {
  render() {
    const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, canDrop, isOver, show } = this.props;
    const { transit_areas, docks, dock, docks_extract, onDropDefault, onDropTask } = this.props;

    let classes = '';
    classes += isDragging ? " is-dragging-dock" : '';
    classes += canDrop && isOver ? " is-over-dock" : '';

    return (
      <Dock className={classes + ' DockDnd'}
        dock={dock}
        transit_areas={transit_areas}
        docks_extract={docks_extract}
        docks={docks}
        onDropDefault={onDropDefault}
        onDropTask={onDropTask}
        handlerDockDnd={connectDragSource}
        connectDragPreview={connectDragPreview}
        connectDropTarget={connectDropTarget}
        show={show}
      />
    );
  }
}

DockDnd.propTypes = {
  transit_areas: PropTypes.object.isRequired,
  docks: PropTypes.array.isRequired,
  dock: PropTypes.object.isRequired,
  docks_extract: PropTypes.object,
  onDropTask: PropTypes.func,
  onDropDefault: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  show: PropTypes.object,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  connectDragPreview: PropTypes.func
};

export default DragSource("Dock", dockSource, collectSource)(
  DropTarget("Dock", dockTarget, collectTarget)(DockDnd)
);
