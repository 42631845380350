import {connect} from "react-redux";
import {LOGS_MODAL_HIDE, LOGS_EXTRA_COST_ADD_FROM_DEMAND} from "../actions";
import {InternalModalExtraCostAdd} from "../../../common-comps/InternalModalExtraCostAdd";


export const ModalExtraCostAddFromDemand = connect(
  (state) => ({
    modal: state.logs.modal,
    driverList: state.logs.demands.driversList,
    extraCostTypes: state.logs.modal.demand
        ? [
            {
                id: state.logs.modal.demand.extra_cost_demand_type,
                label: state.logs.modal.demand.extra_cost_demand_type_label,
                rank: 1,
            }
        ]
        : [],
    driverId: state.logs.modal.demand ? state.logs.modal.demand.driver : null,
    date: state.logs.modal.demand ? state.logs.modal.demand.created_at.slice(0, 10) : null,
    driverLogGroupId: state.logs.modal.demand ? state.logs.modal.demand.driver_log_group : null,
    driverLogId: state.logs.modal.demand ? state.logs.modal.demand.driver_log : null,
    orderId: state.logs.modal.demand ? state.logs.modal.demand.order : null,
    extraCostType: state.logs.modal.demand ? state.logs.modal.demand.extra_cost_demand_type : null,
    minutes: state.logs.modal.demand ? state.logs.modal.demand.minutes : null,
    extraCostDemandId: state.logs.modal.demand ? state.logs.modal.demand.id : null,
    fromDemand: true,
  }),
  (dispatch) => {
    return {
      hide: () => {
        dispatch({type: LOGS_MODAL_HIDE});
      },
      submitForm: (extracost) => {
        dispatch({type: LOGS_MODAL_HIDE});
        dispatch({type: LOGS_EXTRA_COST_ADD_FROM_DEMAND, extracost: extracost});
      }
    };
  }
)(InternalModalExtraCostAdd);
