/* eslint-disable react/prop-types */
import React from "react";
import {PresenceTitle} from "./PresenceTitle";
import {PresenceLogs} from "./PresenceLogs";

export const Presence = ({presence, showDetail, setShowDetail}) => {
  return (
    <React.Fragment>
      <PresenceTitle presence={presence} showDetail={showDetail} setShowDetail={setShowDetail}/>
      <PresenceLogs logs={presence.logs} mainId={presence.id} show={showDetail}/>
    </React.Fragment>
  );
};
