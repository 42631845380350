import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Modal} from "react-bootstrap";

import moment from "moment/moment";

import {BlockingExitReasonForm} from "./BlockingExitReasonForm";
import {MODAL_HIDE__BLOCKING_EXIT_REASON} from "../actions/commonModals";
import {Address, UserName} from "../components/Common";
import {Link} from "react-router-dom";

class InternalBlockingExitReasonModal extends React.Component {
  render() {
    const {
      modal,
      hide,
      statusExitReasons,
      orderStatuses,
    } = this.props;
    moment.locale("fr");

    let center_id = null;
    if(modal.order){
      center_id = modal.order.center_id;
      if(!center_id){
        center_id = modal.order.center;
      }
    }
    let customer = modal.customer;
    if(!customer && modal.task){
      customer = modal.task.client;
    }

    let fraud_details = [];
    if(modal.fraud_detail) {
      for (let rule_id in modal.fraud_detail.details) {
        if (modal.fraud_detail.details[rule_id].description !== '') {
          fraud_details.push(modal.fraud_detail.details[rule_id]);
        }
      }
    }
    const FRAUD_RULE_VALUE_TYPE_ACCOUNT = 0;
    const FRAUD_RULE_VALUE_TYPE_PAYMENT = 1;

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      dialogClassName="blocking-exit-reasons-dialog"
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        { modal.order
        ? <div>
            Commande&nbsp;
            <a href={`/support/order/${modal.order.id}`} target="_blank" rel="noopener noreferrer" >#{modal.order.random_id}</a>,
            <span> </span>
            <UserName client={customer}/>
            <span> </span>
            { modal.task && modal.task.address
            ? <Address address={modal.task.address} center_id={center_id}/>
            : <Address address={modal.order.shipping_address} center_id={center_id}/>
            }
          </div>
        : null
        }
        { modal.order
        ? <div>
            { customer && customer.is_fraud
            && <Badge className="is-fraud">FRAUD</Badge>
            }
            <span> </span>
            { customer && customer.is_staff
            && <Badge className="is-staff">STAFF</Badge>
            }
            <span> </span>
            { customer.referrer_info
            && <span>
                <b>Parrain : </b>
                <Link to={`/support/user/${customer.referrer_info.id}/${customer.referrer_info.email}`} >
                  {customer.referrer_info.first_name} {customer.referrer_info.last_name} - {customer.referrer_info.email}
                </Link>
              </span>
            }
            <span> </span>
            {modal.order.is_pro && <Badge className="is-pro">PRO</Badge>}
            <span> </span>
            {modal.order.is_super_venere
            && <Badge className="is-super-venere"><strong title="Client super vénère"><i className="fa-solid fa-face-angry"></i>VENERE</strong></Badge>
            }
          </div>
        : null
        }
        { modal.fraud_detail &&
          <div>
            <h5>Fraude</h5>
            Score de fraude du compte : {modal.fraud_detail.fraud_score_account} % <br />
            Score de fraude du paiement : {modal.fraud_detail.fraud_score_payment} % <br />
            { fraud_details.length > 0 &&
              <table className="table table-condensed table-hover">
                <tbody>
                <tr>
                  <th>Détail du score</th>
                  <th>Score du compte</th>
                  <th>Score du paiement</th>
                </tr>
                {
                  fraud_details.map(
                    detail => (
                      <tr key={detail.rule_id}>
                        <td>{detail.description}</td>
                        <td>
                          <span style={
                            detail.fraud_score_by_type[FRAUD_RULE_VALUE_TYPE_ACCOUNT] > 0
                            ? {color: 'red'}
                            : {color: 'green'}
                          }>
                            {detail.fraud_score_by_type[FRAUD_RULE_VALUE_TYPE_ACCOUNT]}
                          </span>
                        </td>
                        <td>
                          <span style={
                            detail.fraud_score_by_type[FRAUD_RULE_VALUE_TYPE_PAYMENT] > 0
                            ? {color: 'red'}
                            : {color: 'green'}
                          }>
                            {detail.fraud_score_by_type[FRAUD_RULE_VALUE_TYPE_PAYMENT]}
                          </span>
                        </td>
                      </tr>
                    )
                  )
                }
                <tr>
                  <td className="text-right"><strong>Score total</strong></td>
                  <td>{modal.fraud_detail.fraud_score_account} %</td>
                  <td>{modal.fraud_detail.fraud_score_payment} %</td>
                </tr>
              </tbody>
            </table>
            }
          </div>
        }
        <BlockingExitReasonForm
            statusExitReasons={statusExitReasons}
            orderStatuses={orderStatuses}
            cancelFunction={modal.cancelFunction}
            submitFunction={modal.submitFunction}
        />
      </Modal.Body>
    </Modal>;
  }
}

InternalBlockingExitReasonModal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
  statusExitReasons: PropTypes.object,
  orderStatuses: PropTypes.object,
};

export const BlockingExitReasonModal = connect(
  (state) => {
    return {
      modal: state.commonModals.blockingExitReason,
      statusExitReasons: (
        state.support && state.support.meta && state.support.meta.order_status_exit_reasons
        ? state.support.meta.order_status_exit_reasons
        : (
          state.dispatch && state.dispatch.metas && state.dispatch.metas.order_status_exit_reasons
          ? state.dispatch.metas.order_status_exit_reasons
          : (
            state.m && state.m.metas && state.m.metas.order_status_exit_reasons
            ? state.m.metas.order_status_exit_reasons
            : null
          )
        )
      ),
      orderStatuses: (
        state.support && state.support.meta && state.support.meta.order_statuses
        ? state.support.meta.order_statuses
        : (
          state.dispatch && state.dispatch.metas && state.dispatch.metas.order_statuses
          ? state.dispatch.metas.order_statuses
          : (
            state.m && state.m.metas && state.m.metas.order_statuses
            ? state.m.metas.order_statuses
            : null
          )
        )
      ),
    };
  },
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MODAL_HIDE__BLOCKING_EXIT_REASON});
      }
    };
  }
)(InternalBlockingExitReasonModal);
