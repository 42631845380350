import {DOCK_TYPE_FIRST, DOCK_TYPE_SOFT_ASSIGNED} from "../utils/dock_n_driver";
import { DISPATCH_SKIP } from "./index";

export const DISPATCH_DOCK_LOAD = 'DISPATCH_DOCK_LOAD';
export const DISPATCH_DOCK_UPDATE = 'DISPATCH_DOCK_UPDATE';
export const DISPATCH_DOCK_CLOSE = 'DISPATCH_DOCK_CLOSE';
export const DISPATCH_DOCK_UPDATED = 'DISPATCH_DOCK_UPDATED';
export const DISPATCH_DOCK_ASSIGN = 'DISPATCH_DOCK_ASSIGN';
export const DISPATCH_DOCK_UNLOCK = 'DISPATCH_DOCK_UNLOCK';
export const DISPATCH_DOCK_DEFINE = 'DISPATCH_DOCK_DEFINE';
export const DISPATCH_DOCK_UNDEFINE = 'DISPATCH_DOCK_UNDEFINE';
export const DISPATCH_FINISH_DOCK = 'DISPATCH_FINISH_DOCK';
export const DISPATCH_DOCK_MANUALLY_OPTIMIZED = 'DISPATCH_DOCK_MANUALLY_OPTIMIZED';
export const DISPATCH_DOCK_MANUALLY_VALIDATED = 'DISPATCH_DOCK_MANUALLY_VALIDATED';

export const DISPATCH_DOCK_TOGGLE_EXTRACT = 'DISPATCH_DOCK_TOGGLE_EXTRACT';
export const DISPATCH_DOCK_TASK_EXTRACT = 'DISPATCH_DOCK_TASK_EXTRACT';
export const DISPATCH_DOCK_TASK_EXTRACTED = 'DISPATCH_DOCK_TASK_EXTRACTED';
export const DISPATCH_DOCK_CHECK_TASK_TO_EXTRACT = 'DISPATCH_DOCK_CHECK_TASK_TO_EXTRACT';
export const DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED = 'DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED';
export const DISPATCH_DOCK_SWITCH_DRIVERS = 'DISPATCH_DOCK_SWITCH_DRIVERS';
export const DISPATCH_DOCK_DRIVERS_SWITCHED = 'DISPATCH_DOCK_DRIVERS_SWITCHED';
export const DISPATCH_DOCK_READY_TO_LOAD = 'DISPATCH_DOCK_READY_TO_LOAD';
export const DISPATCH_DOCK_ADD = 'DISPATCH_DOCK_ADD';
export const DISPATCH_AUTO_GENERATE = 'DISPATCH_AUTO_GENERATE';
export const DISPATCH_CANCEL_STUART = 'DISPATCH_CANCEL_STUART';
export const DISPATCH_REGULARIZE_STUART = 'DISPATCH_REGULARIZE_STUART';
export const DISPATCH_CANCEL_UBER = 'DISPATCH_CANCEL_UBER';
export const DISPATCH_REGULARIZE_UBER= 'DISPATCH_REGULARIZE_UBER';

export const generateDispatchAuto = function (centerId) {
  return {
    type: DISPATCH_AUTO_GENERATE,
    center: centerId,
  };
};

export const changeMainSetting = function (dock, newValue) {
  const value = parseInt(newValue, 10);

  if (isNaN(value)) {
    //console.log(newValue, newValue.split('_'));
    let [type, vehicle]  =  newValue.split('_');

    if (type !== DOCK_TYPE_FIRST) {
      vehicle = null;
    }

    return {
      type: DISPATCH_DOCK_UPDATE,
      patch: {
        id: dock.id,
        dock_type: type,
        target_vehicle: vehicle,
      }
    };
  } else {
    return {
      type: DISPATCH_DOCK_UPDATE,
      patch: {
        id: dock.id,
        dock_type: DOCK_TYPE_SOFT_ASSIGNED,
        target_driver: value,
      }
    };
  }
};

export const changeEndingCenter = function (dock, value) {
  return {
    type: DISPATCH_DOCK_UPDATE,
    patch : {
      id: dock.id,
      ending_center: value,
    }
  };
};

export const closeDock = function (dock) {
  let r = window.confirm("Fermer définitivement ce dock et libérer les livraisons qu'il contient ?");
  if (!r) {
    return {type:DISPATCH_SKIP};
  }

  return {
    type: DISPATCH_DOCK_CLOSE,
    dock : dock
  };
};


export const assignDock = function (dock) {
  return {
    type: DISPATCH_DOCK_ASSIGN,
    dock : dock
  };
};

export const unlockDock = function (dock) {
  return {
    type: DISPATCH_DOCK_UNLOCK,
    dock : dock
  };
};

export const dockLoad = function (dock_id) {
  return {
    type: DISPATCH_DOCK_LOAD,
    dock_id : dock_id,
  };
};

export const dockUpdated = function (dock) {
  return {
    type: DISPATCH_DOCK_UPDATED,
    dock : dock,
  };
};

export const dockAdd = function (dock) {
  return {
    type: DISPATCH_DOCK_ADD,
    dock : dock,
  };
};

export const act_dockToggleExtract = function(dock) {
  return {
    type: DISPATCH_DOCK_TOGGLE_EXTRACT,
    dock: dock
  };
};
export const act_dockCheckTaskToExtract = function(dock, task) {
  return {
    type: DISPATCH_DOCK_CHECK_TASK_TO_EXTRACT,
    dock: dock,
    task: task,
  };
};

export const act_dockExtractTask = function(dock, task_ids) {
  return {
    type: DISPATCH_DOCK_TASK_EXTRACT,
    dock: dock,
    task_ids: task_ids
  };
};

export const act_dockSwitchDrivers = function(fromDock, toDockId) {
  return {
    type: DISPATCH_DOCK_SWITCH_DRIVERS,
    fromDockId: fromDock.id,
    toDockId: toDockId,
    fromDockAcceptedByDriver: fromDock.accepted_by_driver,
  };
};

export const act_dockDriversSwitched = function(fromDockId, toDockId) {
  return {
    type: DISPATCH_DOCK_DRIVERS_SWITCHED,
    fromDockId: fromDockId,
    toDockId: toDockId,
  };
};

export const act_dockReadyToLoad = (dock, toVehicle) => ({
  type: DISPATCH_DOCK_READY_TO_LOAD,
  dock,
  toVehicle
});

export const cancelStuart = function (dock) {
  return {
    type: DISPATCH_CANCEL_STUART,
    dock : dock,
  };
};

export const regularizeStuart = function (dock) {
  return {
    type: DISPATCH_REGULARIZE_STUART,
    dock : dock,
  };
};

export const cancelUber = function (dock) {
  return {
    type: DISPATCH_CANCEL_UBER,
    dock : dock,
  };
};

export const regularizeUber = function (dock) {
  return {
    type: DISPATCH_REGULARIZE_UBER,
    dock : dock,
  };
};

export default {
  changeMainSetting,
  changeEndingCenter,
};
