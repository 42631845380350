import {newTaskStatus, STATUS_ORDER_PREPARED} from "../../utils/status";
import uu from "./updatingUpdated"

let _exp = {};


_exp.stopDriverPresence = function(action, state) {
  let driverTasks = [];
  driverTasks = state.deliveries.filter((t) => t.dock && t.dock.target_driver === action.driver.id);

  let label_status = action.unfinished_new_status;
  if (action.unfinished_new_status === "prepared") label_status = "Finalisée(s)";
  if (action.unfinished_new_status === "received") label_status = "Livrée(s)";


  // si tâche en cours, confirm
  let r = true;
  if (driverTasks.length > 0) {
    r = window.confirm("Ce livreur a des tâches en cours qui vont passer " + label_status + ", confirmer ?");
  }
  // si annulé, on ne fait rien
  if (!r) {
    return state;
  }

  const diffDriver = {
    "updating": action.driver.id,
    "state": "hidden"
  };

  const driverTaskIds = driverTasks.map(t => t.id);
  const diffTask = {
    updating: action.driver.id
  };

  return {...state,...{
    drivers: state.drivers.map((e) => e.id === action.driver.id ? {...e, ...diffDriver} : e),
    deliveries: state.deliveries.map((e) => driverTaskIds.includes(e.id) ? {...e, ...diffTask} : e)
  }};
};

_exp.startDriverPresence = function(action, state) {
  const diffDriver = {
    "updating": action.driver.id,
    "state": "hidden"
  };

  return {
    ...state,
    ...{ drivers: state.drivers.map((e) => e.id === action.driver.id ? {...e, ...diffDriver} : e)}
  };
};

// eslint-disable-next-line no-unused-vars
_exp.changeDriverVehicle = function(action, state) {

};



_exp.changeDriverPresence = function(action, state) {

  let driverTasks = [];

  if (!action.isPresent) {
    driverTasks = state.deliveries.filter((t) => t.delivery_man_id === action.driver.id && ['shipping', 'todo'].includes(t.status));
    // si tâche en cours, confirm
    let r = true;
    if (driverTasks.length > 0) {
      r = window.confirm("Ce livreur a des tâches en cours qui vont repasser en finalisée, confirmer ?");
    }
    // si annulé, on ne fait rien
    if (!r) {
      return state;
    }
  }

  // get max ordering on prepared (gestion tâche en cours si leave)
  let maxOrdering = 0;
  for (const t of state.deliveries) {
    if (t.status === newTaskStatus(t, STATUS_ORDER_PREPARED) && maxOrdering < t.ordering) {
      maxOrdering = t.ordering;
    }
  }

  const diffDriver = {
    "updating": action.driver.id,
    "state": "hidden"
  };

  const driverTaskIds = driverTasks.map(t => t.id);
  const diffTask = {
    group_uniq_id: null,
    delivery_man_id: null,
    status: "todo",
    updating: action.driver.id
  };

  return Object.assign(
    {},
    state,
    {
      drivers: state.drivers.map((e) => {
        if  (e.id === action.driver.id) {
          return Object.assign({}, e, diffDriver);
        }
        return e;
      }),
      deliveries: state.deliveries.map((e) => {
        if (driverTaskIds.includes(e.id)) {
          maxOrdering++;
          return Object.assign(
            {},
            e,
            diffTask,
            {ordering: maxOrdering, linked_order: Object.assign({}, e.linked_order, {status:"prepared"})}
          );
        }
        return e;
      })
    }
  );
};

_exp.driverUpdating = uu.stateComponentUpdating(
    "drivers",
    "driver"
);

_exp.driverUpdateSucceeded = uu.stateComponentUpdated(
    "drivers",
    "driver",
    []
);

export default _exp;
