import React from 'react';
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import moment from 'moment';
import {withRouter} from "react-router-dom";

const InternalSubventionLogs = withRouter(({subventionLogs}) => {
  if (!subventionLogs) {
    return null;
  }
  moment.locale('fr');

  return <div className='subscriptionList'>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>Utilisée le</th>
          <th>Montant</th>
          <th>#Cmd</th>
          <th>Cantine</th>
          <th>Organisation</th>
          <th>Conditions</th>
        </tr>
      </thead>
      <tbody>
        {subventionLogs.map((log)=> {
          return <tr key={log.created_at} >
            <td>{moment(log.created_at).format('L')}</td>
            <td>{log.amount}€</td>
            <td><a href={`/support/order/${log.order_id}`} target='_blank' rel="noopener noreferrer">{log.order_random_id}</a></td>
            <td>
              <a href={`${process.env.REACT_APP_ADMIN_B2B}/canteens/${log.canteen_id}`} target='_blank' rel="noopener noreferrer">
                {log.canteen_name}
              </a>
            </td>
            {log.subvention ? (
              <>
                <td>
                  <a href={`${process.env.REACT_APP_ADMIN_B2B}/organizations/${log.subvention.organization.id}`} target='_blank' rel="noopener noreferrer">
                    {log.subvention.organization.name}
                  </a>
                </td>
                <td>
                  <ul style={{ marginBottom: 0 }}>
                    Limite d'usage par jour :
                    <b>{log.subvention.daily_usage_limit === 0 ? 'Illimité' : log.subvention.daily_usage_limit}</b>
                  </ul>
                  <ul style={{ marginBottom: 0 }}>
                    Limité aux produits TR :
                    <b>{log.subvention.is_limited_to_restaurant_ticket_products ? 'Oui' : 'Non'}</b>
                  </ul>
                  <ul style={{ marginBottom: 0 }}>
                    Remise maximale :
                    <b>{log.subvention.max_discount_amount}€</b>
                  </ul>
                  <ul style={{ marginBottom: 0 }}>
                    Minimum à payer par le client :
                    <b>{log.subvention.min_client_amount}€</b>
                  </ul>
                </td>
              </>
            ) : (
              <td colSpan={2}>Subvention introuvable (sans doute supprimée)</td>
            )}
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalSubventionLogs.propTypes = {
  subscriptionList: PropTypes.array,
  cancelSubscription: PropTypes.func,
};

export const SubventionLogs = InternalSubventionLogs;