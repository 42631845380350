import moment from "moment";
moment.locale('fr');

export const LOGS_LOAD = 'LOGS_LOAD';
export const LOGS_LOADED = 'LOGS_LOADED';
export const LOGS_PARTIAL_LOAD = 'LOGS_PARTIAL_LOAD';
export const LOGS_PARTIAL_LOADED = 'LOGS_PARTIAL_LOADED';
export const LOGS_PRESENCE_SAVE = "LOGS_PRESENCE_SAVE";
export const LOGS_PRESENCE_SAVED = "LOGS_PRESENCE_SAVED";
export const LOGS_PRESENCE_ADD = "LOGS_PRESENCE_ADD";
export const LOGS_EXTRACOST_DELETE = "LOGS_EXTRACOST_DELETE";
export const LOGS_EXTRACOST_ADD = "LOGS_EXTRACOST_ADD";
export const LOGS_MODAL_SHOW = 'LOGS_MODAL_SHOW';
export const LOGS_MODAL_HIDE = 'LOGS_MODAL_HIDE';
export const LOGS_DRIVER_LIST_LOAD = 'LOGS_DRIVER_LIST_LOAD';
export const LOGS_DRIVER_LIST_LOADED = 'LOGS_DRIVER_LIST_LOADED';
export const LOGS_EXTRA_COST_DEMANDS_LOAD = "LOGS_EXTRA_COST_DEMANDS_LOAD";
export const LOGS_EXTRA_COST_DEMANDS_LOADED = "LOGS_EXTRA_COST_DEMANDS_LOADED";
export const LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD = "LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD";
export const LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED = "LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOADED";
export const LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD = "LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD";
export const LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED = "LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOADED";
export const LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE = "LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE";
export const LOGS_EXTRA_COST_DEMANDS_REVIEW = "LOGS_EXTRA_COST_DEMANDS_REVIEW";
export const LOGS_EXTRA_COST_ADD_FROM_DEMAND = "LOGS_EXTRA_COST_ADD_FROM_DEMAND";

export const showPopinPresenceAdd = () => {
  return {
    "type": LOGS_MODAL_SHOW,
    "modalData":  {
      "modalType": "presence_add",
    },
  };
};

export const showPopinExtraCostAdd = () => {
  return {
    "type": LOGS_MODAL_SHOW,
    "modalData":  {
      "modalType": "extracost_add",
    },
  };
};

export const loadDriverList = () => {
  return {
    type: LOGS_DRIVER_LIST_LOAD
  };
};
