import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {SUPPORT_MODAL_HIDE, SUPPORT_ORDER_PREPZONE_UPDATE} from "../../../actions";


class InternalOrderPrepZoneNbBagsModal extends React.Component {


  render() {
    const {modal, hide, validate} = this.props;

    return <Modal
      show={modal && modal.modalType === 'OrderPrepZone'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          initialValues={modal.prepZone ? {
            pseudo_zone_id: modal.prepZone.pseudozone_id,
            number_of_bags_isotherm: modal.prepZone.nob_iso ? modal.prepZone.nob_iso : '',
            number_of_crates: modal.prepZone.nb_crates ? modal.prepZone.nb_crates : '',
          } : {}}
          onSubmit={(values, actions) => {
            validate(modal.order.id, values);
          }}
          render={(props: FormikProps<Values>) => (
            <form onSubmit={props.handleSubmit} className="form-horizontal">

              {!modal.pz.bag_apart && <div className="alert alert-info">Une fois la commande closée, la modification du nombre de sac pour cette zone ne sert à rien</div>}

              {modal.pz.bag_apart ? 
                <FormGroup>
                  <Col  sm={12}>
                    <Form.Label>Nombre de sac isotherme</Form.Label>
                    <Field
                      type="number"
                      min="0"
                      max="999"
                      step="1"
                      name="number_of_bags_isotherm"
                      className="form-control"
                    />
                  </Col>
                </FormGroup>
                : <FormGroup>
                  <Col  sm={12}>
                    <Form.Label>Nombre de cagettes</Form.Label>
                    <Field
                      type="number"
                      min="0"
                      max="999"
                      step="1"
                      name="number_of_crates"
                      className="form-control"
                    />
                  </Col>
                </FormGroup>}
              <FormGroup>
                <Col  sm={12}>
                  <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                    VALIDER
                  </button>
                </Col>
              </FormGroup>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>;

  }
}

export const OrderPrepZoneNbBagsModal = connect(
  (state) => ({"modal" : state.support.modal}),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (orderId, prepZone) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch({'type': SUPPORT_ORDER_PREPZONE_UPDATE, orderId: orderId, prepZone: prepZone});
      }
    };
  }
)(InternalOrderPrepZoneNbBagsModal);
