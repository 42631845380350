import {connect} from "react-redux";

import {DISPATCH_MAIN_MODAL_HIDE} from "../../actions/modal";
import {DRIVER_STATEMENT_TYPE_ISOBAG} from "../../utils/driver";
import {DISPATCH_CREATE_STATEMENT} from "../../actions/driver";
import {DriverIsoBagModalContent} from "../../../../components/modal/DriverIsoBagModalContent";

export const DriverIsoBagModal = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": DISPATCH_MAIN_MODAL_HIDE});
      },
      "validate": (values) => {
        const amount = values.operation === "debit" ? -values.amount : values.amount;

        dispatch({'type': DISPATCH_MAIN_MODAL_HIDE});
        dispatch({
          type: DISPATCH_CREATE_STATEMENT,
          driver: {id: values.driver_id},
          statement_type: DRIVER_STATEMENT_TYPE_ISOBAG,
          amount: amount
        });
      }
    };
  }
)(DriverIsoBagModalContent);
