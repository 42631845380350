import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';

import { flattenAddress } from '../../utils/address';


const UberCallModal = ({ modal, onHide, callUber }) => {
  if (!modal || !modal.task) {
    return null;
  }

  const { modalType, task } = modal;

  let weight = parseFloat(task.linked_order.total_weight.replace('Kg', '').trim());
  weight = Math.round(weight);

  return (
    <Modal
      show={modalType === 'uber_call'}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>Appeler un <strong>Uber</strong> pour #{task.linked_order.random_id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Adresse: {flattenAddress(task.address)}</li>
          <li>Poids: {task.linked_order.total_weight}</li>
          {task.linked_order.number_of_bags
            ? <li>Nombre de sacs: {task.linked_order.number_of_bags}</li>
            : null
          }
        </ul>
        {weight > 33
          ? (
            <Alert variant="danger">
              Le poids calculé de la commande est supérieur à 33 kg.
              Aucun livreur Uber ne pas sera en mesure de la livrer
            </Alert>
          ) : weight > 12
            ? (
              <Alert variant="warning">
                Le poids calculé de la commande est supérieur à 12 kg.
                Le temps d&apos;attente peut-être allongé car Uber enverra 
                un livreur haute capacité (poids de le commande compris entre 12kg et 33kg)
              </Alert>
            )
            : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide} >Fermer</Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => callUber(task.linked_order)}
          disabled={modal.sending}
        >
          Appeler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UberCallModal.propTypes = {
  order: PropTypes.object,
  modal: PropTypes.object,
  onHide: PropTypes.func,
  callUber: PropTypes.func,
};

export default UberCallModal;
