import {
  STATUS_CLOSED,
  STATUS_DRAFT,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_MOVING,
  STATUS_READY_TO_LOAD
} from "./utils/dock_n_driver";

export const SUB_DISPATCH_CODE_LIGHT_CREATION_1 = "light_creation1"
export const SUB_DISPATCH_CODE_LIGHT_LOADING_1 = "light_loading1"

export const SUB_DISPATCH_CODES = [
  SUB_DISPATCH_CODE_LIGHT_CREATION_1,
  SUB_DISPATCH_CODE_LIGHT_LOADING_1,
];

export const SUB_DISPATCH_CONFIGURATIONS = {
  [SUB_DISPATCH_CODE_LIGHT_CREATION_1]: {
    label: "Dispatch création de tournée",
    activated: true,
    withDockCurrentStatuses: [
      STATUS_DRAFT,
      STATUS_MOVING,
      STATUS_READY_TO_LOAD,
      STATUS_LOADING,
      // STATUS_LOADED,
      // STATUS_CLOSED,
    ],
    // withNormalSlotAmongDeliveriesToDispatch: false,
    // withDQPAmongDeliveriesToDispatch: false,  // SPEED
    // withDQPExpressAmongDeliveriesToDispatch: false,  // QUICK
    // withDQPExpressPrioAmongDeliveriesToDispatch: false,  // QUICK+
    withDQPCourierAmongDeliveriesToDispatch: false,  // BOLT
    // withDQPNoonTourAmongDeliveriesToDispatch: false,
    // withDQPEveningTourAmongDeliveriesToDispatch: false,
    withDriverAmongDispatchedDeliveries: false,
    withDrivers: false,
    withDriverQueue: false,
    withExtraCostDemands: false,
    withLateDeliveries: false,
    withNotDeliveredOrders: false,
  },
  [SUB_DISPATCH_CODE_LIGHT_LOADING_1]: {
    label: "Dispatch chargement et suivi livreur",
    activated: true,
    withDockCurrentStatuses: [
      // STATUS_DRAFT,
      // STATUS_MOVING,
      STATUS_READY_TO_LOAD,
      STATUS_LOADING,
      STATUS_LOADED,
      STATUS_CLOSED,
    ],
    withNormalSlotAmongDeliveriesToDispatch: false,
    withDQPAmongDeliveriesToDispatch: false,  // SPEED
    // withDQPExpressAmongDeliveriesToDispatch: false,  // QUICK
    // withDQPExpressPrioAmongDeliveriesToDispatch: false,  // QUICK+
    // withDQPCourierAmongDeliveriesToDispatch: false,  // BOLT
    withDQPNoonTourAmongDeliveriesToDispatch: false,
    withDQPEveningTourAmongDeliveriesToDispatch: false,
    withNotLoadedDockAmongDispatchedDeliveries: false,
    withFlowDriverStates: false,
    withLateDeliveries: false,
    withNotDeliveredOrders: false,
  }
}
