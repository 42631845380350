import React from 'react';
import PropTypes from "prop-types";
import {rd} from "../../../../../utils";
import {OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import {copyToClipboard} from "../../../../../utils";


export const StripeRow = ({stripeElt}) => {

  return <tr key={stripeElt.payment_intent_id}>
    <td>
      {stripeElt.status === 'succeeded' ?
        <i className="fa-regular fa-circle-check" title="Succès"></i>
        : <i className="fa-regular fa-circle-xmark" title="Échec"></i>
      }
    </td>
    <td className="col-order-id">{stripeElt.order}</td>
    <td className="col-date">{stripeElt.created_formated}</td>
    <td className="col-amount">{rd(stripeElt.amount/100)}€</td>
    <OverlayTrigger
      key={`overlay-stripe-3ds-${stripeElt.charge_id}`}
      placement="left"
      transition={false}
      overlay={
        <Tooltip id={`tooltip-stripe-3ds-messge-${stripeElt.payment_intent_id}`}>
          {stripeElt.has_3ds === true ?
            stripeElt.status_3ds === 'succeeded' ? <span>Authentification 3D secure réussie</span>
              : <span>Authentification 3D secure échouée</span>
            : <span>Authentification non demandée</span>
          }
        </Tooltip>
      }
    >
      <td className="col-3ds">
        {stripeElt.has_3ds === true ?
          stripeElt.status_3ds === 'succeeded' ? <i className="fa-solid fa-lock"></i>
            : <i className="fa-solid fa-ban"></i>
          : <span>-</span>
        }
      </td>
    </OverlayTrigger>
    <td className="col-message">
      {stripeElt.message.full_message !== '' ? 
        <OverlayTrigger
          key={`overlay-message-${stripeElt.payment_intent_id}`}
          placement="left"
          transition={false}
          overlay={
            <Tooltip id={`tooltip-stripe-messge-${stripeElt.payment_intent_id}`}>
              {stripeElt.message.full_message}
            </Tooltip>
          }
        >
          <span
            onClick={() => {copyToClipboard(stripeElt.message.full_message)}}
          >
            {stripeElt.message.message}
          </span>
        </OverlayTrigger>
        : <span>{stripeElt.message.message}</span>}
    </td>
    <td className="col-score-stripe"><span className={stripeElt.outcome_risk_level}>{stripeElt.outcome_risk_score}</span></td>
    <OverlayTrigger
      key={`overlay-infos-${stripeElt.charge_id}`}
      placement="left"
      trigger="click"
      transition={false}
      overlay={
        <Popover id={`tooltip-stripe-infos-${stripeElt.charge_id}`}>
          <Popover.Title>Détails Transaction</Popover.Title>
          <Popover.Content>
            <ul>
              {stripeElt.charge_id ? <li><strong>Charge Id :</strong><br /><span>{stripeElt.charge_id}</span></li> : null}
              <li><strong>Payment Intent Id :</strong><br /><span>{stripeElt.payment_intent_id}</span></li>
              { stripeElt.receipt_url ? <li className='my-2'><a href={stripeElt.receipt_url} target='_blank'>Afficher le ticket de reçu</a></li> : null }
            </ul>
          </Popover.Content>
        </Popover>
      }
    >
      <td className="col-infos">
        <span><i className="fa-solid fa-circle-info"></i></span>
      </td>
    </OverlayTrigger>
  </tr>;
        
};
StripeRow.propTypes = {
  "stripeElt": PropTypes.object,
};
