import {LAUNCHPAD_DEFAULT, LAUNCHPAD_PREPARED_BASKET} from "../utils/launchpad";
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {driverUpdate} from "../actions";
import React from "react";


export const SwitchPad = connect(
  (state) => {
    return {preparedBasketToday: state.m.metas.count_prepared_basket_today};
  },
  (dispatch) => {
    return {
      "switchPad": (driver, pad) => dispatch(driverUpdate(driver, {launchpad: pad}))
    };
  }
)(
  ({driver, switchPad, preparedBasketToday}) => {

    if (!driver || driver.activity.note) {
      return null;
    }
    if (!preparedBasketToday) {
      return null;
    }

    //const currentLabel = driver.launchpad === LAUNCHPAD_PREPARED_BASKET ? "panier" : "flow";
    const switchLabel = driver.launchpad === LAUNCHPAD_PREPARED_BASKET ? "FLOW" : "PANIER";
    const nextPad = driver.launchpad === LAUNCHPAD_PREPARED_BASKET ? LAUNCHPAD_DEFAULT : LAUNCHPAD_PREPARED_BASKET;

    return <Button
      onClick={() => switchPad(driver, nextPad)}
      size="xs"
      variant={driver.updating ? "default" : "info"}
      disabled={driver.updating ? true : false}>PASSER SUR {switchLabel}</Button>;
  }
)
