import {ORDER_SUPPORT_INFO_LOADED} from "../actions";

let initialState = {
  someSupportInfoByOrderId: {},
};

export const noLoadStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SUPPORT_INFO_LOADED:
      let ns = {
        ...state,
        ...{
          someSupportInfoByOrderId: {...state.someSupportInfoByOrderId},
        },
      };
      ns.someSupportInfoByOrderId[action.order.id] = action.order;
      ns.someSupportInfoByOrderId[action.order.id].fetchedAt = new Date();
      return ns;
    default:
      return state;
    }
};

