import React from 'react';
import {connect} from "react-redux";
import {Form, FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {SUPPORT_MODAL_HIDE, validateChangeDeliveryName} from "../actions";

class InternalModal extends React.Component {


  render() {
    const {modal, hide, validate} = this.props;

    if (!modal.address) {
      return null;
    }

    return <Modal
      show={modal && modal.modalType === 'delivery_name'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="ChangeDeliveryNameModal"
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          initialValues={{
            firstname: modal.address.firstname || '',
            lastname: modal.address.lastname || '',
            company: modal.address.company || '',
            address: modal.address
          }}
          onSubmit={(values, actions) => {

            let newAddress = {
              ...values.address,
              firstname: values.firstname,
              lastname: values.lastname,
              company:values.company
            };

            validate(newAddress);
          }}
          render={(props: FormikProps<Values>) => {

            return <div style={{paddingLeft:"20px", paddingRight:"20px"}}>
              <form onSubmit={props.handleSubmit} className="form-horizontal">
                <FormGroup>
                  <Form.Label>Entreprise</Form.Label>
                  <Field
                    name="company"
                    className="form-control"
                  />
                </FormGroup>
              <FormGroup>
                  <Form.Label>Prenom</Form.Label>
                  <Field
                    name="firstname"
                    className="form-control"
                  />
              </FormGroup>
              <FormGroup>
                <Form.Label>Nom</Form.Label>
                <Field
                  name="lastname"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup style={{textAlign:"center"}}>
                <button type="submit" className="btn btn-primary">Enregistrer</button>
              </FormGroup>
              </form>
            </div>;
          }}
        />
      </Modal.Body>
    </Modal>;

  }
}


export const ChangeDeliveryNameModal = connect(
  (state) => ({
    "modal" : state.support.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (values) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch(validateChangeDeliveryName(
          values
        ));
      }
    };
  }
)(InternalModal);

