import {connect} from "react-redux";

import TransitAreaModalContent from "../../../../components/modal/TransitAreaModalContent";
import { connectTransitAreaModalAction } from "../../../../utils/transit_areas";


export const TransitAreaModal = connect(
  (state) => ({
    "modal" : state.m.modal,
    "pseudoZones": state.support.meta.pseudoZones,
    "transit_areas": state.support.transit_areas,
    "trolleys": state.support.meta.trolleys,
    "transit_area_types": state.support.meta.transit_area_types,
  }),
  (dispatch) => connectTransitAreaModalAction(dispatch)
)(TransitAreaModalContent);
