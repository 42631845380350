import React from "react";
import { createSelector } from 'reselect';
import { stateToCenterId } from "../utils/center";


const getLoads = state => state.m.metas ? state.m.metas.loads : null;
const getPackerstatiph = state => state.m.packerstatiph;
const getCenterId = state => stateToCenterId(state);

function getStatIph(avg_iph, with_zone_detail) {

  let zone_detail = null;
  if (with_zone_detail) {
    let data_by_zone = {};
    Object.values(avg_iph["datas"]).forEach((data, idx) => {
      Object.values(data["detail"]).forEach((detail, index) => {
        if (typeof(data_by_zone[detail.zone_name]) === "undefined") {
          data_by_zone[detail.zone_name] = {
            "name": detail.zone_name,
            "details": [
            ]
          };
        }
        data_by_zone[detail.zone_name].details.push(
          <span key={idx + index} title={detail.C_tooltip} className="iph_tooltip" style={{color: "#444"}}> {data_by_zone[detail.zone_name].details.length > 0 ? <b> / </b> : null}{data.label}: <span style={{color: "grey"}}>{detail.C} iph</span></span>
        );
      });
    });
    zone_detail = Object.values(data_by_zone).map((data, idx) =>
      <span key={idx}>
        &nbsp;&nbsp;&nbsp;&nbsp;{data.name} : {data.details.map(detail => detail)}
        {Object.values(data_by_zone).length > idx+1 ? <br /> : null}
      </span>
    );
  }

  return <small>
    <br />
    <b>{avg_iph.name}</b> ({avg_iph.nb_preps} preps) {` => `}
    {Object.values(avg_iph["datas"]).map((e, index) =>
      <span key={index} title={e.C_tooltip} className="iph_tooltip">{index > 0 ? <b> / </b> : null}{e.label}: {e.C} iph</span>
    )}
    {with_zone_detail ? <br /> : null}
    {zone_detail}
  </small>;
}
export const getAvgTitles = createSelector(
  [getLoads, getPackerstatiph, getCenterId],
  (loads, packerstatiph, centerId) => {
    let loadStr = '';
    if (loads) {
      //  (vitesse : last 5min : 40 iph  /  20min : 43 iph / 1h : 23 iph
      loadStr = ` (vitesse : last ${loads.map(e => `${e.minutes}min: ${e.value} iph`).join(' / ')})`;
    }

    let packerstatiph_avg = null;
    if (packerstatiph && typeof(packerstatiph['avg_iph_hour']) !== 'undefined') {
      let iph_global = getStatIph(packerstatiph["avg_iph_hour"], false);

      let iph_new = null;
      let iph_old = null;
      // On affiche le détail uniquement sur Clichy
      if (centerId === 2 || centerId === 9) {
        if (typeof(packerstatiph["avg_new_iph_hour"]) !== "undefined") {
          iph_new = getStatIph(packerstatiph["avg_new_iph_hour"], true);
        }

        if (typeof(packerstatiph["avg_old_iph_hour"]) !== "undefined") {
          iph_old = getStatIph(packerstatiph["avg_old_iph_hour"], true);
        }
      }

      packerstatiph_avg = (
        <React.Fragment>
          {iph_global}
          {iph_new ? iph_new : null}
          {iph_old ? iph_old : null}
        </React.Fragment>
      );
    }

    return {
      titleRight : loadStr,
      packerstatiph_avg: packerstatiph_avg
    };
  }
);
