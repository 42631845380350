import React from "react";
import PropTypes from "prop-types";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import ExternalCallButton from "./ExternalCallButton";

const ExternalCallMenu = ({ task, children }) => {
  let weight = parseInt(task.linked_order.total_weight, 10);

  const ExternalCallPopover = (
    <Popover id="external-call" title="" style={{ width: "80px", height: "110px" }}>
      <Popover.Content>
        <div style={{ marginBottom: '1rem' }}>
          <ExternalCallButton provider="stuart" task={task}>Stuart</ExternalCallButton>
        </div>
        {weight < 33 ? <div>
          <ExternalCallButton provider="uber" task={task}>Uber</ExternalCallButton>
        </div> : null}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="left" overlay={ExternalCallPopover} rootClose transition={false}>
      <Button variant="primary" size="xs" disabled={task.updating}>
        {children}
      </Button>
    </OverlayTrigger>
  );
};

ExternalCallMenu.propTypes = {
  task: PropTypes.object.isRequired,
  children: PropTypes.string,
};

export default ExternalCallMenu;

