// component
import React from "react";
import {setCenterId} from "./utils/center";
import {Redirect, withRouter} from "react-router-dom";


class InternalNavigateCheckCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      sub: null,
    };
  }

  /**
   * centralise le matching + loading du coposant
   * @param params
   * @private
   */
  _matchMe(params) {
    if (params.center_id && !isNaN(parseInt(params.center_id, 10))) {
      // console.log("Navigate, center : ", params.center_id);
      setCenterId(parseInt(params.center_id, 10));
    }
  }

  componentDidUpdate(prevProps) {
    const query = new URLSearchParams(this.props.location.search)
    // console.log('plop plop', this.props.match.params, query.get('u'));
    let sub = query.get("sub");
    if(sub === undefined){
      sub = null;
    }
    if(this.state.redirect !== query.get("u") || this.state.sub !== sub){
      this.setState(
        {
          redirect: query.get("u"),
          sub: sub,
        }
      );
    }
    this._matchMe(this.props.match.params);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    // console.log('plop plop 2', this.props.match.params, query.get('u'));
    let sub = query.get("sub");
    if(sub === undefined){
      sub = null;
    }
    this.setState(
      {
        redirect: query.get("u"),
        sub: sub,
      }
    );
    this._matchMe(this.props.match.params);
  }

  render() {
    // console.log("redirect", this.state.redirect, this.redirect);
    // console.log("query", query.get('u'));
    if(!this.state.redirect){
      return null;
    }
    let to = this.state.redirect;
    if(this.state.sub !== null){
      to += "?sub=" + this.state.sub;
    }
    return <Redirect to={to}/>;
  }
}

export const NavigateCheckCenter = withRouter(InternalNavigateCheckCenter);
