
import React from "react";
import { connect } from 'react-redux';

import { extractCenterId } from "../utils/center";
import DeliveriesTodoLinked from "../containers/DeliveriesTodoLinked";
import DockListLinked from "../containers/DockListLinked";
import DriverStatesLinked from "../containers/DriverStatesLinked";
import DeliveriesInProgressLinked from "../containers/DeliveriesInProgressLinked";
import AlertLinked from "../containers/AlertLinked";
import TasksBlockedListLinked from "../containers/TasksBlockedListLinked";
import PackingNowListLinked from "../containers/PackingNowListLinked";
import PackingCheckingListLinked from "../containers/PackingCheckingListLinked";
import PackingUberDirectListLinked from "../containers/PackingUberDirectListLinked";
import PackingWaitingListLinked from "../containers/PackingWaitingListLinked";
import PackingDoingListLinked from "../containers/PackingDoingListLinked";
import PackingXdToCheckListLinked from "../containers/PackingXdToCheckListLinked";
import PackingLaterListLinked from "../containers/PackingLaterListLinked";
import PackingStandByListLinked from "../containers/PackingStandByListLinked";
import OrderToRestock from "./OrderToRestock";
import {OrderInfo} from "./OrderInfo";
import OrderHiddenLinked from "../containers/OrdersHiddenLinked";
import {Nexts} from "./Nexts";
import {ChangeNumberOfBagsModal} from "./modal/ChangeNumberOfBagsModal";
import {TransitAreaModal} from "./modal/TransitAreaModal";
import {ChangeTrolleyLocationModal} from "./modal/ChangeTrolleyLocationModal";
import {DriverIsoBagModal} from "./modal/DriverIsoBagModal";
import {DepositModalDriver} from "./modal/DepositModalDriver";
import {DepositModalDrivers} from "./modal/DepositModalDrivers";
import {OrderMissingProductModal} from "../apps/support/components/OrderMissingProductModal";
import {ConfirmModal} from "../apps/support/components/ConfirmModal";
import StuartCallModalLinked from "../containers/StuartCallModalLinked";
import UberCallModalLinked from "../containers/UberCallModalLinked";
import RestockModal from "./modal/RestockModal";
import {Stats} from "./Stats";
import Footer from "./Footer";
import {LAUNCHPAD_DEFAULT} from "../utils/launchpad";
// import {Search} from "./Search";
import IncomingPlanningsSessionLinked from "../containers/IncomingPlanningsSessionLinked";
import CheckProductConnected from "../containers/CheckMissingProduct";
import AlertNotificationsLinked from "../containers/AlertNotificationsLinked";
import {CommonModals} from "../common-comps/CommonModals";

// ex. accès variable env en jsx : {process.env.REACT_APP_DELEEVAPI}

const Home = ({ firstLoad, centers, centerId }) => {
  console.log('render home', firstLoad);
  if (firstLoad) {
    return (
      <div>
        <h1 className="loading">LOADING...</h1>
      </div>
    );
  } else {
    let center = centers.find(x => x.id === centerId);
    return (
      <div>
        {/*<Search/>*/}
        <AlertLinked />
        <TasksBlockedListLinked />
        {center && center.stock_type === 2 &&
          <PackingStandByListLinked />}
        <CheckProductConnected />
        {/*<DeliveriesStrangeLinked />*/}
        <OrderHiddenLinked />
        {center && center.is_shadow_groupment_enabled === false ?
          <React.Fragment>
            <DriverStatesLinked launchpad={LAUNCHPAD_DEFAULT} />
            <DockListLinked />
            <DeliveriesTodoLinked />
          </React.Fragment>
          : null
        }
        <OrderToRestock />
        <PackingWaitingListLinked />
        {center && center.stock_type === 1 &&
          <PackingXdToCheckListLinked />}
        <PackingCheckingListLinked />
        <PackingUberDirectListLinked />
        <PackingNowListLinked />
        <PackingDoingListLinked />
        <Nexts />
        <IncomingPlanningsSessionLinked />
        <PackingLaterListLinked />
        {center && center.is_shadow_groupment_enabled === false &&
            <DeliveriesInProgressLinked />}
        <div className="clearfix"/>
        <Stats />
        <OrderInfo className="order" >
        </OrderInfo>
        <ChangeNumberOfBagsModal />
        <TransitAreaModal />
        <ChangeTrolleyLocationModal />
        {center && center.is_shadow_groupment_enabled === false ?
          <React.Fragment>
            <DriverIsoBagModal />
            <DepositModalDriver />
            <DepositModalDrivers />
          </React.Fragment>
          : null
        }
        <OrderMissingProductModal/>
        <ConfirmModal />
        <StuartCallModalLinked />
        <UberCallModalLinked />
        <RestockModal />
        <CommonModals />
        <Footer />
        <AlertNotificationsLinked />
      </div>
    );
  }
};





// let Home = ({ dispatch }) => {
//   return (<div>
//     <Button variant="primary" size="lg" onClick={dispatch(addLine())}></Button>
//     <PackingTodoTable></PackingTodoTable>
//   </div>);
// };


export default connect(state => ({
  firstLoad: state.m.firstLoad,
  centers: state.m.metas.centers || [],
  centerId: extractCenterId(state.user),}), {}
)(Home);
