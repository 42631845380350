import React from 'react';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import "../css/OrderProducts.css";
import moment from "moment/moment";
import {SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT, validateCreditBulk} from "../actions";

import { MultipleCreditForm } from "./MultipleCreditForm";
import {getCreditSubTypeList} from "../../../selectors/credit_modal";

class InternalMultipleCreditModal extends React.Component {
  render() {
    const {sub_type_list, modal, hide, validate} = this.props;
    moment.locale('fr');

    if (modal && modal.show) {
      /* Set up defaults values min and max quantity */
      for (let orderProduct of modal.orderProducts) {
        let minQuantity = 0;
        let maxQuantity = 0;
        if (typeof (orderProduct.maxQuantity) !== 'undefined') {
          maxQuantity = orderProduct.maxQuantity;
          minQuantity = orderProduct.minQuantity;
        } else {
          minQuantity = -orderProduct.recreditQuantity;
          maxQuantity = orderProduct.defaultQuantity - orderProduct.recreditQuantity - orderProduct.refundQuantity;
          orderProduct.minQuantity = minQuantity;
          orderProduct.maxQuantity = maxQuantity;
          orderProduct.defaultQuantity = maxQuantity;
        }
        orderProduct.quantity = orderProduct.defaultQuantity;
      }
    }

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {
          modal && modal.show ?
          <MultipleCreditForm
            type="credit"
            subTypeList={sub_type_list}
            orderProducts={modal.orderProducts}
            subtype={modal.subtype}  // default value
            validate={(data) => {
              validate(data, modal);
            }}
          />
          : null
        }
      </Modal.Body>
    </Modal>;
  }
}

export const MultipleCreditModal = connect(
  (state) => ({
    "modal" : state.support.modalMultipleCredit,
    "sub_type_list": getCreditSubTypeList(state),
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT});
      },
      "validate": (data, modal) => {
        let products = []
        let notes = ''
        let subtype = ''
        let moreData = {
          orderId: modal.orderId,
          userId: modal.userId,
        };

        for (let values of data) {
          if (
            values.quantity === undefined
            || values.quantity === null
            || isNaN(values.quantity)
            || values.quantity === 0
            || !values.checked
          ) {
            continue;
          }

          notes = values.notes
          subtype = values.subtype

          products.push({
            order_product_id: values.order_product_id,
            quantity: values.quantity,
            price_per_unit_quantity: values.pricePerUnitQuantity,
            credit_amount: values.quantity * values.pricePerUnitQuantity,
          })
        }

        dispatch(validateCreditBulk(notes, subtype, products, moreData));

      }
    };
  }
)(InternalMultipleCreditModal);
