import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { Alert, Button, Col, Form } from "react-bootstrap";

function TrolleyNotFoundAlert ({ alert, reviewAlert }) {
  const [isReviewing, setIsReviewing] = useState(false);
  const [reviewComments, setReviewComments] = useState("");
  const [validationError, setValidationError] = useState("");

  const {
    id,
    created_at,
    order_id,
    order_random_id,
    trolley_name,
    expected_location,
  } = alert;

  const onValidateReview = () => {
    if (reviewComments.length < 5) {
      setValidationError("Veuillez saisir un commentaire (5 caractères min.)");
      return;
    }
    const data = { id, review_comments: reviewComments };
    reviewAlert(data);
  };

  return (
    <Alert variant="danger" key={id} className="mb-1">
      <span style={{ marginRight: '1rem' }}>
        {moment(created_at).format('HH:mm').replace(':', 'h')},&nbsp;
        <a
          href={"/support/order/"+order_id}
          target="_blank" rel="noopener noreferrer"
          tabIndex="0"
        >
          #{order_random_id}
        </a>:&nbsp;
        Le chariot <strong>{trolley_name}</strong> n&apos;a pas été trouvé
        en <strong>{expected_location}</strong>
      </span>
      {isReviewing ? (
        <Form>
          <Form.Row>
            <Col xs={9}>
              <Form.Control
                as="textarea"
                rows="3"
                value={reviewComments}
                onChange={e => setReviewComments(e.target.value)}
                placeholder="Votre commentaire..."
              />
            </Col>
            <Col xs={3}>
              <Button
                size="sm"
                variant="success"
                className="d-block mb-1"
                onClick={onValidateReview}
              >
                Valider
              </Button>
              <Button
                size="sm"
                variant="default"
                className="d-block"
                onClick={() => {
                  setIsReviewing(false);
                  setReviewComments("");
                }}
              >
                Annuler
              </Button>
            </Col>
          </Form.Row>
          {validationError && (
            <Form.Row>
              <Col>
                <span className="text-danger">{validationError}</span> 
              </Col>
            </Form.Row>
          )}
        </Form>
      ): (
        <Button
          size="xs"
          variant="default"
          onClick={() => setIsReviewing(true)}
          className="float-right"
        >
          Traiter
        </Button>
      )}
    </Alert>
  );
}

TrolleyNotFoundAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  reviewAlert: PropTypes.func.isRequired,
};

export default TrolleyNotFoundAlert;
