import React from "react";
import { createSelector } from 'reselect';

/* TAP = Temps moyen entre le début de la tâche de picking et le premier picking */

const getPackerstatTap = state => state.m.packerstatsbeforepicking;

export const getTapAvg = createSelector(
  [getPackerstatTap],
  (packerstattap) => {

    let packerstattap_avg = null;
    if (packerstattap && typeof(packerstattap['by_packer']) !== 'undefined') {
      const tap_global = packerstattap['by_packer'][0];

      let tap_global_per_zone = <small>
        <br />
        <b>TD Moyen</b> {` => `}
        {Object.values(tap_global["datas"]).map((tap, index) => {
          return <span key={index} title={tap.tap_tooltip} className="iph_tooltip">{index > 0 ? <b> / </b> : null}{tap.time_name}: <span style={{color: "grey"}}>{tap.time_avg}</span></span>;
        })}
      </small>;
      

      packerstattap_avg = (
        <React.Fragment>
          {tap_global_per_zone}
        </React.Fragment>
      );
    }

    return packerstattap_avg;
  }
);
