/*
  Retourne une adresse parmi une liste d'adresses en se basant sur l'intitulé
  et non sur l'id.
  Permet de matcher Address et ProfileAddress
*/
export const findAddress = (address, addresses) => {
  return addresses.datas.find(e => (
    e.street_number === address.street_number
      && e.street === address.street
      && e.postal_code === address.postal_code
  ));
};
