

export function flattenAddress(address) {
  if (address === undefined)
    return ""
  return `${address.street_number} ${address.street}, ${address.postal_code} ${address.city}`;
}


export function mapUrl(destAddress, centerId, centers, srcAddress = null ) {
  if (srcAddress === null) {
    if(!centers){
      return '';
    }
    let center = centers.find(x => x.id === centerId);
    if(!center){
      return '';
    }
    srcAddress = center.address;
  }

  const src = flattenAddress(srcAddress).replace(/ /g, '+');
  const dst = flattenAddress(destAddress).replace(/ /g, '+');

  return `https://maps.google.com/?saddr=${src}&daddr=${dst}`;
}

export function mapUrlRound(addresses, centerId, centers) {
  let addressesWithCenter = [];
  if(!centers){
    return '';
  }
  let center = centers.find(x => x.id === centerId);
  if(!center){
    return '';
  }
  addressesWithCenter.push(center.address);
  for (const ad of addresses) {
    addressesWithCenter.push(ad);
  }

  let flattenList = [];
  for (const ad of addressesWithCenter) {
    flattenList.push(flattenAddress(ad).replace(/ /g, '+'));
  }
  return "https://www.google.fr/maps/dir/" + flattenList.join('/') + '/';
}


export default { mapUrl, flattenAddress }
