import React from 'react';
import PropTypes from 'prop-types';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from "react-redux";
import {
  DISPATCH_DOCK_MANUALLY_OPTIMIZED,
  DISPATCH_DOCK_MANUALLY_VALIDATED,
} from "../../../actions/dock";


export const DockManuallyOptimizedButton = connect()(
  ({dock , dispatch}) => {
    let label = "J'ai optimisé manuellement";
    let style = "primary";

    return (
      <Button
        variant={style}
        size="xs"
        onClick={() => dispatch({type: DISPATCH_DOCK_MANUALLY_OPTIMIZED, dock: dock})}
      >
        {label}
      </Button>
    );
  }
);
DockManuallyOptimizedButton.propTypes = {
  dock: PropTypes.object.isRequired
};


export const DockManuallyValidatedButton = connect()(
  ({dock , dispatch}) => {
    let label = "Tournée validée en l'état";
    let style = "primary";

    return (
      <Button
        variant={style}
        size="xs"
        onClick={() => dispatch({type: DISPATCH_DOCK_MANUALLY_VALIDATED, dock: dock})}
      >
        {label}
      </Button>
    );
  }
);
DockManuallyValidatedButton.propTypes = {
  dock: PropTypes.object.isRequired
};


export class BtnEstimateKOInternal extends React.Component {
  render() {
    const dock = this.props.dock;
    const tooltip = (
      <Tooltip id={`tooltip-estimate-ko-${dock.id}`}>
        <DockManuallyOptimizedButton dock={dock} />
        <br />
        <DockManuallyValidatedButton dock={dock} />
      </Tooltip>
    );
    const variant = dock.has_failed_last_estimate ? "danger" : "success";
    return (
      <OverlayTrigger placement="top" overlay={tooltip} transition={false} trigger={"click"}>
        <Button
          size="xs"
          className="BtnEstimateKO toggle"
          variant={variant}
        >
          <span title={"Estimation KO"}>EKO</span>
        </Button>
      </OverlayTrigger>
    );
  }
}

BtnEstimateKOInternal.propTypes = {
  dock: PropTypes.object,
};

export const BtnEstimateKO = connect(
  null, null,
  // (state) => {
  //   return {
  //     authUser: state.user,
  //   };
  // },
  // dispatch => ({
  //   "optimize": (dock) => dispatch(showOptimizeDock(dock))
  // })
)(BtnEstimateKOInternal);
