import moment from "moment/moment";

export function filterAndSortDockTasks(tasks, dock_id) {
  let tasks_filtered = tasks.filter((t) =>
    (t.dock && t.dock.id === dock_id)
    || (t.dock_target && t.dock_target.id === dock_id)
  ).map(t => ({...t}));

  return sortTasksInDock(tasks_filtered, dock_id);
}

export function sortTasksInDock(tasks, dock_id) {
  /* Méthode qui permet d'ordonner les tâches en fonction du
   * dock_rank et dock_rank_target (si la tâche est en cours
   * de déplacement)
   */
  tasks.sort(function(a, b) {
    let x = a.dock_rank_target;
    if (a.dock && a.dock.id === dock_id) {
      x = a.dock_rank;
    }

    let y = b.dock_rank_target;
    if (b.dock && b.dock.id === dock_id) {
      y = b.dock_rank;
    }
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
  return tasks;
}

export function isLock(allTasks, dock) {
  /*
  if (allTasks.length && dock.defined_at && dock.dock_type === "NOCONFIG") {
    return true;
  }
  */

  // Si une tâche d'expéition surgelé est en cours => on bloque
  if (dock.load_info && dock.load_info.is_expe_frozen) {
    return true;
  }

  let isLock = dock.tasks.reduce((acc, task) => {
    if (!!task.updating || acc) {
      return true;
    }
    // On verrouille aussi s'il y a une tâche packing_on_expe en cours sur le dock.
    return task.linked_order.pseudo_zones_status.reduce((acc_opz, opz) => {
      if ((opz.pseudozone.is_packing_on_expe && opz.is_locked) || acc_opz) {
        return true;
      }
      return false;
    }, acc);

  }, !!dock.updating);
  if (allTasks.length) {
    isLock = !!allTasks.filter(e => e.dock_updating === dock.id).length || isLock;
  }
  return isLock;
}


export const isNow = (checkDate) => {
  let today = moment();
  let checkDateMoment =  moment(checkDate);
  return today.isSame(checkDateMoment, "day") || checkDateMoment < today;
};

export const getSortableDatetimeValue = (t) => {
  const etd_at =  t.linked_order.priority_rank;

  if (!etd_at) {
    console.log("etd is null ? ", t);
  }
  return etd_at ? etd_at : t.linked_order.shipping_time;
};

export const getShippingSortableDatetimeValue = (t) => {
  const max_shipping_time =  t.linked_order.max_shipping_time;
  if (!max_shipping_time) {
    console.log("etd is null ? ", t);
  }
  return max_shipping_time ? max_shipping_time : t.linked_order.shipping_time;
};

export const slotLabel = (taskOrOrder) => {
  let label = 'N/A';
  if(taskOrOrder.shipping_time) {
    const start_date = moment(taskOrOrder.shipping_time);
    label = start_date.format('HH:mm') + ' - ??';
  }

  if(taskOrOrder.max_shipping_time) {
    const start_date = moment(taskOrOrder.shipping_time);
    const end_date = moment(taskOrOrder.max_shipping_time);
    label = start_date.format('HH:mm') + ' - ' + end_date.format('HH:mm');
    const order = taskOrOrder.linked_order || taskOrOrder;
    if (order && order.tags.includes('dqp_express_prio')) {
      label = 'QUICK+ ' + end_date.format('HH:mm');
    }
    else if (order && order.tags.includes('dqp_courier')) {
      label = 'BOLT ' + end_date.format('HH:mm');
    }
    else if (order && order.tags.includes('dqp_express')) {
      label = 'QUICK ' + end_date.format('HH:mm');
    }
    else if (taskOrOrder.is_speed) {
      label = 'SPEED ' + end_date.format('HH:mm');
    }
  }
  return label;
};

export const slotDepartureHourLabel = (order) => {
  let etdLabel = '';
  if (order.estimated_travel_departure_at) {
    const departureHour = moment(order.estimated_travel_departure_at).subtract(10, 'minutes').format("HH:mm", "fr");
    etdLabel = `D:${departureHour}max`;
  }
  return etdLabel;
};

export const getTasksSameTrolleyOnDock = (state, task) => {
  let currentTaskTrolley = null;
  let tasksSameTrolleyOnDock = [task];
  if (
    task.dock
    && task.linked_order.transit_area_current
    && typeof(state.transit_areas[task.linked_order.transit_area_current]) !== "undefined"
    && state.transit_areas[task.linked_order.transit_area_current].trolley
  ) {
    currentTaskTrolley = state.transit_areas[task.linked_order.transit_area_current].trolley;
    tasksSameTrolleyOnDock = filterAndSortDockTasks(state.deliveries, task.dock.id).filter(t => {
      if (
        t.linked_order.transit_area_current
        && typeof(state.transit_areas[t.linked_order.transit_area_current]) !== "undefined"
        && state.transit_areas[t.linked_order.transit_area_current].trolley
        && state.transit_areas[t.linked_order.transit_area_current].trolley.id === currentTaskTrolley.id
      ) {
        return true;
      }
      return false;
    });
  }
  return tasksSameTrolleyOnDock;
};

export default  { filterAndSortDockTasks, slotLabel, slotDepartureHourLabel, getTasksSameTrolleyOnDock };
