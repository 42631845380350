import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {SUPPORT_MODAL_HIDE, validateChangeStatus} from "../actions";

class InternalChangeStatusModal extends React.Component {


  render() {
    const {order_product_status, modal, hide, validate} = this.props;


    // On classe les status par order alphabétique
    let op_status = [];
    for (var key in order_product_status) {
      if (order_product_status.hasOwnProperty(key)) {
        op_status.push({
          'code': key,
          'label': order_product_status[key]
        });
      }
    }
    op_status.sort(function(a, b) { return a.label.toLowerCase().localeCompare(b.label.toLowerCase()); });

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'change_status'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          initialValues={{
            orderProductId: modal.orderProductId,
            oldStatus: modal.oldStatus,
            op_status: op_status
          }}
          onSubmit={(values, actions) => {
            validate(values);
          }}
          render={(props: FormikProps<Values>) => {
            let {values } = props;
            return <form onSubmit={props.handleSubmit} className="form-horizontal">
              <FormGroup as={Row} controlId="formControlsSelect">
                <Col as={Form.Label} sm={6}>
                  Passer du statut <em>"{order_product_status[values.oldStatus]}"</em> à : 
                </Col>
                <Col sm={6}>
                  <Field
                    name="new_status"
                    className="form-control"
                    required="required"
                    component="select"
                  >
                    <option value="">-- Choisir --</option>
                    {values.op_status.map(status => (
                      <option key={status.code} value={status.code}>{status.label} ({status.code})</option>
                    ))}
                  </Field>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={12} className="offset-sm-5">
                  <button type="submit" className="btn btn-primary">Modifier le statut</button>
                </Col>
              </FormGroup>
            </form>;
          }}
        />
      </Modal.Body>
    </Modal>;

  }
}


export const ChangeStatusModal = connect(
  (state) => ({
    "modal" : state.support.modal,
    "order_product_status": state.support.meta ? state.support.meta.order_product_status : {},
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (values) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch(validateChangeStatus(
          values.orderProductId,
          values.new_status
        ));
      }
    };
  }
)(InternalChangeStatusModal);



