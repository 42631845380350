import { createSelector } from "reselect";
import { STATUS_ORDER_XD_TO_CHECK, STATUS_ORDER_PREPARED } from "../../utils/status";
import { XD_FULL_STOCK_ORDER } from "../../utils/crossDocking";
import { USER_TYPE_REAPPRO_ID } from "../../utils/user";
import { maxShippingSorting } from "../../utils/task";
import { computeDockList } from "../../containers/common-props/dock";

const getTasks = (state) => state.m.deliveries;
const getDocks = (state) => state.m.docks;
const getDrivers = (state) => state.m.drivers;
const getPackingToCheck = state => state.m.packing_to_check

const getDockListXD = (state) => state.m.dock_list_cross_docking;
const getDeliveringDockListXD = (state) => state.m.dock_list_cross_docking_doing ;

export const getXDToCheckTasks = createSelector([getTasks], (tasks) =>
  tasks
    .filter(t =>
      (t.linked_order.status === STATUS_ORDER_XD_TO_CHECK) ||
      (
        t.client.user_type === USER_TYPE_REAPPRO_ID
        && t.linked_order.status === STATUS_ORDER_PREPARED
        && !t.dock
      )
    ).sort((a, b) => {
      if (a.address.hash < b.address.hash) {
        return -1;
      } else if (a.address.hash > b.address.hash) {
        return 1;
      } else {
        if (a.shipping_time >= b.shipping_time) {
          return -1;
        } else {
          return 1;
        }
      }
    })
);

export const getComputedDockListXD = createSelector(
  [getDockListXD, getTasks, getDocks, getDrivers],
  (dockList, deliveries, docks, drivers) => {
    return computeDockList(dockList, deliveries, docks, drivers);
  }
);

export const getComputedDeliveringDockListXD = createSelector(
  [getDeliveringDockListXD, getTasks, getDocks, getDrivers],
  (dockList, deliveries, docks, drivers) => {
    return computeDockList(dockList, deliveries, docks, drivers);
  }
);

export const getTransfertDrivers = createSelector([getDrivers], (drivers) =>
  drivers.filter((d) => d.name.includes("Transfert Bam"))
);

export const getSortedXDPackingToCheck = createSelector(
  [getPackingToCheck],
  packingToCheck => packingToCheck.filter(
    t => t.linked_order.cross_docking === XD_FULL_STOCK_ORDER
  ).slice().sort(maxShippingSorting)
);
