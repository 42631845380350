import {connect} from "react-redux";

import CrateModal from '../../../common-comps/CrateModal';

import {
  SUPPORT_MODAL_HIDE,
  SUPPORT_ORDER_CRATE_ADD,
  SUPPORT_ORDER_CRATE_DELETE
} from "../actions";


export default connect(
  state => ({
    modal : state.support.modal,
    userMe: state.user,
  }),
  dispatch => ({
    hide: () => {
      dispatch({ type: SUPPORT_MODAL_HIDE });
    },
    deleteCrate: (crateCode, orderId) => dispatch({ type: SUPPORT_ORDER_CRATE_DELETE, orderId, crateCode }),
    addCrate: ({ crateCode, orderId }) => dispatch({ type: SUPPORT_ORDER_CRATE_ADD, orderId, crateCode }),
  })
)(CrateModal);
