import {RepreparationReasonModal} from "./RepreparationReasonModal";
import {BlockingExitReasonModal} from "./BlockingExitReasonModal";
import {CanteenOrdersModal} from "./CanteenOrdersModal";
import React from "react";

export const CommonModals = () => {
  return (
    <React.Fragment>
      <RepreparationReasonModal />
      <BlockingExitReasonModal />
      <CanteenOrdersModal />
    </React.Fragment>
  );
};
