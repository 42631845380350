import React from 'react';
import PropTypes from "prop-types";

import "../css/Paginator.css";

export const Paginator = ({data_object, paginated_api_datas, handlePagination}) => {
  if (!paginated_api_datas.next && !paginated_api_datas.previous) {
    return null;
  }

  const goToPage = (object, offset) => {
    handlePagination(object, offset);
  };
  let current_page = (paginated_api_datas.offset / paginated_api_datas.limit) + 1;
  let nb_pages = Math.ceil(paginated_api_datas.count / paginated_api_datas.limit);


  return (
    <div className="Paginator">
      {paginated_api_datas.previous &&
        <button className="btn btn-default float-left btn-xs"
          onClick={() => goToPage(data_object, paginated_api_datas.previous_offset)}>
          <i className="fa-solid fa-chevron-left"></i> Page précédente
        </button>}
      {current_page} / {nb_pages}
      {paginated_api_datas.next &&
        <button className="btn btn-default float-right btn-xs" 
          onClick={() => goToPage(data_object, paginated_api_datas.next_offset)}>
          Page suivante <i className="fa-solid fa-chevron-right"></i>
        </button>}
    </div>
  );

};

Paginator.propTypes = {
  data_object: PropTypes.object.isRequired, // ex: driver ou deposit
  paginated_api_datas: PropTypes.object.isRequired, // ex réponse de l'api avec pagination (count, next, offset,...)
  handlePagination: PropTypes.func.isRequired, // callback qui met à jour les données
};

