import {sortByKey} from "./index";

export const DOCK_TYPE_HARD_ASSIGNED = 'DRIVER_HARD';
export const DOCK_TYPE_SOFT_ASSIGNED = 'DRIVER_SOFT';
export const DOCK_TYPE_FIRST = 'FIRST';
export const DOCK_TYPE_NOCONFIG = 'NOCONFIG';
export const DOCK_TYPE_STUART = 'STUART';
export const DOCK_TYPE_UBER = 'UBER';

export const EXTERNAL_DOCK_TYPES = [DOCK_TYPE_STUART, DOCK_TYPE_UBER];

export const VEHICLES_LONG_LABEL = [
  {value:'S50', label: 'Scoot 50'},
  {value:'S125', label:  'Scoot 125'},
  {value:'SELEC', label:  'Scoot ELEC'},
  {value:'CAR', label:  'Voiture'},
  {value:'BIKE', label:  'Vélo'}
];
export const VEHICLES_SHORT_LABEL = {
  'S50': '50',
  'S125': '125',
  'SELEC': 'ELEC',
  'CAR': 'CAR',
  'BIKE': 'VLO'
};


export const isDeletable = function (dock) {

  if (dock.updating) {
    return false;
  }

  if (dock.defined_at) {
    return false;
  }

  if (dock.dock_type !== 'DRIVER_HARD' && !dock.locked_at) {
    return true;
  }

  if (dock.driver && ['plan', 'noplan'].includes(dock.driver.state) && !dock.locked_at) {
    return true;
  }


  return false;
};

export const SORT_BY_TYPE = {
  DOCK_TYPE_HARD_ASSIGNED: 9,
  DOCK_TYPE_SOFT_ASSIGNED: 8,
  DOCK_TYPE_STUART: 8,
  DOCK_TYPE_FIRST: 8,
  DOCK_TYPE_NOCONFIG: 8
};

export const isAssignable = function (dock, dockList) {
  // TODO: finir pour les non hard (sans hard present) et implanter
  if (dock.tasks.length === 0) {
    return false;
  }

  if (!dock.driver) {
    return false;
  }

  if (dock.locked_at) {
    return false;
  }

  if (['STUART', 'FIRST', 'NOCONFIG'].includes(dock.dock_type)) {
    return false;
  }

  if (dock.driver.state === "plan") {
    return false;
  }

  let driver_id = dock.target_driver;
  let nbDriverDock = dockList.filter(d => d.target_driver === driver_id).length;

  if (dock.dock_type === 'DRIVER_SOFT' && nbDriverDock > 1 && dock.driver.state !== 'wait') {
    return false;
  }

  return true;
};


export const buildDockConfig = function (dock, dockList, driverList) {
  const existingDriverInDock = dockList
    .filter(d => !!d.target_driver && !d.gone_at)
    .map(e => e.target_driver);

  // const existingTrInDock = !!dock.tasks.filter(t => t.linked_order.paid_with_tr).length;
  // const existingTranquilityInDock = !!dock.tasks.filter(t => t.linked_order.option_tranquility).length;


  // let vehicles = new Set();
  // for(let d of driverList) {
  //   vehicles.add(d.vehicle);
  // }

  let defaultOptions = [
    {value: "NOCONFIG", label: "Non configuré"},
  ];

  if (!dock.cross_docking_target_center) {
    for (let vehicle of VEHICLES_LONG_LABEL) {
      defaultOptions.push({value: "FIRST_" + vehicle.value, label: vehicle.label + ": premier dispo"});
    }
    defaultOptions.push({value: "FIRST_S50125", label: "50/125 : premier dispo"});
    defaultOptions.push({value: "FIRST_SCO", label: "Scoot : premier dispo"});
    defaultOptions.push({value: "FIRST_ALL", label: "Tous : premier dispo"});
  }

  // if (!existingTrInDock && !existingTranquilityInDock) {
  //   defaultOptions.push({value: "STUART", label: "Stuart"});
  // }
  defaultOptions.push({value: "", label: "-----"});

  const driverFiltered = dock.cross_docking_target_center
    ? driverList.filter(d =>
      d.name.includes('Transfert Bam') && (!existingDriverInDock.includes(d.id) || d.id === dock.target_driver)
    ) : driverList.filter(d => !existingDriverInDock.includes(d.id) || d.id === dock.target_driver);

  const driversOption = driverFiltered.map(e =>
    ({value: e.id, label: e.name})).sort(sortByKey('label'));

  return defaultOptions.concat(driversOption);
};
