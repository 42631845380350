import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";

import moment from "moment/moment";

import "../../css/OrderDeliveryDetail.css";
import {
  showPopinOrderCredit,
  showPopinReprepareOrderBefore,
  showPopinReDeliverOrder,
  showPopinRefundOrder
} from "../../actions";
import {isRedeliverStillInTime} from "../../../dispatch/utils/order";
import { canRefundOrder } from "../../utils";

const InternalOrderActions = ({
  order,
  user,
  showPopinCredit,
  showPopinReprepare,
  showPopinReDeliver,
  showPopinRefund,
  readOnly,
}) => {
  if (!order) {
    return null;
  }

  moment.locale('fr');

  return <Table size="sm" hover className="OrderActions">
    <tbody>
      <tr><th colSpan={2}>Actions possibles</th></tr>
      <tr>
        <td></td>
        <td>
          <Button
            variant="primary"
            size="xs"
            onClick={() => showPopinCredit(order, user) }
            disabled={readOnly || order.updating || order.is_uber_eats_order}
          >
            Dédommagement
          </Button>
        </td>
      </tr>
      {
        ["shipping", "received"].includes(order.status) &&
        <tr>
          <td></td>
          <td>
            <Button
              variant="primary"
              size="xs"
              onClick={() => showPopinReprepare(order) }
              disabled={order.updating || order.is_uber_eats_order}
            >
              Repréparer la commande
            </Button>
          </td>
        </tr>
      }
      {
        ["shipping", "received"].includes(order.status) && isRedeliverStillInTime(order) &&
        <tr>
          <td></td>
          <td>
            <Button
              variant="primary"
              size="xs"
              onClick={() => showPopinReDeliver(order)}
              disabled={order.updating || order.is_uber_eats_order}
            >
              Relivrer le(s) sac(s) oublié(s)
            </Button>
          </td>
        </tr>
      }
      {
        canRefundOrder(order) &&
        <tr>
          <td></td>
          <td>
            <Button
              variant="warning"
              size="xs"
              onClick={() => showPopinRefund(order)}
              disabled={order.updating || order.is_uber_eats_order}
            >
              Remboursement
            </Button>
          </td>
        </tr>
      }
    </tbody>
  </Table>;
};

InternalOrderActions.propTypes = {
  "order": PropTypes.object,
  "showPopinCredit": PropTypes.func,
  "showPopinReprepare": PropTypes.func,
  "showPopinReDeliver": PropTypes.func,
  "showPopinRefund": PropTypes.func,
  readOnly: PropTypes.bool,
  user: PropTypes.object,
};

export const OrderActions = connect(
  (state) => ({
    pseudoZones: state.support.meta ? state.support.meta.pseudoZones : [],
  }),
  (dispatch) => {
    return {
      "showPopinCredit": (order, user) => {
        dispatch(showPopinOrderCredit(order, user));
      },
      "showPopinReprepare": (order) => {
        dispatch(showPopinReprepareOrderBefore(order));
      },
      "showPopinReDeliver": (order) => {
        dispatch(showPopinReDeliverOrder(order));
      },
      "showPopinRefund": (order) => {
        dispatch(showPopinRefundOrder(order));
      }
    };
  }
)(InternalOrderActions);
