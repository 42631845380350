import {getDeliveriesToDispatch} from "../selectors/deliveries";
import {cleanRowsSelected} from "../../../actions/deliveries_todo";
import { DISPATCH_SKIP } from "./index";
import {DISPATCH_DIRECT_DISPATCH_MANY} from "./deliveries";

export const DISPATCH_DOCK_DROP_ON_DEFAULT = 'DISPATCH_DOCK_DROP_ON_DEFAULT';
export const DISPATCH_DOCK_DROP_ON_DELIVERY_TASK = 'DISPATCH_DOCK_DROP_ON_DELIVERY_TASK';
export const DISPATCH_DROP_ON_DEFAULT = 'DISPATCH_DROP_ON_DEFAULT';
export const DISPATCH_DROP_ON_DELIVERY_TASK = 'DISPATCH_DROP_ON_DELIVERY_TASK';
export const DISPATCH_DOCK_DROP = 'DISPATCH_DOCK_DROP';
export const DISPATCH_CANCEL_DELIVERY_DROP = 'DISPATCH_CANCEL_DELIVERY_DROP';

const _extractOrderPatch = (droppedTask) => {
  let droppedOrderPatch = {};
  if (droppedTask.linked_order.transit_area.length === 1) {
    let newTransitArea = prompt("Mettez le frais au frigo et indiquez le zonage du sec:");
    if (!newTransitArea) {
      return {type: DISPATCH_SKIP};
    }
    droppedOrderPatch = {id: droppedTask.linked_order.id, transit_area: newTransitArea};
  }
  return droppedOrderPatch;
};
export const cancelDeliveryDrop = (task) => {
  //console.log("Action drag_n_drop cancelDeliveryDrop", task);
  return {
    type: DISPATCH_CANCEL_DELIVERY_DROP,
    task: task
  };
};

export const dockDropOnDefaultInternal = (dockTarget, droppedTask, dockFrom) => {
  const droppedTaskDockId = droppedTask.dock ? droppedTask.dock : null;
  console.log("Action drag_n_drop dockDropOnDefaultInternal", dockTarget, droppedTaskDockId, droppedTask);
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }

  return {
    type: DISPATCH_DOCK_DROP_ON_DEFAULT,
    dockFrom: dockFrom,
    dockTarget: dockTarget,
    droppedOrderPatch: actionOrPatch,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

export const dockDropOnTaskInternal = (targetTask, droppedTask, dockFrom, dockTarget) => {

  console.log(
    "Action drag_n_drop dockDropOnTaskInternal",
    targetTask, droppedTask, dockFrom, dockTarget
  );

  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    console.log("Action drag_n_drop dockDropOnTaskInternal type", actionOrPatch.type)
    return actionOrPatch;
  }

  return {
    type: DISPATCH_DOCK_DROP_ON_DELIVERY_TASK,
    droppedOrderPatch: actionOrPatch,
    dockFrom: dockFrom,
    dockTarget: dockTarget,
    targetTaskId: targetTask.id,
    droppedTaskId: droppedTask.id,
  };
};

export const dropOnDefaultInternal = (dock, droppedTask) => {
  //console.log("dropOnDefault", dock, droppedTask);
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  console.log("Action drag_n_drop dropOnDefaultInternal", dock, droppedTask);
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }
  return {
    type: DISPATCH_DROP_ON_DEFAULT,
    droppedOrderPatch: actionOrPatch,
    dock: dock ? dock  : null,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

export const dropOnTaskInternal = (targetTask, droppedTask, dockTarget) => {
  console.log("Action drag_n_drop dropOnTaskInternal", targetTask, droppedTask, dockTarget);
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }

  return {
    type: DISPATCH_DROP_ON_DELIVERY_TASK,
    droppedOrderPatch: actionOrPatch,
    targetTaskId: targetTask.id,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

export const dropOnDock = function (targetDock, droppedDock, targetShouldBeEmpty) {
  return {
    type: DISPATCH_DOCK_DROP,
    targetDockId: targetDock.id,
    droppedDockId: droppedDock.id,
    targetShouldBeEmpty: targetShouldBeEmpty,
  };
};


/**
 * redux-thunk enables operations to be dispatched,
 * i.e. implicit super-actions.
 * Thus, the original dispatches corresponding to multiDropTask calls
 * just after this function will transparently be converted
 * into a sequence of normal actions dispatches.
 * @param subFunction
 * @returns {function(*=, *=): function(*, *): void}
 */
function multiDropTask(subFunction) {
  return (param1, param2, param3, param4) => (dispatch, getState) => {
    let selectedTasksIds = getState().deliveriesTodo.selectedTasksIds.slice();
    if (selectedTasksIds.includes(param2.id)) {
      let deliveriesTodo = getDeliveriesToDispatch(getState());
      dispatch(cleanRowsSelected());
      for(let id of selectedTasksIds){
        let task = deliveriesTodo.find(t => t.id === id);
        if(task){
          dispatch(subFunction(param1, task, param3, param4));
        }
      }
    }
    else{
      // console.log('simple');
      dispatch(subFunction(param1, param2, param3, param4));
    }
  };
}

export const dockDropOnDefault = multiDropTask(dockDropOnDefaultInternal);
export const dockDropOnTask = multiDropTask(dockDropOnTaskInternal);
export const dropOnDefault = multiDropTask(dropOnDefaultInternal);
export const dropOnTask = multiDropTask(dropOnTaskInternal);
export const createTour = () => (dispatch, getState) => {
  let selectedTasksIds = getState().deliveriesTodo.selectedTasksIds.slice();
  if (selectedTasksIds.length > 0) {
    let deliveriesTodo = getDeliveriesToDispatch(getState());
    dispatch(cleanRowsSelected());
    let task_prio_drop = false;
    let tasks = [];
    for(let id of selectedTasksIds){
      let task = deliveriesTodo.find(t => t.id === id);
      if(task){
        tasks.push(task);
        if(
          task.linked_order.tags.includes("dqp_express_prio")
          || task.linked_order.tags.includes("dqp_courier")
        ){
          task_prio_drop = true;
        }
      }
    }
    dispatch({ type: DISPATCH_DIRECT_DISPATCH_MANY, tasks, define: task_prio_drop });
  }
};
