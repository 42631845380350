import React from 'react';
import {FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {sortByKey} from "../../utils";

export class DriverIsoBagModalContent extends React.Component {

  render() {
    const {modal, hide, validate} = this.props;

    if (!modal.driverList) {
      return null;
    }


    const driverList = modal.driverList.sort(sortByKey('name'));

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'driver_iso_bag'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>
          Livreurs > sac iso
        </h4>
        <Formik
          initialValues={{
            driver_id: "",
            operation: "",
            amount: 0
          }}
          onSubmit={(values, actions) => {
            console.log("modal bags", values, actions);
            validate(values);
          }}
          render={(props: FormikProps<Values>) => {
//            let {values } = props;
            return <form onSubmit={props.handleSubmit}>
              <FormGroup key="driver">
                <Field
                  name="driver_id"
                  className="form-control"
                  required="required"
                  component="select"
                >
                  <option value="">-- Choisir --</option>
                  {driverList.map(d => (
                    <option key={d.id} value={d.id}>{d.name})</option>
                  ))}
                </Field>

              </FormGroup>
              <FormGroup key="ope">
                <label>action</label>
                <Field
                  name="operation"
                  className="form-control"
                  required="required"
                  component="select"
                >
                  <option value="">-- Choisir --</option>
                  <option value="debit">A rendu</option>
                  <option value="credit">A pris</option>
                </Field>
              </FormGroup>
              <FormGroup key="NOBISO">
                <label>Nombre de sac isotherme</label>
                <Field
                  type="number"
                  name="amount"
                  className="form-control"
                  required="required"
                />
              </FormGroup>

              <FormGroup style={{textAlign:"center", marginBottom:"0px"}}>
                <button type="submit" className="btn btn-primary">Enregistrer</button>
              </FormGroup>
              <div className="clearfix" style={{fontSize:"1px", height:"1px"}}></div>
            </form>;
          }}
        />
      </Modal.Body>
    </Modal>;

  }
}

export default DriverIsoBagModalContent;
