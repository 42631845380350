import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import {
  DISPATCH_MAIN_MODAL_HIDE,
  DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT,
} from "../../actions/modal";

const PostalCodesFilterInputModalInternal = (
  {
    modalData,
    postalCodes,
    postalCodesSet,
    deliveries,
    hide,
    departments,
    idf_departments,
    non_idf_departments,
  }
) => {

    const changePostalCodes = (somePostalCodesSet) => {
        modalData.updateChoice(Array.from(somePostalCodesSet).join(","));
    }
    const removePostalCode = (postalCode) => {
        postalCodesSet.delete(postalCode);
        changePostalCodes(postalCodesSet);
    }
    const addPostalCode = (postalCode) => {
        postalCodesSet.add(postalCode);
        changePostalCodes(postalCodesSet);
    }

    const WHITE_LIST = departments.map((department) => department.code);
    let whiteList = departments.filter(
      (department) => {
        return !postalCodesSet.has(department.code);
      }
    )

    let otherPostalCodesSet = new Set([]);
    for (let task of deliveries) {
        if (task.address && task.address.postal_code) {
            otherPostalCodesSet.add(task.address.postal_code);
            if (task.address.postal_code.length > 2) {
                otherPostalCodesSet.add(task.address.postal_code.substring(0, 2));
            }
        }
    }
    for (let postalCode of WHITE_LIST) {
        otherPostalCodesSet.delete(postalCode);
    }
    let otherPostalCodes = Array.from(otherPostalCodesSet);
    otherPostalCodes.sort((a,b) => ((a > b)-(a < b)));

    return <Modal
      show={modalData && modalData.modalType === DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className=" PostalCodesFilterInputModal"
      enforceFocus={false}
    >
      <Modal.Body>
          Codes postaux sélectionnés (cliquer pour désélectionner) :
          {postalCodes.map((postalCode) => {
              return <button key={postalCode} className="btn btn-xs btn-default ml-2" onClick={(e) => removePostalCode(postalCode)}>{postalCode}</button>
          })}
          <br />
          <br />
          <hr/>
          <hr/>
          <br />
          Ajouter (cliquer pour sélectionner) des départements fréquents :
          {whiteList.map((department) => {
              return <button
                  key={department.code}
                  className="btn btn-xs btn-default ml-2"
                  onClick={(e) => addPostalCode(department.code)}>
                {department.name} ({department.code})
              </button>
          })}
          <button
            key={"non-idf"}
            className="btn btn-xs btn-default ml-2"
            onClick={(e) => {
              for(let department of non_idf_departments){
                addPostalCode(department.code)
              }
            }}>
                Non-IDF
          </button>
          <br />
          <br />
          <hr/>
          <br />
          ou des codes postaux :
          {otherPostalCodes.map((postalCode) => {
              return <button key={postalCode} className="btn btn-xs btn-default ml-2" onClick={(e) => addPostalCode(postalCode)}>{postalCode}</button>
          })}
      </Modal.Body>
    </Modal>;
}

PostalCodesFilterInputModalInternal.propTypes = {
  modalData: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
};

export const PostalCodesFilterInputModal = connect(
  (state) => {
    return {
      modalData: state.dispatch.modal,
      postalCodes: state.dispatch.filters.postalCodesArray,
      postalCodesSet: state.dispatch.filters.postalCodesSet,
      deliveries: state.dispatch.deliveries,
      departments: state.dispatch.metas.departments,
      idf_departments: state.dispatch.metas.idf_departments,
      non_idf_departments: state.dispatch.metas.non_idf_departments,
    };
  },
  (dispatch) => {
    return {
      hide: () => {
        dispatch({type: DISPATCH_MAIN_MODAL_HIDE});
      },
    };
  }
)(PostalCodesFilterInputModalInternal);
