import React from "react";
import PropTypes from "prop-types";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";

import DriverVehicle from "./DriverVehicle";
import DriverPlanning from "./DriverPlanning";
import DriverName from "../common-comps/DriverName";
import {DriverCenterSelection} from "../../../../common-comps/DriverCenterSelection";


const DriverState = ({
  driver,
  startPresence,
  stopPresence,
  changeVehicle,
  isothermBagReturn,
  showDepositModalDriver,
  showReturnBagsModal,
  stateActionOnly,
  changeCenter,
  centers,
}) => {

  // si pas de course en  cours pour ce livreur :
  // affichage heure de fin, bouton confirmation
  // si course en cours :
  // avec /sans retour,
  // onClick={e => presenceChange(driver, false)}
  const link_to_deposit = `${process.env.REACT_APP_DELEEVADMIN}/deposit/driver_history/${driver.id}`;

  const centerSelection = <DriverCenterSelection driver={driver} centers={centers} changeCenter={changeCenter} />

  const start = () => {

    const popoverClick = (
      <Popover className="driver-actions driver-actions-absent " title="" id={"driver-state-start-" + driver.id}>
        <Popover.Content>
          <div>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'CAR')}>VOITURE</Button><br/>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'S50')}>Sc. 50</Button><br/>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'S125')}>Sc. 125</Button><br/>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'SELEC')}>Sc. ELEC</Button>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'BIKE')}>Vélo</Button>
            <Button variant="success" size="sm" onClick={() => startPresence(driver, 'TRUCK')}>Camion</Button>
            {/*<SwitchPad driver={driver} switchPad={switchPad} />*/}
            {centerSelection}
            <div className="normal-case">
              <h5>Retour matériel</h5>
              <div className="isotherm">
                <Button variant="default" size="sm" onClick={() => showDepositModalDriver(driver)}>consignes</Button>
                <Button variant="default" size="sm" onClick={() => showReturnBagsModal(driver)}>sacs surg.</Button>
                <a target="_blank" rel="noopener noreferrer" href={link_to_deposit}
                   className="btn btn-default btn-xs float-right">
                  <span className="fa-solid fa-up-right-from-square"></span>
                </a>

              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popoverClick} rootClose transition={false}>
        <Button variant="success" size="xs">START</Button>
      </OverlayTrigger>
    );

  };
  //const EST_LA = <Button onClick={e => presenceChange(driver, true)} variant="success" size="xs">EST LA</Button>;
  const stop = () => {

    const popoverClick = (
      <Popover id="popover-trigger-click" className="driver-actions driver-actions-present" title="">
        <Popover.Content>
          <div>
            {driver.state === 'run' ?
              <div className="stop">
                <strong>Une livraison de cmd(s) est en cours.</strong>
                <Button variant="primary" size="xs" onClick={() => stopPresence(driver, 'prepared')}>STOP
                  =&gt; Finalisé</Button>
                <Button variant="primary" size="xs" onClick={() => stopPresence(driver, 'received')}>STOP
                  =&gt; Livré</Button>
              </div> : null}
            {driver.state !== 'run' ?
              <div className="stop">
                <Button variant="primary" size="xs" onClick={() => stopPresence(driver)}>STOP</Button>
              </div> : null}

            <div className="normal-case">
              <h5>Changer de véhicule</h5>
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'CAR')}>VOITURE</Button>
              </div>
              {driver.vehicle === 'SCO' ? <div><Button variant="success" size="xs"
                                                       onClick={() => changeVehicle(driver, 'SCO')}>SCOOTER</Button>
              </div> : null}
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'S50')}>Scooter
                50</Button></div>
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'S125')}>Scooter
                125</Button></div>
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'SELEC')}>Scooter
                Elec</Button></div>
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'BIKE')}>Vélo</Button>
              </div>
              <div><Button variant="success" size="xs" onClick={() => changeVehicle(driver, 'TRUCK')}>Camion</Button>
              </div>
            </div>
            {centerSelection}
            <div className="normal-case">
              <h5>Retour matériel</h5>
              <div>
                <Button variant="default" size="sm" onClick={() => showDepositModalDriver(driver)}>consignes</Button>
                <Button variant="default" size="sm" onClick={() => showReturnBagsModal(driver)}>sacs surg.</Button>
                <a target="_blank" rel="noopener noreferrer" href={link_to_deposit}
                   className="btn btn-default btn-xs float-right">
                  <span className="fa-solid fa-up-right-from-square"></span>
                </a>
              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );


    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popoverClick} rootClose transition={false}>
        <Button variant="primary" size="xs">MENU</Button>
      </OverlayTrigger>
    );

  };


  const HIDDEN = <Button variant="default" size="xs">...</Button>;

  // button "if elif"
  let stateAction = {
    "plan": start(),
    "run_not_present": start(),
    "run": stop(),
    "run_other": stop(),
    "wait": stop(),
    "noplan": stop(),
    "hidden": HIDDEN,
  };

  let className = `DriverState ${driver.state} launchpad-default`;

  let title = driver.mobile;
  if (driver.activity.note) {
    title += ' ' + driver.activity.note;
  }

  if(stateActionOnly){
    return stateAction[driver.state];
  }

  return (
    <div className={className}>
      <b title={title}>
        {driver.is_paper_checked ?
            <span title="Papiers vérifiés" role="img" aria-label="Papiers vérifiés">✅ </span>
            : null
        }
        <DriverName isLgep={driver.lgep_orders_allowed}>
          <span>{driver.name}</span>
        </DriverName>
        <DriverPlanning driver={driver}/>
        &nbsp;<DriverVehicle driver={driver}/>
      </b>, {driver.stateLabel} {driver.etaLabel} {driver.eta}
      &nbsp;{stateAction[driver.state]}
    </div>
  );
};

DriverState.propTypes = {
  driver: PropTypes.object.isRequired,
  startPresence: PropTypes.func.isRequired,
  stopPresence: PropTypes.func.isRequired,
  changeVehicle: PropTypes.func.isRequired,
  isothermBagReturn: PropTypes.func.isRequired,
  showDepositModalDriver: PropTypes.func.isRequired,
  stateActionOnly: PropTypes.bool,
  changeCenter: PropTypes.func.isRequired,
  centers: PropTypes.array.isRequired,
};

export default DriverState;
