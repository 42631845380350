export const DISPATCH_ERROR = 'DISPATCH_ERROR';
export const DISPATCH_SKIP = 'DISPATCH_SKIP';

export const DISPATCH_LOAD = 'DISPATCH_LOAD';
export const DISPATCH_LOAD_ERROR = 'DISPATCH_LOAD_ERROR';
export const DISPATCH_LOADED = 'DISPATCH_LOADED';
export const DISPATCH_IGNORE_DISCONNECT = 'DISPATCH_IGNORE_DISCONNECT';
export const DISPATCH_CHANGE_STATUS = 'DISPATCH_CHANGE_STATUS';
export const DISPATCH_BULK_CHANGE_STATUS = 'DISPATCH_BULK_CHANGE_STATUS';
export const DISPATCH_ADD_EXTRACOST = "DISPATCH_ADD_EXTRACOST";
export const DISPATCH_EXTRA_COST_DEMANDS_REVIEW = "DISPATCH_EXTRA_COST_DEMANDS_REVIEW";

export const DISPATCH_ORDER_CALL_STUART = 'DISPATCH_ORDER_CALL_STUART';
export const DISPATCH_ORDER_STUART_SENDING = 'DISPATCH_ORDER_STUART_SENDING';
export const DISPATCH_DOCK_CALL_STUART = 'DISPATCH_DOCK_CALL_STUART';
export const DISPATCH_DOCK_STUART_SENDING = 'DISPATCH_DOCK_STUART_SENDING';

export const DISPATCH_ORDER_CALL_UBER = 'DISPATCH_ORDER_CALL_UBER';
export const DISPATCH_ORDER_UBER_SENDING = 'DISPATCH_ORDER_UBER_SENDING';
export const DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE = "DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE";
export const DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE = "DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE";
export const DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE = "DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE";
export const DISPATCH_METAS_SAVED = "DISPATCH_METAS_SAVED";
export const DISPATCH_SUB_SCREEN = "DISPATCH_SUB_SCREEN";
export const DISPATCH_NEEDS_REFRESH = "DISPATCH_NEEDS_REFRESH";

export const FIELDS_DELIVERY = ['id', 'dock_target', 'dock_rank_target'];

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const ignoreDisconnect = () => {
  return {
    type: DISPATCH_IGNORE_DISCONNECT
  };
};

export const loadDispatch = (centerId, sub) => {
  // console.log("load dispatch action created")
  return {
    type: DISPATCH_LOAD,
    center_id: centerId,
    sub: sub,
    version: getRandomInt(15),
    timestamp: (new Date()).getTime()
  };
};

export const loadDispatchError = centerId => {
  // console.log("load dispatch action created")
  return {
    type: DISPATCH_LOAD_ERROR,
    center_id: centerId,
    timestamp: (new Date()).getTime()
  };
};

export const dispatchLoaded =  dispatch => {
  const timestamp = (new Date()).getTime()
  return { type: DISPATCH_LOADED, timestamp, dispatch };
};

export const errorThrown = (e) => {
  console.error("errorThrown", e);
  return {
    type: DISPATCH_ERROR,
    e: e,
  };
};

export const _alertOnError = (res) => {
  if (res.error && res.error.message) {
    console.error('error', res.error);
    window.alert(res.error.message);
    delete res.error;
  }
  return res;
};

export const changeStatus = (
  orderId,
  newStatus,
  dockId,
  additionalData,
) => {

  return {
    type: DISPATCH_CHANGE_STATUS,
    newStatus: newStatus,
    orderId: orderId,
    dockId: dockId,
    additionalData: additionalData,
  };
};

export const bulkChangeStatus = (
  ordersIds,
  newStatus,
  dockId,
) => {

  return {
    type: DISPATCH_BULK_CHANGE_STATUS,
    newStatus: newStatus,
    ordersIds: ordersIds,
    dockId: dockId,
  };
};
