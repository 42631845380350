import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";


const InternalUserFraudDetail = ({user}) => {

  let fraud_details = [];
  for (let rule_id in user.fraud_details) {
    if (user.fraud_details[rule_id].description !== '') {
      fraud_details.push(user.fraud_details[rule_id]);
    }
  }

  const popoverClick = (
    <Popover className="menu-actions UserFraudDetail" title="" id={"user-menu-fraud-detail" + user.id}>
      <Popover.Title>Détail du score</Popover.Title>
      <Popover.Content>
        <strong>Détail du scoring compte sans prise en compte des commandes</strong>
        <div className="container">
          {fraud_details.map(t => (<div className="col-sm-12" key={t.rule_id}>{t.description}</div>))}
        </div>
      </Popover.Content>
    </Popover>
  );
  let score_color = user.is_trusted ? 'green' : 'red';

  if (fraud_details.length === 0) {
    return <span><strong style={{color: score_color}}>{user.fraud_score}%</strong></span>;
  }
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      transition={false}
      overlay={popoverClick}
      rootClose 
    >
      <span>
        <strong style={{color: score_color}}>Compte : {user.fraud_score}%</strong>
        <button style={{marginLeft: "2px", marginRight: "2px" }} className="btn btn-xs btn-default"><i className="fa-solid fa-info"></i></button>
      </span>
    </OverlayTrigger>
  );
};

InternalUserFraudDetail.propTypes = {
  user: PropTypes.any.isRequired
};


// link
export const UserFraudDetail = connect()(InternalUserFraudDetail);

export default UserFraudDetail;
