import flowApp from './reducers/index';
import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';
// import {apiMiddleware} from "redux-api-middleware";
// import { bootRefresh } from './actions/autorefresh';
import createSagaMiddleware from 'redux-saga';
import mySaga from './sagas';
import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";
import {bootUser} from "./actions/user";


Raven.config(
  process.env.REACT_APP_RAVEN_DNS,
  {
    dataCallback: (data) => {
      if (data.errors) {
        data.errors = data.errors.slice(0, 150);
      }
      return data;
    }
  }
).install();

const sagaMiddleware = createSagaMiddleware({onError: (e) => {
  Raven.captureException(e);
  console.error(e);
}});

const ravenMiddleware = createRavenMiddleware(
  Raven,
  {
    stateTransformer: (state) => {
      //console.log(state);
      //return {};
      return {
        // "deliveries": state.m.deliveries.map(
        //   t => (t ? {id: t.id, oid: t.linked_order.id, n: t.name, d: t.dock ? t.dock.id : null} : null)),
        // "packings": state.m.packings.map(
        //   t => (t ? {id: t.id, oid: t.linked_order.id, n: t.name, d: t.dock ? t.dock.id : null} : null)),
        "docks": state.m.docks.map(
          d => (d ? {id: d.id, td: d.target_driver, cl: d.closed_at} : null)),
        "drivers": state.m.drivers.map(
          d => (d ? {id: d.id, st:d.state} : null)),
      };
    }
  });

let createStoreWithMiddleware = applyMiddleware(
  // apiMiddleware,
  ravenMiddleware,
  sagaMiddleware,
  thunk,
)(createStore);

let store = createStoreWithMiddleware(
  flowApp,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run(mySaga);


bootUser(store);



export default store;
