import React, { Component } from 'react';
import PropTypes from "prop-types";
import {  DropTarget } from 'react-dnd';


const taskTarget = {
  drop(props, monitor) {
    let item = monitor.getItem();
    // Dock où était la tâche
    let dockFrom = {...item.task.dock};
    if (item.task.dock_target) {
      dockFrom = {...item.task.dock_target};
    }

    // dock sur lequel on dépose la tâche
    let dockTarget = props.dock;
    //console.log('dropped defaultZone ! ', props.dock, props.id, monitor.getItem().taskId, dockFrom, dockTarget);
    props.onDropDefault(props.dock, monitor.getItem().task, dockFrom, dockTarget);
  },

  canDrop(props, monitor) {
    let item = monitor.getItem();
    // console.log('ici ! ', props.dock,  props.id, item);
    if (!props.dock) {
      // console.log('TRUE A');
      return true;
    }

    // console.log(props.dock.dock_type === 'NOCONFIG' , !props.dock.defined_at, props.dock);
    // pas possible de dropper une tâche from: dock defined, dans un dock defined et autre que noconfig

    if (!props.dock.defined_at && props.dock.dock_type === 'NOCONFIG') {
      //console.log('TRUE B');
      return true;
    }
    // cas dnd de livreur à livreur
    if (!!props.dock && !!item.task.dock
        && !props.dock.locked_at && !item.task.dock.locked_at
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(props.dock.dock_type)
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(item.task.dock.dock_type) ) {
      // console.log('TRUE C');
      return true;
    }
    // console.log('AAA');
    return false;
  }

};


function collectTarget(connect, monitor) {

  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class DefaultDropZone extends Component {
  render() {
    const colSpan = this.props.colSpan || 8;
    const {connectDropTarget, isOver, canDrop } = this.props;

    // console.log("isOver, canDrop", isOver, canDrop);
    return connectDropTarget(
      <tr style={{
        backgroundColor:  canDrop && isOver  ? 'LightGreen' : 'transparent',
      }} className="DefaultDropZone">
        <td colSpan={colSpan}>{this.props.children || '\u00A0'}</td>
      </tr>

    );
  }
}


DefaultDropZone.propTypes = {
  dock: PropTypes.object,
  onDropDefault: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  id: PropTypes.any,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  children: PropTypes.any,
  canDrop: PropTypes.any,
};


export default { build: (type) => DropTarget(type, taskTarget, collectTarget)(DefaultDropZone) };
