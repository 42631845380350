import React from 'react';
import {LinkMapsRound} from "../LinkMapsRound";
import {BtnDefine} from "./BtnDefine";
import {Badge} from "react-bootstrap";


// eslint-disable-next-line react/prop-types
export const BasicDockName = ({dock}) => {
  return (<span className="BasicDockName">
    {dock.name}<span> </span>
    {dock.cross_docking_target_center !== null && <Badge variant="info"><i className="fa-solid fa-shuffle"></i></Badge>}
    <LinkMapsRound dock={dock} style={{marginRight:"5px"}} />
    <BtnDefine dock={dock} style={{marginRight:"5px"}}/>
  </span>);
};
