import React from 'react';
import { withFormik } from 'formik';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import moment from  'moment-timezone';
import {submitRedeliverOrder} from "../apps/support/actions";
import {TYPE_DRIVER, TYPE_TROLLEY} from "../utils/transit_areas";

import RedeliverForm from './RedeliverForm';
import { findAddress } from '../apps/dispatch/utils/address';

export class InternalRedeliverModal extends React.Component {
  render() {
    const {
        modal,
        hide,
        addresses,
        trolleys,
        pseudoZones,
        transit_areas,
        transit_area_types,
        trolley_locations,
        validateTransitAreaType,
    } = this.props;
    moment.locale('fr');
    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      dialogClassName="redeliver-order-dialog"
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        {modal.addresses && modal.addresses.loading === false
          ? <RedeliverOrder
              validateTransitAreaType={validateTransitAreaType}
              transit_area_types={transit_area_types}
              transit_areas={transit_areas}
              pseudoZones={pseudoZones}
              trolleys={trolleys}
              trolley_locations={trolley_locations}
              shipping_address_id={-1}
              address={modal.address}
              slots={modal.data}
              order={modal.order}
              addresses={modal.addresses || addresses} />
          : <div>Loading...</div>
        }
      </Modal.Body>
    </Modal>;
  }
}

InternalRedeliverModal.propTypes = {
  "modal": PropTypes.object,
  "hide": PropTypes.func,
  "addresses": PropTypes.object,
  "pseudoZones": PropTypes.array,
  "transit_areas": PropTypes.object,
  "trolleys": PropTypes.array,
  "transit_area_types": PropTypes.object,
};


const RedeliverFormEnhanced = withFormik({
  mapPropsToValues: (props) => {
    const {slots, addresses, order, address} = props;

    return {
      slots,
      address,
      addresses,
      order,
      number_of_bags_isotherm: '',
      number_of_bags_dry: '',
      transit_area_name: '',
    };
  },
  handleSubmit(values, { props, setSubmitting }) {
    // Generate shipping_time and max_shipping_time
    let slot = props.slots[values.slot];
    let shipping_time = moment(slot.value, 'X').format();
    let max_shipping_time = moment(slot.max_delivery_time, 'X').format();
    const shipping_address_id = values.shipping_address_id || findAddress(
      props.address, props.addresses
    ).id;
    const toInteger = (str) => parseInt(str, 10);
    const transit_area_type = toInteger(values.transit_area_type);

    const locationData = {
      transit_area_type
    };

    if (transit_area_type === TYPE_TROLLEY) {
      locationData['trolley_id'] = toInteger(values.trolley_id);
    }

    if(![TYPE_DRIVER, TYPE_TROLLEY].includes(transit_area_type)) {
      locationData['transit_area_name'] = values.transit_area_name;
    }

    let data = {
      'order_id_to_redeliver': values.order.id,
      'shipping_time': shipping_time,
      'max_shipping_time': max_shipping_time,
      'number_of_bags': values.number_of_bags_dry + values.number_of_bags_isotherm,
      'number_of_bags_isotherm': values.number_of_bags_isotherm,
      'shipping_address_id': toInteger(shipping_address_id),
      ...locationData
    };
    props.dispatch(submitRedeliverOrder(data));
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(RedeliverForm);

export const RedeliverOrder = connect()(RedeliverFormEnhanced);
