import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Button } from "react-bootstrap";
import { connect } from 'react-redux';

import { extractCenterId } from "../../../utils/center";
import { MAIN_MODAL_HIDE } from '../../../actions/modal';
import { ORDER_CRATE_ADD, ORDER_CRATE_DELETE } from '../../../actions';
import { LOAD_FULL_STOCK_DOCK } from '../actions';

import CrateManagerTabs from '../../../common-comps/CratesManagerTabs';

class LoadingConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { extraCrates: {} };
  }

  render() {
    const { modal, hide, validate, currentCenterId, allCenters, addCrate, deleteCrate } = this.props;
    const currentCenter = allCenters.find(c => c.id === currentCenterId);
    const isCratesEnabled = currentCenter && currentCenter.crates_enabled;
    if (!modal.dock) {
      return null;
    }

    const { dock } = modal;
    let nbOfCrates;

    if (isCratesEnabled) {
      nbOfCrates = dock.tasks.reduce(
        (nb, task) => nb + task.linked_order.crates.length,
        0
      );
    } else {
      nbOfCrates = dock.tasks.reduce(
        (nb, task) => nb + task.linked_order.number_of_crates,
        0
      );
    }

    const extraCratesByOrder = dock.tasks.map(({ linked_order }) => (
      {
        id: linked_order.id,
        random_id: linked_order.random_id,
        crates: linked_order.crates,
        opz: linked_order.pseudo_zones_status.filter(pz =>
          pz.is_bag_picking_late && pz.nob_iso > 0
        ),
      }
    )).filter(o => o.opz.length > 0);

    if (isCratesEnabled) {
      return (
        <Modal
          show={modal && modal.modalType === 'xd_full_stock_loading'}
          animation={false}
          backdrop={true}
          onHide={() => hide()}
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Validation du chargement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {extraCratesByOrder.length
              ? (
                <React.Fragment>
                  <h4>Les surgelés à récupérer</h4>
                  <Table bordered size="sm" hover striped>
                    <thead>
                      <tr>
                        <th>Commande</th>
                        <th>Sacs et zone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extraCratesByOrder.map(o =>
                        <React.Fragment key={o.id}>
                          <tr key={o.id}>
                            <td>#{o.random_id}</td>
                            <td>{o.opz.map(pz => `${pz.nob_iso} sac(s) - zone ${pz.transit_subarea}`)}</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </Table>
                </React.Fragment>
              )
              : <h4>Pas de surgelés à récupérer</h4>
            }
            <h4>Nombre de cagettes à charger: {nbOfCrates}</h4>
            {dock.tasks.map(({ linked_order: order }) => (
              <React.Fragment key={order.id}>
                <h5>{`Commande #${order.random_id}`}</h5>
                <CrateManagerTabs
                  order={order}
                  deleteCrate={(crateCode, orderId) => deleteCrate(crateCode, orderId, order)}
                  addCrate={({ crateCode, orderId }) => addCrate(crateCode, orderId, order)}
                />
              </React.Fragment>
            ))}
            <Button
              className="w-100 mb-5"
              variant="success"
              size="sm"
              onClick={() => validate(dock)}
            >
              Valider le chargement
            </Button>
          </Modal.Body>
        </Modal>
      );
    } else {
      return (
        <Modal
          show={modal && modal.modalType === 'xd_full_stock_loading'}
          animation={false}
          backdrop={true}
          onHide={() => hide()}
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Validation du chargement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Nombre de cagettes à charger: {nbOfCrates}</h4>
            {extraCratesByOrder.length
              ? (
                <React.Fragment>
                  <h4>Les surgelés à récupérer</h4>
                  <Table bordered size="sm" hover striped>
                    <thead>
                      <tr>
                        <th>Commande</th>
                        <th>Sacs et zone</th>
                        <th>Nombre de cagettes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extraCratesByOrder.map(o =>
                        <React.Fragment key={o.id}>
                          <tr key={o.id}>
                            <td>#{o.random_id}</td>
                            <td>{o.opz.map(pz => `${pz.nob_iso} sac(s) - zone ${pz.transit_subarea}`)}</td>
                            <td>
                              <input
                                type="number"
                                defaultValue="0"
                                min="0"
                                onChange={e => this.setState({
                                  ...this.state,
                                  extraCrates: {
                                    ...this.state.extraCrates,
                                    [o.id]: parseInt(e.target.value, 10)
                                  }
                                })}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </Table>
                </React.Fragment>
              )
              : <h4>Pas de surgelés à récupérer</h4>
            }
            <Button
              className="w-100 mb-5"
              variant="success"
              size="sm"
              onClick={() => validate(dock)}
            >
              Valider le chargement
            </Button>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

LoadingConfirmModal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
  validate: PropTypes.func,
  allCenters: PropTypes.array,
  currentCenterId: PropTypes.number,
  addCrate: PropTypes.func,
  deleteCrate: PropTypes.func,
};

export default connect(
  state => ({
    modal: state.m.modal,
    allCenters: state.m.metas.centers,
    currentCenterId: extractCenterId(state.user),
  }),
  dispatch => ({
    hide: () => dispatch({ type: MAIN_MODAL_HIDE }),
    validate: (dock, extraCrates) => dispatch({
      type: LOAD_FULL_STOCK_DOCK,
      dock,
      extraCrates,
    }),
    deleteCrate: (crateCode, orderId, order) => dispatch({ type: ORDER_CRATE_DELETE, orderId, crateCode, orderPatch: order }),
    addCrate: (crateCode, orderId, order) => dispatch({ type: ORDER_CRATE_ADD, orderId, crateCode, orderPatch: order }),
  })
)(LoadingConfirmModal);
