import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import { api } from '../../api';
import OrderStuartCallContent from './stuart/OrderStuartCallContent';
import DockStuartCallContent from './stuart/DockStuartCallContent';


const StuartCallModal = ({ modal, onHide, callStuart, callDockStuart, notifyError }) => {
  const initialState = {
    loadingEta: true,
    selectedVehicle: 'bike',
    stuartEta: null,
  };

  const [modalState, setModalState] = useState(initialState);

  const { loadingEta, selectedVehicle, stuartEta } = modalState;

  let taskReference, title, onCallStuart;
  if (modal.task) {
    taskReference = modal.task
    title =  `Appeler un Stuart pour la commande #${modal.task.linked_order.random_id}`
    onCallStuart = () => callStuart(task.linked_order, selectedVehicle)
  } else if (modal.dock && modal.dock.tasks.length) {
    taskReference = modal.dock.tasks[0]
    title =  `Appeler un Stuart pour le groupement ${modal.dock.name}`
    onCallStuart = () => callDockStuart(dock, selectedVehicle)
  }

  useEffect(() => {
    if (modal && modal.modalType === 'stuart_call') {
        setModalState({
          ...modalState,
          loadingEta: true,
        })
      const fetchStuartEta = async (orderId, vehicle) => {
        try {
          const res = await api.getStuartEta(orderId, vehicle);
          const { eta: secondsEta, message: errorMessage } = res;
          setModalState({
            ...modalState,
            stuartEta: errorMessage || `${Math.floor(secondsEta/60)}min ${secondsEta % 60}s`,
            loadingEta: false,
          })
        } catch (e) {
          if (e.response && e.response.data) {
            let errorMessage
            if (e.response.data.detail) {
              errorMessage = e.response.data.detail
            } else if (e.response.data.errors) {
              errorMessage = e.response.data.errors
            } else {
              errorMessage = 'Une erreur est survenue' 
            }
            notifyError(errorMessage)
          }
        }
      };
      taskReference && fetchStuartEta(taskReference.linked_order.id, selectedVehicle);
    }
    return () => setModalState(initialState);
  }, [modal.modalType, modalState.selectedVehicle]);

  if (!modal || (!modal.task && !modal.dock)) {
    return null;
  };

  const { modalType, task, dock } = modal;

  return (
    <Modal
      show={modalType === 'stuart_call'}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {task
          && (
            <OrderStuartCallContent
              task={task}
              onChangeVehicle={evt => setModalState({...modalState, selectedVehicle: evt.target.value })}
              loadingEta={loadingEta}
              stuartEta={stuartEta}
            />
          )
        }
        {dock
          && (
            <DockStuartCallContent
              dock={dock}
              onChangeVehicle={evt => setModalState({...modalState, selectedVehicle: evt.target.value })}
              loadingEta={loadingEta}
              stuartEta={stuartEta}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide} >Fermer</Button>
        <Button
          variant="primary"
          size="sm"
          onClick={onCallStuart}
          disabled={!!loadingEta || modal.sending}
        >
          Appeler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

StuartCallModal.propTypes = {
  order: PropTypes.object,
  modal: PropTypes.object,
  onHide: PropTypes.func,
  callStuart: PropTypes.func,
  callDockStuart: PropTypes.func,
};

export default StuartCallModal;
