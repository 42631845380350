import React from "react";
import {DriverExtraCostDemand} from "./DriverExtraCostDemand";
import PropTypes from "prop-types";

export const DriverExtraCostDemandList = ({demands, driversList, reviewersList}) => {
  let driversMapById = new Map()
  for (let someDriver of driversList) {
    driversMapById.set(someDriver.id, someDriver);
  }

  let reviewersMapById = new Map()
  for (let someReviewer of reviewersList) {
    reviewersMapById.set(someReviewer.id, someReviewer);
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>
                Horodatage de demande
              </th>
              <th>
                Chauffeur
              </th>
              <th title={"Groupement"}>
                Group.
              </th>
              <th title={"Commande"}>
                Cmd.
              </th>
              <th>
                Type de demande
              </th>
              <th title={"Nombre de minutes"}>
                Minutes
              </th>
              <th>
                Commentaire
              </th>
              <th>
                Revue par
              </th>
              <th>
                Date de revue
              </th>
              <th>
                Surcoût créé
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              demands.map(
                demand => <DriverExtraCostDemand key={demand.id}
                               demand={demand}
                               driver={driversMapById.get(demand.driver)}
                               reviewer={reviewersMapById.get(demand.reviewed_by)}

                />
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

DriverExtraCostDemandList.propTypes = {
  demands: PropTypes.array.isRequired,
  driversList: PropTypes.array.isRequired,
  reviewersList: PropTypes.array.isRequired,
};
