import React from 'react';
import PropTypes from "prop-types";
import {DateTimeFormated} from "./Common";
import DisplayPackerName from "./DisplayPackerName";
import {Button} from "react-bootstrap";


export const IncomingPlanningsSessionRow = ({planningsSession, startSooner}) => {

  return (
    <tr className="Row IncomingPlanningsSessionRow">
      <td><DisplayPackerName id={'ipsr_name' + planningsSession.id} packer={planningsSession.packer}/></td>
      <td>
        <DateTimeFormated id={'ipsr_datetime' + planningsSession.id} dateTime={planningsSession.started_at} withDuration={true} />
      </td>
      <td style={{textAlign: "center"}}>
        <Button variant="default" size="xs"  onClick={() => startSooner(planningsSession)}
          id={'ipsr_button' + planningsSession.id}
          disabled={planningsSession.updating ? true : false}>
          Faire commencer maintenant</Button>

      </td>
    </tr>
  );
};

IncomingPlanningsSessionRow.propTypes = {
  planningsSession: PropTypes.object.isRequired,
}
