import React from "react";
import PropTypes from "prop-types";
import {rd} from "../../../../../utils";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {SUPPORT_CANCEL_CREDIT} from "../../../actions";


export const InternalCreditRow = ({creditElt, history, cancelCredit,}) => {
  const navigateTo = orderId  => {
    history.push(`/support/order/${orderId}`);
  };

  return <tr key={creditElt.id}>
    {creditElt.order ?
      <td className="col-order-id" onClick={() => navigateTo(creditElt.order.id)}>{creditElt.order.random_id}</td>
      : <td className="col-order-id"> - </td>}

    <td className="col-date">{creditElt.day}</td>
    <td className="col-time">{creditElt.time}</td>
    <td className="col-amount">{rd(creditElt.amount)}€</td>
    <td className="col-usage">{
      creditElt.product_id ?
        <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_PRODUCTS}/products/${creditElt.product_id}`}>
          {creditElt.usage}
        </a> : creditElt.usage}
    </td>
    <td className="col-type-label">{creditElt.type_label}</td>
    <td className="col-doneby">
      {creditElt.done_by ?
        <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_TEAM}/staffs/${creditElt.done_by.id}`}>
          {creditElt.done_by.first_name + ' ' + creditElt.done_by.last_name}
        </a> : '-'}
    </td>
    <td className="col-action">
      {
        creditElt.order
        && !(
          // On ne bloque que le recrédit de l'utilisation du code promo
          // sur une commande.
          creditElt.discount_code
          && creditElt.type_label === "Utilisation lors de la commande"
        )
        ? <button
            type="button"
            className="btn btn-primary btn-xs"
            onClick={() => cancelCredit(creditElt.id, creditElt.order.id, creditElt.user.id)}
          >Annuler</button>
        : null
      }
    </td>
  </tr>;
        
};

InternalCreditRow.propTypes = {
  creditElt: PropTypes.object.isRequired,
  cancelCredit: PropTypes.func.isRequired,
};

export const CreditRow = withRouter(
  connect(
    null,
    (dispatch) => {
      return {
        cancelCredit: (logMoneyId, orderId, userId) => {
          return dispatch(
            {
              type: SUPPORT_CANCEL_CREDIT,
              logMoneyId: logMoneyId,
              orderId: orderId,
              refreshUserId: userId,
            }
          );
        }
      };
    }
  )(InternalCreditRow)
);
