import React, { Component } from 'react';
import PropTypes from "prop-types";

import {Address, UserName, Slot, Sms, DriverLabel} from "../../../../components/Common";
import TaskName from "../../../../components/TaskName";
import SelectStatus from "../../../../components/SelectStatus";
import Late from "./DeliveryRow/Late";
import {NumberOfBags} from "../../../../components/NumberOfBags";
import {TransitArea} from "./DeliveryRow/TransitArea";
import {CancelDeliveryDrop} from "./DeliveryRow/CancelDeliveryDrop";
import {CheckTaskToExtract} from "../Docks/Dock/CheckTaskToExtract";
import {OtherTasksSameAddressBadge} from "./DeliveryRow/OtherTasksSameAddressBadge";

import '../../css/DeliveryRow.css';
import '../../../../css/Row.css';
import {DeliveryActionMenu} from "./DeliveryRow/DeliveryActionMenu";
import {DISPATCH_MAIN_MODAL_SHOW} from "../../actions/modal";
import { WAITING_RESTOCK_FLAG_ID } from "../../utils/order";
import ExternalCallMenu from '../../../../common-comps/ExternalCallMenu';
import NotDeliveredActionButton from '../common-comps/NotDeliveredActionButton';
import DirectDispatchButton from '../common-comps/DirectDispatchButton';
import {NumberOfCanteenOrders} from "../../../../common-comps/NumberOfCanteenOrders";


class DeliveryRow extends Component {
  render() {

    const { show } = this.props;

    if (show.displayMode === 'one_line') {
      return this._oneLine();
    }
    return this._twoLines();
  }

  _oneLine() {
    const {
      task,
      dock,
      show,
      docks_extract,
      handlerDnd,
      connectDragPreview,
      connectDropTarget,
      selected,
      toggleSelect,
    } = this.props;

    let className = this._defineClassName(task, dock) + " type-dock";

    let tdDnd = this._defineTdDn(handlerDnd, task);

    let moving_icon = null;
    if (task.is_moving || task.linked_order.transit_area_target_type) {
      if (
        dock && (
          (task.dock_target && task.dock_target.id === dock.id)
          || (task.linked_order.transit_area_target_type)
        )
      ) {
        moving_icon = <i className="moving-icon fa-solid fa-download" title="Déplacement vers ce groupement"/>;
      } else {
        moving_icon = <i className="moving-icon fa-solid fa-upload" title="Déplacement vers un autre groupement"/>;
      }
    }

    let classesSelectSpan = "fa-regular";
    classesSelectSpan += selected ? " fa-square-check" : " fa-square";
    let classesSelectButton = "btn btn-xs";
    classesSelectButton += selected ? " btn-primary" : " btn-default";

    let dockExtractToggled = false;
    if (dock && docks_extract && Object.keys(docks_extract).includes("" + dock.id)) {
      dockExtractToggled = docks_extract[dock.id];
    }
    const row = (
      <tr className={"DeliveryRow Row " + className} >
        {tdDnd}

        <td title={task.id} className={"taskname"}>
          <TaskName task={task}/>
          {task.emergency_rank && task.linked_order.status === 'paid' ? <i className="fa-solid fa-gauge-high" title={task.emergency_rank}></i> : null}
          {
            task.tasks_with_same_address || task.tasks_with_same_address_and_intersecting_slot
            ? <OtherTasksSameAddressBadge task={task} dock={dock} />
            : null
          }
          {task.status === 'waiting' ?
            <span className="prep_progress" title={`${task.linked_order.prep_progress} produits préparés sur ${task.linked_order.prep_total}`}>
              { task.linked_order.prep_progress }/{ task.linked_order.prep_total }<i title="Cette commande n'est pas encore finalisée" className="no_finalized fa-solid fa-box-open"/>
            </span>
            : null
          }
          {show.menu ? <DeliveryActionMenu task={task} dock={dock} /> : null}
          {moving_icon ?
            <span className="more_icons">
              {moving_icon}
            </span>
            : null
          }
        </td>
        {dockExtractToggled ?
          <td className={"checkExtract"}>
            <CheckTaskToExtract dock={dock} task={task} />
          </td>
          :
          <td className={"bags"}>
            {
              task.linked_order && task.linked_order.canteen_witness
              ? <NumberOfCanteenOrders order={task.linked_order} />
              : <NumberOfBags order={task.linked_order} show_modal_action={DISPATCH_MAIN_MODAL_SHOW} />
            }
          </td>
        }
        <td className={"zonage"}>
          <TransitArea order={task.linked_order} />
        </td>
        {show.name
          ? <td><UserName task={task}/></td>
          : null
        }
        {show.withSelect ?
          <td className={'drag-select'}
            onClick={() => toggleSelect(task.id)}>
            <button className={classesSelectButton}>
              &nbsp;
              <span className={classesSelectSpan}/>
              &nbsp;
            </button>
          </td>
          : null
        }
        <td className="address">
          <Address address={task.address} center_id={task.linked_order.center_id} format={show.addressFormatting}/>
        </td>
        {show.driver  ? <td><DriverLabel task={task} /></td> : null}
        <td className={"slots"}><Slot task={task} compact={true} /></td>
        {show.status ? (<td> <SelectStatus order={task.linked_order} task={task} /></td>) : null}
        {show.sms ? (<td><Sms task={task} /></td>) : null}
        {show.late ? (<td><Late task={task}/></td>) : null}
        {show.directDispatch ? (<td><DirectDispatchButton task={task}/></td>) : null}
        {show.externalCall && !task.linked_order.option_tranquility
          ? (
            <td className='btn-stuart'>
              <ExternalCallMenu task={task}>
                Appel
              </ExternalCallMenu>
            </td>)
          : null
        }
      </tr>
    );

    return (handlerDnd ? connectDragPreview(connectDropTarget(row)) : row);

  }

  _defineClassName(task, dock) {
    let className = this.props.className || '';

    className += ' ' + task.linked_order.status;

    if (task.updating) {
      className += ' updating';
    }

    if (!task.address.latitude || !task.address.longitude) {
      className += ' address-invalid';
    }
    if (dock && dock.return_time && task.delivered_extra_late) {
      className += ' late_delivered';
    }
    if (dock && dock.return_time && task.will_be_extra_late) {
      className += ' late_delivery_expected';
    }
    if (dock && dock.return_time && task.delivered_extra_soon) {
      className += " soon_delivered";
    }
    if (dock && dock.return_time && task.will_be_extra_soon) {
      className += " soon_delivery_expected";
    }

    if (task.status === 'waiting' && task.linked_order.status === "prep") {
      className += ' info';
    } else if (task.linked_order.status === "prepared" && task.dock_target) {
      className += ' warning';
    } else if (task.linked_order.status === "prepared") {
      className += ' success';
    }

    if (task.is_moving && task.dock && dock && task.dock.id === dock.id) {
      className += " is_moving";
    }

    if(this.props.inToDispatch){
      if(task.linked_order.tags.includes("dqp_express_prio")) {
        className += " express_prio_warning";
      }
      if(task.linked_order.tags.includes("dqp_courier")) {
        className += " dqp_courier_warning";
      }
    }
    else{
      if(task.linked_order.tags.includes("dqp_courier")) {
        className += " dqp_courier_warning_soft";
      }
    }
    return className;
  }

  _defineTdDn(handlerDnd, task) {
    let tdDnd = null;
    if (handlerDnd) {
      tdDnd = (handlerDnd(<td className="draggable" title={`ost_id: ${task.id}`}><CancelDeliveryDrop task={task} /></td>));
    }
    if ((task.isLock || task.is_dispatch_disabled) && handlerDnd) {
      tdDnd = (<td className="draggable locked" title={`ost_id: ${task.id}`}><CancelDeliveryDrop task={task} /></td>);
    }
    return tdDnd;
  }

  /*

   */

  _twoLines() {
    const {
      task,
      dock,
      show,
      handlerDnd,
      connectDragPreview,
      connectDropTarget,
      selected,
      toggleSelect,
      addressWidth,
      taskWidth,
      userWidth,
    } = this.props;

    let className = this._defineClassName(task, dock);
    className += " type-dispatch";

    let tdDnd = null;
    if (handlerDnd) {
      tdDnd = (<td className="draggable" title={`ost_id: ${task.id}`} ref={handlerDnd}><CancelDeliveryDrop task={task} /></td>);
    }
    if ((task.isLock || task.is_dispatch_disabled) && handlerDnd) {
      tdDnd = (<td className="draggable locked" title={`ost_id: ${task.id}`}><CancelDeliveryDrop task={task} /></td>);
    }

    let classesSelectSpan = "fa-regular";
    classesSelectSpan += selected ? " fa-square-check" : " fa-square";
    let classesSelectButton = "btn btn-xs";
    classesSelectButton += selected ? " btn-primary" : " btn-default";

    // function connectDragAndDrop(elt) {
    //   return connectDragPreview(connectDropTarget(elt));
    // }

    let row = (<tr className={"DeliveryRow Row to-dispatch " + className}>
      {tdDnd}

      <td title={task.id} style={{width: taskWidth || "130px"}} className={"taskname"}>
        <TaskName task={task}/>
        {
          task.linked_order.transit_area
          ? <TransitArea order={task.linked_order} inline={true}/>
          : ''
        }
        {
          task.tasks_with_same_address || task.tasks_with_same_address_and_intersecting_slot
          ? <OtherTasksSameAddressBadge task={task}/>
          : null
        }
        {task.status === 'waiting' ?
          <span className="float-right">
            <span className="prep_progress" title={`${task.linked_order.prep_progress} produits préparés sur ${task.linked_order.prep_total}`}>
              { task.linked_order.prep_progress }/{ task.linked_order.prep_total }
            </span>
            <i title="Cette commande n'est pas encore finalisée" className="no_finalized fa-solid fa-box-open"></i>
          </span>
          : null
        }
        {show.menu ? <DeliveryActionMenu task={task} dock={dock} /> : null}
      </td>
      {show.withSelect ?
        <td className={'drag-select'}
          onClick={() => toggleSelect(task.id)}>
          <button className={classesSelectButton}>
            &nbsp;
            <span className={classesSelectSpan}/>
            &nbsp;
          </button>
        </td>
        : null
      }
      <td className={"client-name"} style={userWidth ? { width: userWidth } : null}><UserName task={task}/></td>
      {show.last_driver_name && (
      <td style={{width: '9rem'}}>{task.last_driver_name || 'Aucun livreur'}</td>
      )}
      <td style={addressWidth ? {width: addressWidth} : null }>
        <Address address={task.address} center_id={task.linked_order.center_id} format={show.addressFormatting}/>
      </td>

      {show.notDelivered && (
        <td className="btn-stuart" style={{width: '2rem'}}>
          <NotDeliveredActionButton
            task={task}
          >
            {task.flagged_orders.find(of => of.flag_id === WAITING_RESTOCK_FLAG_ID)
              ? 'Restock': 'Action'}
          </NotDeliveredActionButton>
        </td>
      )}
      {/*<td className={"status"}><SelectStatus order={task.linked_order} /></td>*/}
      <td className="slots" style={{ marginRight: '5rem' }}><Slot task={task} compact={true} /></td>
      {show.externalCall && (
        !task.linked_order.option_tranquility
          ? (
            <td className="btn-stuart">
              <ExternalCallMenu task={task}>
                Appel
              </ExternalCallMenu>
            </td>
          ) : <td></td>
      )}
      {show.directDispatch ? (<td style={{ width: '3.5rem'}}><DirectDispatchButton task={task}/></td>) : null}
    </tr>);

    return (handlerDnd ? connectDragPreview(connectDropTarget(row)) : row);
  }
}


DeliveryRow.propTypes = {
  task: PropTypes.object.isRequired,
  dock: PropTypes.object,
  docks_extract: PropTypes.object,
  show: PropTypes.object,
  className: PropTypes.string,
  handlerDnd: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  selected: PropTypes.bool,
  toggleSelect: PropTypes.func,
  taskNameWidth: PropTypes.string,
  addressWidth: PropTypes.string,
  inToDispatch: PropTypes.bool,
  taskWidth: PropTypes.string,
  userWidth: PropTypes.string,
};

const DEFAULT_TOGGLE_SELECT = () => {};

DeliveryRow.defaultProps = {
  show: {
    displayMode: "one_line",
    name: true,
    menu: false,
    driver: false,
    status: true,
    sms: true,
    late: true,
    withSelect: false,
    addressFormatting: null,
    externalCall: false,
  },
  selected: false,
  toggleSelect: DEFAULT_TOGGLE_SELECT,
  inToDispatch: false,
};

export {DeliveryRow, DEFAULT_TOGGLE_SELECT};
