import {connect} from "react-redux";
import {DISPATCH_ADD_EXTRACOST} from "../../actions";
import {DISPATCH_MAIN_MODAL_HIDE} from "../../actions/modal";
import {InternalModalExtraCostAdd} from "../../../../common-comps/InternalModalExtraCostAdd";


export const ModalExtraCostAdd = connect(
  (state) => ({
    modal: state.dispatch.modal,
    driverList: state.dispatch.modal.demand ? [
        state.dispatch.modal.demand.driver_object
    ] : state.dispatch.drivers,
    extraCostTypes: state.dispatch.modal.demand
        ? [
            {
                id: state.dispatch.modal.demand.extra_cost_demand_type,
                label: state.dispatch.modal.demand.extra_cost_demand_type_label,
                rank: 1,
            }
        ]
        : state.dispatch.extracost_types,
    driverId: state.dispatch.modal.demand ? state.dispatch.modal.demand.driver :
        (state.dispatch.modal ? state.dispatch.modal.driverId : null),
    date: state.dispatch.modal.demand ? state.dispatch.modal.demand.created_at.slice(0, 10) :
        (state.dispatch.modal ? state.dispatch.modal.date : null),
    dock: state.dispatch.modal ? state.dispatch.modal.dock : null,
    task: state.dispatch.modal ? state.dispatch.modal.task : null,
    driverLogGroupId: state.dispatch.modal.demand ? state.dispatch.modal.demand.driver_log_group : null,
    driverLogId: state.dispatch.modal.demand ? state.dispatch.modal.demand.driver_log : null,
    orderId: state.dispatch.modal.demand ? state.dispatch.modal.demand.order : null,
    extraCostType: state.dispatch.modal.demand ? state.dispatch.modal.demand.extra_cost_demand_type : null,
    minutes: state.dispatch.modal.demand ? state.dispatch.modal.demand.minutes : null,
    extraCostDemandId: state.dispatch.modal.demand ? state.dispatch.modal.demand.id : null,
    fromDemand: state.dispatch.modal.demand ? true : false,
  }),
  (dispatch) => {
    return {
      hide: () => {
        dispatch({type: DISPATCH_MAIN_MODAL_HIDE});
      },
      submitForm: (extracost) => {
        dispatch({type: DISPATCH_MAIN_MODAL_HIDE});
        dispatch({type: DISPATCH_ADD_EXTRACOST, extracost: extracost});
      }
    };
  }
)(InternalModalExtraCostAdd);
