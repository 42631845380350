import React, { Component } from 'react';
import PropTypes from "prop-types";

import {Address, ShortSlot, UserName} from "./Common";
import SelectStatus from "./SelectStatus";

import '../css/Row.css';
import {TransitArea} from "./TransitArea";
import {slotLabel, slotDepartureHourLabel} from "../utils/task";
import {TagsOrder} from "./TagsOrder";


class OrderRow extends Component {
  render() {

    const { order } = this.props;
    const slotText = slotLabel(order);
    const slotEtdLabel = slotDepartureHourLabel(order);

    let className = this.props.className || '';
    className += ' ' + order.status;

    if (order.updating) {
      className += ' updating';
    }
    if (order.error) {
      className += ' danger';
    }

    return <tr className={"OrderRow Row " + className}  >
      <td title={order.id} className="tags-collection">
        <a href={`${process.env.REACT_APP_DELEEVADMIN}/commandes/print/${order.id}`} target="_blank" rel="noopener noreferrer" >
          {order.random_id}
        </a>
        <TagsOrder order={order} client={order.client}/>
      </td>
      <td>
        {order.number_of_bags}
      </td>
      <td>
        <TransitArea order={order} />
      </td>
      <td>
        <UserName client={order.client}/>
      </td>
      <td>
        <Address address={order.shipping_address} center_id={order.center_id} format="normal" />
      </td>
      <td>
        <ShortSlot slot={slotText} slotEtd={slotEtdLabel} uniqueId={order.id} />
      </td>
      <td> <SelectStatus order={order} /></td>
    </tr>;
  }
}


OrderRow.propTypes = {
  order: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default OrderRow;

