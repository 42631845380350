import React from 'react';
import { useQuery } from 'react-query';
import { api } from "../../../api";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { extractCenterId } from "../../../utils/center";
import StoreStatusData from "./StoreStatusData";


const StoreStatusIndicator = ({ centerId }) => {
  const { isError, data, error, refetch } = useQuery(
    ['uberEatsStoreStatuses', centerId],
    (_key, id) => api.retrieveUberEatsStoreStatus(id),
    {
      enabled: !!centerId,
      refetchInterval: 5 * 60000, // 5 mins
      retry: 5,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    });

  if (!data || !data.store_statuses || data.store_statuses.length === 0) {
    return null;
  }

  return (
    <>
      {data.store_statuses.map(storeStatusData => (
        <StoreStatusData key={storeStatusData.store_id} storeStatusData={storeStatusData} centerId={centerId} refetch={refetch} />
      ))}
      {isError && <div>{error.message}</div>}
    </>
  );
};

StoreStatusIndicator.propTypes = {
  current: PropTypes.string,
  user: PropTypes.object,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  location: PropTypes.object,
};

export const ConnectedStoreStatusIndicator = connect(
  (state) => {
    return {
      user: state.user,
      centers: (
        state.m.metas.centers
        || state.dispatch.metas.centers
        || []
      ),
      centerId: extractCenterId(state.user),
    };
  }
)(StoreStatusIndicator);

export default ConnectedStoreStatusIndicator;