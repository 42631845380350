import React from 'react';
import {connect} from "react-redux";
import {Col, Form, FormGroup, Modal, Tab, Tabs, Row} from "react-bootstrap";
import {Formik, Field, ErrorMessage} from 'formik';
import moment from  'moment-timezone';
import {SUPPORT_MODAL_HIDE, updateOrder} from "../actions";



class InternalSlotModal extends React.Component {


  render() {
    const {modal, hide, validate} = this.props;
    moment.locale('fr');

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'slot'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>Changer le créneau de livraison</h4>
        <Tabs defaultActiveKey={1} id="SlotChangeModal" transition={false}>
          <Tab eventKey={1} title="Créneaux prédéfinis">
            <br />
            <Formik
              initialValues={{orderId : modal.orderId}}
              onSubmit={(values, actions) => {
                let slot = modal.slots[values.slot]
                let shipping_time = moment(slot.value, 'X').tz("Europe/Paris");
                let max_shipping_time = moment(slot.max_delivery_time, 'X').tz("Europe/Paris");
                let slot_description = slot.description
                let is_speed = slot.is_speed;
                let is_dqp_express = slot.key === 'dqp_express';
                validate(
                    values.orderId,
                    shipping_time.format(),
                    max_shipping_time.format(),
                    slot_description,
                    is_speed,
                    is_dqp_express
                );
              }}
              render={ (props: FormikProps<Values>) => (
                <form onSubmit={props.handleSubmit} className="form-horizontal">
                  <FormGroup as={Row} controlId="formHorizontalSlot">
                    <Col sm={8}>
                      <Field component="select" name="slot" required={true} className="form-control">
                        <option value="">Choisir</option>
                        {modal.slots
                          ? modal.slots.map(
                            (s, idx) => <option key={idx} value={idx}>{s.text}</option>)
                          :<option value="">Loading...</option>}
                      </Field>
                    </Col>
                    <Col>
                      <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                        VALIDER
                      </button>
                    </Col>
                  </FormGroup>
                </form>
              )}
            />

          </Tab>
          <Tab eventKey={2} title="Créneaux libres">
            <br />
            <Formik
              initialValues={{
                orderId : modal.orderId,
                max_shipping_time: moment(modal.max_shipping_time).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm"),
                shipping_time: moment(modal.shipping_time).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm")
              }}
              onSubmit={(values, actions) => {
                let shipping = moment(values.shipping_time).tz("Europe/Paris", true);
                let max_shipping = moment(values.max_shipping_time).tz("Europe/Paris", true);
                let slot_description = shipping.format('H:mm').replace(':', 'h') + ' - ' + max_shipping.format('H:mm').replace(':', 'h');
                validate(values.orderId, shipping.format(), max_shipping.format(), slot_description)
              }}
              validate={(values) => {
                let errors = {};
                if (values.shipping_time > values.max_shipping_time) {
                  errors.shipping_time = 'Le debut de creneau doit être avant la fin de creneau';
                }
                return errors;
              }}
              render={ (props: FormikProps<Values>) => (
                <form onSubmit={props.handleSubmit} className="form-horizontal">
                  <FormGroup as={Row} controlId="formSlotFree">
                    <Col sm={6}>
                      <Form.Label>Début:</Form.Label>
                      <Field
                        type="datetime-local"
                        name="shipping_time"
                        required={true}
                        render={({ field, form }) => (
                          <input
                            {...field}
                            onChange={e => {
                              //console.log()
                              const shipping_time = e.target.value;
                              props.handleChange(e);
                              if (shipping_time > form.values.max_shipping_time) {
                                let new_max_shipping_time = moment.tz(shipping_time, "Europe/Paris").add(30, 'm').format('YYYY-MM-DDTHH:mm');
                                form.setFieldValue('max_shipping_time', new_max_shipping_time)
                              }
                            }}
                            className="form-control"
                            type="datetime-local"
                          />
                        )}
                      />
                    </Col>
                    <Col  sm={6}>
                      <Form.Label>Fin:</Form.Label>
                      <Field type="datetime-local" name="max_shipping_time" required={true} className="form-control" />
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="formSlotFreeError">
                    <Col  sm={12}>
                      <ErrorMessage name="shipping_time" />
                      <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                        VALIDER
                      </button>
                    </Col>
                  </FormGroup>

                </form>
              )}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>;
  }
}

export const SlotModal = connect(
  (state) => ({"modal" : state.support.modal}),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (orderId, shipping_time, max_shipping_time, slot_description, is_speed, is_dqp_express) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch(
          updateOrder(orderId, { shipping_time, max_shipping_time, slot_description, is_speed, is_dqp_express })
        );
      }
    };
  }
)(InternalSlotModal);
