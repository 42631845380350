import React from 'react';
import PropTypes from "prop-types";

import "../css/TransitArea.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {copyToClipboard} from "../utils";

import {
  TYPE_TROLLEY,
} from "../utils/transit_areas";


const composeAreaName = (name, extra) => {
  let temp = name;
  if (temp.length > 1) {
    temp += extra;
  }
  return temp;
};

// eslint-disable-next-line react/prop-types
const TransitAreaHistory = ({order, extraLabel}) => {
  if (!order.log_transit_area || order.log_transit_area.length === 0) {
    return null;
  }

  let all_transit_area = new Set([]);
  for (let l of order.log_transit_area) {
    all_transit_area.add(composeAreaName(l.from_transit_area ? l.from_transit_area : "(vide)", extraLabel));
    all_transit_area.add(composeAreaName(l.to_transit_area, extraLabel));
  }
  const label_history = Array.from(all_transit_area).join(' <= ');

  const tooltip = (
    <Tooltip id={`tooltip-transit-area-${order.id}`}>
      {label_history}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <span className="transit-area-history float-right"
        onClick={() => {copyToClipboard(label_history);}}
      >
        <i className="fa-solid fa-clock-rotate-left"></i>
      </span>
    </OverlayTrigger>);

};

export const TransitAreaDisplay = ({order, transit_areas, showTransitAreaModal, readOnly, inline}) => {
  let extraLabel = "";
  let backupExtraLabel = "";
  let extraSpans = [];
  let order_transit_area_current = (
    order.transit_area_current && typeof(transit_areas[order.transit_area_current]) !== "undefined"
      ? transit_areas[order.transit_area_current]
      : null
  );

  if (order && order.pseudo_zones_status && order.pseudo_zones_status.length > 0) {
    for (const opz of order.pseudo_zones_status) {
      if (opz.pseudozone && opz.pseudozone.is_active && opz.pseudozone.bag_apart && opz.count_item_match > 0) {
        let opz_transit_area_current = (
          opz.transit_subarea_current && typeof(transit_areas[opz.transit_subarea_current]) !== "undefined"
            ? transit_areas[opz.transit_subarea_current]
            : null
        );

        // Si les sacs OPZ sont déjà assemblé avec l'order, le zonage est le même
        // => pas besoin d'afficher le détail
        if (
          order_transit_area_current && opz_transit_area_current
          && order_transit_area_current.transit_area_type === opz_transit_area_current.transit_area_type
          && order_transit_area_current.name === opz_transit_area_current.name
        ) {
          continue;
        }

        let subLabel = `${opz.pseudozone.bag_picking_late_label}`;
        let className = "transit_subarea";
        extraLabel += subLabel;
        subLabel = subLabel.toLowerCase();
        if (opz_transit_area_current){
          subLabel += '(' + opz_transit_area_current.name + ')';
          extraLabel += '(' + opz_transit_area_current.name + ')';
          if (opz_transit_area_current.transit_area_type !== TYPE_TROLLEY) {
            className += " not_loaded_on_trolley";
          }
        }

        extraSpans.push(<React.Fragment key={opz.id}> + <span className={ className }>{subLabel}</span></React.Fragment>);
      }
    }
  }

  // dirty fix about the lack of transit area type
  if (order.transit_area.length === 1) {
    backupExtraLabel = extraLabel;
    extraLabel = "";
    extraSpans = [];
  }
  let order_ta_name = (
    <span className="transit_area" style={order.transit_area ? {} : {'fontStyle': 'italic'}} >
      {order.transit_area ?
        order.transit_area
        : '(vide)'}
      {order_transit_area_current && order_transit_area_current.additional_trolley
        ? `+${order_transit_area_current.additional_trolley.name}`
        : null
      }
    </span>
  );
  if (
    order_transit_area_current
    && order_transit_area_current.transit_area_type === TYPE_TROLLEY
    && order_transit_area_current.trolley
  ) {
    let trolley = order_transit_area_current.trolley;
    const tooltip = (
      <Tooltip id={`tooltip-loader-${order_transit_area_current.id}`}>
        <span>Emplacement du chariot : {trolley.current_location}</span>
      </Tooltip>
    );
    order_ta_name = (
      <OverlayTrigger placement="top" overlay={tooltip} transition={false} className="OrderTransitAreaName">
        {order_ta_name}
      </OverlayTrigger>
    );
  }

  return <div className="TransitArea" style={inline ? {display: 'inline'} : {}}>
    {inline ? <span> </span> : null}
    {order ?
      <React.Fragment>
        <span className="transit_area_container" onClick={() => {!readOnly && showTransitAreaModal && showTransitAreaModal(order);}}>
          {order_ta_name}
          {extraSpans}
        </span>
        <TransitAreaHistory extraLabel={backupExtraLabel} order={order} />
      </React.Fragment>
      : <span>&nbsp;</span>}
  </div>;
};

TransitAreaDisplay.propTypes = {
  order: PropTypes.object.isRequired,
  transit_areas: PropTypes.object.isRequired,
  showTransitAreaModal: PropTypes.func,
  inline: PropTypes.bool,
};
