import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import DriverPlanning from "../DriverPlanning";
import DriverVehicle from "../DriverVehicle";
import {SwitchPad} from "../SwitchPad";
import {GoCancel} from "./GoCancel";
import {LinkMapsRound} from "../LinkMapsRound";
import {BtnDefine} from "./BtnDefine";


// eslint-disable-next-line react/prop-types
export const DriverDockName = ({dock}) => {

  const tooltip = (<Tooltip id={"tooltip_dock_" + dock.id}>{dock.driver.mobile}</Tooltip>);
  return (
    <span className="DriverDockName">
      <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
        <Link to={"/logs/driver/" + dock.driver.id + "/date/0"}>{dock.name}</Link>
      </OverlayTrigger>
      <DriverPlanning driver={dock.driver}/>
      <DriverVehicle driver={dock.driver}/>
      {dock.tasks.length === 0 ?
        <SwitchPad driver={dock.driver}/> : null}
      <GoCancel dock={dock}/>
      <BtnDefine dock={dock}/>
      <LinkMapsRound dock={dock}/>
    </span>
  );
};
