export const DISPATCH_LATE_ACTION = 'DISPATCH_LATE_ACTION';
export const DISPATCH_LATE_WARNED = 'DISPATCH_LATE_WARNED';

export const DISPATCH_DELIVERY_UPDATE = 'DISPATCH_DELIVERY_UPDATE';
export const DISPATCH_DELIVERY_UPDATE_SUCCEEDED = 'DISPATCH_DELIVERY_UPDATE_SUCCEEDED';

export const DISPATCH_LATE_ORDER_VALIDATE = 'DISPATCH_LATE_ORDER_VALIDATE';
export const DISPATCH_LATE_ORDER_IGNORE = 'DISPATCH_LATE_ORDER_IGNORE';
export const DISPATCH_LATE_ORDER_REVIEWED = 'DISPATCH_LATE_ORDER_REVIEWED';

export const DISPATCH_RESET_FOR_REDELIVERY = 'DISPATCH_RESET_FOR_REDELIVERY';
export const DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED = 'DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED';
export const DISPATCH_ORDER_TO_RESTOCK = 'DISPATCH_ORDER_TO_RESTOCK';
export const DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED = 'DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED';
export const DISPATCH_VALIDATE_RESTOCK = 'DISPATCH_VALIDATE_RESTOCK';
export const DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED = 'DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED';
export const DISPATCH_ORDER_WAS_DELIVERED = 'DISPATCH_ORDER_WAS_DELIVERED';
export const DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED = 'DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED';
export const DISPATCH_DIRECT_DISPATCH = 'DISPATCH_DIRECT_DISPATCH';
export const DISPATCH_DIRECT_DISPATCH_MANY = 'DISPATCH_DIRECT_DISPATCH_MANY';

export const deliveryUpdate = (tasks, updatingValue) => {
  return {
    type: DISPATCH_DELIVERY_UPDATE,
    taskIds: tasks.map(e => e.id),
    updating: updatingValue
  };
};

export const deliveryUpdateSucceeded = (tasks) => {
  return {
    type: DISPATCH_DELIVERY_UPDATE_SUCCEEDED,
    tasks: tasks,
  };
};

export const lateAction = (task, credit, message) => {
  return {
    type: DISPATCH_LATE_ACTION,
    message: message,
    creditToAdd: credit,
    orderId: task.linked_order.id,
    task: task
  };
};

export const lateWarned = (task) => {
  return { type: DISPATCH_LATE_WARNED, orderId: task.linked_order.id, task: task };
};

export const ignoreLateOrder = orderId => ({
  type: DISPATCH_LATE_ORDER_IGNORE,
  orderId
});

export const validateLateOrder = (orderId, data) => ({
  type: DISPATCH_LATE_ORDER_VALIDATE,
  orderId,
  data
});

export const lateOrderReviewed = (orderId, reviewedAt) => ({
  type: DISPATCH_LATE_ORDER_REVIEWED,
  orderId,
  reviewedAt,
});

export const resetForRedelivery = (orderId, data) => ({
  type: DISPATCH_RESET_FOR_REDELIVERY,
  orderId,
  data,
});

export const orderToRestock = orderId => ({
  type: DISPATCH_ORDER_TO_RESTOCK,
  orderId
});

export const validateRestock = orderId => ({
  type: DISPATCH_VALIDATE_RESTOCK,
  orderId,
});

export const orderWasDelivered = orderId => ({
  type: DISPATCH_ORDER_WAS_DELIVERED,
  orderId
});

export default {
  deliveryUpdateSucceeded,
  DISPATCH_DELIVERY_UPDATE,
  DISPATCH_DELIVERY_UPDATE_SUCCEEDED,
};
