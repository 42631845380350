import {connect} from "react-redux";
import DockList from "./DockList";
import {dockListDeliveriesHandlers, dockListDeliveriesProps} from "../common-comps/dock";
import {getComputedDockListShipping} from "../../selectors/dock";
import {withRouter} from "react-router-dom";

export const DockListShipping = withRouter(connect(
  (state) => dockListDeliveriesProps(state, getComputedDockListShipping(state)),
  (dispatch) => dockListDeliveriesHandlers(dispatch)
)(DockList));

export default DockListShipping;
