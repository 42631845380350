import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from "react-bootstrap";

const OrderProductOptionBadge = ({ option }) => {
  const { quantity, definition } = option;
  // Default values in case definition is not "hot" (should not happen)
  let label = '???';
  let backgroundColor = 'black';
  if (definition.hot) {
    label = 'à réchauffer';
    backgroundColor = 'darkgreen';
  }
  return <Badge style={{ backgroundColor }}>{quantity} x {label}</Badge>;
};

OrderProductOptionBadge.propTypes = {
  option: PropTypes.object,
};

export default OrderProductOptionBadge;
