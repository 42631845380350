
let _exp = {};

/**
 * Factorization function returning functions
 * that sets the "updating" attribute to an id when needed
 * @param stateComponent
 * @param actionComponent
 * @returns {function(*, *): *}
 * The goal is to return functions like:
 *
   _exp.driverUpdating = function (action, state) {
     return {
       ...state,
       drivers: state.drivers.map(
           (e) => e.id === action.driver.id ? {...e, updating: e.id} : e
       )
     };
   };
 * or
   _exp.planningsSessionUpdating = function (action, state) {
     return {
       ...state,
       incoming_plannings_sessions: state.incoming_plannings_sessions.map(
           (e) => e.id === action.plannings_session.id ? {...e, updating: e.id} : e
       )
      };
    };
 */
_exp.stateComponentUpdating = (stateComponent, actionComponent) => function (action, state) {
  return {
    ...state,
    [stateComponent]: state[stateComponent].map(
        (e) => e.id === action[actionComponent].id ? {...e, updating: e.id} : e
    )
  };
};


/**
 * Factorization function returning functions
 * that sets the "updating" attribute to false when needed
 * @param stateComponent
 * @param actionComponent
 * @returns {function(*, *, *): *}
 * The goal is to return functions like:
 *
    _exp.driverUpdateSucceeded = function (action, state) {
      return Object.assign({}, state, {
        drivers: state.drivers.map((e) => {
          if (e.id === action.driver.id) {
            return Object.assign({}, action.driver, {updating: false});
          }
          return e;
        })
      });
    };
 * or
   _exp.planningsSessionUpdated = function (action, state) {
     return Object.assign({}, state, {
       incoming_plannings_sessions: state.incoming_plannings_sessions.map((e) => {
         if (e.id === action.plannings_session.id) {
           return Object.assign({}, action.plannings_session, {updating: false}, {packer: e.packer});
         }
         return e;
       })
     });
   };
 */
_exp.stateComponentUpdated = (stateComponent, actionComponent, toKeep) => function (action, state) {
  return Object.assign({}, state, {
    [stateComponent]: state[stateComponent].map((e) => {
      if (e.id === action[actionComponent].id) {
        let currentObject = Object.assign({}, action[actionComponent], {updating: false});
        for(const attribute of toKeep){
            currentObject[attribute] = e[attribute];
        }
        return currentObject;
      }
      return e;
    })
  });
};


export default _exp;
