import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

function HighTrolleyOccupationRateAlert ({ occupationRate }) {
  return (
    <Alert variant="danger" className="mb-1">
      <strong style={{ marginRight: '1rem' }}>
        Taux d&apos;occupation chariots: {(occupationRate * 100).toFixed(1)} %
      </strong>
    </Alert>
  );
}

HighTrolleyOccupationRateAlert.propTypes = {
  occupationRate: PropTypes.number.isRequired,
};

export default HighTrolleyOccupationRateAlert;

