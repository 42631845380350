import React from "react";
import {connect} from "react-redux";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {rd} from "../../../utils";



// component
class InternalMultipleCreditForm extends React.Component {

  static getTotalAmountFor(orderProducts) {
    let totalAmount = 0;
    for (let orderProduct of orderProducts) {
      if (orderProduct.checked) {
        totalAmount += rd(orderProduct.pricePerUnitQuantity * orderProduct.quantity);
        totalAmount = rd(totalAmount);
      }
    }
    return totalAmount;
  }

  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      subTypeList: props.subTypeList,
      orderProducts: props.orderProducts,
      subtype: props.subtype,
      validate: props.validate,
      totalAmount: InternalMultipleCreditForm.getTotalAmountFor(props.orderProducts),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let notSameOrderProducts = false;
    if (nextProps.orderProducts.length !== prevState.orderProducts.length) {
      notSameOrderProducts = true;
    }
    else {
      for (let i = 0; i < nextProps.orderProducts.length; ++i) {
        if (nextProps.orderProducts[i].order_product_id !== prevState.orderProducts[i].order_product_id) {
          notSameOrderProducts = true;
          break;
        }
      }
    }

    if (
      nextProps.subTypeList !== prevState.subTypeList
      || notSameOrderProducts
    ) {
      return {
        subTypeList: nextProps.subTypeList,
        orderProducts: nextProps.orderProducts,
        validate: nextProps.validate,
      };
    }
    return null;
  }

  /* Manage min and max quantity */
  handleQuantityChange(event) {
    let debug = false;
    let orderProductId = parseInt(event.target.dataset.orderProductId, 10);
    let quantity = event.target.value;
    if (debug) {
      console.log('handleQuantityChange', 'orderProductId', orderProductId);
      console.log('handleQuantityChange', 'quantity', quantity);
    }
    let minQuantity = null;
    let maxQuantity = null;
    for (let orderProduct of this.state.orderProducts) {
      if (debug) {
        console.log('handleQuantityChange', 'orderProduct', orderProduct);
      }
      if (orderProduct.order_product_id === orderProductId) {
        minQuantity = orderProduct.minQuantity;
        maxQuantity = orderProduct.maxQuantity;
      }
    }
    if (debug) {
      console.log('handleQuantityChange', 'minQuantity', minQuantity);
      console.log('handleQuantityChange', 'maxQuantity', maxQuantity);
    }
    if (quantity < minQuantity)
      quantity = minQuantity;
    else if (quantity > maxQuantity)
      quantity = maxQuantity;
    if (debug) {
      console.log('handleQuantityChange', 'quantity', quantity);
    }
    this.setState(function(state, props) {
      for (let orderProduct of state.orderProducts) {
        if (orderProduct.order_product_id === orderProductId) {
          orderProduct.quantity = quantity;
        }
      }
      return {
        orderProducts: state.orderProducts,
        totalAmount: InternalMultipleCreditForm.getTotalAmountFor(state.orderProducts),
      };
    });
  }

  handleSelectionChange(event) {
    let orderProductId = parseInt(event.target.dataset.orderProductId, 10);
    let checked = event.target.checked;
    this.setState(function(state, props) {
      for (let orderProduct of state.orderProducts) {
        if (orderProduct.order_product_id === orderProductId) {
          orderProduct.checked = checked;
        }
      }
      return {
        orderProducts: state.orderProducts,
        totalAmount: InternalMultipleCreditForm.getTotalAmountFor(state.orderProducts),
      };
    });
  }

  handleSubtypeChange(event) {
    let subtype = event.target.value;
    this.setState(function(state, props) {
      for (let orderProduct of state.orderProducts) {
        orderProduct.subtype = subtype;
      }
      return {
        orderProducts: state.orderProducts,
      };
    });
  }

  handleNotesChange(event) {
    let notes = event.target.value;
    this.setState(function(state, props) {
      for (let orderProduct of state.orderProducts) {
        orderProduct.notes = notes;
      }
      return {
        orderProducts: state.orderProducts,
      };
    });
  }

  handleSubmit() {
    this.state.validate(this.state.orderProducts);
  }

  render() {
    let classNames = "MultipleCredit metas";

    return (
      <div className={classNames}>
        <Form onSubmit={this.handleSubmit.bind(this)} className="form-horizontal">
          <table className="table table-condensed">
            <thead>
              <tr>
                <th></th>
                <th>Produit</th>
                <th>{this.state.type === "credit" ? "Quantité à recréditer" : "Quantité à rembourser"}</th>
                <th>Alertes</th>
              </tr>
            </thead>
            <tbody>
            {this.state.orderProducts ?
              this.state.orderProducts.map(
                orderProduct => {
                  return <tr key={orderProduct.order_product_id}>
                    <td>
                      <input type="checkbox" value="1"
                        onChange={this.handleSelectionChange.bind(this)}
                        data-order-product-id={orderProduct.order_product_id}
                      />
                    </td>
                    <td>
                      {orderProduct.selling_name}
                    </td>
                    <td>
                      <input
                          type="number"
                          name="quantity"
                          placeholder={this.state.type === "credit" ? "Quantité à recréditer" : "Quantité à rembourser"}
                          className="form-control"
                          required
                          autoFocus={true}
                          onChange={this.handleQuantityChange.bind(this)}
                          data-order-product-id={orderProduct.order_product_id}
                          defaultValue={orderProduct.quantity}
                          min={orderProduct.minQuantity}
                          max={orderProduct.maxQuantity}
                      />
                    </td>
                    <td>
                      {orderProduct.alertWarning &&
                        <div className="alert alert-warning" role="alert"><b>{orderProduct.alertWarning}</b></div>
                      }
                      {orderProduct.alertInfo &&
                          <div className="alert alert-info" role="alert">{orderProduct.alertInfo}</div>
                      }
                    </td>
                  </tr>;
                }
              )
              : null
            }
            </tbody>
          </table>
          <FormGroup as={Row} controlId="formControlsSelect">
            <Col as={Form.Label} sm={4}>
              Type de raison
            </Col>
            <Col sm={8}>
              <select
                  name="subtype"
                  onChange={this.handleSubtypeChange.bind(this)}
                  className="form-control"
                  required="required"
                  value={this.state.subtype}
              >
                <option value="">choisir</option>
                {this.state.subTypeList.map(e => (<option key={e.subtype} value={e.subtype}>{e.label}</option>))}
              </select>
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="formControlsSelect">
            <Col as={Form.Label} sm={4}>
              Explications
            </Col>
            <Col sm={8}>
            <textarea
                name="notes"
                className="form-control"
                onBlur={this.handleNotesChange.bind(this)}
            />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} className="offset-sm-5">
              <button type="submit" className="btn btn-primary"
                      disabled={this.state.totalAmount === 0}
              >
               {this.state.type === "credit" ? "Créditer" : "Rembourser"}  {this.state.totalAmount} €
              </button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

InternalMultipleCreditForm.propTypes = {
  type: PropTypes.string,
  subTypeList: PropTypes.array.isRequired,
  orderProducts: PropTypes.array.isRequired,
  subtype: PropTypes.string,
  validate: PropTypes.func.isRequired,
};

// link
export const MultipleCreditForm = connect(
  null,
  null,
)(InternalMultipleCreditForm);
