import React from 'react';
import { withFormik, Field } from 'formik';
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {rd} from "../../../utils";

const MyForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  /* Manage min and max quantity */
  const handleQuantityChange = React.useCallback((event) => {
    let qty = event.target.value;
    if (qty < values.minQuantity)
      qty = values.minQuantity;
    else if (qty > values.maxQuantity)
      qty = values.maxQuantity;
      props.setFieldValue("quantity", qty);
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup as={Row} controlId="formHorizontalEmail">
        <Col as={Form.Label} sm={4}>
          {values.labelQuantity ? values.labelQuantity : "Quantité à recréditer"}
        </Col>
        <Col sm={8}>
          <Field
            type="number"
            name="quantity"
            placeholder="Quantité à recréditer"
            className="form-control"
            required
            autoFocus={true}
            onChange={handleQuantityChange}
          />
          {errors.quantity && touched.quantity && <div id="feedback">{errors.quantity}</div>}
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="formControlsSelect">
        <Col as={Form.Label} sm={4}>
          Type de raison
        </Col>
        <Col sm={8}>
          <select
            name="subtype"
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control"
            required="required"
            value={values.subtype}
          >
            <option value="">choisir</option>
            {values.sub_type_list.map(e => (<option key={e.subtype} value={e.subtype} >{e.label}</option>))}
          </select>
          {errors.subtype && touched.subtype && <div id="feedback">{errors.subtype}</div>}
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="formControlsSelect">
        <Col as={Form.Label} sm={4}>
          Explications
        </Col>
        <Col sm={8}>
          <textarea
            name="notes"
            className="form-control"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.notes && touched.notes && <div id="feedback">{errors.notes}</div>}
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={12} className="offset-sm-5">
          <button type="submit" className="btn btn-primary">Créditer {rd(values.pricePerUnitQuantity * values.quantity)} €</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "errors": PropTypes.any,
  "touched": PropTypes.any,
  "handleChange": PropTypes.any,
  "handleBlur": PropTypes.any,
  "handleSubmit": PropTypes.any,
};

export const CreditForm = withFormik({
  handleSubmit: (values, formikBag) => {
    formikBag.props.validate(values);
  },
  displayName: 'BasicForm',
})(MyForm);
