import React from 'react';
import PropTypes from "prop-types";


import { Badge } from "react-bootstrap";


const DriverName = ({ isLgep, children }) => (
  <span>
    {isLgep &&
      <span style={{ marginRight: '5px', padding: '2px' }}>
        <Badge className="lgep"><strong title="La Grande Épicerie de Paris chez Vous">GEP</strong></Badge>
      </span>
    }
    {children}
  </span>
);

DriverName.propTypes = {
  isLgep: PropTypes.bool,
  children: PropTypes.node,
};

export default DriverName;
