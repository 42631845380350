export function currentToggleValue(subState, key, defaultValue=false) {
  if (typeof  subState[key] === "undefined") {
    return defaultValue;
  } else {
    return subState[key];
  }
}


export const DELIVERY_TODO_LIST_KEY = 'DELIVERY_TODO_LIST';
export const DELIVERING_LIST_KEY = 'DELIVERING_LIST';

export const TOGGLE_KEYS = [
  DELIVERING_LIST_KEY,
  DELIVERY_TODO_LIST_KEY,
];
