import React from 'react';

import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {
  assignDock,
  unlockDock
} from "../../actions";


// eslint-disable-next-line react/prop-types
const IGoCancel = ({dock, onAssignDock, onUnlockDock}) => {

  if (onAssignDock && dock.assignable) {
    return (
      <Button
        onClick={() => onAssignDock(dock)}
        size="xs"
        variant={dock.isLock ? "default" : "success"}
        disabled={dock.isLock ? true : false}>GO</Button>
    );
  }
  if (dock.tasks.length > 0 && onUnlockDock && dock.locked_at && !dock.gone_at && dock.target_driver) {
    return (
      <Button
        className="GoCancel"
        onClick={() => onUnlockDock(dock)}
        size="xs"
        variant={dock.isLock ? "default" : "warning"}
        disabled={!!dock.isLock}>ANNULER</Button>
    );
  }
  return (<span></span>);
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return {
    "onAssignDock":         (dock) => dispatch(assignDock(dock)),
    "onUnlockDock":         (dock) => dispatch(unlockDock(dock)),
  };
};

export const GoCancel = connect(mapStateToProps, mapDispatchToProps)(IGoCancel);
