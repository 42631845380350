import React from 'react';
import { Button, Table } from "react-bootstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment/moment";

import {
  loadArrivals,
  validateArrivals,
} from '../actions';
import { MAIN_MODAL_SHOW } from '../../../actions/modal';
import { ARRIVAL_CRATE_MODAL } from '../modals';
import { extractCenterId } from "../../../utils/center";

import ArrivalRow from './ArrivalRow';
import ArrivalsConfirmModal from './ArrivalsConfirmModal';
import ArrivalsChangeNumberOfBagsModal from './ArrivalsChangeNumberfBags';
import CrateModalLinked from '../containers/CrateModalLinked';

class InternalArrivals extends React.Component {
  constructor(props) {
    super(props);
    this.state = { marks: {}, confirmShow: false, dataToConfirm: {} };
    this.handleArrivals = this.handleArrivals.bind(this);
    this.handleArrivalsValidation = this.handleArrivalsValidation.bind(this);
    this.confirmArrivals = this.confirmArrivals.bind(this);
  }

  componentDidMount() {
    const { loadArrivals, centerId } = this.props;
    loadArrivals(centerId);
  }

  componentDidUpdate(prevProps) {
    const { loadArrivals, centerId } = this.props;
    if (prevProps.centerId !== centerId) {
      loadArrivals(centerId);
    }
  }

  handleArrivalsValidation() {
    const { docks, pendingTasks } = this.props;
    const dataToConfirm = { received: [], not_received: [] };
    const ordersToUpdate = Object.entries(this.state.marks)
      .reduce((obj, [id, nextState]) => {
        let tasks;
        if (nextState.dockId) {
          let dock = docks.find(d => d.id === nextState.dockId);
          if (!dock) {
            return dataToConfirm;
          }
          tasks = dock.tasks;
        } else {
          tasks = pendingTasks;
        }
        const updatedTask = tasks.find(t => t.linked_order.id === parseInt(id, 10));

        if (!updatedTask) {
          return dataToConfirm;
        }

        const { linked_order: { random_id, crates } } = updatedTask;
        const { newArea: new_area, received, notReceived: not_received } = nextState;

        if (received) {
          dataToConfirm.received.push({ id: parseInt(id, 10), random_id, new_area, crates, received, not_received });
        }
        if (not_received) {
          dataToConfirm.not_received.push({ id: parseInt(id, 10), random_id, new_area, crates, received, not_received });
        }

        return dataToConfirm;
      }, dataToConfirm);

    this.setState({...this.state, dataToConfirm: ordersToUpdate, confirmShow: true });
  }

  confirmArrivals(ordersToUpdate) {
    const { validateArrivals, centerId } = this.props;
    validateArrivals(ordersToUpdate, centerId);
    this.setState({ marks: {}, confirmShow: false, dataToConfirm: {} });
  }

  handleArrivals(order, rowState, dockId) {
    const { id } = order;
    const nextRowState = {...rowState, dockId };
    this.setState(
      {...this.state, marks: {...this.state.marks, [id]: nextRowState } });
  }

  render() {
    const {
      docks,
      pendingTasks,
      loading,
      processing,
      centers,
      centerId,
      showCrateModal,
    } = this.props;

    const center = centers.find(c => c.id === centerId);
    if (processing) {
      return <p>Réception en cours de traitement...</p>;
    }
    if (loading) {
      return <p>Chargement...</p>;
    }

    if (!center || center.stock_type !== 2) {
      return (
        <div>
          <p>
            Les arrivages concernent uniquement les centres partiels.
          </p>
          <p>
            {center
              ? `Votre centre courant est ${center.name}.`
              : "Vous n'avez aucun centre courant."
            }
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4>Arrivages:</h4>
        {docks.length
          ? docks.map(dock => {

            const departureLabel = `Départ de ${dock.center_name}: ${moment(dock.gone_at).format("HH:mm")}`;
            const deliveryLabel = dock.finished_at
              ? `Livré: ${moment(dock.finished_at).format("HH:mm")}`
              : `Livraison estimée: ${moment(dock.eta).format("HH:mm")}`;

            return (
              <Table className="col-md-7" key={dock.id} bordered size="sm" hover striped>
                <thead>
                  <tr>
                    <th colSpan={8}>{dock.driver_name} / {departureLabel} / {deliveryLabel}</th>
                  </tr>
                  <tr>
                    <th>Nom</th>
                    <th>Sacs</th>
                    <th>Cagettes</th>
                    <th>Nouvelle zone P1</th>
                    <th>Reçu</th>
                    <th>Pas reçu</th>
                    <th>Créneau</th>
                  </tr>
                </thead>
                <tbody>
                  {dock.tasks.map(st =>
                    <ArrivalRow
                      key={st.id}
                      shippingTask={st}
                      updateArrivals={this.handleArrivals}
                      dockId={dock.id}
                      showCrateModal={showCrateModal}
                    />
                  )}
                </tbody>
              </Table>
            );})
          : <p>Aucun arrivage en cours</p>}
        <Table className="col-md-7" bordered size="sm" hover striped>
          <thead>
            <tr>
              <th colSpan={8}>Arrivages à venir</th>
            </tr>
            <tr>
              <th>Nom</th>
              <th>Sacs</th>
              <th>Cagettes</th>
              <th>Nouvelle zone P1</th>
              <th>Reçu</th>
              <th>Pas reçu</th>
              <th>Créneau</th>
            </tr>
          </thead>
          <tbody>
            {pendingTasks.map(st =>
              <ArrivalRow
                key={st.id}
                shippingTask={st}
                updateArrivals={this.handleArrivals}
                showCrateModal={showCrateModal}
                readOnly
              />
            )}
          </tbody>
        </Table>
        <div className="col-md-7">
          <Button
            className="w-100 mb-5"
            variant="success"
            size="sm"
            onClick={this.handleArrivalsValidation}
            disabled={!Object.keys(this.state.marks).length}
          >
            Valider la reception
          </Button>
        </div>
        <ArrivalsConfirmModal
          show={this.state.confirmShow}
          backdrop={true}
          onHide={() => this.setState({...this.state, dataToConfirm: {}, confirmShow: false})}
          arrivalsToConfirm={this.state.dataToConfirm}
          confirmArrivals={this.confirmArrivals}
        />
        <ArrivalsChangeNumberOfBagsModal />
        <CrateModalLinked />
      </div>
    );
  }
}

InternalArrivals.propTypes = {
  loading: PropTypes.bool,
  processing: PropTypes.bool,
  docks: PropTypes.array,
  pendingTasks: PropTypes.array,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  loadArrivals: PropTypes.func,
  validateArrivals: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  showCrateModal: PropTypes.func,
};


export default connect(
  (state) => {
    return {
      loading: state.arrivals.loading,
      processing: state.arrivals.processing,
      docks: state.arrivals.docks || [],
      pendingTasks: state.arrivals.pending || [],
      centerId: extractCenterId(state.user),
      modal: state.arrivals.modal,
      centers: state.m.metas.centers || [],
    };
  },
  (dispatch) => {
    return {
      loadArrivals: centerId => dispatch(loadArrivals(centerId)),
      validateArrivals: (arrivals, centerId) => dispatch(validateArrivals(arrivals, centerId)),
      showCrateModal: (order, taskId, dockId) => dispatch({
        type: MAIN_MODAL_SHOW,
        modalData: { modalType: ARRIVAL_CRATE_MODAL, order, taskId, dockId },
      }),
    };
  }
)(InternalArrivals);
