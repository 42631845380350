import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';
import {withRouter} from "react-router-dom";

//import {} from "../actions";

import "../../css/OrderList.css";
import {rd, sortByKey} from "../../../../utils";
import {humanStatus} from "../../../../utils/status";

const InternalOrderList = withRouter(({orderList, allCenters, history}) => {
  if (!orderList) {
    return null;
  }
  moment.locale('fr');
  let shippingDate = null;
  let dateLabel = null;

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });

  return <div className='OrderList'>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Centre</th>
          <th>Pour le</th>
          <th>Statut</th>
          <th>€ livr.</th>
          <th>TTC</th>
        </tr>
      </thead>
      <tbody>
        {orderList.map((o)=> {
          const humanStatusText = humanStatus(o.status);
          shippingDate = moment(o.shipping_time);
          dateLabel = shippingDate.format('L');
          const navigateTo = (orderId)  => {
            history.push(`/support/order/${orderId}`);
          };
          const className = o.current ? 'current' : '';
          let centerName = 'inconnu';
          if(o.tags.indexOf("subscription") !== -1){
            centerName = "Abonnement";
          }
          else if (o.center > 0) {
            let center = allCenters.find(x => x.id === o.center);
            centerName = center ? center.name : "??";
          }
          let total_delivery_cost = null;
          if (o.order_stat_driver && o.order_stat_driver.length) {
            const delivery_cost = o.order_stat_driver.reduce((previousValue, stat) => {
              return previousValue + stat.total_cost_with_agency_fee;
            }, 0);
            total_delivery_cost = formatter.format(delivery_cost);
            if (o.order_stat_driver.length > 1) {
              const tooltipOrderStat = (
                <Tooltip id={`tooltip-list-order-stat-driver-${o.id}`}>
                  {o.order_stat_driver.map((stat, key) => {
                    const day = moment(stat.day).format("Do MMM YYYY");
                    return <div key={key}>
                      {formatter.format(stat.total_cost_with_agency_fee)} (livré le {day})&nbsp;
                    </div>;
                  })}
                </Tooltip>
              );
              total_delivery_cost = (
                <OverlayTrigger
                  placement="top"
                  overlay={tooltipOrderStat}
                  transition={false}
                >
                  <span>{total_delivery_cost} ({o.order_stat_driver.length})</span>
                </OverlayTrigger>
              );
            }
          }

          return <tr key={o.id} onClick={() => navigateTo(o.id)  } className={className}>
            <td>
              {o.random_id} - {o.id}
              {o.is_expensive ? <span style={{color:'red'}} title={"Au moins un produit cher sur la commande"}> $$</span>: null}
            </td>
            <td>{centerName}</td>
            <td>{dateLabel}</td>
            <td>{humanStatusText}</td>
            <td>
              {total_delivery_cost ?
                <span>{total_delivery_cost}</span>
                : <span>-</span>
              }
            </td>
            <td>{rd(o.amount)}</td>
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalOrderList.propTypes = {
  "orderList": PropTypes.array,
  "allCenters": PropTypes.array
};

export const OrderList = connect(
  (state, ownProp) => {
    let orderList = ownProp.orderList
      .map(o => {
        o.current = state.support.order &&
          (
            o.id === state.support.order.id ||
            (o.cross_docking && o.id === state.support.order.parent_order)
          );
        return o;
      })
      .sort(sortByKey('shipping_time', true));

    return {
      orderList: orderList,
      allCenters: state.support.meta ? state.support.meta.all_centers : []
    };
  },
  null
)(InternalOrderList);
