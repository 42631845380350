import {connect} from "react-redux";
import {LOGS_MODAL_HIDE, LOGS_EXTRACOST_ADD} from "../actions";
import {InternalModalExtraCostAdd} from "../../../common-comps/InternalModalExtraCostAdd";


export const ModalExtraCostAdd = connect(
  (state) => ({
    modal: state.logs.modal,
    driverList: state.logs.driver_list,
    extraCostTypes: state.logs.extracost_types,
    driverId: state.logs.filters.driver_id,
    date: state.logs.filters.date,
    fromDemand: false,
  }),
  (dispatch) => {
    return {
      hide: () => {
        dispatch({type: LOGS_MODAL_HIDE});
      },
      submitForm: (extracost) => {
        dispatch({type: LOGS_MODAL_HIDE});
        dispatch({type: LOGS_EXTRACOST_ADD, extracost: extracost});
      }
    };
  }
)(InternalModalExtraCostAdd);
