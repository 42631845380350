export const ToRadians = function(deg) {
  return deg * (Math.PI/180);
};

export const getDistanceFromLatLonInKm = function(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  lat1 = parseFloat(lat1);
  lon1 = parseFloat(lon1);
  lat2 = parseFloat(lat2);
  lon2 = parseFloat(lon2);

  var dLat = ToRadians(lat2-lat1);
  var dLon = ToRadians(lon2-lon1);
  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +Math.cos(ToRadians(lat1)) * Math.cos(ToRadians(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c;
  return d; // km
};

export const getPointsInRadiusOfOrigin = function(points, origin, radius) {
  var res = [origin];
  for (let i = 0; i < points.length; i++) {
    if (getDistanceFromLatLonInKm(origin[0], origin[1], points[i][0], points[i][1]) <= radius){
      res.push(points[i]);
    }
  }
  return res;
};

export default getDistanceFromLatLonInKm;

/* EXEMPLE : 

const radius = 2;  // km
const desired_size = 1000000;

var origin = [59.3293371, 13.4877472];
var points = [];
for(let i = 0; i<desired_size; i++) {
  points.push(
    [
      59 + Math.random(),
      13 + Math.random()
    ]
  );
}

var t = process.hrtime();  // setup
// BEGIN TEST
var t = process.hrtime(t);  // time set to 0
var res = getPointsInRadiusOfOrigin(points, origin, radius)
var secs = t[0]
var mills = t[1]/1000
// END TEST
console.log(res);
console.log('benchmark took %d seconds and %d milliseconds', secs, mills);
*/
