import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {act_loadDepositProductsDetail} from  "../../actions";

class InternalDepositProductsDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      showAll: false
    };
  }

  componentDidMount() {
    this.props.loadDepositProductsDetail();
  }

  render() {

    const {deposit_products} = this.props;
    let products = [];
    if (deposit_products.datas.count > 0) {
      products = deposit_products.datas.results.filter(() => true);
    }
    
    return <div className="DepositProductsDetail">
      {deposit_products.loading === true && <div className="loading">
        <div className="loading-back"></div>
        <div className="loading-content">Chargement des données...</div>
      </div>}
      {deposit_products.datas.count > 0 &&
        <div>
          <div className="col-sm-12">
            {products.length > 0 &&
              <table className="table no-border table-condensed">
                <thead>
                  <tr>
                    <th>Nom du produit</th>
                    <th>Qté vendues</th>
                    <th>Qté remise</th>
                    <th>Qté consignée</th>
                    <th>Qté Non rendue</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, key) => (
                    <tr key={key}>
                      <td>
                        <img src={product.product.picture_thumbnail_url} alt={product.product.deposit_label} width="50" />
                        { product.product.deposit_label }
                      </td>
                      <td>{ product.quantity_sold }</td>
                      <td>{ Math.abs(product.quantity_received) }</td>
                      <td>{ product.quantity_returned }</td>
                      <td>{ Math.abs(product.quantity_received) - product.quantity_returned }</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>}
      {deposit_products.loading === false && products.length === 0 &&
        <div className="deposit_summary text-center">
          <br />
          <br />
          <strong>{`Aucune consigne n'a encore été vendue`}</strong>
          <br />
          <br />
        </div>
      }
    </div>;
  }
}

InternalDepositProductsDetail.propTypes = {
  deposit_products: PropTypes.object.isRequired,
  loadDepositProductsDetail: PropTypes.func.isRequired,
};

export const DepositProductsDetail = connect(
  (state) => {
    return {
      deposit_products: state.m.deposit.products,
    };
  },
  (dispatch) => {
    return {
      loadDepositProductsDetail: (id) => {
        return dispatch(act_loadDepositProductsDetail(id));
      },
    };
  }
)(InternalDepositProductsDetail);

export default DepositProductsDetail;
