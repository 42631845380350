import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ModalExtraCostAddFromDemand} from "./ModalExtraCostAddFromDemand";
import PropTypes from "prop-types";
import {
    LOGS_EXTRA_COST_DEMANDS_LOAD,
    LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD,
    LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD,
    LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE
} from "../actions"
import {DriverExtraCostDemandList} from "./DriverExtraCostDemandList";
import Pagination from 'react-bootstrap/Pagination'


export const InternalDriverExtraCostDemands =
  ({loaded, filters, demands, driversList, reviewersList, count, load, updateFilter}) => {

  if (!loaded) {
    load(filters);
    return (
      <div className="DriverExtraCostDemands">
        <div className="row">
          <div className="col-md-12"><h4>{' '}</h4></div>
        </div>
      </div>
    );
  }

  let paginationItems = [];
  let falsePage = 0;
  let page = 1;
  let maxFalsePage = 0;
  // let maxPage = 1;
  let maxOffset = 0;
  if (count !== null && count > filters.limit){
      falsePage = filters.offset / filters.limit;
      page = falsePage + 1;
      maxFalsePage = Math.floor((count - 1) / 100);
      // maxPage = maxFalsePage + 1;
      maxOffset = maxFalsePage * 100;

      function updateAndLoad(newOffset) {
          updateFilter("offset", newOffset);
          filters.offset = newOffset;
          load(filters);
      }

      paginationItems.push(
          <Pagination.First key={1} onClick={() => updateAndLoad(0)}  />
      )
      paginationItems.push(
          <Pagination.Prev key={2} onClick={() => updateAndLoad(Math.max(0, filters.offset - filters.limit))}  />
      )
      paginationItems.push(
          <Pagination.Item key={3} active onClick={() => load(filters)}>
              {page}
          </Pagination.Item>
      )
      paginationItems.push(
          <Pagination.Next key={4} onClick={() => updateAndLoad(Math.min(maxOffset, filters.offset + filters.limit))}  />
      )
      paginationItems.push(
          <Pagination.Last key={5} onClick={() => updateAndLoad(maxOffset)}  />
      )
  }

  function updateDateFilter(filterKey, filterValue){
      if(filterValue.length > 10){
          alert("Votre date a l'air un peu trop dans le futur ;).");
          return;
      }
      updateFilter(filterKey, filterValue);
  }

  return (
    <div className="DriverExtraCostDemands">
      <div className="row">
        <div className="col-md-12">
            {/*<DriverExtraCostDemandsTitle filters={filters}/>*/}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix" style={{marginBottom:"10px"}}>
          <div className="col-md-12">
            <div className={"form-inline"}>
              <label>Date de création du :</label>
              <input type={"date"}
                     name={"fromDate"}
                     value={filters.fromDate}
                     onChange={(e) => updateDateFilter("fromDate", e.target.value)}
                     className={"form-control form-control-sm mx-2"}
                  />
              <label>au :</label>
              <input type={"date"}
                     name={"toDate"}
                     value={filters.toDate}
                     onChange={(e) => updateDateFilter("toDate", e.target.value)}
                     className={"form-control form-control-sm mx-2"}
                  />
              <label>Chauffeur :</label>
              <select
                value={filters.driverId}
                onChange={(e) => updateFilter("driverId", e.target.value)}
                className={"form-control form-control-sm mx-2"}
              >
                <option value={""} ></option>
                  {driversList.map((driver) => {
                    return <option value={driver.id} key={driver.id}>{driver.name}</option>
                  })}
              </select>
              <label>Revue :</label>
              <select
                value={filters.isReviewed}
                onChange={(e) => updateFilter("isReviewed", e.target.value)}
                className={"form-control form-control-sm mx-2"}
              >
                <option value={""} ></option>
                <option value={"true"} >Oui</option>
                <option value={"false"} >Non</option>
              </select>
              <label>Revue par :</label>
              <select
                value={filters.reviewedById}
                onChange={(e) => updateFilter("reviewedById", e.target.value)}
                className={"form-control form-control-sm mx-2"}
              >
                <option value={""} ></option>
                  {reviewersList.map((reviewer) => {
                    return <option value={reviewer.id} key={reviewer.id}>
                      {reviewer.first_name} {reviewer.last_name}
                      ({reviewer.email})
                    </option>
                  })}
              </select>
              <label>Date de revue du :</label>
              <input type={"date"}
                     name={"fromReviewedAt"}
                     value={filters.fromReviewedAt}
                     onChange={(e) => updateDateFilter("fromReviewedAt", e.target.value)}
                     className={"form-control form-control-sm mx-2"}
                  />
              <label>au :</label>
              <input type={"date"}
                     name={"toReviewedAt"}
                     value={filters.toReviewedAt}
                     onChange={(e) => updateDateFilter("toReviewedAt", e.target.value)}
                     className={"form-control form-control-sm mx-2"}
                  />
              <label>Validée :</label>
              <select
                value={filters.isValidated}
                onChange={(e) => updateFilter("isValidated", e.target.value)}
                className={"form-control form-control-sm mx-2"}
              >
                <option value={""} ></option>
                <option value={"true"} >Oui</option>
                <option value={"false"} >Non</option>
              </select>
              <button className={"btn btn-default"} onClick={() => load(filters)}>Filtrer</button>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{marginBottom:"10px"}}>
          <div className="col-md-12" style={{textAlign:"center"}}>
              {
                count === null ? null :
                <span>
                    Résultats {filters.offset + 1} {" "}
                    à {Math.min(filters.offset + filters.limit, count)} {" "}
                    sur {count}.
                </span>
              }
          </div>
        </div>
        <div className="row clearfix" style={{marginBottom:"10px"}}>
          <div className="col-md-12">
              <div style={{marginLeft:"auto", marginRight:"auto", width:"fit-content"}}>
              {count !== null && count > filters.limit ?
                <Pagination>
                    {paginationItems}
                </Pagination>
                : null
              }
              </div>
          </div>
        </div>
      </div>
      <DriverExtraCostDemandList
          demands={demands}
          driversList={driversList}
          reviewersList={reviewersList}
      />
      <div className="container-fluid">
        <div className="row clearfix" style={{marginBottom:"10px"}}>
          <div className="col-md-12" style={{textAlign:"center"}}>
              {
                count === null ? null :
                <span>
                    Résultats {filters.offset + 1} {" "}
                    à {Math.min(filters.offset + filters.limit, count)} {" "}
                    sur {count}.
                </span>
              }
          </div>
        </div>
        <div className="row clearfix" style={{marginTop:"10px"}}>
          <div className="col-md-12">
            <div style={{marginLeft:"auto", marginRight:"auto", width:"fit-content"}}>
            {count !== null && count > filters.limit ?
              <Pagination>
                {paginationItems}
              </Pagination>
              : null
            }
            </div>
          </div>
        </div>
      </div>
      <ModalExtraCostAddFromDemand />
    </div>
  );
}

InternalDriverExtraCostDemands.propTypes = {
  loaded: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  demands: PropTypes.array.isRequired,
  driversList: PropTypes.array.isRequired,
  reviewersList: PropTypes.array.isRequired,
  count: PropTypes.number,
  load: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

// link
export const DriverExtraCostDemands = withRouter(connect(
  (state) => {
    return {
      loaded: state.logs.demands.loaded,
      filters: state.logs.demands.filters,
      demands: state.logs.demands.demands,
      driversList: state.logs.demands.driversList,
      reviewersList: state.logs.demands.reviewersList,
      count: state.logs.demands.count,
    }
  },
  (dispatch) => {
    return {
      load: (filters) => {
        dispatch({type:LOGS_EXTRA_COST_DEMANDS_LOAD, filters: filters});
        dispatch({type:LOGS_EXTRA_COST_DEMANDS_DRIVERS_LOAD});
        dispatch({type:LOGS_EXTRA_COST_DEMANDS_REVIEWERS_LOAD});
      },
      updateFilter: (filterName, filterValue) => {
        dispatch(
          {
            type: LOGS_EXTRA_COST_DEMANDS_FILTERS_UPDATE,
            filterName: filterName,
            filterValue: filterValue
          }
        );
      }
    };
  }
)(InternalDriverExtraCostDemands));
