import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Tab, Tabs} from "react-bootstrap";
import { DepositDriverReturn } from './DepositDriverReturn';
import { DepositDriverHistory } from './DepositDriverHistory';
import DepositOrderDriverReturn from './DepositOrderDriverReturn';

import {rd} from "../../../../utils";

const Internal = ({driver, deposit_driver}) => {

  const { debt_datas, datas, loading } = deposit_driver;
  return <div className="DepositDriver">
    {loading === true && <div className="loading">
      <div className="loading-back"></div>
      <div className="loading-content">Chargement des données...</div>
    </div>}
    {datas.count > 0 &&
      <div>
        <div className="col-sm-12">
          <div className="deposit_summary">
            {debt_datas.quantity > 0 || debt_datas.orders_quantity > 0 || debt_datas.amount > 0
              ? (
                <strong>
                  Le livreur possède {debt_datas.quantity ? `${debt_datas.quantity} produit(s) ` : ''}
                  {debt_datas.quantity && debt_datas.orders_quantity ? 'et ' : ''}
                  {debt_datas.orders_quantity ? `${debt_datas.orders_quantity} commande(s) `: ''}
                  pour un montant total de {rd(debt_datas.amount)}€
                </strong>
              )
              : <strong>{`Le livreur n'a plus de produit consigné en sa possession`}</strong>}
          </div>
          <br />
          <Tabs defaultActiveKey='deposit_return' id="NavUser" transition={false}>
            <Tab eventKey='deposit_return' title="Retour Consigne">
              <DepositDriverReturn driver={driver} />
            </Tab>
            <Tab eventKey='deposit_order_return' title="Retour Commande">
              <DepositOrderDriverReturn driver={driver} />
            </Tab>
            <Tab eventKey='deposit_history' title="Historique">
              <DepositDriverHistory driver={driver} />
            </Tab>
          </Tabs>

        </div>
      </div>}
    {loading === false && datas.count === 0 &&
      <div className="deposit_summary">
        <br />
        <br />
        <strong>{`Le livreur n'a pas de produit consigné en sa possession`}</strong>
        <br />
        <br />
      </div>
    }
  </div>;
};
Internal.propTypes = {
  driver: PropTypes.object.isRequired,
  deposit_driver: PropTypes.object.isRequired,
};

export const DepositDriver = connect(
  (state) => {
    return {
      deposit_driver: state.dispatch.deposit.driver,
    };
  }
)(Internal);
