/* eslint-disable react/prop-types */
import React  from 'react';
import {connect} from "react-redux";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {Field, withFormik} from "formik";
import {ASSIGN_UPDATE_NEXT, NEXT_TASK_DONE} from "../actions/nexts";
import {GRANT_UPDATE} from "../actions/index";

import "../css/Nexts.css";
import {buildTypeValue, buildTypeValueInternal} from "../utils/nexts";
import moment from "moment/moment";
//import {PackerMenu} from "./PackerMenu";

const TASK_TYPE_HELP_CENTER = 21;
const TASK_TYPE_FREE_DELIVERY = 54;
const TASK_TYPE_MANAGE_PARKING = 55;
const TASK_TYPE_BOULANGERIE = 56;
const TASK_TYPE_STOCKPILLING_SORT = 57;
const TASK_TYPE_PREPS_TRAINING = 58;
const TASK_TYPE_MANAGE_QUAY = 73;
const TASK_TYPE_CANTEEN_2_0 = 74;

function filterTypesAgainstGrants(nextTask, allTypes) {
  let grants = [];
  let availableTypes = [];
  if (nextTask.grants) {
    grants = nextTask.grants;
  }

  let grantsFilteredMapped = grants.filter(g => !!g.value).map(g => buildTypeValueInternal(g.task_type, g.external_id));

  for (let type of allTypes) {
    let r = grantsFilteredMapped.indexOf(type.key);
    if(type.is_active
      && (type.task_type === TASK_TYPE_HELP_CENTER
         || type.task_type === TASK_TYPE_FREE_DELIVERY
         || type.task_type === TASK_TYPE_MANAGE_PARKING
         || type.task_type === TASK_TYPE_BOULANGERIE
         || type.task_type === TASK_TYPE_STOCKPILLING_SORT
         || type.task_type === TASK_TYPE_PREPS_TRAINING
         || type.task_type === TASK_TYPE_MANAGE_QUAY
         || type.task_type === TASK_TYPE_CANTEEN_2_0
         || r !== -1
         )
    ){
      availableTypes.push(type);
    }
  }

  return availableTypes;
}


const formatDuration =  (seconds) => {
  const s = Math.floor(seconds);

  if (s <= 60) {
    return `${s} sec`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes <= 60) {
    const sRest = Math.floor(seconds % 60);
    return `${minutes}min ${sRest}sec`;
  } else {
    const m = minutes % 60;
    const h = Math.floor(minutes / 60);
    return `${h}h${m}`;
  }
};

const CurrentTaskDuration = ({nextTask, now}) => {
  let duration_label = '';
  const mNow = moment(now);
  if (moment(nextTask.last_started_at).date() !== mNow.date()) {
    return null;
  }

  if (nextTask.last_ended_at) {
    const from_dt = moment(nextTask.last_ended_at);
    const dur = moment.duration(mNow.diff(from_dt));

    if (dur.as('seconds') < 0) {
      console.log("terminé: =========================");
      console.log("terminé: LEA: ", nextTask.last_ended_at);
      console.log("terminé: NOW: ", mNow.format());
      console.log("terminé: dur, nextTask", dur.as('seconds'), nextTask);
    }

    duration_label = `terminée (${formatDuration(dur.as('seconds'))})`;

  } else if (nextTask.last_started_at) {
    const from_dt = moment(nextTask.last_started_at);
    const dur = moment.duration(mNow.diff(from_dt));

    if (dur.as('seconds') < 0) {
      console.log("encours: =========================");
      console.log("encours: LSA: ", nextTask.last_started_at);
      console.log("encours: NOW: ", mNow.format());
      console.log("encours: dur, nextTask", dur.as('seconds'), nextTask);
    }

    duration_label = `en cours (${formatDuration(dur.as('seconds'))})`;
  }

  return <span>{duration_label}</span>;
};

const CurrentNextTaskDone = ({nextTask, types, nextTaskDone}) => {
  const current_type = types.filter(e => e.key === nextTask.current_task_type);
  const can_stop = current_type.length > 0 ? current_type[0].can_stop : false;

  // Si tâche aide au centre ou mise en stock
  if (nextTask.last_ended_at == null && can_stop) {
    return <Button variant="danger" className="float-right" size="xs" disabled={nextTask.updating} onClick={() => nextTaskDone(nextTask.id)}>Arrêter la tâche</Button>;
  }
  return null;
};


const CurrentTaskName = ({nextTask, types, iph_by_packer, cph_by_packer, tad_by_packer, tap_by_packer}) => {
  const temp = types.filter(e => e.key === nextTask.current_task_type);
  const current_task_type = temp.length > 0 ? temp[0].task_type : 0;
  const current_task_label = temp.length > 0 ? temp[0].label : "??";

  let warning_yesterday = '';
  if (moment(nextTask.last_started_at).date() !== moment().date()) {
    warning_yesterday = ' (hier ou avant)';
  }

  let iph_title = null;
  let iph_by_time = null;
  let tad_title = null;
  let tad_by_time = null;
  let tap_title = null;
  let tap_by_time = null;
  if (current_task_type !== 19 && iph_by_packer && typeof(iph_by_packer[nextTask.user.id]) !== 'undefined') {
    let data = iph_by_packer[nextTask.user.id]['datas']["60minago"];
    let tooltips = [];
    for (var i=0; i < data['detail'].length; ++i) {
      let detail = data['detail'][i];
      if (detail['C'] > 0) {
        tooltips.push(detail['zone_name'] + ': ' + detail['C'] + 'iph (' + detail['C_tooltip'] + ')');
      }
    }
    iph_title = <span className="float-right" style={{color:'#9461ca'}} title={tooltips.join("\n")}>{data['C']} iph</span>;

    iph_by_time = Object.values(iph_by_packer[nextTask.user.id]["datas"]).map((iph, key) => {
      if (iph["C"] > 0) {
        return <span key={key}>{iph["label"]}: <em>{iph["C"]}iph</em></span>;
      }
      return null;
    });

    if (tad_by_packer && typeof(tad_by_packer[nextTask.user.id]) !== 'undefined') {
      let tad_datas = tad_by_packer[nextTask.user.id]['datas']['60minago'];
      tad_title = <span className="float-right" style={{color:'#9461ca'}} title={tad_datas['tad_tooltip']}> / {tad_datas['time_avg']} tf</span>;
    }
    if (typeof(tad_by_packer[nextTask.user.id]) !== 'undefined') {
      tad_by_time = Object.values(tad_by_packer[nextTask.user.id]["datas"]).map((tad, key) => {
        if (tad["tad"] > 0) {
          return <span key={key}>{tad["time_name"]}: <em>{tad["time_avg"]}</em></span>;
        }
        return null;
      });
    }

    if (tap_by_packer && typeof(tap_by_packer[nextTask.user.id]) !== 'undefined') {
      let tap_datas = tap_by_packer[nextTask.user.id]['datas']['60minago'];
      tap_title = <span className="float-right" style={{color:'#9461ca'}} title={tap_datas['tap_tooltip']}> / {tap_datas['time_avg']} td</span>;
    }
    if (typeof(tap_by_packer[nextTask.user.id]) !== 'undefined') {
      tap_by_time = Object.values(tap_by_packer[nextTask.user.id]["datas"]).map((tap, key) => {
        if (tap["tap"] > 0) {
          return <span key={key}>{tap["time_name"]}: <em>{tap["time_avg"]}</em></span>;
        }
        return null;
      });
    }
  }

  let cph_title = null;
  let cph_by_time = null;
  if (current_task_type === 19 && cph_by_packer && typeof(cph_by_packer[nextTask.user.id]) !== 'undefined') {
    let data = cph_by_packer[nextTask.user.id]['datas']["60minago_total"];
    cph_title = <span className="float-right" style={{color:'#9461ca'}}>{data['cph']} cph</span>;

    cph_by_time = Object.values(cph_by_packer[nextTask.user.id]["datas"]).map((cph, key) => {
      if (cph["cph"] > 0 && cph["key"].endsWith("_total")) {
        return <span key={key}>{cph["time_name"]}: <em>{cph["cph"]}cph</em></span>;
      }
      return null;
    });

  }

  const locked = nextTask.lock_on_at && nextTask.lock_on_task_type ===  nextTask.last_task_type
    && nextTask.last_external_id === nextTask.lock_on_external_id;
  return <div>
    {current_task_label}{warning_yesterday}
    {locked ? <span className="fa-solid fa-lock" style={{marginLeft:"5px"}}></span> : null}
    {tad_title}
    {tap_title}
    {iph_title}
    {iph_by_time ? <div className="prep_iph_by_time">{iph_by_time}</div> : null}
    {tad_by_time ? <div className="prep_tad_by_time"><b>TF</b> {tad_by_time}</div> : null}
    {tap_by_time ? <div className="prep_tap_by_time"><b>TD</b> {tap_by_time}</div> : null}
    {cph_title}
    {cph_by_time ? <div className="prep_cph_by_time">{cph_by_time}</div> : null}

  </div>;
};

const MainPermissions = ({nextTask, changePerm, pseudoZoneIds}) => {
  if (!nextTask  || !nextTask.grants) {
    return null;
  }

  function handleClick(event, grant, user_id) {
    event.target.classList.add('loading');
    changePerm(grant, !grant.value, user_id);
  }

  const popoverClick = (grants, user) => {
    let title = "Permissions pour " + user.name;
    let sortedGrants = grants.sort((a, b) => {
      let bool_a = (a.task_type > 0 && a.task_type < 1000 ? 1 : 0);
      let bool_b = (b.task_type > 0 && b.task_type < 1000 ? 1 : 0);
      if (bool_a < bool_b) { return 1; }
      if (bool_a > bool_b) { return -1; }
      return 0;
    });

    let previous_task_type = 1;

    return (
      <Popover id="grant-popover-trigger-click" className='grant-popover' title={title}>
        <Popover.Content>
          {sortedGrants.map(g => {
            let classExtra = g.value ? 'btn-success' : "btn-default";
            let newLine = '';
            if (g.main_config || g.type === 'group') {
              return null;
            }
            if (previous_task_type > 0 && previous_task_type < 1000
                && (g.task_type === 0 || g.task_type >= 1000)) {
              newLine = ' new_line';
            }
            previous_task_type = g.task_type;

            return <span
              key={g.identifier}
              className={"perm-button btn btn-xs " + classExtra + newLine}
              onClick={e => handleClick(e, g, nextTask.user.id)}>
              {g.name}
            </span>;
          }) }
          <div style={{clear: 'both'}}></div>
        </Popover.Content>
      </Popover>
    );
  };


  let overlayIdx = 'grant_overlay_' + nextTask.user.id;
  //console.log("test grants", nextTask.grants, pseudoZoneIds);
  // Ordre alphabétique
  const pseudoZoneIdsStr = pseudoZoneIds.map(x => x + '');
  let grants = nextTask.grants
    .filter(g => !g.external_id || pseudoZoneIdsStr.includes(g.external_id)) // test si droit lie à une pz chargé donc de ce centre
    .sort((a, b) => {
      let fa = a.name.toLowerCase();
      let fb = b.name.toLowerCase();
      if (fa < fb) { return -1; }
      if (fa > fb) { return 1; }
      return 0;
    });

  let nbActiveSubGrant = grants.reduce((accumulator, currentGrant) => {
    if (!currentGrant.main_config && currentGrant.type !== 'group' && currentGrant.value) {
      accumulator += 1;
    }
    return accumulator;
  }, 0);


  return <span>
    {grants.map(g => {
      if (!g.main_config) {
        return null;
      }

      let classExtra = g.value ? 'btn-success' : "btn-default";

      return <span
        key={g.identifier}
        className={"perm-button btn btn-xs " + classExtra}
        onClick={e => handleClick(e, g, nextTask.user.id)}>
        {g.name}
      </span>;
    }) }
    <span className="float-right">
      <OverlayTrigger key={overlayIdx}  trigger="click" placement="bottom" overlay={popoverClick(grants, nextTask.user)} rootClose transition={false}>
        <span className="perm-button btn btn-xs btn-default grant_overly_trigger"><i className="fa-solid fa-ellipsis"></i><span className="nb-active-sub-grant">(+{nbActiveSubGrant})</span></span>
      </OverlayTrigger>
    </span>
  </span>;
};


const MyForm = props => {
  const {
    handleSubmit,
    nextTask,
    iph_by_packer,
    cph_by_packer,
    tad_by_packer,
    tap_by_packer,
    types,
    availableTypes,
    changePerm,
    nextTaskDone,
    now,
    pseudoZoneIds
  } = props;



  if (!nextTask.user || !nextTask.user.id) {
    if (console.warn) {
      console.warn("USER NULL on next : ", nextTask);
    }
    return null;
  }

  let classNameComp = "NextTaskForm";

  if (nextTask.user.is_working) {
    classNameComp += " staff-active";
  }

  if (nextTask.user.trying_period && !nextTask.user.stopped) {
    classNameComp += " staff-trying-period";
  }

  if (nextTask.user.stopped) {
    classNameComp += " staff-stopped";
  }


  let second_link =  nextTask.user.id;
  if (nextTask.user.trying_period && !nextTask.user.stopped) {
    second_link = "reste " + nextTask.user.trying_period + 'j';
  }

  let icon = '';
  const testsMiseEnStock = [
    378727, 379928, 379168, 379994, 379931, 376717, 76732, 379274, 379949, 378972, 377060, 62547,
    380991, 381886, 381728, 381901, 381990, 382221, 381603, 385492, 381632, 384945, 382139, 381862, 385493,
    398229, 388582, 398521, 398466, 398416, 398434, 397324, 389337,
    398687, 398770, 398816, 398817, 400184, 398794, 398771
  ];
  if (testsMiseEnStock.includes(nextTask.user.id)) {
    icon = (<span className="fa-solid fa-box-open"></span>);
  }
  return (
    <tr className={classNameComp}>
      <td className="name">
        {icon}  <a href={`${process.env.REACT_APP_DELEEVADMIN}/inventory/user/logs?user_id=${nextTask.user.id}`} rel="noopener noreferrer" target="_blank">{nextTask.user.name || nextTask.user.email}</a>

        &nbsp;(<a href={process.env.REACT_APP_DELEEVADMIN + `/users/${nextTask.user.id}`} target="_blank"  rel="noopener noreferrer">
          {second_link}
        </a>)
        &nbsp;(<a href={process.env.REACT_APP_TEAM + `/staffs/${nextTask.user.id}`} target="_blank"  rel="noopener noreferrer"><i className="fa-solid fa-user-pen"></i></a>)
        {nextTask.user.is_test ?
          <span className="tags-collection float-right">
            <span className="label label-default is-test">test</span>
          </span>
          : null}
        {nextTask.user.legal_status === "freelance" ?
          <span className="tags-collection float-right">
            <span className="label label-red is-interim">interim</span>
          </span>
          : null}
      </td>
      <td>
        <MainPermissions nextTask={nextTask} changePerm={changePerm} pseudoZoneIds={pseudoZoneIds}/>
      </td>
      <td>
        <CurrentTaskName nextTask={nextTask} types={types} iph_by_packer={iph_by_packer} cph_by_packer={cph_by_packer} tad_by_packer={tad_by_packer} tap_by_packer={tap_by_packer} />
      </td>
      <td>
        <CurrentTaskDuration nextTask={nextTask} now={now}/>
        <CurrentNextTaskDone nextTask={nextTask} types={types} nextTaskDone={nextTaskDone} />

      </td>
      <td>
        <form onSubmit={handleSubmit} className="form-inline" style={{textAlign:"right"}}>
          <Field name="next_task_type_value" component="select" placeholder="" disabled={nextTask.updating} className="form-control">
            <option value="" key=""> </option>
            {availableTypes.map(e =>
              <option value={e.key} key={e.key}>
                {e.label}
              </option>
            )}
          </Field>
        </form>
      </td>
    </tr>
  );
};



const InternalNextTaskForm = withFormik({
  enableReinitialize: true,


  mapPropsToValues: (props) => {
    const nextTask = {...props.nextTask, user: {...props.user}};



    return {
      // ui and context
      types: props.types,
      availableTypes: props.availableTypes,
      pseudoZoneIds: props.pseudoZoneIds,
      // form data
      next_task_type_value: buildTypeValue(nextTask),

      iph_by_packer: props.iph_by_packer,
      cph_by_packer: props.cph_by_packer,
      tad_by_packer: props.tad_by_packer,
      tap_by_packer: props.tap_by_packer,
      nextTask: nextTask,
      validate: props.validate,
      changePerm: props.changePerm,
      nextTaskDone: props.nextTaskDone,
    };
  },

  // Custom sync validation
  validate: (values) => {
    // console.log("validate n" , buildTypeValue(values.nextTask));
    // console.log("validate v" , values.next_task_type_value);
    if (buildTypeValue(values.nextTask) !== values.next_task_type_value) {
      values.validate(values.nextTask.id, values.next_task_type_value);
    }
    return {};
  },

  handleSubmit: (values) => {
    console.log("submit", values);
    values.validate(values.nextTask.id, values.next_task_type_value);
  },

  displayName: 'PrepNext',
})(MyForm);


export const NextTaskForm = connect(
  (state, ownProps) => {
    return {
      "pseudoZoneIds": state.m.pseudoZones.map(pz => pz.id),
      "nextTask" : ownProps.nextTask,
      "iph_by_packer": ownProps.iph_by_packer,
      "cph_by_packer": ownProps.cph_by_packer,
      "tad_by_packer": ownProps.tad_by_packer,
      "tap_by_packer": ownProps.tap_by_packer,
      "availableTypes": filterTypesAgainstGrants(ownProps.nextTask, state.m.metas.task_types),
      "types": state.m.metas.task_types,
      "now": state.m.metas.now
    };
  },
  (dispatch) => {
    return {
      "validate": (next_id, next_task_type_value) => {
        dispatch({"type": ASSIGN_UPDATE_NEXT, "id": next_id, "next_task_type_value": next_task_type_value});
      },
      "changePerm": (grant, newValue, user_id) => {
        let newGrant = {...grant, value: newValue};
        dispatch({"type": GRANT_UPDATE, "grant": newGrant, user_id:user_id});
      },
      "nextTaskDone": (next_id) => {
        dispatch({"type": NEXT_TASK_DONE, "next_id": next_id});
      }

    };
  }
)(InternalNextTaskForm);
