import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from "react-bootstrap";

export const DepositDriverHistoryDetail = ({deposit}) => {

  // On s'assure que ce soit une remise
  if (deposit.details.length === 0) {
    return null;
  }
  let info_type = 'retour';
  if (!deposit.parent && deposit.user) {
    info_type = 'remise';
  }
  const popoverClick = (deposit) => {
    return (
      <Popover className="deposit-popover DepositDriver" id="deposit-detail-form">
        <Popover.Title>{info_type === 'retour' ? `Détail du retour : ${deposit.product_infos.deposit_label}` : `Détail de la remise : ${deposit.product_infos.deposit_label}`}</Popover.Title>
        <Popover.Content>
          <table className="table no-border table-condensed">
            <thead>
              <tr>
                <th>Client</th>
                <th>Acheté le</th>
                <th>{info_type === 'retour' ? `Retour` : `Remis`}</th>
                <th>Statut</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {deposit.details.map((detail, key) => (
                <tr key={key}>
                  <td>
                    <a href={process.env.REACT_APP_DELEEVADMIN + `/users/${detail.deposit_user.user}`}
                      target="_blank" rel="noopener noreferrer">
                      {detail.deposit_user.user_infos.full_name}
                    </a>
                  </td>
                  <td>{detail.deposit_user.formated_created_at}</td>
                  <td>{detail.quantity} sur {detail.deposit_user.quantity} achetés</td>
                  <td>
                    {deposit.is_received && deposit.status.details[detail.deposit_user.id].cancel_status === 'partial' && <span className="badge deposit-fix-partial">annulation partiel.</span>}
                    {deposit.is_received && deposit.status.details[detail.deposit_user.id].cancel_status === 'total' && <span className="badge deposit-fix-total">annulé</span>}
                  </td>
                  <td>
                    <a href={`/support/order/${detail.deposit_user.order_id}`}
                      className="btn btn-default btn-xs" title={detail.deposit_user.order_id}
                      target="_blank" rel="noopener noreferrer">
                      Voir la cmde
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Popover.Content>
      </Popover>
    );
  };

  return <div className="DepositDriverHistoryDetail">
    <OverlayTrigger trigger="click" placement="left" overlay={popoverClick(deposit)} rootClose={true} transition={false}>
      <i className="fa-solid fa-circle-info"></i>
    </OverlayTrigger>
  </div>;
};
DepositDriverHistoryDetail.propTypes = {
  deposit: PropTypes.object.isRequired,
};

