import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";

import {} from "../actions";

import "../css/OrderDeliveryDetail.css";
import {showPopinConfirm, updateOrder} from "../actions";


const InternalOrderPackingDetail = ({order, popinConfirm}) => {
  if (!order) {
    return null;
  }

  const changeIsReplaceAllowed = function () {
    popinConfirm(
      'Êtes vous sûr de modifier l\'option de remplacement de produit ?',
      updateOrder(
        order.id,
        {
          'is_replace_allowed': !order.is_replace_allowed
        }
      )
    );
  };

  return <Table size="sm" hover className="OrderPackingDetail">
    <tbody>
      <tr><th colSpan={2}>Info de Préparation</th></tr>
      <tr>
        { !order.is_replace_allowed && <td className="alert alert-warning"><strong>Remplacement de produit</strong> : Le client ne souhaite aucun remplacement de produit</td>}
        { order.is_replace_allowed && <td>Remplacement de produit autorisé</td> }
        <td>
          <label className="super_checkbox">
            <input type="checkbox" className="super_checkbox" checked={order.is_replace_allowed} onChange={ changeIsReplaceAllowed } />
            <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
          </label>
        </td>
      </tr>
      <tr>
        <td>Poids de la commande</td>
        <td>{order.total_weight}</td>
      </tr>
    </tbody>
  </Table>;
};

InternalOrderPackingDetail.propTypes = {
  "order": PropTypes.object,
  "popinConfirm": PropTypes.func,
};

export const OrderPackingDetail = connect(
  null,
  (dispatch) => {
    return {
      "popinConfirm": (msg, dispatchDatas) => dispatch(showPopinConfirm(msg, dispatchDatas))
    };
  }
)(InternalOrderPackingDetail);
