import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";


export const SwitchToggle = connect(
  (state, ownProps) => {
    return {
      currentState: state.toggle[ownProps.Tkey]
    };
  }
)(
  ({Tkey, currentState , dispatch, labelOn , labelOff, className}) => {
    if (!labelOn) {
      labelOn = "replier";
    }
    if (!labelOff) {
      labelOff = "tout afficher";
    }
    console.log(currentState);
    return <button className={"btn btn-xs btn-default " + className} onClick={() => dispatch({type: "SWITCH_TOGGLE", key: Tkey})}>
      {currentState ? labelOn : labelOff}
    </button>;
  }
);
SwitchToggle.propTypes = {
  Tkey: PropTypes.string.isRequired,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  currentState: PropTypes.bool,
  dispatch: PropTypes.func
};
