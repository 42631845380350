import { call, put, takeEvery } from 'redux-saga/effects';
import { daUtil } from "../api";
import {
  MODAL_SHOW__BLOCKING_EXIT_REASON,
  MODAL_SHOW__BLOCKING_EXIT_REASON__AFTER_LOAD,
} from "../actions/commonModals";


function* modalBlockingExitReason(action) {
  let fraud_detail = yield call(daUtil.get, `/processing-orders/${action.data.order.id}/fraud_detail/`);
  yield put({type: MODAL_SHOW__BLOCKING_EXIT_REASON__AFTER_LOAD, fraud_detail: fraud_detail});
}


export const commonModalsSagas = [
  takeEvery(MODAL_SHOW__BLOCKING_EXIT_REASON, modalBlockingExitReason),
];
