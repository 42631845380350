import {
  CourierExtraDelay,
  ExpressExtraDelay,
  ExpressPrioExtraDelay,
  ExtraDelay,
  FrichtiExpressPrioExtraDelay,
  FrichtiExtraDelay,
} from "./Deliveries/ExtraDelay";
import {RainBonus} from "./RainBonus";
import React from "react";

export const DQPHeader = () => {
  return <div className="dqp-header">
    <h4>
      <FrichtiExpressPrioExtraDelay />
      <FrichtiExtraDelay />
      <CourierExtraDelay />
      <ExpressPrioExtraDelay />
      <ExpressExtraDelay />
      <ExtraDelay />
      <br />
      <RainBonus />
    </h4>
  </div>;
};
