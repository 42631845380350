import React from "react";
import {connect} from "react-redux";
import { Button, Badge, OverlayTrigger, Popover } from "react-bootstrap";
import PropTypes from "prop-types";
import {OrderPrepZoneMarkDoneModal} from "./OrderPrepZoneMarkDoneModal";
import {SUPPORT_ORDER_PREPZONE_UPDATE, showPopinOrderPrepZoneMarkDone} from "../../../actions";


const InternalOrderPrepZoneAction = ({
  order,
  user,
  markTodoOrderPseudoZone,
  popinChange,
}) => {

  const popoverClick = (order, opz) => (
    <Popover id="popover-trigger-click" className="opz-popover" title="">
      <Popover.Content>
        {opz.is_done ? 
          <Button
            variant="default"
            size="xs"
            onClick={() => markTodoOrderPseudoZone(order.id, opz.pseudozone.id)}
          >
            Deverouiller, marquer &#34;à faire&#34;
          </Button>
          :
          <Button
            variant="success"
            size="xs"
            onClick={() => popinChange(order, opz)}
            disabled={opz.is_done}
          >
            Déverouiller, marquer &#34;fait&#34;{opz.is_done}
          </Button>
        }
      </Popover.Content>
    </Popover>
  );

  return (
    <span className="OrderPrepZoneAction float-right">
      {order.pseudo_zones_status.map((opz, idx) => {
        let pseudozone = opz.pseudozone;

        if (opz.count_item_match === 0 && !opz.is_locked) {
          return null;
        }
        if (!pseudozone) {
          return null;
        }
        let name = `${pseudozone.name}:${opz.count_item_match}`;
        let style = opz.is_done ? "success" : "default";
        let classInternal = `opz-${style}`;
        if (opz.is_locked) {
          classInternal = "opz-lock";
          style = "info";
        }

        if (!pseudozone.is_active && !opz.is_done && !opz.is_locked) {
          return null;
        }

        if (user && (user.is_logistic || user.groups.is_manager)) {
          return (
            <OverlayTrigger
              key={idx}
              trigger="click"
              placement="bottom"
              overlay={popoverClick(order, opz)}
              rootClose
              transition={false}
            >
              <Badge variant={style} className={classInternal}>
                {name}
                {opz.is_locked ? <span className="fa-solid fa-lock" /> : null}
              </Badge>
            </OverlayTrigger>
          );
        } else {
          return (
            <Badge variant={style} key={idx} className={`opz opz-${style}`}>
              {name}
            </Badge>
          );
        }
      })}
      <OrderPrepZoneMarkDoneModal />
    </span>
  );
};
InternalOrderPrepZoneAction.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object,
  markTodoOrderPseudoZone: PropTypes.func,
  popinChange: PropTypes.func,
};

export const OrderPrepZoneAction = connect(
  (state) => {
    return {
      user: state.user
    };
  },
  (dispatch) => {
    return {
      "markTodoOrderPseudoZone" : (orderId, pseudoZoneId) => {
        let prepZone = {
          "is_done": false,
          "number_of_bags_isotherm": 0,
          "number_of_crates": 0,
          "pseudo_zone_id": pseudoZoneId,
          "transit_subarea": "",
        };
        return dispatch({type: SUPPORT_ORDER_PREPZONE_UPDATE, orderId:orderId, prepZone: prepZone});
      },
      "popinChange": (order, prep_zone, pz) => dispatch(showPopinOrderPrepZoneMarkDone(order, prep_zone, pz)),
    };
  }
)(InternalOrderPrepZoneAction);
