import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { Modal, Row, Button } from "react-bootstrap";

import { ORDER_CRATE_ADD, ORDER_CRATE_DELETE } from '../../../actions';
import { MAIN_MODAL_HIDE } from '../../../actions/modal';
import { ADD_ORDER_TO_XD_DOCK } from '../actions';
import CrateManagerTabs from '../../../common-comps/CratesManagerTabs';


const CheckTaskModal = ({ modal, hide, deleteCrate, addCrate, validate }) => {
  if (!modal.task) {
    return null;
  }

  return (
    <Modal
      show={modal && modal.modalType === 'xd_task_check'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>{`#${modal.task.linked_order.random_id} - Cagettes`}</h4>
        <CrateManagerTabs
          order={modal.task.linked_order}
          deleteCrate={(crateCode, orderId) => deleteCrate(crateCode, orderId, modal.task.linked_order)}
          addCrate={({ crateCode, orderId }) => addCrate(crateCode, orderId, modal.task.linked_order)}
        />
        {modal.task.linked_order.crates.length < 1 &&
          <div style={{color: 'red'}}>Impossible de valider une commande sans cagette.</div>
        }
        <Row>
          <Button
            className="mx-2"
            variant="success"
            size="sm"
            onClick={() => validate(modal.task)}
            disabled={modal.task.linked_order.crates.length < 1}
          >
            Valider le nombre de cagettes
          </Button>
          <Button className="mx-2" variant="secondary" size="sm" onClick={() => hide()}>Annuler</Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
}


CheckTaskModal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
  deleteCrate: PropTypes.func,
  addCrate: PropTypes.func,
  validate: PropTypes.func,
};

export default connect(
  state => ({
    modal : state.m.modal,
  }),
  dispatch => ({
    hide: () => {
      dispatch({ type: MAIN_MODAL_HIDE });
    },
    deleteCrate: (crateCode, orderId, order) => dispatch({ type: ORDER_CRATE_DELETE, orderId, crateCode, orderPatch: order }),
    addCrate: (crateCode, orderId, order) => dispatch({ type: ORDER_CRATE_ADD, orderId, crateCode, orderPatch: order }),
    validate: ({ linked_order: order, id: taskId, client: { user_type: userType }}) => dispatch(
      { type: ADD_ORDER_TO_XD_DOCK, order, taskId, taskIds: [taskId,], userType }
    ),
  })
)(CheckTaskModal);

