import React from 'react';
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const ArrivalsConfirmModal = ({ arrivalsToConfirm, confirmArrivals, ...props}) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Validation de la réception</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {arrivalsToConfirm.received && arrivalsToConfirm.received.length ? (
          <div>
            <h4>Commandes reçues</h4>
            <ul>
              {arrivalsToConfirm.received.map(o =>
                <li key={o.id}>
                  <span className="font-weight-bold">{o.random_id}</span>: {o.new_area || 'Pas de nouvelle zone'}
                  {o.crates.length
                    ? (
                      <ul>
                        {o.crates.map(c => (<li key={c.id}>{c.code}</li>))}
                      </ul>
                    ) : void 0
                  }
                </li>)
              }
            </ul>
          </div>
        ) : <p>Pas de commande reçue</p>}
        {arrivalsToConfirm.not_received && arrivalsToConfirm.not_received.length ? (
          <div>
            <h4>Commandes non reçues</h4>
            <ul>
              {arrivalsToConfirm.not_received.map(o =>
                <li key={o.id}>{o.random_id}</li>)
              }
            </ul>
          </div>
        ) : <p>Pas de commande non reçue</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Annuler
        </Button>
        <Button variant="success" onClick={() => confirmArrivals(arrivalsToConfirm)}>
          Valider la réception
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ArrivalsConfirmModal.propTypes = {
  arrivalsToConfirm: PropTypes.object.isRequired,
  confirmArrivals: PropTypes.func.isRequired,
  onHide: PropTypes.func,
};


export default ArrivalsConfirmModal;
