import {connect} from "react-redux";
import {MAIN_MODAL_HIDE} from "../../actions/modal";
import {DRIVER_STATEMENT_TYPE_ISOBAG} from "../../utils/driver";
import {CREATE_STATEMENT} from "../../actions";
import { DriverIsoBagModalContent } from "./DriverIsoBagModalContent";

export const DriverIsoBagModal = connect(
  (state) => ({
    "modal" : state.m.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MAIN_MODAL_HIDE});
      },
      "validate": (values) => {
        const amount = values.operation === "debit" ? -values.amount : values.amount;

        dispatch({'type': MAIN_MODAL_HIDE});
        dispatch({
          type: CREATE_STATEMENT,
          driver: {id: values.driver_id},
          statement_type: DRIVER_STATEMENT_TYPE_ISOBAG,
          amount: amount
        });
      }
    };
  }
)(DriverIsoBagModalContent);
