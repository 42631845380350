import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import moment from 'moment';

const UberContent = ({ externalJob }) => (
  <Table striped hover size="sm">
    <thead>
      <tr>
        <th colSpan={2}>Général</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Numéro de la course</td>
        <td>{externalJob.id}</td>
      </tr>
      <tr>
        <td>UUID</td>
        <td>{externalJob.uuid}</td>
      </tr>
      <tr>
        <td>Statut</td>
        <td>{externalJob.status}</td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <th colSpan={2}>Retrait au centre</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Retrait prévu</td>
        <td>{moment(externalJob.pickup_eta).format('DD/MM/YYYY à HH:mm')}</td>
      </tr>
      {/* <tr>
        <td>Statut</td>
        <td>{externalJob.dropoff.status} ({moment(externalJob.dropoff.status_timestamp).format('DD/MM/YYYY à HH:mm')})</td>
      </tr> */}
    </tbody>
    <thead>
      <tr>
        <th colSpan={2}>Livraison au client</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Nom</td>
        <td>{externalJob.dropoff.name}</td>
      </tr>
      <tr>
        <td>Adresse</td>
        <td>{externalJob.dropoff.address}</td>
      </tr>
      {externalJob.dropoff.notes && (<tr>
        <td>Notes</td>
        <td>{externalJob.dropoff.notes}</td>
      </tr>)}
      <tr>
        <td>Code PIN de vérification</td>
        <td>{
          externalJob.dropoff.verification_requirements
          && externalJob.dropoff.verification_requirements.pincode
          && externalJob.dropoff.verification_requirements.pincode.value
        }</td>
      </tr>
      <tr>
        <td>Livraison prévue</td>
        <td>{moment(externalJob.dropoff_eta).format('DD/MM/YYYY à HH:mm')}</td>
      </tr>
      {/* <tr>
        <td>Statut</td>
        <td>{externalJob.dropoff.status} ({moment(externalJob.dropoff.status_timestamp).format('DD/MM/YYYY à HH:mm')})</td>
      </tr> */}
    </tbody>
    <thead>
      <tr>
        <th colSpan={2}>Coursier</th>
      </tr>
    </thead>
    {externalJob.courier && (
      <tbody>
        <tr>
          <td>Nom</td>
          <td>{externalJob.courier.name}</td>
        </tr>
        <tr>
          <td>Téléphone</td>
          <td>{externalJob.courier.phone_number}</td>
        </tr>
        <tr>
          <td>Véhicule</td>
          <td>{externalJob.courier.vehicle_type}</td>
        </tr>
      </tbody>
    )}
    <thead>
      <tr>
        <th colSpan={2}>Prix</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Prix TTC</td>
        <td>{externalJob.fee / 100} €</td>
      </tr>
      {externalJob.undeliverable_action && (
        <tr>
          <td>Action en cas d&apos;impossibilité de livrer</td>
          <td>{externalJob.fee / 100} €</td>
        </tr>
      )}
      {externalJob.undeliverable_reason && (
        <tr>
          <td>Raison de l&apos;impossibilité de livrer</td>
          <td>{externalJob.fee / 100} €</td>
        </tr>
      )}
    </tbody>
  </Table>
);

UberContent.propTypes = {
  externalJob: PropTypes.object,
};

export default UberContent;

