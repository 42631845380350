import React from 'react';
import {FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';

import { useNumberOfBags } from '../../hooks/useNumberOfBags';


export const ChangeNumberOfBagsModalContent = ({ modal, hide, validate }) => {
  if (!modal.order && !modal.modalType !== 'order_number_of_bags') {
    return null;
  }

  const { order, dockId, taskId } = modal;
  const {
    orderBagsDetail: [numberOfBagsDry, numberOfBagsIso, numberOfBagsFrozen],
    extraPlaces
  } = useNumberOfBags(order);

  const extraPlacesInitialValues = extraPlaces.reduce((acc, ep) =>
    ({...acc, [ep.field_name]: ep.number_of_bags_iso})
    , {}
  );

  const initialValues = {
    ...extraPlacesInitialValues,
    number_of_bags_iso: numberOfBagsIso,
    number_of_bags_dry: numberOfBagsDry,
    number_of_bags_frozen: numberOfBagsFrozen,
  }

  return (
    <Modal
      show={modal && modal.modalType === 'order_number_of_bags'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>
            Changer le nombre de sac sur
            &nbsp;<a href={`/support/order/${modal.order.id}`} target="_blank" rel="noopener noreferrer" >#{modal.order.random_id}</a>&nbsp;
          </h4>
        {modal.order ? <Formik
          initialValues={initialValues}
          onSubmit={values => {
            validate({ ...values, orderId: order.id, dockId, taskId });
          }}
          render={(props: FormikProps<Values>) => {
            return <form onSubmit={props.handleSubmit}>
              <FormGroup key="NOB">
                  <label>Nombre de sacs sec</label>
                  <Field
                    autoFocus="autofocus"
                    type="number"
                    min="0"
                    max="999"
                    step="1"
                    name="number_of_bags_dry"
                    className="form-control"
                    required="required"
                  />
              </FormGroup>
              <FormGroup key="NOBISO">
                <label>Nombre de sac frais</label>
                <Field
                  type="number"
                  min="0"
                  max="999"
                  step="1"
                  name="number_of_bags_iso"
                  className="form-control"
                  required="required"
                />
              </FormGroup>
              {numberOfBagsFrozen > 0 &&
                <FormGroup key="NOBFROZEN">
                  <label>Nombre de sac surgelé</label>
                  <Field
                    type="number"
                    min="0"
                    max="999"
                    step="1"
                    name="number_of_bags_frozen"
                    className="form-control"
                    required="required"
                  />
                </FormGroup>
              }
              {extraPlaces.length > 0 ? <h5>Zone sans regroupement Prep</h5> : null}
              {extraPlaces.map(ep => <FormGroup key={ep.id}>
                <label>{ep.pseudoZone.name}</label>
                <Field
                  type="number"
                  name={ep.field_name}
                  className="form-control"
                  required="required"
                />
              </FormGroup>)}
              <FormGroup style={{textAlign:"center", marginBottom:"0px"}}>
                  <button type="submit" className="btn btn-primary">Modifier le nombre de sacs</button>
              </FormGroup>
              <div className="clearfix" style={{fontSize:"1px", height:"1px"}}></div>
            </form>;
          }}
        /> : null}
      </Modal.Body>
    </Modal>
  );
};

export default ChangeNumberOfBagsModalContent;
