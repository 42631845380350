import React from "react";
import PropTypes from 'prop-types';
import {Link, Route, withRouter} from "react-router-dom";

import "../css/AdminCompactHeader.css";
import jsonMenu from '../data/menu.json';
import {connect} from "react-redux";
import {Button, Dropdown, OverlayTrigger, Tooltip, ButtonGroup} from "react-bootstrap";
import {userChangeCenter} from "../actions/user";
import {extractCenterId} from "../utils/center";
import {copyToClipboard} from "../utils";
import {filterDockDeparture, filterPostalCodes} from "../apps/dispatch/actions/filters";
import {DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED, generateDispatchAuto} from "../apps/dispatch/actions/dock";
import {DISPATCH_MAIN_MODAL_SHOW, DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT} from "../apps/dispatch/actions/modal";
import {
  // SUB_DISPATCH_CODE_LIGHT_LOADING_1,
  SUB_DISPATCH_CODES,
  SUB_DISPATCH_CONFIGURATIONS,
} from "../apps/dispatch/sub_dispatchs";
import {DISPATCH_NEEDS_REFRESH, DISPATCH_SUB_SCREEN} from "../apps/dispatch/actions";

function buildUrl(url) {
  let adminBaseUrl = process.env.REACT_APP_DELEEVADMIN;

  if (url.startsWith('/')) {
    return adminBaseUrl + url;
  }
  return url;
}

function handleChange(event) {
  window.location = buildUrl(event.target.value);
}

const DispatchAuto = ({user, isProcessing, generate, centerId}) => {
  return '';
  // return (
  //   user.groups && user.groups.is_super_admin
  //   ? (
  //     isProcessing
  //     ? <Button size="xs" disabled={true}>dispatch en cours</Button>
  //     : <Button size="xs" onClick={() => generate(centerId)}>Générer un dispatch</Button>
  //   )
  //   : ''
  // );
};

const DispatchAutoConnected = connect(
  state => {
    return {
      isProcessing: state.dispatch.dispatch_auto_processing,
      centerId: extractCenterId(state.user),
      user: state.user,
    };
  },
  dispatch => {
    return {
      generate: (centerId) => dispatch(generateDispatchAuto(centerId)),
    };
  },
)(DispatchAuto);

// eslint-disable-next-line react/prop-types
const User = ({user}) => {

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip-user" {...props}>
        {user.email}
      </Tooltip>
    );
  }

  //className={"btn btn-xs btn-default float-right"}
  return <OverlayTrigger placement="bottom" overlay={renderTooltip()} transition={false}>
    <Button size={"xs"} variant="default" className={"float-right"}>
      <span className="fa-solid fa-user"
        onClick={() => {copyToClipboard(user.email);}}
      />
    </Button>
  </OverlayTrigger>;
};

const InternalSyncView = ({updatedAt, skipped, syncFails, requestedAt}) => {
  //console.log("InternalSyncView", updatedAt);
  const maxWait = 60000; // 60 sec
  const lastRefresh = (new Date(updatedAt));
  const lastRequest = (new Date(requestedAt));
  const lastUpdatedInterval = (new Date()).getTime() - updatedAt;
  const isOutOfDate = lastUpdatedInterval >= maxWait;
  return (
    <span className={"sync"}>
      {isOutOfDate && <button className={"float-right btn btn-danger"} onClick={e => window.location.reload()}>
        <i className="fa-solid fa-arrows-rotate"></i>
        <span className={"title"}>&nbsp;Vous n'êtes pas synchronisé(e)&nbsp;</span>
        <ul>
          <li><strong>Échecs:</strong> {syncFails}</li>
          <li><strong>Ignorés pour concurrence:</strong> {skipped}</li>
          <li><strong>Dernière synchro:</strong> {lastRefresh.toTimeString()}</li>
          <li><strong>Dernière demande:</strong> {lastRequest.toTimeString()}</li>
        </ul>
        <span className={"btn btn-default float-right"}>&nbsp;rafraîchir&nbsp;</span>
      </button>
      }

    </span>
  );
};

const DispatchSync = connect(
  state => {
    return {
      skipped: state.dispatch.versioning.skipped,
      syncFails: state.dispatch.versioning.syncFails,
      updatedAt: state.dispatch.versioning.updated_at,
      requestedAt: state.dispatch.versioning.last_requested_at
    };
  }
)(InternalSyncView);

const LegacyFlowSync = connect(
  state => {
    return {
      skipped: state.m.versioning.skipped,
      syncFails: state.m.versioning.syncFails,
      updatedAt: state.m.versioning.updated_at,
      requestedAt: state.m.versioning.last_requested_at
    };
  }
)(InternalSyncView);

// eslint-disable-next-line react/prop-types
const InternalCenter = ({user, centers, changeCenter}) => {
  if (!user || !centers || centers.length === 0) {
    return null;
  }
  const centerId = extractCenterId(user);
  let center = centers.find(x => x.id === centerId);
  let color = '#FFFFFF';
  let name = 'Centre inconnu';
  if (typeof(center) !== 'undefined') {
    color = center.color;
    name = center.name;
  }

  return <Dropdown as={ButtonGroup} size='xs' >
    <Dropdown.Toggle id="dropdown-custom-1" style={{backgroundColor:color, borderColor:color}}>{name}</Dropdown.Toggle>
    <Dropdown.Menu className="super-colors">
      {centers.map(c => <Dropdown.Item size="xs" style={{backgroundColor:c.color}} key={c.id} eventKey={c.id} onClick={() => changeCenter(user, c)}>{c.name}</Dropdown.Item>)}
    </Dropdown.Menu>
  </Dropdown>;
};

const Center = connect(
  (state) => {
    return {centers: state.m.metas.centers || state.dispatch.metas.centers || []};
  },
  (dispatch) => {
    return {
      "changeCenter": (user, center) => dispatch(userChangeCenter(user, center))
    };
  }
)(InternalCenter);


// eslint-disable-next-line react/prop-types
const DockFilterInternal = ({currentChoice, changeChoice}) => {
  const choices = [3, 5, 7];
  const label = currentChoice === 0 ? 'Toutes les tournées' : `${currentChoice} heures`;
  return <Dropdown as={ButtonGroup} size='xs'>
    <Dropdown.Toggle id="dropdown-custom-1" className="btn-group-xs">{label}</Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item size="xs" key={0} onClick={() => changeChoice(0)}>Toutes les tournées</Dropdown.Item>
      {choices.map(c => <Dropdown.Item size="xs" key={c} onClick={() => changeChoice(c)}>{c} heures</Dropdown.Item>)}
    </Dropdown.Menu>
  </Dropdown>;
};

const DockFilter = connect(
  (state) => {
    return {
      currentChoice: state.dispatch.filters.dockDeparture || 0,
    };
  },
  (dispatch) => {
    return {
      changeChoice: (choice) => dispatch(filterDockDeparture(parseInt(choice, 10)))
    };
  }
)(DockFilterInternal);


const PauseDispatchLoad = () => {
  return (
    <span>
      <button
        id="pause_dispatch_load_play"
        className={"btn btn-xs btn-success"}
        onClick={() => {
          document.pause_dispatch_load = false;
          document.getElementById("pause_dispatch_load_play").classList.remove("btn-default");
          document.getElementById("pause_dispatch_load_play").classList.add("btn-success");
          document.getElementById("pause_dispatch_load_pause").classList.remove("btn-danger");
          document.getElementById("pause_dispatch_load_pause").classList.add("btn-default");
        }}
      >
        &#x23F5;
      </button>
      <button
        id="pause_dispatch_load_pause"
        className={"btn btn-xs btn-default"}
        onClick={() => {
          document.pause_dispatch_load = true;
          document.getElementById("pause_dispatch_load_play").classList.remove("btn-success");
          document.getElementById("pause_dispatch_load_play").classList.add("btn-default");
          document.getElementById("pause_dispatch_load_pause").classList.remove("btn-default");
          document.getElementById("pause_dispatch_load_pause").classList.add("btn-danger");
        }}
      >
        &#x23F8;
      </button>
    </span>
  );
};


const PostalCodesFilterInternal = (
  {
    currentChoice,
    changeChoice,
    showInputModal,
    postalCodesSet,
    departments,
    idf_departments,
    non_idf_departments,
    subScreen,
    changeSubScreen,
    location,
  }
) => {
  const updateChoice = (choice) => {
    let input = document.getElementById("postal-codes-filter-input");
    input.value = choice;
    changeChoice(choice);
  };
  let whiteList = [];
  let other_departments = []; // IDF autres que Paris
  if(departments){
    whiteList = (
      idf_departments.map((department) => department.code)
        .sort((a,b) => a.localeCompare(b))
        .filter(
          (postalCode) => {
            return !postalCodesSet.has(postalCode);
          }
        )
    );
    other_departments = (
      idf_departments.filter(x => x.code !== "75" && x.code !== 75)
        .map(x => x.code)
    );
  }

  let postal_code_input = (
    <input
      type="text"
      id="postal-codes-filter-input"
      defaultValue={currentChoice}
      onBlur={(e) => changeChoice(e.target.value)}
      onKeyUp={
        (e) => {
          if (e.key === "Enter") {
            let input = document.getElementById("postal-codes-filter-input");
            changeChoice(input.value);
          }
        }
      }
      className="ml-1"
    />
  );

  let modal_show_button = (
    <button
      className="btn btn-xs btn-default ml-1"
      onClick={(e) => showInputModal(updateChoice)}
    >+/-</button>
  );
  let empty_button = (
    <button
      className="btn btn-xs btn-default"
      onClick={(e) => updateChoice("")}
    ><i className="fa-solid fa-rectangle-xmark"></i></button>
  );

  const Paris_postal_codes_by_borough = {
    "01": ["75001"],
    "02": ["75002"],
    "03": ["75003"],
    "04": ["75004"],
    "05": ["75005"],
    "06": ["75006"],
    "07": ["75007"],
    "08": ["75008"],
    "09": ["75009"],
    "10": ["75010"],
    "11": ["75011"],
    "12": ["75012", "75912"],
    "13": ["75013"],
    "14": ["75014"],
    "15": ["75015"],
    "16": ["75016", "75116"],
    "17": ["75017"],
    "18": ["75018"],
    "19": ["75019"],
    "20": ["75020"],
  };
  let Paris_postal_codes = Object.values(Paris_postal_codes_by_borough).flat();
  // console.log("Paris_postal_codes", Paris_postal_codes);
  // We use this to know what is "activated".
  const Paris_postal_codes_by_label = Object.assign(
    {},
    Paris_postal_codes_by_borough,
    {"Paris": Paris_postal_codes}
  );
  // Object.keys(Paris_postal_codes_by_label).forEach(
  //   key => Paris_postal_codes_by_label[key] = new Set(Paris_postal_codes_by_label[key])
  // )

  function are_postal_codes_checked(postal_codes_to_check){
    for(let x of postal_codes_to_check){
      if(!postalCodesSet.has(x)){
        return false;
      }
    }
    return true;
  }

  // function is_label_checked(reference_object, label){
  //   const postal_codes_to_check = reference_object[label];
  //   return are_postal_codes_checked(postal_codes_to_check);
  // }

  const nested_postal_codes = {
    "75": Object.values(Paris_postal_codes_by_borough).flat(),
  };
  // console.log("nested_postal_codes", nested_postal_codes)

  function manage_nested_postal_codes(){
    Object.entries(nested_postal_codes).forEach(
      ([key, value]) => {
        if(are_postal_codes_checked(value)){
          postalCodesSet.add(key);
        }
        else{
          postalCodesSet.delete(key);
        }
      }
    );
  }

  function get_button_for_label_and_codes(label, postal_codes){
    const checked = are_postal_codes_checked(postal_codes);
    let btn_classes = "btn btn-xs";
    if(checked){
      btn_classes += " btn-success";
    }
    else{
      btn_classes += " btn-default";
    }
    return (
      <button key={label}
        className={btn_classes}
        onClick={
          (e) => {
            if(checked){
              for(let x of postal_codes){
                postalCodesSet.delete(x);
              }
            }
            else{
              for(let x of postal_codes) {
                postalCodesSet.add(x);
              }
            }
            manage_nested_postal_codes();
            updateChoice(Array.from(postalCodesSet).join(","));
          }
        }
      >{label}</button>
    );
  }

  const query = new URLSearchParams(location.search);
  const sub = query.get("sub");
  if(sub !== subScreen){
    changeSubScreen(sub);
  }
  // if(
  //   sub !== null
  //   && sub !== undefined
  //   && SUB_DISPATCH_CODES.indexOf(sub) !== -1
  //   && SUB_DISPATCH_CONFIGURATIONS[sub].withNotDeliveredOrders === false
  // ){
  // if(sub === SUB_DISPATCH_CODE_LIGHT_LOADING_1){
  if(true){
    return (
      <div className="btn-xs mx-auto" style={{marginTop: "0.15cm", width: "fit-content"}}>
        Codes postaux : {postal_code_input}
        {modal_show_button}
        {empty_button}
        <br />
        <div style={{marginTop: "0.15cm"}}>
          {
            Object.keys(Paris_postal_codes_by_label).sort(
              (x, y) => {
                if(x === "Paris"){ return -1; }
                if(y === "Paris"){ return 1; }
                return x.localeCompare(y);
              }
            ).map(
              label => get_button_for_label_and_codes(
                label,
                Paris_postal_codes_by_label[label],
              )
            )
          }
        </div>
        <div style={{marginTop: "0.15cm"}}>
          {
            get_button_for_label_and_codes(
              "I.D.F. hors Paris",
              other_departments,
            )
          }
          {
            other_departments.sort(
              (x, y) => {
                return x.localeCompare(y);
              }
            ).map(
              code => get_button_for_label_and_codes(
                code,
                [code],
              )
            )
          }
        </div>
      </div>
    );
  }

};

const PostalCodesFilter = withRouter(connect(
  (state) => {
    return {
      currentChoice: state.dispatch.filters.postalCodes || "",
      postalCodesSet: state.dispatch.filters.postalCodesSet,
      departments: state.dispatch.metas.departments,
      idf_departments: state.dispatch.metas.idf_departments,
      non_idf_departments: state.dispatch.metas.non_idf_departments,
      subScreen: state.dispatch.subScreen,
    };
  },
  (dispatch) => {
    return {
      changeChoice: (choice) => dispatch(filterPostalCodes(choice)),
      showInputModal: (updateChoice) => dispatch({
        type: DISPATCH_MAIN_MODAL_SHOW,
        modalData: {
          modalType: DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT,
          updateChoice: updateChoice,
        }
      }),
      changeSubScreen: (subScreen) => dispatch({type: DISPATCH_SUB_SCREEN, subScreen: subScreen}),
    };
  }
)(PostalCodesFilterInternal));


const GlobalActionsInternal = ({centerId, waitingForRefresh, extractAllFinalized}) => {
  return (
    <div className="btn-xs mx-auto" style={{marginTop: "0.15cm", width: "fit-content"}}>
      <button
        className="btn btn-xs btn-success"
        onClick={() => extractAllFinalized(centerId)}
      >Extraire toutes les commandes finalisées</button>
      {waitingForRefresh ? <br/> : null}
      {waitingForRefresh ? <span className="alert alert-danger">En attente de rafraîchissement</span> : null}
    </div>
  );
};

const GlobalActions = connect(
  (state) => {
    return {
      waitingForRefresh: state.dispatch.waitingForRefresh,
    };
  },
  (dispatch) => {
    return {
      extractAllFinalized: (centerId) => {
        dispatch(
          {
            type: DISPATCH_DOCKS_EXTRACT_ALL_FINALIZED,
            centerId: centerId,
          }
        );
        dispatch({type: DISPATCH_NEEDS_REFRESH});
      },
    };
  }
)(GlobalActionsInternal);


class InternalAllDispatchAndSubDispatchHeader extends React.Component {

  render() {
    const { user, centers } = this.props;
    const centerId = extractCenterId(user);
    const center = centers.find(c => c.id === centerId);

    if(!center || center.is_shadow_groupment_enabled !== true){
      return null;
    }

    return (
      <React.Fragment>
        <Link
          className="btn btn-xs btn-primary"
          to={"/redirect/" + centerId + "/?u=/dispatch/"}
          target="_blank"
          rel="noopener noreferrer"
        >
            Dispatch
        </Link>
        {
          SUB_DISPATCH_CODES.map(
            dispatchCode => {
              let configuration = SUB_DISPATCH_CONFIGURATIONS[dispatchCode];
              // console.log(dispatchCode, configuration, SUB_DISPATCH_CONFIGURATIONS);
              if(!configuration.activated){
                return null;
              }
              return <Link
                className="btn btn-xs btn-primary"
                to={"/redirect/" + centerId + "/?u=/dispatch/&sub=" + dispatchCode}
                target="_blank"
                rel="noopener noreferrer"
                key={dispatchCode}
              >
                {configuration.label}
              </Link>;
            }
          )
        }
      </React.Fragment>
    );
  }
}

InternalAllDispatchAndSubDispatchHeader.propTypes = {
  user: PropTypes.object,
  centers: PropTypes.array,
};


class InternalAdminCompactHeader extends React.Component {

  render() {
    const { current, user, centers } = this.props;
    const centerId = extractCenterId(user);
    const center = centers.find(c => c.id === centerId);

    const menu =(jsonMenu || [])
      .filter(e => !!e && !!e.url && !!e.value);

    const listOptions = menu
      .sort((a, b) => a.value < b.value ? -1 : 1)
      .map((e) => (<option key={e.url} value={e.url}>{e.value}</option>));

    const listLink = menu
      .filter(e => e.menu && !e.hide_menu)
      .filter(e => e.value !== current)
      .map((e) => {
        if (e.internal) {
          return (<Link key={e.url} className="btn btn-xs btn-primary" to={"/redirect/" + centerId + "/?u=" + e.internal}>{e.value}</Link>);
        }
        return (<a key={e.url} className="btn btn-xs btn-primary" href={buildUrl(e.url)} target="_blank" rel="noopener noreferrer" >{e.value}</a>);
      });

    const isAdminUser = user.groups && user.groups.is_manager && user.groups.is_acl_manager;

    return (
      <header className="AdminCompactHeader">
        {listLink}

        {user && user.is_logistic ? <Link className="btn btn-xs btn-primary" to={"/redirect/" + centerId + "/?u=/logs/date/0"}>Logs</Link>: null}
        {user && user.is_logistic ? <Link className="btn btn-xs btn-primary" to={"/redirect/" + centerId + "/?u=/driver_extra_cost_demands"}>Demandes</Link>: null}
        <Link className="btn btn-xs btn-primary" to={"/redirect/" + centerId + "/?u=/support/"}>Support</Link>
        {user && user.groups && user.groups.is_super_admin ?<Link className="btn btn-xs btn-primary" to={"/redirect/" + centerId + "/?u=/assign/prio"}>assign</Link>: null}
        {user ? <Link className="btn btn-xs btn-primary" to={"/uber-eats/orders/"}>UberEats</Link>: null}
        <select onChange={e => handleChange(e)} style={{width:"60px", marginLeft:"10px"}}>
          <option value="">Aller à</option>
          {listOptions}
        </select>
        <User user={user}/>
        <Route exact path="/" component={LegacyFlowSync}/>
        <Route path="/dispatch" component={DispatchSync}/>

        {center && center.stock_type === 2 && isAdminUser &&
          <Link
            className="btn btn-xs btn-primary"
            to={"/redirect/" + centerId + "/?u=/arrivals/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Arrivages
          </Link>
        }
        <InternalAllDispatchAndSubDispatchHeader
          user={user}
          centers={centers}
        />
        {/*{center && center.stock_type === 1 &&*/}
        {/*  <Link*/}
        {/*    className="btn btn-xs btn-primary"*/}
        {/*    to={"/redirect/" + centerId + "/?u=/loadings/"}*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    XD*/}
        {/*  </Link>*/}
        {/*}*/}
        <Center user={user}/>
        <Route path="/dispatch">
          <DispatchAutoConnected />
          <DockFilter />
          <PauseDispatchLoad />
          <PostalCodesFilter />
          <GlobalActions centerId={centerId} />
        </Route>
      </header>
    );
  }
}
//        <!-- <PseudoZones /> -->

InternalAdminCompactHeader.propTypes = {
  current: PropTypes.string,
  user: PropTypes.object,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  location: PropTypes.object,
};

export const AdminCompactHeader = connect(
  (state) => {
    return {
      user : state.user,
      centers: (
        state.m.metas.centers
        || state.dispatch.metas.centers
        || []
      ),
      centerId: extractCenterId(state.user),
    };
  }
)(InternalAdminCompactHeader);


export default AdminCompactHeader;
