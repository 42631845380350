import React, { useState } from 'react';
import { withFormik, Field } from 'formik';
import { Alert, Col, Form, FormGroup, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import {rd} from "../../../../../utils";

const MyForm = props => {
  const {
    values,
    touched,
    errors,
    order,
    handleChange,
    handleBlur,
    handleSubmit,
    setRefundAmount,
  } = props;

  const [isOrderAmountReached, setIsOrderAmountReached] = useState(false);

  const totalRefundAndCredit = order.amount_refund + order.amount_recredit;

  /* Manage min and max quantity */
  const handleQuantityChange = React.useCallback((event) => {
    let qty = parseFloat(event.target.value);
    if (qty < values.minQuantity) {
      qty = values.minQuantity;
    }
    else if (qty > values.maxQuantity) {
      qty = values.maxQuantity;
    }

    if ( (totalRefundAndCredit + qty) > order.amount) {
      setIsOrderAmountReached(true);
    } else if ((totalRefundAndCredit + qty) <= order.amount) {
      setIsOrderAmountReached(false);
    }
    setRefundAmount && setRefundAmount(rd(qty));
    props.setFieldValue("quantity", rd(qty));
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup as={Row} controlId="formHorizontalEmail">
        <Col as={Form.Label} sm={4}>
          {values.labelQuantity ? values.labelQuantity : "Quantité à rembourser"}
        </Col>
        <Col sm={8}>
          <Field min={values.minQuantity} max={values.maxQuantity} type="number" name="quantity" placeholder={values.labelQuantity ? values.labelQuantity : "Quantité à rembourser"} className="form-control" required autoFocus={true}
            onChange={handleQuantityChange} step="any"/>
          {errors.quantity && touched.quantity && <div id="feedback">{errors.quantity}</div>}
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="formControlsSelect">
        <Col as={Form.Label} sm={4}>
          Type de raison
        </Col>
        <Col sm={8}>
          <select
            name="subtype"
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control"
            required="required"
            value={values.subtype}
          >
            <option value="">choisir</option>
            {values.sub_type_list.map(e => (<option key={e.subtype} value={e.subtype} >{e.label}</option>))}
          </select>
          {errors.subtype && touched.subtype && <div id="feedback">{errors.subtype}</div>}
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="formControlsSelect">
        <Col as={Form.Label} sm={4}>
          Explications
        </Col>
        <Col sm={8}>
          <textarea
            name="notes"
            className="form-control"
            onChange={handleChange}
            onBlur={handleBlur}
            required={values.subtype === 'refund_other' ? true : false}
          />
          {errors.notes && touched.notes && <div id="feedback">{errors.notes}</div>}
        </Col>
      </FormGroup>
      {isOrderAmountReached && (
        <Alert variant="danger">
          <strong>ATTENTION:</strong> Le montant des crédits et remboursement dépassent le montant
          de la commande
        </Alert>
      )
      }
      <FormGroup>
        <Col sm={12} className="offset-sm-5">
          <button type="submit" className="btn btn-primary" disabled={!values.quantity}>Rembourser {rd(1 * values.quantity)} €</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "errors": PropTypes.any,
  "touched": PropTypes.any,
  "order": PropTypes.object,
  "handleChange": PropTypes.any,
  "handleBlur": PropTypes.any,
  "handleSubmit": PropTypes.any,
  "setRefundAmount": PropTypes.func,
};

export const RefundOrderForm = withFormik({
  handleSubmit: (values, formikBag) => {
    formikBag.props.validate(values);
  },
  displayName: 'BasicForm',
})(MyForm);
