import React from 'react';
import moment from "moment";
import 'moment/locale/fr';

import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from "react-redux";
import {DISPATCH_DOCK_DEFINE, DISPATCH_DOCK_UNDEFINE} from "../../../actions/dock";

import "../../../css/DockBtnDefine.css";
import {
  DOCK_TYPE_STUART,
  DOCK_TYPE_HARD_ASSIGNED,
  STATUS_DRAFT,
  STATUS_MOVING,
  STATUS_READY_TO_LOAD,
} from "../../../utils/dock_n_driver";

moment.locale('fr');

export const isDefinable = function (dock) {

  if (dock.dock_type === DOCK_TYPE_STUART) {
    return false;
  }

  if (dock.dock_type !== DOCK_TYPE_HARD_ASSIGNED) {
    if (dock.tasks.length === 0) {
      return false;
    }

    if (dock.current_status === STATUS_DRAFT) {
      return true;
    }
  }

  return false;
};


// eslint-disable-next-line react/prop-types
const IDefine = ({dock, onDefine, onUndefine}) => {

  if (!dock) {
    return null;
  }

  if (dock.current_status === STATUS_READY_TO_LOAD && dock.loaded_at) {
    if (dock.load_info && dock.load_info.is_expe_frozen === true) {
      let now = moment(dock.load_info.started_at);
      const tooltip = (
        <Tooltip id={`tooltip-loader-${dock.id}`}>
          {dock.load_info.user_name} depuis {now.hour()}h{now.format("mm")}
        </Tooltip>
      );
      return (
        <OverlayTrigger placement="top" overlay={tooltip} transition={false} className="BtnDefine">
          <span className="define-loading-label">
            <span><i className="frozen-icon fa-solid fa-snowflake"></i> Expé surgelé en cours</span>
          </span>
        </OverlayTrigger>
      );
    } else if (dock.has_packing_on_expe) {
      return (
        <span className="define-loading-label BtnDefine">
          <span><i className="frozen-icon fa-solid fa-snowflake"></i> Ultraspeed surgelé en cours</span>
        </span>
      );
    }
    return (
      <span className="define-loaded-label BtnDefine">
        <span><i className="fa-solid fa-dolly"></i> Prêt à partir</span>
      </span>
    );
  }

  if (dock.current_status === STATUS_MOVING && dock.dock_type !== DOCK_TYPE_STUART) {
    return (
      <span className="define-loading-label BtnDefine">
        <Button
          className="BtnDefine"
          onClick={() => onUndefine(dock)}
          size="xs"
          variant={"danger"}
          disabled={dock.updating ? true : false}>Invalider la tournée</Button>
      </span>
    );
  }

  if (isDefinable(dock)) {
    let is_disabled = false;
    if (dock.updating === true) {
      is_disabled = true;
    }
    return (
      <Button
        className="ButtonDefine"
        onClick={() => onDefine(dock)}
        size="xs"
        variant={dock.defined_at ? "default" : "primary"}
        disabled={is_disabled}>Valider la tournée</Button>
    );
  }

  return null;
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return {
    "onDefine":         (dock) => dispatch({ type: DISPATCH_DOCK_DEFINE, dock : dock, service: "define"} ),
    "onUndefine":         (dock) => dispatch({ type: DISPATCH_DOCK_UNDEFINE, dock : dock, service: "undefine"} ),
  };
};

export const BtnDefine = connect(mapStateToProps, mapDispatchToProps)(IDefine);
