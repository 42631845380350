import React, { Component } from 'react';
import OrderRow from "./OrderRow";
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";

import "../css/OrderList.css";
import {SwitchToggle} from "./SwitchToggle";

class OrderList extends Component {

  render() {
    const { orders, title, className, initialLength, toggleKey, errorLength } = this.props;
    const nbOrders = orders.length;

    if (initialLength === 0) {
      return null;
    }

    let classNameComp = "OrderList " + className;

    if (errorLength > 0) {
      classNameComp += ' error-list';
    }

    const h4 = (<h4 >
      {title} :
      {errorLength} / {initialLength}

      <SwitchToggle Tkey={toggleKey} className="float-right" />
    </h4>);

    if (nbOrders === 0) {
      return <div className={classNameComp}>{h4}</div>;
    }


    return (
      <div className={classNameComp}>
        {h4}
        <Table bordered size="sm" hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Sacs</th>
              <th>Z.</th>
              <th>Client</th>
              <th>Adresse</th>
              <th>Cr.</th>
              <th>État</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(o => (<OrderRow key={o.id} order={o} />))}
          </tbody>
        </Table>
      </div>
    );
  }
}


OrderList.propTypes = {
  orders: PropTypes.array.isRequired,
  title:  PropTypes.string.isRequired,
  className: PropTypes.string,
  toggleKey: PropTypes.string,
  initialLength: PropTypes.number,
  errorLength: PropTypes.number,
};


export default OrderList;
