export const STUART_BIKE = 'bike';
export const STUART_MOTORBIKE = 'motorbike';
export const STUART_CAR = 'car';
export const STUART_CARGOBIKE = 'cargobike';
export const STUART_CARGOBIKEXL = 'cargobikexl';
export const STUART_MOTORBIKEXL = 'motorbikexl';

export const stuartVehiclesSelect = [
  { value: STUART_BIKE, name: "Vélo" },
  { value: STUART_MOTORBIKE, name: "Scooter/Moto" },
  { value: STUART_CAR, name: "Voiture" },
  { value: STUART_CARGOBIKE, name: "Vélo cargo" },
];
