import {getStatusDiff} from "../../utils/status";


let order = {};

order.changeStatus = function(action, state) {

  // récupérer le nx status de COMMANDE
  // ne pas deviner les status de tasks, mais les mettres en updating
  // dans sagas, recharger également la tâche de prep

  const orderId = action.orderId;
  const taskDelivery = state.deliveries.find(e => e.linked_order.id === orderId);
  const taskPacking = state.packings.find(e => e.linked_order.id === orderId);
  const taskCanceledDelivery = (
    state.canceled_deliveries
    ? state.canceled_deliveries.find(e => e.linked_order.id === orderId)
    : null
  );

  const beforeOrder = (
    taskDelivery
    ? taskDelivery.linked_order
    : (
      taskCanceledDelivery
      ? taskCanceledDelivery
      : taskPacking.linked_order
    )
  );
  if (action.newStatus === beforeOrder.status) {
    return state;
  }

  let newDeliveries = state.deliveries;
  if (taskDelivery) {
    const changeStatusDelivery = Object.assign(
      getStatusDiff(taskDelivery, action.newStatus),
      {updating: orderId}
    );

    // leave Dock
    const leaveDock = (changeStatusDelivery.hasOwnProperty('dock') && !changeStatusDelivery.dock && !!taskDelivery.dock);
    newDeliveries = state.deliveries.map((e) => {
      if (e.id === taskDelivery.id) {
        return Object.assign({}, e, changeStatusDelivery);
      }

      return e;
    });
    console.log('resetShipping:', leaveDock, changeStatusDelivery, taskDelivery);
  }

  let newPackings = state.packings;
  if (taskPacking) {
    const changeStatusPacking = {...getStatusDiff(taskPacking, action.newStatus), ...{updating: orderId}};
    newPackings = state.packings.map((e) => {
      if (e.id === taskPacking.id) {
        return Object.assign({}, e, changeStatusPacking);
      }
      return e;
    });
    console.log('resetShipping:', changeStatusPacking, taskPacking);
  }

  return {...state, ...{deliveries: newDeliveries, packings: newPackings}};
};

order.numberOfBags = function(action, state) {

  if (!action.hasOwnProperty('newNumber')) {
    return state;
  }

  return Object.assign({}, state, {
    deliveries: state.deliveries.map((e) => {
      if  (e.id === action.task.id) {
        return Object.assign({}, e, {
          linked_order: Object.assign({}, e.linked_order, {number_of_bags: action.newNumber}),
          updating: e.id
        });
      }
      return e;
    })
  });
};

order.updateOrder = function (action, state) {

  const mapFunc = (e) => {
    return e.linked_order.id === action.orderPatch.id ? {...e , ...{updating: true, linked_order: {...e.linked_order, ...action.orderPatch }}} : e;
  };
  return {
    ...state,
    ...{deliveries: state.deliveries.map(mapFunc), packings: state.packings.map(mapFunc)}
  };
};

order.updatedOrder = function (action, state) {

  const mapFunc = (e) => {
    return e.linked_order.id === action.order.id ? {...e , ...{updating: false, linked_order: action.order }} : e;
  };
  return {
    ...state,
    ...{deliveries: state.deliveries.map(mapFunc), packings: state.packings.map(mapFunc)}
  };
};


export default order;

order.bulkChangeStatus = (action, state) => {
  return { 
    ...state,
    deliveries: state.deliveries.map(
      d => action.ordersIds.includes(d.linked_order.id) ? {...d, updating: true } : d
    )
  };
};
