import React from "react";

import { DockListMoving } from "./Docks/DockListMoving";
import { DockListDraft } from "./Docks/DockListDraft";
import { DockListReadyToLoad } from "./Docks/DockListReadyToLoad";
import { DockListShipping } from "./Docks/DockListShipping";
import { DriverStatesLinked } from "./Driver/DriverStatesLinked";
import { DriverIsoBagModal } from "./modal/DriverIsoBagModal";
import { DepositModalDriver } from "./modal/DepositModalDriver";
import { DepositModalDrivers } from "./modal/DepositModalDrivers";
import {ModalExtraCostAdd} from "./modal/ModalExtraCostAdd";
import { DeliveriesLate } from "./Deliveries/DeliveriesLate";
import {DockListDraftTomorrow} from "./Docks/DockListDraftTomorrow";
import {DriverExtraCostDemandList} from "./DriverExtraCostDemandList";
import {PostalCodesFilterInputModal} from "./modal/PostalCodesFilterInputModal"
import NotDeliveredOrders from "./Deliveries/NotDeliveredOrders";
import {DriversSoonModal} from "./modal/DriversSoonModal";
import {DQPHeader} from "./DQPHeader";
import {connect} from "react-redux";
import {SUB_DISPATCH_CODE_LIGHT_CREATION_1} from "../sub_dispatchs";

/*
 * Les listes de Docks utilisent toutes le component "DockList", mais on récupère des listes de docks différentes
 * via le selectors/dock
 */

const InternalDocks = ({subScreen}) => {
  if(subScreen === SUB_DISPATCH_CODE_LIGHT_CREATION_1){
    return (
      <div className="Docks col-12 col-sm-8 col-huge-9virgule6">
        <NotDeliveredOrders />
        <DQPHeader />
        <DeliveriesLate />
        <DriverExtraCostDemandList />
        <div className="container-fluid">
          <div className="row">
            <div className="col-4"><DockListDraft /></div>
            <div className="col-4"><DockListMoving /></div>
            <div className="col-4"><DockListReadyToLoad /></div>
          </div>
        </div>
        <DockListDraftTomorrow />
        <DriverStatesLinked />
        <DockListShipping />
        <DriverIsoBagModal />
        <DepositModalDriver />
        <DepositModalDrivers />
        <ModalExtraCostAdd />
        <PostalCodesFilterInputModal />
        <DriversSoonModal />
      </div>
    );
  }
  return (
    <div className="Docks col-12 col-sm-8 col-huge-9virgule6">
      <NotDeliveredOrders />
      <DQPHeader />
      <DeliveriesLate />
      <DriverExtraCostDemandList />
      <DockListReadyToLoad />
      <DockListMoving />
      <DockListDraft />
      <DockListDraftTomorrow />
      <DriverStatesLinked />
      <DockListShipping />
      <DriverIsoBagModal />
      <DepositModalDriver />
      <DepositModalDrivers />
      <ModalExtraCostAdd />
      <PostalCodesFilterInputModal />
      <DriversSoonModal />
    </div>
  );
};

export const Docks = connect(
  (state) => ({
      subScreen: state.dispatch.subScreen,
  }),
  (dispatch) => ({
  })
)(InternalDocks);
