export const ARRIVALS_LOAD = 'ARRIVALS_LOAD';
export const ARRIVALS_LOADED = 'ARRIVALS_LOADED';
export const ARRIVALS_API_STATE = 'ARRIVALS_API_STATE';
export const VALIDATE_ARRIVALS = 'VALIDATE_ARRIVALS';
export const ARRIVALS_CHANGE_NUMBER_BAGS = 'ARRIVALS_CHANGE_NUMBER_BAGS';
export const ARRIVALS_TASK_UPDATED ='ARRIVALS_TASK_UPDATED';
export const ARRIVALS_ORDER_CRATE_ADD = 'ARRIVALS_ORDER_CRATE_ADD';
export const ARRIVALS_ORDER_CRATE_DELETE = "ARRIVALS_ORDER_CRATE_DELETE";
export const ARRIVALS_CRATE_MODAL_UPDATED = 'ARRIVALS_CRATE_MODAL_UPDATED';

export const loadArrivals = centerId => {
  return { type: ARRIVALS_LOAD, centerId };
};

export const arrivalsLoaded =  arrivals => {
  return { type: ARRIVALS_LOADED, arrivals };
};

export const arrivalsApiState =  (loading, processing) => {
  return { type: ARRIVALS_API_STATE, loading, processing };
};

export const validateArrivals = (arrivals, centerId) => {
  return { type: VALIDATE_ARRIVALS, arrivals, centerId };
};

export const arrivalsTaskUpdated = (dockId, taskId, order) => {
  return { type: ARRIVALS_TASK_UPDATED, dockId, taskId, order };
};
