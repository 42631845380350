import {connect} from "react-redux";

import CrateModal from '../../../common-comps/CrateModal';

import { MAIN_MODAL_HIDE } from "../../../actions/modal";

import { ARRIVALS_ORDER_CRATE_ADD, ARRIVALS_ORDER_CRATE_DELETE } from "../actions";


export default connect(
  state => ({
    modal : state.m.modal,
  }),
  dispatch => ({
    hide: () => {
      dispatch({ type: MAIN_MODAL_HIDE });
    },
    deleteCrate: (crateCode, orderId, taskId, dockId) => dispatch({
      type: ARRIVALS_ORDER_CRATE_DELETE, orderId, crateCode, taskId, dockId
    }),
    addCrate: ({ crateCode, orderId }, taskId, dockId) => dispatch({
      type: ARRIVALS_ORDER_CRATE_ADD, orderId, crateCode, taskId, dockId
    }),
  })
)(CrateModal);
