import { connect } from "react-redux";
import PackingList from "../../../components/PackingList";
import { getSortedXDPackingToCheck } from "../selectors";


const SHOW_OBJECT = {prep: true};

export default connect(
  state => ({
    tasks: getSortedXDPackingToCheck(state),
    title: "Commandes non reçues par centre BAM / à vérifier",
    className: 'to-check-list',
    show: SHOW_OBJECT,
  })
)(PackingList);
