import { connect } from 'react-redux';

import { getXDToCheckTasks } from '../apps/crossdocking/selectors';
import PackingList from '../components/PackingList';
import { SWITCH_TOGGLE } from "../actions/switch_toggle";
import { currentToggleValue, PACKING_XD_TO_CHECK_KEY } from "../utils/toggle";

export default connect(
  state => ({
    tasks: getXDToCheckTasks(state),
    title: 'Crossdocking/Transfert stock à vérifier',
    className: 'to-check-list',
    show: { 
      nbBags: true,
      transitArea: true,
      nbProducts: false,
    },
    showAll: currentToggleValue(state.toggle, PACKING_XD_TO_CHECK_KEY)
  }),
  dispatch => ({
    onToggle: () => dispatch({ type: SWITCH_TOGGLE, key: PACKING_XD_TO_CHECK_KEY }),
  })
)(PackingList);
