import { connect } from 'react-redux';

import { dockListDeliveriesProps } from '../../../containers/common-props/dock';
import { getComputedDeliveringDockListXD } from '../selectors';
import DockList from '../../../components/DockList';
import {withRouter} from "react-router-dom";

export default withRouter(connect(
  state => dockListDeliveriesProps(state, getComputedDeliveringDockListXD(state))
)(DockList));
