import React from "react";
import { createSelector } from 'reselect';
import { stateToCenterId } from "../utils/center";


const getPackerstatcph = state => state.m.packerstatstockpilling;
const getCenterId = state => stateToCenterId(state);

export const getCphAvg = createSelector(
  [getPackerstatcph, getCenterId],
  (packerstatcph, centerId) => {

    let packerstatcph_avg = null;
    if (packerstatcph && typeof(packerstatcph['cph_by_packer']) !== 'undefined') {
      const cph_global = packerstatcph['cph_by_packer'][0];
      const conf_zone = packerstatcph['conf_zone'];
      let zone_detail = null;
      if (centerId === 2 ) {
        zone_detail = conf_zone.filter(zone => zone["key"] !== "total").map((zone, idx_zone) => {
          return <React.Fragment key={idx_zone}>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;<span key={idx_zone} title="" className="iph_tooltip" style={{color: "#444"}}> {zone["title"]} : </span>
            {Object.values(cph_global["datas"]).filter(cph => cph["key"].endsWith(zone["key"])).map((cph, idx) => {
              return <span key={idx_zone + idx} title={cph.cph_tooltip} className="iph_tooltip" style={{color: "#444"}}> {idx > 1 ? <b> / </b> : null}{cph["time_name"]}: <span style={{color: "grey"}}>{cph.cph} cph</span></span>;
            })}
          </React.Fragment>;
        });
      }

      let cph_global_per_zone = <small>
        <br />
        <b>CPH Moyen</b> ({cph_global["datas"]["15minago_total"]["nb_preps"]} preps) {` => `}
        {Object.values(cph_global["datas"]).filter(cph => cph["key"].endsWith("_total")).map((cph, index) => {
          return <span key={index} title={cph.cph_tooltip} className="iph_tooltip">{index > 0 ? <b> / </b> : null}{cph.time_name}: {cph.cph} cph</span>;
        })}
        {zone_detail}
      </small>;
      

      packerstatcph_avg = (
        <React.Fragment>
          {cph_global_per_zone}
        </React.Fragment>
      );
    }

    return packerstatcph_avg;
  }
);
