/* eslint-disable react/prop-types */
import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DocumentTitle from "react-document-title";

import "../../../css/Nexts.css";

import {ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG, ASSIGN_LOAD_PSEUDOZONE, ASSIGN_LOAD_CONFIGS} from "../actions";
import {PrioItem} from "./PrioItem";
import {getSortedFilteredNextConfigs} from "../../../selectors/assign_prio";
// import {ConfigList} from "./ConfigList";


// component
class InternalAssignPrio extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      //console.log("UO, match : ", this.props.match.params);
      this.props.loadAssign();
    }
  }

  componentDidMount() {
    //    if (this.props.match.params.order_id) {
    this.props.loadAssign();
  }

  render() {
    return (
      <div className="Assign">
        <div className="AssignPrio">
          <div className="row">
            <div className="col-md-12">
              <DocumentTitle title={"Préparateurs et types de tâches"}>
                <h3>
                  Priorité des types de tâche
                </h3>
              </DocumentTitle>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-8">
              <h4>Les Règles , explications</h4>
              <p>
                <b>Pour prendre la tâche suivante, dans l'ordre:</b><br/>
                - <b>Reprise:</b> tâche (type de tâche courant) commencée et non terminée<br/>

                - <b>Forçage manuel:</b> tâche suivante de configuré pour ce prep sur le flow, si dispo   (y compris règle spéciale prep de zone), verrouillage pour x tâche<br/>
                - <b>Est verrouillé:</b> le prep est verrouillé sur un type de tâche pour x tâche (x configurable: <i>next_lock_when_forced</i>) et le type de tâche est disponible<br/>

                - <b>Spot prio :</b> s'il y a des tâches à faire sur ce stop, et que le préparateur a les droits, il y est affecté,
                même si ça crée un autre spot vide ;

                - <b>Spot vide:</b> si type de tâche "vide" (personne n'a ce type de tâche en tâche courante ou tâche terminée) et au moins une tâche à faire avec un "départ" dans moins d’une heure,
                et que ça ne déplace pas le pb (zone actuelle du prep désertée), le prep est assigné à ce "spot"<br/>


                - <b>Tâches dispos, même type de tâche:</b> tâche courante et tâche courante dispo + règle spéciale prep de zone : on reste sur le type de  tâche courante<br/>

                - <b>Par ordre de priorité de config:</b> recherche sur tous les types de tâches par ordre de priorité,
                  on prend la première disponible + règle spéciale préparation de zone<br/>

                - cas par défaut : normalement cela n'arrive jamais, car help_center toujours vrai<br/>
              </p>
              <p>
                <b>Règles spéciales préparateurs de zone:</b><br />
                - si le "buffer" est plein , cad que le nombre de commande avec la zone prête/faite et en status payé pour cette zone est supérieur ou égale à la limite, alors on skip<br/>
                - néanmoins si la première tâche (par ordre de prio dans les commandes à préparer maintenant) pour cette zone est une commande dont la zone n'est pas préparée, alors ça bypass la limite du buffer
              </p>
            </div>
          </div>
          {/*<div className="row">*/}
          {/*  <div className="col-md-10 col-lg-8">*/}
          {/*    <h4>Configuration diverses</h4>*/}
          {/*    <ConfigList configs={this.props.configs}></ConfigList>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="row">
            <div className="col-md-10 col-lg-8">
              <h4>L'ordre de priorité pour "ordre de prio de config"</h4>
              {this.props.nextConfigs.map(e => <PrioItem key={e.id} nextConfig={e}/>)}
            </div>
          </div>

        </div>
        <div className="AssignAccu">
          <div className="row">
            <div className="col-md-10 col-lg-8">
              <h4>
                Nombre d'accus selon temperature (frais)
              </h4>

            </div>
          </div>

        </div>
      </div>
    );
  }
}


// link
export const AssignPrio = withRouter(connect(
  (state) => {
    return {
      nextConfigs: getSortedFilteredNextConfigs(state),
    };
  },
  (dispatch) => {
    return {
      loadAssign : () => {
        dispatch({type: ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG});
        dispatch({type: ASSIGN_LOAD_PSEUDOZONE});
        dispatch({type: ASSIGN_LOAD_CONFIGS});
      },
      loadAccuConfig : () => {

      }
    };
  }
)(InternalAssignPrio));





