import {connect} from "react-redux";
import moment from 'moment';
//import {sortByKey} from "../utils";
import {IncomingPlanningsSessionList} from "../components/IncomingPlanningsSessionList";
import {planningsSessionUpdate} from "../actions";
import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {currentToggleValue, INCOMING_PLANNING_LIST_KEY} from "../utils/toggle";

const mapStateToProps = state => {
  return {
    planningsSessions: state.m.incoming_plannings_sessions,
    title: "Sessions de planning à venir",
    className: "IncomingPlanningsSession",
    "showAll": currentToggleValue(state.toggle, INCOMING_PLANNING_LIST_KEY)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "startSooner": (planningsSession) => dispatch(planningsSessionUpdate(planningsSession, {started_at: moment()})),
    "onToggle":    () => dispatch({type: SWITCH_TOGGLE, key: INCOMING_PLANNING_LIST_KEY}),
  };
};

let IncomingPlanningsSessionLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomingPlanningsSessionList);

export default IncomingPlanningsSessionLinked;
