import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import "../css/Late.css";
import {lateAction, lateWarned} from "../actions";

export const Late = connect()(({task, dispatch}) => {
  return (
    <div className="Late">
      <button className="btn btn-default btn-xs" onClick={() => dispatch(lateAction(task))}>prevenir</button>

      <button className="btn btn-default btn-xs" onClick={() => dispatch(lateWarned(task))}>
        <span className="fa-solid fa-check" />
      </button>
    </div>);
});

Late.propTypes = {
  task: PropTypes.object.isRequired
};

export default Late;
