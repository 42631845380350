import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";


export const OtherTasksSameAddressBadge = ({task, dock}) => {
  let debug = false;
  let dockId = 0;
  if(dock){
    dockId = dock.id;
  }
  if(debug){
    console.log(
      "task",
      task,
      "tasks_with_same_address",
      task.tasks_with_same_address,
      "tasks_with_same_address_and_intersecting_slot",
      task.tasks_with_same_address_and_intersecting_slot
    );
  }

  function getCompassId(order, index){
    return `compass-same-address-${index}-${task.id}-${dockId}-${order.random_id}`
  }

  let allCompasses = [];
  if(task.tasks_with_same_address){
    task.tasks_with_same_address.forEach(
      task => allCompasses.push(
        {
          id: getCompassId(task.linked_order, 1),
          targetRandomId: task.linked_order.random_id,
        }
      )
    );
  }
  if(task.tasks_with_same_address_and_intersecting_slot){
    task.tasks_with_same_address_and_intersecting_slot.forEach(
      task => allCompasses.push(
        {
          id: getCompassId(task.linked_order, 2),
          targetRandomId: task.linked_order.random_id,
        }
      )
    );
  }

  function updateCompass(compass){
    const compassElement = document.getElementById(compass.id);
    if(!compassElement){
      return;
    }
    let targetElements = document.getElementsByClassName("random-id");
    targetElements = Array.prototype.filter.call(
      targetElements,
      targetElement => targetElement.textContent.indexOf(compass.targetRandomId) !== -1
    );
    if(targetElements.length > 0){
      let targetElement = targetElements[0];
      let sourceRectangle = compassElement.getBoundingClientRect();
      let targetRectangle = targetElement.getBoundingClientRect();
      // console.log(sourceRectangle, targetRectangle);
      let middleVerticalOffset = (
        (targetRectangle.top + targetRectangle.bottom)
        - (sourceRectangle.top + sourceRectangle.bottom)
      ) / 2;
      let middleHorizontalOffset = (
        (targetRectangle.left + targetRectangle.right)
        - (sourceRectangle.left + sourceRectangle.right)
      ) / 2;
      // console.log(middleVerticalOffset + " " + middleHorizontalOffset);
      let angle = Math.atan2(-middleVerticalOffset, middleHorizontalOffset);
      // console.log(angle);
      angle += Math.PI / 4;
      // console.log(angle);
      compassElement.style.transform = "rotate(" + Math.round(-angle * 180 / Math.PI) + "deg)";
      // let hypothenus = Math.sqrt(
      //   middleVerticalOffset*middleVerticalOffset
      //     + middleHorizontalOffset*middleHorizontalOffset
      // );
      // console.log(hypothenus);
      // let sinus = middleVerticalOffset / hypothenus;
      // let cosinus = middleHorizontalOffset /hypothenus;
    }
  }

  function updateCompasses(){
    allCompasses.forEach(compass => setTimeout(() => updateCompass(compass), 500));
  }

  const tooltip = (
    <Tooltip id={`tooltip-other-tasks-same-address-${task.id}-${dockId}`}>

      Autres commandes à livrer à la même adresse
      {task.tasks_with_same_address ? "(" : null}
      {
        task.tasks_with_same_address
        ? task.tasks_with_same_address.map(
            otherTask => <span key={`1-${otherTask.id}`}>
              {otherTask.linked_order.random_id}
              <i style={{border: "solid black", borderWidth: "0 3px 3px 0", display: "inline-block", padding: "3px"}}
                 id={getCompassId(otherTask.linked_order, 1)}
              ></i>
            </span>
          ).reduce((prev, curr) => prev === null ? [curr] :[...prev, ', ', curr], null)
        : null
      }
      {task.tasks_with_same_address ? ")" : null},
      et avec un créneau qui chevauche
      {task.tasks_with_same_address_and_intersecting_slot ? "(" : null}
      {
        task.tasks_with_same_address_and_intersecting_slot
        ? task.tasks_with_same_address_and_intersecting_slot.map(
            otherTask => <span key={`1-${otherTask.id}`}>
              {otherTask.linked_order.random_id}
              <i style={{border: "solid black", borderWidth: "0 3px 3px 0", display: "inline-block", padding: "3px"}}
                 id={getCompassId(otherTask.linked_order, 2)}
              ></i>
            </span>
          ).reduce((prev, curr) => prev === null ? [curr] :[...prev, ', ', curr], null)
        : null
      }
      {task.tasks_with_same_address_and_intersecting_slot ? ")" : null}.
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      overlay={tooltip}
      transition={false}
      defaultShow={false}
      flip={false}
      onToggle={updateCompasses}
    >
      <Badge
        variant="light"
        className={"ml-1 pl-1 pr-1 mr-1"}
        style={{backgroundColor:"#8EFAE8", color: "#212529"}}
      >
        <i className="fa-solid fa-code-pull-request"></i>
      </Badge>
    </OverlayTrigger>
  );
};

OtherTasksSameAddressBadge.propTypes = {
  task: PropTypes.object.isRequired,
  dock: PropTypes.object,
};
