/* eslint-disable react/prop-types */
import React from "react";
import {Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DocumentTitle from "react-document-title";
import {SearchUser} from "./SearchUser";
import {UserOrder} from "./UserOrder";

import "../css/Support.css";



// component
class InternalSupport extends React.Component {



  render() {
    //let {  } = this.props;

    // if (!loaded) {
    //   return (<div className="DriverLogs"><div className="row"><div className="col-md-12"><h4>{' '}</h4></div></div></div>);
    // }


    // const navigateNext =  (currentDate)  => {
    //   history.push('/logs/date/' + currentDate.add(1, 'days').format('YYYY-MM-DD'));
    // };
    //
    // const navigatePrev =  (currentDate)  => {
    //   history.push('/logs/date/' + currentDate.subtract(1, 'days').format('YYYY-MM-DD'));
    // };

    // console.log('support  rendering');

    // if (!driverLogs) {
    //   driverLogs = [];
    // }

    return (
      <DocumentTitle title='Support'>
        <div className="Support">
          <Route path="/support" component={SearchUser} />
          <Route path="/support/user/:user_id/:email/" component={UserOrder} />
          <Route path="/support/order/:order_id/" component={UserOrder} />
          <Route path="/support/order-with-center/:order_id/center/:center_id/" component={UserOrder} />

        </div>
      </DocumentTitle>
    );
  }
}


// link
export const Support = withRouter(connect(
  null,
  (dispatch) => {

    return   {
      // load : (filters) => {
      //   dispatch({type:EXAMPLE, filters: filters});
      //
      // }
    };
  }
)(InternalSupport));

