import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";

import "../css/OrderDeliveryDetail.css";


const InternalOrderWarning = ({order}) => {
  let has_warning = false;
  if (!order) {
    return null;
  }
  if (order.estimated_travel_duration === 0) {
    has_warning = true;
  }
  if (order.estimated_travel_duration > 5 * 3600) {
    has_warning = true;
  }
  const refundAndCreditsExceedOrderAmount = order.amount_recredit + order.amount_refund > order.amount;
  if (refundAndCreditsExceedOrderAmount) {
    has_warning = true;
  }
  if (has_warning === false) { return null; }

  let sapinDeNoel = false;

  // La deuxième colonne servira si on ajoute un bouton d'action sur certaines alertes,
  // pour que les boutons soient bien alignés à gauche.
  return <Table size="sm" hover className="OrderWarning alert alert-danger">
    <tbody>
      <tr><th colSpan={2}>ATTENTION</th></tr>
      {
        order.estimated_travel_duration === 0 || sapinDeNoel
          ? <tr>
              <td>
                Vérifiez l'adresse de livraison car l'estimation google a échoué.
              </td>
              <td>
              </td>
            </tr>
          : null
      }
      {
        order.estimated_travel_duration > 5 * 3600 || sapinDeNoel
          ? <tr>
              <td>
                La durée de livraison estimée est supérieure à 5 heures,
                il faut sans doute corriger l'adresse de livraison.
              </td>
              <td>
              </td>
            </tr>
          : null
      }
      {
        refundAndCreditsExceedOrderAmount || sapinDeNoel
          ? <tr>
              <td>
                Le montant des crédits et remboursements dépasse le montant de
                la commande.
              </td>
              <td>
              </td>
            </tr>
          : null
      }
      {
        sapinDeNoel
          ? <tr>
              <td>
                Mangez des pommes.
              </td>
              <td>
                <Button size="xs">Miam</Button>
              </td>
            </tr>
          : null
      }
      {
        sapinDeNoel
          ? <tr>
              <td>
                Il fait beau.
              </td>
              <td>
              </td>
            </tr>
          : null
      }
    </tbody>
  </Table>
  ;
};

InternalOrderWarning.propTypes = {
  "order": PropTypes.object,
};

export const OrderWarning = connect(
  (state) => ({pseudoZones: state.support.meta ? state.support.meta.pseudoZones : [] }),
)(InternalOrderWarning);
