export const DELIVERIES_TODO_CLEAN_ROWS_SELECTED = 'DELIVERIES_TODO_CLEAN_ROWS_SELECTED';
export const DELIVERIES_TODO_ADD_ROW_SELECTED = 'DELIVERIES_TODO_ADD_ROW_SELECTED';
export const DELIVERIES_TODO_REMOVE_ROW_SELECTED = 'DELIVERIES_TODO_REMOVE_ROW_SELECTED';
export const DELIVERIES_TODO_TOGGLE_ROW_SELECTED = 'DELIVERIES_TODO_TOGGLE_ROW_SELECTED';
export const DELIVERIES_TODO_TOGGLE_DISPATCH_WAITING_TASKS = 'DELIVERIES_TODO_TOGGLE_DISPATCH_WAITING_TASKS';

export const cleanRowsSelected = () => {
  // console.log('dispatch cleanRowsSelected');
  return {
    type: DELIVERIES_TODO_CLEAN_ROWS_SELECTED,
  };
};

export const addRowSelected = (taskId) => {
  // console.log('dispatch addRowSelected');
  return {
    type: DELIVERIES_TODO_ADD_ROW_SELECTED,
    taskId: taskId,
  };
};

export const removeRowSelected = (taskId) => {
  // console.log('dispatch removeRowSelected');
  return {
    type: DELIVERIES_TODO_REMOVE_ROW_SELECTED,
    taskId: taskId,
  };
};

export const toggleRowSelected = (taskId) => {
  // console.log('dispatch toggleRowSelected');
  return {
    type: DELIVERIES_TODO_TOGGLE_ROW_SELECTED,
    taskId: taskId,
  };
};

export const toggleDispatchWaitingTasks = () => {
  return {
    type: DELIVERIES_TODO_TOGGLE_DISPATCH_WAITING_TASKS
  };
};
