import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {
    LOGS_EXTRA_COST_DEMANDS_REVIEW,
    LOGS_MODAL_SHOW,
} from "../actions";


const InternalDriverExtraCostDemand = ({demand, driver, reviewer, review, validate}) => {
  return (
    <tr>
      <td>
        {
            (new Date(demand.created_at)).toLocaleDateString()
            + ' à '
            + (new Date(demand.created_at)).toLocaleTimeString()
        }
      </td>
      <td>
        {driver ? driver.name : ''}
      </td>
      <td>
        {demand.dock ?
            <a href={`${process.env.REACT_APP_DELEEVADMIN}/livreurs/logs/group/${demand.dock}`}
               target="_blank" rel="noopener noreferrer" >Fiche</a>
            : ''
        }
      </td>
      <td>
        {demand.order ?
            <a href={`/support/order/${demand.order}`} target="_blank" rel="noopener noreferrer" >Fiche</a>
            : ''
        }
      </td>
      <td>
        {demand.extra_cost_demand_type_label}
      </td>
      <td>
        {demand.minutes}
      </td>
      <td>
        {demand.comment}
      </td>
      <td>
        {reviewer ? reviewer.first_name + ' ' + reviewer.last_name + ' (' + reviewer.email + ')' : ''}
      </td>
      <td>
        {demand.reviewed_at ? (new Date(demand.reviewed_at)).toLocaleDateString() : ''}
      </td>
      <td>
        {demand.extra_cost ? 'Oui' : 'Non'}
      </td>
      <td>
        <div className={"btn-group"}>
          <button
            className={"btn btn-xs btn-default"}
            style={{borderRadius: "3px"}}
            onClick={() => review(demand)}
          >
            Refusé
          </button>
          <button
            className={"btn btn-xs btn-success ml-3"}
            style={{borderRadius: "3px"}}
            onClick={() => validate(demand)}
          >
            Validé
          </button>
        </div>
      </td>
    </tr>
  );
};

InternalDriverExtraCostDemand.propTypes = {
  demand: PropTypes.object.isRequired,
  driver: PropTypes.object,
  reviewer: PropTypes.object,
  review: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

export const DriverExtraCostDemand = withRouter(connect(
  (state) => {
    return { };
  },
  (dispatch) => {
    return {
      review: (demand) => {
        dispatch(
          {
            type: LOGS_EXTRA_COST_DEMANDS_REVIEW,
            demandId: demand.id,
          }
        );
      },
      validate: (demand) => {
        dispatch(
          {
            "type": LOGS_MODAL_SHOW,
            "modalData": {
                "modalType": "extracost_add",
                "demand": demand,
            }
          }
        )
      }
    };
  }
)(InternalDriverExtraCostDemand));
