import React from 'react';
import {connect} from "react-redux";
import moment from  'moment-timezone';
import {Col, FormGroup, Modal, Row} from "react-bootstrap";
import { ErrorMessage, Formik, Field } from 'formik';
import {LOGS_MODAL_HIDE, LOGS_PRESENCE_ADD} from "../actions";


class InternalModalPresenceAdd extends React.Component {


  render() {
    const {modal, hide, submitForm, driver_list, filters} = this.props;

    let drivers = driver_list.sort((a, b) => a.name.localeCompare(b.name));
    let listOptions = drivers.map((option, index) => {
      return (<option key={index} value={option.id} >{option.name}</option>);
    });
    moment.locale('fr');

    return <Modal
      show={modal && modal.modalType === 'presence_add'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>Ajouter un log de présence</h4>
        <Formik
          initialValues={{
            driver_id: filters.driver_id,
            presence_date: filters.date,
            presence_hour_start: '',
            presence_hour_end: ''
          }}
          onSubmit={(values, actions) => {
            let start = values.presence_hour_start.split('h');
            let end = values.presence_hour_end.split('h');

            let started_at = moment(values.presence_date)
              .set('hour', start[0])
              .set('minute', start[1])
              .set('seconde', 0);

            let ended_at = moment(values.presence_date)
              .set('hour', end[0])
              .set('minute', end[1])
              .set('second', 0);

            let currentdate = new Date();
            
            let driver_name = '-';
            for (var i = 0; i < driver_list.length; ++i) {
              if (driver_list[i].id === parseInt(values.driver_id, 10)) {
                driver_name = driver_list[i].name;
                break;
              }
            }
            let presence_logs_data = {
              driver_id: values.driver_id,
              driver_name: driver_name,
              started_at: started_at.format('YYYY-MM-DD HH:mm:ss'),
              ended_at: ended_at.format('YYYY-MM-DD HH:mm:ss'),
              uniq_id: currentdate.getTime() + '' + currentdate.getMilliseconds()
            };
            submitForm(presence_logs_data);
          }}
          validate={(values) => {
            let errors = {}

            let hour_start = values.presence_hour_start;
            let hour_end = values.presence_hour_end;
            if (parseInt(hour_start.replace('h', ''), 10) >= parseInt(hour_end.replace('h', ''), 10)) {
              errors.presence_hour_start = "L'heure de début doit être avant l'heure de fin";
              errors.presence_hour_end = "L'heure de fin doit être après l'heure de début";
            }
            return errors;
          }}
          render={(props: FormikProps<Values>) => (
            <form onSubmit={props.handleSubmit} className="form-horizontal">
              <FormGroup as={Row}>
                <Col  sm={12}>
                  <Field as="select" name="driver_id" component="select" required={true} disabled={driver_list.length === 0 ? true : false} className="form-control">
                    <option value="">-- Veuillez sélectionner le livreur --</option>
                    {listOptions}
                  </Field>
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col  sm={4}>
                  Le <Field type='date' name="presence_date" required={true} className="form-control" />
                </Col>
                <Col  sm={4}>
                  de <Field type="text" name="presence_hour_start" required={true} className="form-control" pattern="[0-9]{1,2}h[0-9]{2}" placeholder="8h00" />
                  <span style={{color: 'red'}}><ErrorMessage name="presence_hour_start" /></span>
                </Col>
                <Col  sm={4}>
                  à <Field type="text" name="presence_hour_end" required={true} className="form-control" pattern="[0-9]{1,2}h[0-9]{2}" placeholder="18h00" />
                  <span style={{color: 'red'}}><ErrorMessage name="presence_hour_end" /></span>
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col  sm={12}>
                  <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                    CRÉER
                  </button>
                </Col>
              </FormGroup>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>;

  }
}

export const ModalPresenceAdd = connect(
  (state) => ({
    "modal" : state.logs.modal,
    "driver_list": state.logs.driver_list,
    "filters": state.logs.filters
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": LOGS_MODAL_HIDE});
      },
      "submitForm": (presencelog) => {
        dispatch({'type': LOGS_MODAL_HIDE});
        dispatch({'type': LOGS_PRESENCE_ADD, presencelog: presencelog});
      }
    };
  }
)(InternalModalPresenceAdd);
