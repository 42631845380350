import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {showPopinOrderStatDriver} from "../../../actions";

import moment from "moment/moment";

const InternalOrderStatDriver = ({order, popinStatDriver}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });

  return <tr>
    <td>Coût livraison</td>
    <td>
      {
        order.order_stat_driver && order.order_stat_driver.length
          ?
          <React.Fragment>
            {order.order_stat_driver.map((stat, key) => {
              const day = moment(stat.day).format("DD/MM/YYYY");
              return <div key={key}>
                {formatter.format(stat.total_cost_with_agency_fee)} (livré le {day} par {stat.driver ? stat.driver.name : '??'})&nbsp;
              </div>;
            })}
            <Button variant="default" size="xs" onClick={() => popinStatDriver(order)} ><i className="fa-regular fa-circle-question"></i></Button>
          </React.Fragment>
          : <span>Aucune données</span>
      }
    </td>
  </tr>;
};

InternalOrderStatDriver.propTypes = {
  "order": PropTypes.object,
  "popinStatDriver": PropTypes.func,
};

export const OrderStatDriver = connect(
  (state) => {
    return {};
  },
  (dispatch) => {
    return {
      "popinStatDriver": (order) => dispatch(showPopinOrderStatDriver(order)),
    };
  }
)(InternalOrderStatDriver);

