import {connect} from "react-redux";
import PropTypes from "prop-types";
import React from 'react';


const checkProduct = ({checks, occupationRate}) => {
  return (
    <div>
      <h4 style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '2rem' }}>
        <span>
          Check zone en attente:&nbsp;
          <span> Frais: {checks.fresh || '0'} - </span>
          <span>Secs: {checks.dry || '0'} - </span>
          <span>Primeur: {checks.prime || '0'}</span>
        </span>
        <span>Taux d&apos;occupation chariots: {(occupationRate * 100).toFixed(1)} %</span>
      </h4>
    </div>
  )
};

checkProduct.propTypes = {
  checks: PropTypes.object.isRequired,
  occupationRate: PropTypes.number,
};


const mapStateToProps = state => {
  return {
    checks: state.m.metas.check_products,
    occupationRate: state.m.metas.trolleys_occupation_rate || 0,
  };
};


let CheckProductConnected = connect(
  mapStateToProps
)(checkProduct);

export default CheckProductConnected;
