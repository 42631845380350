import {connect} from "react-redux";
import { connectTransitAreaModalAction } from "../../../../utils/transit_areas";

import TransitAreaModalContent from "../../../../components/modal/TransitAreaModalContent";


export const TransitAreaModal = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
    "pseudoZones": state.dispatch.pseudoZones,
    "transit_areas": state.dispatch.transit_areas,
    "trolleys": state.dispatch.metas.trolleys,
    "transit_area_types": state.dispatch.metas.transit_area_types,
  }),
  (dispatch) => connectTransitAreaModalAction(dispatch)
)(TransitAreaModalContent);
