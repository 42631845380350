import { call, put, takeEvery, select } from 'redux-saga/effects';
import { api, daUtil } from "../../../api";
import { dockUpdated } from '../actions/dock';

import Notifications from "react-notification-system-redux";
import * as Raven from "raven-js";

import { ORDER_UPDATED } from "../../../actions/index";
import {
  DISPATCH_LATE_WARNED,
  DISPATCH_LATE_ACTION,
  DISPATCH_LATE_ORDER_IGNORE,
  DISPATCH_LATE_ORDER_VALIDATE,
  DISPATCH_LATE_ORDER_REVIEWED,
  DISPATCH_RESET_FOR_REDELIVERY,
  DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED,
  DISPATCH_ORDER_TO_RESTOCK,
  DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED,
  DISPATCH_VALIDATE_RESTOCK,
  DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED,
  deliveryUpdateSucceeded,
  DISPATCH_ORDER_WAS_DELIVERED,
  DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED,
  DISPATCH_DIRECT_DISPATCH,
  DISPATCH_DIRECT_DISPATCH_MANY,
  deliveryUpdate,
} from "../actions/deliveries";
import { dockAdd } from '../actions/dock';
import { DISPATCH_MAIN_MODAL_HIDE } from '../actions/modal';

import { errorThrown } from "../actions/index";


function* _processing(service, data) {
  try {
    let res = yield call(api.processingOrder, service, data);
    yield put({type:ORDER_UPDATED, order: res});
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* lateAction(action) {
  yield* _processing('late_sms', {id: action.orderId, credits: action.creditToAdd, message: action.message});
  yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
}

function* lateWarned(action) {
  yield* _processing('warned', {id: action.orderId});
}

function*  ignoreLateOrder(action) {
  try {
    let { order, reviewed_at: reviewedAt } = yield call(api.ignoreLateOrder, action.orderId);
    yield put({ type:ORDER_UPDATED, order });
    yield put({ type:DISPATCH_LATE_ORDER_REVIEWED, order, reviewedAt });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }

}

function*  validateLateOrder(action) {
  try {
    const { orderId, data } = action;
    let { order , reviewed_at: reviewedAt } = yield call(api.validateLateOrder, orderId, data);
    yield put({ type:ORDER_UPDATED, order });
    yield put({ type:DISPATCH_LATE_ORDER_REVIEWED, orderId: order.id, reviewedAt });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* resetForRedelivery(action) {
  try {
    const { orderId, data } = action;
    const { order } = yield call(api.resetOrderForRedelivery, orderId, data);
    yield put(Notifications.success({title: "Succès" , message: 'La commande est finalisée et prête pour une relivraison',  autoDismiss:10, dismissible:"click"}));
    yield put({ type: DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED, order: order });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* orderToRestock(action) {
  try {
    const { orderId } = action;
    const { order, new_flag: newFlag } = yield call(api.orderToRestock, orderId);
    yield put(Notifications.success({title: "Succès" , message: 'La commande est en attente de restockage',  autoDismiss:10, dismissible:"click"}));
    yield put({ type: DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED, order, newFlag });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* validateRestock(action) {
  try {
    const { orderId } = action;
    const { order } = yield call(api.validateRestock, orderId);
    yield put(Notifications.success({title: "Succès" , message: 'Le restockage a été validé',  autoDismiss:10, dismissible:"click"}));
    yield put({ type: DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED, order });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

export function* _utilUpdateTasks(filter) {
  const state = yield select();
  const taskIds = state.dispatch.deliveries.filter(filter).map(e => e.id);
  if (taskIds.length > 0) {
    const resTasks = yield daUtil.get(`/dispatch/deliveries/?ids=${taskIds}`);
    yield put(deliveryUpdateSucceeded(resTasks));
  }
}


function* orderWasDelivered(action) {
  try {
    const { orderId } = action;
    const { order } = yield call(api.orderWasDelivered, orderId);
    yield put(
      Notifications.success(
        {
          title: "Succès" ,
          message: "La commande est passée en statut livrée.",
          autoDismiss: 10,
          dismissible: "click"
        }
      )
    );
    yield put({ type: DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED, order });
    yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(
        Notifications.error(
          {
            title: "Action impossible",
            message: error,
            autoDismiss: 10,
            dismissible: "click"
          }
        )
      );
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* orderDirectDispatch(action) {
  try {
    const { task } = action;
    yield put(deliveryUpdate([task,], true));
    const orderId = task.linked_order.id;
    const { dock } = yield call(api.orderDirectDispatch, orderId);
    yield put(dockAdd(dock));
    yield put(dockUpdated(dock));
    yield _utilUpdateTasks((e) => e.updating);
    yield put(Notifications.success({title: "Dispatch" , "message": "Commande dispatchée",  autoDismiss:2, dismissible:"click"}));
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}


function* ordersDirectDispatch(action) {
  try {
    const { tasks, define } = action;
    if(tasks.length <= 0){
      return;
    }
    let orderIds = [];
    for(let task of tasks) {
      yield put(deliveryUpdate([task,], true));
      orderIds.push(task.linked_order.id);
    }
    const { dock } = yield call(api.ordersDirectDispatch, orderIds, define);
    yield put(dockAdd(dock));
    yield put(dockUpdated(dock));
    yield _utilUpdateTasks((e) => e.updating);
    yield put(Notifications.success({title: "Dispatch" , "message": "Commandes dispatchées dans une tournée vide",  autoDismiss:2, dismissible:"click"}));
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

export const deliveriesSagas = [
  takeEvery(DISPATCH_LATE_WARNED, lateWarned),
  takeEvery(DISPATCH_LATE_ACTION, lateAction),
  takeEvery(DISPATCH_LATE_ORDER_IGNORE, ignoreLateOrder),
  takeEvery(DISPATCH_LATE_ORDER_VALIDATE, validateLateOrder),
  takeEvery(DISPATCH_RESET_FOR_REDELIVERY, resetForRedelivery),
  takeEvery(DISPATCH_ORDER_TO_RESTOCK, orderToRestock),
  takeEvery(DISPATCH_VALIDATE_RESTOCK, validateRestock),
  takeEvery(DISPATCH_ORDER_WAS_DELIVERED, orderWasDelivered),
  takeEvery(DISPATCH_DIRECT_DISPATCH, orderDirectDispatch),
  takeEvery(DISPATCH_DIRECT_DISPATCH_MANY, ordersDirectDispatch),
];
