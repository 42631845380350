import React, { Component } from 'react';
import {DeliveryRow} from "./DeliveryRow";
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";

import "../css/DeliveryList.css";

class DeliveryList extends Component {

  render() {
    const { title, className, show, canDispatchWaitingTasks } = this.props;
    let tasks = this.props.tasks;
    const nbTask = tasks.length;
    const h4 = <h4>{title} : {nbTask ? nbTask: "AUCUNE"}</h4>;


    if (nbTask === 0) {
      return (show.nothingIfEmpty ? null : <div>{h4}</div>);
    }
    if (canDispatchWaitingTasks === false) {
      tasks = tasks.filter(item => item.status !== 'waiting');
    }
    return (
      <div className={className + ' DeliveryList'}>
        <h4>{title} {nbTask}</h4>
        <Table bordered size="sm" hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Sacs</th>
              <th>Z.</th>
              <th>Client</th>
              <th>Adresse</th>
              {show.driver ? (<th>Livreur</th>) : null}
              <th></th>
              {show.status ? (<th>État</th>) : null}
              {show.sms ? (<th></th>) : null}
              {show.late ? (<th></th>) : null}
              {show.externalCall ? (<th></th>) : null}
            </tr>
          </thead>
          <tbody>
            {tasks.map(t => (
              <DeliveryRow key={t.id} task={t}
                showMenu={show.menu}
                showDriver={show.driver}
                showStatus={show.status}
                showSms={show.sms}
                showLate={show.late}
                showExternalCall={show.externalCall}
                showToRestock={show.toRestock}
                addressFormatting={show.addressFormatting}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}


DeliveryList.propTypes = {
  tasks: PropTypes.array.isRequired,
  title:  PropTypes.string.isRequired,
  className: PropTypes.string,
  show: PropTypes.object,
  canDispatchWaitingTasks: PropTypes.bool
};


export default DeliveryList;
