import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {Address} from "../../../../../components/Common";
import {showPopinOrderAddress} from "../../../actions";


class InternalAddressRow extends React.Component {


  render() {
    const {address, user, popinAddress} = this.props;

    return <tr key={address.id}>
      <td title={`ProfileAddress: #${address.id}`}><Address address={address} format="no_link" center_id={0} /></td>
      <td>
        <a href={`${process.env.REACT_APP_ADMIN_B2B}/canteens/${address.canteen.id}`} target='_blank' rel="noopener noreferrer">
          {address.canteen.name}
        </a>
      </td>
      <td>{address.canteen.site}</td>
      <td><Button size="xs" varient="default" onClick={() => popinAddress(address, user.id)}><i className="fa-solid fa-pen-to-square"></i> Modifier</Button></td>
    </tr>;

  }
}

InternalAddressRow.propTypes = {
  "address": PropTypes.object,
  "user": PropTypes.object,
  "popinAddress": PropTypes.func,
};

export const AddressRow = connect(
  (state) => {
    return {
      user : state.support.user,
    };
  },
  (dispatch) => {
    return {
      "popinAddress": (address, user_id) => dispatch(
        showPopinOrderAddress(address, "Modifier l'adresse", user_id, "user_address")
      ),
    };
  }
)(InternalAddressRow);
