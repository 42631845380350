import {connect} from "react-redux";
import moment from "moment";
import 'moment/locale/fr';
import {stateToCenterId} from "../utils/center";
import PackingLaterList from "../components/PackingLaterList";
import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {currentToggleValue, PACKING_LATER_LIST_KEY} from "../utils/toggle";
import {getSortedPackingLater} from "../selectors/packing";

moment.locale('fr');

const mapStateToProps = state => {

  let tasks = getSortedPackingLater(state);

  let days = [];
  let dayStruct = {};
  //tasks.reduce((prev, cur) => cur.day < prev.day ? cur.day : prev.day, '9999-99-99');
  //let currentDay = tasks.length > 0 ? tasks[0]['day'] : null;
  let previousDay = null;
  let center_id = stateToCenterId(state);

  for (const t of tasks) {

    if (previousDay !== t.day) {
      if (previousDay) {
        days.push(dayStruct);
      }

      let stats = state.m.metas.loads_future[t.day];
      dayStruct = {
        "label": moment(t.day).format('dddd D MMMM'),
        "day": t.day,
        "countOrders": stats ? stats.orders : null,
        "countItems": stats ? stats.items : null,
        "productLink": `${process.env.REACT_APP_DELEEVADMIN}/queue/day_by_day?day=${t.day}&center_id=${center_id}`,
        "tasks": [],
      };
    }

    dayStruct['tasks'].push(t);
    previousDay = t.day;
  }

  // last day
  if (dayStruct['tasks'] && dayStruct['tasks'].length > 0) {
    days.push(dayStruct);
  }

  return {
    showTable: currentToggleValue(state.toggle, PACKING_LATER_LIST_KEY),
    days: days
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "toggleTable": () => dispatch({type: SWITCH_TOGGLE, key: PACKING_LATER_LIST_KEY}),
  };
};

let PackingLaterListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingLaterList);


export default PackingLaterListLinked;
