
export function buildTypeValueInternal(task_type, external_id) {
  if (external_id === null && task_type === null) {
    return '';
  }

  external_id = external_id === null ? 0 : external_id;
  task_type = task_type === null ? "" : task_type;


  return `${task_type}-${external_id}`;

}

export function buildTypeValue(next) {
  let external_id = next.external_id;
  let next_task_type = next.next_task_type;
  return buildTypeValueInternal(next_task_type, external_id)
}

export function parseTypeValue(value) {
  let [next_task_type, external_id] = value.split('-');
  external_id = !external_id ? null : parseInt(external_id, 10);
  next_task_type = !next_task_type ? null : parseInt(next_task_type, 10);
  return {
    "next_task_type": next_task_type,
    "external_id": external_id
  };
}
