import React from 'react';
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import {connect} from "react-redux";
import {DepositUserCreateReceived} from "./DepositUserCreateReceived";
import {act_loadDriverList} from  "../../../actions";

const Internal = ({deposit_current, user, drivers, loadDriverList}) => {

  if (drivers.datas.length === 0 && drivers.loading === false) {
    loadDriverList();
  }
  if (deposit_current.loading === true) {
    return <div className='DepositUserCurrent text-center'><br />Chargement...</div>;
  }
  let has_permission_create = false;
  if (user.groups && (user.groups.is_manager || user.groups.is_support)) {
    has_permission_create = true;
  }

  return <div className='DepositUserCurrent'>
    { deposit_current.datas && deposit_current.datas.length > 0 && 
      <div>
        <strong>Liste des consignes non rendues</strong>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Qté en sa posession</th>
              {has_permission_create &&
                <th></th>
              }
            </tr>
          </thead>
          <tbody>
            {deposit_current.datas.map((deposit)=> {
              return <tr key={deposit.product_id}>
                <td>
                  <img src={deposit.product.picture_thumbnail_url} alt={deposit.product.deposit_label} width="50" />
                  <a href={process.env.REACT_APP_PRODUCTS + `/products/${deposit.product_id}`} target="_blank" rel="noopener noreferrer" >{deposit.product.deposit_label}</a>&nbsp;
                </td>
                <td>{deposit.quantity}</td>
                {has_permission_create && deposit.quantity > 0 &&
                  <td><DepositUserCreateReceived deposit_current={deposit} /></td>
                }
              </tr>;
            })}
          </tbody>
        </Table>
      </div>
    }
    {deposit_current.datas && deposit_current.datas.length === 0 && <div className="alert alert-info">Le client a rendu toutes ses consignes.</div>}
  </div>;
};

Internal.propTypes = {
  deposit_current: PropTypes.object,
  user: PropTypes.object,
  drivers: PropTypes.object.isRequired,
  loadDriverList: PropTypes.func.isRequired,
};

export const DepositUserCurrent = connect(
  (state) => {
    return { 
      "drivers": state.support.drivers,
      "user": state.user
    };
  },
  (dispatch) => {
    return {
      loadDriverList: () => {
        return dispatch(act_loadDriverList());
      },
    };
  }
)(Internal);
