import React from 'react';
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import moment from "moment/moment";

export const OrderProductEmptyWarning = ({order}) => {
    if (order.products.length > 0) {
      return '';
    }
    return <div className="mt-4 mb-4">{order.tags.includes('redelivering') &&
        <div className="alert alert-warning">
            Cette commande est une relivraison d'une commande existante.&nbsp;
        </div>
    }</div>;
};


export const OrderProductsEmpty = ({order}) => {
  moment.locale('fr');

  if (order.products.length > 0) {
    return '';
  }
  return <div>
      <Table size="sm" hover className="OrderProductsEmpty">
      <tbody>
        <tr><th colSpan={2}>Commande parente</th></tr>
        <tr>
          <td>
            {order.tags.includes('redelivering') && <span>Cette commande est une relivraison d'une commande existante.&nbsp;</span>}
            {order.parent_order &&
              <span>Aller à la commande originale:&nbsp;</span>
            }
          </td>
          <td>
            <a href={`/support/order/${order.parent_order}`} target="_blank" rel="noopener noreferrer">{order.parent_order}</a>
          </td>
        </tr>
      </tbody>
    </Table>
  </div>;
};

OrderProductsEmpty.propTypes = {
  "order": PropTypes.object,
};

OrderProductsEmpty.propTypes = {
  "order": PropTypes.object,
};