import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {AddressesList} from "./UserAddresses/AddressesList";

class InternalUserAddresses extends React.Component {

  render() {
    const {user} = this.props;
    if (!user || !user.id) return <span></span>;

    return <AddressesList user={user} />;
  }
}

InternalUserAddresses.propTypes = {
  "user": PropTypes.object,
};

export const UserAddresses = connect(
  (state) => ({
    "user" : state.support.user
  })
)(InternalUserAddresses);
