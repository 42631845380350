import React from 'react';
import {connect} from "react-redux";
import {Modal, Table} from "react-bootstrap";

import moment from "moment/moment";
import {SUPPORT_MODAL_HIDE_REFUND, validateRefund} from "../../../actions";

import { RefundOrderForm } from "./FormikRefundOrderForm";
import {getRefundOrderSubTypeList} from "../../../../../selectors/refund_modal";
import {rd} from "../../../../../utils";

const paymentTypes = ['edenred', 'paygreen', 'stripe'];
const sortPaymentDetails = (a, b) => {
  const aScore = paymentTypes.indexOf(a.type);
  const bScore = paymentTypes.indexOf(b.type);
  return aScore - bScore;
};

const InternalRefundModal = function ({ sub_type_list, modal, hide, validate }) {

  moment.locale('fr');
  if (!modal || !modal.order) {
    return null;
  }

  const { order } = modal;
  const [refundAmount, setRefundAmount] = React.useState(0.0);

  const refundPaymentDetails = React.useMemo(() => {
    let leftToRefund = refundAmount;
    const details = [];

    for (const pd of order.payment_details.sort(sortPaymentDetails)) {
      if (pd.refundable_amount) {
        details.push({
          type: pd.type,
          amount: rd(Math.min(pd.refundable_amount, leftToRefund)),
        });
        leftToRefund -= pd.amount;
        if (leftToRefund <= 0) {
          break;
        }
      }
    }
    return details;
  }, [refundAmount, order.payment_details]);

  return <Modal
    show={modal && modal.show}
    animation={false}
    backdrop={true}
    onHide={() => hide()}
    keyboard={true}
    // size={"sm"}
  >
    <Modal.Body>
      <h4>{modal.label}</h4>
      {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
      }
      {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
      }
      <div>
          Montant de la commande : {rd(order.amount)}€
        <br />
          Montant remboursé : {rd(order.amount - order.refundable_amount)}€
        <br />
          Montant recrédité : {rd(order.amount_recredit)}€
      </div>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Type</th>
            <th>Montant</th>
            <th>Montant Remboursé</th>
            <th>Remboursable</th>
          </tr>
        </thead>
        <tbody>
          {order.payment_details.map(pd => (
            <tr key={pd.type}>
              <th>{pd.type}</th>
              <td>{pd.amount}€</td>
              <td>{pd.refunded_amount}€</td>
              <td>
                {pd.is_refundable ? 'Oui' : 'Non'}
                {pd.type === 'edenred' && pd.refunded_amount !== pd.amount && (pd.edenred && !pd.edenred.is_token_valid)
                  ? (
                    <React.Fragment>
                      <br />
                      <span style={{paddingLeft: 2}}>
                          (L'utilisateur ne peut pas être remboursé tant qu'il ne s'est pas connecté sur Edenred via notre site. Vous pouvez lui dire de se connecter via ce <a href={pd.edenred.login_link} target="_blank" rel="noopener noreferrer">lien</a>.)
                      </span>
                    </React.Fragment>
                  )
                  : null}
              </td>
            </tr>
          )
          )}
        </tbody>
      </Table>
      <RefundOrderForm
        sub_type_list={sub_type_list}
        quantity={0} // default quantity
        labelQuantity={modal.labelQuantity} //default value
        validate={(values) => {
          console.log("validate refund form", values);
          validate(values, modal);
        }}
        order={modal.order}
        minQuantity={0}
        maxQuantity ={rd(order.refundable_amount) - rd(order.amount_recredit)}
        setRefundAmount={setRefundAmount}
      />
      {refundAmount ? (<React.Fragment>
        <h4>Montant à rembourser : {rd(refundAmount)}€</h4>
        <div className="payment-details">
          <Table striped bordered size="sm">
            <thead>
              <tr>
                <th>Type</th>
                <th>Montant qui va être remboursé</th>
              </tr>
            </thead>
            <tbody>
              {refundPaymentDetails.map(pd => (
                <tr key={pd.type}>
                  <th>{pd.type}</th>
                  <td>{pd.amount}€</td>
                </tr>
              )
              )}
            </tbody>
          </Table>
        </div>
      </React.Fragment>) : null}
    </Modal.Body>
  </Modal>;
};

export const RefundOrderModal = connect(
  (state) => ({
    "modal" : state.support.modalRefund,
    "sub_type_list": getRefundOrderSubTypeList(state),
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_REFUND});
      },
      "validate": (values, modal) => {
        console.log(values);
        dispatch(validateRefund(
          values.quantity,
          values.subtype,
          values.notes,
          modal
        ));
      }
    };
  }
)(InternalRefundModal);
