import {buildDockConfig, DOCK_TYPE_HARD_ASSIGNED, isAssignable} from "../../utils/dock_n_driver";
import {isLock as isLocked} from "../../utils/task";
import {cancelStuart, dockDropOnDefault, dockDropOnTask, dropOnDock} from "../../actions";
import {currentToggleValue, DELIVERING_LIST_KEY, DELIVERY_TODO_LIST_KEY} from "../../utils/toggle";
import {SWITCH_TOGGLE} from "../../actions/switch_toggle";

const SHOW_OBJECT_MENU_FALSE = {menu:false};
const SHOW_OBJECT_MENU_TRUE = {menu:true};

// const oldDockTodoSort = (a, b ) => {
//   if (a.dock_type === b.dock_type && a.dock_type === 'DRIVER_HARD') {
//     return (a.target_name <= b.target_name ? -1 : 1);
//   }
//   if (SORT_BY_TYPE[a.dock_type] > SORT_BY_TYPE[b.dock_type]) {
//     return -1;
//   } else if (SORT_BY_TYPE[a.dock_type] < SORT_BY_TYPE[b.dock_type]) {
//     return 1;
//   } else {
//     return (a.id <= b.id ? -1 : 1);
//   }
// };

const dockTodoSort = (a, b) => {
  if (a.driver && b.driver && a.dock_type === DOCK_TYPE_HARD_ASSIGNED && b.dock_type === DOCK_TYPE_HARD_ASSIGNED) {
    return (a.target_name <= b.target_name ? -1 : 1);
  }

  if (a.dock_type === DOCK_TYPE_HARD_ASSIGNED && b.dock_type !== DOCK_TYPE_HARD_ASSIGNED) {
    return -1;
  }
  if (a.dock_type !== DOCK_TYPE_HARD_ASSIGNED && b.dock_type === DOCK_TYPE_HARD_ASSIGNED) {
    return 1;
  }

  // if (a.driver && !b.driver) {
  //   return -1;
  // }
  if (a.dock_type !== DOCK_TYPE_HARD_ASSIGNED && b.dock_type !== DOCK_TYPE_HARD_ASSIGNED) {
    if (a.loaded_at && !b.loaded_at) {
      return -1;
    }

    if (!a.loaded_at && !b.loaded_at) {
      if (a.defined_at && !b.defined_at) {
        return -1;
      }
      // if (a.loaded_task && a.loaded_task.started_at && (!b.loaded_task || !b.loaded_task.started_at)) {
      //   return -1;
      // }

      // if (a.tasks.length > 0 && b.tasks.length === 0) {
      //   return -1;
      // }

    }
  }

};



export const dockListEventHandler = (dispatch) => {
  let handlers = {
    "onDropDefault":        (dock, droppedTask) => dispatch(dockDropOnDefault(dock, droppedTask)),
    "onDropTask":           (targetTask, droppedTask) => dispatch(dockDropOnTask(targetTask, droppedTask)),
    "onDropDock":           (targetDock, droppedDock) => dispatch(dropOnDock(targetDock, droppedDock)),
    "onToggle":             () => dispatch({type: SWITCH_TOGGLE, key: DELIVERY_TODO_LIST_KEY}),
  };
  return handlers;
};

export const computeDockList = (dock_list, deliveries, docks, drivers) => {
  dock_list = dock_list
      .map(d => Object.assign({}, d , {configChoices : buildDockConfig(d, docks, drivers)}))
      .map(d => ({...d, assignable: isAssignable(d, docks)}))
      .sort(dockTodoSort)
  for (let d of dock_list) {
    const isLock = isLocked(deliveries, d);

    if (isLock || !!d.defined_at) {
      d.isLock = isLock;
      for (let t of d.tasks) {
        // si dock defined, on verrouille le dnd de tâche
        t.isLock = isLock || !(d.loaded_at &&  !d.locked_at);
      }
    }
    //d.tasks = filterAndSortDockTasks(deliveries, d.id);

    //console.log("DockListBasketLinked mapStateToProps", d.tasks);
  }
  return dock_list;
}

export const dockListProps = (state, dock_list) => {
  return {
    title: "À livrer",
    "showAll": currentToggleValue(state.toggle, DELIVERY_TODO_LIST_KEY),
    className: 'DockList deliveries-todo',
    show: SHOW_OBJECT_MENU_FALSE,
    docks: dock_list,
  };
};

export const computeDockListDeliveries = (dock_list, deliveries) => {
  dock_list = dock_list
      .filter(d => d.tasks.length > 0)
      .sort((a, b ) => {
        return (a.id <= b.id ? -1 : 1);
      })
  for (let d of dock_list) {
    const isLock = isLocked(deliveries, d);
    for (let t of d.tasks) {
      t.isLock = isLock;
    }
  }
  return dock_list;
}

export const dockListDeliveriesProps = (state, dock_list) => {
  return {
    docks: dock_list,
    title: "En livraison",
    className: 'DockList deliveries-in-progress',
    show: SHOW_OBJECT_MENU_TRUE,
    "showAll": currentToggleValue(state.toggle, DELIVERING_LIST_KEY),
  };
};

export const dockListDeliveriesHandlers = dispatch => {
  return {
    "onCancelStuart":       (dock) => dispatch(cancelStuart(dock)),
    "onToggle":             () => dispatch({type: SWITCH_TOGGLE, key: DELIVERING_LIST_KEY}),
  };
};
