import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Badge, Button, Table } from "react-bootstrap";

import moment from "moment/moment";
import { Address } from "../../../../../components/Common";
import { slotLabel } from "../../../../../utils/task";

import "../../../css/OrderDeliveryDetail.css";
import { showPopinSlot } from "../../../actions";
import { showPopinOrderAddress } from "../../../actions";
import { showPopinOrderAddressName, showPopinOrderAddressPhone } from "../../../actions";
import { showPopinConfirm, updateOrder } from "../../../actions";
import { recreditShippingFeeAction, refundShippingFeeAction } from "../../../actions";
import { OrderStatDriver } from "./StatDriver";

const InternalOrderDeliveryDetail = ({
  order,
  popinSlot,
  popinAddress,
  popinAddressName,
  popinAddressPhone,
  popinConfirm,
  refundDisabled,
  recreditShippingFee,
  refundShippingFee,
  readOnly,
}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');

  const info = order.shipping_address.additional_information;
  const label = slotLabel(order);
  const deliveredDate = moment(order.shipping_time).tz("Europe/Paris").format("dddd DD.MM.YYYY");
  const changeOptionTranquility = function () {
    popinConfirm(
      'Êtes vous sûr de modifier l\'option tranquilité ?',
      updateOrder(
        order.id,
        {
          'option_tranquility': !order.option_tranquility
        }
      )
    );
  };

  return <Table size="sm" hover className="OrderDeliveryDetail">
    <tbody>
      <tr><th colSpan={2}>Info de livraison</th></tr>
      <tr>
        <td>
          {deliveredDate} {label} {order.canteen_id && <Badge className={'badge-danger'} size="xs"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Créneau cantine</Badge>}
        </td>
        <td>
          <Button variant="primary" size="xs" onClick={() => popinSlot(order)}
            disabled={order.updating}>Changer le créneau</Button>
        </td>
      </tr>
      <tr>
        <td>{order.shipping_address.company} {order.shipping_address.firstname} {order.shipping_address.lastname}
          {
            order.canteen_id
              ? <a target="_blank" href={`${process.env.REACT_APP_ADMIN_B2B}/canteens/${order.canteen_id}`} rel="noopener noreferrer">
                [Cantine "{order.canteen_name}"]
              </a>
              : null
          }
        </td>
        <td><Button variant="primary" size="xs" onClick={() => popinAddressName(order.shipping_address)} disabled={readOnly || order.updating}>Changer nom/prénom</Button></td>
      </tr>
      <tr>
        <td>{order.shipping_address.phone}</td>
        <td><Button variant="primary" size="xs" onClick={() => popinAddressPhone(order.shipping_address)} disabled={readOnly || order.updating}>Changer le téléphone</Button></td>
      </tr>
      <tr className={order.shipping_address.latitude === null ? "invalidAddress" : ""}>
        <td><Address address={order.shipping_address} center_id={order.center} /></td>
        <td><Button variant="primary" size="xs" onClick={() => popinAddress(order.shipping_address, order.user)} disabled={readOnly || order.updating || order.canteen || order.canteen_id}>{`Changer l'adresse`}</Button></td>
      </tr>
      <tr>
        <td className={info ? "" : "empty-info"}>
          {info ? info : "Info de livraison vide"}</td>
        <td><Button variant="primary" size="xs" onClick={() => popinAddress(order.shipping_address, order.user)} disabled={readOnly || order.updating || order.canteen || order.canteen_id}>Changer infos</Button></td>
      </tr>
      <tr>
        {order.option_tranquility && <td className="alert alert-warning"><strong>Option Tranquilité</strong> : sonner une fois à la porte du domicile puis déposer la commande sur le palier</td>}
        {!order.option_tranquility && <td>Option Tranquilité</td>}
        <td>

          <label className="super_checkbox">
            <input type="checkbox" className="super_checkbox" checked={order.option_tranquility} onChange={changeOptionTranquility} />
            <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
          </label>
        </td>
      </tr>
      <tr>
        <td>Calcul du créneau initial</td>
        <td>{order.initial_slot_description ? order.initial_slot_description : '-'}</td>
      </tr>
      <tr>
        <td>Calcul du créneau courant</td>
        <td>{order.slot_description ? order.slot_description : '-'}</td>
      </tr>
      <tr>
        <td>Frais de livraison payés</td>
        <td>
          {order.shipping_fee_refund_or_recredit ?
            <span>0 € (<span style={{ "textDecoration": "line-through" }}>{order.shipping_fee} €</span>)</span>
            : <span>{order.shipping_fee} {order.shipping_fee !== null ? '€' : ''}
              <Button className="ml-2" disabled={readOnly || order.updating || order.is_uber_eats_order} variant="primary" size="xs"
                onClick={() => recreditShippingFee(order)}>Recrédit</Button>
              <Button className="ml-2" disabled={readOnly || refundDisabled || order.updating} variant="warning" size="xs"
                onClick={() => refundShippingFee(order)}>Remboursement</Button>
            </span>
          }
        </td>
      </tr>
      <tr>
        <td>Frais de livraison de base</td>
        <td>{order.slot_shipping_fee} {order.slot_shipping_fee !== null ? '€' : ''}</td>
      </tr>
      <tr>
        <td>Frais de livraison extra</td>
        <td>{order.slot_extra_shipping_fee} {order.slot_extra_shipping_fee !== null ? '€' : ''}</td>
      </tr>
      <tr>
        <td>Diagnostique livraison Quick</td>
        <td>
          {order.packingtodelivery_description
            ? order.packingtodelivery_description
            : order.status === 'received' ? 'Non' : '-'
          }
        </td>
      </tr>
      <tr>
        <td>Photo de preuve de livraison</td>
        <td>
          {order.shipping_proof_picture ? (
            <a href={order.shipping_proof_picture} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" size="xs">Lien vers l&apos;image</Button>
            </a>
          ) : '-'}
        </td>
      </tr>
      <tr>

      </tr>
      <OrderStatDriver order={order} />
    </tbody>
  </Table>;
};

InternalOrderDeliveryDetail.propTypes = {
  "order": PropTypes.object,
  "popinSlot": PropTypes.func,
  "popinAddress": PropTypes.func,
  "popinAddressName": PropTypes.func,
  "popinAddressPhone": PropTypes.func,
  "popinConfirm": PropTypes.func,
  "refundDisabled": PropTypes.bool,
  "recreditShippingFee": PropTypes.func,
  "refundShippingFee": PropTypes.func,
  readOnly: PropTypes.bool,
};

export const OrderDeliveryDetail = connect(
  (state, ownProp) => {
    return {
      refundDisabled: (ownProp.order.payment_method !== "stripe"),
    };
  },
  (dispatch) => {
    return {
      "popinSlot": (order) => dispatch(showPopinSlot(order)),
      "popinAddress": (address, user_id) => dispatch(showPopinOrderAddress(address, "Modifier l'adresse de livraison", user_id)),
      "popinAddressName": (address) => dispatch(showPopinOrderAddressName(address)),
      "popinAddressPhone": (address) => dispatch(showPopinOrderAddressPhone(address)),
      "popinConfirm": (msg, dispatchDatas) => dispatch(showPopinConfirm(msg, dispatchDatas)),
      "recreditShippingFee": (order) => dispatch(recreditShippingFeeAction(order.id)),
      "refundShippingFee": (order) => dispatch(refundShippingFeeAction(order.id)),
    };
  }
)(InternalOrderDeliveryDetail);

