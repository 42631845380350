import {connect} from "react-redux";
import { ExtraDelayContent } from "../../../../components/ExtraDelayContent";
import {
  METAS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  METAS_DQP_COURIER_ENABLED_SAVE,
} from "../../../../actions";
import {
  DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE
} from "../../actions";

// link
export const ExtraDelay = connect(
  (state) => {
    return { dqp : state.dispatch.metas.extra_delay , updating : state.dispatch.metas.updating, type: 'dqp' };
  },
  (dispatch) => {
    return {
      save : (value) => {
        dispatch({type: METAS_EXTRA_DELAY_SAVE, value: value});
      }
    };
  }
)(ExtraDelayContent);


export const FrichtiExtraDelay = connect(
  (state) => {
    return { 
      dqp : state.dispatch.metas.frichti_extra_delay,
      updating : state.dispatch.metas.updating,
      type: 'frichti_dqp'
    };
  },
  (dispatch) => {
    return {
      save : (value) => {
        dispatch({type: DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE, value: value});
      }
    };
  }
)(ExtraDelayContent);

export const FrichtiExpressPrioExtraDelay = connect(
  (state) => {
    return {
      dqp: state.dispatch.metas.frichti_express_prio_extra_delay,
      updating: state.m.metas.updating,
      type: 'frichti_express_prio_dqp',
      withEnabled: true,
      enabledValue: state.dispatch.metas.frichti_dqp_express_prio_enabled,
    };
  },
  (dispatch) => {
    return {
      save: (value) => {
        dispatch({type: DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE, value: value});
      },
      saveEnabled: (value) => {
        dispatch({type: DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE, value: value});
      },
    };
  }
)(ExtraDelayContent);

export const ExpressExtraDelay = connect(
  (state) => {
    return { dqp : state.dispatch.metas.express_extra_delay , updating : state.dispatch.metas.updating, type: 'express_dqp' };
  },
  (dispatch) => {
    return {
      save : (value) => {
        dispatch({type: METAS_EXPRESS_EXTRA_DELAY_SAVE, value: value});
      }
    };
  }
)(ExtraDelayContent);


export const ExpressPrioExtraDelay = connect(
  (state) => {
    return {
      dqp: state.dispatch.metas.express_prio_extra_delay,
      updating: state.dispatch.metas.updating,
      type: 'express_prio_dqp',
      withEnabled: true,
      enabledValue: state.dispatch.metas.dqp_express_prio_enabled,
    };
  },
  (dispatch) => {
    return {
      save: (value) => {
        dispatch({type: METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE, value: value});
      },
      saveEnabled: (value) => {
        dispatch({type: METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE, value: value});
      },
    };
  }
)(ExtraDelayContent);


export const CourierExtraDelay = connect(
  (state) => {
    return {
      dqp: state.dispatch.metas.express_prio_extra_delay,
      updating: state.dispatch.metas.updating,
      type: 'dqp_courier',
      withEnabled: true,
      enabledValue: state.dispatch.metas.dqp_courier_enabled,
      hideValue: true,
    };
  },
  (dispatch) => {
    return {
      save: (value) => {
      },
      saveEnabled: (value) => {
        dispatch({type: METAS_DQP_COURIER_ENABLED_SAVE, value: value});
      },
    };
  }
)(ExtraDelayContent);
