import Notifications from "react-notification-system-redux";
import * as Raven from "raven-js";
import { call, put, takeEvery, select } from 'redux-saga/effects';
import {cloneAndPluck, findByid} from "../../../utils/index";
import { api, daUtil } from "../../../api";

import {
  deliveryUpdateSucceeded,
} from "../actions/deliveries";

import {
  DISPATCH_DOCK_DROP_ON_DEFAULT,
  DISPATCH_DOCK_DROP_ON_DELIVERY_TASK,
  DISPATCH_DROP_ON_DELIVERY_TASK,
  DISPATCH_DROP_ON_DEFAULT,
  DISPATCH_DOCK_DROP,
  DISPATCH_CANCEL_DELIVERY_DROP,
} from "../actions/drag_n_drop";

import { dockUpdated, dockLoad } from "../actions/dock";

import {
  FIELDS_DELIVERY,
  _alertOnError,
  errorThrown,
} from "../actions/index";

import { _utilUpdateTasks } from "./deliveries";

function* dropDelivery(action) {
  console.log("Sagas drag_n_drop dropDelivery", action);
  const state = yield select();

  const dockFrom = action.dockFrom;
  const dockTarget = action.dockTarget;
  const task = Object.assign({}, findByid(state.dispatch.deliveries, action.droppedTaskId));

  let toUpdate = state.dispatch.deliveries
    .filter(delivery => delivery.updating === task.id)
    .map(delivery => {
      return cloneAndPluck(delivery, FIELDS_DELIVERY);
    });

  if (action.droppedOrderPatch && action.droppedOrderPatch.id) {
    yield call(api.editOrder, action.droppedOrderPatch);
  }

  if (dockFrom && dockFrom.id) {
    toUpdate = toUpdate.map(delivery => {
      if (delivery.id === action.droppedTaskId) {
        delivery.from_dock_id = dockFrom.id;
      }
      else {
        delivery.from_dock_id = delivery.dock_target_id;
      }
      return delivery;
    });
  }

  try {
    let res = yield daUtil.post(`/dispatch/deliveries/move_task/`, toUpdate);

    if (res.error) {
      yield call(_alertOnError, res);
      // On essaye quand même de repartir propre en rafraîchissant les docks.
      if (dockFrom && dockFrom.id) {
        yield put(dockLoad(dockFrom.id));
      }
      if (dockTarget && dockTarget.id) {
        yield put(dockLoad(dockTarget.id));
      }
      yield* _utilUpdateTasks(e => e.updating);
      return;
    }

    yield put(deliveryUpdateSucceeded(res));
    if (dockFrom && dockFrom.id) {
      yield put(dockLoad(dockFrom.id));
    }
    if (dockTarget && dockTarget.id) {
      yield put(dockLoad(dockTarget.id));
    }
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
    yield* _utilUpdateTasks((e) => e.updating);
  }
}

function* dropDock(action) {
  //console.log("Sagas drag_n_drop dropDock", action);
  const state = yield select();
  const srcDock = findByid(state.dispatch.docks, action.droppedDockId);
  let destDock = findByid(state.dispatch.docks, action.targetDockId);

  let res;
  try {
    res = yield call(
      daUtil.post,
      `/dispatch/docks/${srcDock.id}/move_dock/`,
      { dock_target_id: destDock.id, target_should_be_empty: action.targetShouldBeEmpty },
      { timeout: 120000 }
    );

    if (res.error) {
      yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }


  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));

    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }

  yield* _utilUpdateTasks((e) => e.updating);
  yield put(dockLoad(destDock.id));
  yield put(dockLoad(srcDock.id));
}

function* cancelDeliveryDrop(action) {
  //console.log("Sagas drag_n_drop dropDock", action);
  const dock = action.task.dock;
  const dock_target = action.task.dock_target;

  let res;
  try {
    res = yield daUtil.post(`/dispatch/deliveries/${action.task.id}/cancel_move/`);
    if (res.error) {
      res = yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      if (dock) {
        yield put(dockUpdated(dock));
      }
      if (dock_target) {
        yield put(dockUpdated(dock_target));
      }

    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }

  yield put(deliveryUpdateSucceeded([res]));

  if (dock) {
    let destDockUpdated = yield call(daUtil.get, `/docks/${dock.id}/`);
    yield put(dockUpdated(destDockUpdated));
  }
  if (dock_target) {
    let srcDockUpdated = yield call(daUtil.get, `/docks/${dock_target.id}/`);
    yield put(dockUpdated(srcDockUpdated));
  }
}

export const dragNDropSagas = [
  takeEvery(DISPATCH_DOCK_DROP_ON_DEFAULT, dropDelivery),
  takeEvery(DISPATCH_DOCK_DROP_ON_DELIVERY_TASK, dropDelivery),
  takeEvery(DISPATCH_DROP_ON_DEFAULT, dropDelivery),
  takeEvery(DISPATCH_DROP_ON_DELIVERY_TASK, dropDelivery),
  takeEvery(DISPATCH_DOCK_DROP, dropDock),
  takeEvery(DISPATCH_CANCEL_DELIVERY_DROP, cancelDeliveryDrop),
];
