import {SKIP} from "../../actions";

export const SUPPORT_USER_LOAD = 'SUPPORT_USER_LOAD';
export const SUPPORT_USER_LOAD_BY_ORDER = 'SUPPORT_USER_LOAD_BY_ORDER';
export const SUPPORT_USER_LOADED = 'SUPPORT_USER_LOADED';

export const SEARCH_USER_CHANGE = 'SEARCH_USER_CHANGE';
export const SEARCH_USER_FILTER = 'SEARCH_USER_FILTER';

export const SUPPORT_MODAL_SHOW_CREDIT = 'SUPPORT_MODAL_SHOW_CREDIT';
export const SUPPORT_MODAL_HIDE_CREDIT = 'SUPPORT_MODAL_HIDE_CREDIT';
export const SUPPORT_MODAL_VALIDATE_CREDIT = 'SUPPORT_MODAL_VALIDATE_CREDIT';
export const SUPPORT_MODAL_VALIDATE_CREDIT_BULK = 'SUPPORT_MODAL_VALIDATE_CREDIT_BULK';
export const SUPPORT_MODAL_VALIDATE_CHANGE_STATUS = 'SUPPORT_MODAL_VALIDATE_CHANGE_STATUS';
export const SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT = 'SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT';
export const SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT = 'SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT';

export const SUPPORT_MODAL_SHOW_REFUND = 'SUPPORT_MODAL_SHOW_REFUND';
export const SUPPORT_MODAL_HIDE_REFUND = 'SUPPORT_MODAL_HIDE_REFUND';
export const SUPPORT_MODAL_SHOW_MULTIPLE_REFUND = 'SUPPORT_MODAL_SHOW_MULTIPLE_REFUND';
export const SUPPORT_MODAL_HIDE_MULTIPLE_REFUND = 'SUPPORT_MODAL_HIDE_MULTIPLE_REFUND';

export const SUPPORT_RECREDIT_SHIPPING_FEE = 'SUPPORT_RECREDIT_SHIPPING_FEE';
export const SUPPORT_REFUND_SHIPPING_FEE = 'SUPPORT_REFUND_SHIPPING_FEE';

export const SUPPORT_MODAL_SHOW_SLOT = 'SUPPORT_MODAL_SHOW_SLOT';
export const SUPPORT_SLOT_LOADED = 'SUPPORT_SLOT_LOADED';

export const SUPPORT_MODAL_SHOW = 'SUPPORT_MODAL_SHOW';
export const SUPPORT_MODAL_HIDE = 'SUPPORT_MODAL_HIDE';


export const SUPPORT_MODAL_SHOW_ORDER_REDELIVER = 'SUPPORT_MODAL_SHOW_ORDER_REDELIVER';
export const SUPPORT_MODAL_HIDE_ORDER_REDELIVER = 'SUPPORT_MODAL_HIDE_ORDER_REDELIVER';
export const SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED = 'SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED';
export const SUPPORT_MODAL_SUBMIT_ORDER_REDELIVER = 'SUPPORT_MODAL_SUBMIT_ORDER_REDELIVER';

export const SUPPORT_MODAL_SHOW_ORDER_REPREPARE_BEFORE = 'SUPPORT_MODAL_SHOW_ORDER_REPREPARE_BEFORE';
export const SUPPORT_MODAL_SHOW_ORDER_REPREPARE = 'SUPPORT_MODAL_SHOW_ORDER_REPREPARE';
export const SUPPORT_MODAL_HIDE_ORDER_REPREPARE = 'SUPPORT_MODAL_HIDE_ORDER_REPREPARE';
export const SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER = 'SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER';
export const SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER = 'SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER';
export const SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED = 'SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED';
export const SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE = 'SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE';
export const SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE_LOADED = 'SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE_LOADED';
export const SUPPORT_MODAL_SHOW_ORDER_CRATES = 'SUPPORT_MODAL_ORDER_CRATES';

export const SUPPORT_ORDER_UPDATE = 'SUPPORT_ORDER_UPDATE';
export const SUPPORT_ORDER_UPDATED = 'SUPPORT_ORDER_UPDATED';

export const SUPPORT_ORDER_GIFT_MESSAGE = 'SUPPORT_ORDER_GIFT_MESSAGE';
export const SUPPORT_ORDER_ADDRESS_UPDATE = 'SUPPORT_ORDER_ADDRESS_UPDATE';
export const SUPPORT_ORDER_PREPZONE_UPDATE = 'SUPPORT_ORDER_PREPZONE_UPDATE';
export const SUPPORT_ORDER_REFUND = 'SUPPORT_ORDER_REFUND';
export const SUPPORT_ORDER_CRATE_ADD = 'SUPPORT_ORDER_CRATE_ADD';
export const SUPPORT_ORDER_CRATE_DELETE = 'SUPPORT_ORDER_CRATE_DELETE';
export const SUPPORT_ORDER_CRATE_UPDATED = 'SUPPORT_ORDER_CRATE_UPDATED';
export const SUPPORT_ORDER_STAT_DRIVER_LOADED = 'SUPPORT_ORDER_STAT_DRIVER_LOADED';

export const SUPPORT_USER_UPDATE = 'SUPPORT_USER_UPDATE';
export const SUPPORT_USER_UPDATED = 'SUPPORT_USER_UPDATED';
export const SUPPORT_USER_ADDRESS_LOAD = 'SUPPORT_USER_ADDRESS_LOAD';
export const SUPPORT_USER_ADDRESS_LOADED = 'SUPPORT_USER_ADDRESS_LOADED';
export const SUPPORT_USER_ADDRESS_UPDATE = 'SUPPORT_USER_ADDRESS_UPDATE';
export const SUPPORT_USER_ADDRESS_UPDATED = 'SUPPORT_USER_ADDRESS_UPDATED';

export const SUPPORT_INVOICE_DOWNLOAD = 'SUPPORT_INVOICE_DOWNLOAD';
export const SUPPORT_ORDER_EXTERNAL_UPDATED = 'SUPPORT_ORDER_EXTERNAL_UPDATED';
export const SUPPORT_ORDER_STATUS_UPDATE = 'SUPPORT_ORDER_STATUS_UPDATE';

export const SUPPORT_STRIPESYNC_LOAD = 'SUPPORT_STRIPESYNC_LOAD';
export const SUPPORT_STRIPESYNC_LOADED = 'SUPPORT_STRIPESYNC_LOADED';

export const SUPPORT_CREDITSYNC_LOAD = 'SUPPORT_CREDITSYNC_LOAD';
export const SUPPORT_CREDITSYNC_LOADED = 'SUPPORT_CREDITSYNC_LOADED';

export const SUPPORT_DEPOSIT_USER_HISTORY_LOAD = 'SUPPORT_DEPOSIT_USER_HISTORY_LOAD';
export const SUPPORT_DEPOSIT_USER_HISTORY_LOADED = 'SUPPORT_DEPOSIT_USER_HISTORY_LOADED';
export const SUPPORT_DEPOSIT_USER_CURRENT_LOADED = 'SUPPORT_DEPOSIT_USER_CURRENT_LOADED';
export const SUPPORT_DEPOSIT_USER_CURRENT_LOAD = 'SUPPORT_DEPOSIT_USER_CURRENT_LOAD';
export const SUPPORT_DEPOSIT_USER_CREATE_RECEIVED = 'SUPPORT_DEPOSIT_USER_CREATE_RECEIVED';
export const SUPPORT_DEPOSIT_RELOAD = 'SUPPORT_DEPOSIT_RELOAD';
export const SUPPORT_DRIVER_LIST_LOAD = 'SUPPORT_DRIVER_LIST_LOAD';
export const SUPPORT_DRIVER_LIST_LOADED = 'SUPPORT_DRIVER_LIST_LOADED';

export const SUPPORT_ACTIVITIESLOGS_LOAD = 'SUPPORT_ACTIVITIESLOGS_LOAD';
export const SUPPORT_ACTIVITIESLOGS_LOADED = 'SUPPORT_ACTIVITIESLOGS_LOADED';

export const SUPPORT_DELETE_ACCOUNT = 'SUPPORT_DELETE_ACCOUNT';
export const SUPPORT_FIX_CENTERS = 'SUPPORT_FIX_CENTERS';
export const SUPPORT_DISCONNECT_EDENRED = 'SUPPORT_DISCONNECT_EDENRED';

export const SUPPORT_CANCEL_SUBSCRIPTION = 'SUPPORT_CANCEL_SUBSCRIPTION';
export const SUPPORT_SUBSCRIPTION_CANCELED = 'SUPPORT_SUBSCRIPTION_CANCELED';

export const SUPPORT_CANCEL_CREDIT = 'SUPPORT_CANCEL_CREDIT';

export const loadUser = (user_id, email) => {
  return {
    type: SUPPORT_USER_LOAD,
    user_id:  user_id,
    email: email
  };
};

export const loadOrder = (order_id) => {
  return {
    type: SUPPORT_USER_LOAD_BY_ORDER,
    order_id:  order_id,
  };
};

export const loadUserAddresses = (user_id) => {
  return {
    type: SUPPORT_USER_ADDRESS_LOAD,
    user_id: user_id
  };
};

export const showPopinProductCredit = (op, user) => {

  let obj = getPriceAndLabelForSuperProductStatus(op);
  const unitPrice = obj.pricePerUnitQuantity;

  // function to avoid zero division error on later call
  const minQuantity = () =>
    user && user.credits ? -user.credits/unitPrice: 0;

  return {
    "type": SUPPORT_MODAL_SHOW_CREDIT,
    "label":  op.product.selling_name,
    "alertWarning": obj.additionalWarning,
    "alertInfo": obj.additionalInfo,
    "defaultQuantity": op.quantity,
    "maxQuantity": op.quantity,
    "minQuantity": Math.ceil(minQuantity()),
    "pricePerUnitQuantity": obj.pricePerUnitQuantity,
    "order_product_id":  op.id,
    "recreditQuantity": op.quantity_recredit,
    "refundQuantity": op.quantity_refund,
    userId: user && user.id,
  };
};


export const showPopinOrderCredit = (order, user) => {

  let pricePerUnitQuantity = 1;

  return {
    "type": SUPPORT_MODAL_SHOW_CREDIT,
    "label":  `Commande #${order.random_id} dédommagement en crédit`,
    "defaultQuantity": 0,
    "pricePerUnitQuantity": pricePerUnitQuantity,
    "order_id":  order.id,
    "maxQuantity": order.amount,
    "minQuantity": user ? - user.credits : 0,
    "userId": user && user.id,
    "labelQuantity": "Montant à recréditer",
    "subtype": "credit_compensation",
  };
};


export const showPopinMultipleProductCreditOrRefund = (
  order,
  user,
  type,
) => {

  let orderProducts = order.products.reduce(
    (acc, item) => {
      let obj = getPriceAndLabelForSuperProductStatus(item);
      if (
          obj.pricePerUnitQuantity === null
          || obj.pricePerUnitQuantity === 0
      ) {
        return acc;
      }
      let data = {
        selling_name: item.product.selling_name,
        alertWarning: obj.additionalWarning,
        alertInfo: obj.additionalInfo,
        defaultQuantity: item.quantity,
        pricePerUnitQuantity: obj.pricePerUnitQuantity,
        order_product_id:  item.id,
        recreditQuantity: item.quantity_recredit,
        refundQuantity: item.quantity_refund,
      };
      acc.push(data);
      return acc;
    },
    []
  );

  let label = "Valeur inconnue";
  if (type === SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT) {
    label = `Commande #${order.random_id} dédommagement en crédit`;
  }
  else if (type === SUPPORT_MODAL_SHOW_MULTIPLE_REFUND) {
    label = `Commande #${order.random_id} dédommagement en remboursement`;
  }

  return {
    type: type,
    label: label,
    orderProducts: orderProducts,
    userId: user && user.id,
    orderId: order.id,
  };
};


export const showPopinRefundOrder = (order) => {

  return {
    "type": SUPPORT_MODAL_SHOW_REFUND,
    "label":  `Remboursement de la commande #${order.random_id}`,
    "order": order,
    "labelQuantity": "Montant à rembourser",
  };
};

export const showPopinReprepareOrderBefore = (order) => {
  // This is needed for saga to load products stocks to allow to "hit" checkbox of available products
  return {
    "type": SUPPORT_MODAL_SHOW_ORDER_REPREPARE_BEFORE,
    "order": order
  };
};

export const showPopinReprepareOrder = (order, products_details) => {
  return {
    "type": SUPPORT_MODAL_SHOW_ORDER_REPREPARE,
    "label":  `Commande #${order.random_id} repréparation`,
    "order": order,
    "product_details": products_details
  };
};

export const showPopinReDeliverOrder = (order) => {
  return showPopinReDeliverOrderWithAddress(order, order.shipping_address);
};

export const showPopinReDeliverOrderWithAddress = (order, address) => {
  return {
    "type": SUPPORT_MODAL_SHOW_ORDER_REDELIVER,
    "label":  `Commande #${order.random_id} nouvelle livraison`,
    "order": order,
    "postalCode": address.postal_code,
    "address": address
  };
};

export const showPopinOrderCrates = (order, taskId = null, userType = null) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "crate",
      order,
      taskId,
      userType,
    },
  };
};

export const showPopinReprepareOrderAfter = (order) => {
  // orderId and postalCode are used in Saga to load slots
  return {
    "type": SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER,
    "order": order,
    "modalData":  {
      "orderId": order.id,
      "postalCode": order.shipping_address.postal_code,
    }
  };
};

export const submitRedeliverOrder = (data) => {
  console.log('in action payload deliver');
  // Data is generated from reprepare final form and sent to saga
  return {
    "type": SUPPORT_MODAL_SUBMIT_ORDER_REDELIVER,
    "data": data
  };
}

export const submitReprepareOrderAfter = (data) => {
  // Data is generated from reprepare final form and sent to saga
  return {
    "type": SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE,
    "data": data
  };
};

export const showPopinProductRefund = (op) => {

  let obj = getPriceAndLabelForSuperProductStatus(op);

  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "refund",
      "title": op.product.selling_name,
      "alertWarning": obj.additionalWarning,
      "alertInfo": obj.additionalInfo,
      "defaultQuantity": op.quantity,
      "pricePerUnitQuantity": obj.pricePerUnitQuantity,
      "orderProductId": op.id,
      "recreditQuantity": op.quantity_recredit,
      "refundQuantity": op.quantity_refund,
    }
  };
};

export const validateCredit = (quantity, subtype, notes, pricePerUnitQuantity, modal, callback) => {
  let value = quantity * pricePerUnitQuantity;

  return {
    "type": SUPPORT_MODAL_VALIDATE_CREDIT,
    "id":  modal.order_product_id || modal.order_id,
    "field":  modal.order_product_id ? 'order_product_id' :  'order_id',
    "quantity": quantity,
    "creditAmount": value,
    "subtype": subtype,
    "notes":  notes,
    "refreshUserId": modal.userId,
    "callback": callback,
  };
};

export const validateCreditBulk = (notes, subtype, products, modal) => {
  return {
    "type": SUPPORT_MODAL_VALIDATE_CREDIT_BULK,
    "id": modal.orderId,
    "field": 'order_id',
    "notes":  notes,
    "subtype": subtype,
    "products": products,
    "refreshUserId": modal.userId,
  };
};

export const validateRefund = (quantity, subtype, notes, modal) => {

  return {
    "type": SUPPORT_ORDER_REFUND,
    "id":  modal.order.id,
    "field": 'order_id',
    "refundAmount": quantity,
    "subtype": subtype,
    "notes":  notes
  };
};

export const orderProductRefund = (quantity, subtype, notes, pricePerUnitQuantity, orderProductId) => {
  let value = quantity * pricePerUnitQuantity;

  return {
    "type": SUPPORT_ORDER_REFUND,
    "id":  orderProductId,
    "field":  'order_product_id',
    "quantity": quantity,
    "refundAmount": value,
    "subtype": subtype,
    "notes":  notes
  };
};

export const showPopinSlot = (order) => {
  return {
    "type": SUPPORT_MODAL_SHOW_SLOT,
    "modalData":  {
      "modalType": "slot",
      "orderId": order.id,
      "postalCode": order.shipping_address.postal_code,
      "max_shipping_time": order.max_shipping_time,
      "shipping_time": order.shipping_time,
    },
  };
};

export const updateOrder = (orderId, toUpdate) => {
  return {
    "type": SUPPORT_ORDER_UPDATE,
    "toUpdate":  toUpdate,
    "orderId": orderId,
  };
};

export const refundOrder = (quantity, subtype, notes, pricePerUnitQuantity, orderProductId) => {
  let value = quantity * pricePerUnitQuantity;

  return {
    "type": SUPPORT_ORDER_REFUND,
    "id":  orderProductId,
    "field":  'order_product_id',
    "creditAmount": value,
    "subtype": subtype,
    "notes":  notes
  };
};

export const showPopinOrderAddressName = (address) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "delivery_name",
      "address": address,
      "title": "Changer le nom / prenom sur la commande"
    },
  };
};

export const showPopinOrderAddressPhone = (address) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "delivery_phone",
      "address": address,
      "title": "Changer le numéro de téléphone sur la commande"
    },
  };
};

export const showPopinOrderAddress = (address, title, user_id=0, address_type="order_address") => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "address",
      "address": address,
      "title": title,
      "user_id": user_id,  // Used in sagas
      "address_type": address_type,  // Address = order_address | ProfileAddress = user_address
    },
  };
};
export const showPopinOrderStatDriver = (order) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "order_stat_driver",
      "order": order,
      "title": "Détail du coût de livraison",
    },
  };
};

export const showPopinOrderPrepZone = (order, prepZone, pz) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "OrderPrepZone",
      "order": order,
      "prepZone": prepZone,
      "pz": pz,
      "title": 'Préparation de la zone ' + pz.name
    },
  };
};

export const showPopinOrderPrepZoneMarkDone = (order, opz) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData":  {
      "modalType": "OrderPrepZoneMarkDoneModal",
      "order": order,
      "opz": opz,
      "title": 'Préparation de la zone ' + opz.pseudozone.name
    },
  };
};


export const showPopinProductChangeStatus = (op) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "change_status",
      "title": op.product.selling_name,
      "orderProductId": op.id,
      "oldStatus": op.status
    }
  };
};

export const validateChangeStatus = (order_product_id, status_code) => {
  return {
    "type": SUPPORT_MODAL_VALIDATE_CHANGE_STATUS,
    "id":  order_product_id,
    "status_code": status_code
  };
};

export const validateChangeDeliveryName = (address) => {
  return {
    "type": SUPPORT_ORDER_ADDRESS_UPDATE,
    "address": address
  };
};

export const validateChangeDeliveryPhone = (address) => {
  return {
    "type": SUPPORT_ORDER_ADDRESS_UPDATE,
    "address": address
  };
};

export const showPopinConfirm = (msg, dispatchDatas) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "popin_confirm",
      "msg": msg,
      "title": 'Veuillez confirmer votre action',
      "dispatchDatas": dispatchDatas,
    }
  };
};
export const validatePopinConfirm = (dispatchDatas) => {
  /*
   * Dispatch Datas peut être de cette forme (comme pour updateOrder)
   *   updateOrder(
   *     order.id,
   *     {
   *       'option_tranquility': !order.option_tranquility
   *     }
   *   )
   *
   * Voir exemple dans /apps/support/components/GiftMessageModal => validate
   */
  return dispatchDatas;
};

export const showPopinGiftMessage = (order) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "gift_message",
      "title": "Message de la carte cadeau",
      "order": order
    }
  };
};
export const updateOrderGiftMessage = (orderId, gift_message) => {
  return {
    "type": SUPPORT_ORDER_GIFT_MESSAGE,
    "orderId": orderId,
    "gift_message": gift_message,
  };
};

export const act_loadCreditSync = (user_id) => {
  return {
    type: SUPPORT_CREDITSYNC_LOAD,
    user_id:  user_id,
  };
};

export const act_loadStripeSync = (user_id) => {
  return {
    type: SUPPORT_STRIPESYNC_LOAD,
    user_id:  user_id,
  };
};

export const act_loadDepositUserHistory = (user_id, offset) => {
  return {
    type: SUPPORT_DEPOSIT_USER_HISTORY_LOAD,
    user_id:  user_id,
    offset: offset
  };
};

export const act_loadDriverList = () => {
  return {
    type: SUPPORT_DRIVER_LIST_LOAD,
  };
};

export const driverDepositReceived = (values) => {
  return {
    "type": SUPPORT_DEPOSIT_USER_CREATE_RECEIVED,
    "driver_id": values.driver_id,
    "user_id": values.user_id,
    "quantity": values.quantity,
    "product_id": values.product_id
  };
};
export const act_loadDepositUserCurrent = (user_id) => {
  return {
    type: SUPPORT_DEPOSIT_USER_CURRENT_LOAD,
    user_id:  user_id
  };
};

export const loadActivitiesLogs = (order_id) => {
  return {
    type: SUPPORT_ACTIVITIESLOGS_LOAD,
    order_id:  order_id,
  };
};



function getPriceAndLabelForSuperProductStatus(op){
  const pack = op.product_pack || op.product.pack;
  let pricePerUnitQuantity = op.unit_price || op.product.pack_price;
  let additionalWarning = '';
  let additionalInfo = '';
  if(op.product.super_product_status_and_amount.is_superproduct_bought
    && !op.product.super_product_status_and_amount.is_unit_bought
  ){
    if(op.product.super_product_status_and_amount.unit_price_superproduct_bought != null) {
      pricePerUnitQuantity = op.product.super_product_status_and_amount.unit_price_superproduct_bought;
      additionalInfo =
          'Ce produit est issu du superproduit "'
          + op.product.super_product_status_and_amount.superproduct_name
          + '", le montant de remboursement est bien celui payé par le client.';
    }
    else{
      additionalWarning =
          'Ce produit est issu du superproduit "'
          + op.product.super_product_status_and_amount.superproduct_name
          + '", avec plusieurs sous-produits différents.'
          + ' Son prix n\'a pas pu être déterminé.';
    }
  }
  else if(op.product.super_product_status_and_amount.is_superproduct_bought){
    additionalWarning = 'Ce produit est peut-être issu d\'un superproduit.';
  }
  pricePerUnitQuantity = pricePerUnitQuantity / pack;
  return {
    "pricePerUnitQuantity": pricePerUnitQuantity,
    "additionalWarning": additionalWarning,
    "additionalInfo": additionalInfo,
  };
}

export const supportTransitArea = function (order) {
  const r = prompt("Changer la zone de transit principale");
  if (!r) {
    return { type: SKIP };
  }
  return updateOrder(
    order.id, {transit_area: r}
  );
};


export const recreditShippingFeeAction = (order_id) => {
  return {
    "type": SUPPORT_RECREDIT_SHIPPING_FEE,
    "id":  order_id,
    "field":  "order_id",
    "notes":  ""
  };
};


export const refundShippingFeeAction = (order_id) => {
  return {
    "type": SUPPORT_REFUND_SHIPPING_FEE,
    "id":  order_id,
    "field":  "order_id",
    "notes":  ""
  };
};

export const validateChangeBags = (
  orderId, numberOfBags, numberOfBagsIso, numberOfBagsFrozen, opzPatchs
) => {

  return {
    type: SUPPORT_ORDER_UPDATE,
    orderId,
    toUpdate: {
      number_of_bags: numberOfBags,
      number_of_bags_isotherm: numberOfBagsIso,
      number_of_bags_frozen: numberOfBagsFrozen,
    },
    opzPatchs,
  };
};
