import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

function PackerCommentLogAlert ({ alert, hideAlerts }) {
  const {
    id,
    created_at,
    comment,
    about_user_id,
  } = alert;

  return (
    <div className="alert alert-danger" key={id}>
      <p>
        {moment(created_at).format('HH:mm').replace(':', 'h')},
        <span> </span>
        <a
          href={`${process.env.REACT_APP_TEAM}/staffs/${about_user_id}`}
          target="_blank" rel="noopener noreferrer"
          tabIndex="0"
        >
          {comment}
        </a>
      </p>
      <p>
        <button onClick={() => hideAlerts([alert])} className="btn btn-xs btn-default float-right">
        Masquer
        </button>
      </p>
    </div>
  );
}

PackerCommentLogAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  hideAlerts: PropTypes.func.isRequired,
};

export default PackerCommentLogAlert;
