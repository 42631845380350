import React, {Component} from 'react';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import PropTypes from 'prop-types';
import { mapUrl } from "../utils/address";

import "../css/Common.css";
import moment from 'moment';
import {extractStuartLabels, slotLabel, slotDepartureHourLabel} from "../utils/task";
import { EXTERNAL_DOCK_TYPES } from '../utils/dock_n_driver';
import {connect} from "react-redux";
import {copyToClipboard} from "../utils";


// FYI: TaskName est maintenant dans ./TaskName.js

export const UserName = ({client, task}) => {
  client = task ? task.client : client;
  let name = client.name;
  if(!name){
    if(client.first_name){
      name = client.first_name;
    }
    if(client.last_name){
      if(name){
        name += " " + client.last_name;
      }
      else{
        name = client.last_name;
      }
    }
  }
  return (<a target="_blank" className="UserName" rel="noopener noreferrer" href={process.env.REACT_APP_DELEEVADMIN + "/users/" + client.id} title={`user_id: ${client.id}`}>{name}</a>);
  // return (<span>{name}</span>);
};

UserName.propTypes = {
  client: PropTypes.object,
  task: PropTypes.object
};


export const InternalAddress = ({address, center_id, centers, format}) => {
  if (format === 'compact') {
    const tooltip = (
      <Tooltip id={`tooltip-${address.id}`}>
        {address.street_number} {address.street}
      </Tooltip>
    );

    //{address.street_number} {address.street}
    return  <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <span className="Address compact">
        <a href={mapUrl(address, center_id, centers)} target="_blank" rel="noopener noreferrer" >{address.postal_code}, {address.city}</a>
      </span>
    </OverlayTrigger>;
  } else if (format === 'no_link') {
    return <span className="Address">
      {address.postal_code}, {address.city}, {address.street_number} {address.street}
    </span>;
  } else {
    return <span className="Address">
      <a href={mapUrl(address, center_id, centers)} target="_blank" rel="noopener noreferrer" >{address.postal_code}, {address.city}, {address.street_number} {address.street}</a>
    </span>;
  }
};

InternalAddress.propTypes = {
  address: PropTypes.object.isRequired,
  center_id: PropTypes.number.isRequired,
  centers: PropTypes.array.isRequired,
  format: PropTypes.string,
};


export const Address = connect(
  (state) => {
    let centers = []
    if(state.support && state.support.meta && state.support.meta.centers){
      centers = state.support.meta.centers
    }
    else if(state.m && state.m.metas && state.m.metas.centers){
      centers = state.m.metas.centers
    } else if (state.dispatch.metas.centers) {
      centers = state.dispatch.metas.centers
    }
    return { centers: centers };
  },
)(InternalAddress);

Address.propTypes = {
  address: PropTypes.object.isRequired,
  center_id: PropTypes.number.isRequired,
  format: PropTypes.string,
};


export const NumberOfProducts = ({task}) => {
  return (<span>{task.status === 'doing' ? `${task.prep_progress}/` : ''}{task.prep_total}</span>);
};
NumberOfProducts.propTypes = {
  task: PropTypes.object.isRequired
};

export const ShortSlot = ({slot, slotEtd, uniqueId, useDangerous}) => {
  if (slotEtd !== '') {
    const tooltip = (
      <Tooltip id={`tooltip-short-slot-${uniqueId}`}>
        {slotEtd}
      </Tooltip>
    );
    return  <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <span className="ShortSlot">
        {
          useDangerous
          ? <span dangerouslySetInnerHTML={{__html: slot}}></span>
          : <span>{slot}</span>
        }
      </span>
    </OverlayTrigger>;
  } else {
    return <span className="ShortSlot">
      {slot}
    </span>;
  }
};
ShortSlot.propTypes = {
  slot: PropTypes.string.isRequired,
  slotEtd: PropTypes.string.isRequired,
  uniqueId: PropTypes.number.isRequired,
  useDangerous: PropTypes.bool,
};

export const Slot = ({task, compact}) => {
  // let date = moment(start_date);
  // let end_date = moment(start_date).add(30, 'minutes');
  let label = slotLabel(task.linked_order);

  let etaLabel = '';
  let etdLabel = '';
  let useDangerous = false;

  if(task.eta) {
    etaLabel = `(${moment(task.eta).format("HH:mm")})`;
    if(
      label.includes("-") // Si on a un créneau
      && task.linked_order.shipping_time > task.eta
    ){
      etaLabel = `(<span style="color:blue; font-weight: bold;">**${moment(task.eta).format("HH:mm")}**</span>)`;
      useDangerous = true;
    }
    else if(task.linked_order.max_shipping_time < task.eta){
      etaLabel = `(<span style="color:red; font-weight: bold;">**${moment(task.eta).format("HH:mm")}**</span>)`;
      useDangerous = true;
    }
  }

  if (task.dock && task.dock.dock_type === 'STUART') {
    const st = extractStuartLabels(task);
    if (st.ok && task.dock.locked_at) {
      etaLabel = `(${st.label})`;
      useDangerous = false;
    }
  }

  const shippingTime = moment(task.linked_order.shipping_time);
  const isNotShippingToday = shippingTime.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD');

  const tooltip = (
    <Tooltip id={`tooltip-slot-${task.id}`}>
      {shippingTime.format("DD/MM/YYYY")}
    </Tooltip>
  );


  if (task.date_received) {
    const receivedHour = moment(task.date_received).format("HH:mm", "fr");
    etaLabel = `Livré à: ${receivedHour}` ;
    useDangerous = false;
    if (task.linked_order.status === "undeliveredclient") {
      etaLabel = `Abs: ${receivedHour}` ;
      useDangerous = false;
    }
  }

  etdLabel = slotDepartureHourLabel(task.linked_order);

  let slotText = label + ' ' + etaLabel;

  return <span className="Slot">
    {['paid', 'waiting-products'].includes(task.linked_order.status) && etdLabel !== '' && compact === false
        ? <span className="slot-label">
            {label}
            {
              useDangerous
              ? <span dangerouslySetInnerHTML={{__html: etaLabel}}></span>
              : <span>{etaLabel}</span>
            }
          </span>
        : <ShortSlot slot={slotText} slotEtd={etdLabel} uniqueId={task.id} useDangerous={useDangerous}/>
    }
    {['paid', 'waiting-products'].includes(task.linked_order.status) && etdLabel !== '' && compact === false
        ? <span className="slot-etd">{etdLabel}</span>
        : null
    }
    {isNotShippingToday
      ? <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
        <span className="fa-regular fa-clock"
          onClick={() => {copyToClipboard(shippingTime.format("DD/MM/YYYY"))}}
        />
      </OverlayTrigger>
      : null}
  </span>;
};
Slot.propTypes = {
  task: PropTypes.object.isRequired,
  compact: PropTypes.bool
};

export class Sms extends Component {

  handleClick(task) {
    let order_id = task.linked_order.id;
    let url = process.env.REACT_APP_DELEEVADMIN + '/queue/list_sms?order_id=' + order_id;
    let options = "menubar=no, status=no, menubar=no, width=600, height=600";
    window.open(url, 'Envoie de SMS', options);
  }

  render() {
    let task = this.props.task;
    if (!task.linked_order) {
      return ('');
    } else {
      return (<Button className="Sms" variant="success" size="xs" onClick={() => this.handleClick(task)}>SMS</Button>);
    }
  }
}
Sms.propTypes = {
  task: PropTypes.object.isRequired
};



export const DriverLabel = ({task}) => {
  let label = "N/A";
  let title = "";

  if (task.dock && task.dock.target_driver) {
    label = task.dock.target_name;
  }
  if (task.dock && EXTERNAL_DOCK_TYPES.includes(task.dock.dock_type)) {
    const st = extractStuartLabels(task);
    if (st.ok) {
      title = st.label + ' ' + st.phone;
      label = "Stuart: " + st.driver;
    }
  }
  return (
    <span title={title}>
      {label}
    </span>
  );
};

DriverLabel.propTypes = {
  task: PropTypes.object.isRequired
};

export const Packer = ({task}) => {
  if (!task.packer) {
    return null;
  }

  // compat ascendante
  if (typeof task.packer.name === "undefined") {
    return <span>{task.packer}</span>;
  }

  // code noouveau
  const with_verif = (task.packer.is_without_prep_check || !task.packer.name)  ? '' : '*';
  const packer_name = task.packer.name + "" + with_verif;

  if (!task.checker_email) {
    return <span>{packer_name}</span>;
  }

  const tooltip = (
    <Tooltip id={`tooltip-packer-${task.id}`}>
      verif: {task.checker_email}
    </Tooltip>
  );

  //{address.street_number} {address.street}
  return  <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
    <span className="label label-info" style={{fontWeight:"normal", fontSize:"12px", "padding":"1px"}}>{packer_name}</span>
  </OverlayTrigger>;

};

Packer.propTypes = {
  task: PropTypes.object.isRequired
};

export const DateTimeFormated = ({id, dateTime, withTooltip, withDuration}) => {

  if (!dateTime) {
    return null;
  }

  withTooltip = !!withTooltip;
  withDuration = !!withDuration;

  if(withDuration) {
    let diff = moment.duration(moment(dateTime).diff(moment()));
    var durationLabel = '';//' dans ';
    if(diff.get("hours") > 0){
      durationLabel += diff.get("hours")+' heures ';
      if(diff.get("minutes") > 0){
        durationLabel += 'et ';
      }
    }
    if(diff.get("minutes") > 0){
      durationLabel += diff.get("minutes")+' minutes ';
    }
    if(durationLabel !== ''){
      durationLabel = ' dans ' + durationLabel;
    }
  }

  const dt = moment(dateTime);
  let label = dt.format('HH:mm').replace(':', 'h');

  const isNotToday = dt.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD');

  const tooltip = (
    <Tooltip id={`tooltip-slot-${id}`}>
      {dt.format("DD/MM/YYYY")}
    </Tooltip>
  );

  return <span className="DateTimeFormated">
    <span className="hour">{label}</span>
    {isNotToday
      ? <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
        <span className="fa-regular fa-clock"
          onClick={() => {copyToClipboard(dt.format("DD/MM/YYYY"))}}
        />
      </OverlayTrigger>
      : null}
    {withDuration && durationLabel}
  </span>;
};

DateTimeFormated.propTypes = {
  id: PropTypes.any,
  withTooltip: PropTypes.bool,
  dateTime:PropTypes.any,
  withDuration: PropTypes.bool,
};


export default { Address , UserName, Slot };
