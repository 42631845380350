import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import moment from 'moment';
import {withRouter} from "react-router-dom";

import "../../css/OrderList.css";

const InternalSMSList = withRouter(({SMSList}) => {
  if (!SMSList) {
    return null;
  }
  moment.locale('fr');

  return <div className='SMSList'>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>Message</th>
          <th>Type</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {SMSList.map((o)=> {
          const className = o.current ? 'current' : '';
          // Creating human readable date
          var date = o.sent ? new Date(o.sent) : new Date(o.created_at);
          var hr_date = date.toLocaleDateString() + "\n" + date.getHours() + "h" + date.getMinutes()+ "m";
          return <tr key={o.id} className={className}>
            <td>{o.message}</td>
            <td>{o.transport_label}</td>
            <td>{hr_date}</td>
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalSMSList.propTypes = {
  "SMSList": PropTypes.array
};

export const SMSList = connect(
  (state, ownProp) => {
    let SMSList = ownProp.SMSList
      .map(o => {
        o.current = state.support.order && o.id === state.support.order.id;
        return o;
      });

    return {
      SMSList: SMSList,
      allCenters: state.support.meta ? state.support.meta.all_centers : []
    };
  },
  () => {
    return {};
  }
)(InternalSMSList);
