import {connect} from "react-redux";

import "../../../css/OrderProducts.css";
import {SUPPORT_MODAL_HIDE_ORDER_REDELIVER} from "../../../actions";
import {InternalRedeliverModal} from "../../../../../common-comps/InternalRedeliverModal";
import {connectTransitAreaModalAction} from "../../../../../utils/transit_areas";


export const RedeliverModal = connect(
  (state) => {
    return {
      "modal": state.support.modalRedeliver,
      "addresses":state.support.user_addresses,
      "pseudoZones": state.m.pseudoZones,
      "transit_areas": state.support.transit_areas,
      "trolleys": state.m.metas.trolleys,
      "trolley_locations": state.m.metas.trolley_locations,
      "transit_area_types": state.m.metas.transit_area_types,
    };
  },
  (dispatch) => {
    return {
      ...connectTransitAreaModalAction(dispatch),
      "hide": () => dispatch({"type": SUPPORT_MODAL_HIDE_ORDER_REDELIVER}),
    };
  }
)(InternalRedeliverModal);
