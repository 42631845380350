import React from 'react';
import {FormControl, FormGroup} from "react-bootstrap";

import "../css/DockSetting.css";
import {connect} from "react-redux";
import PropTypes from "prop-types";


const InternalDockReturnSetting = ({dock, onChangeEndingCenter, centers}) => {

  let currentValue = dock.ending_center || 0;

  return (<FormGroup className="DockReturnSetting">
    <FormControl
      disabled={!!dock.updating}
      as="select"
      value={currentValue}
      onChange={evt => onChangeEndingCenter(dock, evt.target.value)}>
      <option key={0} value={0} defaultValue={currentValue}>{"PAS DE RETOUR"}</option>
      {
        centers.map((c) => {
          return (
            <option key={c.id} value={c.id} defaultValue={currentValue}>{c.name}</option>
          );
        })
      }
    </FormControl>
  </FormGroup>);
};

InternalDockReturnSetting.propTypes = {
  dock: PropTypes.object.isRequired,
  centers: PropTypes.array.isRequired,
  onChangeEndingCenter: PropTypes.func.isRequired,
};

const DockReturnSetting = connect(
  (state) => {
    return {centers: state.m.metas.centers || []};
  },
  () => {
    return {
      // "changeCenter": (user, center) => dispatch(userChangeCenter(user, center))
    };
  }
)(InternalDockReturnSetting);

export default DockReturnSetting;
