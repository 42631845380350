import { connect } from "react-redux";
import { MAIN_MODAL_HIDE } from "../actions/modal";
import StuartCallModal from "../components/modal/StuartCallModal";
import { 
  ORDER_CALL_STUART,
  ORDER_STUART_SENDING,
  DOCK_STUART_SENDING,
  DOCK_CALL_STUART
} from "../actions";

export default connect(
  state => ({
    modal: state.m.modal,
  }),
  dispatch => ({
    onHide: () => dispatch({ type: MAIN_MODAL_HIDE }),
    callStuart: (orderPatch, vehicle) => {
      dispatch({ type: ORDER_STUART_SENDING, sending: true });
      return dispatch({ type: ORDER_CALL_STUART, orderPatch, vehicle });
    },
    callDockStuart: (dock, vehicle) => {
      dispatch({ type: DOCK_STUART_SENDING, sending: true });
      return dispatch({ type: DOCK_CALL_STUART, dockId: dock.id, vehicle });
    },
  })
)(StuartCallModal);
