import React from 'react';
import {connect} from "react-redux";
import {Col, FormGroup, Modal} from "react-bootstrap";
import { Formik } from 'formik';
import {SUPPORT_MODAL_HIDE, validatePopinConfirm} from "../actions";

class InternalConfirmModal extends React.Component {


  render() {
    const {order_product_status, modal, orderId, hide, validate} = this.props;


    // On classe les status par order alphabétique
    let op_status = [];
    for (var key in order_product_status) {
      if (order_product_status.hasOwnProperty(key)) {
        op_status.push({
          'code': key,
          'label': order_product_status[key]
        });
      }
    }
    op_status.sort(function(a, b) { return a.label.toLowerCase().localeCompare(b.label.toLowerCase()); });

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'popin_confirm'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          onSubmit={(values, actions) => {
            validate(modal, orderId);
          }}
          render={(props: FormikProps<Values>) => {
            return <form onSubmit={props.handleSubmit} className="form-horizontal">
              <FormGroup controlId="formControlsSelect">
                <Col sm={12}>
                  { modal.msg }
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={12} className="offset-sm-8">
                  <button onClick={hide} className="btn btn-danger">Annuler</button>&nbsp;
                  <button type="submit" className="btn btn-primary">Confirmer</button>
                </Col>
              </FormGroup>
            </form>;
          }}
        />
      </Modal.Body>
    </Modal>;

  }
}


export const ConfirmModal = connect(
  (state) => ({
    "modal" : state.support.modal,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (modal, orderId) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        dispatch(validatePopinConfirm(
          modal.dispatchDatas
        ));
      }
    };
  }
)(InternalConfirmModal);



