import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Tab, Tabs} from "react-bootstrap";
import { DepositDriverReturn } from './DepositDriverReturn';
import { DepositDriverHistory } from './DepositDriverHistory';

import {rd} from "../../utils";

const Internal = ({driver, deposit_driver}) => {

  return <div className="DepositDriver">
    {deposit_driver.loading === true && <div className="loading">
      <div className="loading-back"></div>
      <div className="loading-content">Chargement des données...</div>
    </div>}
    {deposit_driver.datas.count > 0 &&
      <div>
        <div className="col-sm-12">
          <div className="deposit_summary">
            {deposit_driver.debt_datas.quantity > 0 || deposit_driver.debt_datas.amount > 0 ?
              <strong>Le livreur possède { deposit_driver.debt_datas.quantity } produit(s) pour un montant de { rd(deposit_driver.debt_datas.amount) }€</strong>
              : <strong>{`Le livreur n'a plus de produit consigné en sa possession`}</strong>}
          </div>
          <br />
          <Tabs defaultActiveKey='deposit_return' id="NavUser" transition={false}>
            <Tab eventKey='deposit_return' title="Retour Consigne">
              <DepositDriverReturn driver={driver} />
            </Tab>
            <Tab eventKey='deposit_history' title="Historique">
              <DepositDriverHistory driver={driver} />
            </Tab>
          </Tabs>

        </div>
      </div>}
    {deposit_driver.loading === false && deposit_driver.datas.count === 0 &&
      <div className="deposit_summary">
        <br />
        <br />
        <strong>{`Le livreur n'a pas de produit consigné en sa possession`}</strong>
        <br />
        <br />
      </div>
    }
  </div>;
};
Internal.propTypes = {
  driver: PropTypes.object.isRequired,
  deposit_driver: PropTypes.object.isRequired,
};

export const DepositDriver = connect(
  (state) => {
    return {
      deposit_driver: state.m.deposit.driver,
    };
  }
)(Internal);
