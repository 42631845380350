/* eslint-disable react/prop-types */
import React from 'react';
//import { Field, reduxForm } from 'redux-form';
import {connect} from "react-redux";
import moment from "moment/moment";
import {Formik, Field} from "formik";

let InternalPresenceEdit = ({ presence, validate }) => {

  return (
    <div className='float-right edit'>
      <Formik
        initialValues={{
          started_at: moment(presence.started_at).format('HH:mm').replace(':', 'h'),
          ended_at: presence.is_ended ? moment(presence.ended_at).format('HH:mm').replace(':', 'h') : "",
        }}
        onSubmit={(values, actions) => {
          validate(values, presence)
        }}
        render={ (props: FormikProps<Values>) => (
          <form onSubmit={props.handleSubmit} >
            <Field input="text" name="started_at" component="input" type="text" pattern="[0-9]{1,2}h[0-9]{2}" />
            <Field input="text" name="ended_at" component="input" type="text" pattern="[0-9]{1,2}h[0-9]{2}" />
            <button type="submit" className="btn btn-xs btn-primary" disabled={presence.updating}>OK</button>
        </form>
        )} />
    </div>
  );
};

//InternalPresenceEdit = reduxForm({})(InternalPresenceEdit);

export const PresenceEdit = connect(
  null,
  (dispatch) => {
    return {
      validate : (values, presence) => {

        let started_at = moment(presence.started_at).clone();
        let ended_at = moment(presence.ended_at).clone();
        const vs = values.started_at.trim();
        const ve = values.ended_at.trim();

        const originalTime = moment(presence.started_at);
        const originalStartHour = moment(presence.started_at).format('HH:mm').replace(':', 'h')
        const originalEndHour = moment(presence.ended_at).format('HH:mm').replace(':', 'h')

        let is_updated = false;
        let error = false;
        if (vs && vs !== originalStartHour) {
          const [hours, minutes] = vs.split('h');
          started_at = originalTime.clone().hour(hours).minute(minutes).second(0);
          is_updated = true;
        }
        if (ve && ve !== originalEndHour) {
          const [hours, minutes] = ve.split('h');
          ended_at = originalTime.clone().hour(hours).minute(minutes).second(0);
          is_updated = true;
        }

        // On vérifie si l'heure et les minutes sont les même on doit remettre les secondes à 0
        // pour s'assurer qu'il n'y ait pas de chevauchement
        if (is_updated && started_at.format("HH:mm") === ended_at.format("HH:mm")) {
          started_at = started_at.second(0);
          ended_at = ended_at.second(0);
        }

        if (started_at > ended_at) {
          alert("Le début ne peut être après la fin du temps de présence");
          error = true;
        }

        if (is_updated === true && error === false) {
          dispatch({
            type: "LOGS_PRESENCE_SAVE",
            id: presence.id,
            started_at: started_at.format(),
            ended_at: ended_at.format()
          });
        }
      }
    };
  }
)(InternalPresenceEdit);
