import {connect} from "react-redux";
import OrderList from "../components/OrderList";
import {getHiddenOrdersInError} from "../selectors/orders_hidden";

const mapStateToProps = state => {
  const toggleKey = "order_hidden_list_show_all";
  let orders = state.m.hidden_orders;
  const initialLength = state.m.hidden_orders.length;
  const orderInErrors = getHiddenOrdersInError(state);
  const errorLength = orderInErrors.length;

  if (!state.toggle[toggleKey]) {
    orders = orderInErrors;
  }

  return {
    orders: orders,
    title: "Commande(s) cachée(s) ou bizarre(s)",
    toggleKey: toggleKey,
    initialLength: initialLength,
    errorLength: errorLength
  };
};

const mapDispatchToProps = null;


let OrderHiddenLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList);

export default OrderHiddenLinked;
