import React, { Component } from 'react';
import PropTypes from "prop-types";

import {Address, UserName, Slot, Sms, DriverLabel} from "./Common";
import TaskName from "./TaskName";
import SelectStatus from "./SelectStatus";

import '../css/Row.css';
import { WAITING_RESTOCK_FLAG_ID } from '../utils/task';
import Late from "./Late";
import {DeliveryActionMenu} from "./DeliveryActionMenu";
import {TransitArea} from "./TransitArea";
import {NumberOfBags} from "./NumberOfBags";
import ExternalCallMenu from '../common-comps/ExternalCallMenu';

import CanceledNoShippingActionButton from '../common-comps/CanceledNoShippingActionButton';
import {NumberOfCanteenOrders} from "../common-comps/NumberOfCanteenOrders";

class DeliveryRow extends Component {
  render() {

    const {
      task,
      showMenu,
      showDriver,
      showStatus,
      showSms,
      showLate,
      showExternalCall,
      showToRestock,
      addressFormatting,
      handlerDnd,
      connectDragPreview,
      connectDropTarget,
      withSelect,
      selected,
      toggleSelect,
      inCrossdock,
      inToDispatch,
    } = this.props;

    let className = this.props.className || '';
    className += ' ' + task.linked_order.status;

    if (task.updating) {
      className = ' updating';
    }

    if (task.status === 'waiting') {
      className = ' info';
    }
    if(inCrossdock && task.linked_order
        && task.linked_order.cross_docking === null
        && task.client
        && task.client.user_type !== 2 // Reappro client
    ){
      className += " cross_dock_warning";
    }
    if(inToDispatch){
      if(task.linked_order.tags.includes("dqp_express_prio")) {
        className += " express_prio_warning";
      }
      if(task.linked_order.tags.includes("dqp_courier")) {
        className += " dqp_courier_warning";
      }
    }
    else{
      if(task.linked_order.tags.includes("dqp_courier")) {
        className += " dqp_courier_warning_soft";
      }
    }

    let tdDnd = null;
    if (handlerDnd) {
      tdDnd = (handlerDnd(<td className="draggable"></td>));
    }
    if ((task.isLock || task.is_dispatch_disabled) && handlerDnd) {
      tdDnd = (<td className="draggable locked"></td>);
    }

    let classesSelectSpan = "fa-regular";
    classesSelectSpan += selected ? " fa-square-check" : " fa-square";
    let classesSelectButton = "btn btn-xs";
    classesSelectButton += selected ? " btn-primary" : " btn-default";

    const row = (
      <tr className={"DeliveryRow Row " + className}  >
        {tdDnd}
        <td title={task.id}>
          <TaskName task={task}/>
          {task.status === 'waiting' ? 
            <span className="float-right">
              {task.packing_task ? <span>{task.packing_task.prep_progress}/{task.packing_task.prep_total}&nbsp;</span> : null}
              <i title="Cette commande n'est pas encore finalisée" className="no_finalized fa-solid fa-box-open"></i>
            </span>
            : null
          }
          {showMenu ? <DeliveryActionMenu task={task} /> : null}
        </td>
        <td>
          {
            task.linked_order && task.linked_order.canteen_witness
            ? <NumberOfCanteenOrders order={task.linked_order} />
            : <NumberOfBags order={task.linked_order} />
          }
        </td>
        <td>
          <TransitArea order={task.linked_order} />
        </td>
        <td>
          <UserName task={task}/>
        </td>
        { withSelect ?
          <td className={'drag-select'}
            onClick={() => toggleSelect(task.id)}>
            <button className={classesSelectButton}>
              &nbsp;
              <span className={classesSelectSpan}/>
              &nbsp;
            </button>
          </td>
          : null
        }
        <td>
          <Address address={task.address} center_id={task.linked_order.center_id} format={addressFormatting}/>
        </td>
        {showDriver  ? <td><DriverLabel task={task} /></td> : null}
        <td><Slot task={task}/></td>
        {showStatus ? (<td> <SelectStatus order={task.linked_order} task={task} /></td>) : null}
        {showSms ? (<td><Sms task={task} /></td>) : null}
        {showLate ? (<td><Late task={task}/></td>) : null}
        {showExternalCall
          ? (
            <td>
              <ExternalCallMenu task={task}>
                Appel
              </ExternalCallMenu>
            </td>
          ) : null
        }
        {showToRestock && (
          <td className="btn-stuart">
            <CanceledNoShippingActionButton
              task={task}
            >
              {task.flagged_orders.find(of => of.flag_id === WAITING_RESTOCK_FLAG_ID)
                ? 'Restock': 'Action'}
            </CanceledNoShippingActionButton>
          </td>
        )}
      </tr>
    );

    return (handlerDnd ? connectDragPreview(connectDropTarget(row)) : row);
  }
}


DeliveryRow.propTypes = {
  task: PropTypes.object.isRequired,
  showMenu: PropTypes.bool,
  showDriver: PropTypes.bool,
  showStatus: PropTypes.bool,
  showSms: PropTypes.bool,
  showLate: PropTypes.bool,
  showExternalCall: PropTypes.bool,
  addressFormatting: PropTypes.string,
  className: PropTypes.string,
  handlerDnd: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  withSelect: PropTypes.bool,
  selected: PropTypes.bool,
  toggleSelect: PropTypes.func,
  inCrossdock: PropTypes.bool,
  inToDispatch: PropTypes.bool,
};

const DEFAULT_TOGGLE_SELECT = () => {};

DeliveryRow.defaultProps = {
  showMenu: false,
  showDriver: false,
  showStatus: true,
  showSms: true,
  showLate: false,
  addressFormatting: null,
  withSelect: false,
  selected: false,
  toggleSelect: DEFAULT_TOGGLE_SELECT,
  inCrossdock: false,
  inToDispatch: false,
};

export {DeliveryRow, DEFAULT_TOGGLE_SELECT};
