import {connect} from "react-redux";
import DockList from "./DockList";
import {
  dockListEventHandler,
  dockListProps,
  getTitleDockListMoving,
} from "../common-comps/dock";
import {getComputedDockListMoving} from "../../selectors/dock";
import {withRouter} from "react-router-dom";

export const DockListMoving = withRouter(connect(
  state => dockListProps(
    state,
    getComputedDockListMoving(state),
    getTitleDockListMoving(state)
  ),
  dispatch => dockListEventHandler(dispatch)
)(DockList));

