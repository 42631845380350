import {Button} from "react-bootstrap";
import React from "react";


export const DriverCenterSelection = (
  {
    driver,
    centers,
    changeCenter,
  }
) => {
  //wait, plan, run_other, noplan, run
  if(driver.state !== "wait" && driver.state !== "plan"){
    return null;
  }
  let currentCenterId = driver.current_center_id;
  if (currentCenterId === null && centers.length > 0) {
    currentCenterId = centers[0].id;
  }
  return (
    <div className="normal-case">
      <h5>Choix du centre</h5>
      <select
        onChange={
          (event) => currentCenterId = event.target.value
        }
        defaultValue={driver.current_center_id}
      >
        {
          centers.map(
            (center) => <option key={center.id} value={center.id}>
              {center.name} {center.id === driver.current_center_id ? "✅" : ""}
            </option>
          )
        }
      </select>
      <Button
        variant="success" size="sm"
        onClick={() => changeCenter(driver, currentCenterId)}
      >Valider le centre</Button>
    </div>
  );
}
