/* eslint-disable react/prop-types */
import React from "react";
import { PresenceEdit } from "./PresenceEdit";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from "moment/moment";

import { PAY_MODE_LABEL_BY_LOG_PAY_MODE } from "../../../utils/driver";


const InternalPresenceTitle = ({presence, showDetail, setShowDetail, filters, history}) => {
  let className="presence-title";
  if (typeof(presence.is_ended) !== "undefined" && !presence.is_ended) {
    className += ' running';
  }
  if (presence.updating) {
    className += ' updating';
  }

  let alert = false;
  let warning = false;
  if (presence.driver.is_paid_by_race) {
      if (presence.money_perf > 200) {
          warning = true;
      }
      if (presence.money_perf > 300) {
          alert = true;
      }
  }
  else {
      //if (presence.money_pres > 200) {
      if (presence.time_total > 13 * 3600) {
          warning = true;
      }
      if (presence.time_total > 16 * 3600) {
          alert = true;
      }
  }
  if (alert) {
      className += " alert alert-danger";
  }
  else if (warning) {
      className += " alert alert-warning";
  }

  const navigateToDriverDay = (driverId) => {
    let date = null;
    if (presence.type === "driverextracost") {
      date = presence.date;
    } else {
      date = moment(presence.started_at).format('YYYY-MM-DD');
    }
    history.push('/logs/driver/' + driverId + "/date/" + date);
  };

  const getContent = function() {
    if (presence.type === "driverextracost") {
      return getExtraCostContent();
    }
    return getPresenceContent();
  };

  const getExtraCostContent = function() {
    let total_minutes = 0;
    let total_amount = 0;
    for (let log of presence.logs) {
        if (log.minutes !== null) {
            total_minutes += log.minutes;
        }
        total_amount += log.amount;
    }
    total_amount = total_amount.toFixed(2);
    return (
      <React.Fragment>
        <td>
          {' '}<i className="fa-solid fa-money-bill-wave"></i> Surcoûts
        </td>
        <td>
          {presence.driver.is_paid_by_race ? "Perf" : "Temps"}
        </td>
        <td>
          {total_minutes} minutes
        </td>
        <td>
        </td>
        <td>
          {total_amount} euros
        </td>
        <td colSpan={2}></td>
      </React.Fragment>
    );
  };

  const getPresenceContent = function() {
    return (
      <React.Fragment>
        <td>
          {' '}<i className="fa-solid fa-user-clock" title="Présence"></i> Shift
        </td>
        <td>
          {PAY_MODE_LABEL_BY_LOG_PAY_MODE[presence.pay_mode]}
        </td>
        <td>
          {presence.time_total_formated}
        </td>
        <td>
          {presence.time_waiting_formated}
        </td>
        <td>
          {presence.money_real} €
        </td>
        <td>
          {presence.planning_sessions &&
          <span><b>
            Planning&nbsp;:&nbsp;
            {presence.planning_sessions.map(planning_session => (
              <span key={planning_session.start}>
                { new Date(planning_session.start * 1000).toLocaleTimeString({timeZone: 'Europe/Paris'}).slice(0, 5) }
                -
                { new Date(planning_session.end * 1000).toLocaleTimeString({timeZone: 'Europe/Paris'}).slice(0, 5) }
                &nbsp;
              </span>
            ))}
          </b></span>}
        </td>
        <td>
          <PresenceEdit presence={presence} />
        </td>
      </React.Fragment>
    );
  };

  let toggleShowDetailButtonLabel = "Voir le détail";
  if (showDetail) {
      toggleShowDetailButtonLabel = "Masquer le détail";
  }

  let toggleShowDetail = () => {
      if (showDetail) {
          setShowDetail(false);
      }
      else {
          setShowDetail(true);
      }
  }

  return (
    <tr id={"main" + presence.id} key={"main" + presence.id} className={className}>
      <td>
      {filters.driver_id ? presence.date_formated :
        <button className={"btn btn-link"}
                onClick={() => navigateToDriverDay(presence.driver.id)}
        >
          <i className="fa-solid fa-rectangle-list" />&nbsp;{presence.driver.is_paid_by_race ? <i className="fa-solid fa-person-running" title="Payé à la perf"></i> : null}<b>{presence.driver.name}</b>
        </button>
      }
      </td>
      <td className="text-center">
        <button
            className={"btn btn-xs btn-default"}
            onClick={() => toggleShowDetail()}
        >
            {toggleShowDetailButtonLabel}
        </button>
      </td>
      {getContent()}
    </tr>
  );
};


export const PresenceTitle = withRouter(connect(
  (state) => {
    return { filters : state.logs.filters };
  }
)(InternalPresenceTitle));

// {/*<Button variant="primary" size="xs">*/}
// {/*<span className="fa-solid fa-pencil" aria-hidden="true"></span>*/}
// {/*</Button>*/}
