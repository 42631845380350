
let _exp = {};

_exp.ignoreDisconnect = function(state) {
  const versioning = {...state.versioning};
  versioning.updated_at = (new Date()).getTime();
  return {
    ...state,
    versioning
  };
}

_exp.refreshRequest = function(action, state) {
  // return state;
  return {
    ...state,
    version: action.version,
    versioning: {
      ...state.versioning,
      last_requested_at: action.timestamp
    }
  };
};

_exp.refreshRequestError = function (action, state) {
  const versioning = {
    ...state.versioning,
    syncFails: state.versioning.syncFails + 1
  };
  return {...state, versioning};
}


_exp.refreshRequestSucceded = function (action, state) {

  let version = action.payload.version;
  let docks = action.payload.docks;
  let deliveries = action.payload.deliveries;
  let drivers = action.payload.drivers;
  let packings = action.payload.packings;
  let alerts = action.payload.alerts;
  let metas = action.payload.metas;
  let nexts = action.payload.nexts;
  let pseudoZones = action.payload.pseudo_zones;
  let recurringIncomings = action.payload.recurring_incomings;
  let recurringTaskInstances = action.payload.recurringtask_instances;
  let incoming_plannings_sessions = action.payload.incoming_plannings_sessions;
  let packerstatiph = action.payload.packerstatiph;
  let packerstatstockpilling = action.payload.packerstatstockpilling;
  let packerstatsordermoving = action.payload.packerstatsordermoving;
  let packerstatsbeforepicking = action.payload.packerstatsbeforepicking;
  let shipmentstats = action.payload.shipmentstats;
  let transit_areas = action.payload.transit_areas;
  let canceled_deliveries = action.payload.canceled_deliveries;
  let alert_notifications = action.payload.alert_notifications;
  let hiddenAlertNotifications = state.hiddenAlertNotifications;

  const versioning = {...state.versioning};

  if (version + "" !==  state.version + "" && !state.firstLoad) {
    console.log('autorefresh: skip all update', version, state.version);
    versioning.skip += 1;
    return {...state, versioning};
  }

  // reinit versioning not updated keys
  const notUpdatedMap = {};
  const timestamp = action.timestamp;
  versioning.notUpdated.forEach(e => notUpdatedMap[e.key] = e);
  versioning.notUpdated = [];

  if (state.drivers.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip drivers update');
    drivers = state.drivers;
    versioning.notUpdated.push(notUpdatedMap["drivers"] || {key:"drivers", timestamp});
  }

  if (state.deliveries.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip deliveries update');
    deliveries = state.deliveries;
    versioning.notUpdated.push(notUpdatedMap["deliveries"] || {key:"deliveries", timestamp})
  }

  if (state.docks.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip deliveries update');
    docks = state.docks;
    versioning.notUpdated.push(notUpdatedMap["docks"] || {key:"docks", timestamp})
  }

  if (state.packings.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip packings update');
    packings = state.packings;
    versioning.notUpdated.push(notUpdatedMap["packings"] || {key:"packings", timestamp})
  }

  if (state.alerts.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip alerts update');
    alerts = state.alerts;
    versioning.notUpdated.push(notUpdatedMap["alerts"] || {key:"alerts", timestamp})
  }

  if (state.metas.updating) {
    console.log('autorefresh: skip metas update');
    metas = state.metas;
    versioning.notUpdated.push(notUpdatedMap["metas"] || {key:"metas", timestamp})
  }

  if (state.pseudoZones.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip metas update');
    pseudoZones = state.pseudoZones;
    versioning.notUpdated.push(notUpdatedMap["pseudoZones"] || {key:"pseudoZones", timestamp})

  }

  if (state.nexts.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip nexts update');
    nexts = state.nexts;
    versioning.notUpdated.push(notUpdatedMap["nexts"] || {key:"nexts", timestamp})
  }

  if (state.recurring_incomings.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip recurring_incomings update');
    recurringIncomings = state.recurring_incomings;
    versioning.notUpdated.push(notUpdatedMap["recurring_incomings"] || {key:"recurring_incomings", timestamp})
  }
  if (state.recurringtask_instances.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip nexts update');
    recurringTaskInstances = state.recurringtask_instances;
    versioning.notUpdated.push(notUpdatedMap["recurringtask_instances"] || {key:"recurringtask_instances", timestamp})
  }

  if (state.incoming_plannings_sessions.filter(e => e.updating).length > 0) {
    console.log('autorefresh: skip incoming_plannings_sessions update');
    incoming_plannings_sessions = state.incoming_plannings_sessions;
    versioning.notUpdated.push(notUpdatedMap["incoming_plannings_sessions"] || {key:"incoming_plannings_sessions", timestamp})
  }

  //We keep the oldest timestamp of all to set the updated_at property,
  // or the current action timestamp if everything's up-to-date.
  versioning.updated_at = versioning.notUpdated.reduce((tsOne, tsTwo) => {
    return tsOne < tsTwo ? tsOne : tsTwo
  }, action.timestamp);

  return {
    version: 0,
    firstLoad: false,
    deliveries: deliveries,
    drivers: drivers,
    docks: docks,
    packings: packings,
    alerts: alerts,
    metas: metas,
    nexts: nexts,
    pseudoZones: pseudoZones,
    recurring_incomings: recurringIncomings,
    recurringtask_instances: recurringTaskInstances,
    incoming_plannings_sessions: incoming_plannings_sessions,
    packerstatiph: packerstatiph,
    packerstatstockpilling: packerstatstockpilling,
    packerstatsordermoving: packerstatsordermoving,
    packerstatsbeforepicking: packerstatsbeforepicking,
    transit_areas: transit_areas,
    modal: state.modal,
    deposit: state.deposit,
    trolley_logs: state.trolley_logs,
    alert_notifications,
    hiddenAlertNotifications,
    canceled_deliveries,
    versioning,
    shipmentstats,
  };
};


export default _exp;
