import {HIDE_ORDER_INFO} from "../actions";
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";


// STATUS: draft / unused

const InternalOrderInfo = ({order}) => {

  if (!order) {
    return null
  }

  return (
    <div className="OrderInfo">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            # {order.random_id} {order.status}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            A
          </div>
          <div className="col-md-6">
            B
          </div>
        </div>
      </div>
    </div>
  );
};

InternalOrderInfo.propTypes = {
  order: PropTypes.object,
};


export const OrderInfo = connect(
  (state) => {
    return { order : state.m.displayedOrder || null };
  },
  (dispatch) => {
    return {
      hideOrder : (order) => dispatch({type:HIDE_ORDER_INFO, order})
    };
  }
)(InternalOrderInfo);
