import React from 'react';
import PropTypes from 'prop-types';
import Dock from "./Dock";
import DockDnd from "./DockDnd";

export const DockList = ({docks, onDropDefault, onDropTask, onDropDock, title, show, className, showAll, onToggle}) => {

  let listDocks = null;
  if (showAll || !onToggle) {
    listDocks = docks.map((dock) => {

      if (onDropDock) {
        return (<DockDnd
          key={dock.id}
          dock={dock}
          onDropDefault={onDropDefault}
          onDropTask={onDropTask}
          onDropDock={onDropDock}
          show={show}
        />);
      } else {
        return (<Dock
          key={dock.id}
          dock={dock}
          onDropDefault={onDropDefault}
          onDropTask={onDropTask}
          show={show}
        />);
      }
    });
  }
  const reducer = (acc, currentValue) => acc + (currentValue && currentValue.tasks ? currentValue.tasks.length : 0);
  const countTasks = docks.reduce(reducer, 0);

  if (!className) {
    className = '';
  }

  className += ' container-fluid';

  return (
    <div className={className} style={{marginRight: "-5px"}}>
      {title
        ? <h4 className="row">
          {title} ({countTasks})
          {onToggle ? <button className="btn btn btn-default btn-xs float-right" onClick={(e) => onToggle()}  >{showAll ? "Cacher" : "Afficher"}</button> : null}
        </h4>
        : null}


      <div className="row">{listDocks}</div>
    </div>
  );
};

DockList.propTypes = {
  docks: PropTypes.array.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onDropDefault: PropTypes.func,
  onDropTask: PropTypes.func,
  onDropDock: PropTypes.func,
  show: PropTypes.object,
  showAll: PropTypes.bool,
  onToggle: PropTypes.func
};

export default DockList;
