import React from 'react';
import PropTypes from "prop-types";
import { DragSource, DropTarget } from 'react-dnd';
import PackingRow from "./PackingRow";

const taskSource = {
  beginDrag(props) {
    //console.log('A');
    return {
      taskId: props.task.id
    };
  }
};

const taskTarget = {
  // hover(props, monitor, component) {
  //   //console.log(props.task.id, monitor.getItem().taskId, component);
  //   return;
  // },

  canDrop(props, monitor) {
    let item = monitor.getItem();
    // !item.deliveryManId && !props.task.delivery_man_id

    return item.taskId !== props.task.id;

  },

  drop(props, monitor) {
    console.log('dropped ! ', props.task.id, monitor.getItem().taskId);
    props.onDropTask(props.task.id, monitor.getItem().taskId);
  }
};


function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

const PackingRowDnd = function ({connectDragSource, connectDragPreview, connectDropTarget, isDragging, canDrop, isOver, task, show}) {

  let classes = 'PackingRow Row';
  classes += isDragging ? " is-dragging-row" : '';
  classes += canDrop && isOver ? " is-over-row" : '';

  return (
    <PackingRow className={classes}
      task={task}
      show={show}
      handlerDnd={connectDragSource}
      connectDragPreview={connectDragPreview}
      connectDropTarget={connectDropTarget}/>
  );
};

PackingRowDnd.propTypes = {
  task: PropTypes.object.isRequired,
  show: PropTypes.object,
  onDropTask: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  connectDragPreview: PropTypes.func,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func
};

export default DragSource("TASK/PACKING", taskSource, collectSource)(
  DropTarget("TASK/PACKING", taskTarget, collectTarget)(PackingRowDnd)
);
