import React from 'react';
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";
import PackingRowDnd from "./PackingRowDnd";

import "../css/PackingNowList.css";

import DefaultDropZoneBuilder from "./DefaultDropZone";

const DefaultDropZone = DefaultDropZoneBuilder.build("TASK/PACKING");


export const PackingNowList = ({
  normalTasks,
  emergencyTasks,
  titleLink,
  titleRight,
  countItemsTodo,
  countItemsTodoToday,
  countOrdersTodo,
  countOrdersTodoToday,
  onDropTask,
  onDropDefault,
  showAll,
  packerstatiph_avg,
  packerstatcph_avg,
  packerstattad_avg,
  packerstattap_avg,
  shipmentstats,
  onToggle
}) => {

  if (!titleRight) {
    titleRight = '';
  }

  if (countOrdersTodo === 0) {
    return (<div><h4>A preparer maintenant : AUCUNE</h4></div>);
  }

  return (
    <div className="PackingNowList">
      <h4>
        A preparer maintenant : <b>{countOrdersTodo}</b> commandes, <b>{countItemsTodo}</b> items,
        dont  <b>{countOrdersTodoToday}</b> commandes, <b>{countItemsTodoToday}</b> items pour aujourd'hui
        <span className="title-right">{titleRight}</span>
        {titleLink ? <a href={titleLink.url} className="product-link btn btn btn-default btn-xs float-right" target="_blank" rel="noopener noreferrer" >{titleLink.label}</a> : null}

        {packerstatiph_avg ? packerstatiph_avg :null}
        {packerstatcph_avg ? packerstatcph_avg :null}
        {packerstattad_avg ? packerstattad_avg :null}
        {packerstattap_avg ? packerstattap_avg :null}
        {Object.entries(shipmentstats).length ? (
          <small>
            <br />
            <b>EXPE: </b>
            {shipmentstats.docks_not_loaded}+{shipmentstats.docks_ready_to_go} tournées à charger,
            &nbsp;{shipmentstats.nb_preps} preps =&gt; {shipmentstats.load_by_prep} tournées par prep
          </small>
        ) :null}

      </h4>
      <Table bordered size="sm" hover striped>
        <thead>
          <tr>
            <th></th>
            <th>Tâche</th>
            <th>Pr.</th>
            <th>Client</th>
            <th>Adresse</th>
            {/*<th>H. prep.</th>*/}
            <th></th>
            <th>État</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {emergencyTasks.map(t => (<PackingRowDnd key={t.id} task={t} onDropTask={onDropTask} />))}
          <DefaultDropZone  onDropDefault={onDropDefault} colSpan={8} />
          {normalTasks.map(t => (<PackingRowDnd key={t.id} task={t} onDropTask={onDropTask} />))}
          {onToggle ? <tr><td colSpan={8} style={{textAlign:"center"}}>
            <button className="btn btn btn-default btn-xs" onClick={(e) => onToggle()}  >{showAll ? "Afficher moins" : "Afficher plus"}</button>
          </td></tr> : null }
        </tbody>
      </Table>
    </div>
  );
};


PackingNowList.propTypes = {
  onDropTask: PropTypes.func.isRequired,
  onDropDefault: PropTypes.func.isRequired,
  normalTasks: PropTypes.array.isRequired,
  emergencyTasks: PropTypes.array.isRequired,
  titleLink: PropTypes.any,
  titleRight: PropTypes.any,
  countItemsTodo: PropTypes.number,
  countItemsTodoToday: PropTypes.number,
  countOrdersTodo: PropTypes.number,
  countOrdersTodoToday: PropTypes.number,
  showAll: PropTypes.bool,
  packerstatiph_avg: PropTypes.object,
  packerstatcph_avg: PropTypes.object,
  packerstattad_avg: PropTypes.object,
  packerstattap_avg: PropTypes.object,
  onToggle: PropTypes.func,

};


export default PackingNowList;
