import React, { useEffect } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { api } from "../../../api";
import PropTypes from 'prop-types';
import moment from 'moment';

const StoreStatusChangeLogs = ({ storeId, storeName, isOpen, setIsOpen }) => {
  const queryCache = useQueryCache();
  const { isError, data, error, refetch } = useQuery(
    ['uberEatsStoreStatusChangeLogs', storeId],
    (_key, id) => api.listUberEatsStoreStatusChangeLogs(id),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    if (isOpen) {
      queryCache.invalidateQueries({ queryKey: ['uberEatsStoreStatusChangeLogs', storeId] });
      refetch();
    }
  }, [isOpen]);

  return (
    <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" id={`status-logs-${storeId}`} style={{ display: isOpen ? 'block' : 'none' }}
      aria-hidden={!isOpen}
      aria-labelledby={`status-logs-${storeId}-label`}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Changements de Statut pour <b>{storeName}</b> ({storeId})</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {isError && (<span>{error.message}</span>)}

            {data && data.logs && data.logs && (
              <table className="table">
                <thead>
                  <tr className="thead-light">
                    <th scope="col">Nouv. statut</th>
                    <th scope="col">Action par</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.logs.map(log => (
                    <tr key={JSON.stringify(log)}>
                      <td><b>{log.status === 'ONLINE' ? 'OUVERT' : 'FERMÉ'}</b></td>
                      <td>{log.done_by}</td>
                      <td>{moment(log.created_at).locale('fr').format('LLLL')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StoreStatusChangeLogs.propTypes = {
  storeId: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default StoreStatusChangeLogs;
