import moment from "moment";

export const DISPATCH_MAIN_MODAL_SHOW = 'DISPATCH_MAIN_MODAL_SHOW';
export const DISPATCH_MAIN_MODAL_HIDE = 'DISPATCH_MAIN_MODAL_HIDE';

export const DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG = 'DISPATCH_MAIN_MODAL_PRELOAD_ISO_BAG';
export const DISPATCH_MODAL_DEPOSIT_DRIVER = 'DISPATCH_MODAL_DEPOSIT_DRIVER';
export const DISPATCH_MODAL_DEPOSIT_DRIVERS = 'DISPATCH_MODAL_DEPOSIT_DRIVERS';
export const DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT = 'DISPATCH_MODAL_POSTAL_CODES_FILTER_INPUT';
export const DISPATCH_MODAL_DOCK_ROUTE = 'DISPATCH_MODAL_DOCK_ROUTE';
export const DISPATCH_MODAL_OPTIMIZE_DOCK = 'DISPATCH_MODAL_OPTIMIZE_DOCK';
// export const DISPATCH_MODAL_OPTIMIZE_DOCK_LOADED = 'DISPATCH_MODAL_OPTIMIZE_DOCK_LOADED';
export const DISPATCH_MODAL_NOT_DELIVERED = 'DISPATCH_MODAL_NOT_DELIVERED';
export const DISPATCH_MODAL_DRIVERS_SOON = 'DISPATCH_MODAL_DRIVERS_SOON';

export const showOptimizeDock = (dock) => {
  return {
    type: DISPATCH_MODAL_DOCK_ROUTE,
    dock: dock,
  };
};

export const optimizeDockRoute = (dock, optimizer) => {
  return {
    type: DISPATCH_MODAL_OPTIMIZE_DOCK,
    dock: dock,
    optimizer: optimizer,
  };
};

export const showDepositModalDriver = (driver) => {
  return {
    type: DISPATCH_MODAL_DEPOSIT_DRIVER,
    driver: driver,
  };
};

export const showDepositModalDrivers = () => {
  return {
    type: DISPATCH_MODAL_DEPOSIT_DRIVERS,
  };
};

export const showPopinExtraCostAdd = (dock, task) => {
  return {
    "type": DISPATCH_MAIN_MODAL_SHOW,
    "modalData":  {
      "modalType": "extracost_add",
      "dock": dock,
      "task": task,
      "driverId": dock.target_driver,
      "date": moment().format('YYYY-MM-DD'),
    },
  };
};

export const showReturnBagsModal = driver => ({
  type: DISPATCH_MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'return_bags_deposit_modal',
    driver
  },
});

export const showRedeliverOrRestock = task => ({
  type: DISPATCH_MAIN_MODAL_SHOW,
  modalData: {
    modalType: 'redeliver_or_restock',
    task,
  }
});
