import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";

import { ARRIVAL_CRATE_MODAL } from '../apps/arrivals/modals';

import CrateManagerTabs from './CratesManagerTabs';

import { isSuperAdmin, isAdmin, isSupport, isDockStaff } from '../utils/permissions';

const CrateModal = ({ modal, hide, deleteCrate, addCrate, userMe }) => {
  if (!modal.order) {
    return null;
  }

  return <Modal
    show={modal && [ARRIVAL_CRATE_MODAL, 'crate'].includes(modal.modalType)}
    animation={false}
    backdrop={true}
    onHide={() => hide()}
    keyboard={true}
  >
    <Modal.Body>
      <h4>{`#${modal.order.random_id} - Cagettes`}</h4>
      <CrateManagerTabs
        order={modal.order}
        deleteCrate={(crateCode, orderId) => deleteCrate(crateCode, orderId, modal.taskId, modal.dockId)}
        addCrate={values => addCrate(values, modal.taskId, modal.dockId)}
        readOnly={!(isSuperAdmin(userMe) || isAdmin(userMe) || isSupport(userMe) || isDockStaff(userMe))}
      />
    </Modal.Body>
  </Modal>;

};


CrateModal.propTypes = {
  modal: PropTypes.object,
  userMe: PropTypes.object,
  hide: PropTypes.func,
  deleteCrate: PropTypes.func,
  addCrate: PropTypes.func,
};

export default CrateModal;
