import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge, Button, Form as BForm, Col, Row } from 'react-bootstrap';
import { Formik, Field, Form } from "formik";


const ReturnBagsDepositForm = ({
  remainingDebts,
  formValues,
  handleSubmit,
}) => (
  <Formik
    initialValues={formValues}
    onSubmit={handleSubmit}
    validate={(values) => {
      let errors = {};
      const positiveValues = Object.values(values).filter(v => v > 0);
      if (!positiveValues.length) {
        errors.form = 'Au moins un élément doit être retourné pour valider.';
      }
      return errors;
    }}
  >
    {({ errors }) => (
      <Form>
        {remainingDebts.map(([depositKey, depositLabel, quantity]) => (
          <BForm.Group as={Row} key={depositKey}>
            <Col xs={2} className="pr-1">
              <Field
                type="number"
                id={depositKey}
                name={depositKey}
                className="input-sm form-control"
                max={quantity}
                min={0}
              />
            </Col>
            <BForm.Label column className="pl-1">
              x {depositLabel}
              <Badge className="alert-warning">
                {quantity} à retourner
              </Badge>
            </BForm.Label>
          </BForm.Group>
        ))}
        {errors.form && <Alert variant="danger">{errors.form}</Alert>}
        <Button
          variant="success"
          size="sm"
          type="submit"
          disabled={!!errors.form}
        >
        Valider
        </Button>
      </Form>
    )}
  </Formik>
);

ReturnBagsDepositForm.propTypes = {
  remainingDebts: PropTypes.array,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default ReturnBagsDepositForm;
