import {validateChangeTrolleyLocation} from "../actions/index";
import {MAIN_MODAL_HIDE} from "../actions/modal";

export const connectChangeTrolleyLocationModalAction = (dispatch) => {
  return {
    "hide": () => {
      dispatch({"type": MAIN_MODAL_HIDE});
    },
    "validateTrolleyLocation": (value) => {
      let error;
      if (value === '') {
        error = "Veuillez renseigner l'emplacement";
      }
      return error;
    },
    "cancelSubmit": () => {
      dispatch({'type': MAIN_MODAL_HIDE});
    },
    "submit": (values) => {
      dispatch({'type': MAIN_MODAL_HIDE});
      dispatch(validateChangeTrolleyLocation(values));
    }
  };
};
