
import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Table} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {loadActivitiesLogs} from "../actions";

//import {} from "../actions";

import "../css/ActivitiesLogs.css";

const InternalActivitiesLogs = withRouter(({ activitieslogs, order, setExternalJobId }) => {
  let loading = activitieslogs.loading;
  let datas = activitieslogs.datas;
  if (!datas) {
    return null;
  }

  let order_products_by_product_id = null;
  if(order && order.products){
    order_products_by_product_id = new Map();
    Object.entries(order.products).forEach(
      ([key, value]) => {
        order_products_by_product_id.set(value.product.id, value);
      }
    );
  }

  if (loading === true) {
    return <div className='ActivitiesLogs text-center'><br />Chargement...</div>;
  }
  if (datas.length === 0) {
    return <div className='ActivitiesLogs'><br /><div className="alert alert-info">Aucune activité pour cette commande</div></div>;
  }
  // <div className="p-2 text-nowrap align-self-baseline h4">Légende : </div>
  return <div className='ActivitiesLogs'>
    <div>
      <div className="d-flex flex-column flex-wrap justify-content-start" style={{maxHeight: "12vh"}}>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-box-open" title="Préparation de commande"></i> Préparation de commande
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-box-open" title="Impression d'étiquette"></i> Impression étiquette
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-truck-fast" title="Livraison"></i> Livraison
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-truck-ramp-box" title="Dispatch"></i> Dispatch
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-boxes-stacked" title="Mouvements de stock"></i> Mouvements de stock
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-cart-flatbed" title="Chariots et zones de transit"></i> Chariots et zones de transit
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-basket-shopping" title="Cagettes"></i> Cagettes
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-euro-sign" title="Recrédits et remboursements"></i> Recrédits et remboursements
        </div>
        <div className="p-2 text-nowrap">
          <i className="fa-solid fa-circle-info" title="Autres infos"></i> Autres infos
        </div>
      </div>
    </div>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th></th>
          <th>Le</th>
          <th>Nom</th>
          <th>Message</th>
          <th>Extras</th>
        </tr>
      </thead>
      <tbody>
        {datas.map((data)=> {
          return <tr key={data.uniq_id}>
            <td>
              {data.activity_type === 'packing'
                ? <i className="fa-solid fa-box-open" title="Préparation de commande"></i>
                : ["log_driverloggroup", "back_to_center"].includes(data.activity_type)
                  ? <i className="fa-solid fa-truck-fast" title="Livraison"></i>
                  : data.activity_type === "log_dock"
                    ? <i className="fa-solid fa-truck-ramp-box" title="Dispatch"></i>
                    : data.activity_type === "log_stock"
                      ? <i className="fa-solid fa-boxes-stacked" title="Mouvements de stock"></i>
                      : (data.activity_type === "log_trolley_suggested" || data.activity_type === "log_transit_area")
                        ? <i className="fa-solid fa-cart-flatbed" title="Chariots et zones de transit"></i>
                        : data.activity_type === "order_crate"
                          ? <i className="fa-solid fa-basket-shopping" title="Cagettes"></i>
                          : data.activity_type === "log_money"
                            ? <i className="fa-solid fa-euro-sign" title="Recrédits et remboursements"></i>
                            : data.activity_type === "log_print_line_use"
                              ? <i className="fa-solid fa-print" title="Impression d'étiquette"></i>
                              : <i className="fa-solid fa-circle-info" title="Autres infos"></i>
              }
            </td>
            <td>{data.activity_date}</td>
            <td title={data.user_id ? data.user_id : null}>{data.full_name}</td>
            <td>{data.message}</td>
            <td>
              {data.extras.map((extra) => (
                data.activity_type === 'external_job' ?
                  (
                    <button
                      key={extra.label}
                      className="btn btn-default btn-xs"
                      onClick={() => setExternalJobId(extra.id)}
                      title={extra.title}
                    >
                      {extra.label}
                    </button>
                  ) : (
                    <a
                      key={extra.label}
                      className={
                        "btn btn-xs "
                        + (
                          extra.title.startsWith("new_status:paid")
                            ? "btn-warning font-weight-bold"
                            : "btn-default"
                        )
                      }
                      href={extra.url}
                      title={extra.title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {extra.label}
                    </a>
                  )
              ))}
              {
                order_products_by_product_id
                && order_products_by_product_id.has(data.product_id)
                && order_products_by_product_id.get(data.product_id).is_promo
                  ? <Badge variant="default" style={{backgroundColor:"#fd7a7a"}}>
                  promo
                  </Badge>
                  : null
              }
            </td>
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalActivitiesLogs.propTypes = {
  "activitieslogs": PropTypes.object,
  setExternalJobId: PropTypes.func,
};

export const ActivitiesLogs = connect(
  (state) => ({
    "activitieslogs": state.support.activitieslogs,
    order: state.support.order,
  }),
  (dispatch) => {
    return {
      loadActivitiesLogs: (id) => {
        return dispatch(loadActivitiesLogs(id));
      }
    };
  }
)(InternalActivitiesLogs);
