import {call, select, takeEvery} from "redux-saga/effects";
import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {currentToggleValue} from "../utils/toggle";

function localStorageSetItem(key, value) {
  localStorage.setItem(key, value);
}

function* switchToggle(action) {
  const state = yield select();
  const newValue = currentToggleValue(state.toggle, action.key) ? '1' : '0';
  yield  call(localStorageSetItem, action.key, newValue);
}

export const toggleSagas = [
  takeEvery(SWITCH_TOGGLE, switchToggle)
];
