import React from 'react';
import { Field } from 'formik';
import {Col, Form, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import {TYPE_DRIVER, TYPE_TROLLEY} from "../utils/transit_areas";

import { findAddress } from '../apps/dispatch/utils/address';

const RedeliverForm = props => {
  const {
    values,
    slots,
    addresses,
    address,
    handleSubmit,
    trolleys,
    transit_area_types,
    trolley_locations,
  } = props;

  const mainAddress = findAddress(address, addresses);

  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup>
        <Field component="select" name="shipping_address_id" required={true} className="form-control" defaultValue={mainAddress && mainAddress.id}>
          <option value="">-- Choisir l&apos;adresse de livraison --</option>
          {addresses.loading === false
            ? addresses.datas.map((address, idx) =>
              <option key={idx} value={address.id}>
                {address.street_number} {address.street}, {address.postal_code}, {address.city}
              </option>)
            :<option value="">Loading...</option>}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field component="select" name="slot" required={true} className="form-control">
          <option value="">-- Choisir le créneau de livraison --</option>
          {slots
            ? slots.map( (s, idx) => <option key={idx} value={idx}>{s.text}</option>)
            :<option value="">Loading...</option>}
        </Field>
      </FormGroup>
      <FormGroup>
        <b>Nombre total de sacs secs:</b>
        <Field
          type="number"
          min="0"
          max="999"
          step="1"
          value={values.number_of_bags_dry}
          name="number_of_bags_dry"
          required={true}
          className="form-control"
        >
        </Field>
      </FormGroup>
      <FormGroup>
        <b>Nombre total de sacs frais:</b>
        <Field
          type="number"
          min="0"
          max="999"
          step="1"
          value={values.number_of_bags_isotherm}
          name="number_of_bags_isotherm"
          required={true}
          className="form-control"
        >
        </Field>
      </FormGroup>
      <FormGroup key="transit_area_type">
        <label>Type de dépôt</label>
        <Field
          component="select"
          name="transit_area_type"
          className="form-control"
          required="required"
          value={values.transit_area_type}
        >
          <option value="">-- Sélectionner un type --</option>
          {Object.keys(transit_area_types).map((type_id, key) => {
            return <option key={key} value={type_id}>{transit_area_types[type_id]}</option>;
          })}
        </Field>
      </FormGroup>
      { values.transit_area_type
        && parseInt(values.transit_area_type, 10) !== TYPE_TROLLEY
        && parseInt(values.transit_area_type, 10) !== TYPE_DRIVER
        && (
          <FormGroup key="transit_area_name">
            <label>Zone de dépôt</label>
            <Field
              type="text"
              name="transit_area_name"
              className="form-control"
              required="required"
              value={values.transit_area_name}
            />
          </FormGroup>
        )
      }
      <FormGroup>
        {parseInt(values.transit_area_type, 10) === TYPE_TROLLEY
        && (
          <FormGroup key="trolley_id">
            <Field
              component="select"
              name="trolley_id"
              className="form-control"
              required="required"
            >
              <option value="">-- Sélectionner un chariot --</option>
              {trolleys.map((trolley, key) => {
                return <option key={key} value={trolley.id}>{trolley.full_name}</option>;
              })}
            </Field>
          </FormGroup>
        )}
        {parseInt(values.transit_area_type, 10) === TYPE_TROLLEY
        && false
        && (
          <FormGroup key="transit_area_name">
            <Field
              component="select"
              name="transit_area_name"
              className="form-control"
              required="required"
            >
              <option value="">-- Sélectionner une zone --</option>
              {trolley_locations.map((trolleyLocation, key) => {
                return <option key={key} value={trolleyLocation.name}>{trolleyLocation.name}</option>;
              })}
            </Field>
          </FormGroup>
        )}
        <Col sm={12} className="d-flex justify-content-around">
          <button type="submit" className="btn btn-success">Valider</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

RedeliverForm.propTypes = {
  values: PropTypes.any,
  handleSubmit: PropTypes.any,
  modal: PropTypes.object,
  hide: PropTypes.func,
  addresses: PropTypes.object,
  pseudoZones: PropTypes.array,
  transit_areas: PropTypes.object,
  trolleys: PropTypes.array,
  transit_area_types: PropTypes.object,
  slots: PropTypes.array,
  trolley_locations: PropTypes.array,
  address: PropTypes.object,
};

export default RedeliverForm;
