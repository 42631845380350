import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TransfertDriversLinked from "../containers/TransfertDriversLinked";
import TasksToCheck from "./TasksToCheck";
import DockListLinked from "../containers/DockListLinked";
import DeliveringDockLinked from "../containers/DeliveringDockLinked";
import XDPackingCheckLinked from "../containers/XDPackingCheckLinked";
import LoadingConfirmModal from "./LoadingConfirmModal";
import { TransitAreaModal } from "../../../components/modal/TransitAreaModal";
import { LAUNCHPAD_DEFAULT } from "../../../utils/launchpad";

const Loadings = ({ firstLoad }) => {
  if (firstLoad) {
    return (
      <div>
        <h1 className="loading">LOADING...</h1>
      </div>
    );
  }
  return (
    <div>
      <h4>Livreurs</h4>
      <TransfertDriversLinked launchpad={LAUNCHPAD_DEFAULT} />
      <DockListLinked />
      <h4>À vérifier</h4>
      <TasksToCheck />
      <XDPackingCheckLinked />
      <DeliveringDockLinked />
      <LoadingConfirmModal />
      <TransitAreaModal />
    </div>
  );
};

Loadings.propTypes = {
  firstLoad: PropTypes.bool,
};

export default connect((state) => ({ firstLoad: state.m.firstLoad }))(Loadings);
