let _exp = {};


_exp.reduceCloseAlert = function(action, stateAlerts) {
  return stateAlerts.map(a => a.id === action.alert.id ? {...a, ...{hidden: true, updating : action.alert.id}} : a);
};

_exp.reduceAlertClosed = function(action, stateAlerts) {
  return stateAlerts.filter(a => a.id !== action.alert.id);
};


export default _exp;
