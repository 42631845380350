import { call, put, takeEvery } from 'redux-saga/effects';
import { daUtil } from "../api";
import {MAIN_MODAL_PRELOAD_ISO_BAG, MAIN_MODAL_SHOW, MODAL_DEPOSIT_DRIVER, MODAL_DEPOSIT_DRIVERS} from "../actions/modal";


function* modalIsobagPreload(action) {
  let driverList = yield call(daUtil.get, `/drivers/`);
  yield put({type: MAIN_MODAL_SHOW, modalData: {modalType: "driver_iso_bag", driverList: driverList}});
}

function* modalDepositDriver(action) {
  yield put({type: MAIN_MODAL_SHOW, modalData: {modalType: MODAL_DEPOSIT_DRIVER, driver: action.driver}});
}
function* modalDepositDrivers(action) {
  yield put({type: MAIN_MODAL_SHOW, modalData: {modalType: MODAL_DEPOSIT_DRIVERS}});
}


export const modalSagas = [
  takeEvery(MAIN_MODAL_PRELOAD_ISO_BAG, modalIsobagPreload),
  takeEvery(MODAL_DEPOSIT_DRIVER, modalDepositDriver),
  takeEvery(MODAL_DEPOSIT_DRIVERS, modalDepositDrivers),
];
