import {SEARCH_CHANGE} from "../actions/search";

const initialState = {query:"", displayed:true};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
  case SEARCH_CHANGE:
    return {...state, query: action.value.query};
  default:
    return state;
  }
};

