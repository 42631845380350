import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table, Button} from "react-bootstrap";
import {StripeRow} from "./StripeList/StripeRow";
import {act_loadStripeSync} from "../../actions";

//import {} from "../actions";

import "../../css/StripeList.css";

class InternalStripeList extends React.Component {
  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      this.props.loadStripeSync(this.props.user.id);
    }
  }

  componentDidUpdate() {
    if (typeof(this.props.stripe.shouldRefresh) !== 'undefined' && this.props.stripe.shouldRefresh === true) {
      this.props.loadStripeSync(this.props.user.id);
    }
  }
  render () {
    let {stripe, user, loadStripeSync} = this.props;
    let stripeList = stripe.datas;

    const refreshList = () => {
      loadStripeSync(user.id);
    };

    if (!stripeList) {
      return null;
    }

    if (stripe.loading === true) {
      return <div className='StripeList text-center'><br />Chargement...</div>;
    }
    if (stripeList.length === 0) {
      return <div className='StripeList'><br /><div className="alert alert-info">Aucune transaction CB ces 7 derniers jours</div></div>;
    }
    return <div className='StripeList'>
      <br />
      <Button size="xs" variant="default" onClick={refreshList}><i className="fa-solid fa-rotate"></i> Rafraîchir</Button>
      <Table size="sm" striped hover>
        <thead>
          <tr>
            <th></th>
            <th className="col-order-id">N° Cmd.</th>
            <th className="col-date">Date</th>
            <th className="col-amount">TTC</th>
            <th className="col-3ds">3DS</th>
            <th className="col-message">Messages</th>
            <th className="col-score-stripe">Eval.</th>
            <th className="col-infos">Infos</th>
          </tr>
        </thead>
        <tbody>
          {stripeList.map((stripeElt)=> {
            return <StripeRow key={stripeElt.payment_intent_id ? stripeElt.payment_intent_id : stripeElt.charge_id} stripeElt={stripeElt} />;
          })}
        </tbody>
      </Table>
    </div>;
  }
}

InternalStripeList.propTypes = {
  "stripe": PropTypes.object,
  "user": PropTypes.object,
  "loadStripeSync": PropTypes.func,
};

export const StripeList = connect(
  (state) => ({
    "stripe": state.support.stripe,
    "user": state.support.user
  }),
  (dispatch) => {
    return {
      loadStripeSync: (id) => {
        return dispatch(act_loadStripeSync(id));
      }
    };
  }
)(InternalStripeList);
