import React from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";

import DockStuart from "./DockStuart";
import DockUber from './DockUber';

import "../css/Dock.css";
import {
  DOCK_TYPE_HARD_ASSIGNED,
  DOCK_TYPE_SOFT_ASSIGNED,
  EXTERNAL_DOCK_TYPES,
  isDeletable
} from "../utils/dock_n_driver";
import {BasicDockName} from "./dock/BasicDockName";
import {StuartDockName} from "./dock/StuartDockName";
import {DriverDockName} from "./dock/DriverDockName";
import DockSetting from "./DockSetting";
import DockReturnSetting from "./DockReturnSetting";


const DockTitle = ({
  authUser,
  dock,
  onChangeEndingCenter,
  onCallStuart,
  onCloseDock,
  onCancelStuart,
  onRegularizeStuart,
  onChangeMainSetting
}) => {

  const getDisplayedName = () => {
    // case freelance driver
    if (dock.target_driver && dock.driver !== undefined) {
      return <DriverDockName dock={dock} />;
    }
    // case stuart/uber
    if (EXTERNAL_DOCK_TYPES.includes(dock.dock_type)){
      return <StuartDockName dock={dock} />;
    }
    return <BasicDockName dock={dock} />;
  };

  const displayedName = getDisplayedName();

  let admin_opti_url = process.env.REACT_APP_DELEEVADMIN + "/logistics/stats/optimization/dock/?dock_id=" + dock.id;

  let formClassNames = "";
  if (dock.updating) {
    formClassNames += " updating";
  }

  return (<div style={{paddingRight: "3px", paddingLeft: "3px"}} className="DockTitle">
    <Form inline className={formClassNames}>
      {dock.dock_type === 'STUART' ?
        <DockStuart
          dock={dock}
          onCallStuart={onCallStuart}
          onCancelStuart={onCancelStuart}
          onRegularizeStuart={onRegularizeStuart}
        /> : null}

      {dock.dock_type === 'UBER' ?
        <DockUber
          dock={dock}
          onCancelUber={() => console.log("cancel")}
          onRegularizeUber={() => console.log("regularize")}
        /> : null}

      {dock.dock_type !== 'DRIVER_HARD' && !dock.locked_at ?
        <DockSetting dock={dock} onChangeMainSetting={onChangeMainSetting}/> : null}

      {dock.dock_type === DOCK_TYPE_HARD_ASSIGNED || dock.dock_type === DOCK_TYPE_SOFT_ASSIGNED ?
        <DockReturnSetting dock={dock} onChangeEndingCenter={onChangeEndingCenter}/> : null
      }

      {isDeletable(dock)
        ? dock.isLock
          ? <button className="btn btn-xs btn-default" onClick={() => false}>x</button>
          : <button className="btn btn-xs btn-danger" onClick={() => onCloseDock(dock)}>x</button>
        : null
      }
    </Form>
    <span>{displayedName}</span>
    {dock.target_driver && authUser.groups.is_super_admin
      ? (
        <a href={admin_opti_url} className="link_admin" title="Détails d'optimisation">
          <i className="fa-solid fa-gear"></i>
        </a>
      ) : null
    }
  </div>);
};


DockTitle.propTypes = {
  authUser: PropTypes.object.isRequired,
  dock: PropTypes.object.isRequired,
  onChangeEndingCenter: PropTypes.func.isRequired,
  onCancelStuart: PropTypes.func.isRequired,
  onCloseDock: PropTypes.func,
  onChangeMainSetting: PropTypes.func,
  onCallStuart: PropTypes.func,
  onRegularizeStuart: PropTypes.func
};

export default DockTitle;
