import React, { useState } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Card, Nav, NavItem, NavLink} from "react-bootstrap";

import "../css/OrderDetail.css";
import moment from "moment/moment";
import {OrderPackingDetail} from "./OrderPackingDetail";
import {OrderDeliveryDetail} from "./Order/Delivery/Detail";
import {OrderProducts} from "./OrderProducts";
import {OrderInvoice} from "./OrderInvoice";
import {OrderSummary} from "./OrderSummary";
import {OrderCrossDocking} from "./OrderCrossDocking";
import {OrderFinancial} from "./OrderFinancial";
import {OrderActions} from "./Order/OrderActions";
import {OrdersLinked} from "./Order/OrdersLinked";
import {OrderOtherInfos} from "./OrderOtherInfos";
import {OrderWarning} from "./OrderWarning";
import {ActivitiesLogs} from "./ActivitiesLogs";
import ExternalJobModal from "./modal/ExternalJobModal";
import {loadActivitiesLogs} from "../actions";
import {OrderProductsEmpty, OrderProductEmptyWarning} from "./OrderProductsEmpty";
import { isSuperAdmin, isAdmin, isSupport } from '../../../utils/permissions';


const InternalOrderDetail = ({order, user, onLoadActivitiesLogs, userMe}) => {
  const [nav_selected, setNavSelected] = useState('order_detail');
  const [externalJobId, setExternalJobId] = useState(null);

  if (!order) {
    return null;
  }
  moment.locale('fr');
  const readOnly = !(isSuperAdmin(userMe) || isAdmin(userMe) || isSupport(userMe));

  const orderedDate = moment(order.order_time).format("L");
  const orderedDisplay = moment(order.order_time).format("DD/MM/YYYY à HH:mm").replace(':','h');
  const shippingDate = moment(order.shipping_time).format("L");
  const onSelect = (key_nav) => {
    if (key_nav !== 'old_order_detail') {
      setNavSelected(key_nav);
    }
  };

  return (
    <React.Fragment>
      <Card className="OrderDetail">
        <Card.Body className="panel-body-sm">
          <h4>
            #{order.random_id} passé le {orderedDisplay} {shippingDate !== orderedDate ? <span>livraison le {shippingDate}</span> : null}

            <Nav className="small order-nav float-right" onSelect={onSelect}>
              <NavItem className={nav_selected === 'order_detail' ? "active float-left" : "pull-left"} title="Fiche Commande">
                <NavLink eventKey="order_detail">
                  <i className="fa-regular fa-rectangle-list"></i>
                </NavLink>
              </NavItem>
              <NavItem className={nav_selected === 'activitieslogs' ? "active float-left" : "pull-left"} title="Activités sur la commande" onClick={() => onLoadActivitiesLogs(order.id)}>
                <NavLink eventKey="activitieslogs">
                  <i className="fa-solid fa-clock-rotate-left"></i>
                </NavLink>
              </NavItem>
            </Nav>
          </h4>
          {nav_selected === 'order_detail' ?
            <div>
              <OrderProductEmptyWarning order={order}/>
              <OrderWarning order={order} />
              {order.cross_docking && <OrderCrossDocking order={order} />}
              <OrderSummary order={order} showExternalJob={setExternalJobId} readOnly={readOnly}/>
              <OrderFinancial order={order} />
              <OrderActions order={order} user={user} readOnly={readOnly} />
              <OrdersLinked order={order} />
              <OrderPackingDetail order={order}/>
              <OrderDeliveryDetail order={order} readOnly={readOnly}/>
              <OrderInvoice order={order} readOnly={readOnly}/>
              <OrderProducts order={order} readOnly={readOnly} user={user}/>
              <OrderProductsEmpty order={order}/>
              <OrderOtherInfos order={order} />
            </div>
            : null}
          { nav_selected === 'activitieslogs' ?
            <div>
              <ActivitiesLogs setExternalJobId={setExternalJobId} />
            </div>
            : null}
        </Card.Body>
      </Card>
      <ExternalJobModal
        jobId={externalJobId}
        show={!!externalJobId}
        onHide={() => setExternalJobId(null)}
      />
    </React.Fragment>
  );
};

InternalOrderDetail.propTypes = {
  order: PropTypes.object,
  user: PropTypes.object,
  onLoadActivitiesLogs: PropTypes.func,
};

export const OrderDetail = connect(
  state => ({
    userMe: state.user,
  }),
  (dispatch) => {

    return {
      onLoadActivitiesLogs: (id) => {
        return dispatch(loadActivitiesLogs(id));
      }

    };
  }
)(InternalOrderDetail);
