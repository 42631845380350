import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import {connect} from "react-redux";
import {
  ASSIGN_DROP_NEXTCONFIG,
  changeLateThreshold,
  changeMaxBuffer,
  changeMaxStaff,
//  toggleOneGrantBypass
} from "../actions";

import "../css/AssignPrioItem.css";
import { TASK_TYPE_PICKING_ZONE} from "../utils";
import {PrioItemButton} from "./PrioItemButton";



// COMPONENT
const IPrioItem = (
  {
    nextConfig,
    connectDragSource,
    connectDragPreview,
    connectDropTarget,
    isDragging,
    isOver,
    canDrop,
    onChangeMax,
    onChangeMaxBuffer,
    // onChangeLateThreshold,
    // onToggleBypass,
  }
) => {
  let classes = 'PrioItem';
  classes += isDragging ? " is-dragging-row" : '';
  classes += canDrop && isOver ? " is-over-row" : '';
  classes += nextConfig.updating ? " updating" : '';
  classes += nextConfig.disabled ? " disabled" : '';



  //const lateManagementTypes = [TASK_TYPE_PICKING_ZONE, TASK_TYPE_PICKING, TASK_TYPE_MISSING];
  //const lateManagementEnabled = lateManagementTypes.includes(nextConfig.next_task_type);
  const maxBufEnabled = TASK_TYPE_PICKING_ZONE === nextConfig.next_task_type;

  return  connectDragPreview(connectDragSource(connectDropTarget(
    <div key={nextConfig.id} className={classes}>
      <div className="prio-label">
        {nextConfig.id} - <span className="prio-name">{nextConfig.name}</span>&nbsp;
        {nextConfig.late_status.is_late ? <span className="late-title"><b>en retard</b>: {nextConfig.late_status.label}</span> : null}

        <div className="div-alert" onClick={(e) => {onChangeMax(nextConfig.id);}}>
          Nombre maximum de préparateurs : <span style={{paddingLeft:"10px"}} >{nextConfig.max_staff}</span>
        </div>
        <PrioItemButton
          nextConfig={nextConfig}
          field="is_high_priority_spot"
          label="Activer la haute prio (destaff d'ailleurs)" />
        { maxBufEnabled
          ? <div className="div-alert" onClick={(e) => {onChangeMaxBuffer(nextConfig.id);}}>Buffer maximum : <span style={{paddingLeft:"10px"}} >{nextConfig.max_buffer}</span></div>
          : null}
        { maxBufEnabled ? <PrioItemButton
          nextConfig={nextConfig}
          field="is_one_grant_bypass_max_buffer"
          label="Prep avec une seul permission, bypass max_buffer" /> : null}
      </div>

      <div className="insert-zone">&nbsp;</div>
    </div>
  )));
};



const ncSource = {
  beginDrag(props) {
    return {
      nextConfigId: props.nextConfig.id,
    };
  }
};

const ncTarget = {
  canDrop(props, monitor) {
    //let item = monitor.getItem();
    return true;
  },

  drop(props, monitor) {
    console.log('nextConfig dropped ! ', props, monitor.getItem());
    props.onDrop(props.nextConfig.id, monitor.getItem().nextConfigId);
  }
};


function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}


// CONNECT TO THE STATE

// const mapProps = (state, ownProps) => {
//   return ownProps;
// };

const mapHandlers = (dispatch) => {
  return {
    "onDrop": (targetId, droppedId) => {
      dispatch({type: ASSIGN_DROP_NEXTCONFIG, targetId:targetId, droppedId: droppedId});
    },
    "onChangeLateThreshold": (id) => (dispatch(changeLateThreshold(id))),
    "onChangeMax": (id) => (dispatch(changeMaxStaff(id))),
    "onChangeMaxBuffer": (id) => (dispatch(changeMaxBuffer(id)))
  };
};

export const PrioItem = connect(null, mapHandlers)(
  DragSource("NextConfigPrio", ncSource, collectSource)(
    DropTarget("NextConfigPrio", ncTarget, collectTarget)(
      IPrioItem
    )
  )
);



