export const isSupport = user => (
  user && user.groups && user.groups.is_support
);

export const isSuperAdmin = user => (
  user && user.groups && user.groups.is_super_admin
);

export const isAdmin = user => (
  user && user.groups && user.groups.is_manager
);

export const isDockStaff = user => (
  user && user.groups && user.groups.is_dockstaff
);
