import {connect} from "react-redux";
import {validateChangeBags} from "../../actions";
import {MAIN_MODAL_HIDE} from "../../../../actions/modal";

import ChangeNumberOfBagsModalContent from "../../../../components/modal/ChangeNumberOfBagsModalContent";


export const ChangeNumberOfBagsModal = connect(
  (state) => ({
    "modal" : state.support.modal,
    "pseudoZones": state.support.meta ? state.support.meta.pseudoZones : [],
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MAIN_MODAL_HIDE});
      },
      "validate": (values) => {
        let extraPlacePzBags = [];
        for (let k in values) {
          if (k.startsWith('pz_')) {
            let x = k.split('_');
            extraPlacePzBags.push({
              pseudo_zone_id: parseInt(x[5], 10),
              number_of_bags_isotherm: values[k]
            });
          }
        }

        dispatch({'type': MAIN_MODAL_HIDE});

        // Compute number of bags from dry, iso and frozen bags
        const { orderId, number_of_bags_dry, number_of_bags_iso, number_of_bags_frozen } = values;
        let number_of_bags = number_of_bags_iso + number_of_bags_dry + number_of_bags_frozen;
        dispatch(validateChangeBags(
          orderId,
          number_of_bags,
          number_of_bags_iso,
          number_of_bags_frozen,
          extraPlacePzBags
        ));
      }
    };
  }
)(ChangeNumberOfBagsModalContent);
