import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";

import {} from "../actions";
import moment from "moment/moment";

import "../css/OrderOtherInfos.css";
import {showPopinGiftMessage, updateOrderGiftMessage, showPopinConfirm} from "../actions";




const InternalOrderOtherInfos = ({order, popinGiftMessage, popinDeleteGiftMessage}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');

  return <Table size="sm" hover className="OrderDeliveryDetail">
    <tbody>
      <tr><th colSpan={2}>Infos Divers</th></tr>
      <tr>
        {order.gift_message !== ''
          ? <td className="alert alert-warning"><strong>Option carte cadeau</strong><p className="display-linebreak">{order.gift_message}</p></td>
          : <td>Option carte cadeau : Aucune</td>
        }
        <td>
          <Button variant="primary" size="xs"  onClick={() => popinGiftMessage(order) } style={{marginBottom: '3px', marginRight: '3px'}} disabled={order.updating ? true : false}>
            <i className="fa-solid fa-pen-to-square"></i>
            {order.gift_message !== ''
              ? <span> changer le message</span>
              : <span> ajouter un message</span>
            }
          </Button>
          {order.is_gift
            ? <a href={process.env.REACT_APP_DELEEVADMIN + `/commandes/print_gift_message/${order.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-xs" style={{marginRight: '3px', marginBottom: '3px'}}><i className="fa-solid fa-print"></i> Imprimer</a>
            : null
          }
          {order.is_gift
            ? <Button variant="danger" size="xs" style={{marginBottom: '3px'}} onClick={() => popinDeleteGiftMessage(order.id) }>
              <i className="fa-solid fa-trash"></i><span> suppr. le message</span>
            </Button>
            : null
          }
        </td>
      </tr>
    </tbody>
  </Table>;
};

InternalOrderOtherInfos.propTypes = {
  "order": PropTypes.object,
  "popinGiftMessage": PropTypes.func,
  "popinDeleteGiftMessage": PropTypes.func,
};

export const OrderOtherInfos = connect(
  null,
  (dispatch) => {
    return {
      "popinGiftMessage": (order) => dispatch(showPopinGiftMessage(order)),
      "popinDeleteGiftMessage": (orderId) => dispatch(showPopinConfirm(
        'Êtes vous certain de vouloir supprimer le message ?',
        updateOrderGiftMessage(orderId, '')
      ))
    };
  }
)(InternalOrderOtherInfos);

