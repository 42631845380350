import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Table } from "react-bootstrap";
import moment from 'moment';

const StuartContent = ({ externalJob }) => (
  <Tabs>
    <Tab eventKey="job" title='Job'>
      <Table striped hover size="sm">
        <thead>
          <tr>
            <th colSpan={2}>Général</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Numéro de la course</td>
            <td>{externalJob.id}</td>
          </tr>
          <tr>
            <td>Statut</td>
            <td>{externalJob.status}</td>
          </tr>
          <tr>
            <td>Commentaire</td>
            <td>{externalJob.comment}</td>
          </tr>
          <tr>
            <td>Distance</td>
            <td>{externalJob.distance} km</td>
          </tr>
          <tr>
            <td>Durée</td>
            <td>{externalJob.duration} minutes</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={2}>Coursier</th>
          </tr>
        </thead>
        {externalJob.driver && (
          <tbody>
            <tr>
              <td>id</td>
              <td>{externalJob.driver.id}</td>
            </tr>
            <tr>
              <td>Nom</td>
              <td>{externalJob.driver.display_name}</td>
            </tr>
            <tr>
              <td>Téléphone</td>
              <td>{externalJob.driver.phone}</td>
            </tr>
          </tbody>
        )}
        <thead>
          <tr>
            <th colSpan={2}>Prix</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Prix</td>
            <td>{externalJob.pricing.price_tax_excluded}</td>
          </tr>
          <tr>
            <td>Prix TTC</td>
            <td>{externalJob.pricing.price_tax_included}</td>
          </tr>
        </tbody>
      </Table>
    </Tab>
    <Tab eventKey="deliveries" title='Livraisons'>
      <Table striped hover size="sm">
        {externalJob.deliveries.map(delivery => (
          <React.Fragment key={delivery.id}>
            <thead>
              <tr>
                <th>Numéro de la livraison</th>
                <th>{delivery.id}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Statut</td>
                <td>{delivery.status}</td>
              </tr>
              <tr>
                <td>Récupéré au centre à</td>
                <td>{moment(delivery.picked_at).format('DD/MM/YYYY à HH:mm')}</td>
              </tr>
              <tr>
                <td>Livrée à</td>
                <td>{moment(delivery.delivered_at).format('DD/MM/YYYY à HH:mm')}</td>
              </tr>
              {delivery.proof &&
                <tr>
                  <td colSpan={2}>
                    <a
                      className="btn btn-primary btn-xs"
                      href={delivery.proof.signature_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preuve de livraison
                    </a>
                  </td>
                </tr>
              }
              {delivery.cancellation.canceledBy &&
                <tr>
                  <td>Annulé par</td>
                  <td>{delivery.cancellation.canceledBy}</td>
                </tr>
              }
              {delivery.cancellation.reason_key &&
                <tr>
                  <td>Raison de l'annulation</td>
                  <td>{delivery.cancellation.reason_key}</td>
                </tr>
              }
              {delivery.cancellation.comment &&
                <tr>
                  <td>Commentaire de l'annulation</td>
                  <td>{delivery.cancellation.comment}</td>
                </tr>
              }
            </tbody>
          </React.Fragment>
        ))}
      </Table>
    </Tab>
  </Tabs>
);

StuartContent.propTypes = {
  externalJob: PropTypes.object,
};

export default StuartContent;
