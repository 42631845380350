import {createSelector} from 'reselect';
import {maxShippingSorting} from "../utils/task";


const getTasks = state =>  state.m.tasks_blocked;

export const getSortedTasks = createSelector(
  [getTasks],
  (tasks) => {
      return tasks.slice().sort(maxShippingSorting);
  }
)
