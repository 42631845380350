import React from 'react';
import moment from "moment";
import 'moment/locale/fr';

import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from "react-redux";
import {
  assignDock,
  unlockDock
} from "../../../actions/dock";

moment.locale('fr');

// eslint-disable-next-line react/prop-types
const IGoCancel = ({dock, onAssignDock, onUnlockDock}) => {

  let has_moving_tasks = false;
  dock.tasks.forEach((task) => {
    if (task.dock_target) {
      has_moving_tasks = true;
    }
    if (has_moving_tasks === false) {
      for (var opz of task.linked_order.pseudo_zones_status) {
        if (opz.is_bag_picking_late && opz.is_done) {
          if (opz.transit_subarea !== task.linked_order.transit_area) {
            //has_moving_tasks = true;
            break;
          }
        }
      }
    }
  });
  let frozen = null;
  if (
    dock.isLock
    && typeof(dock.load_info.is_expe_frozen) !== "undefined"
    && dock.load_info.is_expe_frozen === true
  ) {
    let now = moment(dock.load_info.started_at);
    const tooltip = (
      <Tooltip id={`tooltip-loader-${dock.id}`}>
        {dock.load_info.user_name} depuis {now.hour()}h{now.minute()}
      </Tooltip>
    );
    frozen = (
      <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
        <span><i className="frozen-icon fa-solid fa-snowflake" title="Expédition Surgelé en cours"></i> en cours</span>
      </OverlayTrigger>
    );
  } 
  if (onAssignDock && dock.assignable && dock.loaded_at && !has_moving_tasks) {
    return (
      <Button
        onClick={() => onAssignDock(dock)}
        size="xs"
        variant={dock.isLock ? "default" : "success"}
        disabled={!!dock.isLock}>
        {dock.isLock && frozen ? frozen : <span>GO</span>}
      </Button>
    );
  }
  if (dock.tasks.length > 0 && onUnlockDock && dock.locked_at && !dock.gone_at && dock.target_driver) {
    return (
      <Button
        className="GoCancel"
        onClick={() => onUnlockDock(dock)}
        size="xs"
        variant={dock.isLock ? "default" : "warning"}
        disabled={!!dock.isLock || dock.driver.daily_refusals_limit_exceeded}>ANNULER</Button>
    );
  }
  return (<span></span>);
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return {
    "onAssignDock":         (dock) => dispatch(assignDock(dock)),
    "onUnlockDock":         (dock) => dispatch(unlockDock(dock)),
  };
};

export const GoCancel = connect(mapStateToProps, mapDispatchToProps)(IGoCancel);
