import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'react-bootstrap';

import { api } from '../../../../api';
import StuartContent from './StuartContent';
import UberContent from './UberContent';


const ExternalJobModal = ({ show, onHide, jobId }) => {
  const [externalJob, setExternalJob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => { 
    const fetchExternalJob = async (id) => {
      try {
        const res = await api.getStuartJob(id);
        setExternalJob(res);
        setLoading(false);
      } catch (e) {
        if (e.response.data && e.response.data.error) {
          console.log(e.response.data.error);
        } else {
          console.log('error: not allowed to get Stuart job', e.response.status);
        }
      }
    };

    jobId && fetchExternalJob(jobId);
  }, [jobId]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>Livraison #{jobId}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {(loading && !externalJob)
          ? <p>Chargement...</p>
          : externalJob.provider === 'STUART'
            ? <StuartContent externalJob={externalJob} />
            : <UberContent externalJob={externalJob} />
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} >Fermer</Button>
      </Modal.Footer>
    </Modal>
  );
};

ExternalJobModal.propTypes = {
  jobId: PropTypes.number,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ExternalJobModal;
