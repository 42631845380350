/* eslint-disable react/prop-types */
import React from 'react';
//import { Field, reduxForm } from 'redux-form';
import {connect} from "react-redux";
import {LOGS_EXTRACOST_DELETE} from "../actions";

let InternalExtraCostDelete = ({ extracost, deleteExtraCost }) => {

  return (
    <div className='float-right edit ExtraCostDelete'>
      <button className="btn btn-xs btn-danger" onClick={() => deleteExtraCost(extracost.id)}>
        <i className="fa-solid fa-trash-can"></i>
      </button>
    </div>
  );
};


export const ExtraCostDelete = connect(
  null,
  (dispatch) => {
    return {
      deleteExtraCost : (extracost_id) => {
        if (window.confirm("Êtes vous sûr de vouloir supprimer ce surcoût ?")) {
          dispatch({type: LOGS_EXTRACOST_DELETE, id: extracost_id});
        }
      }
    };
  }
)(InternalExtraCostDelete);
