import React from "react";
import {connect} from "react-redux";
import {Dropdown, DropdownButton, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {
  METAS_RAIN_BONUS_SAVE,
} from "../../../actions/index";

import "../../../css/ExtraDelay.css";


// component
const InternalRainBonus = ({rain_bonuses, rain_bonus_id, updating, save}) => {
    let classNames = "RainBonus metas";
    if (updating) {
      classNames += " updating";
    }

    const getRainBonusIcon = (rainBonusId) => {
      switch (rainBonusId) {
        case 1: return <i className="fa-solid fa-cloud-sun"></i>;
        case 2: return <i className="fa-solid fa-cloud-showers-heavy"></i>;
        case 3: return <i className="fa-solid fa-bolt"></i>;
        default: return '';
      }
    };

    let title = "Prime pluie";
    let selectedRainBonus = null;
    let dropDownTitle = ' Valeur non chargée';
    if (rain_bonuses) {
      selectedRainBonus = rain_bonuses.find((rain_bonus) => rain_bonus.id === rain_bonus_id);
      if (selectedRainBonus) {
        dropDownTitle = " " + selectedRainBonus.label;
      }
    }

    return (
      <div className={classNames}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <Form.Label>{title}</Form.Label>{' '}
            <DropdownButton
                className="d-inline mx-2"
                size="xs"
                variant={"default"}
                title={
                <React.Fragment>
                    {rain_bonus_id ? getRainBonusIcon(rain_bonus_id) : null}
                    {dropDownTitle}
                </React.Fragment>
                }
                key={"rain_bonus"}
                id={"rain_bonus"}
            >
              {rain_bonuses ?
                rain_bonuses.map(
                  rain_bonus => {
                    return <Dropdown.Item key={rain_bonus.id}
                                          onClick={() => save(rain_bonus.id)}
                    >{getRainBonusIcon(rain_bonus.id)} {rain_bonus.label}</Dropdown.Item>
                  }
                )
                : null
              }
            </DropdownButton>
          </FormGroup>
        </Form>
      </div>
    );
}

InternalRainBonus.propTypes = {
  rain_bonuses: PropTypes.array.isRequired,
  rain_bonus_id: PropTypes.number.isRequired,
  updating: PropTypes.any,
  save: PropTypes.func.isRequired,
};

// link
export const RainBonus = connect(
  (state) => {
    let metas = state.m.metas;
    // if (state.m.metas.rain_bonuses) {
    //   metas = state.m.metas;
    // }
    if (state.dispatch.metas.rain_bonuses) {
      metas = state.dispatch.metas;
    }
    return {
        rain_bonuses : metas.rain_bonuses,
        rain_bonus_id : metas.rain_bonus_id,
        updating : metas.updating,
    };
  },
  (dispatch) => {
    return {
      save : (value) => {
        dispatch({type: METAS_RAIN_BONUS_SAVE, value: value});
      }
    };
  }
)(InternalRainBonus);
