import React from 'react';
import PropTypes from "prop-types";

import {Address, UserName, Slot, Sms, NumberOfProducts, Packer} from "./Common";
import ReadyToGoButton from "./ReadyToGoButton";
import TaskName from "./TaskName";
import SelectStatus from "./SelectStatus";

import '../css/Row.css';
import {TransitArea} from "./TransitArea";
import {NumberOfBags} from "./NumberOfBags";
import {BtnOptimizeInternal} from "../apps/dispatch/components/Docks/Dock/BtnOptimize"
import {NumberOfCanteenOrders} from "../common-comps/NumberOfCanteenOrders";


export const PackingRow = ({task, handlerDnd, connectDragPreview, connectDropTarget, className, show}) => {

  let style = {};
  if (task.updating) {
    style.color = 'Lightgrey';
  }

  let cName = "PackingRow Row " + className + ' ' + task.linked_order.status;
  if(task.client.is_vip){
    cName += " vip_warning";
  }
  else if(task.linked_order.is_super_venere){
    cName += " venere_warning";
  }
  // also done in PackingActivity
  show = {...{prep:false, startDate: false, nbProducts: true, nbBags: false, transitArea: false}, ...(show ? show : {})};

  const row = (
    <tr style={style} className={cName} >
      {handlerDnd ? handlerDnd(<td className="draggable"></td>) : null}
      <td title={task.id}>
        <TaskName task={task}/>
        {/*{task.emergency_rank ? <span>!{task.emergency_rank}</span> : null}*/}
      </td>
      {show.departure
      ? <td
          style={{
              backgroundColor: (
                  task.dock && (
                      task.dock.target_driver
                      || (
                          !task.dock.num_tasks_before_waiting
                          && task.dock.departure
                          && task.dock.departure.length
                          && ((new Date(task.dock.departure[0]).getTime()) - (new Date()).getTime())
                          < 4 * 3600 * 1000 // 4 heures
                      )
                  )
                  ? "red"
                  : "transparent"
              )
          }}
        >
          {task.dock
          ? <BtnOptimizeInternal dock={task.dock} type={"missing"} optimize={()=> {}}/>
          : null}
        </td>
      : null
      }
      {show.nbProducts ? <td><NumberOfProducts task={task}/></td> : null}
      {
        show.nbBags
        ? (
          <td>
            {
              task.linked_order && task.linked_order.canteen_witness
              ? <NumberOfCanteenOrders order={task.linked_order}/>
              : <NumberOfBags order={task.linked_order}/>
            }
          </td>
        )
        : null
      }
      {show.transitArea ? <td><TransitArea order={task.linked_order}/></td> : null}
      <td>
        <UserName task={task}/>
      </td>
      <td>
        {task.address ? <Address address={task.address} center_id={task.linked_order.center_id}/> : null}
      </td>
      {show.prep ? <td><Packer task={task}/></td> : null}
      {show.fraud ? <td>
        <a href={process.env.REACT_APP_DELEEVADMIN + `/fraud/details/${task.client.id}/?order_id=${task.linked_order.id}`} rel="noopener noreferrer" title="Voir l'analyse de fraude" target="_blank">Analyse de fraude <i className="fa-solid fa-bullseye"></i></a>
      </td> : null}
      {show.stateDate ? <td>{task.startDateFormated}</td> : null}
      <td><Slot task={task}/></td>
      <td> <SelectStatus order={task.linked_order} task={task} /> </td>
      <td>
        <Sms task={task} />
        {['waiting-products', 'to-check'].includes(task.linked_order.status) ? <ReadyToGoButton task={task} /> : null}
      </td>
    </tr>
  );

  return (handlerDnd ? connectDragPreview(connectDropTarget(row)) : row);
};

PackingRow.propTypes = {
  task: PropTypes.object.isRequired,
  show: PropTypes.object,
  className: PropTypes.string,
  handlerDnd: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func
};

export default PackingRow;
