import React from 'react';
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import {IncomingPlanningsSessionRow} from "./IncomingPlanningsSessionRow";



export const IncomingPlanningsSessionList = ({planningsSessions, title, className, startSooner, showAll, onToggle}) => {
  return (
    <div className={"IncomingPlanningsSessionList " + className}>
      <h4>
        {title} : {planningsSessions.length}
        {onToggle ? <button className="btn btn btn-default btn-xs float-right" onClick={(e) => onToggle()}  >{showAll ? "Cacher" : "Afficher"}</button> : null}
      </h4>
      {planningsSessions.length > 0 && (showAll || !onToggle) &&
      <Table bordered size="sm" hover striped>
        <thead>
          <tr>
            <th>Préparateur</th>
            <th>Date heure de début</th>
            <th>Commencer</th>
          </tr>
        </thead>
        <tbody>
          {planningsSessions.map(ps => (<IncomingPlanningsSessionRow key={ps.id} planningsSession={ps} startSooner={startSooner}/>))}
        </tbody>
      </Table>
      }
    </div>
  );
};

IncomingPlanningsSessionList.propTypes = {
  planningsSessions: PropTypes.array.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  showAll: PropTypes.bool,
  onToggle: PropTypes.func
};
