import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dock from "./Dock";
import DockDnd from "./DockDnd";
import {
  SUB_DISPATCH_CODE_LIGHT_CREATION_1,
  SUB_DISPATCH_CODE_LIGHT_LOADING_1,
  SUB_DISPATCH_CODES,
  SUB_DISPATCH_CONFIGURATIONS,
} from "../../sub_dispatchs";
import {
  STATUS_DRAFT,
  STATUS_MOVING,
  STATUS_READY_TO_LOAD,
  STATUS_LOADING,
  STATUS_LOADED,
  DOCK_TYPE_HARD_ASSIGNED,
  DOCK_TYPE_SOFT_ASSIGNED,
  DOCK_TYPE_STUART,
  DOCK_TYPE_UBER,
} from "../../utils/dock_n_driver";


function isScoot(dock){
  return (
    [DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock.dock_type)
    && dock.driver
    && dock.driver.vehicle === "SELEC"
  );
}

function isCar(dock){
  return (
    [DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock.dock_type)
    && dock.driver
    && dock.driver.vehicle === "CAR"
  );
}

function isStuartOrUber(dock){
  return [DOCK_TYPE_STUART, DOCK_TYPE_UBER].includes(dock.dock_type);
}

function driverDocksSort(a, b){
  // Les docks vides en premier.
  if(a.tasks.length > 0 && b.tasks.length === 0){ return 1; }
  if(a.tasks.length === 0 && b.tasks.length > 0){ return -1; }
  if(a.tasks.length === 0 && b.tasks.length === 0){
    // Triés par position dans la file d'attente.
    if(a.driver.queue_position > 0 && b.driver.queue_position > 0){
      return (a.driver.queue_position <= b.driver.queue_position ? -1 : 1);
    }
    if(a.driver.queue_position > 0 && b.driver.queue_position === 0){
      return -1;
    }
    if(a.driver.queue_position === 0 && b.driver.queue_position > 0){
      return 1;
    }
  }
  // Les refus en second.
  if(!a.accepted_by_driver && b.accepted_by_driver){
    return -1;
  }
  if(a.accepted_by_driver && !b.accepted_by_driver){
    return 1;
  }
  // Ou mieux les docks par ordre croissant de taille.
  // if(a.tasks.length < b.tasks.length){ return -1; }
  // if(a.tasks.length > b.tasks.length){ return 1; }
  // On trie les égalités jusque ici par le nom du livreur.
  return (a.target_name <= b.target_name ? -1 : 1);
}


export const DockList = (
  {
    docks,
    transit_areas,
    docks_extract,
    onDropDefault,
    onDropTask,
    onDropDock,
    title,
    show,
    className,
    showAll,
    onToggle,
    showDriverQueue,
    center_id,
    showDriversSoonModal,
    location,
  }
) => {
  const [showInLoadingDocks, setShowInLoadingDocks] = useState(true);

  const query = new URLSearchParams(location.search);
  const sub = query.get("sub");
  if(
    sub !== null
    && sub !== undefined
    && SUB_DISPATCH_CODES.indexOf(sub) !== -1
    && (
      (
        (
          title.startsWith("Tournées en cours de création")
          || title.startsWith("Tournées en création POUR DEMAIN")
        )
        && SUB_DISPATCH_CONFIGURATIONS[sub].withDockCurrentStatuses.indexOf(STATUS_DRAFT) === -1
      )
      ||
      (
        title.startsWith("Tournées en préparation")
        && SUB_DISPATCH_CONFIGURATIONS[sub].withDockCurrentStatuses.indexOf(STATUS_MOVING) === -1
      )
      ||
      (
        title.startsWith("Tournées Prêtes à livrer")
        && SUB_DISPATCH_CONFIGURATIONS[sub].withDockCurrentStatuses.indexOf(STATUS_READY_TO_LOAD) === -1
        && SUB_DISPATCH_CONFIGURATIONS[sub].withDockCurrentStatuses.indexOf(STATUS_LOADING) === -1
      )
      ||
      (
        title.startsWith("En livraison")
        && SUB_DISPATCH_CONFIGURATIONS[sub].withDockCurrentStatuses.indexOf(STATUS_LOADED) === -1
      )
    )
  ){
    return null;
  }

  function dockToComponent(dock){
    if(onDropDock){
      return (
        <DockDnd
          key={dock.id}
          dock={dock}
          transit_areas={transit_areas}
          docks_extract={docks_extract}
          docks={docks}
          onDropDefault={onDropDefault}
          onDropTask={onDropTask}
          onDropDock={onDropDock}
          show={show}
        />
      );
    }
    else{
      return (
        <Dock
          key={dock.id}
          dock={dock}
          transit_areas={transit_areas}
          docks_extract={docks_extract}
          docks={docks}
          onDropDefault={onDropDefault}
          onDropTask={onDropTask}
          show={show}
        />
      );
    }
  }

  if (!className) {
    className = '';
  }

  className += ' container-fluid';

  let title_h4 = null;
  if(title){
    title_h4 = (
      <h4 className="row">
        {title}
        {
          onToggle
          ? (
            <button className="btn btn-default btn-xs float-right" onClick={() => onToggle()}  >
              {showAll ? "Cacher" : "Afficher"}
            </button>
          )
          : null
        }
        {
          showDriverQueue
          ? (
            <button
              className="btn btn-default btn-xs float-right"
              onClick={() => showDriverQueue(center_id)}
            >
              Prio livreur
            </button>
          )
          : null
        }
        {
          showDriversSoonModal
          ? <button
              className="btn btn-default btn-xs float-right"
              onClick={showDriversSoonModal}
            >
              Livreurs
            </button>
          : null
        }
        {
          sub === SUB_DISPATCH_CODE_LIGHT_LOADING_1
          && title.startsWith("Tournées Prêtes à livrer")
          ? (
            <button className="btn btn-default btn-xs float-right" onClick={() => setShowInLoadingDocks(!showInLoadingDocks)}>
              {showInLoadingDocks ? "Cacher les tournées acceptées et en chargement" : "Afficher les tournées acceptées et en chargement"}
            </button>
          )
          : null
        }
      </h4>
    );
  }

  if(
    sub === SUB_DISPATCH_CODE_LIGHT_LOADING_1
    && title.startsWith("Tournées Prêtes à livrer")
  ){
    let listDocksNotAffected = null;
    let listDocksDriverScoot = null;
    let listDocksDriverCar = null;
    let listDocksStuartUber = null;
    if(!showInLoadingDocks){
      docks = docks.filter(dock => dock.locked_at === null || !dock.accepted_by_driver);
    }

    if (showAll || !onToggle) {
      listDocksNotAffected = docks.filter(
        dock => !isScoot(dock) && !isCar(dock) && !isStuartOrUber(dock)
      ).map((dock) => dockToComponent(dock));
      listDocksDriverScoot = docks.filter(
        dock => isScoot(dock)
      ).sort(driverDocksSort).map((dock) => dockToComponent(dock));
      listDocksDriverCar = docks.filter(
        dock => isCar(dock)
      ).sort(driverDocksSort).map((dock) => dockToComponent(dock));
      listDocksStuartUber = docks.filter(
        dock => isStuartOrUber(dock)
      ).map((dock) => dockToComponent(dock));
    }

    className += " deliveries-docks-col-12";
    return (
      <div className={className}>
        {title_h4}
        <div className="row">
          <div className="col-3">
            <h5 style={{backgroundColor: "#f5defd"}}>Non affectées</h5>
            {listDocksNotAffected}
          </div>
          <div className="col-3">
            <h5 style={{backgroundColor: "#f5defd"}}>Livreur scoot</h5>
            {listDocksDriverScoot}
          </div>
          <div className="col-3">
            <h5 style={{backgroundColor: "#f5defd"}}>Livreur voiture</h5>
            {listDocksDriverCar}
          </div>
          <div className="col-3">
            <h5 style={{backgroundColor: "#f5defd"}}>Stuart, Uber</h5>
            {listDocksStuartUber}
          </div>
        </div>
      </div>
    );
  }

  let listDocks = null;
  if (showAll || !onToggle) {
    listDocks = docks.map((dock) => dockToComponent(dock));
  }

  if(
    sub === SUB_DISPATCH_CODE_LIGHT_CREATION_1
    && (
      title.startsWith("Tournées en cours de création")
      || title.startsWith("Tournées en préparation")
      || title.startsWith("Tournées Prêtes à livrer")
    )
  ){
    className += " deliveries-docks-col-12";
  }
  return (
    <div className={className}>
      {title_h4}
      <div className="row">{listDocks}</div>
    </div>
  );
};

DockList.propTypes = {
  docks: PropTypes.array.isRequired,
  transit_areas: PropTypes.object,
  docks_extract: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
  onDropDefault: PropTypes.func,
  onDropTask: PropTypes.func,
  onDropDock: PropTypes.func,
  show: PropTypes.object,
  showAll: PropTypes.bool,
  onToggle: PropTypes.func,
  showDriverQueue: PropTypes.func,
  center_id: PropTypes.number,
  showDriversSoonModal: PropTypes.func,
};

export default DockList;
