import React from 'react';
import {connect} from "react-redux";
import {Col, FormGroup, Modal, Row} from "react-bootstrap";
import {Formik, Field} from 'formik';
import moment from  'moment-timezone';
import {SUPPORT_MODAL_HIDE, updateOrderGiftMessage, showPopinConfirm} from "../actions";



class InternalGiftMessageModal extends React.Component {


  render() {
    const {modal, hide, validate} = this.props;
    moment.locale('fr');

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'gift_message'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="GiftMessageModal"
    >
      <Modal.Body id="gift_message_modal">
        <h4>Message carte cadeau</h4>
        <Formik
          onSubmit={(values, actions) => {
            validate(modal.order.id, values.gift_message)
          }}
          render={ (props: FormikProps<Values>) => (
            <form onSubmit={props.handleSubmit} className="form-horizontal">
              <FormGroup as={Row} controlId="formHorizontalSlot">
                <Col  sm={9}>
                  <Field component="textarea" col="8" row="9" name="gift_message" className="form-control" defaultValue={modal.order.gift_message} autoFocus={true}></Field>
                </Col>
                <Col  sm={3}>
                  <button className="btn btn-primary btn-small float-right" type="submit">
                    Modifier
                  </button>
                </Col>
              </FormGroup>
            </form>
          )}
        />

      </Modal.Body>
    </Modal>;
  }
}

export const GiftMessageModal = connect(
  (state) => ({
    "modal" : state.support.modal
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (orderId, gift_message) => {
        if (typeof gift_message !== 'undefined') {
          gift_message = gift_message.trim();
          if (gift_message === '') {
            dispatch(showPopinConfirm(
              'Êtes vous certain de vouloir supprimer le message ?',
              updateOrderGiftMessage(orderId, '')
            ))
          } else {
            dispatch({'type': SUPPORT_MODAL_HIDE});
            dispatch(updateOrderGiftMessage(orderId, gift_message));
          }
        } else {
          dispatch({'type': SUPPORT_MODAL_HIDE});
        }
      }
    };
  }
)(InternalGiftMessageModal);
