import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Button} from "react-bootstrap";

import {act_dockToggleExtract, act_dockExtractTask} from "../../../actions/dock";
import {STATUS_ORDER_PREPARED} from "../../../../../utils/status";


// eslint-disable-next-line react/prop-types
const Internal = ({dock, toggleExtract, extractTasks, docks_extract}) => {

  // Impossible d'extraire si dock livreur
  if (dock.target_driver && (dock.locked_at || dock.gone_at)) {
    return null;
  }

  // Il faut au moins 2 tâches dans le dock pour pouvoir les extraire.
  if (!dock.tasks || dock.tasks.length < 2) {
    return null;
  }

  let dock_extract = null;
  if (Object.keys(docks_extract).includes("" + dock.id)) {
    dock_extract = docks_extract[dock.id];
  }
  let stuckTransitAreas = new Set();
  const notFinalizedTasks = dock.tasks.filter(
      task => task.linked_order.status !== STATUS_ORDER_PREPARED
  );
  if(notFinalizedTasks && notFinalizedTasks.length) {
    notFinalizedTasks.forEach(task => stuckTransitAreas.add(task.linked_order.transit_area));
  }
  const finalized_tasks_ids = dock.tasks.filter(
    task => (
      task.linked_order.status === STATUS_ORDER_PREPARED
      && !stuckTransitAreas.has(task.linked_order.transit_area)
    )
  ).map(task => task.id);
  return (<span className="BtnExtractTask">
    <Button
      className="BtnExtractTask toggle"
      size="xs"
      onClick={() => toggleExtract(dock)}
      variant={dock_extract !== null ? "primary" : "default"}
      disabled={!!dock.updating}>
      <i className="fa-solid fa-file-export"></i>
    </Button>
    {dock_extract !== null && dock_extract.length > 0 && 
      <Button
        className="BtnExtractTask extract"
        size="xs"
        onClick={() => extractTasks(dock, dock_extract)}
        variant={"success"}
        disabled={!!dock.updating}>
        <i className="fa-solid fa-file-export"></i> Extraire {dock_extract.length}
      </Button>
    }
    {
      dock.tasks.length > 0 && !dock.loaded_at
      ? (
        <Button
          className="BtnExtractTask extract ml-3"
          size="xs"
          onClick={() => extractTasks(dock, finalized_tasks_ids)}
          variant={"primary"}
          disabled={!!dock.updating}>
          <i className="fa-solid fa-file-export"></i> F
        </Button>
      )
      : null
    }
  </span>);
};

Internal.propTypes = {
  dock: PropTypes.object.isRequired,
  docks_extract: PropTypes.object,
  toggleExtract: PropTypes.func.isRequired,
  extractTasks: PropTypes.func.isRequired,
};

export const BtnExtractTask = connect(
  (state) => {
    return {
      docks_extract: state.dispatch.docks_extract,
    };
  },
  (dispatch) => {
    return {
      "toggleExtract": (dock) => {
        dispatch(act_dockToggleExtract(dock));
      },
      "extractTasks": (dock, task_ids) => {
        dispatch(act_dockExtractTask(dock, task_ids));
      },
    };
  }
)(Internal);


export default BtnExtractTask;
