import {connect} from "react-redux";
import DockList from "../components/DockList";
import {dockListDeliveriesHandlers, dockListDeliveriesProps} from "./common-props/dock";
import {getComputedDockListDoing} from "../selectors/dock";
import {withRouter} from "react-router-dom";

let DockListLinked = withRouter(connect(
  (state) => dockListDeliveriesProps(state, getComputedDockListDoing(state)),
  (dispatch) => dockListDeliveriesHandlers(dispatch)
)(DockList));


export default DockListLinked;
