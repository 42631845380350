import { connect } from "react-redux";
import PackingList from "../components/PackingList";
import { getSortedTasks } from "../selectors/tasks_stand_by_list";
import { SWITCH_TOGGLE } from "../actions/switch_toggle";
import { currentToggleValue, PACKING_XD_STAND_BY_KEY } from "../utils/toggle";

export default connect(
  state => ({
    tasks: getSortedTasks(state),
    title: "Commandes crossdocking en stand by",
    className: 'stand-by-list',
    show : {
      nbBags: true,
      transitArea: true,
      nbProducts: true,
      nothingIfEmpty: true
    },
    showAll: currentToggleValue(state.toggle, PACKING_XD_STAND_BY_KEY),
  }),
  dispatch => ({
    onToggle: () => dispatch({ type: SWITCH_TOGGLE, key: PACKING_XD_STAND_BY_KEY }),
  })
)(PackingList);
