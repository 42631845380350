let transitAreas = {};

transitAreas.loaded = function (action, state) {
  /*
   * action = liste de transitareas
   */
  return {
    ...state,
    transit_areas: {
      ...state.transit_areas,
      ...action.transit_areas
    }
  };
};
transitAreas.changed = function(action, state) {
  /*
   * action = order
   */
  return {
    ...state,
    deliveries: state.deliveries.map(
      (task) => task.linked_order.id === action.id ? {
        ...task,
        linked_order: {
          ...task.linked_order,
          transit_area: action.transit_area,
          transit_area_current: action.transit_area_current,
          transit_area_target_type: action.transit_area_target_type,
          pseudo_zones_status: action.pseudo_zones_status,
        }
      } : task
    ),
    packings: state.packings.map(
      (task) => task.linked_order.id === action.id ? {
        ...task,
        linked_order: {
          ...task.linked_order,
          transit_area: action.transit_area,
          transit_area_current: action.transit_area_current,
          transit_area_target_type: action.transit_area_target_type,
          pseudo_zones_status: action.pseudo_zones_status,
        }
      } : task
    ),
  };
};

export default transitAreas;
