import {
  MODAL_SHOW__REPREPARATION_REASON,
  MODAL_HIDE__REPREPARATION_REASON,
  MODAL_SHOW__BLOCKING_EXIT_REASON,
  MODAL_SHOW__BLOCKING_EXIT_REASON__AFTER_LOAD,
  MODAL_HIDE__BLOCKING_EXIT_REASON,
  MODAL_SHOW__CANTEEN_ORDERS,
  MODAL_HIDE__CANTEEN_ORDERS,
} from "../actions/commonModals";

let commonModalsInitialState = {
  repreparationReason: {},
  blockingExitReason: {},
  canteenOrders: {},
};

export const commonModalsReducer = (state = commonModalsInitialState, action) => {
  switch (action.type) {
  case MODAL_SHOW__REPREPARATION_REASON: {
    return {
      ...state,
      repreparationReason: {...action.data},
    };
  }
  case MODAL_HIDE__REPREPARATION_REASON: {
    return {
      ...state,
      repreparationReason: {},
    };
  }
  case MODAL_SHOW__BLOCKING_EXIT_REASON: {
    return {
      ...state,
      blockingExitReason: {...action.data},
    };
  }
  case MODAL_SHOW__BLOCKING_EXIT_REASON__AFTER_LOAD: {
    return {
      ...state,
      blockingExitReason: {...state.blockingExitReason, fraud_detail: action.fraud_detail},
    };
  }
  case MODAL_HIDE__BLOCKING_EXIT_REASON: {
    return {
      ...state,
      blockingExitReason: {},
    };
  }
  case MODAL_SHOW__CANTEEN_ORDERS: {
    return {
      ...state,
      canteenOrders: {...action.data},
    };
  }
  case MODAL_HIDE__CANTEEN_ORDERS: {
    return {
      ...state,
      canteenOrders: {},
    };
  }
  default: {
    return state;
  }
  }
};
