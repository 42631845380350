import {connect} from "react-redux";
import DeliveriesTodo from "../components/DeliveriesTodo";
import {dropOnDefault, dropOnTask} from "../actions";
import {cleanRowsSelected, toggleRowSelected, toggleDispatchWaitingTasks} from "../actions/deliveries_todo";
import {getSortedTasks} from "../selectors/deliveries_todo_linked";
import {getSortedPackingDoing} from "../selectors/packing";

const mapStateToProps = state => {
  let props = {
    tasks: getSortedTasks(state),
    countDeliveries: state.m.deliveries.length,
    selectedTasksIds: state.deliveriesTodo.selectedTasksIds,
    canDispatchWaitingTasks: state.deliveriesTodo.canDispatchWaitingTasks,
    packingDoing: getSortedPackingDoing(state),
  };
  return props;
};

const mapDispatchToProps = dispatch => {
  return {
    "onDropTask": (targetTask, droppedTask) => {
      dispatch(dropOnTask(targetTask, droppedTask));
    },
    "onDropDefault": (dock, droppedTask) => {
      dispatch(dropOnDefault(dock, droppedTask));
    },
    "onCleanSelected": () => {
      dispatch(cleanRowsSelected());
    },
    "toggleSelect": (taskId) => dispatch(toggleRowSelected(taskId)),
    "toggleDispatchWaitingTasks": () => dispatch(toggleDispatchWaitingTasks()),
  };
};


let DeliveriesTodoLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveriesTodo);


export default DeliveriesTodoLinked;
