import React from 'react';
import {
  getStatuses,
  getCurrentStatus,
  STATUS_ORDER_REDESTOCK,
} from "../../../utils/status";
import {FormControl, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SUPPORT_ORDER_STATUS_UPDATE} from "../actions";
import {ORDER_REDESTOCK} from "../../../actions";
import {STATUS_ORDER_PAID, STATUS_ORDER_BLOCKED,} from "../../../utils/status";
import {
  MODAL_SHOW__REPREPARATION_REASON,
  MODAL_HIDE__REPREPARATION_REASON,
  MODAL_SHOW__BLOCKING_EXIT_REASON,
  MODAL_HIDE__BLOCKING_EXIT_REASON,
} from "../../../actions/commonModals";


class InternalSelectStatus extends React.Component {

  handleChange(event) {
    if (event.target.value === STATUS_ORDER_PAID) {
      if (!window.confirm("Êtes-vous sûr de vouloir passer en statut Payé ?")) {
        return false;
      }
    }
    this.props.onChangeStatus(event.target.value);
  }


  render() {
    const order = this.props.order;

    const statuses = getStatuses();
    const status = getCurrentStatus(order);

    let possible_statuses = null;
    let is_open_modal_button = false;
    let open_modal_function = null;
    if(order.cross_docking !== null){
      possible_statuses = this.props.crossdockStatusTransitions[order.cross_docking][status];
    }
    else{
      if(order.tags.indexOf("subscription") !== -1){
        possible_statuses = this.props.orderStatuses.SUBSCRIPTION_ALLOWED_TRANSITIONS[status];
      }
      else{
        possible_statuses = this.props.orderStatuses.ALLOWED_TRANSITIONS[status];
        if(status === STATUS_ORDER_BLOCKED){
          is_open_modal_button = true;
          open_modal_function = this.props.onClickBlockedStatus;
        }
      }
    }

    if(is_open_modal_button){
      let option = statuses.find(option => option.value === status);
      return (
        <button onClick={open_modal_function} className="btn btn-xs btn-default">{option.text}</button>
      );
    }

    let listOptions = statuses.filter((option) => {
      if (possible_statuses) {
        if(
          option.value === status
          || possible_statuses.includes(option.value)
        ){
          return true;
        }
        return false;
      }
      return true;
    }).map((option, index) => {
      //
      return (<option key={order.id + ':' + index} value={option.value} >{option.text}</option>);
    });

    return (
      <FormGroup className="SelectStatus" controlId="formControlsSelect">
        <FormControl
            as="select"
            placeholder="select"
            onChange={this.handleChange.bind(this)}
            value={status}
            disabled={order.updating ? true : false}
        >
          {listOptions}
        </FormControl>
      </FormGroup>
    );
  }
}

InternalSelectStatus.propTypes = {
  order: PropTypes.object.isRequired,
  crossdockStatusTransitions: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onClickBlockedStatus: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

const SupportSelectStatus = connect(
  (state) => {
    return {
      crossdockStatusTransitions: state.support.meta.crossdock_status_transitions,
      orderStatuses: state.support.meta.order_statuses,
    };
  },
  (dispatch, ownProps) => {
    return {
      re_destock: (orderId) => dispatch({
        type: ORDER_REDESTOCK,
        orderId: orderId
      }),
      cancelFunction: () => {
        dispatch({type: MODAL_HIDE__REPREPARATION_REASON})
        dispatch({type: MODAL_HIDE__BLOCKING_EXIT_REASON})
      },
      submitPreFunction: (newStatus, additionalData) => {
        dispatch(
          {
            type: SUPPORT_ORDER_STATUS_UPDATE,
            orderId: ownProps.order.id,
            newStatus: newStatus,
            additionalData: additionalData,
          }
        );
        dispatch({type: MODAL_HIDE__REPREPARATION_REASON});
        dispatch({type: MODAL_HIDE__BLOCKING_EXIT_REASON})
      },
      preShowRepreparationReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__REPREPARATION_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            newOrder: false,
          }
        }
      ),
      preShowBlockingExitReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__BLOCKING_EXIT_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            order: ownProps.order,
            customer: ownProps.customer,
          }
        }
      ),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onChangeStatus: (newStatus) => {
        if(newStatus === STATUS_ORDER_REDESTOCK){
          dispatchProps.re_destock(ownProps.order.id);
          return;
        }
        const submitFunction = (additionalData) => dispatchProps.submitPreFunction(newStatus, additionalData);
        if(
          newStatus === STATUS_ORDER_PAID
          && getCurrentStatus(ownProps.order) in stateProps.orderStatuses.ORIGIN_STATUSES_FOR_REPREPARATION_REASON
        ){
          dispatchProps.preShowRepreparationReasonModal(dispatchProps.cancelFunction, submitFunction);
        }
        else{
          submitFunction(null);
        }
      },
      onClickBlockedStatus: () => {
        const submitFunction = dispatchProps.submitPreFunction;
        dispatchProps.preShowBlockingExitReasonModal(dispatchProps.cancelFunction, submitFunction);
      },
    };
  },
)(InternalSelectStatus);

export default  SupportSelectStatus;
