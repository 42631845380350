import React from 'react';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import "../css/OrderProducts.css";
import moment from "moment/moment";
import {SUPPORT_MODAL_HIDE_CREDIT, validateCredit} from "../actions";

import { CreditForm } from "./FormikCreditForm";
import {getCreditSubTypeList} from "../../../selectors/credit_modal";

class InternalCreditModal extends React.Component {
  render() {
    const {sub_type_list, modal, hide, validate} = this.props;
    moment.locale('fr');

    /* Set up defaults values min and max quantity */
    let minQuantity = 0;
    let maxQuantity = 0;
    if (typeof(modal.maxQuantity) !== 'undefined') {
      maxQuantity = modal.maxQuantity;
      minQuantity = modal.minQuantity;
    } else {
      minQuantity = -modal.recreditQuantity;
      maxQuantity = modal.defaultQuantity - modal.recreditQuantity - modal.refundQuantity;
      modal.defaultQuantity = maxQuantity;
    }

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }
        <CreditForm
          sub_type_list={sub_type_list}
          quantity={modal.defaultQuantity} // default quantity
          pricePerUnitQuantity={modal.pricePerUnitQuantity} // price per quantity of quantity
          subtype={modal.subtype}  // default value
          labelQuantity={modal.labelQuantity} //default value
          validate={(values) => {
            console.log("valudate credit form", values);
            validate(values, modal);
          }}
          minQuantity={minQuantity}
          maxQuantity ={maxQuantity}
        />
      </Modal.Body>
    </Modal>;
  }
}

export const CreditModal = connect(
  (state) => ({
    "modal" : state.support.modalCredit,
    "sub_type_list": getCreditSubTypeList(state),
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_CREDIT});
      },
      "validate": (values, modal) => {
        dispatch(validateCredit(
          values.quantity,
          values.subtype,
          values.notes,
          values.pricePerUnitQuantity,
          modal
        ));
      }
    };
  }
)(InternalCreditModal);
