import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Table } from 'react-bootstrap';

import { extractCenterId } from "../../../utils/center";
import { getXDToCheckTasks } from '../selectors';
import { ADD_ORDER_TO_XD_DOCK } from '../actions';
import { MAIN_MODAL_SHOW } from '../../../actions/modal';
import { XD_TASK_CHECK_MODAL } from '../modals';
import { slotLabel, slotDepartureHourLabel } from "../../../utils/task";
import TaskName from "../../../components/TaskName";
import { ShortSlot } from "../../../components/Common";
import { NumberOfBags } from "../../../components/NumberOfBags";
import { ChangeNumberOfBagsModal } from "../../../components/modal/ChangeNumberOfBagsModal";
// import CrateModal from "../../support/components/CrateModal";
import CheckTaskModal from './CheckTaskModal';
import {NumberOfCanteenOrders} from "../../../common-comps/NumberOfCanteenOrders";

const TasksToCheck = ({ tasks, showModalCrate, allCenters, currentCenterId, validatePrompt }) => {
  let currentHash;
  let shippingCenter;
  const currentCenter = allCenters.find(c => c.id === currentCenterId);
  const isCratesEnabled = currentCenter && currentCenter.crates_enabled;
  return (
    <div>
      <Table className="col-md-6" bordered size="sm" hover striped>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Nb sacs</th>
            <th>Cagettes</th>
            <th>Créneau</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => {
            const label = slotLabel(task);
            const etdLabel = slotDepartureHourLabel(task.linked_order);
            let changeCenter = false;
            if (task.address.hash !== currentHash) {
              currentHash = task.address.hash;
              changeCenter = true;
              shippingCenter = allCenters.find(c => c.address.hash === currentHash);
            }

            let shippingCenterName = shippingCenter ? shippingCenter.name : '??';

            return (
              <React.Fragment key={task.id}>
                {changeCenter &&
                  <tr>
                    <th colSpan={5} >{`Centre de destination: ${shippingCenterName}`}</th>
                  </tr>
                }
                <tr className="Row">
                  <td>
                    <TaskName task={task} />
                  </td>
                  <td>
                    {
                      task.linked_order && task.linked_order.canteen_witness
                      ? <NumberOfCanteenOrders order={task.linked_order} />
                      : <NumberOfBags order={task.linked_order} />
                    }
                  </td>
                  <td>
                    <span className="font-weight-bold">{task.linked_order.crates.length}: </span>
                    <span>
                      {task.linked_order.crates.map(c => c.code).join(', ')}
                    </span>
                  </td>
                  <td>
                    <ShortSlot
                      slot={label}
                      slotEtd={etdLabel}
                      uniqueId={task.linked_order.id}
                    />
                  </td>
                  <td>
                    <Button
                      className="w-100 py-0"
                      variant="success"
                      size="sm"
                      onClick={() => {
                        if(isCratesEnabled) {
                          showModalCrate(task);
                        } else {
                          validatePrompt(task);
                        }
                      }}
                    >
                      Valider
                    </Button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
      <ChangeNumberOfBagsModal />
      <CheckTaskModal />
    </div>
  );
};

TasksToCheck.propTypes = {
  tasks: PropTypes.array.isRequired,
  allCenters: PropTypes.array.isRequired,
  currentCenterId: PropTypes.number,
  validatePrompt: PropTypes.func,
  showModalCrate: PropTypes.func,
};

export default connect(
  state => ({
    tasks: getXDToCheckTasks(state),
    allCenters: state.m.metas.centers,
    currentCenterId: extractCenterId(state.user),
  }),
  dispatch => ({
    showModalCrate: task => dispatch({
      type: MAIN_MODAL_SHOW,
      modalData: { modalType: XD_TASK_CHECK_MODAL, task },
    }),
    // Nécessaire si la gestion des cagettes est désactivée.
    validatePrompt: task => {
      const nextNbOfCrates = window.prompt('Valider le nombre de cagettes', task.linked_order.number_of_crates);
      if (parseInt(nextNbOfCrates, 10) >= 1) {
        const nbOfCrates = nextNbOfCrates !== task.linked_order.number_of_crates ? nextNbOfCrates : void 0;
        return dispatch({ 
          type: ADD_ORDER_TO_XD_DOCK,
          order: task.linked_order,
          taskId: task.id,
          userType: task.client.user_type,
          nbOfCrates,
          taskIds: [task.id,] });
      }
      return null;
    },
  })
)(TasksToCheck);
