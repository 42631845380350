import React from 'react';
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";
import PackingRow from "./PackingRow";

import "../css/PackingList.css";


export const PackingList = ({
  tasks,
  title,
  titleRight,
  className,
  show,
  title_link,
  packerstatiph_avg,
  packerstatcph_avg,
  packerstattad_avg,
  packerstattap_avg,
  onToggle,
  showAll,
}) => {

  if (!titleRight) {
    titleRight = '';
  }

  show = {...{prep:false, startDate: false, nbProducts: true, nbBags: false, transitArea: false, nothingIfEmpty: false}, ...(show ? show : {})};

  if (tasks.length === 0) {
    return (show.nothingIfEmpty ? null : <div><h4>{title} : AUCUNE</h4></div>);
  }

  return (
    <div className={"PackingList " + className}>
      <h4>
        {title} : {tasks.length} <span className="title-right">{titleRight}</span>
        {onToggle
          ? (
            <button
              className="btn btn btn-default btn-xs float-right"
              onClick={onToggle}
            >
              {showAll ? "Cacher" : "Afficher"}
            </button>
          ) : null
        }
        {title_link
          ? <a href={title_link.url} className="product-link btn btn btn-default btn-xs float-right" target="_blank" rel="noopener noreferrer" >
            {title_link.label}</a>
          : null}
        {packerstatiph_avg ? packerstatiph_avg :null}
        {packerstatcph_avg ? packerstatcph_avg :null}
        {packerstattad_avg ? packerstattad_avg :null}
        {packerstattap_avg ? packerstattap_avg :null}
      </h4>
      {(!onToggle || showAll) &&
        <Table bordered size="sm" hover striped>
          <thead>
            <tr>
              {/*<th>#</th>*/}
              <th>Tâche</th>
              {show.departure ? <th>Départ</th> : null}
              {show.nbProducts ? <th>Pr.</th>: null}
              {show.nbBags ? <th>Sacs.</th>: null}
              {show.transitArea ? <th>Z.</th>: null}
              <th>Client</th>
              <th>Adresse</th>
              {/*<th>H. prep.</th>*/}
              {show.prep ? <th>Prep</th> : null}
              {show.fraud ? <th>Fraude</th> : null}
              <th></th>
              <th>État</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(t => (<PackingRow key={t.id} task={t} show={show} />))}
          </tbody>
        </Table>
      }
    </div>
  );
};


PackingList.propTypes = {
  title:  PropTypes.string.isRequired,
  titleRight: PropTypes.string,
  tasks: PropTypes.array.isRequired,
  show:  PropTypes.object,
  title_link: PropTypes.object,
  packerstatiph_avg: PropTypes.object,
  packerstatcph_avg: PropTypes.object,
  packerstattad_avg: PropTypes.object,
  packerstattap_avg: PropTypes.object,
  className:  PropTypes.string,
  onDropTask: PropTypes.func,
  onDropDefault: PropTypes.func,
  onToggle: PropTypes.func,
  showAll: PropTypes.bool,
};


export default PackingList;
