import React, { Component } from 'react';
import PropTypes from "prop-types";
import { DragSource, DropTarget } from 'react-dnd';
import { DeliveryRow, DEFAULT_TOGGLE_SELECT } from "./DeliveryRow";
import {
  DOCK_TYPE_HARD_ASSIGNED,
  DOCK_TYPE_SOFT_ASSIGNED,
} from "../../utils/dock_n_driver";

import { TYPE_FREE, TYPE_TROLLEY } from "../../../../utils/transit_areas";

const taskSource = {
  beginDrag(props) {
    let transit_areas = props.transit_areas;
    let dockFrom = props.dock;
    let currentTask = props.task;
    let currentTransitArea = currentTask.linked_order.transit_area_current;

    /* On identifie toutes les tasks qui sont sur le même chariot
     * car elles doivent être DND ensemble.
     * Pour optimiser un peu, on fait l'identification sur le beginDrag
     * et on l'utilise dans le isDragging car sur le beginDrag
     * on ne passe qu'une fois pour la tâche que l'on est en train
     * de DND alors que le isDragging est appelé pour chacune des tâches.
     */
    let tasks_on_same_trolley = [];

    if (
      dockFrom
      && currentTransitArea
      && typeof(transit_areas[currentTransitArea]) !== "undefined"
      && transit_areas[currentTransitArea].trolley
    ) {
      let currentTrolley = transit_areas[currentTransitArea].trolley;
      dockFrom.tasks.forEach(task => {
        if (
          task.id !== currentTask.id
          && task.linked_order.transit_area_current
          && typeof(transit_areas[task.linked_order.transit_area_current]) !== "undefined"
          && transit_areas[task.linked_order.transit_area_current].trolley
          && transit_areas[task.linked_order.transit_area_current].trolley.id === currentTrolley.id
        ) {
          tasks_on_same_trolley.push(task.id);
        }
      });
    }

    tasks_on_same_trolley.push(currentTask.id);

    return {
      task: currentTask,
      transit_areas: props.transit_areas,
      tasks_on_same_trolley: tasks_on_same_trolley,
    };
  },
  isDragging(props, monitor) {
    let currentTask = props.task;
    let tasks_on_same_trolley = monitor.getItem().tasks_on_same_trolley;
    if (tasks_on_same_trolley.includes(currentTask.id)) {
      return true;
    }
    return false;
  }
};

const taskTarget = {

  canDrop(props, monitor) {
    /*
     * item = la tâche qu'on est en train de déplacer
     * props = la tâche sur laquelle on drop
     */
    let item = monitor.getItem();
    let task_src = item.task;
    let task_target = props.task;
    let dock_target = props.dock;
    //console.log("can drop : ", task_src, task_target, dock_target);

    // Impossible de Dnd sur une tâche lockée
    if (task_target.isLock) {
      //console.log("FALSE A", props.task);
      return false;
    }

    // Impossible de dnd la tâche sur elle-même
    if (task_src.id === task_target.id) {
      //console.log("FALSE B", task_src, task_target);
      return false;
    }

    // Impossible de DND la tâche sur la liste des commandes à dispatcher
    if (!task_target.dock) {
      return false;
    }

    let transit_area = null;
    if (task_src.linked_order.transit_area_current) {
      transit_area = props.transit_areas[task_src.linked_order.transit_area_current];
    }

    // on ne peut pas dnd une tâche sur un dock livreur locké
    if (dock_target
      && [DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock_target.dock_type)
      && dock_target.locked_at
    ) {
      return false;
    }

    // on ne peut pas dnd une tâche sur un dock avec livreur si elle n'a pas été déposée
    // sur un chariot ou une zone libre
    if (dock_target
      && [DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock_target.dock_type)
      && (
        transit_area === null
        || (
          transit_area
          && transit_area.transit_area_type !== TYPE_TROLLEY
          && transit_area.transit_area_type !== TYPE_FREE
        )
      )
    ) {
      return false;
    }

    return true;
  },

  drop(props, monitor) {
    let item = monitor.getItem();
    // Dock où était la tâche
    let dockFrom = {...item.task.dock};
    if (item.task.dock_target) {
      dockFrom = {...item.task.dock_target};
    }

    // Dock sur lequel on dépose la tâche
    let dockTarget = props.dock;

    //console.log('dropped ! ', props.task.id, item.task.id, dockFrom, dockTarget);
    props.onDropTask(props.task, item.task, dockFrom, dockTarget);
  }
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

class DeliveryRowDnd extends Component {
  render() {
    const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, canDrop, isOver } = this.props;
    const {
      task,
      dock,
      docks_extract,
      show,
      addressFormatting,
      withSelect,
      selected,
      toggleSelect,
      inCrossdock,
      inToDispatch,
    } = this.props;

    let classes = 'DeliveryRowDnd';
    classes += isDragging ? " is-dragging-row" : '';
    classes += canDrop && isOver ? " is-over-row" : '';

    return (
      <DeliveryRow className={classes}
        task={task}
        dock={dock}
        docks_extract={docks_extract}
        handlerDnd={connectDragSource}
        connectDragPreview={connectDragPreview}
        connectDropTarget={connectDropTarget}
        show={show}
        addressFormatting={addressFormatting}
        withSelect={withSelect}
        selected={selected}
        toggleSelect={toggleSelect}
        inCrossdock={inCrossdock}
        inToDispatch={inToDispatch}
      />
    );
  }
}

DeliveryRowDnd.propTypes = {
  task: PropTypes.object.isRequired,
  transit_areas: PropTypes.object.isRequired,
  docks: PropTypes.array,
  dock: PropTypes.object,
  docks_extract: PropTypes.object,
  show: PropTypes.object,
  addressFormatting: PropTypes.string,
  onDropTask: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  connectDragPreview: PropTypes.func,
  withSelect: PropTypes.bool,
  selected: PropTypes.bool,
  toggleSelect: PropTypes.func,
  inCrossdock: PropTypes.bool,
  inToDispatch: PropTypes.bool,
};

DeliveryRowDnd.defaultProps = {
  show: {
    menu: false,
    driver: false,
    status: true,
    sms: true,
    late: false,
    name: true
  },
  addressFormatting: null,
  withSelect: false,
  selected: false,
  toggleSelect: DEFAULT_TOGGLE_SELECT,
  inCrossdock: false,
  inToDispatch: false,
};

export default DragSource("TASK/DELIVERY", taskSource, collectSource)(
  DropTarget("TASK/DELIVERY", taskTarget, collectTarget)(DeliveryRowDnd)
);
