import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "react-bootstrap";

import DeliveryRowDnd from "./DeliveryRowDnd";
import {DeliveryRow} from "./DeliveryRow";
import DefaultDropZoneBuilder from "./DefaultDropZone";

import "../css/Dock.css";
import moment from "moment";
import {OtherActivityButton} from "./OtherActivityButton";
import {FinishDockButton} from "./FinishDockButton";
import DockTitleLinked from "../containers/DockTitleLinked";
import { EXTERNAL_DOCK_TYPES } from '../apps/dispatch/utils/dock_n_driver';


const DefaultDropZone = DefaultDropZoneBuilder.build("TASK/DELIVERY");

const Dock = ({dock, onDropTask, onDropDefault, handlerDockDnd, connectDragPreview, connectDropTarget, show, className}) => {
  let colspan = 8;

  const name = dock.name;
  const tasks = dock.tasks;

  let listTasks = null;

  let dockClassNames = "Dock col-12 col-sm-6 col-md-6 col-lg-4 " + dock.dock_type.toLowerCase();
  if (className) {
    dockClassNames += className;
  }
  if (dock.locked_at && !EXTERNAL_DOCK_TYPES.includes(dock.dock_type)) {
    dockClassNames += " locked";
    colspan = 6;
  }

  if (dock.loaded_at && !dock.locked_at) {
    dockClassNames += " loaded";
  }

  if (
    dock.external_job && (
      [
        'voided',
        'expired',
        'canceled',
        'returned',
      ].includes(dock.external_job.status)
      || (
        ['in_progress', 'finished'].includes(dock.external_job.status)
        && dock.external_job.cancel_reason
      )
    )
  ) {
    dockClassNames += " stuart-expired";
  }

  if(dock.cross_docking_target_center !== null){
    dockClassNames += " cross_dock";
  }

  if (tasks.length === 0) {

    let resetCase = dock.dock_type !== 'DRIVER_HARD' && dock.external_job && ['voided', 'expired'].includes(dock.external_job.status);
    resetCase = resetCase || (dock.dock_type !== 'DRIVER_HARD' && dock.locked_at);

    if(onDropDefault){
      listTasks = (
        <DefaultDropZone dock={dock} name={name} onDropDefault={onDropDefault} colSpan={colspan}>
          {dock.dock_type === 'DRIVER_HARD' ? <OtherActivityButton driver={dock.driver} /> : null}
          {resetCase ?
            <FinishDockButton dock={dock} /> : null}
          {'\u00A0'}
        </DefaultDropZone>
      );
    }
    else{
      listTasks = (
        <tr>
          <td colSpan={colspan} style={{textAlign: "center"}}>
            {dock.dock_type === 'DRIVER_HARD' ? <OtherActivityButton driver={dock.driver} /> : null}
            {resetCase ? <FinishDockButton dock={dock} /> : null}
            {'\u00A0'}
          </td>
        </tr>
      );
    }
  } else {
    listTasks = tasks.map((task) => {
      if ( !onDropTask || dock.locked_at) {
        return (
            <DeliveryRow key={task.id}
                         task={task}
                         showMenu={show.menu}
                         showDriver={false}
                         showStatus={false}
                         showSms={false}
                         showLate={false}
                         showExternalCall={tasks.length === 1}
                         inCrossdock={dock.cross_docking_target_center !== null}
                         addressFormatting={'compact'}
            />
        );
      } else {
        return (
            <DeliveryRowDnd key={task.id}
                            task={task}
                            onDropTask={onDropTask}
                            showMenu={show.menu}
                            showDriver={false}
                            showStatus={false}
                            showSms={false}
                            showLate={false}
                            showExternalCall={tasks.length === 1}
                            inCrossdock={dock.cross_docking_target_center !== null}
                            addressFormatting={'compact'}
            />
        );
      }
    });
  }

  let handlerDockDndElement = null;
  let headerColspan = colspan;
  if (handlerDockDnd && !dock.locked_at) {
    if (dock.cross_docking_target_center || !dock.isLock || dock.loaded_at) {
      handlerDockDndElement = handlerDockDnd(<td className="draggable"></td>);
    } else {
      handlerDockDndElement = (<td className="draggable locked"></td>);
    }
    headerColspan--;
  }
  const dockElement =  (
    <div className={dockClassNames} style={{padding: "0 5px 0 0"}}>
      <Table bordered size="sm" hover striped>
        <thead>
          <tr>
            {handlerDockDndElement}
            <th colSpan={headerColspan} >
              <DockTitleLinked dock={dock} />
            </th>
          </tr>

          {dock.return_time ? <tr className="subheader">
            <td colSpan={colspan} >
              En liv depuis {moment(dock.locked_at).format("HH:mm", 'fr').replace(':', 'h')},
              retour prévu à <b>{dock.return_time.replace(':', 'h')}</b>
            </td>
          </tr> : null}

        </thead>
        <tbody>{listTasks}</tbody>
      </Table>
    </div>
  );


  return (handlerDockDnd && !dock.locked_at ? connectDragPreview(connectDropTarget(dockElement)) : dockElement);
};


Dock.propTypes = {
  dock: PropTypes.object.isRequired,
  onDropDefault: PropTypes.func,
  onDropTask: PropTypes.func,
  handlerDockDnd: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  show: PropTypes.object,
  classes: PropTypes.string
};

export default Dock;
