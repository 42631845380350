import { createSelector } from 'reselect'

const getSubTypeList = state => state.support.meta.sub_type_list

export const getCreditSubTypeList = createSelector(
  [getSubTypeList],
  (subTypeList) => {
    return subTypeList.filter(e => e.subtype.indexOf('credit') === 0)
  }
)
