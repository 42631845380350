import { createSelector } from 'reselect';

const getSubTypeList = state => state.support.meta.sub_type_list;

export const getRefundProductSubTypeList = createSelector(
  [getSubTypeList],
  (subTypeList) => {
    return subTypeList.filter((e) => {
      const refundProducts = [
        'refund_forget_product',
        'refund_other',
        'refund_forget_discount',
        'refund_late_order',
        'refund_short_dlc',
        'refund_expired_dlc',
        'refund_compensation',
        'refund_product_error',
        'refund_pz_forgotten',
        'refund_bag_forgotten',
        'refund_client_absent_and_no_reship',
      ];
      if (e.subtype.indexOf('refund') === 0 && (refundProducts.includes(e.subtype) || e.product_id_required === true)) {
        return true;
      }
      return false;
    });
  }
);

export const getRefundOrderSubTypeList = createSelector(
  [getSubTypeList],
  (subTypeList) => {
    return subTypeList.filter((e) => {
      const refundProducts = [
        'refund_damaged_product',
        'refund_missing_product',
        'refund_forget_product',
        'refund_forget_discount',
        'refund_short_dlc',
        'refund_expired_dlc',
        'refund_compensation',
        'refund_product_error',
        'refund_pz_forgotten',
        'refund_bag_forgotten',
      ];
      if (e.subtype.indexOf('refund') === 0 && !refundProducts.includes(e.subtype) && e.product_id_required === false) {
        return true;
      }
      return false;
    });
  }
);
