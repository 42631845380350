import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import {act_loadDepositUserHistory} from "../../../actions";
import { DepositUserHistoryDetail } from './DepositUserHistoryDetail';
import { Paginator } from '../../../../../common-comps/Paginator';

const InternalDepositUserHistory = ({deposit_history, user, loadDepositUserHistory}) => {

  return <div className='DepositUserHistory'>
    <strong>Historique complet</strong>
    <Paginator data_object={user} paginated_api_datas={deposit_history.datas} handlePagination={loadDepositUserHistory} />
    {deposit_history.loading === true ?
      <div className='DepositUser text-center'><br />Chargement...</div>
      : <div>
        <Table size="sm" striped hover>

          <thead>
            <tr>
              <th>Acheté le</th>
              <th>Produit</th>
              <th>Qté totale</th>
              <th>Qté remise</th>
              <th>Crédité</th>
              <th>Qté Consignée</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deposit_history.datas.results.map((deposit_user)=> {
              var class_name = '';
              let current_returned = 0;
              if (Object.keys(deposit_user.status).length > 0) {
                current_returned = (
                  deposit_user.status.quantity_returned
                  - deposit_user.status.quantity_returned_canceled
                );
              }
              if (deposit_user.quantity === deposit_user.quantity_given && deposit_user.quantity === current_returned) {
                class_name += 'success';
              } else if (deposit_user.quantity_given === 0) {
                class_name += 'danger';
              } else {
                class_name += 'warning';
              }
              return <tr key={deposit_user.id} className={class_name}>
                <td>{deposit_user.formated_created_at}</td>
                <td>{deposit_user.quantity_bought}x {deposit_user.product_infos.deposit_label}</td>
                <td>{deposit_user.quantity}</td>
                <td>{deposit_user.quantity_given}</td>
                <td>{deposit_user.total_credited_amount}€</td>
                <td>{current_returned}</td>
                <td>
                  <DepositUserHistoryDetail user={user} deposit_user={deposit_user} />
                </td>
              </tr>;
            })}
          </tbody>
        </Table>
      </div>
    }
    <Paginator data_object={user} paginated_api_datas={deposit_history.datas} handlePagination={loadDepositUserHistory} />
  </div>;
};

InternalDepositUserHistory.propTypes = {
  "deposit_history": PropTypes.object,
  "user": PropTypes.object,
  "loadDepositUserHistory": PropTypes.func,
};

export const DepositUserHistory = connect(
  () => ({}),
  (dispatch) => {
    return {
      loadDepositUserHistory: (user, offset) => {
        return dispatch(act_loadDepositUserHistory(user.id, offset));
      }
    };
  }
)(InternalDepositUserHistory);
