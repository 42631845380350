// @flow
// https://medium.com/flow-type/even-better-support-for-react-in-flow-25b0a3485627

import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';


import AdminCompactHeader from "./components/AdminCompactHeader";
import Home from "./components/Home";
import {DriverLogs} from "./apps/logs/components/DriverLogs";
import {DriverExtraCostDemands} from "./apps/logs/components/DriverExtraCostDemands";
import {Support} from "./apps/support/components/Support";
import {Dispatch} from "./apps/dispatch/components/Dispatch";
import Notif from "./components/Notifications";
import {AssignPrio} from "./apps/assign/components/AssignPrio";
import Arrivals from "./apps/arrivals/components/Arrivals";
import Loadings from './apps/crossdocking/components/Loadings';
import UberEatsOrders from './apps/uber-eats/components/UberEatsOrders';

import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';
import {NavigateCheckCenter} from "./NavigateCheckCenter";

type Empty = {};

class App extends Component<Empty> {

  render() {
    return (
      <Router>
        <div className="App container-fluid">
          <AdminCompactHeader current="Flow2"/>
          <Route exact path="/" component={Home}/>
          <Route path="/arrivals" component={Arrivals} />
          <Route path="/loadings" component={Loadings} />
          <Route path="/support" component={Support}/>
          <Route path="/dispatch" component={Dispatch}/>
          <Route path="/assign/prio" component={AssignPrio}/>
          <Route path="/redirect/:center_id" component={NavigateCheckCenter}/>
          <Route path="/logs/date/:date" component={DriverLogs}  />
          <Route path="/logs/driver/:driver_id/month/:month" component={DriverLogs}  />
          <Route path="/logs/driver/:driver_id/date/:date" component={DriverLogs}  />
          <Route path="/driver_extra_cost_demands/" component={DriverExtraCostDemands}  />
          <Route path="/uber-eats/orders/" component={UberEatsOrders}  />
          <Notif/>
        </div>
      </Router>
    );
  }
}

App.propTypes = {};


export default DragDropContext(HTML5Backend)(App);
