import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getLoadsForStats} from "../selectors/stats";


export const Stats = connect(
  (state) => {
    return {
      loads: getLoadsForStats(state),
    };
  }
)(
  ({loads}) => {
    return <div>
      {loads.map((d) => (
        <div key={d.day}>
          <span className="day-label">{d.label}</span>
          {d.load.orders ? <span> {d.load.orders} commandes, {d.load.items} items</span>: null}
          <hr/>
        </div>
      ))}
    </div>;
  }
);

Stats.propTypes = {
  loads: PropTypes.any,
};
