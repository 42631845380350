import {USER_LOAD} from "../actions/user";
import {userLoaded} from "../actions/user";
import {errorThrown} from "../actions/index";
import {daUtil} from "../api";
import {call, put, takeEvery} from 'redux-saga/effects';
import * as Raven from "raven-js";
import {bootRefresh} from "../actions/autorefresh";
import store from "../createStore";

function flowAccess(adminUser) {
  console.log("is manager : ", adminUser.groups.is_manager);
  if (adminUser.groups.is_manager || adminUser.groups.is_support || adminUser.groups.is_super_admin) {
    return true;
  }

  for(let g of adminUser.grants) {
    if (g.codename === "flow_access" && g.value) {
      return true;
    }
  }
  return false;
}

function* doUserLoad() {
  // const store = select();
  try {
    const res = yield call(daUtil.get, `/admin-user/`, {});
    yield put(userLoaded(res));

    let access = flowAccess(res);
    //console.log('before boot', res, access);
    if (access) {
      bootRefresh(store, res);
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

export const userSagas = [
  takeEvery(USER_LOAD, doUserLoad),
];
