import uu from "./updatingUpdated"

let _exp = {};

_exp.planningsSessionUpdating = uu.stateComponentUpdating(
    "incoming_plannings_sessions",
    "plannings_session"
);

_exp.planningsSessionUpdated = uu.stateComponentUpdated(
    "incoming_plannings_sessions",
    "plannings_session",
    ["packer"]
);

export default _exp;
