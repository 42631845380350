import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {DISPATCH_FINISH_DOCK} from "../../../actions/dock";

export const FinishDockButton = connect()(
  ({dock , dispatch}) => {
    let label = "Marquer livrer / Fermer";
    let style = "primary";

    return (
      <Button variant={style} size="xs" onClick={() => dispatch({type: DISPATCH_FINISH_DOCK, dock: dock})}>
        {label}
      </Button>
    );
  });
FinishDockButton.propTypes = {
  dock: PropTypes.object.isRequired
};
