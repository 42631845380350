import React from 'react';
import {connect} from "react-redux";
import {Col, FormGroup, Modal, Row} from "react-bootstrap";
import { Formik, Field } from 'formik';
import {
  SUPPORT_MODAL_HIDE,
  SUPPORT_ORDER_ADDRESS_UPDATE,
  SUPPORT_USER_ADDRESS_UPDATE,
} from "../actions";


class InternalAddressModal extends React.Component {


  render() {
    const {modal, hide, validate, moreAddresses} = this.props;

    let addressValue = 0;
    let changeAddress = (event, setFieldValue) => {

        let address_id = parseInt(event.target[event.target.selectedIndex].value, 10);
        let address = moreAddresses.find((someAddress) => {return someAddress.id === address_id});
        if(address){
            setFieldValue("street_number", address.street_number, false);
            setFieldValue("street", address.street, false);
            setFieldValue("postal_code", address.postal_code, false);
            setFieldValue("city", address.city, false);
            setFieldValue("additional_information", address.additional_information, false);
        }
    }

    //this.handleClose
    // onHide={}
    return <Modal
      show={modal && modal.modalType === 'address'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        <Formik
          initialValues={modal.address ? {
            id : modal.address.id,
            street_number : modal.address.street_number,
            street : modal.address.street,
            postal_code : modal.address.postal_code,
            city : modal.address.city,
            additional_information: modal.address.additional_information
          } : {}}
          onSubmit={(values, actions) => {
            validate(values, modal.address_type);
          }}
          render={(props: FormikProps<Values>) => (
            <form onSubmit={props.handleSubmit} className="form-horizontal">
              {modal.address_type === "order_address" ? <select className="form-control mb-3" value={addressValue}
                    onChange={(event) => {changeAddress(event, props.setFieldValue)}}>
                    <option key={0} value={0}></option>
                {moreAddresses &&
                moreAddresses.map((someAddress) =>
                    <option key={someAddress.id} value={someAddress.id}>
                        {someAddress.street_number}&nbsp;
                        {someAddress.street}&nbsp;
                        {someAddress.postal_code}&nbsp;
                        {someAddress.city}
                    </option>
                )
                }
              </select>
              : null
              }
              <FormGroup as={Row}>
                <Col sm={2}>
                  <Field type='text' name="street_number" className="form-control"/>
                </Col>
                <Col sm={10}>
                  <Field type='text' name="street" required={true} className="form-control"/>
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col sm={3}>
                  {
                    modal.title === "Modifier l'adresse de facturation"
                    ? <Field type='text' name="postal_code" required={true} className="form-control"/>
                    : <Field type='number' name="postal_code" required={true} className="form-control"/>
                  }
                </Col>
                <Col sm={9}>
                  <Field type='text' name="city" required={true} className="form-control"/>
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col>
                  <Field as="textarea" component="textarea" name="additional_information" className="form-control" rows={20}/>
                </Col>
              </FormGroup>
              <FormGroup as={Row}>
                <Col>
                  <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                    VALIDER
                  </button>
                </Col>
              </FormGroup>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>;

  }
}

export const AddressModal = connect(
  (state) => ({
      "modal": state.support.modal,
      "moreAddresses": state.support.user_addresses && state.support.user_addresses.datas,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
      "validate": (address, address_type) => {
        dispatch({'type': SUPPORT_MODAL_HIDE});
        if (address_type === 'order_address') {
          dispatch({'type': SUPPORT_ORDER_ADDRESS_UPDATE, address: address});
        } else if (address_type === 'user_address') {
          dispatch({'type': SUPPORT_USER_ADDRESS_UPDATE, address: address});
        }

      }
    };
  }
)(InternalAddressModal);
