import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { da } from '../../../api';


import {Badge} from "react-bootstrap";

import '../css/SearchUser.css';
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";



const SEARCH_BASE_URL = process.env.REACT_APP_SEARCH;
const USERS_COLLECTION_NAME = process.env.REACT_APP_ALGOLIA_USERS;
const searchUrl = `${SEARCH_BASE_URL}/staff/`;


export const InternalSearchUser = withRouter(({ sites, history }) => {
  const [results, setResults] = useState();
  const [query, setQuery] = useState('');

  useEffect(() => {
    const searchTimer = setTimeout(
      query && (
        async () => {
          try {
            const response = await da.post(
              searchUrl,
              { indexName: USERS_COLLECTION_NAME, q: query, perPage: 100 }
            );
            setResults(response.data);
          } catch (e) {
            console.log("search error", e);
          }
        }
      ), 300);
    return () => clearTimeout(searchTimer);
  }, [query]);

  const navigateTo = (userId, email)  => {
    setResults(null);
    setQuery('');
    history.push(`/support/user/${userId}/${email}`);
  };

  const onQueryChange = (evt) => {
    setQuery(evt.target.value);
  };

  return (
    <div className="row SearchUser">
      <div className="col-md-12">
        <input
          type="text"
          className="form-control input-lg"
          placeholder="Chercher des utilisateurs..."
          onInput={onQueryChange}
          value={query}
        /> 
      </div>
      {results && results.found > 0 && (
        <Table striped bordered size="sm" hover>
          <tbody>
            <tr>
              <th>Site</th>
              <th>Email</th>
              <th>Nom</th>
              <th>Dernière adresse</th>
              <th>Téléphone</th>
              <th>Cmdes récentes</th>
              <th>Der. cmd</th>
            </tr>
            {results.hits.map(hit => {
              let badgeColor = 'grey';
              if (Object.keys(sites).length > 0 && typeof(sites[hit.site[0]]) !== 'undefined') {
                badgeColor = sites[hit.site[0]].color;
              }

              return (
                <tr key={hit.id} onClick={() => navigateTo(hit.id, hit.email)} className="hit">
                  <td>
                    <Badge style={{ backgroundColor: badgeColor}}>{hit.site[0]}</Badge>
                  </td>
                  <td>{hit.email}</td>
                  <td>{hit.name}</td>
                  <td>{hit.last_order_shipping_address}</td>
                  <td>{hit.phone_number}</td>
                  <td>{hit.last_orders_id_with_short_number}</td>
                  <td>{hit.last_order_time ? moment(hit.last_order_time, "X").format("L") : ''}</td>
                </tr>
              );
            }
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
});


InternalSearchUser.propTypes = {
  sites: PropTypes.object,
};

export const SearchUser = connect(
  (state) => {

    let sites = {};
    if (typeof(state.m.metas.sites) !== 'undefined') {
      for (let site_id in state.m.metas.sites) {
        let site = state.m.metas.sites[site_id];
        sites[site.domain] = site;
      }
    }
    return { sites };
  }
)(InternalSearchUser);
