import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';

import {findByid} from "../../utils/index";

export class ChangeTrolleyLocationModalContent extends React.Component {

  render() {
    const {
      trolleys,
      trolley_logs,
      trolley_locations,
      modal,
      hide,
      submit,
      cancelSubmit,
      validateTrolleyLocation,
    } = this.props;

    if (!modal || modal.modalType !== 'change_trolley_location') {
      return null;
    }

    if (!modal.trolley_id) {
      return null;
    }

    let trolley = findByid(trolleys, modal.trolley_id);
    const { logistics_center: centerId } = trolley;

    let iv = {
      trolley_id: modal.trolley_id,
      current_location: trolley.current_location ? trolley.current_location : "",
    };

    return <Modal
      show={modal && modal.modalType === 'change_trolley_location'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>{`Modifier l'emplacement d'un chariot`}</h4>
        <div className="alert alert-danger">{`ATTENTION la modification de l'emplacement impacte toutes les commandes déposées sur le chariot modifié`}</div>
        {trolley ? <Formik
          initialValues={iv}
          onSubmit={(values, actions) => {
            for (var key in iv) {
              if (iv[key] !== values[key]) {
                return submit(values);
              }
            }
            return cancelSubmit();
          }}
        >
          {({errors, touched, values, handleSubmit}) => {
            return <form onSubmit={handleSubmit}>
              <FormGroup key="trolley_id">
                <h4 >Chariot <mark style={{fontSize: "24px"}}>{trolley.name}</mark></h4>
              </FormGroup>

              <FormGroup key="current_location">
                <label>Emplacement</label>
                <Field
                  component="select"
                  name="current_location"
                  className="form-control"
                  required="required"
                  validate={validateTrolleyLocation}
                >
                  <option value="">-- Sélectionner un emplacement --</option>
                  {trolley_locations.filter(l => l.center === centerId).map((location, key) => {
                    return <option key={key} value={location.name}>{location.name}</option>;
                  })}
                </Field>
                {errors.current_location && touched.current_location && <p style={{color: "red"}}>{errors.current_location}</p>}
              </FormGroup>

              <FormGroup style={{marginBottom:"0px"}}>
                <button type="submit" className="btn btn-primary">{`Modifier l'emplacement`}</button>
              </FormGroup>
              <div className="clearfix" style={{fontSize:"1px", height:"1px"}}></div>
            </form>;
          }}
        </Formik> : null}
        <div>
          <br />
          <strong>Dernière modifications</strong>
          {trolley_logs.length > 0 ?
            <ul>
              {trolley_logs.map((log, key) => {
                return <li key={key}>{log}</li>;
              })}
            </ul>
            : <div className="alert alert-info">Aucune modification</div>
          }
        </div>
      </Modal.Body>
    </Modal>;

  }
}

ChangeTrolleyLocationModalContent.propTypes = {
  modal: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  trolley_logs: PropTypes.array,
  trolleys: PropTypes.array,
  trolley_locations: PropTypes.array,
  validateTrolleyLocation: PropTypes.func,
  cancelSubmit: PropTypes.func,
  submit: PropTypes.func,
};

export default ChangeTrolleyLocationModalContent;
