import React from "react";
import {Button} from "react-bootstrap";

// wip

export const DriverLogsTitle = ({presences, filters, history}) => {
  let driverMonthLink = null;
  if(filters.driver_id !== undefined && filters.date !== undefined) {
    const navigateToDriverMonth = () => {
      history.push('/logs/driver/' + filters.driver_id + '/month/' + filters.date.slice(0, 7));
    };
    driverMonthLink = <Button size="sm" onClick={() => navigateToDriverMonth()}>Voir le mois du chauffeur</Button>
  }

  const byDayTitle = Object.keys(presences).length > 0 ?
    (<h4>Logs du {presences[0].date_formated}</h4>) :
    (<h4>Aucun logs pour ce jour là ({filters.date}) </h4>);

  const byDriverTitle = Object.keys(presences).length > 0 ?
    <h4>Logs de {presences[0].driver.name} {driverMonthLink}</h4> :
    <h4>Aucun logs pour ce livreur</h4>;

  return filters.driver_id ? byDriverTitle : byDayTitle;
};
