import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DISPATCH_MAIN_MODAL_SHOW } from '../../../actions/modal';
// import "../../../../../css/Late.css";
// import {lateAction, lateWarned} from "../../../actions/dock";

export const Late = ({ task, showReviewModal, lateOrders }) => {
  const { linked_order: order } = task;
  const relatedLateOrder = lateOrders[order.id];
  if (relatedLateOrder) {
    if (!relatedLateOrder.reviewed_at) {
      return (
        <div className="Late">
          <button className="btn btn-default btn-xs" onClick={() => showReviewModal(task)}>
            Vérifier
          </button>
        </div>);
    }
    if (relatedLateOrder.reviewed_at && !relatedLateOrder.reviewed_by) {
      return (<div className="Late">Traité (auto) <i className="fa-solid fa-check"></i></div>);
    }
    if (relatedLateOrder.reviewed_at && relatedLateOrder.reviewed_by) {
      return (<div className="Late">Traité <i className="fa-solid fa-check"></i></div>);
    }
  } else {
    return (
      <div className="Late">
        <button className="btn btn-default btn-xs" onClick={() => showReviewModal(task)}>
          Prévenir
        </button>
      </div>
    );
  }
};

Late.propTypes = {
  task: PropTypes.object.isRequired,
  lateOrders: PropTypes.object,
  showReviewModal: PropTypes.func,
};

export default connect(
  state => (
    {
      lateOrders: state.dispatch.late_orders,
    }
  ),
  dispatch => ({
    showReviewModal: task => dispatch(
      {
        type: DISPATCH_MAIN_MODAL_SHOW,
        modalData: {
          modalType: 'review_late_order',
          task: task,
        }
      }
    ),
  })
)(Late);
