import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Modal} from "react-bootstrap";
import { Formik, Field } from 'formik';

import {
  TYPE_TROLLEY,
  TYPE_DRIVER
} from "../../utils/transit_areas";

export class TransitAreaModalContent extends React.Component {

  render() {
    const {
      pseudoZones,
      transit_areas,
      transit_area_types,
      trolleys,
      modal,
      hide,
      submit,
      cancelSubmit,
      validateTransitAreaType,
      validateTransitAreaName,
      validateTrolley,
      showModalChangeTrolleyLocation,
    } = this.props;

    if (!modal || modal.modalType !== 'order_change_transit_area') {
      return null;
    }

    if (!modal.order) {
      return null;
    }

    let ta_id = modal.order.transit_area_current;
    let current_transit_area = null;
    if (transit_areas && typeof(transit_areas[ta_id])) {
      current_transit_area = transit_areas[ta_id];
    }

    let extra_places = [];
    for (const opz of modal.order.pseudo_zones_status) {
      if (opz.pseudozone && opz.pseudozone.is_active && opz.pseudozone.bag_apart && opz.count_item_match > 0) {
        let opz_transit_area_current = (
          opz.transit_subarea_current && typeof(transit_areas[opz.transit_subarea_current]) !== "undefined"
            ? transit_areas[opz.transit_subarea_current]
            : null
        );

        extra_places.push({
          id: opz.id,
          transit_area: opz_transit_area_current,
          pseudoZone: pseudoZones.find(e => e.id === opz.pseudozone_id),
          field_transit_area_type: "pz_transit_area_type_" + opz.id,
          field_transit_area_name: "pz_transit_area_name_" + opz.id,
          field_trolley_id: "pz_trolley_id_" + opz.id,
        });
      }
    }

    let initialValues = {
      order_id: modal.order.id,
      transit_area_type: current_transit_area ? current_transit_area.transit_area_type : "",
      transit_area_name: current_transit_area && current_transit_area.transit_area_type !== TYPE_TROLLEY ? current_transit_area.name : "",
      trolley_id: current_transit_area && current_transit_area.trolley && typeof(current_transit_area.trolley.id) !== "undefined" ? current_transit_area.trolley.id : "",
      additional_trolley: current_transit_area && current_transit_area.additional_trolley && typeof(current_transit_area.additional_trolley.id) !== "undefined" ? current_transit_area.additional_trolley.id : "",
    };

    for (let ep of extra_places) {
      initialValues[ep.field_transit_area_type] = ep.transit_area ? ep.transit_area.transit_area_type : "";
      initialValues[ep.field_transit_area_name] = ep.transit_area && ep.transit_area.transit_area_type !== TYPE_TROLLEY ? ep.transit_area.name : "";
      initialValues[ep.field_trolley_id] = ep.transit_area && ep.transit_area.trolley && typeof(ep.transit_area.trolley.id) !== "undefined" ? ep.transit_area.trolley.id : "";
      initialValues[ep.field_additional_trolley] = ep.transit_area && ep.transit_area.additional_trolley && typeof(ep.transit_area.additional_trolley.id) !== "undefined" ? ep.transit_area.trolley.id : "";
    }

    return <Modal
      show={modal && modal.modalType === 'order_change_transit_area'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
    >
      <Modal.Body>
        <h4>
          Changer la zone de dépôt sur
          &nbsp;<a href={`/support/order/${modal.order.id}`} target="_blank" rel="noopener noreferrer" >#{modal.order.random_id}</a>&nbsp;
        </h4>
        {modal.order ? <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            for (var key in initialValues) {
              if (initialValues[key] !== values[key]) {
                return submit(values);
              }
            }
            return cancelSubmit();
          }}
        >
          {({errors, touched, values, handleSubmit, setFieldValue }) => {
            return <form onSubmit={handleSubmit}>
              <FormGroup key="transit_area_type">
                <label>Type de dépôt</label>
                <Field
                  component="select"
                  name="transit_area_type"
                  className="form-control"
                  required="required"
                  validate={validateTransitAreaType}
                  value={values.transit_area_type}
                >
                  <option value="">-- Sélectionner un type --</option>
                  {Object.keys(transit_area_types).map((type_id, key) => {
                    return <option key={key} value={type_id}>{transit_area_types[type_id]}</option>;
                  })}
                </Field>
                {errors.transit_area_type && touched.transit_area_type && <p style={{color: "red"}}>{errors.transit_area_type}</p>}
              </FormGroup>
              {parseInt(values.transit_area_type, 10) !== TYPE_TROLLEY && parseInt(values.transit_area_type, 10) !== TYPE_DRIVER && 
                <FormGroup key="transit_area_name">
                  <label>Zone de dépôt</label>
                  <Field
                    type="text"
                    name="transit_area_name"
                    className="form-control"
                    required="required"
                    validate={validateTransitAreaName}
                  />
                  {errors.transit_area_name && touched.transit_area_name && <p style={{color: "red"}}>{errors.transit_area_name}</p>}
                </FormGroup>
              }
              {parseInt(values.transit_area_type, 10) === TYPE_TROLLEY && 
                <FormGroup key="trolley_id">
                  <label>Chariot <em className="btn btn-default btn-xs" onClick={() => {showModalChangeTrolleyLocation(initialValues.trolley_id);}}><i className="fa-solid fa-pen-to-square"></i> {`modifier l'emplacement`}</em> </label>
                  <Field
                    component="select"
                    name="trolley_id"
                    className="form-control"
                    required="required"
                    validate={validateTrolley}
                  >
                    <option value="">-- Sélectionner un chariot --</option>
                    {trolleys.map((trolley, key) => {
                      return <option key={key} value={trolley.id}>{trolley.full_name}</option>;
                    })}
                  </Field>
                  {errors.trolley_id && touched.trolley_id && <p style={{color: "red"}}>{errors.trolley_id}</p>}
                </FormGroup>
              }
              {parseInt(values.transit_area_type, 10) === TYPE_TROLLEY && 
                <FormGroup key="additional_trolley">
                  <label>
                    Chariot additionnel
                    {initialValues.additional_trolley
                      && (
                        <em
                          className="btn btn-default btn-xs"
                          onClick={() => {showModalChangeTrolleyLocation(initialValues.additional_trolley);}}>
                          <i className="fa-solid fa-pen-to-square"></i> {`modifier l'emplacement`}
                        </em>
                      )
                    }
                  </label>
                  <Field
                    component="select"
                    name="additional_trolley"
                    className="form-control"
                  >
                    <option value="">Aucun</option>
                    {trolleys.map((trolley, key) => {
                      return <option key={key} value={trolley.id}>{trolley.full_name}</option>;
                    })}
                  </Field>
                  {errors.additional_trolley_id && touched.additional_trolley_id && <p style={{color: "red"}}>{errors.trolley_id}</p>}
                </FormGroup>
              }

              {extra_places.length > 0 ? <React.Fragment><hr /><h5 style={{fontSize: "16px"}}>Zone sans regroupement Prep</h5></React.Fragment> : null}
              {extra_places.map(ep => <React.Fragment key={ep.id}>
                <FormGroup key={ep.id}>
                  <h6 style={{fontSize: "14px"}}>{ep.pseudoZone.name}</h6>
                </FormGroup>
                <FormGroup key={ep.field_transit_area_type}>
                  <label>Type de dépôt</label>
                  <Field
                    component="select"
                    name={ep.field_transit_area_type}
                    className="form-control"
                    required="required"
                    validate={validateTransitAreaType}
                    value={values[ep.field_transit_area_type]}
                  >
                    <option value="">-- Sélectionner un type --</option>
                    <option value="-1">Aucune zone de dépôt</option>
                    {Object.keys(transit_area_types).map((type_id, key) => {
                      return <option key={key} value={type_id}>{transit_area_types[type_id]}</option>;
                    })}
                  </Field>
                  {errors[ep.field_transit_area_type] && touched[ep.field_transit_area_type] && <p style={{color: "red"}}>{errors[ep.field_transit_area_type]}</p>}
                </FormGroup>
                {parseInt(values[ep.field_transit_area_type], 10) !== TYPE_TROLLEY && parseInt(values[ep.field_transit_area_type], 10) !== TYPE_DRIVER && parseInt(values[ep.field_transit_area_type], 10) !== -1 && 
                  <FormGroup key={ep.field_transit_area_name}>
                    <label>Zone de dépôt</label>
                    <Field
                      type="text"
                      name={ep.field_transit_area_name}
                      className="form-control"
                      required="required"
                      validate={validateTransitAreaName}
                    />
                    {errors[ep.field_transit_area_name] && touched[ep.field_transit_area_name] && <p style={{color: "red"}}>{errors[ep.field_transit_area_name]}</p>}
                  </FormGroup>
                }
                {parseInt(values[ep.field_transit_area_type], 10) === TYPE_TROLLEY && 
                  <FormGroup key={ep.field_trolley_id}>
                    <label>Chariot</label>
                    <Field
                      component="select"
                      name={ep.field_trolley_id}
                      className="form-control"
                      required="required"
                      validate={validateTrolley}
                    >
                      <option value="">-- Sélectionner un chariot --</option>
                      {trolleys.map((trolley, key) => {
                        return <option key={key} value={trolley.id}>{trolley.name}</option>;
                      })}
                    </Field>
                    {errors[ep.field_trolley_id] && touched[ep.field_trolley_id] && <p style={{color: "red"}}>{errors[ep.field_trolley_id]}</p>}
                  </FormGroup>
                }
              </React.Fragment>
              )}

              <FormGroup style={{marginBottom:"0px"}}>
                <button type="submit" className="btn btn-primary">Modifier le dépôt</button>
              </FormGroup>
              <div className="clearfix" style={{fontSize:"1px", height:"1px"}}></div>
            </form>;
          }}
        </Formik> : null}
      </Modal.Body>
    </Modal>;

  }
}

TransitAreaModalContent.propTypes = {
  modal: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  pseudoZones: PropTypes.array,
  transit_areas: PropTypes.object,
  transit_area_types: PropTypes.object,
  trolleys: PropTypes.array,
  cancelSubmit: PropTypes.func,
  submit: PropTypes.func,
  validateTransitAreaType: PropTypes.func,
  validateTransitAreaName: PropTypes.func,
  validateTrolley: PropTypes.func,
  showModalChangeTrolleyLocation: PropTypes.func
};

export default TransitAreaModalContent;
