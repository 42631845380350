import React from 'react';
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {SUPPORT_DELETE_ACCOUNT, SUPPORT_FIX_CENTERS, SUPPORT_DISCONNECT_EDENRED} from '../../actions';

class InternalDangerZone extends React.Component {

  render() {
    const {user, deleteAccount, fixCenters, disconnectEdenred } = this.props;
    if (!user || !user.id) return <span></span>;

    const fixCentersOnClick = () => {
        if (
            window.confirm(
                "Êtes-vous sûr de vouloir recalculer le centre de l'utilisateur ?"
                + " Attention, certains des produits du panier de l'utilisateur peuvent être perdus."
           )
        ) {
            fixCenters(user);
        }
    }

    return <React.Fragment>
      <Button style={{marginTop: "10px"}} className='btn btn-danger' onClick={() => {
          if (window.confirm("Êtes-vous sûr de vouloir supprimer/anonymiser ce compte ?")) {
            deleteAccount(user);
          }
      }}>Supprimer et anonymiser l'utilisateur</Button>

      <br />
      <Button style={{marginTop: "10px"}}
              className='btn btn-warning'
              onClick={fixCentersOnClick}
      >
          Forcer le centre de l'utilisateur
      </Button>      
      <br />
      <Button style={{marginTop: "10px"}}
              className='btn btn-warning'
              onClick={() => disconnectEdenred(user)}
      >
          Déconnecter Edenred
      </Button>
    </React.Fragment>;
  }
}

export const DangerZone = connect(
  (state) => ({
    "user" : state.support.user,
    "deleteAccount": state.support.deleteAccount,
  }),
  (dispatch) => {
    return {
      deleteAccount: (user) => {
        dispatch({"type": SUPPORT_DELETE_ACCOUNT, userId: user.id});
      },
      fixCenters: (user) => {
        dispatch({"type": SUPPORT_FIX_CENTERS, userId: user.id});
      },
      disconnectEdenred: (user) => {
        dispatch({"type": SUPPORT_DISCONNECT_EDENRED, user_id: user.id})
      }
    };
  }
)(InternalDangerZone);
