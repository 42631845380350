import {connect} from "react-redux";
import {
  changeEndingCenter, changeMainSetting,
  closeDock, assignDock, unlockDock,
  cancelStuart, regularizeStuart,
  cancelUber, regularizeUber,
} from "../../../actions/dock";
import DockTitle from "./DockTitle";

import { callStuart } from "../../../../../actions";

const mapStateToProps = (state) => {
  return {
    authUser: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "onChangeMainSetting":  (dock, newValue) => dispatch(changeMainSetting(dock, newValue)),
    "onCloseDock":          (dock) => dispatch(closeDock(dock)),
    "onChangeEndingCenter": (dock, newValue) => dispatch(changeEndingCenter(dock, newValue)),
    "onAssignDock":         (dock) => dispatch(assignDock(dock)),
    "onUnlockDock":         (dock) => dispatch(unlockDock(dock)),
    "onCallStuart":         (dock, transportType) => dispatch(callStuart(dock, transportType)),
    "onCancelStuart":       (dock) => dispatch(cancelStuart(dock)),
    "onRegularizeStuart":   (dock) => dispatch(regularizeStuart(dock)),
    "onCancelUber":       (dock) => dispatch(cancelUber(dock)),
    "onRegularizeUber":   (dock) => dispatch(regularizeUber(dock)),
  };
};


let DockTitleLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(DockTitle);


export default DockTitleLinked;

