import {connect} from "react-redux";
import DockList from "./DockList";
import {
  dockListEventHandler,
  dockListProps,
  getTitleDockListDraft,
} from "../common-comps/dock";
import {getComputedDockListDraftToday} from "../../selectors/dock";
import {withRouter} from "react-router-dom";

export const DockListDraft = withRouter(connect(
  state => dockListProps(
    state,
    getComputedDockListDraftToday(state),
    getTitleDockListDraft(state)
  ),
  dispatch => dockListEventHandler(dispatch)
)(DockList));

