import React from "react";
import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";

import "../css/ExtraDelay.css";

// component
export class ExtraDelayContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '' + props.dqp,
      changed: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(event) {
    if(event.key === 'Enter'){
      this.handleSubmit(event);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dqp !== this.state.value && !this.state.changed) {
      this.setState({...this.state, ...{value:nextProps.dqp}});
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value, changed: true});
  }

  handleSubmit(event) {
    let message = 'Changer le EXTRA DQP en "' + this.state.value + '" ?';
    if (this.props.type === 'express_dqp') {
      message = 'Changer le EXTRA DQP EXPRESS en "' + this.state.value + '" ?';
    }
    else if (this.props.type === 'express_prio_dqp') {
      message = 'Changer le EXTRA DQP EXPRESS PRIO en "' + this.state.value + '" ?';
    }
    else if (this.props.type === 'dqp_courier') {
      message = 'Changer l\'EXTRA DQP Coursier privé en "' + this.state.value + '" ?';
    }
    else if (this.props.type === 'frichti_dqp') {
      message = 'Changer le FRICHTI DQP en "' + this.state.value + '" ?';
    }
    else if (this.props.type === 'frichti_express_prio_dqp') {
      message = 'Changer le FRICHTI EXPRESS PRIO DQP en "' + this.state.value + '" ?';
    }
    let r = window.confirm(message)
    if (r) {
      this.props.save(this.state.value);
      this.setState({...this.state, ...{changed: false}});
    } else {
      this.setState({value: this.props.dqp, changed: false});

    }
    event.preventDefault();
    event.stopPropagation();
  }

  handleEnabledChange(event) {
    let newValue = !this.props.enabledValue;
    this.props.saveEnabled(newValue);
  }

  render() {

    const {updating, type} = this.props;
    let classNames = "metas";
    if (updating) {
      classNames += " updating";
    }

    let title = 'DQP';
    if(type === 'dqp'){
      classNames += " ExtraDelay";
    }
    else if (type === 'express_dqp') {
      title = 'EXPRESS DQP';
      classNames += " ExpressExtraDelay";
    }
    else if (type === 'express_prio_dqp') {
      title = 'EXPRESS PRIO DQP';
      classNames += " ExpressPrioExtraDelay";
    }
    else if (type === 'dqp_courier') {
      title = 'DQP Coursier privé';
      classNames += " CourierExtraDelay";
    }
    else if (type === 'frichti_express_prio_dqp') {
      title = 'FRICHTI EXPRESS PRIO DQP';
      classNames += " FrichtiExpressPrioExtraDelay";
    }
    else if (type === 'frichti_dqp') {
      title = 'FRICHTI DQP';
      classNames += " FrichtiExtraDelay";
    }

    return (
      <div className={classNames}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <Form.Label>{title}</Form.Label>{' '}
            { this.props.withEnabled &&
              <input type="checkbox"
                     value="1"
                     checked={this.props.enabledValue}
                     onChange={this.handleEnabledChange.bind(this)}
              ></input>
            }
            { !this.props.hideValue &&
              <FormControl type="text" value={this.state.value} onChange={this.handleChange} onKeyDown={this.handleKeyPress}/>
            }
          </FormGroup>{' '}
          { !this.props.hideValue &&
            <Button size="xs" onClick={this.handleSubmit} variant={this.state.changed ? "primary" : "default"}>OK</Button>
          }
        </Form>
      </div>
    );

  }
}

ExtraDelayContent.propTypes = {
  dqp: PropTypes.any.isRequired,
  save: PropTypes.func.isRequired,
  updating: PropTypes.any,
  type: PropTypes.string,
  enabledValue: PropTypes.bool,
  withEnabled: PropTypes.bool,
  saveEnabled: PropTypes.func,
  hideValue: PropTypes.bool,
};
