import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from  'moment-timezone';
import {Badge, Modal, OverlayTrigger, Popover, Table} from "react-bootstrap";
import {format_seconds} from "../../../../../utils";
import {mapUrlRound} from "../../../../../utils/address";
import {SUPPORT_MODAL_HIDE} from "../../../actions";

moment.locale('fr');

class InternalModalStatDriver extends React.Component {


  render() {
    const {modal, hide, order_stat_driver, centers} = this.props;
    let content = <div className="text-center" style={{"marginTop": "50px"}}>Chargement en cours...</div> ;
    const order = modal.order;
    if (!order) {
      return null;
    }

    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
    if (!order_stat_driver.loading) {
      
      content = (
        <div>
          {order_stat_driver.datas.driverloggroups.length > 1 && <h5><b>Cette commande a été relivrée</b></h5>}
          {order_stat_driver.datas.driverloggroups.map((dlg, key) => {

            const date_fmt = moment(dlg.created_at).format("Do MMM YYYY à HH:mm");
            let total_time_loading = dlg.logs.reduce((previousValue, log) => {
              return previousValue + parseFloat(log.orderstatdriver.time_loading);
            }, 0.0);

            let total_time_waiting_before = dlg.logs.reduce((previousValue, log) => {
              return previousValue + parseFloat(log.orderstatdriver.time_waiting);
            }, 0.0);

            let total_additional_time_waiting = dlg.logs.reduce((previousValue, log) => {
              return previousValue + parseFloat(log.orderstatdriver.additional_time_waiting);
            }, 0.0);

            let total_estimated_duration = dlg.logs.reduce((previousValue, log) => {
              return previousValue + parseFloat(log.estimated_travel_duration);
            }, 0.0);

            let center = centers.filter(center => center.id === dlg.center);

            let total_cost_agency_fee = dlg.logs.reduce((previousValue, log) => {
              return previousValue + parseFloat(log.orderstatdriver.total_cost_agency_fee);
            }, 0.0);
            let total_cost_with_agency_fee = total_cost_agency_fee + dlg.gain_real;

            let addresses = [];
            for (const log of dlg.logs) {
              addresses.push(log.end_point);
            }
            if (dlg.is_accepted_with_return && center.length) {
              addresses.push(center[0].address);
            }
            
            return (
              <div key={key} style={{"marginBottom": "60px"}}>
                <h5>
                  <Badge pill className="bg-info">{key+1}</Badge>
                  Tournée le {date_fmt} ({dlg.number_of_waypoint} étapes / mode: {dlg.pay_mode})
                  {center.length && <b> - Centre : {center[0].name}</b>}
                  {dlg.is_accepted_with_return
                    ? <span> (Avec retour au centre)</span>
                    : <span> (Sans retour au centre)</span>
                  }
                </h5>
                <ul>
                  <li>
                    <a href={mapUrlRound(addresses, order.center, centers)} target="_blank"  className="btn btn-xs btn-default" rel="noopener noreferrer"><i className="fa-solid fa-route"/> maps</a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_DELEEVADMIN}/logistics/stats/payperf/logs/?driver_id=${dlg.driver}&order_id=${order.id}`} target="_blank"  className="btn btn-xs btn-default" rel="noopener noreferrer"><i className="fa-solid fa-circle-info"/> Détail acceptation de la tournée</a>
                  </li>
                  <li>
                    <b>Coût de la tournée NET Livreur :</b> {formatter.format(dlg.gain_real)}
                    <em> (hors attente, chargement et autres activités)</em>
                  </li>
                  <li>
                    <b>Total commission :</b> {formatter.format(total_cost_agency_fee)}
                  </li>
                  <li>
                    <b>Coût commissionné de la tournée :</b> {formatter.format(total_cost_with_agency_fee)}
                  </li>
                  <li>
                    <b>{`Temps d'attente:`}</b> {format_seconds(total_time_waiting_before + total_additional_time_waiting)}
                    <em> (Avant livraison: {format_seconds(total_time_waiting_before)} / réparti: {format_seconds(total_additional_time_waiting)})</em>
                  </li>
                  <li><b>Temps de chargement:</b> {format_seconds(total_time_loading)}</li>
                  <li><b>Poids:</b> {dlg.weight}kg</li>
                  <li><b>Véhicule:</b> {dlg.vehicle}</li>
                  <li><b>Artisan livreur:</b> {dlg.driveragency ? <span>{dlg.driveragency.name}</span> : <span>Aucun</span>}</li>
                </ul>

                <Table size="sm" striped hover>
                  <thead>
                    <tr>
                      <th>Cmde</th>
                      <th>Adresse</th>
                      <th>Estimation<br />{`Centre=>Adresse`}</th>
                      <th>
                        Coût de Livraison
                        <br />
                        <em style={{"fontWeight": "normal"}}>(Chargt. + livr. + retour)</em>
                      </th>
                      <th>Attente</th>
                      <th>Coût total Net livreur</th>
                      <th style={{textAlign: "center"}}>Coût total commissionné</th>
                      <th style={{textAlign: "right"}}>Détail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {center.length && <tr>
                      <td colSpan="9" className="info">
                        {`Départ de ${center[0].name} - ${center[0].address.street_number} ${center[0].address.street}`}
                      </td>
                    </tr>}
                    {dlg.logs.map((log, log_key) => {
                      let total_time = format_seconds(log.orderstatdriver.total_time);
                      let time_delivering = format_seconds(log.orderstatdriver.time_delivering);
                      let className = "";
                      if (log.order === order.id) {
                        className = "success";
                      }
                      let percent_estimation = Math.round((log.estimated_travel_duration * 100) / total_estimated_duration, 2);
                      return (
                        <tr key={log_key} className={className}>
                          <td title={`oid:${log.order} / driverlog:${log.id}`}>#{log.order_random_id}</td>
                          <td>
                            {`${log.end_point.street_number} ${log.end_point.street}`}
                            <br />
                            {`${log.end_point.postal_code} ${log.end_point.city}`}
                          </td>
                          <td>
                            {percent_estimation}%
                            <em> ({format_seconds(log.estimated_travel_duration)})</em>
                          </td>
                          <td>
                            {formatter.format(log.orderstatdriver.cost_delivering)}
                            <em> ({time_delivering})</em>
                          </td>
                          <td>
                            {dlg.pay_mode === "performance" ?
                              <span>
                                {formatter.format(log.orderstatdriver.additional_cost_waiting)} réparti
                              </span>
                              : <div>
                                {formatter.format(log.orderstatdriver.cost_waiting + log.orderstatdriver.additional_cost_waiting)}
                                <br />
                                <em>({formatter.format(log.orderstatdriver.cost_waiting)} avant livraison / {formatter.format(log.orderstatdriver.additional_cost_waiting)} réparti)</em>
                              </div>
                            }
                          </td>
                          <td>
                            {formatter.format(log.orderstatdriver.total_cost)}
                            <em> ({total_time})</em>
                          </td>
                          <td style={{textAlign: "center"}}>
                            <b>{formatter.format(log.orderstatdriver.total_cost_with_agency_fee)}</b>
                          </td>
                          <td style={{textAlign: "right"}}>
                            <OverlayTrigger
                              key={`overlay-delivery-detail-${log_key}`}
                                placement="left"
                                transition={false}
                                overlay={
                                  <Popover id={`tooltip-delivery-detail-${log_key}`} className="PopoverStatDriverDetail">
                                    <Popover.Title>Détails coûts</Popover.Title>
                                    <Popover.Content>
                                      <strong>Détail pour cette commande</strong>
                                      <ul>
                                        <li>Coût de livraison : {formatter.format(log.orderstatdriver.cost_delivering)} ({format_seconds(log.orderstatdriver.time_delivering)})</li>
                                        <li>Coût d'attente : {formatter.format(log.orderstatdriver.cost_waiting)} ({format_seconds(log.orderstatdriver.time_waiting)})</li>
                                        <li>Surcoût : {formatter.format(log.orderstatdriver.cost_driverextracost)} ({format_seconds(log.orderstatdriver.time_driverextracost)})</li>
                                        <li>Commission : {formatter.format(log.orderstatdriver.cost_agency_fee)}</li>
                                      </ul>
                                      <br />
                                      <strong>Coûts additionnels</strong>
                                      <ul>
                                        <li>Attente: {formatter.format(log.orderstatdriver.additional_cost_waiting)} ({format_seconds(log.orderstatdriver.additional_time_waiting)})</li>
                                        <li>Autre activité: {formatter.format(log.orderstatdriver.additional_cost_other)} ({format_seconds(log.orderstatdriver.additional_time_other)})</li>
                                        <li>Surcoût: {formatter.format(log.orderstatdriver.additional_cost_driverextracost)} ({format_seconds(log.orderstatdriver.additional_time_driverextracost)})</li>
                                        <li>Commission: {formatter.format(log.orderstatdriver.additional_cost_agency_fee)}</li>
                                      </ul>
                                    </Popover.Content>
                                  </Popover>
                                }
                            >
                                <span><i className="fa-solid fa-circle-info"/></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                    {dlg.is_accepted_with_return && center.length && <tr>
                      <td colSpan="9" className="info">
                        {`Retour à ${center[0].name} - ${center[0].address.street_number} ${center[0].address.street}`}
                      </td>
                    </tr>}
                  </tbody>
                </Table>

              </div>
            );
          })}
        </div>
      );
    }

    return <Modal
      show={modal && modal.modalType === 'order_stat_driver'}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="ModalStatDriver"
    >
      <Modal.Body>
        <h4>{modal.title}</h4>
        {content}
      </Modal.Body>
    </Modal>;

  }
}

InternalModalStatDriver.propTypes = {
  "modal": PropTypes.object,
  "order_stat_driver": PropTypes.object,
  "hide": PropTypes.func,
  "centers": PropTypes.array,
};


export const ModalStatDriver = connect(
  (state) => ({
    "modal": state.support.modal,
    "order_stat_driver": state.support.order_stat_driver,
    "centers": state.support.meta && state.support.meta.all_centers ? state.support.meta.all_centers : [],
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE});
      },
    };
  }
)(InternalModalStatDriver);
