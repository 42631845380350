import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import "../../../css/OrderProducts.css";
import moment from "moment/moment";
import {SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER} from "../../../actions";

import { ReprepareAfterOrder } from "./FormikReprepareAfterForm";

class InternalReprepareAfterModal extends React.Component {
  render() {
    const {addresses, modal, hide, repreparation_reasons} = this.props;
    moment.locale('fr');

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      dialogClassName="duplicate-order-dialog"
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {modal.alertWarning &&
          <div className="alert alert-warning" role="alert"><b>{modal.alertWarning}</b></div>
        }
        {modal.alertInfo &&
          <div className="alert alert-info" role="alert">{modal.alertInfo}</div>
        }

        <ReprepareAfterOrder slots={modal.data} order={modal.order} addresses={addresses} repreparation_reasons={repreparation_reasons} />
      </Modal.Body>
    </Modal>;
  }
}

InternalReprepareAfterModal.propTypes = {
  "addresses": PropTypes.object,
  "modal": PropTypes.object,
  "hide": PropTypes.func,
};

export const ReprepareAfterModal = connect(
  (state) => {
    return {
      "modal" : state.support.modalReprepareAfter,
      "addresses": state.support.user_addresses,
      "repreparation_reasons": state.support.meta.repreparation_reasons,
    };
  },
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER});
      }
    };
  }
)(InternalReprepareAfterModal);
