import React, { Component } from 'react';
import PropTypes from "prop-types";
import {  DropTarget } from 'react-dnd';


const taskTarget = {
  drop(props, monitor) {
    //console.log('dropped ! ', props.dock, props.id, monitor.getItem().taskId, monitor);
    props.onDropDefault(props.dock, monitor.getItem().task);
  },

  canDrop(props, monitor) {
    /*
     * props = destination (zone de drop)
     * item = source (élément en train d'être déplacé)
     */
    let item = monitor.getItem();
    //console.log('ici ! ', props,  item);
    if (!props.dock) {
      return true;
    }

    // console.log(props.dock.dock_type === 'NOCONFIG' , !props.dock.defined_at, props.dock);
    // pas possible de dropper une tâche from: dock defined, dans un dock defined et autre que noconfig

    if (!props.dock.defined_at && props.dock.dock_type === 'NOCONFIG') {
      return true;
    }
    // cas dnd de livreur à livreur
    if (!!props.dock && !!item.task.dock
        && !props.dock.locked_at && !item.task.dock.locked_at
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(props.dock.dock_type)
        && ["DRIVER_HARD", "DRIVER_SOFT"].includes(item.task.dock.dock_type) ) {
      return true;
    }

    return false;
  }

};


function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}


class DefaultDropZone extends Component {
  render() {
    const colSpan = this.props.colSpan || 8;
    const {connectDropTarget, isOver, canDrop } = this.props;
    return connectDropTarget(
      <tr style={{
        backgroundColor:  canDrop && isOver  ? 'LightGreen' : 'transparent',
      }} className="DefaultDropZone">
        <td colSpan={colSpan}>{this.props.children || '\u00A0'}</td>
      </tr>

    );
  }
}


DefaultDropZone.propTypes = {
  onDropDefault: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  id: PropTypes.any,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  children: PropTypes.any,
  canDrop: PropTypes.any,
};


export default { build: (type) => DropTarget(type, taskTarget, collectTarget)(DefaultDropZone) };
