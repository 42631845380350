import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {MAIN_MODAL_SHOW} from "../actions/modal";

import { useNumberOfBags } from '../hooks/useNumberOfBags';


export const NumberOfBags = connect()(({
  order, taskId, dockId, show_modal_action, show_full_infos, dispatch
}) => {
  // compute extrabags https://trello.com/c/CWc9O7xR/608-assemblage-par-le-livreur-sur-certaine-zone
  const mData = { order, taskId, dockId, modalType: 'order_number_of_bags' };

  show_modal_action = show_modal_action || MAIN_MODAL_SHOW;

  const { getFormattedContent } = useNumberOfBags(order);

  return (
    <div
      onClick={() => dispatch({type: show_modal_action, modalData: mData})}
      className="NumberOfBags" style={{cursor:'pointer'}}
    >
      <span>{getFormattedContent(show_full_infos)}</span>
    </div>
  );
});

NumberOfBags.propTypes = {
  order: PropTypes.object.isRequired,
  taskId: PropTypes.any,
  dockId: PropTypes.any,
  show_full_infos: PropTypes.bool,
  show_modal_action: PropTypes.any,
};
