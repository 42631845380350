import React from "react";
import PropTypes from "prop-types";

import "../css/DriverStates.css";
import DriverStateLinked from "../containers/DriverStateLinked";
import {DriverBurger} from "./DriverBurger";


const DriverStates = ({ drivers }) => {
  return (
    <div className="drivers">
      <div className="panel panel-default">
        <div className="panel-body drivers-list clearfix">
          {drivers.map(d =>
            <DriverStateLinked key={d.id} driver={d} />)}
          <DriverBurger />
        </div>
      </div>
    </div>
  );
};

DriverStates.propTypes = {
  drivers: PropTypes.array.isRequired
};

export default DriverStates;



