import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import '../../css/DriverQueue.css';

export const InternalDriverQueue = ({dock}) => {
  let driver = dock.driver;

  if (!driver || driver.state === 'plan') {
    return null;
  }
  let position = driver.queue_position;
  if(position === 0){
    position = '-';
  }
  let classes = "DriverQueue badge badge-pill badge-" + driver.vehicle.toLowerCase();
  return <span className={classes} title={`Priorité livreur : ${position}`}>{position}</span>;
};

InternalDriverQueue.propTypes = {
  dock: PropTypes.object.isRequired,
};

export const DriverQueue = connect(
  (state) => {
    return {
    };
  }
)(InternalDriverQueue);
