import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {showPopinOrderPrepZone} from "../../../actions";
import {OrderPrepZoneAction} from "./OrderPrepZoneAction";

//import {OverlayTrigger, Tooltip} from "react-bootstrap";

const InternalOrderPrepZoneDisplay = ({order, pseudoZones, popinChange}) => {
  let mapPZ = {};
  for (let pz of pseudoZones) {
    mapPZ[pz.id] = pz;
  }
  const prep_zones = order.pseudo_zones_status.filter(e => !!e.user.id);

  return <div className="OrderPrepZoneDisplay" disabled={order.updating ? true : false}>
    {prep_zones.map(prep_zone => {
      return <span key={prep_zone.pseudozone_id} onClick={() => popinChange(order, prep_zone, mapPZ[prep_zone.pseudozone_id]) }>
        {mapPZ[prep_zone.pseudozone_id].name} 
        {(prep_zone.transit_subarea !== '' ?  '(' + prep_zone.transit_subarea + ')' : '')}
        : {prep_zone.user.name.trim() || prep_zone.user.email}
      </span>;
    })}
    <OrderPrepZoneAction order={order} />
  </div>;
};

InternalOrderPrepZoneDisplay.propTypes = {
  order: PropTypes.object.isRequired,
  pseudoZones: PropTypes.array.isRequired,
  popinChange: PropTypes.func.isRequired
};

export const OrderPrepZoneDisplay = connect(
  null,
  (dispatch) => {
    return {
      "popinChange": (order, prep_zone, pz) => dispatch(showPopinOrderPrepZone(order, prep_zone, pz)),
    };
  }
)(InternalOrderPrepZoneDisplay);
