import {STATUS_ORDER_REDESTOCK} from "../utils/status";
import {DOCK_TYPE_SOFT_ASSIGNED} from "../utils/dock_n_driver";
import {cleanRowsSelected} from "./deliveries_todo";
import { SUPPORT_DEPOSIT_RELOAD } from "../apps/support/actions";

export const ERROR = 'ERROR';
export const SKIP = 'SKIP';

export const CLOSE_ALERT = 'CLOSE_ALERT';
export const ALERT_CLOSED = 'ALERT_CLOSED';

export const REFRESH_REQUEST = 'REFRESH_REQUEST';
export const REFRESH_IGNORE_DISCONNECT = 'REFRESH_IGNORE_DISCONNECT'
export const REFRESH_REQUEST_OLDIES = 'REFRESH_REQUEST_OLDIES';
export const REFRESH_REQUEST_SUCCESS = 'REFRESH_REQUEST_SUCCESS';
export const REFRESH_REQUEST_ERROR = 'REFRESH_REQUEST_ERROR';

export const DOCK_DROP_ON_DEFAULT = 'DOCK_DROP_ON_DEFAULT';
export const DOCK_DROP_ON_DELIVERY_TASK = 'DOCK_DROP_ON_DELIVERY_TASK';

export const DROP_ON_DEFAULT = 'DROP_ON_DEFAULT';
export const DROP_ON_DELIVERY_TASK = 'DROP_ON_DELIVERY_TASK';
export const DELIVERY_UPDATE = 'DELIVERY_UPDATE';
export const DELIVERY_UPDATE_SUCCEEDED = 'DELIVERY_UPDATE_SUCCEEDED';

export const DROP_ON_PACKING_DEFAULT = 'DROP_ON_PACKING_DEFAULT';
export const DROP_ON_PACKING_TASK = 'DROP_ON_PACKING_TASK';
// export const PACKING_UPDATE = 'PACKING_UPDATE';
export const PACKING_UPDATE_SUCCEEDED = 'PACKING_UPDATE_SUCCEEDED';

export const CHANGE_STATUS = 'CHANGE_STATUS';
// export const CHANGE_STATUS_ONLY_REFRESH = 'CHANGE_STATUS_ONLY_REFRESH';
export const ORDER_REDESTOCK = 'ORDER_REDESTOCK';
export const ORDER_UPDATED = 'ORDER_UPDATED';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const ORDER_PSEUDOZONE_UPDATE = "ORDER_PSEUDOZONE_UPDATE";
export const ORDER_PSEUDOZONE_ALTER_STOCK = 'ORDER_PSEUDOZONE_ALTER_STOCK';
export const ORDER_PSEUDOZONE_ALTERED_STOCK = 'ORDER_PSEUDOZONE_ALTERED_STOCK';
export const ORDER_CRATE_ADD = 'ORDER_CRATE_ADD';
export const ORDER_CRATE_DELETE = 'ORDER_CRATE_DELETE';
export const ORDER_CALL_STUART = 'ORDER_CALL_STUART';
export const ORDER_STUART_SENDING = 'ORDER_STUART_SENDING';
export const DOCK_STUART_SENDING = 'DOCK_STUART_SENDING';
export const DOCK_CALL_STUART = 'DOCK_CALL_STUART';
export const ORDER_CALL_UBER = 'ORDER_CALL_UBER';
export const ORDER_UBER_SENDING = 'ORDER_UBER_SENDING';


export const TRANSIT_AREA_LOAD = "TRANSIT_AREA_LOAD";
export const TRANSIT_AREA_LOADED = "TRANSIT_AREA_LOADED";
export const TRANSIT_AREA_CHANGE = "TRANSIT_AREA_CHANGE";
export const TRANSIT_AREA_CHANGED = "TRANSIT_AREA_CHANGED";

export const TROLLEY_LOAD = "TROLLEY_LOAD";
export const TROLLEY_LOADED = "TROLLEY_LOADED";
export const TROLLEY_LOCATION_CHANGE = "TROLLEY_LOCATION_CHANGE";
export const TROLLEY_LOCATION_CHANGED = "TROLLEY_LOCATION_CHANGED";
export const TROLLEY_LOGS_LOAD = "TROLLEY_LOGS_LOAD";
export const TROLLEY_LOGS_LOADED = "TROLLEY_LOGS_LOADED";

export const NUMBER_OF_BAGS = 'NUMBER_OF_BAGS';
export const LATE_ACTION = 'LATE_ACTION';
export const LATE_WARNED = 'LATE_WARNED';

export const START_DRIVER_PRESENCE = 'START_DRIVER_PRESENCE';
export const CHANGE_DRIVER_VEHICLE = 'CHANGE_DRIVER_VEHICLE';
export const STOP_DRIVER_PRESENCE = 'STOP_DRIVER_PRESENCE';
export const CREATE_STATEMENT = 'CREATE_STATEMENT';


export const DRIVER_UPDATE = 'DRIVER_UPDATE';
export const DRIVER_UPDATE_SUCCEEDED = 'DRIVER_UPDATE_SUCCEEDED';
// export const DRIVER_LEAVE = 'DRIVER_LEAVE';

export const DRIVER_ACTIVITY_START = 'DRIVER_ACTIVITY_START';
export const DRIVER_ACTIVITY_END = 'DRIVER_ACTIVITY_END';

export const DRIVER_DEPOSIT_DRIVER_LOAD = 'DRIVER_DEPOSIT_DRIVER_LOAD';
export const DRIVER_DEPOSIT_DRIVER_LOADED = 'DRIVER_DEPOSIT_DRIVER_LOADED';
export const DRIVER_DEPOSIT_DRIVERS_LOAD = 'DRIVER_DEPOSIT_DRIVERS_LOAD';
export const DRIVER_DEPOSIT_DRIVERS_LOADED = 'DRIVER_DEPOSIT_DRIVERS_LOADED';
export const DRIVER_DEPOSIT_MAKE_RETURN = 'DRIVER_DEPOSIT_MAKE_RETURN';
export const DRIVER_DEPOSIT_CANCEL_RETURNED = 'DRIVER_DEPOSIT_CANCEL_RETURNED';
export const DRIVER_DEPOSIT_CANCEL_RECEIVED = 'DRIVER_DEPOSIT_CANCEL_RECEIVED';
export const DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD = 'DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD';
export const DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED = 'DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED';

export const DOCK_UPDATE = 'DOCK_UPDATE';
export const DOCK_CLOSE = 'DOCK_CLOSE';
export const DOCK_UPDATED = 'DOCK_UPDATED';
export const DOCK_ASSIGN = 'DOCK_ASSIGN';
export const DOCK_UNLOCK = 'DOCK_UNLOCK';
export const DOCK_DEFINE = 'DOCK_DEFINE';
export const DOCK_UNDEFINE = 'DOCK_UNDEFINE';
export const CALL_STUART = 'CALL_STUART';
export const CANCEL_STUART = 'CANCEL_STUART';
export const REGULARIZE_STUART = 'REGULARIZE_STUART';
export const FINISH_DOCK = 'FINISH_DOCK';
export const DOCK_DROP = 'DOCK_DROP';
export const ADD_DOCK = 'ADD_DOCK';

export const SHOW_ORDER_INFO = "SHOW_ORDER_INFO";
export const HIDE_ORDER_INFO = "HIDE_ORDER_INFO";

export const PSEUDOZONE_UPDATE = "PSEUDOZONE_UPDATE";
export const PSEUDOZONE_UPDATED = "PSEUDOZONE_UPDATED";

export const GRANT_UPDATE = 'USER_UPDATE_GRANT';
export const GRANT_UPDATED = 'USER_UPDATED_GRANT';

export const PLANNINGS_SESSION_UPDATE = 'PLANNINGS_SESSION_UPDATE';
export const PLANNINGS_SESSION_UPDATED = 'PLANNINGS_SESSION_UPDATED';

export const SUPPORT_MODAL_SHOW = 'SUPPORT_MODAL_SHOW';
export const SUPPORT_MODAL_HIDE = 'SUPPORT_MODAL_HIDE';

export const LOAD_ALARM = 'LOAD_ALARM';

export const ORDER_SUPPORT_INFO_LOAD = "ORDER_SUPPORT_INFO_LOAD";
export const ORDER_SUPPORT_INFO_LOADED = "ORDER_SUPPORT_INFO_LOADED";

export const METAS_EXTRA_DELAY_SAVE = "METAS_EXTRA_DELAY_SAVE";
export const METAS_EXPRESS_EXTRA_DELAY_SAVE = "METAS_EXPRESS_EXTRA_DELAY_SAVE";
export const METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE = "METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE";
export const METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE = "METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE";
export const METAS_DQP_COURIER_ENABLED_SAVE = "METAS_DQP_COURIER_ENABLED_SAVE";
export const METAS_RAIN_BONUS_SAVE = "METAS_RAIN_BONUS_SAVE";
export const METAS_SAVED = "METAS_SAVED";
export const HIDE_ALERT_NOTIFICATIONS = "HIDE_ALERT_NOTIFICATIONS";
export const REVIEW_LOG_TROLLEY_NOT_FOUND = "REVIEW_LOG_TROLLEY_NOT_FOUND";
export const LOG_TROLLEY_NOT_FOUND_REVIEWED = "LOG_TROLLEY_NOT_FOUND_REVIEWED";


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const ignoreDisconnect = () => {
  return {
    type: REFRESH_IGNORE_DISCONNECT
  };
};

export const refreshRequest = () => {
  return {
    type: REFRESH_REQUEST_OLDIES,
    version: getRandomInt(15),
    timestamp: (new Date()).getTime()
  };
};

export const refreshRequestSuccess = (res) => {
  return {
    type: REFRESH_REQUEST_SUCCESS,
    timestamp: (new Date()).getTime(),
    payload: res
  };
};

export const refreshRequestError = () => {
  return {
    type: REFRESH_REQUEST_ERROR,
    timestamp: (new Date()).getTime()
  };
};

export const loadAlarmRequest = () => {
  return {
    type: LOAD_ALARM,
  };
};

export const dockDropOnDefaultInternal = (dock, droppedTask) => {
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }

  return {
    type: DOCK_DROP_ON_DEFAULT,
    dockId: dock.id,
    droppedOrderPatch: actionOrPatch,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

export const dockDropOnTaskInternal = (targetTask, droppedTask) => {
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  console.log(`dockDropOnTask action targetTaskId:${targetTask.id}, droppedTaskId:${droppedTask.id}, droppedTaskDockId:${droppedTaskDockId}`);
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }

  return {
    type: DOCK_DROP_ON_DELIVERY_TASK,
    droppedOrderPatch: actionOrPatch,
    targetTaskId: targetTask.id,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

const _extractOrderPatch = (droppedTask) => {
  let droppedOrderPatch = {};
  if (droppedTask.linked_order.transit_area.length === 1) {
    let newTransitArea = prompt("Mettez le frais au frigo et indiquez le zonage du sec:");
    if (!newTransitArea) {
      return {type: SKIP};
    }
    droppedOrderPatch = {id: droppedTask.linked_order.id, transit_area: newTransitArea};
  }
  return droppedOrderPatch;
};

export const dropOnDefaultInternal = (dock, droppedTask) => {
  //console.log("dropOnDefault", dock, droppedTask);
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }
  return {
    type: DROP_ON_DEFAULT,
    droppedOrderPatch: actionOrPatch,
    dockId: dock ? dock.id  : null,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

export const dropOnTaskInternal = (targetTask, droppedTask) => {
  const droppedTaskDockId = droppedTask.dock && droppedTask.dock.id ? droppedTask.dock.id : null;
  const actionOrPatch = _extractOrderPatch(droppedTask);
  if (actionOrPatch.type) {
    return actionOrPatch;
  }

  return {
    type: DROP_ON_DELIVERY_TASK,
    droppedOrderPatch: actionOrPatch,
    targetTaskId: targetTask.id,
    droppedTaskId: droppedTask.id,
    droppedTaskDockId: droppedTaskDockId
  };
};

/*
export const deliveryUpdate = (tasks, updatingValue) => {
  return {
    type: DELIVERY_UPDATE,
    taskIds: tasks.map(e => e.id),
    updating: updatingValue
  };
};
*/

export const deliveryUpdateSucceeded = (tasks) => {
  return {
    type: DELIVERY_UPDATE_SUCCEEDED,
    tasks: tasks,
  };
};

export const dropOnPackingDefault = (droppedTaskId) => {
  return {
    type: DROP_ON_PACKING_DEFAULT,
    droppedTaskId: droppedTaskId
  };
};

export const dropOnPackingTask = (targetTaskId, droppedTaskId) => {
  return {
    type: DROP_ON_PACKING_TASK,
    targetTaskId: targetTaskId,
    droppedTaskId: droppedTaskId
  };
};


/*
export const packingUpdate = (tasks, updatingValue) => {
  return {
    type: PACKING_UPDATE,
    tasks: tasks,
    updating: updatingValue
  };
};
*/

export const packingUpdateSucceeded = (tasks) => {
  return {
    type: PACKING_UPDATE_SUCCEEDED,
    tasks: tasks,
  };
};



export const errorThrown = (e) => {
  console.error("errorThrown", e);
  return {
    type: ERROR,
    e: e,
  };
};

export const changeStatus = (
  orderId,
  newStatus,
  dockId,
  additionalData,
) => {

  if (newStatus === STATUS_ORDER_REDESTOCK) {
    return {
      type: ORDER_REDESTOCK,
      orderId: orderId
    };
  }

  return {
    type: CHANGE_STATUS,
    newStatus: newStatus,
    orderId: orderId,
    dockId: dockId,
    additionalData: additionalData,
  };
};

export const startDriverPresence = (driver, vehicle) => {
  return {
    type: START_DRIVER_PRESENCE,
    vehicle: vehicle,
    driver: driver,
  };
};

export const changeDriverVehicle = (driver, vehicle) => {
  return {
    type: CHANGE_DRIVER_VEHICLE,
    vehicle: vehicle,
    driver: driver,
  };
};

export const stopDriverPresence = (driver, unfinished_new_status = "prepared") => {
  return {
    type: STOP_DRIVER_PRESENCE,
    driver: driver,
    unfinished_new_status: unfinished_new_status
  };
};

export const isothermBagReturn = (driver) => {

  let amount = window.prompt("Nombre de sacs isothermes récupérés:");
  amount = parseInt(amount, 10);

  if (isNaN(amount)) {
    return {
      type: SKIP,
    };
  } else {
    return {
      type: CREATE_STATEMENT,
      driver: driver,
      statement_type: 1,
      amount: -amount
    };
  }
};

export const driverUpdate = (driver, patch) => {
  return {
    type: DRIVER_UPDATE,
    driver: driver,
    patch: patch
  };

};


export const driverUpdateSucceeded = (driver) => {
  return {
    type: DRIVER_UPDATE_SUCCEEDED,
    driver: driver,
  };
};


export const planningsSessionUpdate = (planningsSession, patch) => {
  return {
    type: PLANNINGS_SESSION_UPDATE,
    plannings_session: planningsSession,
    patch: patch
  };
};


export const planningsSessionUpdated = (planningsSession) => {
  return {
    type: PLANNINGS_SESSION_UPDATED,
    plannings_session: planningsSession,
  };
};


export const numberOfBags = (task) => {
  let newNumber = window.prompt("Nombre de sacs:");
  newNumber = parseInt(newNumber, 10);

  if (isNaN(newNumber)) {
    return {
      type: NUMBER_OF_BAGS,
    };
  } else {
    return {
      type: NUMBER_OF_BAGS,
      newNumber: newNumber,
      task: task
    };
  }
};

export const lateAction = (task) => {
  let minutes = prompt('Dans combien de temps arrivera-t-elle (en minutes) ?');
  let recredit = parseFloat(prompt('Dédommagement (crédit en euros) ?'));

  if (!minutes) {
    return { type: SKIP };
  }

  let message = "Votre commande devrait arriver dans environ "+ minutes +" min maximum. ";
  if(recredit > 0) {
    message += "Toutes nos excuses pour le retard, votre compte est crédité de "+recredit.toFixed(2)+" euros.";
  } else {
    message += "Toutes nos excuses pour le retard.";
  }

  return {
    type: LATE_ACTION,
    message: message,
    creditToAdd: recredit,
    orderId: task.linked_order.id,
    task: task
  };
};

export const lateWarned = (task) => {
  return { type: LATE_WARNED, orderId: task.linked_order.id, task: task };
};

export const validateChangeBags = (orderId, numberOfBags, numberOfBagsIso, opzPatchs) => {

  return {
    type: ORDER_UPDATE,
    orderPatch: {id: orderId, number_of_bags: numberOfBags, number_of_bags_isotherm: numberOfBagsIso},
    opzPatchs: opzPatchs
  };
};

export const validateChangeTransitArea = (datas) => {
  return {
    type: TRANSIT_AREA_CHANGE,
    datas: datas,
  };
};

export const validateChangeTrolleyLocation = (datas) => {
  return {
    type: TROLLEY_LOCATION_CHANGE,
    datas: datas,
  };
};

// **************** DOCK

export const changeMainSetting = function (dock, newValue) {
  const value = parseInt(newValue, 10);

  if (isNaN(value)) {
    let [type, vehicle]  =  newValue.split('_');

    // if (type !== DOCK_TYPE_FIRST) {
    //   vehicle = null;
    // }

    return {
      type: DOCK_UPDATE,
      patch: {
        id: dock.id,
        dock_type: type,
        target_vehicle: vehicle,
      }
    };
  } else {
    return {
      type: DOCK_UPDATE,
      patch: {
        id: dock.id,
        dock_type: DOCK_TYPE_SOFT_ASSIGNED,
        target_driver: value,
      }
    };
  }
};

export const changeEndingCenter = function (dock, value) {
  return {
    type: DOCK_UPDATE,
    patch : {
      id: dock.id,
      ending_center: value,
    }
  };
};

export const cancelStuart = function (dock) {
  return {
    type: CANCEL_STUART,
    dock : dock,
  };
};

export const regularizeStuart = function (dock) {
  return {
    type: REGULARIZE_STUART,
    dock : dock,
  };
};

export const closeDock = function (dock) {
  let r = window.confirm("Fermer définitivement ce dock et libérer les livraisons qu'il contient ?");
  if (!r) {
    return {type:SKIP};
  }

  return {
    type: DOCK_CLOSE,
    dock : dock
  };
};


export const assignDock = function (dock) {
  return {
    type: DOCK_ASSIGN,
    dock : dock
  };
};

export const unlockDock = function (dock) {
  return {
    type: DOCK_UNLOCK,
    dock : dock
  };
};

export const callStuart = function (dock, transportType) {
  return {
    type: CALL_STUART,
    dock: dock,
    transportType: transportType
  };
};

export const addDock = dock => ({
  type: ADD_DOCK,
  dock : dock,
});

export const dockUpdated = function (dock) {
  return {
    type: DOCK_UPDATED,
    dock : dock,
  };
};

export const dropOnDock = function (targetDock, droppedDock) {
  return {
    type: DOCK_DROP,
    targetDockId: targetDock.id,
    droppedDockId: droppedDock.id
  };
};


export const startActivity = function (driver) {
  const r = prompt("Ajouter une note");
  if (!r) {
    return { type: SKIP };
  }

  return {
    type: DRIVER_ACTIVITY_START,
    driver : driver,
    note: r
  };
};

export const endActivity = function (driver) {
  return {
    type: DRIVER_ACTIVITY_END,
    driver : driver,
  };
};

export const alterStockPseudoZone = (task, pseudoZoneId, action_type) => {
  return {
    "type": ORDER_PSEUDOZONE_ALTER_STOCK,
    "task": task,
    "orderId": task.linked_order.id,
    "pseudoZoneId": pseudoZoneId,
    "action_type": action_type
  };
};

export const act_loadDepositDriver = (driver_id, offset) => {
  return {
    type: DRIVER_DEPOSIT_DRIVER_LOAD,
    driver_id: driver_id,
    offset: offset
  };
};

export const act_loadDepositDrivers = (offset) => {
  return {
    type: DRIVER_DEPOSIT_DRIVERS_LOAD,
    offset: offset
  };
};

export const act_loadDepositProductsDetail = (offset) => {
  return {
    type: DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD,
    offset: offset
  };
};

export const driverDepositMakeReturn = (driver_id, product_id, quantity) => {
  return {
    "type": DRIVER_DEPOSIT_MAKE_RETURN,
    "driver_id": driver_id,
    "product_id": product_id,
    "quantity": quantity
  };
};

export const driverDepositCancelReturned = (driver_id, deposit_driver_id) => {
  return {
    "type": DRIVER_DEPOSIT_CANCEL_RETURNED,
    "driver_id": driver_id,
    "deposit_driver_id": deposit_driver_id,
  };
};
export const act_showPopinConfirm = (msg, dispatchDatas) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "popin_confirm",
      "msg": msg,
      "title": 'Veuillez confirmer votre action',
      "dispatchDatas": dispatchDatas,
    }
  };
};

export const driverDepositCancelReceived = (driver_id, deposit_driver_id, deposit_user_id, user_id) => {
  let trigger_action_type = DRIVER_DEPOSIT_DRIVER_LOAD;
  if (typeof(user_id) !== 'undefined') {
    trigger_action_type = SUPPORT_DEPOSIT_RELOAD;
  }
  return {
    "type": DRIVER_DEPOSIT_CANCEL_RECEIVED,
    "driver_id": driver_id,
    "deposit_driver_id": deposit_driver_id,
    "deposit_user_id": deposit_user_id,
    "trigger_action_type": trigger_action_type,
    "user_id": user_id,
  };
};

/**
 * redux-thunk enables operations to be dispatched,
 * i.e. implicit super-actions.
 * Thus, the original dispatches corresponding to multiDropTask calls
 * just after this function will transparently be converted
 * into a sequence of normal actions dispatches.
 * @param subFunction
 * @returns {function(*=, *=): function(*, *): void}
 */
function multiDropTask(subFunction) {
  return (param1, param2) => (dispatch, getState) => {
    let selectedTasksIds = getState().deliveriesTodo.selectedTasksIds.slice();
    if (selectedTasksIds.includes(param2.id)) {
      let deliveriesTodo = getState().m.deliveries_todo.slice();
      dispatch(cleanRowsSelected());
      for(let id of selectedTasksIds){
        let task = deliveriesTodo.find(t => t.id === id);
        if(task){
          dispatch(subFunction(param1, task));
        }
      }
    }
    else{
      dispatch(subFunction(param1, param2));
    }
  };
}

export const dockDropOnDefault = multiDropTask(dockDropOnDefaultInternal);
export const dockDropOnTask = multiDropTask(dockDropOnTaskInternal);
export const dropOnDefault = multiDropTask(dropOnDefaultInternal);
export const dropOnTask = multiDropTask(dropOnTaskInternal);

export const loadOrderSupportInfo = (order, fetchedAt) => {
  if (
      !order
      || (
          fetchedAt !== null
          && (new Date()).getTime() - fetchedAt.getTime() <= 3000
      )
  ) {
    return {
      type: null,
    }
  }
  return {
    type: ORDER_SUPPORT_INFO_LOAD,
    order_id: order.id,
  }
}

export default {
  dropOnDefault,
  dropOnTask,
  deliveryUpdateSucceeded,
  errorThrown,
  changeStatus,
  numberOfBags,
  changeMainSetting,
  changeEndingCenter,
  DROP_ON_DEFAULT,
  DROP_ON_DELIVERY_TASK,
  NUMBER_OF_BAGS,
  DELIVERY_UPDATE,
  DELIVERY_UPDATE_SUCCEEDED,
  ERROR,
  CHANGE_STATUS,
};
