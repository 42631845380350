import React from 'react';
import PropTypes from "prop-types";
import {STATUS_ORDER_BLOCKED} from "../../../utils/status";

export const OrderFraude = ({order}) => {
  let style = {};
  if (order.status === STATUS_ORDER_BLOCKED) {
    style.color = 'red';
  }
  return <span style={style}>Scoring Compte: {order.fraud_score_account}% / Scoring Paiement: {order.fraud_score_payment}% - <a href={process.env.REACT_APP_DELEEVADMIN + `/fraud/details/${order.user}/?order_id=${order.id}`} rel="noopener noreferrer" title="Voir l'analyse de fraude" target="_blank">Analyse de fraude <i className="fa-solid fa-bullseye"></i></a></span>;
        
};
OrderFraude.propTypes = {
  order: PropTypes.object.isRequired
};
