import React, { Component } from 'react';
import PropTypes from "prop-types";
import { DragSource, DropTarget } from 'react-dnd';
import Dock from "./Dock";

const dockSource = {
  beginDrag(props) {
    //console.log('A');
    return {
      dock: props.dock,
    };
  }
};

const dockTarget = {
  // hover(props, monitor, component) {
  //   //console.log(props.task.id, monitor.getItem().taskId, component);
  //   return;
  // },

  canDrop(props, monitor) {
    let item = monitor.getItem();
    // console.log("DOCK CAN DROP",  props.dock, item.dock);
    if (props.dock.isLock) {
      console.log('DOCK LOCK');
      return false;
    }
    if (props.dock.id === item.dock.id) {
      // console.log('DOCK SELF');
      return false;
    }


    // si no config et pas défini, tout est permis
    if (
      props.dock.dock_type === "NOCONFIG"
      && item.dock.dock_type === 'NOCONFIG'
      && !props.dock.defined_at && !item.dock.defined_at
    ){
      // console.log('DOCK FREE : OK');
      return true;
    }
    // si cible livreur vide et source chargé : ok
    if (["DRIVER_HARD", "DRIVER_SOFT"].includes(props.dock.dock_type) && item.dock.loaded_at){
      // console.log('DOCK DRIVER and loaded : OK');
      return true;
    }

    // console.log('DOCK default : FALSE');
    return false;
  },

  drop(props, monitor) {
    console.log('dock dropped ! ', props.dock.id, monitor.getItem());
    props.onDropDock(props.dock, monitor.getItem().dock);
  }
};


function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

class DockDnd extends Component {
  render() {
    const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, canDrop, isOver, show } = this.props;
    const { dock, onDropDefault, onDropTask } = this.props;


    let classes = '';
    classes += isDragging ? " is-dragging-dock" : '';
    classes += canDrop && isOver ? " is-over-dock" : '';

    return (
      <Dock className={classes + ' DockDnd'}
        dock={dock}
        onDropDefault={onDropDefault}
        onDropTask={onDropTask}
        handlerDockDnd={connectDragSource}
        connectDragPreview={connectDragPreview}
        connectDropTarget={connectDropTarget}
        show={show}
      />
    );
  }
}

DockDnd.propTypes = {
  dock: PropTypes.object.isRequired,
  onDropTask: PropTypes.func,
  onDropDefault: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  show: PropTypes.object,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func
};

export default DragSource("Dock", dockSource, collectSource)(
  DropTarget("Dock", dockTarget, collectTarget)(DockDnd)
);
