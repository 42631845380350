import {connect} from "react-redux";
import DockList from "../components/DockList";
import {dockListEventHandler, dockListProps} from "./common-props/dock";
import {getComputedDockListTodo} from "../selectors/dock";
import {withRouter} from "react-router-dom";

let DockListLinked = withRouter(connect(
  state => dockListProps(state, getComputedDockListTodo(state)),
  dispatch => dockListEventHandler(dispatch)
)(DockList));

export default DockListLinked;
