import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";

import {Sms} from "../../../../../components/Common";
import { changeStatus, bulkChangeStatus } from "../../../actions";
import {isRedeliverStillInTime} from "../../../utils/order";
import {showPopinReDeliverOrderWithAddress} from "../../../../support/actions";
import BtnOpenModalExtraCost from "../../common-comps/BtnOpenModalExtraCost";
import {
  getCurrentStatus,
  STATUS_ORDER_PAID,
} from "../../../../../utils/status";
import {
  MODAL_SHOW__REPREPARATION_REASON,
  MODAL_HIDE__REPREPARATION_REASON,
} from "../../../../../actions/commonModals";


function buildOptions(task) {
  let options = [];
  if (task.linked_order.status === "received" || task.linked_order.status === "undeliveredclient") {
    options.push({label: "En livraison", status:"shipping", bulkMode: false });
  }
  const { canteen_tasks: canteenTasks } = task.linked_order
  if (
    canteenTasks
    && canteenTasks.length > 0
    && canteenTasks.some(t => t.linked_order.status === "prepared")
  ) {
    options.push({ label: "En livraison (multi)", status: "shipping", bulkMode: true })
  }
  if (task.linked_order.status === "shipping") {
    options.push({label: "Finalisée", status: "prepared", bulkMode: false });
    options.push({label: "Livrée", status: "received", bulkMode: false });
  }
  return options;
}

const InternalActionMenu = ({task, dock, changeStatus, showPopinReDeliver, bulkChangeStatus }) => {
  const options = buildOptions(task);
  const order = task.linked_order;

  const popoverClick = (
    <Popover className="menu-actions" title="" id={"task-menu-" + task.id}>
      <Popover.Content>
        <div className="container">
          { options ? <strong>status</strong> : null}
          { options.map(e => (
              <div className="col-sm-12" key={e.status}>
                <Button
                  variant="primary"
                  size="xs"
                  onClick={() => e.bulkMode ? bulkChangeStatus(task, e.status) : changeStatus(task, e.status)} 
                >
                  cmd =&gt; {e.label}
                </Button>
              </div>
            )) }
          <strong>autre</strong>
          { isRedeliverStillInTime(order) && <div className="col-sm-12"> <Button variant="primary" size="xs" onClick={() => document.body.click() || showPopinReDeliver(order, task.address) }>Sacs oubliés</Button> </div>}
          <div className="col-sm-12"><Sms task={task}/></div>
          <div className="col-sm-12"><BtnOpenModalExtraCost dock={dock} task={task} /></div>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      transition={false}
      overlay={popoverClick}
      rootClose
    >
      <button style={{marginLeft: "2px", marginRight: "2px" }} className="btn btn-xs btn-default"><i className="fa-solid fa-bars"></i></button>
    </OverlayTrigger>
  );
};

InternalActionMenu.propTypes = {
  task: PropTypes.any.isRequired,
  dock: PropTypes.any.isRequired,
  changeStatus: PropTypes.func,
  bulkChangeStatus: PropTypes.func,
  showPopinReDeliver: PropTypes.func,
};


// link
export const DeliveryActionMenu = connect(
  (state) => {
    return {
      repreparationReasons: state.dispatch.metas.repreparation_reasons,
      orderStatuses: state.dispatch.metas.order_statuses,
    };
  },
  (dispatch) => {
    return {
      cancelFunction: () => dispatch(
        {type: MODAL_HIDE__REPREPARATION_REASON}
      ),
      bulkChangeStatus : (task, newStatus) => {
        const ordersIds = task.linked_order.canteen_tasks.map(t => t.linked_order.id)
        dispatch(bulkChangeStatus(ordersIds, newStatus, task.dock.id))
      },
      submitPreFunction: (task, newStatus, dockId) => (additionalData) => {
        dispatch(
          changeStatus(
            task.linked_order.id,
            newStatus,
            dockId,
            additionalData,
          )
        );
        dispatch(
          {type: MODAL_HIDE__REPREPARATION_REASON}
        );
      },
      preShowRepreparationReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__REPREPARATION_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            newOrder: false,
          }
        }
      ),
      showPopinReDeliver: (order, address) => {
        dispatch(showPopinReDeliverOrderWithAddress(order, address));
      }
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      changeStatus: (
        task,
        newStatus,
      ) => {
        const submitFunction = dispatchProps.submitPreFunction(task, newStatus, ownProps.dock.id);
        if(
          newStatus === STATUS_ORDER_PAID
          && getCurrentStatus(task.linked_order) in stateProps.orderStatuses.ORIGIN_STATUSES_FOR_REPREPARATION_REASON
        ){
          dispatchProps.preShowRepreparationReasonModal(dispatchProps.cancelFunction, submitFunction);
        }
        else{
          submitFunction(null);
        }
      },
    };
  },
)(InternalActionMenu);
