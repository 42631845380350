import React from 'react';
import {
  getStatuses,
  getCurrentStatus,
  STATUS_ORDER_PAID,
  STATUS_ORDER_BLOCKED,
} from "../utils/status";
import {FormControl, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import '../css/SelectStatus.css';
import {connect} from "react-redux";
import {changeStatus} from "../actions";
import {
  MODAL_SHOW__REPREPARATION_REASON,
  MODAL_HIDE__REPREPARATION_REASON,
  MODAL_SHOW__BLOCKING_EXIT_REASON,
  MODAL_HIDE__BLOCKING_EXIT_REASON,
} from "../actions/commonModals";


class InternalSelectStatus extends React.Component {

  handleChange(event) {
    this.props.onChangeStatus(event.target.value);
  }


  render() {
    const order = this.props.order;

    const statuses = getStatuses();
    const status = getCurrentStatus(order);

    let possible_statuses = null;
    let is_open_modal_button = false;
    let open_modal_function = null;
    if(order.cross_docking !== null){
      possible_statuses = this.props.crossdockStatusTransitions[order.cross_docking][status];
    }
    else{
      if(order.tags.indexOf("subscription") !== -1){
        possible_statuses = this.props.orderStatuses.SUBSCRIPTION_ALLOWED_TRANSITIONS[status];
      }
      else{
        possible_statuses = this.props.orderStatuses.ALLOWED_TRANSITIONS[status];
        if(status === STATUS_ORDER_BLOCKED){
          is_open_modal_button = true;
          open_modal_function = this.props.onClickBlockedStatus;
        }
      }
    }

    if(is_open_modal_button){
      let option = statuses.find(option => option.value === status);
      return (
        <button onClick={open_modal_function} className="btn btn-xs btn-default">{option.text}</button>
      );
    }

    let listOptions = statuses.filter((option) => {
      if (possible_statuses) {
        if(
          option.value === status
          || possible_statuses.includes(option.value)
        ){
          return true;
        }
        return false;
      }
      return true;
    }).map((option, index) => {
      //
      return (<option key={order.id + ':' + index} value={option.value} >{option.text}</option>);
    });

    return (
      <FormGroup className="SelectStatus" controlId="formControlsSelect">
        <FormControl as="select" placeholder="select" onChange={this.handleChange.bind(this)} value={status}>
          {listOptions}
        </FormControl>
      </FormGroup>
    );
  }
}

InternalSelectStatus.propTypes = {
  order: PropTypes.object.isRequired,
  crossdockStatusTransitions: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onClickBlockedStatus: PropTypes.func.isRequired,
  task: PropTypes.object,
};

const SelectStatus = connect(
  (state, ownProps) => {
    const taskDelivery = state.m.deliveries.find(e => e.id === ownProps.order.id);
    return {
      dockId: taskDelivery && taskDelivery.dock && taskDelivery.dock.id ? taskDelivery.dock.id : null,
      crossdockStatusTransitions: state.m.metas.crossdock_status_transitions,
      orderStatuses: state.m.metas.order_statuses,
    };
  },
  (dispatch, ownProps) => {
    return {
      cancelFunction: () => {
        dispatch({type: MODAL_HIDE__REPREPARATION_REASON})
        dispatch({type: MODAL_HIDE__BLOCKING_EXIT_REASON})
      },
      submitPreFunction: (newStatus, dockId, additionalData) => {
        dispatch(
          changeStatus(
            ownProps.order.id,
            newStatus,
            dockId,
            additionalData,
          )
        );
        dispatch({type: MODAL_HIDE__REPREPARATION_REASON});
        dispatch({type: MODAL_HIDE__BLOCKING_EXIT_REASON})
      },
      preShowRepreparationReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__REPREPARATION_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            newOrder: false,
          }
        }
      ),
      preShowBlockingExitReasonModal: (cancelFunction, submitFunction) => dispatch(
        {
          type: MODAL_SHOW__BLOCKING_EXIT_REASON,
          data: {
            show: true,
            cancelFunction: cancelFunction,
            submitFunction: submitFunction,
            order: ownProps.order,
            task: ownProps.task,
          }
        }
      ),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onChangeStatus: (newStatus) => {
        const submitFunction = (additionalData) => dispatchProps.submitPreFunction(newStatus, stateProps.dockId, additionalData);
        if(
          newStatus === STATUS_ORDER_PAID
          && getCurrentStatus(ownProps.order) in stateProps.orderStatuses.ORIGIN_STATUSES_FOR_REPREPARATION_REASON
        ){
          dispatchProps.preShowRepreparationReasonModal(dispatchProps.cancelFunction, submitFunction);
        }
        else{
          submitFunction(null);
        }
      },
      onClickBlockedStatus: () => {
        const submitFunction = (newStatus, additionalData) => dispatchProps.submitPreFunction(
            newStatus,
            stateProps.dockId,
            additionalData,
        );
        dispatchProps.preShowBlockingExitReasonModal(dispatchProps.cancelFunction, submitFunction);
      },
    };
  },
)(InternalSelectStatus);

export default  SelectStatus;
