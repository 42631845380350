import React from "react";
import PropTypes from "prop-types";

export const DriverVehicle = ({driver}) => {

  if (!driver || driver.state === 'plan') {
    return null;
  }

  if (driver.vehicle === 'CAR') {
    return <span className="DriverVehicle"><i className="fa-solid fa-car"/></span>;
  }
  if (driver.vehicle === 'BIKE') {
    return <span className="DriverVehicle"><i className="fa-solid fa-bicycle"></i></span>;
  }
  let vCode = driver.vehicle === 'SCO' ? '?' :driver.vehicle.replace('S', '');
  return <span className="DriverVehicle">
    <i className="fa-solid fa-motorcycle"/> {vCode}
  </span>;

};

DriverVehicle.propTypes = {
  driver: PropTypes.object.isRequired
};

export default DriverVehicle;



