import React from "react";
import {withFormik, Field} from "formik";
import {Col, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isBlockingExitReasonCommentValid} from "../utils";
import {STATUS_ORDER_BLOCKED, STATUS_ORDER_CANCELED, STATUS_ORDER_PAID} from "../utils/status";

const buttonsSpaceStyle = {
  display: "flex",
  justifyContent: "space-around",
};

const orderStatusToBtnType = {
  [STATUS_ORDER_CANCELED]: "btn btn-warning",
  [STATUS_ORDER_PAID]: "btn btn-primary",
}
const orderStatusToBtnLabel = {
  [STATUS_ORDER_CANCELED]: "Annuler et rembourser",
  [STATUS_ORDER_PAID]: 'RAS, Passer en "payée"',
}

const MyForm = props => {
  const {
    values,
    handleSubmit,
    errors,
    statusExitReasons,
    // orderStatuses,
    cancelFunction,
  } = props;

  let availableReasons = {};
  if(statusExitReasons){
    Object.entries(statusExitReasons.AVAILABLE).filter(
      ([choice_id,]) => statusExitReasons.ID_TO_EXIT_ORDER_STATUS[choice_id] === STATUS_ORDER_BLOCKED
    ).forEach(([choice_id, order]) => { availableReasons[choice_id] = order; })
  }
  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup>
        <Field component="select" name="blocking_exit_reason_id" required={true} className="form-control">
          <option value="">-- Choisir la raison de la sortie de blocage --</option>
          {
            availableReasons
            ? Object.entries(availableReasons).sort(
                // Tri par valeur
                ([,a], [,b]) => a-b
              ).map(
                ([choice_id,], idx) => <option key={idx} value={choice_id}>
                  {statusExitReasons.ID_TO_LABEL[choice_id]}
                </option>
              )
            : <option value="">Loading...</option>
          }
        </Field>
      </FormGroup>
      <FormGroup>
        <Form.Label>Commentaire de raison de sortie de blocage</Form.Label>
        <Field
            component="textarea"
            col="100"
            row="9"
            name="blocking_exit_comment"
            className="form-control"
        ></Field>
        {
          errors.blocking_exit_comment
          && <div style={{color: 'red'}}>{errors.blocking_exit_comment}</div>
        }
      </FormGroup>
      <FormGroup>
        <Col sm={12} style={buttonsSpaceStyle}>
          <button type="button" onClick={() => cancelFunction()} className="btn btn-default">Fermer</button>
          <button type="submit" className={
            orderStatusToBtnType[
                props.statusExitReasons.ID_TO_ENTRY_ORDER_STATUS[parseInt(values.blocking_exit_reason_id, 10)]
            ] || "btn btn-danger"
          }>
            {
              orderStatusToBtnLabel[
                  props.statusExitReasons.ID_TO_ENTRY_ORDER_STATUS[parseInt(values.blocking_exit_reason_id, 10)]
              ] || "Saisir une raison"
            }
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "handleSubmit": PropTypes.any,
};

const InternalBlockingExitReasonForm = withFormik({
  handleSubmit(values, { props, setSubmitting }) {
    let status = props.statusExitReasons.ID_TO_ENTRY_ORDER_STATUS[parseInt(values.blocking_exit_reason_id, 10)];
    props.submitFunction(
      status,
      {
        blocking_exit_reason_id: parseInt(values.blocking_exit_reason_id, 10),
        blocking_exit_comment: values.blocking_exit_comment,
        refund: '1',
      },
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
  validate: (values, props) => {
    const errors = {};
    if (
      values.blocking_exit_reason_id !== ""
      && !isBlockingExitReasonCommentValid(values.blocking_exit_comment)
      && props.statusExitReasons.MANDATORY_COMMENT[parseInt(values.blocking_exit_reason_id, 10)]
    ) {
      errors.blocking_exit_comment = 'Un commentaire de raison de sortie de blocage est requis.';
    }
    return errors;
  },
})(MyForm);

export const BlockingExitReasonForm = connect()(InternalBlockingExitReasonForm);
