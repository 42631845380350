import React from "react";
import {DriverExtraCostDemand} from "./DriverExtraCostDemand";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {currentToggleValue, EXTRA_COST_DEMAND_LIST_KEY} from "../../../utils/toggle";
import {SWITCH_TOGGLE} from "../../../actions/switch_toggle";
import {SUB_DISPATCH_CODES, SUB_DISPATCH_CONFIGURATIONS} from "../sub_dispatchs";

export const InternalDriverExtraCostDemandList = ({demands, showAll, onToggle, driversList, location}) => {
  let driversMapById = new Map();
  for (let someDriver of driversList) {
    driversMapById.set(someDriver.id, someDriver);
  }
  // console.log('driversMapById', driversMapById);

  // console.log('demands', demands);
  const query = new URLSearchParams(location.search);
  const sub = query.get("sub");
  if(
    sub !== null
    && sub !== undefined
    && SUB_DISPATCH_CODES.indexOf(sub) !== -1
    && SUB_DISPATCH_CONFIGURATIONS[sub].withExtraCostDemands === false
  ){
    return null;
  }

  return (
    <div className={'DeliveryList'}>
      <h4>
        Demandes de surcoût
        {showAll ?
          <button className={"btn btn-xs btn-default float-right"}
            onClick={onToggle}
          >Masquer les demandes</button>
          : <button className={"btn btn-xs btn-default float-right"}
            onClick={onToggle}
          >Afficher les demandes</button>
        }
      </h4>
      {showAll ?
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>
                Horodatage de demande
              </th>
              <th>
                Chauffeur
              </th>
              <th title={"Groupement"}>
                Group.
              </th>
              <th title={"Commande"}>
                Cmd.
              </th>
              <th>
                Type de demande
              </th>
              <th title={"Nombre de minutes"}>
                Minutes
              </th>
              <th>
                Commentaire
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              demands.map(
                demand => <DriverExtraCostDemand key={demand.id} demand={demand} driver={driversMapById.get(demand.driver)}
                />
              )
            }
          </tbody>
        </table>
        : null}
    </div>
  );
};

InternalDriverExtraCostDemandList.propTypes = {
  demands: PropTypes.array.isRequired,
  showAll: PropTypes.bool,
  onToggle: PropTypes.func,
  driversList: PropTypes.array.isRequired,
};

export const DriverExtraCostDemandList = withRouter(connect(
  (state) => {
    return {
      demands: state.dispatch.driver_extra_cost_demands,
      showAll: currentToggleValue(state.toggle, EXTRA_COST_DEMAND_LIST_KEY),
      driversList: state.dispatch.drivers,
    };
  },
  (dispatch) => {
    return {
      onToggle: () => dispatch({type: SWITCH_TOGGLE, key: EXTRA_COST_DEMAND_LIST_KEY}),
    };
  },
)(InternalDriverExtraCostDemandList));
