import { SUPPORT_MODAL_SHOW, SUPPORT_DEPOSIT_RELOAD } from "../../support/actions";

export const DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD = 'DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD';
export const DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED = 'DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED';
export const DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD = 'DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD';
export const DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED = 'DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED';
export const DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN = 'DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN';
export const DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED = 'DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED';
export const DISPATCH_DRIVER_DEPOSIT_CANCEL_RECEIVED = 'DISPATCH_DRIVER_DEPOSIT_CANCEL_RECEIVED';
export const DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD = 'DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD';
export const DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED = 'DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED';
export const DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN = 'DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN';

export const act_loadDepositDriver = (driver_id, offset) => {
  return {
    type: DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD,
    driver_id: driver_id,
    offset: offset
  };
};

export const act_loadDepositDrivers = (offset) => {
  return {
    type: DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD,
    offset: offset
  };
};

export const act_loadDepositProductsDetail = (offset) => {
  return {
    type: DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD,
    offset: offset
  };
};

export const driverDepositMakeReturn = (driver_id, product_id, quantity) => {
  return {
    "type": DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN,
    "driver_id": driver_id,
    "product_id": product_id,
    "quantity": quantity
  };
};

export const driverDepositCancelReturned = (driver_id, deposit_driver_id) => {
  return {
    "type": DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED,
    "driver_id": driver_id,
    "deposit_driver_id": deposit_driver_id,
  };
};

export const act_showPopinConfirm = (msg, dispatchDatas) => {
  return {
    "type": SUPPORT_MODAL_SHOW,
    "modalData": {
      "modalType": "popin_confirm",
      "msg": msg,
      "title": 'Veuillez confirmer votre action',
      "dispatchDatas": dispatchDatas,
    }
  };
};

export const driverDepositCancelReceived = (driver_id, deposit_driver_id, deposit_user_id, user_id) => {
  let trigger_action_type = DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD;
  if (typeof(user_id) !== 'undefined') {
    trigger_action_type = SUPPORT_DEPOSIT_RELOAD;
  }
  return {
    "type": DISPATCH_DRIVER_DEPOSIT_CANCEL_RECEIVED,
    "driver_id": driver_id,
    "deposit_driver_id": deposit_driver_id,
    "deposit_user_id": deposit_user_id,
    "trigger_action_type": trigger_action_type,
    "user_id": user_id,
  };
};

export const driverDepositOrderMakeReturn = (driver_id, order_id) => ({
  type: DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN,
  driver_id,
  order_id
});
