import { call, put, takeEvery } from 'redux-saga/effects';
import * as Raven from "raven-js";
import Notifications from "react-notification-system-redux";

import { api } from '../../api';
import { ADD_ORDER_TO_XD_DOCK, LOAD_FULL_STOCK_DOCK } from './actions';
import { STATUS_ORDER_PREPARED } from '../../utils/status';
import { dockUpdated, errorThrown, deliveryUpdateSucceeded } from '../../actions';
import { MAIN_MODAL_HIDE } from '../../actions/modal';
import { USER_TYPE_REAPPRO_ID } from '../../utils/user';


function* addOrderToXdDock({ order, taskId, userType, nbOfCrates: number_of_crates }) {
  if (number_of_crates) {
    yield call(api.editOrder, { id: order.id, number_of_crates });
  }

  if (userType === USER_TYPE_REAPPRO_ID) {
    yield call(api.shippingTaskToXdDock, taskId);
  } else {
    yield call(api.changeOrderStatus, order.id, STATUS_ORDER_PREPARED);
  }

  const resTaskDeliveries = yield call(api.loadShippingTasks, [taskId,]);
  if (resTaskDeliveries.length > 0) {
    yield put(deliveryUpdateSucceeded(resTaskDeliveries));
  }
  yield put({ type: MAIN_MODAL_HIDE });
}

function* loadFullStockDock({ dock, extraCrates }) {
  yield put(Notifications.info({title: "Cross-dock" , "message": "Validation du chargement en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const res = yield call(api.xdLoadedDock, dock.id, extraCrates);
    yield put(Notifications.success({title: "Cross-dock" , "message": "Chargement validé",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(res));
  } catch(e) {
    Raven.captureException(e);
    yield put(Notifications.error({title: "Cross-dock" , "message": "Erreur, chargement non validé",  autoDismiss:3, dismissible:"click"}));
    yield put(errorThrown(e));
  }
  yield put({ type: MAIN_MODAL_HIDE });
}

export const crossDockingSagas = [
  takeEvery(ADD_ORDER_TO_XD_DOCK, addOrderToXdDock),
  takeEvery(LOAD_FULL_STOCK_DOCK, loadFullStockDock),
];
