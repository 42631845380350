import {connect} from "react-redux";

import {dropOnPackingDefault, dropOnPackingTask} from "../actions";
import PackingNowList from "../components/PackingNowList";
import {currentToggleValue, PACKING_NOW_LONG_LIST_KEY} from "../utils/toggle";
import {SWITCH_TOGGLE} from "../actions/switch_toggle";
import {getAvgTitles} from "../selectors/iph";
import {getCphAvg} from "../selectors/cph";
import {getTadAvg} from "../selectors/tad";
import {getTapAvg} from "../selectors/tap";
import {getSortedEmergencyPackingNow, getSortedFilteredPackingNow, getTitleLink} from "../selectors/packing";

const mapStateToProps = state => {
  let iph_titles = getAvgTitles(state);
  return {
    titleLink: getTitleLink(state),
    titleRight : iph_titles['titleRight'],
    normalTasks: getSortedFilteredPackingNow(state),
    emergencyTasks: getSortedEmergencyPackingNow(state),
    countOrdersTodo: state.m.metas.load_incoming_order,
    countOrdersTodoToday: state.m.metas.load_incoming_order_today,
    countItemsTodo: state.m.metas.load_incoming_items,
    countItemsTodoToday: state.m.metas.load_incoming_items_today,
    "showAll": currentToggleValue(state.toggle, PACKING_NOW_LONG_LIST_KEY),
    packerstatiph_avg: iph_titles['packerstatiph_avg'],
    packerstatcph_avg: getCphAvg(state),
    packerstattad_avg: getTadAvg(state),
    packerstattap_avg: getTapAvg(state),
    shipmentstats: state.m.shipmentstats,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "onDropTask":      (targetTaskId, droppedTaskId) => dispatch(dropOnPackingTask(targetTaskId, droppedTaskId)),
    "onDropDefault":   (driverId, droppedTaskId) => dispatch(dropOnPackingDefault(droppedTaskId)),
    "onToggle":        () => dispatch({type: SWITCH_TOGGLE, key: PACKING_NOW_LONG_LIST_KEY}),
  };
};

let PackingNowListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingNowList);


export default PackingNowListLinked;
