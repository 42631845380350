import React from "react";
import PropTypes from "prop-types";

import "../../css/DriverStates.css";
import DriverStateLinked from "./DriverStateLinked";
import {DriverBurger} from "./DriverBurger";
import {SUB_DISPATCH_CODES, SUB_DISPATCH_CONFIGURATIONS} from "../../sub_dispatchs";


const DriverStates = ({ drivers, location }) => {
  const query = new URLSearchParams(location.search);
  const sub = query.get("sub");
  if(
    sub !== null
    && sub !== undefined
    && SUB_DISPATCH_CODES.indexOf(sub) !== -1
    && (
      SUB_DISPATCH_CONFIGURATIONS[sub].withDrivers === false
      || SUB_DISPATCH_CONFIGURATIONS[sub].withFlowDriverStates === false
    )
  ){
    return null;
  }
  return (
    <div className="drivers">
      <div className="panel panel-default">
        <div className="panel-body drivers-list clearfix">
          {drivers.map(d =>
            <DriverStateLinked key={d.id} driver={d} />)}

          <DriverBurger />
        </div>
      </div>
    </div>
  );
};

DriverStates.propTypes = {
  drivers: PropTypes.array.isRequired
};

export default DriverStates;



