import {createSelector} from 'reselect';
import moment from "moment";
import {sortByKey} from "../utils";


const getLoadsFuture = state => state.m.metas.loads_future;

export const getLoadsForStats = createSelector(
  [getLoadsFuture],
  (loadsFuture) => {
    let plop = [];
    for (let k in loadsFuture) {
      let t = {
        "day": k,
        "label": moment(k).format('dddd D MMMM'),
        "load": loadsFuture[k]
      }

      plop.push(t);
    }
    plop.sort(sortByKey('day'));
    return plop;
  }
)
