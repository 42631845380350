import React from 'react';
import PropTypes from "prop-types";

class DisplayPackerName extends React.Component {
  render() {
    const { packer } = this.props;

    if(packer.name !== ''){
      return packer.name;
    }
    return packer.email;
  }
}

DisplayPackerName.propTypes = {
  packer: PropTypes.object.isRequired,
};

export default DisplayPackerName;
