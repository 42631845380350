import React from 'react';
import {LinkMapsRound} from "./LinkMapsRound";
import {BtnDefine} from "./BtnDefine";
import {Badge} from "react-bootstrap";
import {BtnExtractTask} from "./BtnExtractTask";
import {BtnOptimize} from "./BtnOptimize";
import {BtnEstimateKO} from "./BtnEstimateKO";


// eslint-disable-next-line react/prop-types
export const BasicDockName = ({ dock, user }) => {

  const isAdminUser = user.groups && user.groups.is_manager;
  return (
    <span className="BasicDockName">
      <span>{dock.name}</span>
      {dock.scheduled_round && <span style={{ margin: '0 2px' }} title={`[${dock.scheduled_round.id}] ${dock.scheduled_round.name}`}>-{dock.scheduled_round.code}</span>}
      {dock.cross_docking_target_center !== null && <Badge variant="info"><i className="fa-solid fa-shuffle"></i></Badge>}
      <LinkMapsRound dock={dock} style={{marginRight:"5px"}} />
      <BtnDefine dock={dock} style={{marginRight:"5px"}}/>
      {isAdminUser ? <BtnOptimize dock={dock} style={{marginRight: "5px"}}/> : null}
      {dock.defined_at && <BtnEstimateKO dock={dock} style={{marginRight: "5px"}}/>}
      <BtnExtractTask dock={dock} style={{marginRight:"5px"}} />
    </span>);
};
