/* eslint-disable react/prop-types */
import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {PresenceList} from "./PresenceList";
import moment from "moment/moment";
import {LOGS_LOAD} from "../actions";

import "../css/DriverLogs.css";
import {DriverLogsTitle} from "./DriverLogsTitle";
import {loadDriverList, showPopinPresenceAdd, showPopinExtraCostAdd} from "../actions";
import {ModalPresenceAdd} from "./ModalPresenceAdd";
import {ModalExtraCostAdd} from "./ModalExtraCostAdd";


// component
class InternalDriverLogs extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      //console.log("match : ", this.props.match.params);
      this.props.load(this.props.match.params);
    }
  }

  componentDidMount() {
    this.props.load(this.props.match.params);
  }

  // componentWillMount() {
  //   // console.log('componentWillMount, param', this.props.match.params);
  //   this.props.load(this.props.match.params);
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location !== this.props.location) {
  //     console.log("match : ", nextProps.match.params)
  //     this.props.load(nextProps.match.params);
  //   }
  //   //console.log('DriverLogs componentWillReceiveProps', nextProps);
  // }

  render() {
    let {loaded, filters , presences, history, centers } = this.props;

    const currentDate = filters.date && filters.date.length > 2 ? moment(filters.date) : moment();

    if (!loaded) {
      return (<div className="DriverLogs"><div className="row"><div className="col-md-12"><h4>{' '}</h4></div></div></div>);
    }

    if(filters.has_out_of_planning_delivery === "oui"){
      presences = presences.filter(presence => presence.has_out_of_planning_delivery);
    }

    const navigateNext =  (currentDate)  => {
      history.push('/logs/date/' + currentDate.add(1, 'days').format('YYYY-MM-DD'));
    };

    const navigatePrev =  (currentDate)  => {
      history.push('/logs/date/' + currentDate.subtract(1, 'days').format('YYYY-MM-DD'));
    };

    const setPayMode = (payMode) => {
      filters.pay_mode = payMode;
      this.props.load(filters);
    };
    const payModeSelectOnChange = (e) => {
      setPayMode(e.target.value);
    };
    const setVehicle = (vehicle) => {
      filters.vehicle = vehicle;
      this.props.load(filters);
    };
    const vehicleSelectOnChange = (e) => {
      setVehicle(e.target.value);
    };
    const setCenter = (center) => {
      filters.center_id = center;
      this.props.load(filters);
    };
    const centerSelectOnChange = (e) => {
      setCenter(e.target.value);
    };
    const setLogType = (logType) => {
      filters.log_type = logType;
      this.props.load(filters);
    };
    const logTypeSelectOnChange = (e) => {
      setLogType(e.target.value);
    };
    const setHasOutOfPlanningDelivery = (value) => {
      filters.has_out_of_planning_delivery = value;
      this.props.load(filters);
    };
    const hasOutOfPlanningDeliverySelectOnChange = (e) => {
      setHasOutOfPlanningDelivery(e.target.value);
    };

    const popinPresenceAdd = () => {
      this.props.popinPresenceAdd();
      this.props.loadDriverList();
    };

    const popinExtraCostAdd = () => {
      this.props.popinExtraCostAdd();
      this.props.loadDriverList();
    };

    //console.log('DriverLogs  rendering');

    // if (!driverLogs) {
    //   driverLogs = [];
    // }

    return (
      <div className="DriverLogs">
        <div className="row">
          <div className="col-md-12">
            <DriverLogsTitle presences={presences} filters={filters} history={history}/>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix" style={{marginBottom:"10px"}}>
            <div className="col">
              <Button className="float-left" size="sm" onClick={() => navigatePrev(currentDate)}>PRECEDENT</Button>
            </div>
            <div className="col text-center">
              <Button className="text-center btn-success" size="sm" onClick={() => popinPresenceAdd()}>
                <i className="fa-solid fa-user-clock"></i> AJOUTER UN LOG
              </Button>
              &nbsp;
              &nbsp;
              <Button className="text-center btn-success" size="sm" onClick={() => popinExtraCostAdd()}>
                <i className="fa-solid fa-money-bill-wave"></i> AJOUTER UN SURCOÛT
              </Button>
              <br />
              <span className={"btn btn-sm ml-3"}>
                Critère de paiement :
                <select
                   value={filters.pay_mode}
                   onChange={payModeSelectOnChange}
                   className={"btn btn-sm"}
                >
                  <option value={""} ></option>
                  <option value={"performance"} >Perf</option>
                  <option value={"presence"} >Temps</option>
                  <option value={"by_order"} >A la commande</option>
                  <option value={"by_day"} >Forfait jour</option>
                  <option value={"perf_and_waiting"} >Perf. et attente</option>
                </select>
              </span>
              <span className={"btn btn-sm ml-3"}>
                Véhicule :
                <select
                   value={filters.vehicle}
                   onChange={vehicleSelectOnChange}
                   className={"btn btn-sm"}
                >
                  <option value={""}></option>
                  <option value={"CAR"}>Voiture</option>
                  <option value={"SCO"}>Scooter</option>
                  <option value={"S50"}>Scooter 50</option>
                  <option value={"S125"}>Scooter 125</option>
                  <option value={"SELEC"}>Scooter Elec</option>
                  <option value={"BIKE"}>Vélo</option>
                  <option value={"TRUCK"}>Camion</option>
                </select>
              </span>
              <br />
              <span className={"btn btn-sm ml-3"}>
                Centre :
                <select
                   value={filters.center_id}
                   onChange={centerSelectOnChange}
                   className={"btn btn-sm"}
                >
                  <option value={""}></option>
                  {centers.map(c => (<option value={c.id}>{c.name}</option>))}

                </select>
              </span>
              <span className={"btn btn-sm ml-3"}>
                Type de log :
                <select
                   value={filters.log_type}
                   onChange={logTypeSelectOnChange}
                   className={"btn btn-sm"}
                >
                  <option value={""} ></option>
                  <option value={"presence"} >Shift</option>
                  <option value={"extra_cost"} >Surcoût</option>
                </select>
              </span>
              <span className={"btn btn-sm ml-3"}>
                Livraison hors planning :
                <select
                   value={filters.has_out_of_planning_delivery}
                   onChange={hasOutOfPlanningDeliverySelectOnChange}
                   className={"btn btn-sm"}
                >
                  <option value={""} ></option>
                  <option value={"oui"} >Oui</option>
                </select>
              </span>
            </div>

            <div className="col">
              <Button className="float-right" size="sm" onClick={() => navigateNext(currentDate)}>SUIVANT</Button>
            </div>
          </div>
        </div>
        {/*<PresenceList logs={{[Object.keys(presences)[0]] :Object.values(presences)[0]}} />*/}
        <PresenceList logs={presences} />
        <ModalPresenceAdd />
        <ModalExtraCostAdd />
      </div>
    );
  }
}


// link
export const DriverLogs = withRouter(connect(
  (state) => {

    // because sometimes, Object.value don't work as expected , dont know why
    let logs_logs = [];
    if (state.logs && state.logs.logs) {
      for (let k in state.logs.logs) {
        logs_logs.push(state.logs.logs[k]);
      }
    }

    return {
      loaded: state.logs.loaded,
      filters : state.logs.filters,
      centers: state.logs.centers,
      presences: logs_logs.sort((a, b) => {

        if (a.driver.name > b.driver.name) {
          return 1;
        } else if (a.driver.name < b.driver.name) {
          return -1;
        } else if (a.type === "driverextracost") {
          return -1;
        } else {
          return a.started_at <= b.started_at ? -1 : 1;
        }
      })
    };
  },
  (dispatch) => {

    return {
      load : (filters) => {
        dispatch({type:LOGS_LOAD, filters: filters});
      },
      "popinPresenceAdd": () => dispatch(showPopinPresenceAdd()),
      "popinExtraCostAdd": () => dispatch(showPopinExtraCostAdd()),
      "loadDriverList": () => dispatch(loadDriverList()),
    };
  }
)(InternalDriverLogs));

