import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";

import "../css/OrderCrossDocking.css";
import moment from "moment/moment";
import { slotLabel } from "../../../utils/task";
import { humanStatus } from '../../../utils/status';


const InternalOrderCrossDocking = ({order, allCenters }) => {
  if (!order) {
    return null;
  }
  let history = useHistory();
  moment.locale('fr');
  const centerNamesById = allCenters.reduce(
    (acc, item) => {
      acc[item.id] = item.name;
      return acc;
    }, {}
  );

  return <Table hover size="sm" className="OrderCrossDocking">
    <tbody>
      <tr className="docking-title">
        <th colSpan={2}>
          Commande avec cross docking&nbsp;
          <span className="fa-solid fa-shuffle"></span>
        </th>
      </tr>
      {
        order.cross_docking_orders.map(o => {
          const label = slotLabel(o);
          const deliveredDate = moment(o.shipping_time).format("dddd DD.MM.YYYY");
          return (
            <tr
              key={o.cross_docking}
              className={
                o.cross_docking === order.cross_docking
                  ? 'current-order' : void 0}
              onClick={
                o.cross_docking === order.cross_docking
                  ? void 0
                  : () => history.push(`/support/order/${o.id}/`)}
            >
              <td>{o.random_id}</td>
              <td>{deliveredDate} {label}</td>
              <td>{humanStatus(o.status)}</td>
              <td>{o.products_count} produit{o.products_count > 1 ? 's' : ''}</td>
              <td>{o.dynamic_number_of_bags} sac{o.dynamic_number_of_bags > 1 ? 's' : ''}</td>
              <td>{centerNamesById[o.center]}</td>
            </tr>
          );
        })
      }
    </tbody>
  </Table>;
};

InternalOrderCrossDocking.propTypes = {
  "order": PropTypes.object,
  "allCenters": PropTypes.array,
};

export const OrderCrossDocking = connect(
  (state) => ({
    allCenters: state.support.meta ? state.support.meta.all_centers : []
  })
)(InternalOrderCrossDocking);

