import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

import { DISPATCH_MAIN_MODAL_HIDE } from "../../actions/modal";
import {orderToRestock, orderWasDelivered, validateRestock} from "../../actions/deliveries";
import { api } from "../../../../api";
import { WAITING_RESTOCK_FLAG_ID } from "../../utils/order";
import RedeliverFormLinked from "./RedeliverOrRestock/RedeliverFormLinked";

const REDELIVER_CHOICE = 'redeliver';
const RESTOCK_CHOICE = 'restock';
const WAITING_RESTOCK = 'waiting_restock';

const TITLE_MAPPING = {
  [REDELIVER_CHOICE]: ': Relivraison',
  [RESTOCK_CHOICE]: ': Restock',
  [WAITING_RESTOCK]: ': Attente de restockage',
};

const RedeliverOrRestockModal = ({
  modal,
  dispatch,
}) => {
  const initialState = {
    selectedChoice: null,
    loading: false
  };

  const [modalState, setModalState] = useState({...initialState});
  const [slots, setSlots] = useState({ datas: [], loading: true })
  const [addresses, setAddresses] = useState({ datas: [], loading: true })
  const [lastDriver, setLastDriver] = useState({ driver: null, loading: true })

  useEffect(() => {
    if (
      modal && modal.modalType === 'redeliver_or_restock'
      && modal.task
    ) {
      const fetchAddresses = async (userId) => {
        const res = await api.getUserAddresses(userId);
        setAddresses({ datas: [...res.data], loading: false })
      }

      const fetchSlots = async (postalCode, orderId) => {
        const slots = await api.loadSlotsForOrder(postalCode, orderId);
        setSlots({ datas: [...slots], loading: false })
      }

      const fetchDriver = async (orderId) => {
        const driver = await api.getLastDriver(orderId);
        setLastDriver({ driver, loading: false })
      }

      const { client, linked_order, address } = modal.task;

      if (!modal.task.flagged_orders.find(of => of.flag_id === WAITING_RESTOCK_FLAG_ID)) {
        fetchSlots(address.postal_code, linked_order.id)
        fetchAddresses(client.id)
        fetchDriver(linked_order.id)
      } else if (modalState.selectedChoice !== WAITING_RESTOCK) {
        setModalState({...modalState, selectedChoice: WAITING_RESTOCK})
      }
    }
    return () => setModalState(initialState);
  }, [modal]);

  if (!modal || !modal.task) {
    return null;
  }

  const onHide = () => dispatch({ type: DISPATCH_MAIN_MODAL_HIDE });
  const selectChoice = choice => setModalState({ ...modalState, selectedChoice: choice });

  const { modalType, task } = modal;
  const { linked_order: order } = task;

  let titleLabel = `Commande #${order.random_id}`;

  if (TITLE_MAPPING[modalState.selectedChoice]) {
    titleLabel += TITLE_MAPPING[modalState.selectedChoice];
  }

  return (
    <Modal
      show={modalType === "redeliver_or_restock"}
      onHide={onHide}
      animation={false}
      backdrop
    >
      <Modal.Header closeButton className="py-2">
        <Modal.Title>Relivrer ou restocker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {[REDELIVER_CHOICE, RESTOCK_CHOICE].includes(modalState.selectedChoice) && (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => selectChoice(null)}
              className="float-left"
            >
              Retour
            </Button>
          )}
          <h4 className="mb-4">{titleLabel}</h4>
        </div>
        {!modalState.selectedChoice && (
          <React.Fragment>
            <div className="d-flex justify-content-center mx-4 my-2">
              <ul>
                <li>Livreur: {lastDriver.driver && lastDriver.driver.name}</li>
                <li>
                  <strong>{order.number_of_bags} {order.number_of_bags > 1 ? 'sacs' : 'sac'} </strong>
                  ({order.number_of_bags - order.number_of_bags_isotherm} sec, {order.number_of_bags_isotherm} frais)
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-center mx-4 my-2">
              <Button
                variant="success"
                className="px-2 mx-3"
                onClick={() => selectChoice(REDELIVER_CHOICE)}
              >
                Relivrer
              </Button>
              <Button
                variant="warning"
                className="px-2 mx-3"
                onClick={() => dispatch(orderToRestock(order.id))}
              >
                Restocker
              </Button>
              <Button
                variant="success"
                className="px-2 mx-3"
                onClick={() => dispatch(orderWasDelivered(order.id))}
              >
                Commande livrée
              </Button>
            </div>
          </React.Fragment>
        )}
        {modalState.selectedChoice === REDELIVER_CHOICE && (
          <RedeliverFormLinked
            slots={slots.datas}
            address={task.address}
            addresses={addresses}
            order={order}
          />
        )}
        {modalState.selectedChoice === WAITING_RESTOCK && (
          <div>
            <div className="alert alert-info">
              <p>
                La commande doit être restockée via la fonctionnalité
                <strong>&ldquo;Restocker par N° de commande&ldquo;</strong>
                sur un PDA.
              </p>
              <p>
                <strong>Si les produits ont été restockés</strong>, vous pouvez
                valider en cliquant sur le bouton ci-dessous
                (la commande disparaitra du flow).
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                size="sm"
                className="mx-auto"
                onClick={() => dispatch(validateRestock(order.id))}
              >
                La commande a été restockée
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Button variant="secondary" size="sm" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RedeliverOrRestockModal.propTypes = {
  modal: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(
  state => ({
    modal: state.dispatch.modal,
  })
)(RedeliverOrRestockModal);
