

export const ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG = 'ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG';
export const ASSIGN_LOAD_PSEUDOZONE = 'ASSIGN_LOAD_PSEUDOZONE';
export const ASSIGN_LOAD_CONFIGS = 'ASSIGN_LOAD_CONFIGS';

export const ASSIGN_LOADED_NEXTCONFIG = "ASSIGN_LOADED_NEXTCONFIG";
export const ASSIGN_LOADED_PSEUDOZONE = "ASSIGN_LOADED_PSEUDOZONE";
export const ASSIGN_LOADED_CONFIGS = "ASSIGN_LOADED_CONFIGS";

export const ASSIGN_DROP_NEXTCONFIG = "ASSIGN_DROP_NEXTCONFIG";

export const ASSIGN_UPDATE_NEXTCONFIG = "ASSIGN_UPDATE_NEXTCONFIG";
export const ASSIGN_UPDATED_NEXTCONFIG = "ASSIGN_UPDATED_NEXTCONFIG";


export const ASSIGN_UPDATE_CONFIGS = "ASSIGN_UPDATE_CONFIGS";


export const ASSIGN_SKIP = "ASSIGN_SKIP";


export const changeMaxStaff = (id) => {
  let newNumber = window.prompt("Nombre maximum de personne sur ce type de tâche:");
  newNumber = parseInt(newNumber, 10);

  if (isNaN(newNumber)) {
    return {
      type: ASSIGN_SKIP,
    };
  } else {
    return {
      type: ASSIGN_UPDATE_NEXTCONFIG,
      id: id,
      patch: {max_staff: newNumber},
    };
  }
};

export const changeMaxBuffer = (id) => {
  let newNumber = window.prompt("Nombre maximum à preparer d'avance (max_buffer):");
  newNumber = parseInt(newNumber, 10);

  if (isNaN(newNumber)) {
    return {
      type: ASSIGN_SKIP,
    };
  } else {
    return {
      type: ASSIGN_UPDATE_NEXTCONFIG,
      id: id,
      patch: {max_buffer: newNumber},
    };
  }
};

export const changeLateThreshold = (id) => {
  let newNumber = window.prompt("Seuil pour qu'un type de tâche soit considéré en retard (late_threshold):");
  newNumber = parseInt(newNumber, 10);

  if (isNaN(newNumber)) {
    return {
      type: ASSIGN_SKIP,
    };
  } else {
    return {
      type: ASSIGN_UPDATE_NEXTCONFIG,
      id: id,
      patch: {late_threshold: newNumber},
    };
  }
};

// export const toggleOneGrantBypass = (id, currentValue) => {
//   return {
//     type: ASSIGN_UPDATE_NEXTCONFIG,
//     id: id,
//     patch: {is_one_grant_bypass_max_buffer: !currentValue},
//   };
// };
