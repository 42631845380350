import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { stuartVehiclesSelect } from '../../../utils/stuart';
import { flattenAddress } from '../../../utils/address';


const DockStuartCallContent = ({ dock, onChangeVehicle, loadingEta, stuartEta }) => {
  let weight = dock.tasks
    .map(task => Number(task.linked_order.total_weight.replace('Kg', '').trim()))
    .filter(orderWeight => !isNaN(orderWeight))
    .reduce((totalWeight, orderWeight) => totalWeight + orderWeight, 0);
  weight = Math.round(weight);

  const addresses = dock.tasks.reduce(
    (acc, task) => acc.find(a => a.hash === task.address.hash) ? acc : [...acc, task.address], []
  );
  const numberOfBags = dock.tasks.map(t => t.linked_order.number_of_bags).reduce((sum, bags) => sum + bags, 0);

  return (
    <div>
      <div>Adresses</div>
      <ul>
        {addresses.map(address => <li key={address.hash}>{flattenAddress(address)}</li>)}
      </ul>
      <ul>
        <li>Poids: {weight} Kg</li>
        <li>Nombre de sacs: {numberOfBags}</li>
        <li>
          Véhicule:
          <select name="stuart_vehicles" onChange={onChangeVehicle}>
            {stuartVehiclesSelect.map(v =>
              <option key={v.value} value={v.value}>{v.name}</option>
            )}
          </select>
        </li>
        <li>
          Estimation arrivée Stuart au centre: {
            loadingEta
              ? 'calcul en cours...'
              : stuartEta
          }
        </li>
      </ul>
      {weight > 12
        ? (
          <Alert variant="danger">
            Le poids calculé de la commande est supérieur à 12 kg.
          </Alert>
        ): null
      }
    </div>
  );
};

DockStuartCallContent.propTypes = {
  dock: PropTypes.object,
  onChangeVehicle: PropTypes.func,
  loadingEta: PropTypes.bool,
  stuartEta: PropTypes.string,
};

export default DockStuartCallContent;
