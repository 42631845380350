import React  from 'react';
import PropTypes from "prop-types";

const openAlertDetail = (alert) => {
  let url = process.env.REACT_APP_DELEEVADMIN + "/tadp/alert-detail/" + alert.id + "/";
  let name = alert;
  let options = "menubar=no, status=no, menubar=no, width=600, height=600";
  window.open(url, name, options);
};


const Alert = ({alerts, onCloseAlert}) => {

  return (
    alerts.length > 0 ?
      <div className="row" style={{marginTop: "10px"}}>
        <div className="col-md-12" >
          {alerts.map(a =>
            (<div className="alert alert-danger" role="alert" key={a.id} style={{padding:"5px"}}>
              {a.label} &nbsp;
              {a.link ? (<a href={process.env.REACT_APP_DELEEVADMIN + a.link} target="_blank" rel="noopener noreferrer">voir la commande</a>) : null }
              <button className="btn btn-danger btn-xs float-right" style={{marginLeft: "5px"}} onClick={() => onCloseAlert(a)}>fermer</button>
              <button className="btn btn-primary btn-xs float-right"  onClick={() => openAlertDetail(a)}>détail alerte</button>
            </div>)
          )}
        </div>
      </div>
      : null
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  onCloseAlert: PropTypes.func.isRequired
};

export default Alert;
