import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

import { DeliveryRow } from "./DeliveryRow";
import {SUB_DISPATCH_CODES, SUB_DISPATCH_CONFIGURATIONS} from "../../sub_dispatchs";
import {withRouter} from "react-router-dom";

const InternalNotDeliveredOrders = ({ tasks, location }) => {
  const [showing, setShowing] = useState(true);

  const show = {
    last_driver_name: true,
    displayMode: 'two_line',
    name: true,
    notDelivered: true,
  };

  const query = new URLSearchParams(location.search);
  const sub = query.get("sub");
  if(
    sub !== null
    && sub !== undefined
    && SUB_DISPATCH_CODES.indexOf(sub) !== -1
    && SUB_DISPATCH_CONFIGURATIONS[sub].withNotDeliveredOrders === false
  ){
    return null;
  }

  return (
    <div className="DeliveriesToDispatch">
      <h4>
        Commandes à relivrer / restocker <span>({tasks.length})</span>
        <button
          className={"btn btn-xs btn-default float-right"}
          onClick={() => setShowing(!showing)}
        >
          {showing ? "Masquer" : "Afficher"}
        </button>
      </h4>
      {showing && tasks.length > 0 && (
        <Table bordered size="sm" hover striped>
          <tbody>
            {tasks.map((task) => (
              <DeliveryRow
                key={task.id}
                task={task}
                show={show}
                withSelect={true}
                addressWidth={'9rem'}
                taskWidth={'5rem'}
                userWidth={'4rem'}
              />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

InternalNotDeliveredOrders.propTypes = {
  tasks: PropTypes.array,
};

export default withRouter(connect((state) => ({
  tasks: state.dispatch.deliveries_not_delivered,
}))(InternalNotDeliveredOrders));
