import { all } from 'redux-saga/effects';
import {mainSagas} from "./sagas/mainSagas";
import {userSagas} from "./sagas/userSagas";
import {modalSagas} from "./sagas/modalSagas";

import {logsSagas} from "./apps/logs/sagas";
import {supportSagas} from "./apps/support/sagas";
import {assignSagas} from "./apps/assign/sagas";
import { arrivalsSagas } from "./apps/arrivals/sagas";
import { dispatchSagas } from "./apps/dispatch/sagas/main";
import { crossDockingSagas } from './apps/crossdocking/sagas';
import {toggleSagas} from "./sagas/toggleSagas";
import {commonModalsSagas} from "./sagas/commonModalsSagas";


function* mySaga() {
  yield all([
    ...mainSagas,
    ...logsSagas,
    ...modalSagas,
    ...userSagas,
    ...supportSagas,
    ...toggleSagas,
    ...assignSagas,
    ...arrivalsSagas,
    ...dispatchSagas,
    ...crossDockingSagas,
    ...commonModalsSagas,
  ]);
}

export default mySaga;
