import React from 'react';
import { withFormik, Field } from 'formik';
import {Col, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {showPopinReprepareOrderAfter} from "../../../actions";
import { connect } from 'react-redux';

const MyForm = props => {
  const {
    values,
    handleSubmit,
  } = props;

  const product_nbr = values.order.products.length;

  values.order.products.forEach(
    (product) => {
      let maxQty = Math.max(0, product.stock_quantity - (product.stock_quantity % product.product.pack));
      product.maxQtyAllowed = maxQty;

      if(typeof product.reprepQuantity === 'undefined') {
        product.reprepQuantity = product.quantity;
        if (product.stock_quantity < product.quantity) {
          product.reprepQuantity = product.maxQtyAllowed;
        }
      }
    }
  );

  const onCheckboxChange = (elem) => {
    let newBool = elem.checked;
    let id = elem.id.split("_");
    id = id[id.length - 1];
    values.order.products[id].checked = newBool;
    props.setFieldValue("products", values.order.products);
    checkProductQuantity(id);
  };

  const handleCheckboxClick = (e) => {
    let checkbox = e.currentTarget.querySelectorAll('input[type="checkbox"]')[0];
    if (e.target.tagName.toLowerCase() === 'td') {
      checkbox.checked = !checkbox.checked;
    }
    onCheckboxChange(checkbox);
  };

  const onReprepQuantityChange = React.useCallback((event) => {
    let elem = event.currentTarget;
    let newValue = parseInt(elem.value, 10);
    let id = elem.id.split("_");
    id = id[id.length - 1];
    if (checkProductQuantity(id)) {
      values.order.products[id].reprepQuantity = newValue;
      props.setFieldValue("products", values.order.products);
    }
  });

  const checkProductQuantity = (productIdx) => {
    let productCheckBox = document.getElementById("checkbox_rep_" + productIdx.toString());
    let productQty = document.getElementById("quantity_rep_" + productIdx.toString());
    if (productCheckBox.checked === true) {
      productQty.required = true;
      productQty.min = values.order.products[productIdx].product.pack;
      productQty.reportValidity();
      let newQty = parseInt(productQty.value, 10);
      if (newQty < values.order.products[productIdx].product.pack) {
        return false;
      }
    } else {
      productQty.required = false;
      productQty.min = 0;
    }
    return true;
  }

  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <Field name="area_filter" style={{marginBottom: '10px'}} placeholder="Filtre par zone" onChange={(e) => {
        let filter_start_width = e.target.value;
        for (let a = 0; a < product_nbr; ++a){
          let current_area = document.getElementById("area_rep_" + (a).toString());
          current_area.parentElement.hidden = !current_area.innerHTML.startsWith(filter_start_width);
        }
      }} />
      <FormGroup>
        <table className="table table-condensed table-hover">
          <tbody>
            <tr>
              <td>
                <Field
                  type="checkbox"
                  name="checked"
                  onChange={(e) => {
                    document.querySelectorAll(
                      'input[id^="checkbox_rep_"]:not([disabled])'
                    ).forEach(currentCheckbox => {
                      currentCheckbox.checked = e.target.checked;
                      onCheckboxChange(currentCheckbox);
                    });
                  }}
                />
              </td>
              <td>Quantité initiale</td>
              <td>Quantité à repréparer</td>
              <td>Stock</td>
              <td>Nom</td>
              <td>Zone</td>
            </tr>
            {values.order.products && values.order.products.map((product, idx) => {
              const {
                product: { id, area, shelf, tier, selling_name },
                checked,
                reprepQuantity,
                stock_quantity,
                real_quantity,
                not_found,
                quantity,
                maxQtyAllowed,
              } = product;

              return (
                <tr
                  key={`${id}-${idx}`}
                  id={"product_rep_" + idx.toString()}
                  style={{opacity: stock_quantity === 0 ? '0.7' : '1'}}
                  className={stock_quantity > 0 && stock_quantity < real_quantity ? 'alert-warning': ''}
                >
                  <td onClick={(e) => { handleCheckboxClick(e);}}>
                    <Field
                      disabled={stock_quantity === 0}
                      id={"checkbox_rep_" + idx.toString()}
                      type="checkbox" checked={checked}
                      name="checked"
                      value="" 
                    />
                  </td>
                  <td><b>{quantity}</b></td>
                  <td>
                    <Field
                      disabled={stock_quantity === 0}
                      id={"quantity_rep_" + idx.toString()}
                      type="number"
                      value={reprepQuantity}
                      name="reprepQuantity"
                      max={maxQtyAllowed}
                      step={product.product.pack}
                      onChange={onReprepQuantityChange}
                    />
                  </td>
                  <td>{not_found ? "Introuvable" : stock_quantity}</td>
                  <td>
                    <a
                      href={`${process.env.REACT_APP_PRODUCTS}/products/${id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selling_name}
                    </a>
                  </td>
                  <td id={"area_rep_" + idx.toString()}>{area}.{shelf}.{tier}</td>
                </tr>
              );
            })
            }
          </tbody>
        </table>
      </FormGroup>
      <FormGroup>
        <Col sm={12} className="text-center">
          <button type="submit" className="btn btn-primary">Continuer</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "handleSubmit": PropTypes.any,
};

const ReprepareForm = withFormik({
  handleSubmit(values, { props, setSubmitting }) {
    props.dispatch(showPopinReprepareOrderAfter(values.order));
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(MyForm);
export const ReprepareOrder = connect()(ReprepareForm);
