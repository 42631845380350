import {connect} from "react-redux";

import TransitAreaModalContent from "./TransitAreaModalContent";
import { connectTransitAreaModalAction } from "../../utils/transit_areas";


export const TransitAreaModal = connect(
  (state) => ({
    "modal" : state.m.modal,
    "pseudoZones": state.m.pseudoZones,
    "transit_areas": state.m.transit_areas,
    "trolleys": state.m.metas.trolleys,
    "transit_area_types": state.m.metas.transit_area_types,
  }),
  (dispatch) => connectTransitAreaModalAction(dispatch)
)(TransitAreaModalContent);
