import driver from "./driver";
import dock from "./dock";
import order from "../../../reducers/main/order";
import task from "./task";
import refresh from "./refresh";
import dispatchFilter from "./dispatchFilter";
import transitAreas from "../../../reducers/main/transitAreas";
import trolleys from "../../../reducers/main/trolleys";
import {
  METAS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  METAS_DQP_COURIER_ENABLED_SAVE,
  METAS_RAIN_BONUS_SAVE,
  METAS_SAVED,
} from "../../../actions/index";
import {
  LOG_TROLLEY_NOT_FOUND_REVIEWED
} from "../../../actions/index";
import {
  DISPATCH_LOADED,
  DISPATCH_IGNORE_DISCONNECT,
  DISPATCH_LOAD,
  DISPATCH_BULK_CHANGE_STATUS,
  DISPATCH_CHANGE_STATUS,
  DISPATCH_LOAD_ERROR,
  DISPATCH_ORDER_CALL_STUART,
  DISPATCH_ORDER_STUART_SENDING,
  DISPATCH_DOCK_STUART_SENDING,
  // DISPATCH_ORDER_CALL_UBER,
  DISPATCH_ORDER_UBER_SENDING,
  DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  DISPATCH_METAS_SAVED,
  DISPATCH_SUB_SCREEN,
  DISPATCH_NEEDS_REFRESH,
} from "../actions";

import {
  TYPE_TROLLEY,
} from "../../../utils/transit_areas";

import {
  DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD,
  DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED,
  DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD,
  DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED,
  DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN,
  DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN,
  DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED,
  DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD,
  DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED,

} from "../actions/deposit_driver";

import {
  DISPATCH_START_DRIVER_PRESENCE,
  DISPATCH_STOP_DRIVER_PRESENCE,
  DISPATCH_CHANGE_DRIVER_VEHICLE,
  DISPATCH_CHANGE_DRIVER_CENTER,
  DISPATCH_DRIVER_UPDATE,
  DISPATCH_DRIVER_UPDATE_SUCCEEDED,
  DISPATCH_DRIVER_ACTIVITY_START,
  DISPATCH_DRIVER_ACTIVITY_END,

} from "../actions/driver";

import {
  DISPATCH_DOCK_UPDATE,
  DISPATCH_DOCK_CLOSE,
  DISPATCH_DOCK_UPDATED,
  DISPATCH_DOCK_ASSIGN,
  DISPATCH_DOCK_UNLOCK,
  DISPATCH_DOCK_DEFINE,
  DISPATCH_DOCK_UNDEFINE,
  DISPATCH_FINISH_DOCK,
  DISPATCH_DOCK_MANUALLY_OPTIMIZED,
  DISPATCH_DOCK_MANUALLY_VALIDATED,
  DISPATCH_DOCK_TOGGLE_EXTRACT,
  DISPATCH_DOCK_CHECK_TASK_TO_EXTRACT,
  DISPATCH_DOCK_TASK_EXTRACT,
  DISPATCH_DOCK_TASK_EXTRACTED,
  DISPATCH_DOCK_SWITCH_DRIVERS,
  DISPATCH_DOCK_DRIVERS_SWITCHED,
  DISPATCH_DOCK_READY_TO_LOAD,
  DISPATCH_DOCK_ADD, DISPATCH_AUTO_GENERATE,
  DISPATCH_CANCEL_STUART,
  DISPATCH_REGULARIZE_STUART,
  DISPATCH_CANCEL_UBER,
  DISPATCH_REGULARIZE_UBER,
} from "../actions/dock";

import {
  DISPATCH_LATE_ACTION,
  DISPATCH_LATE_WARNED,
  DISPATCH_DELIVERY_UPDATE,
  DISPATCH_DELIVERY_UPDATE_SUCCEEDED,
  DISPATCH_LATE_ORDER_IGNORE,
  DISPATCH_LATE_ORDER_VALIDATE,
  DISPATCH_LATE_ORDER_REVIEWED,
  DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED,
  DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED,
  DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED,
  DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED,
} from "../actions/deliveries";

import {
  DISPATCH_DOCK_DROP,
  DISPATCH_DOCK_DROP_ON_DEFAULT,
  DISPATCH_DOCK_DROP_ON_DELIVERY_TASK,
  DISPATCH_DROP_ON_DEFAULT,
  DISPATCH_DROP_ON_DELIVERY_TASK,
  DISPATCH_CANCEL_DELIVERY_DROP,
} from "../actions/drag_n_drop";

import {
  DISPATCH_FILTER_TOGGLE
} from "../actions/dispatchFilter";

import {
  DISPATCH_MAIN_MODAL_HIDE,
  DISPATCH_MAIN_MODAL_SHOW,
  DISPATCH_MODAL_OPTIMIZE_DOCK,
} from "../actions/modal";

import {
  MAIN_MODAL_HIDE,
  MAIN_MODAL_SHOW
} from "../../../actions/modal";

import {
  ORDER_UPDATE,
  ORDER_UPDATED,
  TRANSIT_AREA_CHANGED,
  TRANSIT_AREA_LOADED,
  TROLLEY_LOADED,
  TROLLEY_LOCATION_CHANGED,
  TROLLEY_LOGS_LOAD,
  TROLLEY_LOGS_LOADED,
} from "../../../actions/index";
import {DOCK_FILTER_DEPARTURE, POSTAL_CODES_FILTER} from "../actions/filters";
import filters from "./filters";
import KDBush from "kdbush";
import moment from "moment";
import {
  STATUS_ORDER_SHIPPING,
  STATUS_ORDER_UNDELIVERED_CLIENT,
  STATUS_ORDER_CANCELED,
  STATUS_ORDER_RECEIVED,
} from "../../../utils/status";
import {sortByKey} from "../../../utils";


function driverPositionFromDriverQueue(driver, driver_queues){
  let position = 0;
  if (typeof(driver_queues[driver.vehicle]) !== 'undefined') {
    driver_queues[driver.vehicle].forEach(function(driver_queue, i) {
      if (driver_queue.target_driver === driver.id) {
        position = i+1;
      }
    });
  }
  return position;
}

const preCompute = (new_state) => {
  let docks_by_id = {};
  new_state.docks.forEach(dock => docks_by_id[dock.id] = dock);

  let pz_by_id = {};
  new_state.pseudoZones.forEach(pz => pz_by_id[pz.id] = pz);

  if(
    // S'il y a des commandes (elles sont normalement toutes sur le même centre),
    new_state.deliveries
    && new_state.deliveries.length > 0
    // et que le centre logistique courant est paramétré pour que les commandes
    // cantine d'un même dock soient regroupées.
    && new_state.metas
    && new_state.metas.centers
    && new_state.metas.centers.find(center => center.id === new_state.deliveries[0].linked_order.center_id)
    && new_state.metas.centers.find(center => center.id === new_state.deliveries[0].linked_order.center_id).group_canteen_orders_in_docks
  ){
    let dockIdsAndCanteenIdsMap = new Map();
    // On fait passer directement les commandes sans cantine ou sans dock.
    let filteredTasks = new_state.deliveries.filter(task => task.linked_order.canteen_id === null || !task.dock);
    let tasksWithCanteen = new_state.deliveries.filter(task => task.linked_order.canteen_id !== null && task.dock);
    // Quant aux autres, on les trie par id.
    tasksWithCanteen.sort(sortByKey("id"));
    for (let task of tasksWithCanteen) {
      // Et on ne garde que la première pour un dock et une cantine donnée.
      // On calcule au passage des cumuls plus pertinents sur le poids,
      // le nombre de sacs, etc.
      let dock_id = typeof(task.dock.id) !== "undefined" ? task.dock.id : task.dock;
      if (dockIdsAndCanteenIdsMap.has(`${dock_id} ${task.linked_order.canteen_id}`)) {
        let mainTask = dockIdsAndCanteenIdsMap.get(`${dock_id} ${task.linked_order.canteen_id}`);
        let weight1 = parseFloat(
          mainTask.linked_order.total_weight.replace('Kg', '').trim()
        );
        weight1 = Math.round(weight1);
        let weight2 = parseFloat(
            task.linked_order.total_weight.replace('Kg', '').trim()
        );
        weight2 = Math.round(weight2);
        let weight = (weight1 || 0) + (weight2 || 0);
        mainTask.linked_order.total_weight = `${weight} Kg`;
        mainTask.linked_order.number_of_bags += task.linked_order.number_of_bags || 0;
        mainTask.linked_order.number_of_bags_iso += task.linked_order.number_of_bags_iso || 0;
        mainTask.linked_order.number_of_bags_dry += task.linked_order.number_of_bags_dry || 0;
        mainTask.linked_order.number_of_bags_frozen += task.linked_order.number_of_bags_frozen || 0;
        mainTask.linked_order.dynamic_number_of_bags += task.linked_order.dynamic_number_of_bags || 0;
        ++mainTask.linked_order.canteen_orders_count;
        mainTask.linked_order.canteen_tasks.push(task);
        continue;
      }
      const task_copy = Object.assign({}, task);
      task_copy.linked_order = Object.assign({}, task.linked_order);
      task_copy.linked_order.canteen_witness = true;
      task_copy.linked_order.canteen_orders_count = 1;
      task_copy.linked_order.canteen_tasks = [task];
      dockIdsAndCanteenIdsMap.set(`${dock_id} ${task_copy.linked_order.canteen_id}`, task_copy);
      filteredTasks.push(task_copy);
    }
    new_state.deliveries = filteredTasks;
  }

  let deliveries_by_id = {};
  new_state.deliveries.forEach((task) => {
    /* On récupère l'objet Dock et on l'injecte dans la task à la place d'un simple id
     * on ajoute la task à la liste des tâches du dock
     * on le fait pour dock et dock_target
     */
    if (task.dock) {
      let dock_id = typeof(task.dock.id) !== "undefined" ? task.dock.id : task.dock;
      task.dock = docks_by_id[dock_id];
    }
    if (task.dock_target) {
      let dock_target_id = typeof(task.dock_target.id) !== "undefined"
        ? task.dock_target.id
        : task.dock_target;
      task.dock_target = docks_by_id[dock_target_id];
    }

    task.linked_order.pseudo_zones_status.forEach(
      opz => opz.pseudozone = pz_by_id[opz.pseudozone_id]
    );

    task.address_string = (
      task.address.street_number
      + '|' + task.address.street
      + '|' + task.address.postal_code
      + '|' + task.address.city
    );
    task.tasks_with_same_address = null;
    task.tasks_with_same_address_and_intersecting_slot = null;

    deliveries_by_id[task.id] = task;
  });

  let trolleys_by_id = {};
  if (typeof(new_state.metas.trolleys) !== "undefined") {
    new_state.metas.trolleys.forEach(trolley => trolleys_by_id[trolley.id] = trolley);
  }
 
  let transit_areas_by_id = {};
  let transit_areas = new_state.transit_areas;
  if (!(new_state.transit_areas instanceof Array)) {
    transit_areas = Object.values(new_state.transit_areas);
  }
  transit_areas.forEach((transit_area) => {
    if (transit_area.transit_area_type === TYPE_TROLLEY && transit_area.trolley in trolleys_by_id) {
      transit_area.trolley = trolleys_by_id[transit_area.trolley];
      if (transit_area.additional_trolley && transit_area.additional_trolley in trolleys_by_id) {
        transit_area.additional_trolley = trolleys_by_id[transit_area.additional_trolley];
      }
    }
    transit_areas_by_id[transit_area.id] = transit_area;
  });

  new_state.transit_areas = transit_areas_by_id;

  let moving_tasks_by_oid = {};
  if (new_state.moving_tasks instanceof Array) {
    new_state.moving_tasks.forEach(
      (moving_task) => moving_tasks_by_oid[moving_task.order] = moving_task
    );
    new_state.moving_tasks = moving_tasks_by_oid;
  }

  let late_orders_by_oid = {};
  if (new_state.late_orders instanceof Array) {
    new_state.late_orders.forEach(lo => late_orders_by_oid[lo.order] = lo);
    new_state.late_orders = late_orders_by_oid;
  }

  new_state.deliveries = Object.values(deliveries_by_id);

  const idsOfTasksWithSameAddressByTaskId = new Map();
  // On repère les livraisons à la même adresse et à des créneaux qui se chevauchent
  const someDeliveries = Array.from(new_state.deliveries).filter(
    task => [
      STATUS_ORDER_RECEIVED,
      STATUS_ORDER_CANCELED,
      STATUS_ORDER_UNDELIVERED_CLIENT,
      // STATUS_ORDER_SHIPPING, géré plus bas
    ].indexOf(task.linked_order.status) === -1
  )
  let sorted_deliveries = Array.from(someDeliveries).sort(
    (a, b) => a.address_string.localeCompare(b.address_string)
  )

  const handleTasksWithSameAddress = (current_tasks) => {
    if(current_tasks.length < 2){
      return;
    }
    current_tasks.forEach(
      task1 => {
        idsOfTasksWithSameAddressByTaskId.set(
          task1.id,
          new Set(
            current_tasks.map(task2 => task2.id).filter(
              task2_id => task2_id !== task1.id
            )
          )
        )
      }
    );
  };

  let current_address_string = null;
  let current_tasks = [];
  sorted_deliveries.forEach(
    task => {
      if(current_address_string === null){
        current_address_string = task.address_string;
        current_tasks = [task];
        return;
      }
      if(current_address_string === task.address_string){
        current_tasks.push(task);
        return;
      }
      handleTasksWithSameAddress(current_tasks);
      current_address_string = task.address_string;
      current_tasks = [task];
    }
  )
  handleTasksWithSameAddress(current_tasks);

  let all_geocoded_deliveries = someDeliveries.filter(
    task => task.address && task.address.latitude !== null && task.address.longitude !== null
  )
  const geo_index = new KDBush(all_geocoded_deliveries.length);
  all_geocoded_deliveries.forEach(
    task => geo_index.add(task.address.longitude, task.address.latitude)
  )
  geo_index.finish();
  const idsOfTasksWithCloseAddressByTaskId = new Map();
  all_geocoded_deliveries.forEach(
    task => {
      // console.log(geo_index.within(task.address.longitude, task.address.latitude, 0.001));
      idsOfTasksWithCloseAddressByTaskId.set(
        task.id,
        new Set(
            // Si un jour geokdbush qui n'a pas été maintenu depuis 6 ans est à nouveau compatible avec le reste.
            // geokdbush.around(geo_index, task.address.longitude, task.address.latitude, 1000, 100)
            // En mode approximatif qui néglige qu'un degré décimal d'est en ouest ne compte pas pareil,
            // selon la latitude, un millième de degré vaut entre 70 et 120 m.
            geo_index.within(task.address.longitude, task.address.latitude, 0.002)
                .map(index => all_geocoded_deliveries[index].id)
                .filter(someId => someId !== task.id)
        ),
      )
    }
  )

  function notShippingOrSameDockTasks(task, task2){
    return (
      (
        (
          !task.linked_order
          || task.linked_order.status !== STATUS_ORDER_SHIPPING
        )
        && (
          !task2.linked_order
          || task2.linked_order.status !== STATUS_ORDER_SHIPPING
        )
      )
      || (
        task.dock && task2.dock && task.dock.id === task2.dock.id
      )
    )
  }

  someDeliveries.forEach(
    task => {
      if(!task.client || task.client.is_pro){
        return;
      }
      if(!idsOfTasksWithSameAddressByTaskId.has(task.id)){
        return;
      }
      const otherTaskIds = idsOfTasksWithSameAddressByTaskId.get(task.id);
      if(otherTaskIds.size <= 0){
        return;
      }
      task.tasks_with_same_address = Array.from(otherTaskIds).map(
        someId => deliveries_by_id[someId]
      ).filter(
        task2 => (
          task2.client
          && task2.client.id === task.client.id
          // && !task2.client.is_pro inutile suite au précontrôle 16 lignes plus haut.
          && notShippingOrSameDockTasks(task, task2)
        )
      );
      if(task.tasks_with_same_address.length === 0){
        task.tasks_with_same_address = null;
      }
    }
  )

  someDeliveries.forEach(
    task => {
      let otherTaskIds = null;
      if(idsOfTasksWithSameAddressByTaskId.has(task.id)) {
        otherTaskIds = idsOfTasksWithSameAddressByTaskId.get(task.id);
        if(idsOfTasksWithCloseAddressByTaskId.has(task.id)) {
          idsOfTasksWithCloseAddressByTaskId.get(task.id).forEach(
            element => otherTaskIds.add(element)
          );
        }
      }
      else {
        if(idsOfTasksWithCloseAddressByTaskId.has(task.id)) {
          otherTaskIds = idsOfTasksWithCloseAddressByTaskId.get(task.id);
        }
      }
      if(otherTaskIds === null || otherTaskIds.size === 0){
        return;
      }
      task.tasks_with_same_address_and_intersecting_slot = Array.from(otherTaskIds).map(
        someId => deliveries_by_id[someId]
      ).filter(
        task2 => {
          const order1 = task.linked_order;
          const order2 = task2.linked_order;
          if(!order1 || !order2){
            return false;
          }
          let max_of_shipping_time = order1.shipping_time;
          if(order2.shipping_time > max_of_shipping_time){
            max_of_shipping_time = order2.shipping_time;
          }
          max_of_shipping_time = moment(max_of_shipping_time);
          let min_of_max_shipping_time = order1.max_shipping_time;
          if(order2.max_shipping_time < min_of_max_shipping_time){
            min_of_max_shipping_time = order2.max_shipping_time;
          }
          min_of_max_shipping_time = moment(min_of_max_shipping_time);
          let duration = moment.duration(min_of_max_shipping_time.diff(max_of_shipping_time))
          return (
            // (order1.shipping_time <= order2.shipping_time && order2.shipping_time <= order1.max_shipping_time)
            // || (order2.shipping_time <= order1.shipping_time && order1.shipping_time <= order2.max_shipping_time)
            duration.asHours() >= 1
            && notShippingOrSameDockTasks(task, task2)
          );
        }
      );
      if(task.tasks_with_same_address_and_intersecting_slot.length === 0){
        task.tasks_with_same_address_and_intersecting_slot = null;
      }
    }
  )

  new_state.drivers.forEach(
    driver => {
      driver.queue_position = driverPositionFromDriverQueue(
        driver,
        new_state.driver_queues_by_vehicule,
      )
    }
  );

  return new_state;
};

const rv = (ns) => {
  ns.version = 0;
  return preCompute(ns);
};


export const dispatchReducer = (
  state = {
    //@todo move version and firstLoad in versioning
    version: "first_load",
    firstLoad: true,
    subScreen: null,
    waitingForRefresh: false,
    filters: {
      dockDeparture: 0,
      postalCodes: "",
      postalCodesArray: [],
      postalCodesSet: new Set([]),
    },
    versioning: {
      syncFails: 0,
      skipped:0,
      notUpdated:[],
      updated_at: (new Date()).getTime(),
      last_requested_at: (new Date()).getTime(),
    },
    deliveries: [],
    packings: [], // Pour la compatibilité avec le reducer order et transitAreas dans le flow principal
    drivers: [],
    docks: [],
    docks_extract: {},
    metas: {},
    moving_tasks: [],
    modal: {},
    pseudoZones: [],
    transit_areas: [],
    dispatchFilter: {},
    stats: {},
    trolley_logs: [],
    extracost_types: [],
    late_orders: {},
    deliveries_not_delivered: [],
    deposit: {
      driver: {
        loading: true,
        datas: {
          count: 0
        },
        products: [],
        debt_datas: {},
      },
      drivers: {
        loading: true,
        showAll: false,
        datas: {
          count: 0
        },
      },
      products: {
        loading: true,
        datas: {
          count: 0
        },
      }
    },
    driver_extra_cost_demands: [],
    dispatch_auto_processing: false,
    driver_queues_by_vehicule: {},
    alert_notifications: [],
  },
  action
) => {
  switch (action.type) {

  /* ############ MAIN  ########### */
  case DISPATCH_LOAD:
    return preCompute(refresh.refreshRequest(action, state));

  case DISPATCH_LOAD_ERROR:
    return preCompute(refresh.refreshRequestError(action, state));

  case DISPATCH_LOADED:
    return preCompute(refresh.refreshRequestSucceded(action, state));

  case DISPATCH_AUTO_GENERATE:
    return {...state, dispatch_auto_processing: true};

  case DISPATCH_IGNORE_DISCONNECT:
    return refresh.ignoreDisconnect(state);

  case DISPATCH_ORDER_CALL_STUART:
  case ORDER_UPDATE:
    return rv(order.updateOrder(action, state));

  case ORDER_UPDATED:
    return rv(order.updatedOrder(action, state));

  case DISPATCH_CHANGE_STATUS:
    return rv(order.changeStatus(action, state));

  case DISPATCH_BULK_CHANGE_STATUS:
    return rv(order.bulkChangeStatus(action, state));

  /* ######### DRAG N DROP ######## */
  case DISPATCH_DOCK_DROP:
    return rv(dock.reduceDockDropped(action, state));

  case DISPATCH_DOCK_DROP_ON_DELIVERY_TASK:
    return rv(task.dockDropOnDeliveryTask(action, state));

  case DISPATCH_DOCK_DROP_ON_DEFAULT:
    return rv(task.dockDropOnDefault(action, state));

  case DISPATCH_DROP_ON_DEFAULT:
    return rv(task.dropOnDefault(action, state));

  case DISPATCH_DROP_ON_DELIVERY_TASK:
    return rv(task.dropOnDeliveryTask(action, state));

  case DISPATCH_CANCEL_DELIVERY_DROP:
    return rv(task.cancelDeliveryDrop(action, state));

  /* ######### DELIVERIES  ######## */
  case DISPATCH_DELIVERY_UPDATE:
    return rv(task.deliveryUpdate(action, state));

  case DISPATCH_DELIVERY_UPDATE_SUCCEEDED:
    return rv({ ...state, ...{deliveries: task.updateSucceeded(action, state.deliveries)}});

  case DISPATCH_LATE_ORDER_IGNORE:
  case DISPATCH_LATE_ORDER_VALIDATE:
    return rv(task.lateOrderUpdate(action, state));

  case DISPATCH_LATE_ORDER_REVIEWED:
    return rv(task.lateOrderReviewed(action, state));

  case DISPATCH_LATE_ACTION:
  case DISPATCH_LATE_WARNED:
    return rv(task.lateProcessing(action, state));

  case DISPATCH_RESET_FOR_REDELIVERY_SUCCEEDED:
    return rv(task.resetOrderForRedelivery(action, state));

  case DISPATCH_ORDER_TO_RESTOCK_SUCCEEDED:
    return rv(task.orderToRestock(action, state));

  case DISPATCH_ORDER_VALIDATE_RESTOCK_SUCCEEDED:
    return rv(task.validateRestock(action, state));

  case DISPATCH_ORDER_WAS_DELIVERED_SUCCEEDED:
    return rv(task.orderWasDelivered(action, state));

  /* ############ DOCKS ########### */
  case DISPATCH_DOCK_UPDATE:
    return rv(dock.reduceDockUpdate(action, state));

  case DISPATCH_DOCK_UPDATED:
    return rv(dock.reduceDockUpdated(action, state));

  case DISPATCH_DOCK_ADD:
    return rv(dock.reduceNewDockAdded(action, state));

  case DISPATCH_DOCK_CLOSE:
    return rv(dock.reduceDockClose(action, state));

  case DISPATCH_DOCK_ASSIGN:
  case DISPATCH_DOCK_UNLOCK:
  case DISPATCH_FINISH_DOCK:
  case DISPATCH_DOCK_MANUALLY_OPTIMIZED:
  case DISPATCH_DOCK_MANUALLY_VALIDATED:
  case DISPATCH_DOCK_UNDEFINE:
  case DISPATCH_DOCK_DEFINE:
  case DISPATCH_DOCK_READY_TO_LOAD:
  case DISPATCH_CANCEL_STUART:
  case DISPATCH_REGULARIZE_STUART:
  case DISPATCH_CANCEL_UBER:
  case DISPATCH_REGULARIZE_UBER:
    return rv(dock.reduceDockSimpleAction(action, state));

  case DISPATCH_DOCK_TOGGLE_EXTRACT:
    return rv(dock.reduceDockToggleExtract(action, state));

  case DISPATCH_DOCK_CHECK_TASK_TO_EXTRACT:
    return rv(dock.reduceDockCheckTaskToExtract(action, state));

  case DISPATCH_DOCK_TASK_EXTRACT:
    return rv(dock.reduceDockTaskExtract(action, state));

  case DISPATCH_DOCK_TASK_EXTRACTED:
    return rv(dock.reduceDockTaskExtracted(action, state));

  case DISPATCH_DOCK_SWITCH_DRIVERS:
    return rv(dock.reduceDockSwitchDrivers(action, state));

  case DISPATCH_DOCK_DRIVERS_SWITCHED:
    return rv(dock.reduceDockDriversSwitched(action, state));

  /* ########### DRIVERS ########## */
  case DISPATCH_START_DRIVER_PRESENCE:
    return rv(driver.startDriverPresence(action, state));

  case DISPATCH_STOP_DRIVER_PRESENCE:
    return rv(driver.stopDriverPresence(action, state));

  case DISPATCH_CHANGE_DRIVER_VEHICLE:
    return rv(driver.changeDriverPresence(action, state));

  case DISPATCH_CHANGE_DRIVER_CENTER:
    return rv(driver.changeDriverPresence(action, state));

  case DISPATCH_DRIVER_UPDATE:
    return rv(driver.driverUpdating(action, state));

  case DISPATCH_DRIVER_UPDATE_SUCCEEDED:
    return rv(driver.driverUpdateSucceeded(action, state));

  case DISPATCH_DRIVER_ACTIVITY_START:
    return rv(driver.driverUpdating(action, state));

  case DISPATCH_DRIVER_ACTIVITY_END:
    return rv(driver.driverUpdating(action, state));

  /* ########### DEPOSIT DRIVER ########## */
  case DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN:
  case DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN:
  case DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED:
  case DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD:
    return rv({...state, deposit: {...state.deposit, driver: {...state.deposit.driver, loading: true}}});

  case DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED:
    return rv({...state, deposit: {...state.deposit, driver: {...action, loading: false}}});

  case DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD:
    return rv({...state, deposit: {...state.deposit, drivers: {...state.deposit.drivers, loading: true}}});

  case DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED:
    return rv({...state, deposit: {...state.deposit, drivers: {...action, loading: false}}});

  case DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD:
    return rv({...state, deposit: {...state.deposit, products: {...state.deposit.products, loading: true}}});

  case DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED:
    return rv({...state, deposit: {...state.deposit, products: {...action, loading: false}}});

  /* ######### TRANSIT AREAS ########## */
  case TRANSIT_AREA_LOADED:
    return rv(transitAreas.loaded(action, state));
  case TRANSIT_AREA_CHANGED:
    return rv(transitAreas.changed(action, state));

  /* ############ TROLLEY ############# */
  case TROLLEY_LOCATION_CHANGED:
    return rv(trolleys.changed(action, state));

  case TROLLEY_LOADED:
    return rv(trolleys.loaded(action, state));

  case TROLLEY_LOGS_LOAD:
    return rv(trolleys.logsLoad(action, state));

  case TROLLEY_LOGS_LOADED:
    return rv(trolleys.logsLoaded(action, state));

  /* ############# MODAL ############## */
  case DISPATCH_MAIN_MODAL_SHOW:
    return rv({...state, modal : action.modalData});

  case DISPATCH_MAIN_MODAL_HIDE:
    return rv({...state, modal : {}});

  case DISPATCH_ORDER_UBER_SENDING:
  case DISPATCH_DOCK_STUART_SENDING:
  case DISPATCH_ORDER_STUART_SENDING:
    return rv({...state, modal: {...state.modal, sending: action.sending } });

  case DISPATCH_FILTER_TOGGLE:
    return rv(dispatchFilter.toggleDispatchFilter(action, state));

  case DOCK_FILTER_DEPARTURE:
    return rv(filters.dockDepartureFilter(action, state));

  case DISPATCH_MODAL_OPTIMIZE_DOCK:
    return {...state, modal: {...state.modal, loading: true}};


  case POSTAL_CODES_FILTER:
    let postalCodesArray = [];
    for (let postalCode of action.payload.split(",")) {
      postalCode = postalCode.trim();
      if (postalCode !== "") {
        postalCodesArray.push(postalCode);
      }
    }
    // On enlève les doublons.
    const postalCodesSet = new Set(postalCodesArray);
    postalCodesArray = Array.from(postalCodesSet);
    // On trie
    postalCodesArray.sort((a,b) => ((a > b) - (a < b)));
    // pour avoir une belle chaîne dans l'ordre.
    const postalCodesString = postalCodesArray.join(",");
    return rv(
      {
        ...state,
        filters: {
          ...state.filters,
          postalCodes: postalCodesString,
          postalCodesArray: postalCodesArray,
          postalCodesSet: postalCodesSet,
        }
      }
    )

  case MAIN_MODAL_SHOW:
    return rv({...state, modal : action.modalData});

  case MAIN_MODAL_HIDE:
    return rv({...state, modal : {}});

  /* ############## METAS ############# */
  case METAS_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{extra_delay: action.value, updating: true}}}});
  case METAS_EXPRESS_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{express_extra_delay: action.value, updating: true}}}});
  case METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{express_prio_extra_delay: action.value, updating: true}}}});
  case METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{dqp_express_prio_enabled: action.value, updating: true}}}});
  case METAS_DQP_COURIER_ENABLED_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{dqp_courier_enabled: action.value, updating: true}}}});
  case METAS_RAIN_BONUS_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{rain_bonus_id: action.value, updating: true}}}});
  case DISPATCH_METAS_SAVED:
  case METAS_SAVED:
    return rv({...state, ...{metas: {...state.metas, ...{updating: false}}}});
  case DISPATCH_METAS_FRICHTI_EXTRA_DELAY_SAVE:
    return rv({...state, metas: {...state.metas, frichti_extra_delay: action.value, updating: true}});
  case DISPATCH_METAS_FRICHTI_EXPRESS_PRIO_EXTRA_DELAY_SAVE:
    return rv({...state, metas: {...state.metas, frichti_express_prio_extra_delay: action.value, updating: true}});
  case DISPATCH_METAS_FRICHTI_DQP_EXPRESS_PRIO_ENABLED_SAVE:
    return rv({...state, ...{metas: {...state.metas, frichti_dqp_express_prio_enabled: action.value, updating: true}}});
  case LOG_TROLLEY_NOT_FOUND_REVIEWED:
    return {
      ...state,
      alert_notifications: state.alert_notifications.filter(
        a => a.type !== "TROLLEY_NOT_FOUND" && a.id !== action.id
      )
    };
  case DISPATCH_SUB_SCREEN:
    return {
      ...state,
      subScreen: action.subScreen,
    };

  case DISPATCH_NEEDS_REFRESH:
    return rv({...state, waitingForRefresh: true});

  default:
    return state;
  }
};
