import {validateChangeTransitArea} from "../actions/index";
import {MAIN_MODAL_HIDE, showModalChangeTrolleyLocation} from "../actions/modal";
import { TROLLEY_LOGS_LOAD } from "../actions";

export const TYPE_FREE = 0;
export const TYPE_START = 1;
export const TYPE_FRESH = 2;
export const TYPE_TROLLEY = 3;
export const TYPE_DRIVER = 4;
export const TYPE_FROZEN = 5;

export const connectTransitAreaModalAction = (dispatch) => {
  return {
    "hide": () => {
      dispatch({"type": MAIN_MODAL_HIDE});
    },
    "validateTransitAreaType": (value) => {
      let error;
      if (value === '') {
        error = "Veuillez renseigner le type de dépôt";
      }
      return error;
    },
    "validateTransitAreaName": (value) => {
      let error;
      if (value === '') {
        error = "Veuillez renseigner le nom du dépôt";
      }
      return error;
    },
    "validateTrolley": (value) => {
      let error;
      if (value === '') {
        error = "Veuillez renseigner le chariot";
      }
      return error;
    },
    "showModalChangeTrolleyLocation": (trolley_id) => {
      dispatch({'type': MAIN_MODAL_HIDE});
      dispatch({"type": TROLLEY_LOGS_LOAD, "trolley_id": trolley_id});
      dispatch(showModalChangeTrolleyLocation(trolley_id));
    },
    "cancelSubmit": () => {
      dispatch({'type': MAIN_MODAL_HIDE});
    },
    "submit": (values) => {
      dispatch({'type': MAIN_MODAL_HIDE});

      // Compute number of bags from dry and iso bags
      dispatch(validateChangeTransitArea(values));
    }
  };
};
