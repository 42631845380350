import {connect} from "react-redux";
import {TransitAreaDisplay} from "../../../../../common-comps/TransitAreaDisplay";
import { showTransitAreaModal } from "../../../../../actions/modal";

export const TransitArea = connect(
  (state) => ({
    pseudoZones: state.dispatch.pseudoZones,
    transit_areas: state.dispatch.transit_areas,
  }),
  (dispatch) => ({
    showTransitAreaModal: (order) => dispatch(showTransitAreaModal(order)),
  })
)(TransitAreaDisplay);
