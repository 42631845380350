import {findByid} from "../../utils/index";
import {filterAndSortDockTasks} from "../../utils/task";


let task = {};

task.updateSucceeded = function (action, partialState) {
  let index = {};
  for (const t of action.tasks) {
    index[t.id] = t;
  }

  return partialState.map((e) => index.hasOwnProperty(e.id) ? { ...index[e.id], ...{updating: false, dock_updating:false}} : e);
};


task.dropOnDefault = function(action, state) {
  //const originDock = Object.assign({}, findByid(state.docks, action.droppedTaskDockId));
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));

  const payloadDropOnDriver = {
    "dock": null,
    "updating": task.id,
  };

  return {
    ...state,
    deliveries: state.deliveries.map((e) => e.id === task.id ? {...e, ...payloadDropOnDriver} : e),
  };
};


task.dockDropOnDefault  = function(action, state) {
  //const originDock = Object.assign({}, findByid(state.docks, action.droppedTaskDockId));
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  const dock = Object.assign({}, findByid(state.docks, action.dockId));

  // ajouter au dock
  const payloadDropOnDriver = {
    "dock_rank": 1,
    "dock": dock,
    "updating": task.id,
    "dock_updating": dock.id
  };

  return {
    ...state,
    deliveries: state.deliveries.map((e) => {
      const t = (e.id === task.id) ? {...e, ...payloadDropOnDriver} : e;
      if (e.id === task.id) {
        console.log(t);
      }
      return t;
    })
  };
};


task.dockDropOnDeliveryTask = function(action, state) {
  if (action.droppedTaskId === action.targetTaskId) {
    console.warn('self drop : skip');
    return state;
  }
  //const srcDock = Object.assign({}, findByid(state.docks, action.droppedTaskDockId));
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  const target = Object.assign({}, findByid(state.deliveries, action.targetTaskId));
  const targetDock =  target.dock;

  // on recalcul le rank pour tout le dock de destination
  //console.log("state.deliveries", state.deliveries, targetDock.id);
  let tasksInDock = filterAndSortDockTasks(state.deliveries, targetDock.id)
    .filter(t => t.id !== task.id);
  const targetIndex = tasksInDock.findIndex(t => t.id === target.id);
  tasksInDock.splice((targetIndex + 1), 0, task);
  //console.log("targetIndex/afterUpdateTask", targetIndex, tasksInDock);
  let newRankMap = {};
  let currentRank = 1;
  for (const ta of tasksInDock) {
    newRankMap[ta.id] = currentRank;
    currentRank++;
  }

  const payload = {
    "dock" : target.dock,
    "dock_rank": newRankMap[task.id],
    "updating": task.id,
    "dock_updating": targetDock.id
  };
  //console.log('rankComputed', newRankMap, 'beforeDockContent', tasksInDock, payload);

  return {
    ...state,
    "deliveries": state.deliveries.map((e) => {
      if (e.id === task.id) {
        console.log("match task.id", {...e, ...payload});
        return {...e, ...payload};
      }
      if (e.id in newRankMap) {
        return {...e, "dock_rank": newRankMap[e.id], "updating": task.id, "dock_updating": targetDock.id};
      }
      return e;
    }),
    "docks": state.docks.map((d) => {
      if (d.id === targetDock.id) {
        return {...d, "updating": task.id};
      }
      // if (d.id === srcDock.id) {
      //   return {...d, "updating": task.id};
      // }
      return d;
    })
  };

};


task.dropOnDeliveryTask = function(action, state) {
  if (action.droppedTaskId === action.targetTaskId) {
    console.warn('self drop : skip');
    return state;
  }

  // see also saga.js
  const task = Object.assign({}, findByid(state.deliveries, action.droppedTaskId));
  const target = Object.assign({}, findByid(state.deliveries, action.targetTaskId));
  const updating_dock =  task.dock;

  let newRank = target.ordering + 1;
  const payloadDropOnTask = {
    "dock" : null,
    "ordering": newRank,
    "updating": task.id,
    "dock_updating": updating_dock ? updating_dock.id : false
  };

  // console.log('target', target, 'droppedTask', task, 'dmid', dmid, 'diff', payloadDropOnTask);

  return Object.assign({}, state, {
    deliveries: state.deliveries.map((e) => {
      if  (e.id === task.id) {
        return {...e, ...payloadDropOnTask};
      }
      //const haveDock = e.dock && e.dock.id;
      // PROBABLEMENT INUTILE MAINTENANT QUE LE TRI EST SUR estimated_travel_departure_at
      // if  (e.ordering >= newRank && !haveDock && e.status === 'todo') {
      //   return {...e, ordering: e.ordering + 1, "updating": task.id};
      // }
      return e;
    })
  });

};




task.deliveryUpdate = function (action, state) {
  return Object.assign({}, state, {
    deliveries: state.deliveries.map((delivery) => {
      return action.taskIds.includes(delivery.id)
        ? Object.assign({}, delivery, {updating: action.updating})
        : delivery;
    })
  });
};



task.dropOnPackingDefault = function(action, state) {
  const task = Object.assign({}, findByid(state.packings, action.droppedTaskId));
  const existingMax = state.packings.filter(e => e.emergency_rank).reduce((max, val) => val > max ? val : max, 0);
  const newRank = task.emergency_rank ? null : (existingMax + 1);
  return {...state, ...{
    packings: state.packings.map((e) => {
      if  (e.id === task.id) {
        return {...e, ...{ "emergency_rank": newRank, "updating": task.id}};
      }
      return e;
    })
  }};
};


task.dropOnPackingTask = function(action, state) {
  if (action.droppedTaskId === action.targetTaskId) {
    console.warn('self drop : skip');
    return state;
  }

  // see also saga.js
  const task = Object.assign({}, findByid(state.packings, action.droppedTaskId));
  const target = Object.assign({}, findByid(state.packings, action.targetTaskId));
  const newEmergencyRank = target.emergency_rank ? (target.emergency_rank + 1) : null;

  return {...state, ...{
    packings: state.packings.map((e) => {
      if  (e.id === task.id) {
        return {...e, ...{"emergency_rank": newEmergencyRank, "updating": task.id}};
      }

      if  (e.emergency_rank && e.emergency_rank >= newEmergencyRank && e.status === 'todo') {
        return {...e, ...{emergency_rank: e.emergency_rank + 1, "updating": task.id}};
      }

      return e;
    })
  }};
};

task.lateProcessing = function (action, state) {
  const tid = action.task.id;
  return {...state, ...{
    deliveries: state.deliveries.map((e) => {
      return (e.id === tid ? {...e, ...{updating: tid}} : e);
    })
  }};
};


export default task;
