import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Raven from "raven-js";
import Notifications from "react-notification-system-redux";
import { api, daUtil } from "../../../api";

import { DISPATCH_MAIN_MODAL_HIDE } from '../actions/modal';
import {
  DISPATCH_CHANGE_DRIVER_VEHICLE,
  DISPATCH_CHANGE_DRIVER_CENTER,
  DISPATCH_START_DRIVER_PRESENCE,
  DISPATCH_STOP_DRIVER_PRESENCE,
  DISPATCH_DRIVER_ACTIVITY_START,
  DISPATCH_DRIVER_ACTIVITY_END,
  DISPATCH_CREATE_STATEMENT,
  DISPATCH_DRIVER_UPDATE,
  DISPATCH_DRIVER_RETURN_BAGS,
  driverUpdateSucceeded,
} from "../actions/driver";

import { errorThrown } from "../actions/index";
import { dockUpdated } from "../actions/dock";
import { _utilUpdateTasks } from "./deliveries";
import {stateToCenterId} from "../../../utils/center";


function* stopDriverPresence(action) {
  const state = yield select();
  const driverUpdated = state.dispatch.drivers.filter(d => d.updating === action.driver.id);

  if (driverUpdated.length === 0) {
    return;
  }

  try {
    let resDriver = yield call(api.stopPresence, action.driver.id, action.unfinished_new_status);
    yield* _utilUpdateTasks(t => t.updating === action.driver.id && t.linked_order);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* startDriverPresence(action) {
  const state = yield select();

  try {
    // TODO: fix start response format (not driver state but driver actually)

    const centerId = stateToCenterId(state);

    let resDriver = yield call(api.startPresence, action.driver.id, action.vehicle, centerId);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* changeDriverVehicle(action) {
  try {
    // TODO: fix response format (not driver state but driver actually)

    let resDriver = yield call(api.changeVehicle, action.driver.id, action.vehicle);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* createStatement(action) {
  const data = {
    driver: action.driver.id,
    statement_type: action.statement_type,
    amount: action.amount
  };
  yield call(daUtil.post, `/driver-statements/`, data);
  yield put(Notifications.success({title: "Retour sac" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}

function* driverReturnBags(action) {
  const { driver: { id: driverId }, quantityByDepositLabel } = action;
  try {
    yield call(daUtil.post, `/depositdriver/bulk_return_to/`, { driver_id: driverId, returned_by_label: quantityByDepositLabel });
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
  yield put(Notifications.success({title: "Retour sac" , "message": "Retour enregistré",  autoDismiss:3, dismissible:"click"}));
  yield put({ type: DISPATCH_MAIN_MODAL_HIDE });
}

function* driverActivityStart(action) {
  const d = {
    note: action.note,
  };
  try {
    const res = yield call(daUtil.post, `/drivers/${action.driver.id}/start_activity/`, d);
    yield put(driverUpdateSucceeded(res));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* driverActivityEnd(action) {
  try {
    const res = yield call(daUtil.post, `/drivers/${action.driver.id}/stop_activity/`, {});
    yield put(driverUpdateSucceeded(res));

    if (res.extra && res.extra.dock_ids) {
      let resDock;
      for (let dock_id of res.extra.dock_ids) {
        resDock = yield call(daUtil.get, `/docks/${dock_id}/`);
        yield put(dockUpdated(resDock));
      }
    }
    if (res.extra && res.extra.shipping_task_ids) {
      yield* _utilUpdateTasks(e => res.extra.shipping_task_ids.includes(e.id) );
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}


function* driverApiUpdate(action) {
  const state = yield select();

  try {
    const centerId = stateToCenterId(state);
    const patch = {...action.patch, center_id: centerId};
    const res = yield call(daUtil.patch, `/drivers/${action.driver.id}/`, patch);
    yield put(driverUpdateSucceeded(res));
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}


function* changeDriverCenter(action) {
  try {
    // TODO: fix response format (not driver state but driver actually)
    let resDriver = yield call(api.changeCenter, action.driver.id, action.centerId);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}


export const driverSagas = [
  takeEvery(DISPATCH_STOP_DRIVER_PRESENCE, stopDriverPresence),
  takeEvery(DISPATCH_START_DRIVER_PRESENCE, startDriverPresence),
  takeEvery(DISPATCH_CHANGE_DRIVER_VEHICLE, changeDriverVehicle),
  takeEvery(DISPATCH_DRIVER_ACTIVITY_START, driverActivityStart),
  takeEvery(DISPATCH_DRIVER_ACTIVITY_END, driverActivityEnd),
  takeEvery(DISPATCH_DRIVER_UPDATE, driverApiUpdate),
  takeEvery(DISPATCH_CREATE_STATEMENT, createStatement),
  takeEvery(DISPATCH_DRIVER_RETURN_BAGS, driverReturnBags),
  takeEvery(DISPATCH_CHANGE_DRIVER_CENTER, changeDriverCenter),
];
