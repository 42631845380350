import React, {useState} from 'react';
import moment from  'moment-timezone';
import {Col, FormGroup, Modal, Row} from "react-bootstrap";
import {ErrorMessage, Formik, Field} from 'formik';
import PropTypes from "prop-types";


const DEFAULT_DRIVER_HOURLY_RATE = 12.75;  // TODO Issam doit me donner la bonne valeur plus tard dans la journée


export const InternalModalExtraCostAdd =
  ({
    modal,
    hide,
    submitForm,
    driverList,
    extraCostTypes,
    driverId,
    date,
    dock,
    task,
    driverLogGroupId,
    driverLogId,
    orderId,
    extraCostType,
    minutes,
    extraCostDemandId,
    fromDemand,
  }) => {

  const [inputMinutes, setInputMinutes] = useState(true);
  if (!inputMinutes && fromDemand) {
    setInputMinutes(true);
  }

  let drivers = driverList.sort((a, b) => a.name.localeCompare(b.name));
  const listOptions = drivers.map((option, index) => {
    return (<option key={index} value={option.id} >{option.name}</option>);
  });
  const listOptionsType = extraCostTypes.map((datum) => {
    return (<option key={datum.rank - 1} value={datum.id} >{datum.label}</option>);
  });
  moment.locale('fr');

  const computeAmountFromMinutesAndDriver = (minutes, driver, init) => {
    let hourly_rate = DEFAULT_DRIVER_HOURLY_RATE;
    let debug = false;
    if (debug) {
      console.log("computeAmountFromMinutesAndDriver() init:", init);
      console.log("Taux horaire par défaut:", hourly_rate);
    }
    if (driver) {
      if (debug) {
        console.log("On a un chauffeur.");
      }
      let driver_object = drivers.find(dr => dr.id === parseInt(driver, 10));
      if (driver_object) {
        hourly_rate = driver_object.hourly_rate;
        if (debug) {
          console.log("Chauffeur trouvé avec hourly_rate:", hourly_rate);
        }
      }
    }
    let result = (hourly_rate * parseInt(minutes, 10) / 60).toFixed(2);
    if (debug) {
      console.log("Montant calculé:", result);
    }
    return result;
  }

  return <Modal
    show={modal && modal.modalType === 'extracost_add'}
    animation={false}
    backdrop={true}
    onHide={() => hide()}
    keyboard={true}
    // size={"sm"}
  >
    <Modal.Body>
      <h4>
        Ajouter un surcoût
        {inputMinutes ? " en minutes" : " en montant"}
        {fromDemand ? null :
          (inputMinutes ?
            <button className={"btn btn-xs btn-info float-right"}
                    onClick={() => {setInputMinutes(false)}}
            >Changer (montant)</button>
            : <button className={"btn btn-xs btn-info float-right"}
                      onClick={() => {setInputMinutes(true)}}
            >Changer (minutes)</button>
          )
        }
      </h4>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          driverId: driverId,
          day: date,
          amount: (minutes && driverId) ? computeAmountFromMinutesAndDriver(minutes, driverId, true) : 0,
          minutes: minutes ? minutes : "",
          extracost_type: extraCostType ? extraCostType : "",
          comment: "",
        }}
        onSubmit={(values, actions) => {
          let extracost_data = {
            driver: values.driverId,
            day: values.day,
            amount: values.amount,
            minutes: (values.minutes === "" || values.minutes === 0 || !inputMinutes ? null : values.minutes),
            extracost_type: values.extracost_type,
            comment: values.comment,
            order: orderId ? orderId : (task ? task.linked_order.id : null),
            dock_id: dock ? dock.id : null,
            driver_log_group: driverLogGroupId ? driverLogGroupId : null,
            driver_log: driverLogId ? driverLogId : null,
            driver_extra_cost_demand_id: extraCostDemandId ? extraCostDemandId : null,
          };
          submitForm(extracost_data);
        }}
        validate={(values) => {
          let errors = {}

          const driver = values.driverId;
          const minutes = values.minutes;
          const day = values.day;
          const type = values.extracost_type;
          if (!driver) {
            errors.driverId = "Veuillez renseigner un livreur";
          }
          if (parseFloat(values.amount).toFixed(2) === "0.00") {
            errors.amount = "Veuillez renseigner un montant (positif ou négatif)";
          }
          if (parseFloat(values.amount) > 20) {
            errors.amount = "Le montant ne peut pas dépasser 20 euros.";
          }
          if (parseFloat(minutes) > 99) {
            errors.minutes = "Le nombre de minutes ne peut pas être supérieur à 99.";
          }
          if (!day) {
            errors.day = "Veuillez renseigner le jour";
          }
          if (!type ) {
            errors.extracost_type = "Veuillez indiquer une raison";
          }
          return errors;
        }}
        render={(props: FormikProps<Values>) => (
          <form onSubmit={props.handleSubmit} className="form-horizontal">
            <FormGroup as={Row}>
              <Col sm={12}>
                <Field as="select" name="driverId" component="select" required={true} disabled={driverList.length === 0 ? true : false} className="form-control">
                  <option value="">-- Veuillez sélectionner le livreur --</option>
                  {listOptions}
                </Field>
                <span style={{color: 'red'}}><ErrorMessage name="driverId" /></span>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col sm={4}>
                Le <Field type='date' name="day" required={true} className="form-control" />
                <span style={{color: 'red'}}><ErrorMessage name="day" /></span>
              </Col>
              {inputMinutes ?
              <Col sm={4}>
                Minutes <Field type="number" name="minutes" required={false} className="form-control"
                               onBlur={
                                 (e) => {
                                   let some_minutes = e.target.value;
                                   if (some_minutes !== "" && parseInt(some_minutes, 10) !== 0) {
                                     props.setFieldValue(
                                         "amount",
                                         computeAmountFromMinutesAndDriver(
                                             some_minutes,
                                             props.values.driverId,
                                             false,
                                         ),
                                     );
                                   }
                                 }
                               } />
                <span style={{color: 'red'}}><ErrorMessage name="minutes" /></span>
              </Col>
              : null}
              <Col sm={4}>
                Montant <Field type="number" name="amount" required={true} className="form-control" disabled={inputMinutes}/>
                <span style={{color: 'red'}}><ErrorMessage name="amount" /></span>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col sm={12}>
                <Field as="select" name="extracost_type" component="select" required={true} className="form-control">
                  <option value="">-- Veuillez sélectionner la raison --</option>
                  {listOptionsType}
                </Field>
                <span style={{color: 'red'}}><ErrorMessage name="extracost_type" /></span>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col sm={12}>
                Commentaire
                <Field as="textarea" name="comment" className="form-control"></Field>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col sm={12}>
                <button className="btn btn-primary btn-small float-right" type="submit" disabled={props.isSubmitting}>
                  CRÉER
                </button>
              </Col>
            </FormGroup>
          </form>
        )}
      />
    </Modal.Body>
  </Modal>;
}

InternalModalExtraCostAdd.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  driverList: PropTypes.array.isRequired,
  extraCostTypes: PropTypes.array.isRequired,
  driverId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  date: PropTypes.string,
  dock: PropTypes.object,
  task: PropTypes.object,
  driverLogGroupId: PropTypes.number,
  driverLogId: PropTypes.number,
  orderId: PropTypes.number,
  extraCostType: PropTypes.number,
  minutes: PropTypes.number,
  extraCostDemandId: PropTypes.number,
  fromDemand: PropTypes.bool,
};
