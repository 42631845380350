import {connect} from "react-redux";
import DockList from "./DockList";
import {
  dockListEventHandler,
  dockListProps,
  getTitleDockListReadyToLoad,
} from "../common-comps/dock";
import {getComputedDockListReadyToLoad} from "../../selectors/dock";
import {withRouter} from "react-router-dom";

export const DockListReadyToLoad = withRouter(connect(
  state => dockListProps(
    state,
    getComputedDockListReadyToLoad(state),
    getTitleDockListReadyToLoad(state)
  ),
  dispatch => dockListEventHandler(dispatch, true, true)
)(DockList));

