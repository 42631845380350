import {connect} from "react-redux";
import NumberOfBagsModal from "../../../components/modal/ChangeNumberOfBagsModalContent";
import {MAIN_MODAL_HIDE} from "../../../actions/modal";
import { ARRIVALS_CHANGE_NUMBER_BAGS } from "../actions";


const ArrivalsChangeNumberOfBagsModal = connect(
  (state) => ({
    "modal" : state.m.modal,
    "pseudoZones": state.arrivals.pseudozones,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": MAIN_MODAL_HIDE});
      },
      "validate": (values) => {
        let extraPlacePzBags = [];
        for (let k in values) {
          if (k.startsWith('pz_')) {
            let x = k.split('_');
            extraPlacePzBags.push({
              pseudo_zone_id: parseInt(x[5], 10),
              number_of_bags_isotherm: values[k]
            });
          }
        }

        dispatch({'type': MAIN_MODAL_HIDE});

        // Compute number of bags from dry and iso bags
        let number_of_bags = values.number_of_bags_iso + values.number_of_bags_dry;
        dispatch({
          type: ARRIVALS_CHANGE_NUMBER_BAGS,
          orderId: values.orderId,
          number_of_bags_iso: values.number_of_bags_iso,
          taskId: values.taskId,
          dockId: values.dockId,
          number_of_bags,
          extraPlacePzBags
        });
      }
    };
  }
)(NumberOfBagsModal);

export default ArrivalsChangeNumberOfBagsModal;
