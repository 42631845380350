import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from "react-bootstrap";
import { DepositDriverCancelReceivedButton } from '../../../../../components/Deposit/DepositDriverCancelReceivedButton';

export const DepositUserHistoryDetail = ({deposit_user}) => {

  // On s'assure que ce soit une remise
  if (deposit_user.details && deposit_user.details.length === 0) {
    return null;
  }
  const popoverClick = (deposit_user) => {
    return (
      <Popover className="deposit-popover" id="deposit-detail-form">
        <Popover.Title>Détail de la remise : {deposit_user.product_infos.deposit_label}</Popover.Title>
        <Popover.Content>
          <table className="table no-border table-condensed">
            <thead>
              <tr>
                <th>Livreur</th>
                <th>Le</th>
                <th>Qté</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {deposit_user.details.map(detail => {
                if (detail.deposit_driver.is_returned || detail.deposit_driver.is_returned_canceled) {
                  return null;
                }
                let deposit_driver = detail.deposit_driver;
                deposit_driver['product_infos'] = deposit_user.product_infos;

                return <tr key={detail.deposit_driver.id}>
                  <td>{deposit_driver.driver_infos.name}</td>
                  <td>{deposit_driver.formated_created_at}</td>
                  <td>{detail.quantity}</td>
                  <td>
                    {detail.quantity < 0 ? 
                      <span className="badge deposit-cancel">annul. remise</span>
                      : <span className="badge deposit-user">remise</span>}
                    {deposit_driver.is_received && deposit_driver.status.details[deposit_user.id].cancel_status === 'partial' ?
                      <span className="badge deposit-fix-partial">annulation partiel.</span>
                      : deposit_driver.is_received && deposit_driver.status.details[deposit_user.id].cancel_status === 'total' ?
                        <span className="badge deposit-fix-total">annulé</span>
                        : null
                    }
                  </td>
                  <td>
                    <DepositDriverCancelReceivedButton deposit={ deposit_driver } deposit_user={deposit_user} user_id={deposit_user.user} />
                  </td>
                </tr>;
              })}
            </tbody>
          </table>
        </Popover.Content>
      </Popover>
    );
  };

  return <div className="DepositUserHistoryDetail">
    <OverlayTrigger trigger="click" placement="left" overlay={popoverClick(deposit_user)} rootClose={true} transition={false}>
      <i className="fa-solid fa-circle-info"></i>
    </OverlayTrigger>
  </div>;
};
DepositUserHistoryDetail.propTypes = {
  deposit_user: PropTypes.object.isRequired,
};

