import { connect } from "react-redux";
import { MAIN_MODAL_HIDE } from "../../../../actions/modal";
import UberCallModal from "../../../../components/modal/UberCallModal";
import { DISPATCH_ORDER_CALL_UBER } from "../../actions";
import { ORDER_UBER_SENDING } from "../../../../actions";

export default connect(
  state => ({
    modal: state.m.modal,
  }),
  dispatch => ({
    onHide: () => dispatch({ type: MAIN_MODAL_HIDE }),
    callUber: (orderPatch) => {
      dispatch({ type: ORDER_UBER_SENDING, sending: true });
      return dispatch({ type: DISPATCH_ORDER_CALL_UBER, orderPatch });
    },
  })
)(UberCallModal);

