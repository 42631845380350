import {connect} from "react-redux";
import DeliveriesList from "./DeliveriesList";
import {getDeliveriesLate} from "../../selectors/deliveries";
import {withRouter} from "react-router-dom";



const mapStateToProps = state => {
  let count = getDeliveriesLate(state).length;

  let toReviewCount = 0;
  if (state.dispatch.late_orders instanceof Object) {
    toReviewCount = Object.values(state.dispatch.late_orders).filter(lo =>
      lo.needs_review === true && !lo.reviewed_at && !lo.sent_at
    ).length;
  }

  return {
    nothingIfEmpty: false,
    tasks: getDeliveriesLate(state).slice(0, 35),
    title: `Commandes en retard: ${count} dont ${toReviewCount} à vérifier`,
    className: "late-list",
    lateOrders: state.dispatch.late_orders,
    show: {
      displayMode: "one_line",
      name: true,
      driver: true,
      sms: true,
      status: false,
      late: true,
      menu: false,
      withSelect: false,
      addressFormatting: null,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // "onDropTask": (targetTaskId, droppedTaskId) => dispatch(dropOnPackingTask(targetTaskId, droppedTaskId)),
    // "onDropDefault": (driverId, droppedTaskId) => dispatch(dropOnPackingDefault(droppedTaskId)),
  };
};

export let DeliveriesLate = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveriesList));

export default DeliveriesLate;
