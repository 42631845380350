import {connect} from "react-redux";

import {connectChangeTrolleyLocationModalAction} from "../../utils/trolleys";
import ChangeTrolleyLocationModalContent from "./ChangeTrolleyLocationModalContent";


export const ChangeTrolleyLocationModal = connect(
  (state) => ({
    "modal" : state.m.modal,
    "trolley_logs": state.m.trolley_logs,
    "trolleys": state.m.metas.trolleys,
    "trolley_locations": state.m.metas.trolley_locations,
  }),
  (dispatch) => connectChangeTrolleyLocationModalAction(dispatch)
)(ChangeTrolleyLocationModalContent);
