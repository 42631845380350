import React, { Component } from 'react';
import {Table, Button} from "react-bootstrap";
import PropTypes from "prop-types";

import {DeliveryRow} from "./DeliveryRow";
import "../../../../css/DeliveryList.css";
import {RedeliverModal} from "../modal/RedeliverModal";
import {SUB_DISPATCH_CODES, SUB_DISPATCH_CONFIGURATIONS} from "../../sub_dispatchs";

export class DeliveriesList extends Component {
  constructor(props) {
    super(props);
    this.state = { hideAutoProcess: true };
  }

  render() {
    const { title, className, show, lateOrders, location } = this.props;
    let tasks = this.props.tasks;
    const nbTask = tasks.length;
    const h4 = <h4>{title} : {nbTask ? nbTask: "AUCUNE"}</h4>;

    const query = new URLSearchParams(location.search);
    const sub = query.get("sub");
    if(
      sub !== null
      && sub !== undefined
      && SUB_DISPATCH_CODES.indexOf(sub) !== -1
      && SUB_DISPATCH_CONFIGURATIONS[sub].withLateDeliveries === false
    ){
      return null;
    }

    if (nbTask === 0) {
      return (show.nothingIfEmpty ? null : <div>{h4}</div>);
    }

    // Filtre pour Afficher/Masquer les retards traités
    tasks = show.late ? tasks.filter(t => {
      const relatedOrder = lateOrders[t.linked_order.id];
      return this.state.hideAutoProcess && relatedOrder
        ? !relatedOrder.reviewed_at
        : true;
    }): tasks;

    return (
      <div className={className + " DeliveryList"}>
        <h4>
          {title}
          {show.late ? (
            <Button
              size="xs"
              variant="default"
              className="mx-3"
              onClick={() =>
                this.setState({ hideAutoProcess: !this.state.hideAutoProcess })
              }
            >
              {this.state.hideAutoProcess ? "Afficher" : "Masquer"} les retards traités
            </Button>
          ) : null}
        </h4>
        <Table bordered size="sm" hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Sacs</th>
              <th>Zone</th>
              <th>Client</th>
              <th>Adresse</th>
              {show.driver ? (<th>Livreur</th>) : null}
              <th></th>
              {show.status ? (<th>État</th>) : null}
              {show.sms ? (<th></th>) : null}
              {show.late ? (<th></th>) : null}
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, key) => (
              <React.Fragment key={key}>
                <DeliveryRow
                  key={task.id}
                  task={task}
                  show={show}
                />
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <RedeliverModal />
      </div>
    );
  }
}


DeliveriesList.propTypes = {
  tasks: PropTypes.array.isRequired,
  title:  PropTypes.string.isRequired,
  className: PropTypes.string,
  show: PropTypes.object,
  lateOrders: PropTypes.object,
};


export default DeliveriesList;
