let _exp = {};

_exp.toggleDispatchFilter = function (action, state) {
  let dispatchFilter = null;
  if (state.dispatchFilter.type === action.filterType && state.dispatchFilter.value === action.value) {
    dispatchFilter = {};
  } else {
    dispatchFilter = {type : action.filterType, value: action.value};
  }

  return {...state, dispatchFilter: dispatchFilter};
};


export default _exp;

