import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {Button, Col, Form, FormGroup, OverlayTrigger, Popover, Row} from "react-bootstrap";
import { connect } from 'react-redux';

import {driverDepositReceived} from  "../../../actions";

class Internal extends React.Component {

  render() {
    let drivers = this.props.drivers;
    let deposit_current = this.props.deposit_current;
    let current_user = this.props.current_user;
    let validate = this.props.validate;
    let handleSubmit = this.props.handleSubmit;

    const popoverClick = (deposit_current) => {
      return (
        <Popover className="deposit-popover" id="deposit-fix-form">
          <Popover.Title>{`Création de la remise pour ${deposit_current.product.deposit_label}`}</Popover.Title>
          <Popover.Content>
            <div className="alert alert-info">
              <span>{`Remise possible jusqu'à ${deposit_current.quantity}`}</span>
            </div>
            <br />
            {deposit_current.quantity > 0 &&
              <Formik
                initialValues={{
                  'quantity' : 0,
                  'driver_id': '',
                  'user_id': current_user.id,
                  'product_id': deposit_current.product_id,
                }}
                onSubmit={(values, actions) => {
                  if (validate(values)) {
                    handleSubmit(values);
                    actions.resetForm();
                    // astuce pour fermer l'overlay
                    document.body.click();
                  }
                  actions.setSubmitting(false);
                }}
              >
                {props => (
                  <Form onSubmit={props.handleSubmit} className="form-horizontal">
                    <Field name="product_id" type="hidden" required="required" 
                      className="form-control" component="input" />
                    <Field name="user_id" type="hidden" required="required" 
                      className="form-control" component="input" />
                    <FormGroup as={Row} key="driver" controlId="formControlsSelect">
                      <Col as={Form.Label} sm={3}>Livreur</Col>
                      <Col sm={5}>
                        <Field
                          name="driver_id"
                          className="form-control"
                          required="required"
                          component="select"
                          disabled={drivers.loading}
                        >
                          <option value="">-- Choisir --</option>
                          {drivers.loading === false && drivers.datas.length > 0 && drivers.datas.map((d, index) => (
                            <option key={index} value={d.id}>{d.name}</option>
                          ))}
                        </Field>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="formControlsSelect">
                      <Col as={Form.Label} sm={3}>
                        Qté
                      </Col>
                      <Col sm={3}>
                        <Field name="quantity" type="number" required="required" 
                          className="form-control" component="input" max={deposit_current.quantity} min="0" />
                      </Col>
                      <Col sm={2} className="info-quantity"><div className="control-label info-quantity"> / {deposit_current.quantity}</div></Col>
                      <Col sm={4}>
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>Créer!</button>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            }
          </Popover.Content>
        </Popover>
      );
    };

    return <div className="DepositUserCreateReceived">
      <OverlayTrigger trigger="click" placement="left" overlay={popoverClick(deposit_current)} rootClose={true} transition={false}>
        <Button  variant="danger" size="xs">Créer une remise</Button>
      </OverlayTrigger>
    </div>;
  }
}

Internal.propTypes = {
  deposit_current: PropTypes.object.isRequired,
  current_user: PropTypes.object.isRequired,
  drivers: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const DepositUserCreateReceived = connect(
  (state) => {
    return {
      "drivers": state.support.drivers,
      "current_user": state.support.user,
      "validate": (values) => {
        if (values.driver_id && values.quantity && values.quantity > 0) {
          return true;
        }
        return false;
      }
    };
  },
  (dispatch) => {
    return {
      "handleSubmit": (values) => {
        dispatch(driverDepositReceived(values));
      }
    };
  }
)(Internal);
