import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";

import moment from "moment/moment";

import "../css/OrderDeliveryDetail.css";
import {rd} from "../../../utils";


const InternalOrderFinancial = ({order}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');

  function getPaymentDetailName (type) {
    switch (type) {
      case "papertr":
        return "Ticket Restaurant Papier";
      case "paygreen":
        return "Ticket Restaurant Paygreen";
      case "edenred":
        return "Ticket Restaurant Edenred";
      case "sub":
        return "Subvention employeur";
      case "unlimitedfunds":
        return "Fond illimité";
      default:
        return "?";
      }
  }

  return <Table size="sm" hover className="OrderFinancial">
    <tbody>
      <tr><th colSpan={2}>Résumé financier</th></tr>
      {order.receipt_url ? <tr><td>Payment Stripe</td><td><a href={order.receipt_url} rel="noopener">Afficher le ticket de reçu</a></td></tr> : null}
      {order.codepromo ? <tr><td>Code Promo</td><td>{order.codepromo}</td></tr> : null}
      {order.credits_used ? <tr><td>Crédits utilisés</td><td>{rd(order.credits_used)} €</td></tr> : null}
      {order.payment_details.map((paymentDetail, index) => (
        paymentDetail.type !== "stripe" && (
          <tr key={index}>
            <td>{getPaymentDetailName(paymentDetail.type)}</td>
            <td>
              {paymentDetail.amount.toFixed(2)} €
              {
                paymentDetail.type === 'papertr'
                ? paymentDetail.metadata.paper_tr_detail.map(
                  obj => <span>, {obj.quantity}x{obj.unit_amount.toFixed(2)} €</span>
                )
                : null
              }
              {
                paymentDetail.type === 'papertr'
                ? (
                  <span>
                    <span> </span>= {paymentDetail.metadata.total_tr_amount.toFixed(2)} €,
                    solde crédité à livraison : {paymentDetail.metadata.to_credit_amount.toFixed(2)} €
                  </span>
                )
                : null
              }
            </td>
          </tr>
        )
      ))}
      <tr>
        <td>Total TTC</td>
        <td>{rd(order.amount)} €</td>
      </tr>
      {order.amount_recredit ? <tr><td>Déjà recrédité</td><td>{rd(order.amount_recredit)} €</td></tr> : null}
      {order.amount_refund ? <tr><td>Déjà remboursé</td><td>{rd(order.amount_refund)} €</td></tr> : null}
    </tbody>
  </Table>
  ;
};

InternalOrderFinancial.propTypes = {
  "order": PropTypes.object
};

export const OrderFinancial = connect(
  (state) => ({pseudoZones: state.support.meta ? state.support.meta.pseudoZones : [] }),
)(InternalOrderFinancial);
