import React from 'react';
//import {} from "react-bootstrap";
import PropTypes from "prop-types";
import {mapUrlRound} from "../../../../../utils/address";
import {connect} from "react-redux";


const InternalLinkMapsRound = ({dock, centers}) => {
  if (dock.tasks.length === 0) {
    return null;
  }

  let addresses = [];
  for (const t of dock.tasks) {
    addresses.push(t.address);
  }

  return (
    <a href={mapUrlRound(addresses, dock.center, centers)} target="_blank"  className="btn btn-xs btn-default LinkMapsRound" rel="noopener noreferrer" style={{marginLeft:"15px"}}><i className="fa-solid fa-route"/> maps</a>
  );
};

InternalLinkMapsRound.propTypes = {
  dock: PropTypes.object.isRequired,
  centers: PropTypes.array.isRequired,
};

export const LinkMapsRound = connect(
  (state) => {
    return {centers: state.dispatch.metas.centers || []};
  }
)(InternalLinkMapsRound);

LinkMapsRound.propTypes = {
  dock: PropTypes.object.isRequired,
};
