import {connect} from "react-redux";
import PackingList from "../components/PackingList";
import {getSortedTasks} from "../selectors/tasks_blocked_list";

const mapStateToProps = state => {
  return {
    tasks: getSortedTasks(state),
    title: "Commandes Bloquées",
    className: 'blocked-list',
    show : {nbBags: true, transitArea: true, nbProducts: true, fraud: true, nothingIfEmpty: true},
  };
};

const mapDispatchToProps = null;

let TasksBlockedListLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingList);

export default TasksBlockedListLinked;
