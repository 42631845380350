/* eslint-disable no-underscore-dangle,indent,no-case-declarations */

import { reducer as notifications} from 'react-notification-system-redux';

import {combineReducers} from "redux";

import {logReducer} from "../apps/logs/reducer";
import {mainReducer} from "./main/mainReducer";
import {userReducer} from "./user";
import {searchReducer} from "./search";
import {uiReducer} from "./ui";
import {switchToggleReducer} from "./switch_toggle";
import {noLoadStateReducer} from "./noLoadState";
import {supportReducer} from "../apps/support/reducer";
import {assignReducer} from "../apps/assign/reducer";
import {deliveriesTodoReducer} from "./deliveries_todo";
import { arrivalsReducer } from '../apps/arrivals/reducer';
import { dispatchReducer } from '../apps/dispatch/reducers/main';
import {commonModalsReducer} from "./commonModalsReducer";

/**
 * reset version
 * @param state
 * @returns {{version: number}}
 */

const flowApp = combineReducers({
  m: mainReducer,
  logs: logReducer,
  support: supportReducer,
  user: userReducer,
  assign: assignReducer,
  search: searchReducer,
  toggle: switchToggleReducer,
  ui: uiReducer,
  deliveriesTodo: deliveriesTodoReducer,
  arrivals: arrivalsReducer,
  dispatch: dispatchReducer,
  noLoadState: noLoadStateReducer,
  commonModals: commonModalsReducer,
  notifications
});


export default flowApp;
/* eslint-enable */
