import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table, Button} from "react-bootstrap";
import {act_loadCreditSync} from "../../actions";

//import {} from "../actions";

import "../../css/CreditList.css";
import {CreditRow} from "./CreditList/CreditRow";

class InternalCreditList extends React.Component {
  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      this.props.loadCreditSync(this.props.user.id);
    }
  }

  componentDidUpdate() {
    if (typeof(this.props.credit.shouldRefresh) !== 'undefined' && this.props.credit.shouldRefresh === true) {
      this.props.loadCreditSync(this.props.user.id);
    }
  }
  render () {
    let {credit, user, loadCreditSync} = this.props;
    let creditList = credit.datas;

    const refreshList = () => {
      loadCreditSync(user.id);
    };

    if (!creditList) {
      return null;
    }

    if (credit.loading === true) {
      return <div className='CreditList text-center'><br />Chargement...</div>;
    }
    if (creditList.length === 0) {
      return <div className='CreditList'><br /><div className="alert alert-info">Aucun crédit trouvé</div></div>;
    }
    return <div className='CreditList'>
      <br />
      <Button size="xs" variant="default" onClick={refreshList}><i className="fa-solid fa-rotate"></i> Rafraîchir</Button>
      <Table size="sm" striped hover>
        <thead>
          <tr>
            <th className="col-order-id">N° Cmd.</th>
            <th className="col-date">Date</th>
            <th className="col-time">Heure</th>
            <th className="col-amount">Montant</th>
            <th className="col-usage">Usage</th>
            <th className="col-type-label">Type</th>
            <th className="col-doneby">Fait par</th>
            <th className="col-action">Action</th>
          </tr>
        </thead>
        <tbody>
          {creditList.map((creditElt)=> {
            return <CreditRow key={creditElt.id} creditElt={creditElt} />;
          })}
        </tbody>
      </Table>
    </div>;
  }
}

InternalCreditList.propTypes = {
  "credit": PropTypes.object,
  "user": PropTypes.object,
  "loadCreditSync": PropTypes.func,
};

export const CreditList = connect(
  (state) => ({
    "credit": state.support.credit,
    "user": state.support.user
  }),
  (dispatch) => {
    return {
      loadCreditSync: (id) => {
        return dispatch(act_loadCreditSync(id));
      }
    };
  }
)(InternalCreditList);
