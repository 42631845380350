import { call, put, takeEvery, select } from 'redux-saga/effects';
import {cloneAndPluck, findByid, playAlarm} from "../utils/index";
import { api, daUtil } from "../api";
import {
  addDock,
  deliveryUpdateSucceeded,
  packingUpdateSucceeded,
  errorThrown,
  driverUpdateSucceeded,
  dockUpdated,
  refreshRequestSuccess,
  planningsSessionUpdated,
  refreshRequestError,
  CHANGE_STATUS,
  DROP_ON_DELIVERY_TASK,
  DROP_ON_DEFAULT,
  NUMBER_OF_BAGS,
  CALL_STUART,
  DOCK_CLOSE,
  REFRESH_REQUEST_OLDIES,
  DOCK_UPDATE,
  CANCEL_STUART,
  REGULARIZE_STUART,
  ALERT_CLOSED,
  CLOSE_ALERT,
  DROP_ON_PACKING_DEFAULT,
  DROP_ON_PACKING_TASK,
  LATE_WARNED,
  LATE_ACTION,
  ORDER_UPDATED,
  ORDER_UPDATE,
  CHANGE_DRIVER_VEHICLE,
  START_DRIVER_PRESENCE,
  STOP_DRIVER_PRESENCE,
  DOCK_ASSIGN,
  DRIVER_ACTIVITY_START,
  DRIVER_ACTIVITY_END,
  DOCK_UNLOCK,
  FINISH_DOCK,
  DOCK_DROP,
  ORDER_REDESTOCK,
  PSEUDOZONE_UPDATE,
  PSEUDOZONE_UPDATED, ORDER_PSEUDOZONE_UPDATE,
  ORDER_PSEUDOZONE_ALTER_STOCK, ORDER_PSEUDOZONE_ALTERED_STOCK,
  ERROR, CREATE_STATEMENT, DOCK_DROP_ON_DEFAULT, DOCK_DROP_ON_DELIVERY_TASK, GRANT_UPDATED,
  DRIVER_UPDATE, DOCK_DEFINE, DOCK_UNDEFINE,
  PLANNINGS_SESSION_UPDATE,
  DRIVER_DEPOSIT_DRIVER_LOAD, DRIVER_DEPOSIT_DRIVER_LOADED,
  DRIVER_DEPOSIT_DRIVERS_LOAD, DRIVER_DEPOSIT_DRIVERS_LOADED,
  DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD, DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED,
  DRIVER_DEPOSIT_MAKE_RETURN, DRIVER_DEPOSIT_CANCEL_RETURNED,
  DRIVER_DEPOSIT_CANCEL_RECEIVED, LOAD_ALARM,
  TRANSIT_AREA_LOAD, TRANSIT_AREA_LOADED,
  TRANSIT_AREA_CHANGE, TRANSIT_AREA_CHANGED,
  TROLLEY_LOAD, TROLLEY_LOADED,
  TROLLEY_LOCATION_CHANGE, TROLLEY_LOCATION_CHANGED,
  TROLLEY_LOGS_LOAD, TROLLEY_LOGS_LOADED,
  ORDER_CRATE_ADD,
  ORDER_CRATE_DELETE,
  ORDER_CALL_STUART,
  ORDER_STUART_SENDING,
  DOCK_CALL_STUART,
  DOCK_STUART_SENDING,
  // ORDER_UBER_SENDING,
  ORDER_SUPPORT_INFO_LOAD,
  ORDER_SUPPORT_INFO_LOADED,
  METAS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  METAS_DQP_COURIER_ENABLED_SAVE,
  METAS_RAIN_BONUS_SAVE,
  METAS_SAVED,
  HIDE_ALERT_NOTIFICATIONS,
  REVIEW_LOG_TROLLEY_NOT_FOUND,
  LOG_TROLLEY_NOT_FOUND_REVIEWED,
} from "../actions/index";
import { ORDER_TO_RESTOCK, ORDER_TO_RESTOCK_SUCCEEDED, VALIDATE_RESTOCK, VALIDATE_RESTOCK_SUCCEEDED } from '../actions/canceled_no_shipping';
import { CANCELED_NO_SHIPPING_FLAG_ID } from '../utils/task';
import { LOADING_MODAL_CRATE_UPDATE } from '../apps/crossdocking/actions';
import { MAIN_MODAL_HIDE } from '../actions/modal'; 
import { AUTH_ERROR, requireLogin }from "../actions/auth";
import * as Raven from "raven-js";
import Notifications from "react-notification-system-redux";
import {ASSIGN_UPDATE_NEXT, ASSIGN_UPDATED_NEXT, NEXT_TASK_DONE} from "../actions/nexts";
import {GRANT_UPDATE} from "../actions";
import {USER_UPDATE_CURRENT_CENTER} from "../actions/user";
import {setCenterIdAndRefresh, stateToCenterId, extractCenterId} from "../utils/center";

const FIELDS_DELIVERY = ['id', 'ordering', 'dock', 'dock_rank'];
const FIELDS_PACKING = ['id', 'emergency_rank'];

function _alertOnError(res) {
  if (res.error && res.error.message) {
    console.error('error', res.error);
    window.alert(res.error.message);
    delete res.error;
  }
  return res;
}

function notifError(message) {
  return Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"});
}


function* _utilUpdateTasks(filter) {
  const state = yield select();
  const taskIds = state.m.deliveries.filter(filter).map(e => e.id);
  const resTasks = yield call(api.loadShippingTasks, taskIds);
  yield put(deliveryUpdateSucceeded(resTasks));
}




function* closeAlert(action) {
  try {
    yield call(api.closeAlert, action.alert); // Order api system
    yield put({type: ALERT_CLOSED, alert: action.alert});

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* autoRefresh(action) {
  const state = yield select();
  try {
    const centerId = stateToCenterId(state);
    console.log("autoRefresh", centerId);
    let res = yield call(api.autoRefresh, action.version, centerId);
    yield put(refreshRequestSuccess(res));

  } catch (e) {
    Raven.captureException(e);
    yield put(refreshRequestError())
    yield put(errorThrown(e));
  }
}

function* dropDelivery(action) {
  const state = yield select();

  const task = Object.assign({}, findByid(state.m.deliveries, action.droppedTaskId));
  let toUpdate = state.m.deliveries.filter((e) => e.updating === task.id).map((e) => {
    return cloneAndPluck(e, FIELDS_DELIVERY);
  });

  if (action.droppedOrderPatch && action.droppedOrderPatch.id) {
    yield call(api.editOrder, action.droppedOrderPatch);
  }

  try {
    let res = yield call(api.saveShippingTasks, toUpdate);

    if (res.error) {
      yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }

    yield put(deliveryUpdateSucceeded(res));

    // maj dock dock dest (à cause du lock update)
    const taskDropperUpdated = res.find(e => e.id === task.id);
    if (taskDropperUpdated && taskDropperUpdated.dock && taskDropperUpdated.dock.id) {
      yield put(dockUpdated(taskDropperUpdated.dock));
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dropPacking(action) {
  const state = yield select();
  const task = Object.assign({}, findByid(state.m.packings, action.droppedTaskId));

  let toUpdate = state.m.packings.filter(e => e.updating === task.id).map(e => cloneAndPluck(e, FIELDS_PACKING));
  try {
    let res = yield call(api.savePackingTasks, toUpdate);
    yield put(packingUpdateSucceeded(res));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* destockAgain(action) {
  const orderId = action.orderId;
  try {
    // network calls
    let res = yield call(api.destockAgain, orderId);
    if (res.id === orderId) {
      window.alert(`Re - Déstockage de la commande #${res.random_id} fait (${res.id})`);
    }
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* changeStatus(action) {
  const orderId = action.orderId;
  const dockId = action.dockId ? action.dockId : null;
  let resTaskDeliveries = [];
  let resTaskPackings = [];

  const state = yield select();
  let toUpdateDelivery = state.m.deliveries
    .filter((e) => e.updating === orderId)
    .map(e => cloneAndPluck(e, FIELDS_DELIVERY));
  let toUpdatePacking = state.m.packings
    .filter((e) => e.updating === orderId)
    .map(e => cloneAndPluck(e, FIELDS_PACKING));


  // console.log("toUpdateDelivery", toUpdateDelivery);
  // console.log("toUpdatePacking", toUpdatePacking);
  // console.log("toRefreshDeliverys", toRefreshDelivery);
  try {
    // network calls
    yield call(
      api.changeOrderStatus,
      orderId,
      action.newStatus,
      "simple",
      action.additionalData,
    );

    if (toUpdateDelivery.length > 0) {
      resTaskDeliveries = yield call(api.saveShippingTasks, toUpdateDelivery);
    }
    if (toUpdatePacking.length > 0) {
      resTaskPackings = yield call(api.savePackingTasks, toUpdatePacking);
    }
    // change state
    if (resTaskDeliveries.length > 0) {
      yield put(deliveryUpdateSucceeded(resTaskDeliveries));
    }
    if (resTaskPackings.length > 0) {
      yield put(packingUpdateSucceeded(resTaskPackings));
    }

    // case update inside dock (special refresh case)
    let toRefreshDeliveryIds = [];
    if (dockId) {
      toRefreshDeliveryIds = state.m.deliveries
        .filter((e) => e.dock && e.dock.id === dockId)
        .map(e => e.id);
    }
    if (toRefreshDeliveryIds.length > 0) {
      resTaskDeliveries = yield call(api.loadShippingTasks, toRefreshDeliveryIds);
      if (resTaskDeliveries.length > 0) {
        yield put(deliveryUpdateSucceeded(resTaskDeliveries));
      }
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* changeBags(action) {
  if (!action.hasOwnProperty('newNumber')) {
    return;
  }
  try {
    let order = yield call(api.editOrder, {"id": action.task.linked_order.id, "number_of_bags": action.newNumber});
    yield put({type:ORDER_UPDATED, order: order});
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* updateOrder(action) {
  try {
    //No need for this call if we are just given an id
    if(Object.keys(action.orderPatch).length > 1) {
      let order = yield call(api.editOrder, action.orderPatch);
      yield put({type:ORDER_UPDATED, order: order});
    }

    //`/processing-orders/${data.id}/${service}/`
    if (action.opzPatchs) {
      for (const opzPatch of action.opzPatchs) {
        yield _processing("pseudozone", {...opzPatch, id: action.orderPatch.id})
      }
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* addOrderCrate({ orderId, crateCode }) {
  yield put(Notifications.info({title: "Crossdock", "message": "Ajout en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.addOrderCrate, orderId, crateCode, 1);
    yield put(Notifications.success({title: "Crossdock", "message": "Cagette ajoutée",  autoDismiss:3, dismissible:"click"}));
    yield put({ type: ORDER_UPDATED, order });
    yield put({ type: LOADING_MODAL_CRATE_UPDATE, order });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Crossdock" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Crossdock" , "message": "Impossible d'ajouter la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* deleteOrderCrate({ orderId, crateCode }) {
  yield put(Notifications.info({title: "Crossdock" , "message": "Suppression en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.deleteOrderCrate, orderId, crateCode, 1);
    yield put(Notifications.success({title: "Crossdock" , "message": "Cagette supprimée",  autoDismiss:3, dismissible:"click"}));
    yield put({ type: ORDER_UPDATED, order });
    yield put({ type: LOADING_MODAL_CRATE_UPDATE, order });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Crossdock" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Crossdock" , "message": "Impossible de supprimer la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* orderCallStuart({ orderPatch, vehicle }) {
  yield put(Notifications.info({title: "Stuart" , "message": "Appel en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const { order, dock } =  yield call(api.callStuartForOrder, orderPatch.id, vehicle);
    console.log('Before Put, res', order)
    yield put(Notifications.success({title: "Stuart" , "message": "Appel Stuart effectué",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(dock));
    yield put({ type: ORDER_UPDATED, order });
    yield put({ type: MAIN_MODAL_HIDE });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put({ type: ORDER_STUART_SENDING, sending: false })
      yield put(Notifications.error({title: "Stuart" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* dockCallStuart({ dockId, vehicle }) {
  yield put(Notifications.info({title: "Stuart" , "message": "Appel en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const  dock =  yield call(api.callStuartForDock, dockId, vehicle);
    yield put(Notifications.success({title: "Stuart" , "message": "Appel Stuart effectué",  autoDismiss:3, dismissible:"click"}));
    yield put(dockUpdated(dock));
    yield put({ type: MAIN_MODAL_HIDE });
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put({ type: DOCK_STUART_SENDING, sending: false })
      yield put(Notifications.error({title: "Stuart" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* stopDriverPresence(action) {
  const state = yield select();

  const deliveryIds = state.m.deliveries
    .filter(t => t.updating === action.driver.id && t.linked_order)
    .map(e => e.id);

  const driverUpdated = state.m.drivers.filter(d => d.updating === action.driver.id);

  if (driverUpdated.length === 0) {
    return;
  }

  try {
    let resDriver = yield call(api.stopPresence, action.driver.id, action.unfinished_new_status);
    const resTasks = yield call(api.loadShippingTasks, deliveryIds);
    yield put(deliveryUpdateSucceeded(resTasks));
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* startDriverPresence(action) {
  const state = yield select();

  try {


    // TODO: fix start response format (not driver state but driver actually)

    const centerId = stateToCenterId(state);

    let resDriver = yield call(api.startPresence, action.driver.id, action.vehicle, centerId);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* changeDriverVehicle(action) {
  try {
    // TODO: fix response format (not driver state but driver actually)

    let resDriver = yield call(api.changeVehicle, action.driver.id, action.vehicle);
    yield put(driverUpdateSucceeded(resDriver));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* createStatement(action) {
  const data = {
    driver: action.driver.id,
    statement_type: action.statement_type,
    amount: action.amount
  };
  yield call(daUtil.post, `/driver-statements/`, data);
  yield put(Notifications.success({title: "Retour sac" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}


function* dockUpdate(action) {

  if (!action.patch) {
    console.error("dockUpdate, undefined update", action);
  }

  try {
    let res = yield call(api.editDock, action.patch);
    yield put(dockUpdated(res));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dockAssign(action) {
  try {
    const res = yield call(daUtil.post, `/docks/${action.dock.id}/assign/`);
    yield put(dockUpdated(res));
    if (!res.locked_at) {

      yield put(Notifications.error({title: "Action impossible" , "message": "Impossible de lancer la livraison : " + (res.error_message && res.error_message !== "" ? res.error_message : "raison inconnue") + ". Veuillez réessayer ou mettre à jour votre flow",  autoDismiss:10, dismissible:"click"}));
    }
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error,  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* dockService(action) {
  try {
    const res = yield call(daUtil.post, `/docks/${action.dock.id}/${action.service}/`);
    yield put(dockUpdated(res));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error,  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }

  // TODO: GERER LES RETOUR 409
}




function* dockUnlock(action) {
  const res = yield call(daUtil.post, `/docks/${action.dock.id}/unlock/`);
  yield put(dockUpdated(res));
}

function* dockClose(action) {

  try {
    let res = yield call(api.closeDock, action.dock);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.updating === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* createStuartJob(action) {

  try {
    let res = yield call(api.createStuartJob, action.dock, action.transportType);
    res = yield call(_alertOnError, res);

    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    console.error(e);
    yield put(errorThrown(e));
  }
}

function* saveExtraDelay(action) {
  const newValue = parseInt(action.value, 10);
  if (isNaN(newValue)) {
    yield call(_alertOnError, {error: {message: "INVALIDE, Il faut juste mettre le nombre en minutes."}});
    return;
  }
  const state = yield select();
  yield call(daUtil.post, "/postal_codes/update_extra_delay/", {
    "extra_delay": newValue,
    "logistics_center_id": stateToCenterId(state),
  });
  yield put({type: METAS_SAVED});
}

function* saveExpressExtraDelay(action) {
  const newValue = parseInt(action.value, 10);
  if (isNaN(newValue)) {
    yield call(_alertOnError, {error: {message: "INVALIDE, Il faut juste mettre le nombre en minutes."}});
    return;
  }
  const state = yield select();
  yield call(daUtil.post, "/postal_codes/update_express_extra_delay/", {
    "express_extra_delay": newValue,
    "logistics_center_id": stateToCenterId(state),
  });
  yield put({type: METAS_SAVED});
}

function* saveExpressPrioExtraDelay(action) {
  const newValue = parseInt(action.value, 10);
  if (isNaN(newValue)) {
    yield call(_alertOnError, {error: {message: "INVALIDE, Il faut juste mettre le nombre en minutes."}});
    return;
  }
  const state = yield select();
  yield call(daUtil.post, "/postal_codes/update_express_prio_extra_delay/", {
    "express_prio_extra_delay": newValue,
    "logistics_center_id": stateToCenterId(state),
  });
  yield put({type: METAS_SAVED});
}

function* saveSomeDQPEnabled(action, code, label){
  const newValue = action.value === true ? 1 : 0;
  const state = yield select();
  try {
    yield call(daUtil.post, `/postal_codes/update_some_dqp_enabled/`, {
      [code]: newValue,
      "logistics_center_id": stateToCenterId(state),
    });
    yield put(
      Notifications.success(
        {
          title: label,
          message: action.value ? `${label} activé` : `${label} désactivé`,
          autoDismiss: 10,
          dismissible:"click",
        }
      )
    );
  } catch (e) {
    if(e.response.status === 400){
      console.log(e.response.data);
      yield put(
        Notifications.error(
          {
            title: label,
            message: e.response.data.errors[0] + " Votre flow n'est sans doute pas à jour.",
            autoDismiss: 10,
            dismissible: "click",
          }
        )
      );
    }
    else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
  yield put({type: METAS_SAVED});
}

function* saveDQPExpressPrioEnabled(action) {
  yield* saveSomeDQPEnabled(action, "dqp_express_prio_enabled", "DQP Express Prio");
}

function* saveDQPCourierEnabled(action) {
  yield* saveSomeDQPEnabled(action, "dqp_courier_enabled", "DQP Coursier privé");
}

function* saveRainBonus(action) {
  const newValue = parseInt(action.value, 10);
  const state = yield select();
  yield call(daUtil.post, "/driver_log_rules/update_rain_bonus/", {
    "vehicle": "SELEC",
    "rain_bonus_id": newValue,
    "logistics_center_id": stateToCenterId(state),
  });
  yield put({type: METAS_SAVED});
}


function* cancelStuartJob(action) {
  try {
    const res = yield call(api.cancelStuartJob, action.dock);
    yield _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);
    if (res.error) {
      yield put(dockUpdated(action.dock));
      yield put(Notifications.error(
        {
          title: "Stuart" ,
          message: res.error.message,
          autoDismiss: 10,
          dismissible:"click"}
      ));
    } else {
      const pendingDock = {
        ...action.dock,
        external_job: {
          ...action.dock.external_job,
          status: "annulation en cours...",
          pickup_at: null,
        }
      };
      yield put(dockUpdated(pendingDock));
      yield put(Notifications.success(
        {
          title: "Stuart" ,
          message: "Job en cours d'annulation",
          autoDismiss: 10,
          dismissible:"click"}
      ));
    }
  } catch (e) {
    yield put(Notifications.error(
      {
        title: "Stuart" ,
        message: "Une erreur est survenue lors de l'annulation",
        autoDismiss: 10,
        dismissible:"click"}
    ));
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* regularizeStuartDock(action) {
  try {
    const [firstTask] = action.dock.tasks || [];
    let res = yield call(api.regularizeCanceledStuart, action.dock);
    res = yield call(_alertOnError, res);
    const { dock, new_dock: newDock } = res;
    yield put(dockUpdated(dock));
    if (newDock) {
      yield put(addDock(newDock));
    } else {
      const orderNumber = firstTask ? firstTask.linked_order.random_id : null;
      const message = orderNumber
        ? `Le groupement de la commande #${orderNumber} a été régularisé`
        : `Le groupement a été régularisé`
      yield put(Notifications.success(
        {
          title: "Groupement régularisé" ,
          "message": message,
          autoDismiss:10,
          dismissible:"click"}
      ));
    }
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* finishDock(action) {

  try {
    let res = yield call(api.finishDock, action.dock);
    res = yield call(_alertOnError, res);
    yield put(dockUpdated(res));
    yield* _utilUpdateTasks(e => e.dock && e.dock.id === action.dock.id);

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* dropDock(action) {


  const state = yield select();
  let toUpdate = state.m.deliveries.filter((e) => e.updating === action.targetDockId).map((e) => {
    return cloneAndPluck(e, FIELDS_DELIVERY);
  });
  const srcDock = findByid(state.m.docks, action.droppedDockId);
  let destDock = findByid(state.m.docks, action.targetDockId);

  let destDockUpdated = null;
  let srcDockUpdated = null;
  try {
    let res;
    if (srcDock.loaded_at) {


      res = yield call(daUtil.post, `/docks/${destDock.id}/assign/`, {from_dock_id: srcDock.id});
      destDockUpdated = res;
    } else {
      // deprecated
      res = yield call(api.saveShippingTasks, toUpdate);
      destDockUpdated = destDock;
    }

    if (res.error) {
      res = yield call(_alertOnError, res);
      yield* _utilUpdateTasks((e) => e.updating);
      return;
    }


  } catch (e) {
    if (e.response && e.response.status === 409) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , "message": res.error, "position": "tc",  autoDismiss:10, dismissible:"click"}));
      delete res.error;
      yield put(dockUpdated(res));

    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
  yield* _utilUpdateTasks((e) => e.updating);
  destDockUpdated = yield call(daUtil.get, `/docks/${destDock.id}/`);
  srcDockUpdated = yield call(daUtil.get, `/docks/${srcDock.id}/`);
  yield put(dockUpdated(destDockUpdated));
  yield put(dockUpdated(srcDockUpdated));
}

function* _processing(service, data) {
  try {
    let res = yield call(api.processingOrder, service, data);
    yield put({type:ORDER_UPDATED, order: res});
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* lateAction(action) {
  yield* _processing('late_sms', {id: action.orderId, credits: action.creditToAdd, message: action.message});
}

function* lateWarned(action) {
  yield* _processing('warned', {id: action.orderId});
}

function* driverActivityStart(action) {
  const d = {
    note: action.note,
  };
  try {
    const res = yield call(daUtil.post, `/drivers/${action.driver.id}/start_activity/`, d);
    yield put(driverUpdateSucceeded(res));
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* driverActivityEnd(action) {
  // const state = yield select();
  try {
    const res = yield call(daUtil.post, `/drivers/${action.driver.id}/stop_activity/`, {});
    yield put(driverUpdateSucceeded(res));

    if (res.extra && res.extra.dock_ids) {
      let resDock;
      for (let dock_id of res.extra.dock_ids) {
        resDock = yield call(daUtil.get, `/docks/${dock_id}/`);
        yield put(dockUpdated(resDock));
      }
    }
    if (res.extra && res.extra.shipping_task_ids) {
      yield* _utilUpdateTasks(e => res.extra.shipping_task_ids.includes(e.id) );
    }

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* pzUpdate(action) {
  try {
    const res = yield call(daUtil.patch, `/pseudo-zones/${action.pseudoZone.id}/`, {is_active: action.newValue});
    yield put({type: PSEUDOZONE_UPDATED, pseudoZone : res});
  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* opzUpdate(action) {
  yield* _processing('pseudozone', {id: action.orderId, pseudo_zone_id: action.pseudoZoneId, is_done: action.isDone});
}

function* opzAlterStock(action) {
  try {

    let operation = action.action_type === 'destock' ? 'destock' : 'ajout';
    const order = yield call(
      daUtil.post,
      `/processing-orders/${action.orderId}/alter_stock/`,
      {'pseudo_zone_id': action.pseudoZoneId, 'action_type': action.action_type}
    );
    yield put({type:ORDER_UPDATED, order: order});
    yield put({type:ORDER_PSEUDOZONE_ALTERED_STOCK, task: action.task, pseudoZoneId: action.pseudoZoneId, operation: operation});

  } catch (e) {
    Raven.captureException(e);
    yield put(errorThrown(e));
  }
}

function* processErrors(action) {
  if (action.e.response && ""+action.e.response.status === '401') {
    yield put({type: AUTH_ERROR});
  } else if (action.e.response && action.e.response.data.detail) {
    yield put(Notifications.error(
      {
        title: "Une erreur est survenue" ,
        message: action.e.response.data.detail,
        autoDismiss:5,
        dismissible:"click"
      }
    ));
  }
  yield true;
}

function* assignUpdateNext(action) {

  let [next_task_type, external_id] = action.next_task_type_value.split('-');
  external_id = !external_id ? null : parseInt(external_id, 10);
  next_task_type = parseInt(next_task_type, 10);
  if (isNaN(next_task_type)) {
    next_task_type = null;
  }

  const data = {
    "next_task_type": next_task_type,
    "external_id": external_id
  };

  const res = yield call(daUtil.patch, `/nexts/${action.id}/`, data);
  yield put({response: res, type: ASSIGN_UPDATED_NEXT});
}

function* nextTaskDone(action) {
  try {
    const res = yield call(daUtil.post, `/nexts/${action.next_id}/task_done/`);
    yield put({response: res, type: ASSIGN_UPDATED_NEXT});
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenu" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
  }
}

function* grantUpdate(action) {
  try {
    const res = yield call(daUtil.post, `/users/${action.user_id}/grant/`, action.grant);
    yield put({type: GRANT_UPDATED, res: res, user_id: action.user_id});
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenu" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }

}

function* driverApiUpdate(action) {
  const state = yield select();

  try {
    const centerId = stateToCenterId(state);
    const patch = {...action.patch, center_id: centerId};
    const res = yield call(daUtil.patch, `/drivers/${action.driver.id}/`, patch);
    yield put(driverUpdateSucceeded(res));
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* planningsSessionApiUpdate(action) {
  try {
    const res = yield call(daUtil.patch, `/plannings/${action.plannings_session.id}/`, action.patch);
    yield put(planningsSessionUpdated(res));
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* onLoadDepositDriver(action) {
  try {
    let offset = 0;
    if (typeof(action.offset) !== 'undefined') {
      offset = action.offset;
    }
    const res = yield call(daUtil.get, `/depositdriver/history/?driver_id=${action.driver_id}&offset=${offset}`);
    yield put({...res, type: DRIVER_DEPOSIT_DRIVER_LOADED});
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}
function* onDriverDepositMakeReturn(action) {
  const state = yield select();

  try {
    let datas = {
      'driver_id': action.driver_id,
      'product_id': action.product_id,
      'quantity': action.quantity,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/return_to/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Retour de produit effectué!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": error.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(error);
  }
  yield put({...action, type: DRIVER_DEPOSIT_DRIVER_LOAD});
}
function* onDriverDepositCancelReturned(action) {
  const state = yield select();
  try {
    let datas = {
      'deposit_driver_id': action.deposit_driver_id,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/cancel_returned/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Correction effectuée!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": error.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(error);
  }
  yield put({...action, type: DRIVER_DEPOSIT_DRIVER_LOAD});
}
function* onDriverDepositCancelReceived(action) {
  const state = yield select();
  try {
    let datas = {
      'deposit_driver_id': action.deposit_driver_id,
      'deposit_user_id': action.deposit_user_id,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/cancel_received/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Correction effectuée!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": error.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(error);
  }
  yield put({...action, type: action.trigger_action_type});
}

function* onLoadDepositDrivers(action) {
  try {
    const res = yield call(daUtil.get, `/depositdriver/by_driver/`);
    yield put({...res, type: DRIVER_DEPOSIT_DRIVERS_LOADED});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}
function* onLoadDepositProductsDetail(action) {
  try {
    const res = yield call(daUtil.get, `/depositdriver/by_product/`);
    yield put({...res, type: DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}

function onUserUpdateCurrentCenter(action) {
  let state = select();
  setCenterIdAndRefresh(action.center.id, state.user);
}


async function loadAlarm(){
  let debug = true;
  if (window.location.pathname !== '/') {
    // Only on main flow screen
    if(debug) {
      console.log("loadAlarm exit not on main flow");
    }
    return;
  }
  let centerId = extractCenterId(null);
  let lastOrderId = window.sessionStorage.getItem('load_alarm_last_order_id');
  if(lastOrderId === null || typeof lastOrderId === 'undefined' || lastOrderId === 'undefined'){
    lastOrderId = 0;
  }
  let lastTs = window.sessionStorage.getItem('load_alarm_last_ts');
  if(lastTs === null || typeof lastTs === 'undefined' || lastTs === 'undefined'){
    lastTs = 0;
  }
  let data = null;
  try {
    if(debug) {
      console.log("loadAlarm api call", centerId, lastOrderId, lastTs);
    }
    data = await api.loadAlarm(centerId, lastOrderId, lastTs);
    if(debug) {
      console.log("loadAlarm api data response ", data);
    }
  } catch (e) {
    console.log("loadAlarm exit api error");
    console.error(e);
    return;
  }
  window.sessionStorage.setItem('load_alarm_last_order_id', data["last_order_id"]);
  window.sessionStorage.setItem('load_alarm_last_ts', data["last_ts"]);

  if(data["alarm"]){
    if(debug) {
      console.log("loadAlarm bell ");
    }
    playAlarm();
  }
}

function* changeTransitArea(action) {
  try {
    const res = yield call(daUtil.post, `/processing-orders/${action.datas.order_id}/change_transit_area/`, action.datas)
    yield put({...res, type: TRANSIT_AREA_CHANGED});
    yield put({...res, type: TRANSIT_AREA_LOAD, order_id: action.datas.order_id});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}

function* changeTrolleyLocation(action) {
  try {
    const res = yield call(daUtil.patch, `/trolleys/${action.datas.trolley_id}/`, action.datas)
    yield put({...res, type: TROLLEY_LOCATION_CHANGED});
    yield put({...res, type: TROLLEY_LOAD});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}

function* loadTransitAreas(action) {
  const state = yield select();
  try {
    let params = "?center_id=";
    let centerId = stateToCenterId(state);
    if (typeof(action.center_id) !== undefined) {
      centerId = action.center_id;
    }
    params += centerId;
    if (action.order_id) {
      params += "&order_id=" + action.order_id;
    }
    const res = yield call(daUtil.get, `/transitareas/${params}`)
    yield put({ transit_areas: [...res], type: TRANSIT_AREA_LOADED});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}

function* loadTrolleys(action) {
  try {
    const res = yield call(daUtil.get, `/trolleys/?limit=999`)
    yield put({...res, type: TROLLEY_LOADED});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}

function* loadTrolleyLogs(action) {
  try {
    const res = yield call(daUtil.get, `/trolleys/${action.trolley_id}/logs/`)
    yield put({data:res, type: TROLLEY_LOGS_LOADED});
  } catch(e) {
    yield put(notifError(e.message));
    Raven.captureException(e);
  }
}


function* loadSupportInfoForOrder(action) {
  //const res = yield call(daUtil.get, `/support-info/?order_id=${action.order_id}`);
  // yield put({...res, type: ORDER_SUPPORT_INFO_LOADED});
  const res = yield call(
      daUtil.get,
      `/processing-orders/${action.order_id}/`
      + '?ser=admin'
      + '&products=0'
      + '&shipping_task=0'
      + '&group_shipment_task=0'
      + '&missing_task=0'
      + '&invoice=0'
      + '&payment=0'
      + '&transit_area=0'
      + '&parent_order=0'
      + '&child_orders=0'
      + '&last_external_job=0'
  );
  yield put({order: res, type: ORDER_SUPPORT_INFO_LOADED});
}

function* orderToRestock(action) {
  try {
    const { orderId } = action;
    const { order, new_flag: newFlag } = yield call(api.orderToRestock, orderId, CANCELED_NO_SHIPPING_FLAG_ID);
    yield put(Notifications.success({title: "Succès" , message: 'La commande est en attente de restockage',  autoDismiss:10, dismissible:"click"}));
    yield put({ type: ORDER_TO_RESTOCK_SUCCEEDED, order, newFlag });
    yield put({ type: MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      yield put(Notifications.error({title: "Action impossible" , message: res.error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* validateRestock(action) {
  try {
    const { orderId } = action;
    const { order } = yield call(api.validateRestock, orderId);
    yield put(Notifications.success({title: "Succès" , message: 'Le restockage a été validé',  autoDismiss:10, dismissible:"click"}));
    yield put({ type: VALIDATE_RESTOCK_SUCCEEDED, order });
    yield put({ type: MAIN_MODAL_HIDE });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:10, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

function* hideAlertNotifications(action) {
  const state = yield select();
  localStorage.setItem("hiddenAlertNotifications", JSON.stringify([...state.m.hiddenAlertNotifications]));
}

function* reviewLogTrolleyNotFound(action) {
  try {
    const { payload } = action;
    yield call(api.reviewLogTrolleyNotFound, payload.id, payload);
    yield put(Notifications.success({title: "Succès" , message: 'Alerte traitée !',  autoDismiss:2, dismissible:"click"}));
    yield put({ type: LOG_TROLLEY_NOT_FOUND_REVIEWED, id: payload.id });
  } catch (e) {
    if (e.response && e.response.data) {
      let res = e.response.data;
      const error = res.error || res.detail;
      yield put(Notifications.error({title: "Action impossible" , message: error,  autoDismiss:2, dismissible:"click"}));
    } else {
      Raven.captureException(e);
      yield put(errorThrown(e));
    }
  }
}

export const mainSagas = [
  takeEvery(DOCK_DROP_ON_DEFAULT, dropDelivery),
  takeEvery(DOCK_DROP_ON_DELIVERY_TASK, dropDelivery),
  takeEvery(DROP_ON_DEFAULT, dropDelivery),
  takeEvery(DROP_ON_DELIVERY_TASK, dropDelivery),
  takeEvery(DROP_ON_PACKING_DEFAULT, dropPacking),
  takeEvery(DROP_ON_PACKING_TASK, dropPacking),
  takeEvery(CHANGE_STATUS, changeStatus),
  takeEvery(ORDER_REDESTOCK, destockAgain),
  takeEvery(NUMBER_OF_BAGS, changeBags),
  takeEvery(STOP_DRIVER_PRESENCE, stopDriverPresence),
  takeEvery(START_DRIVER_PRESENCE, startDriverPresence),
  takeEvery(CHANGE_DRIVER_VEHICLE, changeDriverVehicle),
  takeEvery(DRIVER_ACTIVITY_START, driverActivityStart),
  takeEvery(DRIVER_ACTIVITY_END, driverActivityEnd),
  takeEvery(DRIVER_UPDATE, driverApiUpdate),
  takeEvery(CREATE_STATEMENT, createStatement),
  takeEvery(DOCK_UPDATE, dockUpdate),
  takeEvery(DOCK_CLOSE, dockClose),
  takeEvery(DOCK_ASSIGN, dockAssign),
  takeEvery(DOCK_UNLOCK, dockUnlock),
  takeEvery([DOCK_DEFINE, DOCK_UNDEFINE], dockService),
  takeEvery(DOCK_DROP, dropDock),
  takeEvery(CALL_STUART, createStuartJob),
  takeEvery(CANCEL_STUART, cancelStuartJob),
  takeEvery(REGULARIZE_STUART, regularizeStuartDock),
  takeEvery(FINISH_DOCK, finishDock),
  takeEvery(REFRESH_REQUEST_OLDIES, autoRefresh),
  takeEvery(CLOSE_ALERT, closeAlert),
  takeEvery(LATE_WARNED, lateWarned),
  takeEvery(LATE_ACTION, lateAction),
  takeEvery(METAS_EXTRA_DELAY_SAVE, saveExtraDelay),
  takeEvery(METAS_EXPRESS_EXTRA_DELAY_SAVE, saveExpressExtraDelay),
  takeEvery(METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE, saveExpressPrioExtraDelay),
  takeEvery(METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE, saveDQPExpressPrioEnabled),
  takeEvery(METAS_DQP_COURIER_ENABLED_SAVE, saveDQPCourierEnabled),
  takeEvery(METAS_RAIN_BONUS_SAVE, saveRainBonus),
  takeEvery(PSEUDOZONE_UPDATE, pzUpdate),
  takeEvery(ORDER_PSEUDOZONE_UPDATE, opzUpdate),
  takeEvery(ORDER_PSEUDOZONE_ALTER_STOCK, opzAlterStock),
  takeEvery(ORDER_UPDATE, updateOrder),
  takeEvery(ORDER_CRATE_ADD, addOrderCrate),
  takeEvery(ORDER_CRATE_DELETE, deleteOrderCrate),
  takeEvery(ORDER_CALL_STUART, orderCallStuart),
  takeEvery(DOCK_CALL_STUART, dockCallStuart),
  takeEvery(ASSIGN_UPDATE_NEXT, assignUpdateNext),
  takeEvery(NEXT_TASK_DONE, nextTaskDone),
  takeEvery(GRANT_UPDATE, grantUpdate),
  takeEvery(ERROR, processErrors),
  takeEvery(AUTH_ERROR, requireLogin),
  takeEvery(PLANNINGS_SESSION_UPDATE, planningsSessionApiUpdate),
  takeEvery(DRIVER_DEPOSIT_DRIVER_LOAD, onLoadDepositDriver),
  takeEvery(DRIVER_DEPOSIT_DRIVERS_LOAD, onLoadDepositDrivers),
  takeEvery(DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD, onLoadDepositProductsDetail),
  takeEvery(DRIVER_DEPOSIT_MAKE_RETURN, onDriverDepositMakeReturn),
  takeEvery(DRIVER_DEPOSIT_CANCEL_RETURNED, onDriverDepositCancelReturned),
  takeEvery(DRIVER_DEPOSIT_CANCEL_RECEIVED, onDriverDepositCancelReceived),
  takeEvery(USER_UPDATE_CURRENT_CENTER, onUserUpdateCurrentCenter),
  takeEvery(LOAD_ALARM, loadAlarm),
  takeEvery(TRANSIT_AREA_CHANGE, changeTransitArea),
  takeEvery(TRANSIT_AREA_LOAD, loadTransitAreas),
  takeEvery(TROLLEY_LOCATION_CHANGE, changeTrolleyLocation),
  takeEvery(TROLLEY_LOAD, loadTrolleys),
  takeEvery(TROLLEY_LOGS_LOAD, loadTrolleyLogs),
  takeEvery(ORDER_SUPPORT_INFO_LOAD, loadSupportInfoForOrder),
  takeEvery(ORDER_TO_RESTOCK, orderToRestock),
  takeEvery(VALIDATE_RESTOCK, validateRestock),
  takeEvery(HIDE_ALERT_NOTIFICATIONS, hideAlertNotifications),
  takeEvery(REVIEW_LOG_TROLLEY_NOT_FOUND, reviewLogTrolleyNotFound),
];
