let _exp = {};

_exp.dockDepartureFilter = function (action, state) {
  return {
    ...state,
    filters: {
      ...state.filters,
      dockDeparture: action.payload
    }
  };
};


export default _exp;

