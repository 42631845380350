import {connect} from "react-redux";
import Alert from "../components/Alert";
import {CLOSE_ALERT} from "../actions";

const mapStateToProps = state => {
  return {
    alerts: state.m.alerts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    "onCloseAlert": (a) => dispatch({type: CLOSE_ALERT, alert:a})
  };
};

let AlertLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);


export default AlertLinked;
