import { call, put, takeEvery, select } from 'redux-saga/effects';
import { daUtil } from "../../../api";
import {
  DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD,
  DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED,
  DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD,
  DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED,
  DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD,
  DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED,
  DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN,
  DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN,
  DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED,
  DISPATCH_DRIVER_DEPOSIT_CANCEL_RECEIVED,
} from "../actions/deposit_driver";
import * as Raven from "raven-js";
import Notifications from "react-notification-system-redux";
import {stateToCenterId} from "../../../utils/center";

function notifError(e) {
  const errorMessage = e.response && e.response.data.detail ? e.response.data.detail : e.message;
  return Notifications.error({title: "Une erreur est survenue" , "message": errorMessage,  autoDismiss:5, dismissible:"click"});
}


function* onLoadDepositDriver(action) {
  try {
    let offset = 0;
    if (typeof(action.offset) !== 'undefined') {
      offset = action.offset;
    }
    const res = yield call(daUtil.get, `/depositdriver/history/?driver_id=${action.driver_id}&offset=${offset}`);
    yield put({...res, type: DISPATCH_DRIVER_DEPOSIT_DRIVER_LOADED});
  } catch(e) {
    yield put(notifError(e));
    Raven.captureException(e);
  }
}
function* onDriverDepositMakeReturn(action) {
  const state = yield select();

  try {
    let datas = {
      'driver_id': action.driver_id,
      'product_id': action.product_id,
      'quantity': action.quantity,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/return_to/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Retour de produit effectué!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(notifError(error));
    Raven.captureException(error);
  }
  yield put({...action, type: DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD});
}

function* onDriverDepositOrderMakeReturn(action) {
  try {
    yield call(daUtil.post, '/depositdriver/return_order_to/', action);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Retour de commande effectué!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(notifError(error));
    Raven.captureException(error);
  }
  yield put({...action, type: DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD});
}

function* onDriverDepositCancelReturned(action) {
  const state = yield select();
  try {
    let datas = {
      'deposit_driver_id': action.deposit_driver_id,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/cancel_returned/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Correction effectuée!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(notifError(error));
    Raven.captureException(error);
  }
  yield put({...action, type: DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD});
}
function* onDriverDepositCancelReceived(action) {
  const state = yield select();
  try {
    let datas = {
      'deposit_driver_id': action.deposit_driver_id,
      'deposit_user_id': action.deposit_user_id,
      'center_id': stateToCenterId(state),
    };
    yield call(daUtil.post, '/depositdriver/cancel_received/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Correction effectuée!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(notifError(error));
    Raven.captureException(error);
  }
  yield put({...action, type: action.trigger_action_type});
}

function* onLoadDepositDrivers(action) {
  try {
    const res = yield call(daUtil.get, `/depositdriver/by_driver/`);
    yield put({...res, type: DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOADED});
  } catch(e) {
    yield put(notifError(e));
    Raven.captureException(e);
  }
}
function* onLoadDepositProductsDetail(action) {
  try {
    const res = yield call(daUtil.get, `/depositdriver/by_product/`);
    yield put({...res, type: DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED});
  } catch(e) {
    yield put(notifError(e));
    Raven.captureException(e);
  }
}

export const depositDriverSagas = [
  takeEvery(DISPATCH_DRIVER_DEPOSIT_DRIVER_LOAD, onLoadDepositDriver),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_DRIVERS_LOAD, onLoadDepositDrivers),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD, onLoadDepositProductsDetail),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_MAKE_RETURN, onDriverDepositMakeReturn),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_ORDER_MAKE_RETURN, onDriverDepositOrderMakeReturn),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_CANCEL_RETURNED, onDriverDepositCancelReturned),
  takeEvery(DISPATCH_DRIVER_DEPOSIT_CANCEL_RECEIVED, onDriverDepositCancelReceived),
];
