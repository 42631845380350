import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { showStuartDockCall } from '../actions/modal';

const StuartDockCallButton = ({ dock,  children, dispatch }) => {

  const backgroundColor = '#0192ff';
  const clickHandler = () => dispatch(showStuartDockCall(dock));

  return (
    <Button
      size="sm"
      onClick={clickHandler}
      style={{ backgroundColor }}
    >
      {children}
    </Button>
  );
};

StuartDockCallButton.propTypes = {
  dock: PropTypes.object,
  children: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(() => ({}))(StuartDockCallButton);


