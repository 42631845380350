import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import {DISPATCH_MAIN_MODAL_HIDE, DISPATCH_MODAL_DEPOSIT_DRIVER, showDepositModalDrivers} from "../../actions/modal";
import {act_loadDepositDriver} from "../../actions/deposit_driver";
import {DepositDriver} from "../Deposit/DepositDriver";
import "../../css/DepositModal.css";

class InternalModal extends React.Component {
  componentDidUpdate() {
    if (this.props.modal && this.props.modal.driver) {
      this.props.loadDepositDriver(this.props.modal.driver.id);
    }
  }
  componentDidMount() {
    const {modal} = this.props;

    if (!modal.driver) {
      return null;
    }
    
    const driver = modal.driver;
    this.props.loadDepositDriver(driver.id);
  }

  render() {
    const {modal, hide, goToDepositDrivers} = this.props;

    if (!modal.driver) {
      return null;
    }
    const driver = modal.driver;

    return <Modal
      show={modal && modal.modalType === DISPATCH_MODAL_DEPOSIT_DRIVER}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="DepositModalDriver"
      enforceFocus={false}
    >
      <Modal.Body>
        <button className="btn btn-default btn-xs" onClick={() => goToDepositDrivers()}>
          <i className="fa-solid fa-chevron-left"></i> Retour à la liste des consignes
        </button>
        <h4>Consignes {driver.name}</h4>
        <DepositDriver driver={driver} />
      </Modal.Body>
    </Modal>;

  }
}

InternalModal.propTypes = {
  driver: PropTypes.object,
  modal: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  loadDepositDriver: PropTypes.func.isRequired,
  goToDepositDrivers: PropTypes.func.isRequired,
};

export const DepositModalDriver = connect(
  (state) => {
    return {
      "modal" : state.dispatch.modal,
    };
  },
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": DISPATCH_MAIN_MODAL_HIDE});
      },
      loadDepositDriver: (id) => dispatch(act_loadDepositDriver(id)),
      goToDepositDrivers: () =>  dispatch(showDepositModalDrivers()),
    };
  }
)(InternalModal);
