import {daUtil} from "../api";
import Notifications from "react-notification-system-redux";
import {extractApiErrorMessageFromError} from "./index";
import {call, put} from "redux-saga/effects";

export function* doExtraCostAdd(datas) {
  try {
    yield call(daUtil.post, `/driver-extra-costs/`, datas);
    yield put(
      Notifications.success(
        {
          title: "Surcoût",
          message: "Le surcoût a été ajouté avec succès.",
          autoDismiss: 3,
          dismissible: "click"
        }
      )
    );
  }
  catch (error) {
    yield put(
      Notifications.error(
        {
          title: "Surcoût",
          message: "Une erreur empêche la création du surcoût (" + extractApiErrorMessageFromError(error) + ").",
          autoDismiss: 30,
          dismissible: "click"
        }
      )
    );
  }
}

export function* doReviewExtraCostDemand(demandId) {
  try {
    yield call(daUtil.post, `/driver-extra-cost-demands/${demandId}/review/`);
    yield put(
      Notifications.success(
        {
          title: "Demande de surcoût" ,
          message: "La demande de surcoût a bien été refusée.",
          autoDismiss:3,
          dismissible:"click",
        }
      )
    );
  } catch(error) {
    yield put(
      Notifications.error(
        {
          title: "Demande de surcoût",
          message: "Une erreur empêche de refuser la demande de surcoût (" + extractApiErrorMessageFromError(error) + ").",
          autoDismiss:30,
          dismissible:"click",
        }
      )
    );
  }
}
