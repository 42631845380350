import React from "react";
import PropTypes from "prop-types";
import {Address} from "../../../../components/Common";
import {TagsOrder} from "../../../../components/TagsOrder";
import {mapUrlRound} from "../../../../utils/address";
import {Modal} from "react-bootstrap";
import {DISPATCH_MAIN_MODAL_HIDE, DISPATCH_MODAL_DOCK_ROUTE, optimizeDockRoute} from "../../actions/modal";
import {connect} from "react-redux";
import "../../css/RouteComparisonModal.css";
import moment from "moment";
import {TransitArea} from "../Deliveries/DeliveryRow/TransitArea";


export class RouteComparisonModalInternal extends React.Component {
  render() {
    const {modal, hide, centers, deleevOptimizer, googleOptimizer} = this.props;
    return (
      <Modal
        size="xl"
        show={modal && modal.modalType === DISPATCH_MODAL_DOCK_ROUTE}
        animation={false}
        backdrop={true}
        onHide={() => hide()}
        keyboard={true}
        className="OptimizeDockModal"
        enforceFocus={false}>
        <Modal.Body>
          <h1>Optimisation de tournée</h1>
          <div className="route-compare-container">
            <RouteOptimizationComparison
              dock={modal.dock}
              routes={modal.routes}
              loading={modal.loading}
              deleevOptimizer={deleevOptimizer}
              googleOptimizer={googleOptimizer}
              centers={centers}/>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

export class RouteOptimizationComparison extends React.Component {

  constructor(props) {
    super(props);
    this.state = {autoGenerated: false};
  }

  componentDidMount() {
    const dock = this.props.dock;
    const routes = this.getRoutes();
    const deleevOptimizer = this.props.deleevOptimizer;

    if (routes['deleev'].count === 0 && !this.state.autoGenerated) {
      this.setState({autoGenerated: true});
      deleevOptimizer(dock);
    }
  }


  render() {
    const routes = this.getRoutes();

    return (
      <div className="row">
        <div className="col-md-6">
          {routes['deleev'].route.length
            ? this.renderRoute(routes['deleev'])
            : this.renderGenerateDeleev()
          }
        </div>
        <div className="col-md-6">
          {routes['google'].route.length
            ? this.renderRoute(routes['google'])
            : this.renderGenerateGoogle()
          }
        </div>
      </div>
    );
  }

  renderGenerateDeleev() {
    const loading = this.props.loading || false;
    const dock = this.props.dock;
    const deleevOptimizer = this.props.deleevOptimizer;
    return <button
      disabled={loading}
      onClick={() => deleevOptimizer(dock)}
      className="btn btn-default">
        Générer l'optimisation Deleev
      </button>
  }

  renderGenerateGoogle() {
    const loading = this.props.loading || false;
    const dock = this.props.dock;
    const googleOptimizer = this.props.googleOptimizer;
    return <span><button
      disabled={loading}
      onClick={() => googleOptimizer(dock)}
      className="btn btn-default">
        Générer l'optimisation Google*
      </button><p><strong>*Attention, cela peut entrainer un surcoût.</strong></p></span>;
  }

  getRoutes() {
    let routes = {};
    console.log(this.props.routes)
    for (let route of this.props.routes) {
      routes[route.type] = route;
    }
    return routes;
  }

  renderRoute(route) {
    const addresses = route.route.map(
      route => route.order.shipping_address
    );
    return (
      <div>
        <h2>{route.type}</h2>
        <table>
          <thead>
            {this.renderRouteHeader(addresses)}
          </thead>
          <tbody>
            {this.renderFirstLine(route.route[0])}
            {route.route.map((step) => this.renderStep(step))}
          </tbody>

        </table>
        {route.type === 'deleev' && this.renderGenerateDeleev()}
        {route.type === 'google' && this.renderGenerateGoogle()}
      </div>
    );
  }

  renderRouteHeader(addresses) {
    const dock = this.props.dock;
    const centers = this.props.centers;
    return (
      <tr>
        <th colSpan="5">
          <DockHeader
            dock={dock}
            centers={centers}
            addresses={addresses}/>
        </th>
      </tr>
    );
  }

  renderFirstLine(step) {
    const time = this.formatTime;
    return <tr className="Row" key={step.id}>
      <td>Commande</td>
      <td className="tags-collection"></td>
      <td>depart du centre&nbsp;
        {time(step.earliest_departure_from_center)} -
        {time(step.latest_departure_from_center, false)}</td>
      <td>créneau</td>
      <td>estimation arrivée livreur</td>
      <td>cagettes</td>
      <td>emplacement</td>
    </tr>;
  }

  renderStep(step) {
    const time = this.formatTime;
    return (
      <tr className="Row" key={step.id}>
        <td>#{step.order.random_id}</td>
        <td className="tags-collection"><TagsOrder order={step.order} address={step.order.shipping_address}/></td>
        <td><Address center_id={step.order.center} address={step.order.shipping_address} /></td>
        <td>{time(step.order.shipping_time)} - {time(step.order.max_shipping_time, false)}</td>
        <td>{time(step.earliest_arrival)} - {time(step.latest_arrival, false)}</td>
        <td>{step.order.crates.map((c => c.code)).join(",")}</td>
        <td><TransitArea order={step.order} /></td>
      </tr>
    );
  }

  formatTime(dateAsString, addDay = true) {
    const now = new Date();
    const date = new Date(dateAsString);
    return date.toDateString() !== now.toDateString() && addDay
        ? `${moment(date).format('(DD/MM/YYYY) HH:mm')}`
        : `${moment(date).format('HH:mm')}`;
  }
}

RouteOptimizationComparison.propTypes = {
  "dock": PropTypes.object,
  "routes": PropTypes.array,
  "centers": PropTypes.array,
  "loading": PropTypes.bool,
};

export class DockHeader extends React.Component {
  render() {
    const dock = this.props.dock;
    const centers = this.props.centers;
    const addresses = this.props.addresses;
    return (
      <div className="DockTitle" data-dock-id={dock.id}>
        <span className="dock_name">
          <span className="RouteDock">
            <span>{dock.name}</span>
            <a href={mapUrlRound(addresses, dock.center, centers)}
               target="_blank"
               rel="noopener noreferrer"
               className="btn btn-xs btn-default LinkMapsRound"
               style={{marginLeft: "15px"}}>
              <i className="fa-solid fa-route"></i> maps
            </a>
          </span>
        </span>
      </div>
    );
  }
}


RouteComparisonModalInternal.propTypes = {
  modal: PropTypes.object,
  hide: PropTypes.func,
  centers: PropTypes.array,
  googleOptimizer: PropTypes.func,
  deleevOptimizer: PropTypes.func,
};

RouteOptimizationComparison.propTypes = {
  "dock":    PropTypes.object,
  "centers": PropTypes.array,
  "googleOptimizer": PropTypes.func,
  "deleevOptimizer": PropTypes.func,
};

DockHeader.propTypes = {
  "dock": PropTypes.object,
  "centers": PropTypes.array,
  "addresses": PropTypes.array,
}

export const RouteComparisonModal = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
    "centers": state.dispatch.metas.centers,
  }),
  (dispatch) => {
    return {
      "hide": () => dispatch({"type": DISPATCH_MAIN_MODAL_HIDE}),
      "googleOptimizer": (dock) => dispatch(optimizeDockRoute(dock, 'google')),
      "deleevOptimizer": (dock) => dispatch(optimizeDockRoute(dock, 'deleev'))
    };
  }
)(RouteComparisonModalInternal);
