import Notifications from "react-notification-system-redux";
import store from "../createStore";


export function findByid(list, id) {
  return list.find((e) => {
    return e.id === id;
  });
}

export function  cloneAndPluck(sourceObject, keys) {
  let newObject = {};
  for (let key of keys) {
    newObject[key] = sourceObject[key];
  }
  return newObject;
}

export function sortByKey(key, reverse = false) {
  return function(a, b) {
    const x = a[key];
    const y = b[key];
    if (reverse) {
      return ((y < x) ? -1 : ((y > x) ? 1 : 0));
    } else {
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    }
  };
}


export function rd(num){
  return Math.round((num + 0.00001) * 100) / 100;
}

export function trunc(label){
  if (label.length > 50) {
    return label.substring(0, 50) + '...';
  }
  return label;
}

export function immutableSplice(arr, start, deleteCount, ...items) {
  return [ ...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount) ];
}

export function getObjectChanges(oldObject, newObject) {
  if (JSON.stringify(oldObject) === JSON.stringify(newObject)) {
    return false;
  }
  let changes = {};
  for (let k in oldObject) {
    if (k === 'updated_at') {
      continue;
    }
    if (newObject.hasOwnProperty(k)) {
      let old_value = oldObject[k];
      let new_value = newObject[k];
      if (new_value instanceof Object) {
        if (JSON.stringify(old_value) === JSON.stringify(new_value)) {
          continue;
        }
      } else if (old_value === new_value) {
        continue;
      }
      changes[k] = new_value;
    }
  }
  return changes;
}


export function playAlarm() {
  try {
    var audio = new Audio('/bell.mp3');
    audio.play();
  } catch (e) {
    console.log("loadAlarm audio error");
    console.error(e);
  }
}


export function copyToClipboard(text) {
  if(!navigator.clipboard) {
    //dirty fix until we have HTTPS everywhere
    alert(text);
    return;
  }
  navigator.clipboard.writeText(text);
  store.dispatch(Notifications.success({
    title: "Donnée copiée dans le presse-papier.",
    "message": "Donnée copiée dans le presse-papier.",
    autoDismiss: 3,
    dismissible: "click"
  }));
}

export function format_seconds(nb_sec) {
  if (nb_sec < 60) {
    return Math.round(nb_sec) + 's';
  }

  let minutes = Math.trunc(nb_sec / 60);
  let seconds = nb_sec % 60;

  if (nb_sec < 3600) {
    if (Math.round(seconds) === 0) {
      return Math.round(minutes) + 'm';
    }

    return Math.round(minutes) + 'm ' + Math.round(seconds) + 's';
  }

  let hours = Math.trunc(minutes / 60);
  minutes = minutes % 60;
  if (nb_sec < 86400) {
    return Math.round(hours) + 'h ' + Math.round(minutes) + 'm ' + Math.round(seconds) + 's';
  }

  let days = Math.trunc(hours / 24);
  hours = hours % 24;
  return Math.round(days) + 'j ' + Math.round(hours) + 'h ' + Math.round(minutes) + 'm ' + Math.round(seconds) + 's';
}


export function extractApiErrorMessageFromError(error) {

  if (error.response.data) {
    function filterErrorsCandidate(someData) {
      if (typeof someData === 'string' || someData instanceof String){
        if (someData === "") {
          return null;
        }
        return someData;
      }
      if(Array.isArray(someData)) {
        return someData;
      }
      return null;
    }

    let errorsCandidate = (
        filterErrorsCandidate(error.response.data)
        || filterErrorsCandidate(error.response.data.errors)
        || filterErrorsCandidate(error.response.data.error)
    );
    let errorsString = '';
    if (typeof errorsCandidate === 'string' || errorsCandidate instanceof String){
      errorsString = errorsCandidate;
    }
    if(Array.isArray(errorsCandidate)){
        for(let error of errorsCandidate){
            //alert(error);
            errorsString += ' ' + error;
        }
    }
    if (errorsString !== '') {
      return errorsString;
    }
  }
  return error.message;
}

export function isRepreparationReasonCommentValid(someText){
  return someText && someText.trim().length >= 10;
}

export function isBlockingExitReasonCommentValid(someText){
  return someText && someText.trim().length >= 10;
}


export default { findByid, cloneAndPluck, sortByKey };
