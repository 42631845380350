import React from 'react';
//import registerServiceWorker from './registerServiceWorker';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import store from "./createStore";
import './index.css';
import App from './App';
import { ReactQueryCacheProvider } from 'react-query';
import queryCache from './queryCache';


render(
  <Provider store={store}>
    <ReactQueryCacheProvider client={queryCache}>
      <App firstLoad={store.getState().firstLoad} />
    </ReactQueryCacheProvider>
  </Provider>,
  document.getElementById('root')
);

//registerServiceWorker();
