import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {DropdownButton, Dropdown} from "react-bootstrap";

import {
  act_dockSwitchDrivers,
  act_dockReadyToLoad,
} from "../../../actions/dock";
import {DOCK_TYPE_HARD_ASSIGNED, VEHICLES_LONG_LABEL} from "../../../utils/dock_n_driver";


// eslint-disable-next-line react/prop-types
const Internal = ({
  dock,
  docks_extract,
  allDocks,
  allDrivers,
  doSwitch,
  switchToReadyToLoad
}) => {

  // Impossible de switcher si dock sans livreur ou parti ou choisi par le livreur
  if (!dock.target_driver || dock.gone_at || dock.dock_type !== DOCK_TYPE_HARD_ASSIGNED) {
    return null;
  }

  let driversMap = new Map();
  for (let someDriver of allDrivers) {
    driversMap.set(someDriver.id, someDriver);
  }
  if (driversMap.size === 0) {
    return null;
  }

  let driver = driversMap.get(dock.target_driver);
  let useDriverVehicle = true;

  let someDocks = allDocks.filter(
      someDock => (
          someDock.target_driver
          && someDock.target_driver !== dock.target_driver
          && someDock.gone_at === null
          && someDock.center === dock.center
      )
  ).sort(
      (a, b) => {
        let someDriverA = driversMap.get(a.target_driver);
        let someDriverB = driversMap.get(b.target_driver);
        if (!someDriverA || !someDriverB) {
            // Quand les chauffeurs ne sont pas encore chargés,
            // tant pis, on ne trie pas.
            return -1;
        }
        let referenceVehicle = dock.target_vehicle;
        let someVehicleA = a.target_vehicle;
        let someVehicleB = b.target_vehicle;
        if (useDriverVehicle) {
          referenceVehicle = driver.vehicle;
          someVehicleA = someDriverA.vehicle;
          someVehicleB = someDriverB.vehicle;
        }

        if (someVehicleA !== someVehicleB) {
          if (someVehicleA === referenceVehicle) {
            return -1;
          }
          if (someVehicleB === referenceVehicle) {
            return 1;
          }
        }
        if (someDriverA.name < someDriverB.name) {
            return -1;
        }
        if (someDriverA.name === someDriverB.name) {
            return 0;
        }
        return 1;
      }
  );

  // Pour le moment seuls les voitures et les scoot ELEC sont autorisés
  const allowedSwitchConfigVehicle = ['CAR', 'SELEC'];
  const vehicleConfigChoices = VEHICLES_LONG_LABEL.filter(v =>
    allowedSwitchConfigVehicle.includes(v.value)
  );

  return (<span className="BtnSwitchTasks">
    <DropdownButton
      className="BtnSwitchTasks d-inline mx-2"
      size="xs"
      variant={"default"}
      title={<i className="fa-solid fa-right-left"></i>}
      key={dock.id}
      id={"BtnSwitchTasks_" + dock.id}
    >
      {
        someDocks.map(
          someDock => {
            let someDriver = driversMap.get(someDock.target_driver);
            if (someDriver === undefined) {
              console.log("Chauffeur inconnu ", someDock.target_driver, someDock);
              return null;
            }
            return <Dropdown.Item key={someDock.target_driver}
                                  onClick={() => doSwitch(dock, someDock.id)}
            >{someDriver.name} ({someDriver.vehicle})</Dropdown.Item>
          }
        )
      }
      <Dropdown.Divider />
      <Dropdown.Item onClick={() => switchToReadyToLoad(dock)}>
        Prêt à partir - Non configuré
      </Dropdown.Item>
      {vehicleConfigChoices.map(vehicle =>
        <Dropdown.Item key={vehicle.value} onClick={() => switchToReadyToLoad(dock, vehicle.value)}>
          Prêt à partir - {vehicle.label}: premier dispo
        </Dropdown.Item>
      )}
    </DropdownButton>
  </span>);
};

Internal.propTypes = {
  dock: PropTypes.object.isRequired,
  docks_extract: PropTypes.object,
  allDocks: PropTypes.array.isRequired,
  allDrivers: PropTypes.array.isRequired,
  doSwitch: PropTypes.func.isRequired,
  switchToReadyToLoad: PropTypes.func.isRequired,
};

export const BtnSwitchTasks = connect(
  (state) => {
    return {
      docks_extract: state.dispatch.docks_extract,
      allDocks: state.dispatch.docks,
      allDrivers: state.dispatch.drivers,
    };
  },
  (dispatch) => {
    return {
      doSwitch: (fromDock, toDockId) => {
        dispatch(act_dockSwitchDrivers(fromDock, toDockId));
      },
      switchToReadyToLoad: (dock, toVehicle) => {
        dispatch(act_dockReadyToLoad(dock, toVehicle));
      }
    };
  }
)(Internal);


export default BtnSwitchTasks;
