/* eslint-disable react/prop-types */
import React from "react";
import {openGroup} from "../utils";

import {ExtraCostDelete} from "./ExtraCostDelete";

export const PresenceLogs = (datas) => {
  let classNames = "presence-logs";
  if (datas.show) {
    classNames += " d-table-row";
  }
  else {
    classNames += " d-none";
  }

  return <tr id={"detail" + datas.mainId} key={"detail" + datas.mainId} className={classNames}>
    <td colSpan={9}>
      <ul>
        {
          datas.logs.map((log, key) => {
            return <li key={key}>
              {log.label}
              {' '}
              {
                log.link_id
                ? <button className={"btn btn-link"} onClick={() => openGroup(log.link_id)} >voir le groupement</button>
                : null
              }
              {' '}
              {log.error ? <b>{ log.error.message }</b> : null }
              {" "}
              {
                (log.start_center_name || log.end_center_name) &&
                <span style={{paddingLeft: 1}}>
                  (
                  {log.start_center_name ? "Départ : " + log.start_center_name : ""}
                  {log.end_center_name ? " Arrivée : " + log.end_center_name : ""}
                  )
                </span>
              }
              {log.comment && <span>{`(=> ${log.comment})`}</span>}
              {log.type === "driverextracost" && <ExtraCostDelete extracost={log} /> }
            </li>
          })
        }
      </ul>
    </td>
  </tr>;
};
