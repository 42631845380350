import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {act_loadDepositDrivers} from  "../../actions/deposit_driver";
import {showDepositModalDriver} from "../../actions/modal";

class InternalDepositDrivers extends React.Component {
  constructor() {
    super();
    this.state = {
      showAll: false
    };
  }

  componentDidMount() {
    this.props.loadDepositDrivers();
  }
  filterDrivers(e) {
    this.setState({
      showAll: e.target.checked
    });
  }

  render() {

    const {deposit_drivers, goToDepositModalDriver} = this.props;
    let drivers = [];
    if (deposit_drivers.datas.count > 0) {
      if (this.state.showAll === true) {
        drivers = deposit_drivers.datas.results.filter(() => true);
      } else {
        drivers = deposit_drivers.datas.results.filter((d) => d.nb_deposit > 0);
      }
    }
    
    return <div className="DepositDrivers">
      {deposit_drivers.loading === true && <div className="loading">
        <div className="loading-back"></div>
        <div className="loading-content">Chargement des données...</div>
      </div>}
      {deposit_drivers.datas.count > 0 &&
        <div>
          <div className="col-sm-12">
            <label>
              <input type="checkbox" onClick={this.filterDrivers.bind(this)} />&nbsp;Afficher tous les livreurs qui ont eu des consignes
            </label>
            {drivers.length > 0 &&
              <table className="table no-border table-condensed">
                <thead>
                  <tr>
                    <th>Nom du livreur</th>
                    <th>Qté récupérée</th>
                    <th>Qté rendue</th>
                    <th>Qté corrigée</th>
                    <th>Qté en sa possession</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {drivers.map((driver, key) => (
                    <tr key={key}>
                      <td>{ driver.name }</td>
                      <td>{ driver.quantity_received - driver.quantity_received_canceled }</td>
                      <td>{ driver.quantity_returned }</td>
                      <td>{ driver.quantity_returned_canceled }</td>
                      <td><strong>{ driver.nb_deposit }</strong></td>
                      <td><button className="btn btn-default btn-xs" onClick={() => goToDepositModalDriver(driver)}>Afficher</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>}
      {deposit_drivers.loading === false && drivers.length === 0 &&
        <div className="deposit_summary text-center">
          <br />
          <br />
          <strong>{`Aucun livreur n'a pas de produit consigné en sa possession`}</strong>
          <br />
          <br />
        </div>
      }
    </div>;
  }
}

InternalDepositDrivers.propTypes = {
  deposit_drivers: PropTypes.object.isRequired,
  loadDepositDrivers: PropTypes.func.isRequired,
  goToDepositModalDriver: PropTypes.func.isRequired,
};

export const DepositDrivers = connect(
  (state) => {
    return {
      deposit_drivers: state.dispatch.deposit.drivers,
    };
  },
  (dispatch) => {
    return {
      loadDepositDrivers: (id) => {
        return dispatch(act_loadDepositDrivers(id));
      },
      goToDepositModalDriver: (driver) => {
        return dispatch(showDepositModalDriver(driver));
      },
    };
  }
)(InternalDepositDrivers);

export default DepositDrivers;
