import React from 'react';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

import "../css/OrderProducts.css";
import moment from "moment/moment";
import {orderProductRefund, SUPPORT_MODAL_HIDE_MULTIPLE_REFUND} from "../actions";

import { MultipleCreditForm } from "./MultipleCreditForm";
import {getRefundProductSubTypeList} from "../../../selectors/refund_modal";


class InternalMultipleRefundModal extends React.Component {
  render() {
    const {sub_type_list, modal, hide, validate} = this.props;
    moment.locale('fr');

    if (modal && modal.show) {
      /* Set up defaults values min and max quantity */
      for (let orderProduct of modal.orderProducts) {
        let minQuantity = 0;
        let maxQuantity = 0;
        if (typeof (orderProduct.maxQuantity) !== 'undefined') {
          maxQuantity = orderProduct.maxQuantity;
          minQuantity = orderProduct.minQuantity;
        } else {
          minQuantity = -orderProduct.refundQuantity;
          maxQuantity = orderProduct.defaultQuantity - orderProduct.recreditQuantity - orderProduct.refundQuantity;
          orderProduct.minQuantity = minQuantity;
          orderProduct.maxQuantity = maxQuantity;
          orderProduct.defaultQuantity = maxQuantity;
        }
        orderProduct.quantity = orderProduct.defaultQuantity;
      }
    }

    return <Modal
      show={modal && modal.show}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      // size={"sm"}
    >
      <Modal.Body>
        <h4>{modal.label}</h4>
        {
          modal && modal.show ?
          <MultipleCreditForm
            type="refund"
            subTypeList={sub_type_list}
            orderProducts={modal.orderProducts}
            subtype={modal.subtype}  // default value
            validate={(data) => {
              validate(data, modal);
            }}
          />
          : null
        }
      </Modal.Body>
    </Modal>;
  }
}

export const MultipleRefundModal = connect(
  (state) => ({
    "modal" : state.support.modalMultipleRefund,
    "sub_type_list": getRefundProductSubTypeList(state),
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": SUPPORT_MODAL_HIDE_MULTIPLE_REFUND});
      },
      "validate": (data, modal) => {
        for (let values of data) {
          if (
            values.quantity === undefined
            || values.quantity === null
            || isNaN(values.quantity)
            || values.quantity === 0
            || !values.checked
          ) {
            continue;
          }
          dispatch(
            orderProductRefund(
              values.quantity,
              values.subtype,
              values.notes || '',
              values.pricePerUnitQuantity,
              values.order_product_id,
            )
          );
        }
      }
    };
  }
)(InternalMultipleRefundModal);
