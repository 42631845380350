import React from "react";
import { createSelector } from 'reselect';

/* TAD = Temps après le Dernier picking */

const getPackerstatTad = state => state.m.packerstatsordermoving;

export const getTadAvg = createSelector(
  [getPackerstatTad],
  (packerstattad) => {

    let packerstattad_avg = null;
    if (packerstattad && typeof(packerstattad['by_packer']) !== 'undefined') {
      const tad_global = packerstattad['by_packer'][0];

      let tad_global_per_zone = <small>
        <br />
        <b>TF Moyen</b> {` => `}
        {Object.values(tad_global["datas"]).map((tad, index) => {
          return <span key={index} title={tad.tad_tooltip} className="iph_tooltip">{index > 0 ? <b> / </b> : null}{tad.time_name}: <span style={{color: "grey"}}>{tad.time_avg}</span></span>;
        })}
      </small>;
      

      packerstattad_avg = (
        <React.Fragment>
          {tad_global_per_zone}
        </React.Fragment>
      );
    }

    return packerstattad_avg;
  }
);
