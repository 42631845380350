import React from "react";

//import DeliveriesFilter from "./Deliveries/DeliveriesFilter";
import DeliveriesToDispatch from "./Deliveries/DeliveriesToDispatch";

import '../css/Deliveries.css';

export const Deliveries = () => {
  return (
    <div className="Deliveries col-0 col-sm-4 col-huge-2virgule4">
      <DeliveriesToDispatch />
    </div>
  );
};
