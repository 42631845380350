import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";


const DriverPlanning = ({driver}) => {
  let style = {};
  if ((driver.planning_end || driver.early_start) && ['run', 'run_other', 'wait'].includes(driver.state)) {
    let planningEnd = moment(parseInt(driver.planning_end, 10), 'X');
    let now = moment();
    let now30added = moment().add(30, "minutes");
    //console.log(now30added.format('HH:mm'), now.format('HH:mm'), planningEnd.format('HH:mm'));

    if (driver.early_start) {

      let earlyStart = moment(parseInt(driver.early_start, 10), 'X');
      style.color = "blue";
      return <span style={style} title="Heure de fin de présence"> {earlyStart.format('HH:mm') + '-..'}</span>;
    } else {

      if (planningEnd < now) {
        style.color = "red";
      } else if (planningEnd && planningEnd < now30added) {
        style.color = "orange";
      }
      return <span style={style} title="Heure de fin de présence"> {planningEnd.format('HH:mm')}</span>;
    }
  }
  return null;
};

DriverPlanning.propTypes = {
  driver: PropTypes.object.isRequired,
};

export  default  DriverPlanning;
