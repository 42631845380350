import { call, put, takeEvery, select} from 'redux-saga/effects';
//import Notifications from 'react-notification-system-redux';
import {stateToCenterId} from "../../utils/center";

import {daUtil} from "../../api";
import {
  ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG,
  ASSIGN_LOADED_NEXTCONFIG,
  ASSIGN_DROP_NEXTCONFIG,
  ASSIGN_UPDATE_NEXTCONFIG,
  ASSIGN_UPDATED_NEXTCONFIG,
  ASSIGN_LOAD_PSEUDOZONE,
  ASSIGN_LOADED_PSEUDOZONE,
  ASSIGN_LOAD_CONFIGS, ASSIGN_LOADED_CONFIGS, ASSIGN_UPDATE_CONFIGS
} from "./actions";
import {cloneAndPluck} from "../../utils";

//  yield put(Notifications.success({title: "Fiche client", "message": "Sauvegarde effectuée", autoDismiss: 3, dismissible: "click"}));


function* assignAutoCreateNextConfig(action) {
  const state = yield select();
  const centerId = stateToCenterId(state);
  const res = yield call(daUtil.post, `/nextconfigs/auto_create/?center_id=${centerId}`, {});
  yield put({response: res, type: ASSIGN_LOADED_NEXTCONFIG});
}


function* assignLoadPseudoZone(action) {
  const state = yield select();
  const centerId = stateToCenterId(state);
  const res = yield call(daUtil.get, `/pseudo-zones/?center_id=${centerId}`, {});
  yield put({response: res, type: ASSIGN_LOADED_PSEUDOZONE});
}

function* assignLoadConfigs(action) {
  const state = yield select();
  const centerId = stateToCenterId(state);
  const res = yield call(daUtil.get, `/configs/global/?section=logistics,cart,launchpad,slots&center_id=${centerId}`, {});
  yield put({response: res, type: ASSIGN_LOADED_CONFIGS});
}

// function* assignLoadNextConfig(action) {
//   const res = yield call(daUtil.get, `/nextconfigs/`, {});
//   yield put({response: res, type: ASSIGN_LOADED_NEXTCONFIG});
// }

function* assignUpdateAllNextConfig(action) {
  const state = yield select();
  const data = state.assign.nextConfigs.map(e => (cloneAndPluck(e, ["id", "rank"])));

  const centerId = stateToCenterId(state);
  const res = yield call(daUtil.post, `/nextconfigs/bulk/?center_id=${centerId}`, data);
  yield put({response: res, type: ASSIGN_LOADED_NEXTCONFIG});
}

function* assignUpdateNextConfig(action) {
  // const state = yield select();

  const data = action.patch;

  const res = yield call(daUtil.patch, `/nextconfigs/${action.id}/`, data);
  yield put({response: res, type: ASSIGN_UPDATED_NEXTCONFIG});
}


function* assignUpdateConfigs(action) {

  const res = yield call(daUtil.post, `/configs/global/bulk/`, action.update);
  yield put({response: res, type: ASSIGN_LOAD_CONFIGS});
}


export const assignSagas = [
  takeEvery(ASSIGN_LOAD_AUTO_CREATE_NEXTCONFIG, assignAutoCreateNextConfig),
  takeEvery(ASSIGN_LOAD_PSEUDOZONE, assignLoadPseudoZone),
  takeEvery(ASSIGN_LOAD_CONFIGS, assignLoadConfigs),
  //takeEvery(ASSIGN_LOAD_NEXTCONFIG, assignLoadNextConfig),
  takeEvery(ASSIGN_DROP_NEXTCONFIG, assignUpdateAllNextConfig),
  takeEvery(ASSIGN_UPDATE_NEXTCONFIG, assignUpdateNextConfig),
  takeEvery(ASSIGN_UPDATE_CONFIGS, assignUpdateConfigs),

];
