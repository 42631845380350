import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { showStuartCall, showUberCall } from '../actions/modal';

const ExternalCallButton = ({ task, provider, children, dispatch }) => {
  const showing = !(
    task.external_job_delivery_status
    || (
      task.dock && Object(task.dock.external_job).length
    )
  );

  let backgroundColor;
  switch (provider) {
  case 'stuart':
    backgroundColor = '#0192ff';
    break;
  case 'uber':
    backgroundColor = 'black';
    break;
  default:
    console.log("Provider inconnu dans ExternalCallButton", provider);
    return null;
  }

  const clickHandler = () => {
    switch (provider) {
    case 'stuart':
      return dispatch(showStuartCall(task));
    case 'uber':
      return dispatch(showUberCall(task));
    default:
      console.log("Provider inconnu dans ExternalCallButton clickHandler", provider);
      return null;
    }
  };

  return (
    showing
      ? (
        <Button
          size="sm"
          onClick={clickHandler}
          style={{ backgroundColor }}
        >
          {children}
        </Button>
      ) : null
  );
};

ExternalCallButton.propTypes = {
  task: PropTypes.object,
  children: PropTypes.string,
  provider: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(() => ({}))(ExternalCallButton);

