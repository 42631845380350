import {
  ARRIVALS_LOADED,
  ARRIVALS_API_STATE,
  ARRIVALS_TASK_UPDATED,
} from "./actions";

const preCompute = (new_state) => {
  let pz_by_id = {};
  new_state.pseudozones.forEach(pz => pz_by_id[pz.id] = pz);

  new_state.pending.forEach(task => {
    task.linked_order.pseudo_zones_status.forEach(opz => {
      opz.pseudozone = pz_by_id[opz.pseudozone_id];
    });
  });

  return new_state;
};


export const arrivalsReducer = (
  state = {docks: [], pending: [], pseudozones: [], loading: false, processing: false, showModal: false, modalData: {} },
  action
) => {
  switch (action.type) {
  case ARRIVALS_API_STATE:
    return preCompute({...state, processing: action.processing, loading: action.loading });
  case ARRIVALS_LOADED:
    return preCompute({...state, docks: action.arrivals.docks, pending: action.arrivals.pending, pseudozones: action.arrivals.pseudozones });
  case ARRIVALS_TASK_UPDATED:
    if(action.dockId) {
      return preCompute({
        ...state,
        docks: state.docks.map(d =>
          d.id === action.dockId
            ? {...d,  tasks: d.tasks.map(t =>
              t.id === action.taskId ? {...t, linked_order: action.order} : t
            )}
            : d
        )
      });
    }
    return preCompute({
      ...state,
      pending: state.pending.map(t =>
        t.linked_order.id === action.order.id
          ? { ...t, linked_order: action.order }
          : t
      )
    });
  default:
    return preCompute(state);
  }
};
