
export const USER_LOAD = 'USER_LOAD';
export const USER_LOADED = 'USER_LOADED';
export const USER_UPDATE_CURRENT_CENTER = 'USER_UPDATE_CURRENT_CENTER';
//export const USER_UPDATED_CURRENT_CENTER = 'USER_UPDATED_CURRENT_CENTER';

export const userChangeCenter = (user, center) => {
  return {
    type: USER_UPDATE_CURRENT_CENTER,
    user: user,
    center: center
  };
};

// export const userChangedCenter = () => {
//   return {
//     type: USER_UPDATED_CURRENT_CENTER
//   };
// };



export const userLoad = () => {
  return {
    type: USER_LOAD
  };
};

export const userLoaded = (user) => {
  return {
    type: USER_LOADED,
    user: user
  };
};

export const bootUser = (store) => {
  store.dispatch(userLoad());
};
