import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {showOptimizeDock} from "../../../actions/modal";
import { DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED } from '../../../utils/dock_n_driver';


export class BtnOptimizeInternal extends React.Component {
  render() {
    const dock = this.props.dock;
    const optimize = this.props.optimize;
    const type = this.props.type;
    const authUser = this.props.authUser;

    if(!authUser || !authUser.groups || !authUser.groups.is_super_admin){
      return null;
    }

    const formatHoursOrMinutes = number => number < 10 ? `0${number}` : number;

    const formatDeparture = departure => {
      const [beginIso, endIso] = departure;
      const begin = new Date(beginIso);
      const end = new Date(endIso);
      const beginHours = formatHoursOrMinutes(begin.getHours());
      const beginMinutes = formatHoursOrMinutes(begin.getMinutes());
      const endHours = formatHoursOrMinutes(end.getHours());
      const endMinutes = formatHoursOrMinutes(end.getMinutes());
      return `${beginHours}:${beginMinutes} - ${endHours}:${endMinutes}`;
    };
    if(
      type === "missing"
      && !(
        dock.departure
        && dock.departure.length
        && ![DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock.dock_type)
      )
    ){
      return "";
    }

    return (
      <Button
        size="xs"
        onClick={() => optimize(dock)}
        className="BtnOptimizeRoute toggle"
        variant="info"
      >
        {
          dock.departure
          && dock.departure.length
          && ![DOCK_TYPE_HARD_ASSIGNED, DOCK_TYPE_SOFT_ASSIGNED].includes(dock.dock_type)
          ? (
            <span>
              <i className="fa-solid fa-plane-departure"></i>
              <span style={{marginLeft:"2px"}}>
                {formatDeparture(dock.departure)}
              </span>
            </span>
          )
          : <span title={"Optimisation"}>O</span>
        }
      </Button>
    );
  }
}

BtnOptimizeInternal.propTypes = {
  dock: PropTypes.object,
  type: PropTypes.string,
  optimize: PropTypes.func,
  authUser: PropTypes.object,
};

export const BtnOptimize = connect(
  (state) => {
    return {
      authUser: state.user,
    };
  },
  dispatch => ({
    "optimize": (dock) => dispatch(showOptimizeDock(dock))
  })
)(BtnOptimizeInternal);
