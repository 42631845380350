import { createSelector } from 'reselect'
import moment from "moment";


const SHOW_MORE_KEY  = 'next_show_more';

const getNexts = state => state.m.nexts
const getCurrentSort = state => state.ui.sort['nexts'] || 'name'
const getShowMoreKey = state => state.ui.toggle[SHOW_MORE_KEY]

export const getSortedFilteredNexts = createSelector(
  [getNexts, getCurrentSort, getShowMoreKey],
  (nexts, currentSort, showMoreKey) => {
    // 0-0 : ??
    // 31-0 : stop
    const beginOfDay = moment().startOf('day');
    return nexts.filter(p => p.current_task_type !== '31-0' && p.current_task_type !== '0-0')
      .filter(p => moment(p.last_started_at) > beginOfDay)
      .sort((a, b ) => {
        if (currentSort === 'name') {
          if (a.user.is_working === b.user.is_working) {
            return (a.user.name <= b.user.name ? -1 : 1);
          } else {
            return (a.user.is_working ? -1 : 1);
          }
        }
        return (a.current_task_type <= b.current_task_type ? -1 : 1);
      })
      .filter(p => p.user.has_planning || showMoreKey);
  }
)
