import { connect } from 'react-redux';

import { dockListEventHandler, dockListProps } from '../../../containers/common-props/dock';
import { getComputedDockListXD } from '../selectors';
import DockList from '../../../components/DockList';
import {withRouter} from "react-router-dom";

export default withRouter(connect(
  state => dockListProps(state, getComputedDockListXD(state)),
  dispatch => dockListEventHandler(dispatch)
)(DockList));
