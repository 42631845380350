import {
  DELIVERIES_TODO_CLEAN_ROWS_SELECTED,
  DELIVERIES_TODO_ADD_ROW_SELECTED,
  DELIVERIES_TODO_REMOVE_ROW_SELECTED,
  DELIVERIES_TODO_TOGGLE_ROW_SELECTED,
  DELIVERIES_TODO_TOGGLE_DISPATCH_WAITING_TASKS,
} from "../actions/deliveries_todo";

const deliveriesTodoInitialState = {
  "selectedTasksIds": [],
  "canDispatchWaitingTasks": (
    window.localStorage.getItem('canDispatchWaitingTasks') !== "true" ? false : true
  ),
};
export const deliveriesTodoReducer = (state = deliveriesTodoInitialState, action) => {
  // console.log(state);
  let newState = {};
  switch (action.type) {
  case DELIVERIES_TODO_CLEAN_ROWS_SELECTED:
    // console.log('DELIVERIES_TODO_CLEAN_ROWS_SELECTED');
    newState = {...state, "selectedTasksIds": []};
    break;
  case DELIVERIES_TODO_ADD_ROW_SELECTED:
    // console.log('DELIVERIES_TODO_ADD_ROW_SELECTED');
    newState = {
      ...state
    };
    if (!newState.selectedTasksIds.includes(action.taskId)){
      newState.selectedTasksIds = state.selectedTasksIds.slice();
      newState.selectedTasksIds.push(action.taskId);
    }
    break;
  case DELIVERIES_TODO_REMOVE_ROW_SELECTED:
    // console.log('DELIVERIES_TODO_REMOVE_ROW_SELECTED');
    newState = {
      ...state
    };
    if (newState.selectedTasksIds.includes(action.taskId)){
      newState.selectedTasksIds = state.selectedTasksIds.slice();
      newState.selectedTasksIds.splice(newState.selectedTasksIds.indexOf(action.taskId), 1);
    }
    break;
  case DELIVERIES_TODO_TOGGLE_ROW_SELECTED:
    // console.log('DELIVERIES_TODO_TOGGLE_ROW_SELECTED');
    newState = {
      ...state
    };
    newState.selectedTasksIds = state.selectedTasksIds.slice();
    if (newState.selectedTasksIds.includes(action.taskId)){
      // console.log('was present');
      newState.selectedTasksIds.splice(newState.selectedTasksIds.indexOf(action.taskId), 1);
    }
    else {
      // console.log('was absent');
      newState.selectedTasksIds.push(action.taskId);
    }
    // console.log(newState.selectedTasksIds);
    break;
  case DELIVERIES_TODO_TOGGLE_DISPATCH_WAITING_TASKS: {
    let canDispatchWaitingTasks = window.localStorage.getItem('canDispatchWaitingTasks');
    if (canDispatchWaitingTasks !== 'true') {
      canDispatchWaitingTasks = false;
    }
    canDispatchWaitingTasks = !canDispatchWaitingTasks;
    window.localStorage.setItem('canDispatchWaitingTasks', canDispatchWaitingTasks);
    newState = {
      ...state, 'canDispatchWaitingTasks': canDispatchWaitingTasks
    };
    break;
  }

  default:
    newState = state;
  }
  // console.log(newState);
  return newState;
};
