import {UI_SORT, UI_TOGGLE} from "../actions/ui";

let uiInitialState = {
  toggle: {},
  sort: {}
};

let sortBackup = localStorage.getItem('ui_sort');
if (sortBackup) {
  try {
    uiInitialState['sort'] = JSON.parse(sortBackup);
  } catch(e) {
    console.error(e)
  }
}


export const uiReducer = (state = uiInitialState, action) => {
  switch (action.type) {
  case UI_TOGGLE: {
    return {
      ...state,
      'toggle': {
        ...state.toggle,
        [action.key]: !state.toggle[action.key]
      }
    };
  }
  case UI_SORT: {
    let newSort = {
      ...state.sort,
      [action.colGroup]: action.colKey
    };

    localStorage.setItem('ui_sort', JSON.stringify(newSort));

    return {
      ...state,
      'sort': newSort
    };
  }
  default: {
    return state;
  }
  }
};
