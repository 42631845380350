import { createSelector } from 'reselect'
import {maxShippingSortingFinalized} from "../utils/task";

const getTasks = state => state.m.deliveries_todo

export const getSortedTasks = createSelector(
  [getTasks],
  (tasks) => {
    return tasks.slice().sort(maxShippingSortingFinalized)
  }
)
