import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FinishDockButton} from "../FinishDockButton";
import React from "react";

// eslint-disable-next-line react/prop-types
export const StuartDockName = ({dock}) => {

  const tooltip = (<Tooltip id={"tooltip_dock_" + dock.id}>
    {dock.external_job.driver_phone ? dock.external_job.driver_phone.replace('+33', '0') : ''}</Tooltip>);
  return (
    <OverlayTrigger placement="top" overlay={tooltip} transition={false} className="StuartDockName">
      <span>
        Stuart&nbsp;
        {dock.external_job.driver_name || ''}
        {dock.tasks.length > 0 && dock.locked_at ? <FinishDockButton dock={dock}/> : null}
      </span>
    </OverlayTrigger>);
};
