import React from 'react';
import PropTypes from 'prop-types';
import {Button } from "react-bootstrap";
import { connect } from 'react-redux';

import {driverDepositCancelReturned, act_showPopinConfirm} from  "../../actions/deposit_driver";

const Internal = ({deposit, showPopupConfirm}) => {

  if (deposit.is_returned && (deposit.status.cancel_status !== 'total')) {
    return <div className="DepositDriverHistoryCancelReturnButton">
      <Button variant="danger" size="xs" onClick={() => {showPopupConfirm(deposit.driver, deposit.id);}}>Annuler</Button>
    </div>;
  }
  return null;
};
Internal.propTypes = {
  isSubmitting: PropTypes.bool,
  deposit: PropTypes.object.isRequired,
  showPopupConfirm: PropTypes.func.isRequired,
};

export const DepositDriverHistoryCancelReturnButton = connect(
  () => {
    return {};
  },
  (dispatch) => {
    return {
      "handleFixSubmit": (driver_id, values) => {
        dispatch(driverDepositCancelReturned(driver_id, values));
      },
      "showPopupConfirm": (driver_id, deposit_driver_id) => {
        dispatch(act_showPopinConfirm(
          'Êtes vous certain de vouloir annuler ce retour ?',
          driverDepositCancelReturned(driver_id, deposit_driver_id)
        ));
      }
    };
  }
)(Internal);
