import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { MAIN_MODAL_HIDE } from "../../../../actions/modal";
import StuartCallModal from "../../../../components/modal/StuartCallModal";
import { DISPATCH_ORDER_CALL_STUART, DISPATCH_DOCK_CALL_STUART } from "../../actions";
import { ORDER_STUART_SENDING, DOCK_STUART_SENDING } from "../../../../actions";

export default connect(
  state => ({
    modal: state.m.modal,
  }),
  dispatch => ({
    onHide: () => dispatch({ type: MAIN_MODAL_HIDE }),
    callStuart: (orderPatch, vehicle) => {
      dispatch({ type: ORDER_STUART_SENDING, sending: true });
      return dispatch({ type: DISPATCH_ORDER_CALL_STUART, orderPatch, vehicle });
    },
    callDockStuart: (dock, vehicle) => {
      dispatch({ type: DOCK_STUART_SENDING, sending: true });
      return dispatch({ type: DISPATCH_DOCK_CALL_STUART, dockId: dock.id, vehicle });
    },
    notifyError: (errorMessage) => dispatch(
      Notifications.error({title: "Stuart" , "message": errorMessage,  autoDismiss:3, dismissible:"click"})
    )
  })
)(StuartCallModal);

