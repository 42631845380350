import {extractToken} from "../api";
import {refreshRequest, loadAlarmRequest, refreshRequestError, ignoreDisconnect} from "./index";
import {extractCenterId} from "../utils/center";
import {SHARE_WORKER_ON} from "../config";


const shouldSync = (location) => {
  return !location.pathname.includes('/dispatch/')
    && !location.pathname.includes('/support/')
    && !location.pathname.includes('/logs/')
    && !location.pathname.includes('/assign/');
}

export const bootRefresh = (store, user) => {

  if (typeof SharedWorker !== 'undefined' && SHARE_WORKER_ON) {
    const myWorker = new SharedWorker('/doWork.js');

    const centerId = extractCenterId(user);

    myWorker.port.start();
    // configure worker
    myWorker.port.postMessage({
      type: "INIT_WORKER",
      baseURL: process.env.REACT_APP_DELEEVAPI,
      centerId: centerId,
      timeout: 20 * 1000,
      headers: {
        'Authorization': 'Token ' + extractToken(),
        'Content-Type': 'application/json'
      }
    });
    // define handler (call on each REFRESH_REQUEST / REFRESH_REQUEST_SUCCESS)
    myWorker.port.addEventListener('message', (event) => {
      store.dispatch(event.data);
    });
    // ask to load initial data
    myWorker.port.postMessage({type: "INIT_DATA"});

  } else {
    if (document.location.pathname.includes('/dispatch/')) {
      console.log('skipped because dispatch is sync on its own');
      return;
    }

    // Approche sans WORKER :
    // le setInterval est plus fréquent que le refresh
    // pour gérer plus rapidement le changement de page.
    // André : Why is that counter here ?
    // Laurent : Because otherwise it would be reinit at 0
    // in each call of the anonymous function below.
    // But you can also refactor to emulate a static variable à la C
    // like this answer suggests:
    // https://stackoverflow.com/a/1535650/5796086
    let currentCount = 0;

    store.dispatch(refreshRequest());
    setInterval(() => {
      currentCount++;
      if (document && document.visibilityState === 'hidden') {
        store.dispatch(ignoreDisconnect());
        console.log('skipped because not visible');
        return;
      }

      // @todo try to use react router here to get the location
      if (!shouldSync(document.location)) {
        console.log('skipped because no need to sync');
        return;
      }

      if (currentCount < 4) {
        console.log('skipped because currentCount : ', currentCount);
        return;
      }
      try {
        store.dispatch(refreshRequest());
        currentCount = 0;
      } catch (e) {
        console.log(e)
        store.dispatch(refreshRequestError());
      }

    }, 1000 * 5);
  }
  setInterval(
    () => {
      store.dispatch(loadAlarmRequest());
    },
    1000 * 10
  );

};
