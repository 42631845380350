import React, {useState} from 'react';
import {connect} from "react-redux";
import {DISPATCH_MAIN_MODAL_HIDE, DISPATCH_MODAL_DRIVERS_SOON} from "../../actions/modal";

import {getDriversReadyInLessThan15min} from "../common-comps/dock";
import {Badge, Modal} from "react-bootstrap";
import DriverStateLinked from "../Driver/DriverStateLinked";
import {VEHICLES_LONG_LABEL} from "../../../../utils/dock_n_driver";
import moment from "moment";


export const DriversSoonModalContent = ({ modal, drivers, hide, validate }) => {
  const initialState = {
    sortBy1: "eta",
    sortBy2: "name",
    sortBy3: "vehicle",
    arrivalFilter: "soon",
    planningFilter: "hide_refusal",
    vehicleFilter: "all",
    search: "",
  };

  const [modalState, setModalState] = useState({ ...initialState });

  let filteredDrivers = drivers;
  if(modalState.arrivalFilter === "soon"){
    filteredDrivers = getDriversReadyInLessThan15min(drivers);
  }
  if(modalState.planningFilter === "hide_refusal"){
    filteredDrivers = filteredDrivers.filter(driver => driver.is_planning_confirmed !== -1);
  }
  if(modalState.vehicleFilter !== "all"){
    filteredDrivers = filteredDrivers.filter(driver => driver.vehicle === modalState.vehicleFilter);
  }
  if(modalState.search !== ""){
    filteredDrivers = filteredDrivers.filter(driver => driver.name.toLowerCase().indexOf(modalState.search.toLowerCase()) !== -1);
  }
  const sortOneRound = (sortBy) => {
    if(sortBy === "name"){
      filteredDrivers.sort((driver1, driver2) => {
        let name1 = driver1.name ? driver1.name : "";
        let name2 = driver2.name ? driver2.name : "";
        return name1.localeCompare(name2);
      });
    }
    else if(sortBy === "eta"){
      filteredDrivers.sort((driver1, driver2) => {
        let eta_comparable1 = driver1.eta_comparable ? driver1.eta_comparable : "";
        let eta_comparable2 = driver2.eta_comparable ? driver2.eta_comparable : "";
        return eta_comparable1.localeCompare(eta_comparable2);
      });
    }
    else if(sortBy === "vehicle"){
      filteredDrivers.sort((driver1, driver2) => {
        let vehicle1 = driver1.vehicle ? driver1.vehicle : "";
        let vehicle2 = driver2.vehicle ? driver2.vehicle : "";
        return vehicle1.localeCompare(vehicle2);
      });
    }
    if(sortBy === "id"){
      filteredDrivers.sort((driver1, driver2) => {
        return (driver1.id > driver2.id) - (driver1.id < driver2.id);
      });
    }
  }
  sortOneRound("id");
  sortOneRound(modalState.sortBy3);
  sortOneRound(modalState.sortBy2);
  sortOneRound(modalState.sortBy1);

  const vehicleToStr = (vehicle) => {
    if(vehicle === 'CAR'){
      return 'voiture';
    }
    if(vehicle === 'SELEC'){
      return 'e-scooter';
    }
    if(vehicle === 'TRUCK'){
      return 'camion';
    }
    return vehicle;
  }

  const driverToPrint = (driver) => {
    return (
      <tr key={driver.id}>
        <td>{driver.name}</td>
        <td>{driver.mobile}</td>
        <td>{vehicleToStr(driver.vehicle)}</td>
        <td>{driver.eta_comparable}
          {
            driver.eta_comparable === "OO:OO"
            && driver.current_planning_start !== ""
            ? "(" + moment(driver.current_planning_start * 1000).format("HH:mm") + ")"
            : null
          }
        </td>
        <td>
          {
            driver.planning_data
            ? driver.planning_data.map(
              (session, i, planning) => {
                const sessionString = (
                  moment(session.start * 1000).format("HH:mm")
                  + "-"
                  + moment(session.end * 1000).format("HH:mm")
                  + (i + 1 !== planning.length ? ", " : "")
                );
                const is_current = (
                    moment(session.start * 1000) <= moment()
                    && moment() <= moment(session.end * 1000)
                );
                return <span
                  style={{fontWeight: is_current ? "bold" : "normal"}}
                  key={moment(session.start * 1000).format("HH:mm")}
                >
                  {sessionString}
                </span>
              }
            )
            : null
          }
        </td>
        <td>
          {
            driver.is_planning_confirmed === 1
            ? "Oui" // "Il viendra."
            : (
              driver.is_planning_confirmed === -1
              ? "Non" // "Il ne viendra pas."
              : "" // "On ne sait pas."
            )
          }
        </td>
        <td>{driver.state === 'plan' ? "" : "Oui"}</td>
        <td>
          {
            driver.current_presence_start
            ? moment(driver.current_presence_start * 1000).format("HH:mm")
            : null
          }
        </td>
        <td>
          {
            driver.is_paper_checked
            ? <span title="Papiers vérifiés" role="img" aria-label="Papiers vérifiés">✅ </span>
            : (
              driver.billing_group === "AUTO"
              ? <span
                  title="Auto-entrepreneur sans vérification des papiers"
                  role="img" aria-label="Auto-entrepreneur sans vérification des papiers"
                >❌ </span>
              : null
            )
          }
        </td>
        <td>
          {
            driver.lgep_orders_allowed
            ? <Badge className="lgep"><strong title="La Grande Épicerie de Paris chez Vous">GEP</strong></Badge>
            : null
          }
        </td>
        <td>
          {
            driver.planning_not_respected
            ? <Badge className="lgep"><strong title="Planning Non Respecté">PNR</strong></Badge>
            : null
          }
        </td>
        <td>
          {
            driver.has_cni
            ? <Badge className="lgep"><strong title="Carte Nationale d'Identité">CNI</strong></Badge>
            : null
          }
        </td>
        <td>
          <DriverStateLinked key={driver.id} driver={driver} stateActionOnly={true}/>
        </td>
      </tr>
    );
  }

  return <Modal
      show={modal && modal.modalType === DISPATCH_MODAL_DRIVERS_SOON}
      animation={false}
      backdrop={true}
      onHide={() => hide()}
      keyboard={true}
      className="DriversSoonModal"
      enforceFocus={false}
    >
      <Modal.Body>
        <h4>Chauffeurs</h4>
        <div className={"mt-2 mb-2"}>
          Arrivée&nbsp;:&nbsp;
          <select
            onChange={(event) => setModalState({...modalState, arrivalFilter: event.target.value})}
          >
            <option value="soon"
                    title="Les chauffeurs qui devraient être au centre d'ici au plus 15 minutes. Les chauffeurs en retard de plus de 90 minutes sont exclus."
            >Chauffeurs bientôt au centre</option>
            <option value="all"
            >Tous</option>
          </select>
          &nbsp;&nbsp;
          Planning&nbsp;:&nbsp;
          <select
            onChange={(event) => setModalState({...modalState, planningFilter: event.target.value})}
          >
            <option value="hide_refusal"
            >Cacher les refus</option>
            <option value="all"
            >Tous</option>
          </select>
          &nbsp;&nbsp;
          Véhicule&nbsp;:&nbsp;
          <select
            onChange={(event) => setModalState({...modalState, vehicleFilter: event.target.value})}
          >
            <option value="all" key={"all"}
            >Tous</option>
            {
              VEHICLES_LONG_LABEL.map(
                option =>
                  <option value={option.value} key={option.value}
                  >{option.label}</option>
              )
            }
          </select>
          &nbsp;&nbsp;
          Recherche&nbsp;:&nbsp;
          <input type="text"
                 onChange={(event) => setModalState({...modalState, search: event.target.value})}
          />
        </div>
        <div className={"mt-2 mb-2"}>
        <button
          className={"btn btn-xs btn-default"}
          onClick={() => setModalState({...modalState, sortBy1: "eta", sortBy2: modalState.sortBy1, sortBy3: modalState.sortBy2})}
        >Trier par heure d'arrivée estimée</button>
        <button
          className={"btn btn-xs btn-default ml-2"}
          onClick={() => setModalState({...modalState, sortBy1: "name", sortBy2: modalState.sortBy1, sortBy3: modalState.sortBy2})}
        >Trier par nom</button>
        <button
          className={"btn btn-xs btn-default ml-2"}
          onClick={() => setModalState({...modalState, sortBy1: "vehicle", sortBy2: modalState.sortBy1, sortBy3: modalState.sortBy2})}
        >Trier par véhicule</button>
        </div>
        <table className="table table-bordered table-condensed table-hover">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Téléphone</th>
              <th>Véhicule</th>
              <th>Heure d'arrivée</th>
              <th>Sessions de planning</th>
              <th>Confirmation planning</th>
              <th>A commencé ?</th>
              <th>Début de présence</th>
              <th>Papiers vérifiés</th>
              <th>GEP</th>
              <th title={"Planning Non respecté"}>PNR</th>
              <th title={"Carte Nationale d'Identité"}>CNI</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {
            filteredDrivers.length > 0
            ? filteredDrivers.map(driverToPrint)
            : null
          }
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  ;
}

export const DriversSoonModal = connect(
  (state) => ({
    "modal" : state.dispatch.modal,
    "drivers": state.dispatch.drivers,
  }),
  (dispatch) => {
    return {
      "hide": () => {
        dispatch({"type": DISPATCH_MAIN_MODAL_HIDE});
      },
      "validate": () => {},
    };
  }
)(DriversSoonModalContent);
