import React from 'react';
import PropTypes from 'prop-types';

import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from "react-redux";
import { DOCK_DEFINE, DOCK_UNDEFINE } from "../../actions";
import { MAIN_MODAL_SHOW } from "../../actions/modal";
import { XD_LOADING_MODAL } from '../../apps/crossdocking/modals.js';

import "../../css/DockBtnDefine.css";
import {DOCK_TYPE_HARD_ASSIGNED} from "../../utils/dock_n_driver";


export const isDefinable = function (dock) {

  if (dock.tasks.length === 0) {
    return false;
  }

  if (dock.defined_at || dock.loaded_at || dock.locked_at || dock.gone_at) {
    return false;
  }

  return dock.dock_type !== DOCK_TYPE_HARD_ASSIGNED;
};


// eslint-disable-next-line react/prop-types
const IDefine = ({
  dock,
  onDefine,
  onUndefine,
  showXdLoadingModal,
}) => {
  const isCrossDocking = !!dock.cross_docking_target_center;

  if (!dock || dock.locked_at) {
    return null;
  }

  if (dock.current_status === "ready_to_load") {
    return (
      <span className="define-loaded-label BtnDefine">
        <span><i className="fa-solid fa-truck-ramp-box"></i> Chargé</span>
        {dock.tasks.length === 0 && dock.dock_type !== 'DRIVER_HARD'
          ? <Button
            onClick={() => onUndefine(dock)}
            size="xs"
            variant={dock.updating ? "default" : "danger"}
            disabled={dock.updating ? true : false}><span className="fa-regular fa-circle-xmark"></span></Button>
          : null
        }
      </span>
    );
  }

  if (dock.load_info.started_at && !dock.loaded_at) {
    const tooltip = (
      <Tooltip id={`tooltip-loader-${dock.id}`}>
        {dock.load_info.user_name}
      </Tooltip>
    );
    return (
      <OverlayTrigger placement="top" overlay={tooltip} transition={false} className="BtnDefine">
        <span className="define-loading-label"><i className="fa-solid fa-truck-ramp-box"></i>&nbsp;en cours</span>
      </OverlayTrigger>
    );
  }

  if (dock.current_status === "moving") {
    return (
      <Button
        className="BtnDefine"
        onClick={() => onUndefine(dock)}
        size="xs"
        variant={"danger"}
        disabled={dock.updating ? true : false}>ANNULER LA MISE EN CHARIOT</Button>
    );
  }

  if (isDefinable(dock)) {
    let is_disabled = false;
    if (dock.updating === true) {
      is_disabled = true;
    }
    for (var i=0; i < dock.tasks.length; ++i) {
      if (dock.tasks[i].status === 'waiting') {
        is_disabled = true;
        break;
      }
    }

    return (
      isCrossDocking
        ? (
          <Button
            className="ButtonDefine"
            onClick={() => showXdLoadingModal(dock)}
            size="xs"
            variant={dock.defined_at ? "default" : "primary"}
            disabled={is_disabled}
          >
            Charger
          </Button>
        ): (
          <Button
            className="ButtonDefine"
            onClick={() => onDefine(dock)}
            size="xs"
            variant={dock.defined_at ? "default" : "primary"}
            disabled={is_disabled}
          >
            Lancer la mise en chariot
          </Button>
        )
    );
  }

  return null;
};

IDefine.propTypes = {
  dock: PropTypes.object,
  onDefine: PropTypes.func,
  onUndefine: PropTypes.func,
  showXdLoadingModal: PropTypes.func,
};

export const BtnDefine = connect(
  state => ({
    modal: state.m.modal,
  }),
  dispatch => ({
    onDefine: (dock) => dispatch({ type: DOCK_DEFINE, dock : dock, service: "define"} ),
    onUndefine: (dock) => dispatch({ type: DOCK_UNDEFINE, dock : dock, service: "undefine"} ),
    showXdLoadingModal: dock => dispatch({
      type: MAIN_MODAL_SHOW,
      modalData: { modalType: XD_LOADING_MODAL, dock },
    })
  })
)(IDefine);
