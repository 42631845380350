import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";

import moment from "moment/moment";

const InternalOrdersLinked = ({order}) => {

  if (
    !order ||
    order.cross_docking ||
    (order.parent_order == null && order.child_orders.length === 0)
  ) {
    return null;
  }

  moment.locale('fr');
  let i = 0;
  return <Table size="sm" hover className="OrdersLinked">
    <tbody>
      <tr><th colSpan={2}>Commandes liées</th></tr>
      {
        order.parent_order && 
        <tr>
          <td>Commande parente</td>
          <td><a href={`/support/order/${order.parent_order}`} target="_blank" rel="noopener noreferrer">{order.parent_order}</a></td>
        </tr>
      }
      {
        order.child_orders && order.child_orders.map(child_order_id => {
          return (
            <tr key={"linked"+child_order_id}>
              <td>Commande enfante {++i}</td>
              <td><a href={`/support/order/${child_order_id}`} target="_blank" rel="noopener noreferrer">{child_order_id}</a></td>
            </tr>
          );
        })
      }
    </tbody>
  </Table>;
};

InternalOrdersLinked.propTypes = {
  "order": PropTypes.object
};

export const OrdersLinked = connect(
  (state, ownProp) => ({...ownProp , pseudoZones: state.support.meta ? state.support.meta.pseudoZones : [] })
)(InternalOrdersLinked);
